import { gql } from '@apollo/client';

export interface SimulationItemInputProps {
  resourceId: string;
  competence: string;
  price: number;
  quantityMwm: number;
}

export interface UpdateSimulationItemProps {
  simulationId: string;
  simulationItems: SimulationItemInputProps[];
}

export interface MutationResponseProps {
  status: string;
}

export interface UpdateSimulationItemsPayloadProps {
  updateSimulationItems: MutationResponseProps;
  errors: string[];
}

export interface PayloadProps {
  input: UpdateSimulationItemProps;
}

export interface ResponseProps {
  updateSimulationInput: UpdateSimulationItemsPayloadProps;
}

const UPDATE_SIMULATION_ITEMS = gql`
  mutation updateSimulationItems($input: UpdateSimulationItemsInput!) {
    updateSimulationItems(input: $input) {
      updateSimulationItems {
        status
      }
      errors
    }
  }
`;

export default UPDATE_SIMULATION_ITEMS;
