import { useMemo } from 'react';

import Chart from 'react-apexcharts';

import { addMonths } from 'date-fns';
import PropTypes from 'prop-types';

import { getSeries, getOptions, getCategories } from './BalanceYearHelper';
import { Container } from './styles';

function BalanceYear({ isAveragePrice, onZoomIn, hide, data, loading }) {
  const series = useMemo(() => getSeries(data?.portfolioSummary, isAveragePrice), [data, isAveragePrice]);

  const options = useMemo(() => {
    const categories = getCategories(data?.portfolioSummary);
    const max = addMonths(new Date(categories.slice(-1).pop()), 6).getTime();
    const properties = {
      xaxis: { categories, max },
      chart: {
        events: {
          click: (event) => {
            const { j } = event.target.attributes;

            if (j?.value) onZoomIn(new Date(properties.xaxis.categories[j?.value]));
          },
        },
      },
    };

    if (isAveragePrice) {
      properties.stroke = { width: [2, 2] };
      properties.markers = {
        size: [4, 4],
        colors: ['#2558F8', '#EA7117'],
        strokeWidth: 0,
      };
    }

    return getOptions(properties);
  }, [data, isAveragePrice, onZoomIn]);

  return <Container hide={hide}>{!loading && <Chart options={options} series={series} height="100%" />}</Container>;
}

BalanceYear.propTypes = {
  isAveragePrice: PropTypes.bool.isRequired,
  onZoomIn: PropTypes.func.isRequired,
  hide: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default BalanceYear;
