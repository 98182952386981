import { useCallback, useMemo } from 'react';

import { NumericFormat } from 'react-number-format';

import { Box } from '@material-ui/core';
import MuiTableCell, { TableCellProps as MuiTableCellProps } from '@material-ui/core/TableCell';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { DateTime } from 'luxon';

import usePortfolio from '~/pages/Portfolio/usePortfolio';

import useStyles from './styles';

export interface TableCellProps extends MuiTableCellProps {
  sticky?: boolean;
  head?: boolean;
  foldable?: boolean;
  negative?: boolean;
  strong?: boolean;
  previous?: boolean;
  current?: boolean;
  decimalScale?: number;
}

const TableCell: React.FC<TableCellProps> = ({
  sticky,
  head,
  foldable,
  negative,
  strong,
  previous,
  current,
  children,
  decimalScale,
  ...props
}) => {
  const {
    tableCell,
    tableCellHead,
    tableCellStrong,
    tableCellNegative,
    tableCellPrevious,
    tableCellHeadPrevious,
    tableCellStrongPrevious,
    tableCellCurrent,
    tableCellHeadCurrent,
    tableCellStrongCurrent,
    tableCellStick,
    iconUnfold,
  } = useStyles();
  const { expandedPeriods, setFilter } = usePortfolio();

  const formattedChildren = useMemo((): string => {
    if (foldable && /^\d{4}-\d{2}$/.test(children as string)) {
      return DateTime.local(...(children as string).split('-').map((item) => Number(item)))
        .setLocale('pt-br')
        .toFormat('LLL / yy')
        .toUpperCase()
        .replace('.', '');
    }

    return children as string;
  }, [children, foldable]);

  const numberChildren = useMemo(() => /\d/.test(children as string), [children]);

  const handleUnfold = useCallback(
    (action: 'fold' | 'unfold') => () => {
      if (typeof children === 'string') {
        const [year] = (children as string).split('-');

        setFilter(
          'expandedPeriods',
          action === 'fold'
            ? expandedPeriods?.filter((period) => period !== year)
            : [...(expandedPeriods as Array<string>), year],
        );
      }
    },
    [children, expandedPeriods, setFilter],
  );

  const foldValue = useMemo(() => {
    if (!foldable || typeof children !== 'string') return null;

    const [year] = (children as string).split('-');

    return expandedPeriods?.includes(year) ? 'fold' : 'unfold';
  }, [children, expandedPeriods, foldable]);

  const UnfoldToggleIcon = useMemo(() => {
    if (foldValue === null) return null;

    if (foldValue === 'unfold') {
      return <UnfoldMoreIcon fontSize="small" className={iconUnfold} />;
    }

    return <UnfoldLessIcon fontSize="small" className={iconUnfold} />;
  }, [foldValue, iconUnfold]);

  const className = useMemo(() => {
    const classes = [tableCell];
    let classPrevious: string;
    let classCurrent: string;

    if (head) classes.push(tableCellHead);

    if (strong) classes.push(tableCellStrong);

    if (negative) classes.push(tableCellNegative);

    if (previous) {
      classPrevious = tableCellPrevious;

      if (head) classPrevious = tableCellHeadPrevious;
      if (strong) classPrevious = tableCellStrongPrevious;

      classes.push(classPrevious);
    }

    if (current) {
      classCurrent = tableCellCurrent;

      if (head) classCurrent = tableCellHeadCurrent;
      if (strong) classCurrent = tableCellStrongCurrent;

      classes.push(classCurrent);
    }

    if (sticky) {
      classes.push(tableCellStick);
    }

    return classes.join(' ');
  }, [
    current,
    head,
    negative,
    previous,
    sticky,
    strong,
    tableCell,
    tableCellCurrent,
    tableCellHead,
    tableCellHeadCurrent,
    tableCellHeadPrevious,
    tableCellNegative,
    tableCellPrevious,
    tableCellStick,
    tableCellStrong,
    tableCellStrongCurrent,
    tableCellStrongPrevious,
  ]);

  return (
    <MuiTableCell className={className} onClick={foldValue ? handleUnfold(foldValue) : undefined} {...props}>
      <Box display="flex" justifyContent={head ? 'space-between' : 'flex-end'} height="22px" alignContent="center">
        {!head && numberChildren ? (
          <NumericFormat
            displayType="text"
            value={children as string}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={decimalScale}
            fixedDecimalScale
          />
        ) : (
          formattedChildren
        )}
        {UnfoldToggleIcon}
      </Box>
    </MuiTableCell>
  );
};

TableCell.defaultProps = {
  decimalScale: 3,
};

export default TableCell;
