import { MentionsInput, Mention } from 'react-mentions';
import PropTypes from 'prop-types';
import useColorMode from '~/hooks/useColorMode';
import { useEffect, useState } from 'react';

function TextAreaTemplate({ disabled, template, variables, setValue, setTemplate }) {
  const { mode } = useColorMode();

  const [mentionsMarkup, setMentionsMarkup] = useState('{{__display__}}');

  const mentionsData = Object.keys(variables).map((key) => ({ id: variables[key], display: key }));

  useEffect(() => {
    if (disabled) {
      setMentionsMarkup('{{__display__}}');
      setTemplate(template.replaceAll('[mention-editing]{{', '{{'));
    } else {
      setMentionsMarkup('[mention-editing]{{__display__}}');
      setTemplate(template.replaceAll('{{', '[mention-editing]{{'));
    }
  }, [disabled]);

  function handleChange(event, newValue, newPlainTextValue, mentions) {
    setTemplate(newValue);
    setValue(newPlainTextValue);
  }

  const fontColor = (disabled, mode) => {
    const darkMode = mode === 'dark';
    const disabledColor = darkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.38)';
    const color = darkMode ? '#FFFFFF' : 'rgba(0, 0, 0, 0.87)';

    return disabled ? disabledColor : color;
  };

  return (
    <MentionsInput
      autoFocus
      placeholder="Insira o texto descritivo"
      disabled={disabled}
      value={template}
      onChange={handleChange}
      allowSuggestionsAboveCursor={true}
      style={{
        control: {
          background: mode === 'dark' ? '#232323' : '#F7F9FA',
          fontSize: 13,
          fontFamily: 'Roboto',
          fontWeight: 'bold',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.42,
          letterSpacing: 0.6,
          textAlign: 'left',
          width: '100%',
          color: fontColor(disabled, mode),
          visibility: 'visible',
          padding: 0,
        },
        suggestions: {
          list: {
            background: mode === 'dark' ? '#373737' : '#FFF',
          },
          item: {
            padding: '4px 8px',
            '&focused': {
              backgroundColor: 'rgba(0,0,0,0.25)',
            },
          },
        },
      }}
    >
      <Mention
        style={{
          background: disabled ? 'unset' : 'rgba(192, 192, 192, 0.33)',
          borderRadius: '2px',
          position: 'relative',
          left: -4,
          top: -1,
        }}
        trigger="{{"
        data={mentionsData}
        markup={mentionsMarkup}
        displayTransform={(_id, display) => `${variables[display]}`}
      />
    </MentionsInput>
  );
}

TextAreaTemplate.propTypes = {
  disabled: PropTypes.bool,
  template: PropTypes.string,
  variables: PropTypes.object,
  setValue: PropTypes.func,
  setTemplate: PropTypes.func,
};

export default TextAreaTemplate;
