import { memo } from 'react';

import { Theme, useTheme } from '@tradener/lumen';
import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Scatter, ZAxis, Cell, ComposedChart } from 'recharts';

import { parsedScarters } from '../../configs';
import { useProductAnalytics } from '../../useProductAnalytics';

function Scatters({ scartterType, showAxis }: any) {
  const { colors } = useTheme<Theme>();
  const { series } = useProductAnalytics();
  const allScatters = parsedScarters(series);
  const scatters = parsedScarters(series, scartterType);
  const xAxisDomain = [...new Set(allScatters.map(({ timestamp }: any) => timestamp))].sort((a, b) => a - b);
  const yAxisDomain = [...new Set(allScatters.map((item: any) => item?.price && Number(item?.price)))].filter(Boolean);

  if (!scatters?.length) return null;

  return (
    <ResponsiveContainer style={{ position: 'absolute', top: 0 }}>
      <ComposedChart data={scatters} margin={{ top: 30, left: 15, right: 15 }} height={400} syncId="scatterId">
        <CartesianGrid vertical={false} horizontal={false} strokeWidth={0} />
        <XAxis
          xAxisId="top"
          dataKey="timestamp"
          type="category"
          domain={xAxisDomain}
          tickLine={false}
          minTickGap={40}
          orientation="top"
          stroke="transparent"
          padding={{ left: 40, right: 40 }}
          tickFormatter={() => ''}
          hide
        />
        <ZAxis zAxisId="operationMwm" name="Volume negociado" dataKey="operationMwm" type="number" range={[50, 1000]} />
        <YAxis yAxisId="left-hidden" orientation="left" padding={{ top: 35, bottom: 35 }} tickLine={false} tickMargin={25} />
        <YAxis
          yAxisId="right"
          orientation="right"
          type="number"
          domain={[0, Math.max(...yAxisDomain)]}
          interval={0}
          includeHidden
          padding={{ top: 35, bottom: 35 }}
          tickLine={false}
          tickMargin={25}
          tick={{ fill: colors.gray[600], fontSize: '0.875rem' }}
          label={{ value: 'R$', position: 'top' }}
          tickFormatter={(value) => (showAxis === true ? value : '')}
        />
        <Scatter
          name="Operação"
          xAxisId="top"
          zAxisId="operationMwm"
          yAxisId="right"
          dataKey="price"
          shape="circle"
          isAnimationActive={false}
        >
          {scatters?.map((entry, index) => {
            const fillColor =
              entry?.type === 'SALE'
                ? `${colors.orange[200]}3D`
                : entry?.type === 'PURCHASE'
                ? `${colors.blue[400]}3D`
                : `${colors.purple[300]}3D`;

            return <Cell key={`cell-${index}`} fill={fillColor} />;
          })}
        </Scatter>
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default memo(Scatters);
