/* eslint-disable @typescript-eslint/no-empty-interface */
import { gql } from '@apollo/client';
import { ISO8601DateTime } from '@tradener/lumen';

export type Stages = 'opened' | 'denied' | 'proposed' | 'closed' | 'rejected' | 'rejected' | 'canceled' | 'succeeded';

export type Submarkets = 'S' | 'SE' | 'N' | 'NE';

export type PriceTypes = 'FIXO' | 'PERCENT_PLD' | 'SPREAD';

export type OperationTypes = 'PURCHASE' | 'SALE';

export interface CommonProps {
  id: string;
  name: string;
}

export interface QuoteInformationProps {
  id: string;
  name: string;
  type?: string;
  template?: string;
  value: string;
  order: number;
}

export interface ProductProps {
  unit: string;
  shortName: string;
}

export interface CreatedByProps {
  id: string;
  name: string;
}
export interface CreatedByProps extends Partial<CommonProps> {
  email?: string;
}

export interface AccountProps extends CommonProps {}

export interface TenantProps extends CommonProps {}

export interface BbceProductProps extends CommonProps {}

export interface OpportunityProps {
  id: string;
  account?: AccountProps;
  tenant: TenantProps;
  buyer: AccountProps;
  seller: AccountProps;
  channel?: string;
  type: OperationTypes;
  bbceProduct: BbceProductProps;
  bbceProductTransfer?: BbceProductTransferProps;
}

export interface BbceProductTransferProps {
  id: string;
  code: string;
  quantityMwm: number;
  amountTotal?: number;
  bbceProductFrom: BbceProductProps;
  bbceProductTo: BbceProductProps;
  price?: string;
  opportunity?: OpportunityProps;
  quote?: any;
  startDate?: string;
  endDate?: string;
  createdBy?: CreatedByProps;
}

export interface QuoteItemProps {
  id: string;
  startDate: string;
  endDate: string;
  quantity: number;
  amount: number;
}

export interface QuoteProps {
  id: string;
  accountName: string;
  createdBy: CreatedByProps;
  expireDate?: string;
  notes?: string;
  quantity: number;
  price?: string;
  proposalUrl?: string;
  offerCode?: string;
  amountTotal?: number;
  opportunity: OpportunityProps;
  stage: Stages;
  externalId?: string;
  giro?: string;
  swap?: boolean;
  updatedAt?: ISO8601DateTime;
  submarket?: Submarkets;
  energyType?: string;
  quoteItems?: QuoteItemProps[];
}

export interface ProductOperationsProps extends Partial<QuoteProps> {
  code?: string;
  quantityMwm?: number;
  bbceProductFrom?: BbceProductProps;
  bbceProductTo?: BbceProductProps;
  quote?: any;
  startDate?: string;
  endDate?: string;
  __typename: string;
}

export interface ProductQuotesFilterProps {
  endDate?: string;
  startDate?: string;
  restrictIntervalDate?: boolean;
  tenantId?: string;
  internalAccountId?: string;
  userId?: string;
}

export interface PayloadProps {
  productId: string;
  filter?: ProductQuotesFilterProps;
}

export interface ResponseProps {
  productOperations: ProductOperationsProps[];
}

const QUOTE_FRAGMENT = gql`
  fragment productQuotes on Quote {
    id
    accountName
    amountTotal
    expireDate
    id
    notes
    quantity
    price
    proposalUrl
    offerCode
    stage
    externalId
    giro
    swap
    updatedAt
    quoteItems {
      id
      amount
      quantity
      startDate
      endDate
    }
    opportunity {
      id
      account {
        id
        name
      }
      tenant {
        id
        name
      }
      buyer {
        id
        name
      }
      seller {
        id
        name
      }
      type
      channel
      bbceProduct {
        id
        name
      }
    }
    createdBy {
      ... on User {
        id
        name
        email
      }
      ... on ClientApp {
        id
      }
    }
    __typename
  }
`;

const TRANSFER_FRAGMENT = gql`
  fragment productTransfers on BbceProductTransfer {
    id
    code
    quantityMwm
    amountTotal
    quote {
      ...productQuotes
    }
    opportunity {
      id
    }
    bbceProductFrom {
      id
      name
    }
    bbceProductTo {
      id
      name
    }
    startDate
    endDate
    price
    createdBy {
      ... on User {
        id
        name
        email
      }
      ... on ClientApp {
        id
      }
    }
    __typename
  }
`;

const PRODUCT_OPERATIONS = gql`
  ${QUOTE_FRAGMENT}
  ${TRANSFER_FRAGMENT}

  query productOperations($productId: ID!, $filter: ProductQuotesFilter) {
    productOperations(productId: $productId, filter: $filter) {
      ... on Quote {
        ...productQuotes
      }
      ... on BbceProductTransfer {
        ...productTransfers
      }
    }
  }
`;

export default PRODUCT_OPERATIONS;
