import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: calc(100% - 32px);
  overflow-y: auto;
`;

export const Header = styled.ul`
  ${({ theme }) => css`

  ${(theme?.palette?.type === 'light') && `background-color: #FFF` || `background-color: #282828`};

  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-top: 0;
  `}
`;

export const Content = styled.div`
  height: calc(100% - 128px);
  position: relative;
  overflow-y: auto;
`;
