import { MdDone } from 'react-icons/md';

import PropTypes from 'prop-types';

import { Container } from './styles';

function CustomChip({ checked, ...other }) {
  return (
    <Container checked={checked} {...other}>
      <div>
        <MdDone size={16} />
        <span>Comercial</span>
      </div>
    </Container>
  );
}

CustomChip.propTypes = {
  checked: PropTypes.bool.isRequired,
};

export default CustomChip;
