import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import { useProvider } from '~/DEPRECATED/pages/provider';

import OfferProgressOperation, { OperationValue } from './OfferProgressOperation';
import { Container } from './styles';

type QuoteStats = {
  purchase: OperationValue;
  sale: OperationValue;
};

type QuoteStatsData = {
  quoteStats: QuoteStats | undefined;
};

type QuoteStatsVars = {
  internalAccounts?: string[];
};

export const GET_QUOTE_STATS = gql`
  query quoteStats($internalAccounts: [String!]) {
    quoteStats(filter: { internalAccounts: $internalAccounts }) {
      purchase {
        opened
        negotiated
      }
      sale {
        opened
        negotiated
      }
    }
  }
`;

const OfferProgress = () => {
  const { internalAccounts } = useProvider();

  const { data } = useQuery<QuoteStatsData, QuoteStatsVars>(GET_QUOTE_STATS, {
    variables: {
      internalAccounts,
    },
  });

  return (
    <Container>
      <OfferProgressOperation title="Oportunidades de Compra" color="#2558F8" data={data?.quoteStats?.purchase} />
      <OfferProgressOperation title="Oportunidades de Venda" color="#EB6100" data={data?.quoteStats?.sale} />
    </Container>
  );
};

export default OfferProgress;
