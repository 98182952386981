import { gql } from '@apollo/client';

export interface SeriesProps {
  competence: string;
  balanceMwm?: number;
  saleMwm?: number;
  purchaseMwm?: number;
  averageSalePrice?: number;
  averagePurchasePrice?: number;
  pldPrice?: number;
  pldResult?: number;
  pldResultAccumulated?: number;
  marketResult?: number;
  marketResultAccumulated?: number;
  finalResult?: number;
  finalResultAccumulated?: number;
}

export interface BalanceResultsProps {
  series: SeriesProps[];
}

export interface PayloadProps {
  startDate: string;
  endDate: string;
  internalAccountId: string;
  userId?: string;
}

export interface ResponseProps {
  balanceResults: BalanceResultsProps;
}

const BALANCE_RESULTS = gql`
  query balanceResults($startDate: ISO8601Date!, $endDate: ISO8601Date!, $internalAccountId: String!, $userId: ID) {
    balanceResults(startDate: $startDate, endDate: $endDate, internalAccountId: $internalAccountId, userId: $userId) {
      series {
        competence
        balanceMwm
        saleMwm
        purchaseMwm
        averageSalePrice
        averagePurchasePrice
        pldPrice
        pldResult
        pldResultAccumulated
        marketResult
        marketResultAccumulated
        finalResult
        finalResultAccumulated
      }
    }
  }
`;

export default BALANCE_RESULTS;
