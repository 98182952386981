import { gql } from '@apollo/client';

export interface UserProps {
  id: string;
  name: string;
}

export interface SimulationProps {
  id: string;
  name: string;
  user: UserProps;
  createdAt: string;
  updatedAt: string;
}

export interface PayloadProps {
  search?: string;
  shared?: boolean;
}

export interface ResponseProps {
  simulations: SimulationProps[];
}

const SIMULATIONS = gql`
  query simulations($search: String, $shared: Boolean) {
    simulations(search: $search, shared: $shared) {
      id
      name
      user {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

export default SIMULATIONS;
