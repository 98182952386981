import { ThumbDownIcon } from '@tradener/lumen';

import OptionItem from '../../OptionItem';
import useChangeQuoteStage from '../../useChangeQuoteStage';
import { useQuoteOptions } from '../../useQuoteOptions';

function ActionReject() {
  const changeQuoteStage = useChangeQuoteStage();
  const { onOpen } = useQuoteOptions();

  const openDialog = () => {
    onOpen('REJECT', changeQuoteStage);
  };

  return (
    <OptionItem stage={['OPENED', 'PROPOSED']} onClick={openDialog} icon={<ThumbDownIcon boxSize="5" />}>
      Rejeitar
    </OptionItem>
  );
}

export default ActionReject;
