import { Action, Reducer } from 'redux';

export enum ActionTypes {
  TOGGLE = 'COLOR-MODE/TOGGLE',
  RESET = 'COLOR-MODE/RESET',
}

export interface StateProps {
  mode: 'light' | 'dark';
}

const initialState: StateProps = {
  mode: 'dark',
};

const reducer: Reducer<StateProps, Action<ActionTypes>> = (state = initialState, { type }) => {
  switch (type) {
    case ActionTypes.TOGGLE:
      return { mode: state.mode === 'light' ? 'dark' : 'light' };
    case ActionTypes.RESET:
      return initialState;
    default:
      return state;
  }
};

export const actions = {
  toggle: () => ({ type: ActionTypes.TOGGLE }),
  reset: () => ({ type: ActionTypes.RESET }),
};

export default reducer;
