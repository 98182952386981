import { useEffect, useRef } from 'react';

import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@material-ui/core';
import { Button, AddIcon, Flex, deepCompare } from '@tradener/lumen';
import { nanoid } from 'nanoid';
import * as Yup from 'yup';

import UPSERT_SIMULATION_OPERATIONS, { ResponseProps, PayloadProps } from '@graphql/mutation/upsertSimulationOperations';
import SIMULATION, { SimulationOperationsProps, ResponseProps as SimulationResponseProps } from '@graphql/query/simulation';

import useUpdateEffect from '~/hooks/useUpdateEffect';
import useSimulation from '~/pages/Portfolio/useSimulation';
import useSimulationRules from '~/pages/Portfolio/useSimulationRules';

import useSubmitQueue from '../../context';
import usePeriods from '../../hooks/usePeriods';
import SimulationSection from '../SimulationSection';
import { TableHorizontalAxis } from '../Table';
import { useSimulationTable } from '../withSimulationTable';
import Operation from './Operation';
import mergeOperations from './mergeOperations';
import mergePeriods from './mergePeriods';
import withGuarantee, { FormProps, Context, OperationsProps } from './withGuarantee';

const schema = Yup.object({
  operations: Yup.array().of(
    Yup.object({
      simulationItems: Yup.array().when('id', {
        is: (value: string) => /temp_/.test(value),
        then: (schema) =>
          schema.test(
            'simulationItems',
            'quantityMwm and price are required',
            (value) => !!value?.some(({ price, quantityMwm }) => price && quantityMwm),
          ),
      }),
    }),
  ),
});

function SimulationOperations() {
  const { simulationId } = useSimulation();
  const { isReadOnly } = useSimulationRules();
  const { simulationOperations } = useSimulationTable();
  const { checkSubmitEnqueued, enqueue, unenqueue } = useSubmitQueue();
  const [updateSimulationOperations, { client }] = useMutation<ResponseProps, PayloadProps>(UPSERT_SIMULATION_OPERATIONS);
  const { periods } = usePeriods();
  const dataRef = useRef(mergePeriods(simulationOperations ?? [], periods));

  const { control, formState, reset, getValues, watch, ...methods } = useForm<FormProps>({
    resolver: yupResolver(schema),
    defaultValues: { operations: dataRef.current },
    mode: 'all',
  });

  const { append, fields } = useFieldArray({ control, name: 'operations', keyName: 'key' });

  const handleAddMore = () => {
    append([
      {
        id: `temp_${nanoid()}`,
        energyType: 'C',
        operation: 'PURCHASE',
        submarket: 'SE',
        priceType: 'FIXO',
        simulationItems: [],
      } as any,
    ]);
  };

  useUpdateEffect(() => {
    const operations = getValues('operations');

    if (formState.isValid && formState.isDirty) {
      enqueue({
        name: 'simulation',
        async onSubmit() {
          if (simulationId) {
            if (!simulationOperations) return;

            const updatedOperations = mergeOperations(periods, simulationOperations, operations);

            if (updatedOperations.length) {
              const data = await new Promise<SimulationResponseProps>((resolve, reject) => {
                updateSimulationOperations({
                  variables: {
                    input: {
                      simulationId,
                      simulationOperations: updatedOperations,
                    },
                  },
                  async onCompleted() {
                    const [{ data }] = await client.refetchQueries({ include: [SIMULATION] });

                    resolve(data);
                  },
                  onError: (exception) => {
                    toast.error(exception.message);

                    reject(exception);
                  },
                });
              });

              if (data?.simulation) {
                const merged = mergePeriods(data.simulation.simulationOperations ?? [], periods);

                reset({ operations: merged });

                dataRef.current = merged as SimulationOperationsProps[];

                toast.success('Operação atualizada com sucesso!');
              }
            }
          }
        },
        onCancel: () => reset({ operations: dataRef.current }, { keepDirty: false }),
      });
    }
  }, [
    client,
    enqueue,
    formState.isDirty,
    formState.isValid,
    getValues,
    periods,
    reset,
    simulationId,
    simulationOperations,
    updateSimulationOperations,
  ]);

  useUpdateEffect(() => {
    const isEnqueued = checkSubmitEnqueued('simulation');
    const isFieldsCleaned = !Object.values(formState.dirtyFields).length;
    const isFormInvalid = !formState.isValid;

    if ((isFormInvalid || isFieldsCleaned) && isEnqueued) {
      unenqueue('simulation');
    }
  }, [formState.dirtyFields, formState.isValid, checkSubmitEnqueued, unenqueue]);

  useEffect(() => {
    const subscribe = watch(({ operations }) => {
      const isEnqueued = checkSubmitEnqueued('simulation');

      if (isEnqueued && deepCompare(operations, dataRef.current)) {
        unenqueue('simulation');
        reset({ operations });
      }
    });

    return () => {
      subscribe.unsubscribe();
    };
  }, [checkSubmitEnqueued, reset, unenqueue, watch]);

  return (
    <>
      <SimulationSection
        label={
          <Flex columnGap="3" alignItems="center">
            Simulação
            {!isReadOnly && (
              <Button leftIcon={<AddIcon boxSize="4" />} onClick={handleAddMore} size="xs" fontSize="sm" colorScheme="orange">
                Operação
              </Button>
            )}
          </Flex>
        }
      />
      <TableHorizontalAxis flexDirection="column">
        {fields.length ? (
          <FormProvider {...{ ...methods, control, formState, reset, getValues, watch }}>
            {fields.map((operation, index) => (
              <Context.Provider key={operation.id} value={{ operation, index } as OperationsProps}>
                <Operation data-testid="operation-row" />
              </Context.Provider>
            ))}
          </FormProvider>
        ) : (
          <Box position="sticky" left="0" display="flex" alignItems="center" justifyContent="center" height="80px" width="90vw">
            <Typography variant="body1" color="secondary">
              Nenhuma operação criada
            </Typography>
          </Box>
        )}
      </TableHorizontalAxis>
    </>
  );
}

export default withGuarantee(SimulationOperations) as () => JSX.Element;
