import styled from 'styled-components';

export const Container = styled.div`
  width: 600px;
  height: 568px;
  padding: 32px;
`;

export const Header = styled.div`
  margin: 16px 0;

  h5 {
    font-weight: 500;
    letter-spacing: 0.9px;
  }
`;

export const Content = styled.div`
  min-height: 368px;
  height: calc(100% - 120px);
  overflow-y: scroll;
`;

export const Footer = styled.div`
  margin-top: 10px;

  hr {
    margin-bottom: 24px;
    height: 2px;
  }

  div {
    display: flex;
    justify-content: space-between;

    button,
    a {
      width: 240px;
      border-radius: 2px;
    }
  }
`;
