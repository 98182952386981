import { DeleteIcon } from '@tradener/lumen';

import OptionItem from '../../OptionItem';
import useChangeQuoteStage from '../../useChangeQuoteStage';
import { useQuoteOptions } from '../../useQuoteOptions';

function ActionCancel() {
  const changeQuoteStage = useChangeQuoteStage();
  const { onOpen } = useQuoteOptions();

  const openDialog = () => {
    onOpen('CANCEL', changeQuoteStage);
  };

  return (
    <OptionItem data-testid="cancel" onClick={openDialog} icon={<DeleteIcon boxSize="5" />}>
      Cancelar
    </OptionItem>
  );
}

export default ActionCancel;
