import { AddCircleIcon } from '@tradener/lumen';

import OptionItem from '../../OptionItem';
import { useQuoteOptions } from '../../useQuoteOptions';

function ActionComplement() {
  const {
    complementDialog: { onOpen },
  } = useQuoteOptions();

  return (
    <OptionItem data-testid="complement" onClick={onOpen} icon={<AddCircleIcon boxSize="5" />}>
      Complemento
    </OptionItem>
  );
}

export default ActionComplement;
