import { toast } from 'react-toastify';

import { useLazyQuery } from '@apollo/client';

import DOWNLOAD_PORTFOLIO_DATA, { ResponseProps, PayloadProps } from '@graphql/query/downloadPortfolioData';

import usePortfolio, { getVariables } from './usePortfolio';
import useSimulation from './useSimulation';
import useSimulationRules from './useSimulationRules';

function useDownloadPortfolioData() {
  const { isPortfolio } = useSimulationRules();
  const portfolio = usePortfolio();
  const {
    tenantId: _,
    expandedPeriods,
    hasGiro,
    periodType,
    closeDateLte,
    closeDateGte,
    accountingPortfolio,
    ...rest
  } = getVariables(portfolio);
  const variables = { ...rest, expandedPeriods, excludedContracts: Object.keys(portfolio.excludedContracts) };
  const { portfolioId, simulationId } = useSimulation();
  const id = isPortfolio ? portfolioId : simulationId;

  const [download, { loading }] = useLazyQuery<ResponseProps, PayloadProps>(DOWNLOAD_PORTFOLIO_DATA, {
    nextFetchPolicy: 'standby',
    onCompleted: ({ downloadPortfolioData }) => {
      window.open(downloadPortfolioData.downloadUrl, '_self');
    },
    onError: ({ message }) => toast.error(message),
  });

  const downloadUrl = () => {
    download({
      variables: {
        ...variables,
        simulationId: id as string,
        hasGiro,
        periodType,
        closeDateLte,
        closeDateGte,
        accountingPortfolio,
      },
    });
  };

  return { downloadPortfolio: downloadUrl, loadingDownloadPortfolio: loading };
}

export default useDownloadPortfolioData;
