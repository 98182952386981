import { useEffect } from 'react';

import { NavLink } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { Button, Flex, Text, SwapVertIcon, pickByPaths } from '@tradener/lumen';

import BBCE_PRODUCTS, { ResponseProps, PayloadProps, BbceProductProps } from '@graphql/query/bbceProducts';
import UPDATE_BBCE_PRODUCTS, { ResponseProps as UpdateResponseProps } from '@graphql/subscription/updateBbceProduct';

import ListSearch from '~/pages/Trading/TradingList/ListSearch';
import Period from '~/pages/Trading/TradingList/Period';

import Header from '../../../theme/layout/Header';
import useTrading, { usePagination } from '../useTrading';
import InternalAccounts from './InternalAccounts';
import ListProducts from './ListProducts';
import ListSkeleton from './ListSkeleton';
import Users from './Users';

const staging = process.env.REACT_APP_ROLLBAR_ENV === 'staging';
const minListHeight = !staging ? 'calc(100dvh - 9.25rem)' : 'calc(100dvh - 9.25rem - 2.3125rem)';

function TradingList() {
  const { filter, ...state } = useTrading();
  const { limit, offset } = usePagination();
  // TODO const routes = useRoutes([{ path: 'settings', element: <Settings /> }]);

  const { subscribeToMore, loading, previousData } = useQuery<ResponseProps, PayloadProps>(BBCE_PRODUCTS, {
    skip: !filter?.internalAccountId || !filter?.startDate || !filter?.endDate,
    variables: pickByPaths({ ...state, limit, offset, filter }, 'filter', 'limit', 'offset'),
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      variables: {
        filter: pickByPaths(filter, 'internalAccountId', 'userId', 'restrictIntervalDate', 'startDate', 'endDate'),
        limit,
        offset,
      },
      document: UPDATE_BBCE_PRODUCTS,
      updateQuery: (prev, { subscriptionData }: SubscriptionData<UpdateResponseProps>) => {
        if (!subscriptionData.data) return prev;

        const getDesc = (current: BbceProductProps, next: BbceProductProps) => {
          if (current.totalOffers === next.totalOffers) {
            return Math.abs(current.balanceVolume) > Math.abs(next.balanceVolume) ? -1 : 0;
          }

          return current.totalOffers > next.totalOffers ? -1 : 0;
        };

        const updateProduct = (products: BbceProductProps[], append = false) => {
          const index = products.findIndex(({ id }) => id === subscriptionData.data.updateBbceProduct.id);

          if (!append) {
            if (index > -1) {
              products.splice(index, 1, subscriptionData.data.updateBbceProduct);
            }

            return products;
          }

          if (index > -1) products.splice(index, 1);

          return [subscriptionData.data.updateBbceProduct, ...products];
        };

        return {
          bbceProducts: {
            pinneds: updateProduct(prev.bbceProducts.pinneds),
            products: updateProduct(prev.bbceProducts.products, true).sort(getDesc),
          },
        };
      },
    });

    return () => {
      unsubscribe();
    };
  }, [filter, limit, offset, subscribeToMore]);

  return (
    <Flex flexDirection="column" maxWidth="calc(100dvw - 4.5rem)" width="full" height="full">
      <Header px="4" columnGap="3" align="center">
        <InternalAccounts />
        <Period />
        <Users />
        {/* TODO <IconButton as={NavLink} to="settings" aria-label="open configuration" variant="ghost" colorScheme="gray">
          <SettingsIcon />
        </IconButton> */}
      </Header>
      <Flex direction="column" rowGap="6" width="full" padding="5">
        <Flex justify="space-between" align="center" gap="2">
          <Text textStyle="subheading1-semibold">Produtos</Text>
          <Flex direction="row" gap="3" width="fit-content">
            <ListSearch />
            <Button as={NavLink} to="/trading/list/offer?type=create" size="sm" leftIcon={<SwapVertIcon />}>
              Negociar
            </Button>
          </Flex>
        </Flex>
        <Flex direction="column" rowGap="2" width="calc(100dvw - 7.5rem)" borderRadius="xl" overflowX="scroll" flex="1">
          <Flex direction="column" rowGap="2" width="fit-content" flex="1" height="auto" minHeight={minListHeight}>
            {loading &&
            (!previousData ||
              (offset === 0 && previousData?.bbceProducts?.pinneds?.length === filter?.pinnedIds?.filter(Boolean)?.length)) ? (
              <ListSkeleton />
            ) : (
              <ListProducts />
            )}
          </Flex>
        </Flex>
      </Flex>
      {/* TODO {routes} */}
    </Flex>
  );
}

export default TradingList;
