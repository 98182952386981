import { createIcon } from "@tradener/lumen";

const DashedSquareIcon = createIcon({
  displayName: 'DashedSquareIcon',
  viewBox: '0 0 13 12',
  path: (
    <>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.792893 4.20711C0.402369 3.81658 0.402369 3.18342 0.792893 2.79289L2.79289 0.792893C3.18342 0.402369 3.81658 0.402369 4.20711 0.792893C4.59763 1.18342 4.59763 1.81658 4.20711 2.20711L2.20711 4.20711C1.81658 4.59763 1.18342 4.59763 0.792893 4.20711ZM1.29289 10.9571C0.902369 10.5666 0.902369 9.93342 1.29289 9.54289L9.29289 1.54289C9.68342 1.15237 10.3166 1.15237 10.7071 1.54289C11.0976 1.93342 11.0976 2.56658 10.7071 2.95711L2.70711 10.9571C2.31658 11.3476 1.68342 11.3476 1.29289 10.9571ZM7.79289 10.0429C7.40237 10.4334 7.40237 11.0666 7.79289 11.4571C8.18342 11.8476 8.81658 11.8476 9.20711 11.4571L11.2071 9.45711C11.5976 9.06658 11.5976 8.43342 11.2071 8.04289C10.8166 7.65237 10.1834 7.65237 9.79289 8.04289L7.79289 10.0429Z" fill="currentColor"/>
    </>
  )
});

export default DashedSquareIcon;
