import { useState, useEffect } from 'react';

import Loader from '@DEPRECATED/components/Loader';
import Widget from '@DEPRECATED/components/Widget';
import WidgetContent from '@DEPRECATED/components/WidgetContent';
import WidgetHeader from '@DEPRECATED/components/WidgetHeader';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import useUndo from 'use-undo';

import Header from '~/theme/layout/Header';

import { useProvider, withProvider } from '../provider';
import Balance from './Balance';
import BalanceMonth from './Balance/BalanceMonth';
import BoxStatusInfo from './BoxStatusInfo';
import Dre from './Dre';
import Operation from './Operation';
import PortfolioFilter from './PortfolioFilter';
import PurchaseAndSale from './PurchaseAndSale';
import Submarket from './Submarket';
import { Container, StatusInfo } from './styles';

export const PORTFOLIO_SUMMARY = gql`
  query portfolioSummary($period: Int, $internalAccounts: [String!]) {
    portfolioSummary(filter: { period: $period, internalAccounts: $internalAccounts }) {
      period
      balanceMwm
      pld
      purchase {
        ...OperationFields
      }
      sale {
        ...OperationFields
      }
      submarket {
        ...SubmarketGroupFields
      }
      dre {
        ...Dre
      }
      months {
        ...BalanceFields
        ...EnergyBallast
        pld
        sale {
          ...OperationFields
        }
        purchase {
          ...OperationFields
        }
        dre {
          ...Dre
        }
        submarket {
          ...SubmarketGroupFields
        }
      }
    }
  }
  ${Submarket.fragments.submarket}
  ${BalanceMonth.fragments.balance}
  ${BoxStatusInfo.fragments.energyBallast}
  ${Operation.fragments.operation}
  ${Dre.fragments.dre}
`;

function Portfolio() {
  const { internalAccounts, setInternalAccounts, skip } = useProvider();
  const [period, setPeriod] = useState(new Date());
  const [periodSelected, setPeriodSelected] = useState(period.toISOString());
  const [isAveragePrice, setIsAveragePrice] = useState(false);
  const [{ present, past }, { canUndo, set, undo, reset }] = useUndo(null);

  const subtitle = isAveragePrice ? '(R$/MWh)' : '(MWm)';

  const [getSummary, { loading }] = useLazyQuery(PORTFOLIO_SUMMARY, {
    onCompleted: ({ portfolioSummary }) => reset(portfolioSummary[0]),
  });

  const onSwitchPeriod = (position) => {
    if (canUndo) return undo();
    if (!position) return null;

    setPeriodSelected(present.months[position].period);

    return set(present.months[position]);
  };

  const handleFilter = (internalAccounts) => {
    setInternalAccounts(internalAccounts.length ? internalAccounts : []);
  };

  useEffect(() => {
    if (!skip) {
      getSummary({
        variables: {
          period: period.getFullYear(),
          internalAccounts,
        },
      });
      setPeriodSelected(period.toISOString());
    }
  }, [getSummary, period, skip, internalAccounts]);

  return (
    <>
      {loading && <Loader />}
      <Header style={{ paddingRight: '16px' }}>
        <PortfolioFilter
          onChange={handleFilter}
          onChangeConversion={setIsAveragePrice}
          currentConversion={isAveragePrice}
          period={periodSelected}
          isMonth={!!past.length}
        />
      </Header>
      <Container>
        <StatusInfo>
          <BoxStatusInfo data={present} hidden={false} />
          <Widget>
            <WidgetHeader title={isAveragePrice ? 'Preço Médio' : 'Balanço'} subtitle={subtitle} />
            <WidgetContent>
              <Balance
                data={!past.length ? present : past[0]}
                isAveragePrice={isAveragePrice}
                onSwitch={onSwitchPeriod}
                onChangePeriod={setPeriod}
              />
            </WidgetContent>
          </Widget>
        </StatusInfo>
        <Widget>
          <WidgetHeader title={isAveragePrice ? 'Preço por Submercado' : 'Saldo por Submercado'} subtitle={subtitle} />
          <WidgetContent>
            <Submarket data={present?.submarket} isAveragePrice={isAveragePrice} />
          </WidgetContent>
        </Widget>
        <PurchaseAndSale subtitle={subtitle} data={present} isAveragePrice={isAveragePrice} />
        <Widget>
          <WidgetHeader title="DRE" subtitle="(R$)" />
          <Dre data={present?.dre} />
        </Widget>
      </Container>
    </>
  );
}

export default withProvider(Portfolio);
