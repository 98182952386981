import { useCallback } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress, InputAdornment, InputLabel, Typography } from '@material-ui/core';
import { Select, Option, parseSafeFloat } from '@tradener/lumen';
import * as Yup from 'yup';

import UPSERT_SIMULATION_OPERATIONS, {
  ResponseProps,
  PayloadProps,
  Submarkets,
} from '@graphql/mutation/upsertSimulationOperations';
import { EnergyTypes } from '@graphql/query/contracts';

import useColorMode from '~/hooks/useColorMode';
import client from '~/services/apollo';
import Chip from '~/theme/components/Chip';
import FilledInput from '~/theme/components/FilledInput';
import Modal, { ModalBody, ModalHeader, ModalFooter, ModalProps } from '~/theme/components/Modal';
import MonthRange, { DateYearMonthProps } from '~/theme/components/MonthRange';
import calculateMonthRange from '~/utils/calculateMonthRange';

import useSimulation from '../../useSimulation';
import { SubheaderDirectives, useModalContext } from '../SimulationSubheader';

export interface OperationProps {
  price: number;
  quantityMwm: number;
  operation: 'PURCHASE' | 'SALE';
  energyType: EnergyTypes;
  submarket: Submarkets;
  competence: Array<string>;
  priceType: 'FIXO' | 'SPREAD';
}

export interface NewOperationModalProps extends Omit<ModalProps, 'isOpen' | 'onClose'> {
  id: SubheaderDirectives;
}

const schema = Yup.object({
  price: Yup.number().moreThan(0).required('Preço é obrigatório'),
  quantityMwm: Yup.number().moreThan(0).required('Quantidade MWm é obrigatório'),
  priceType: Yup.string().required('Tipo de preço é obrigatório'),
  operation: Yup.string().required('Operação é obrigatório'),
  energyType: Yup.string().required('Tipo de energia é obrigatório'),
  submarket: Yup.string().required('Submercado é obrigatório'),
  competence: Yup.array().min(1).required('Competência é obrigatório'),
}).required();

const SimulationOperation: React.ComponentType<NewOperationModalProps> = (props) => {
  const { colorTernary } = useColorMode();
  const { simulationId } = useSimulation();
  const { onClose } = useModalContext();
  const { handleSubmit, setValue, control, formState } = useForm<OperationProps>({
    resolver: yupResolver(schema),
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: {
      operation: 'PURCHASE',
      price: 0.0,
      quantityMwm: 0.0,
      competence: [],
      priceType: 'FIXO',
    },
  });

  const [upsertSimulationOperations, { loading }] = useMutation<ResponseProps, PayloadProps>(UPSERT_SIMULATION_OPERATIONS, {
    onCompleted: async ({ upsertSimulationOperations }: ResponseProps) => {
      if (upsertSimulationOperations?.upsertSimulationOperations) {
        toast.success('Operação criada com sucesso!');
        await client.refetchQueries({
          include: ['simulation'],
        });

        client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'simulation' });
      } else {
        toast.error(upsertSimulationOperations?.errors?.join('\n') ?? 'Erro ao criar a operação.');
      }
    },
  });

  const getMonthRange = useCallback(
    (monthRange: DateYearMonthProps) => {
      const dates = calculateMonthRange(monthRange);

      if (monthRange.startYear && monthRange.startMonth && monthRange.endYear && monthRange.endMonth)
        setValue('competence', dates, { shouldDirty: true, shouldValidate: true });
    },
    [setValue],
  );

  const onSubmit = async ({ energyType, operation, submarket, competence, priceType, ...data }: OperationProps) => {
    await upsertSimulationOperations({
      variables: {
        input: {
          simulationId,
          simulationOperations: [
            {
              energyType,
              operation,
              submarket,
              priceType,
              simulationItems: competence.map((competence) => ({
                competence,
                ...data,
              })),
            },
          ],
        },
      },
    });
    onClose();
  };

  return (
    <Modal onClose={onClose} isOpen {...props}>
      <ModalHeader style={{ height: '64px' }}>
        <Typography variant="h5" style={{ fontWeight: 600 }}>
          Nova operação
        </Typography>
      </ModalHeader>
      <ModalBody style={{ padding: '0 24px' }}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
            width: '100%',
            rowGap: '20px',
          }}
        >
          <Box display="flex" flexDirection="row" alignItems="center" alignSelf="flex-start" style={{ columnGap: '5px' }}>
            <Controller
              control={control}
              name="operation"
              rules={{ required: true }}
              render={({ field: { onChange, value, ...field } }) => (
                <>
                  {['PURCHASE', 'SALE'].map((operation) => (
                    <Chip
                      key={operation}
                      label={operation === 'PURCHASE' ? 'Compra' : 'Venda'}
                      color={value === operation ? 'primary' : 'secondary'}
                      className="unstyled"
                      onClick={() => onChange(operation, { shouldDirty: true, shouldValidate: true })}
                      style={
                        value === operation
                          ? { width: '90px', height: '36px', fontSize: '16px', textTransform: 'capitalize' }
                          : {
                              width: '90px',
                              height: '36px',
                              fontSize: '16px',
                              textTransform: 'capitalize',
                            }
                      }
                      {...field}
                    />
                  ))}
                </>
              )}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            style={{ columnGap: '20px' }}
          >
            <Box display="flex" flexDirection="column" alignItems="center" style={{ rowGap: '6px' }}>
              <InputLabel
                htmlFor="period"
                style={{ color: `${colorTernary('#d9d9d9', '#262626')}`, fontSize: '14px', alignSelf: 'flex-start' }}
              >
                Período de fornecimento
              </InputLabel>
              <MonthRange id="period" onChange={getMonthRange} style={{ width: '226px' }} />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" style={{ rowGap: '6px' }}>
              <InputLabel
                htmlFor="quantityMwm"
                style={{ color: `${colorTernary('#d9d9d9', '#262626')}`, fontSize: '14px', alignSelf: 'flex-start' }}
              >
                Volume médio
              </InputLabel>
              <Controller
                name="quantityMwm"
                control={control}
                render={({ field: { onChange, ref, value: _, ...field } }) => (
                  <NumericFormat
                    customInput={FilledInput}
                    getInputRef={ref}
                    placeholder="0,000000"
                    decimalScale={6}
                    allowNegative={false}
                    thousandSeparator="."
                    decimalSeparator=","
                    onValueChange={({ value }) => onChange(parseSafeFloat(value), { shouldDirty: true, shouldValidate: true })}
                    endAdornment={<InputAdornment position="end">MWm</InputAdornment>}
                    style={{ width: '226px' }}
                    fixedDecimalScale
                    allowLeadingZeros
                    {...field}
                  />
                )}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            style={{ columnGap: '20px' }}
          >
            <Box display="flex" flexDirection="column" alignItems="center" style={{ rowGap: '6px' }} flex="1">
              <InputLabel
                htmlFor="priceType"
                style={{ color: `${colorTernary('#d9d9d9', '#262626')}`, fontSize: '14px', alignSelf: 'flex-start' }}
              >
                Tipo de preço
              </InputLabel>
              <Controller
                control={control}
                name="priceType"
                render={({ field: { ref: _, ...field } }) => (
                  <Select
                    placeholder="Tipo de preço"
                    borderRadius="base"
                    width="full"
                    closeOnSelect={true}
                    isToggleable={false}
                    gap="2.5"
                    isSmall
                    {...field}
                  >
                    <Option value="FIXO" width="13.125rem">
                      Fixo
                    </Option>
                    <Option value="SPREAD" width="13.125rem">
                      Spread
                    </Option>
                  </Select>
                )}
              />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" style={{ rowGap: '6px' }}>
              <InputLabel
                htmlFor="price"
                style={{ color: `${colorTernary('#d9d9d9', '#262626')}`, fontSize: '14px', alignSelf: 'flex-start' }}
              >
                Preço
              </InputLabel>
              <Controller
                name="price"
                control={control}
                render={({ field: { onChange, ref, value: _, ...field } }) => (
                  <NumericFormat
                    customInput={FilledInput}
                    getInputRef={ref}
                    placeholder="0,00"
                    decimalScale={2}
                    allowNegative={false}
                    thousandSeparator="."
                    decimalSeparator=","
                    onValueChange={({ value }) => onChange(parseSafeFloat(value), { shouldDirty: true, shouldValidate: true })}
                    endAdornment={<InputAdornment position="end">R$</InputAdornment>}
                    style={{ width: '226px' }}
                    fixedDecimalScale
                    {...field}
                  />
                )}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="space-between"
            width="100%"
            style={{ rowGap: '8px' }}
          >
            <InputLabel htmlFor="energyType" style={{ color: `${colorTernary('#d9d9d9', '#262626')}`, fontSize: '14px' }}>
              Tipo de energia
            </InputLabel>
            <Box display="flex" flexDirection="row" style={{ columnGap: '8px' }}>
              <Controller
                control={control}
                name="energyType"
                rules={{ required: true }}
                render={({ field: { onChange, value, ...field } }) => (
                  <>
                    {['C', 'I0', 'I5', 'I8', 'I1', 'INE5'].map((type) => (
                      <Chip
                        key={type}
                        label={type}
                        color={value === type ? 'primary' : 'secondary'}
                        onClick={() => onChange(type, { shouldDirty: true, shouldValidate: true })}
                        style={{ minWidth: '45px', width: 'fit-content', height: '32px' }}
                        {...field}
                      />
                    ))}
                  </>
                )}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            style={{ columnGap: '20px' }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
              height="60px"
              style={{ rowGap: '8px' }}
            >
              <InputLabel htmlFor="energyType" style={{ color: `${colorTernary('#d9d9d9', '#262626')}`, fontSize: '14px' }}>
                Submercado
              </InputLabel>
              <Box display="flex" flexDirection="row" style={{ columnGap: '8px' }}>
                <Controller
                  control={control}
                  name="submarket"
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ...field } }) => (
                    <>
                      {['SE', 'S', 'NE', 'N'].map((submarket) => (
                        <Chip
                          key={submarket}
                          label={submarket}
                          color={value === submarket ? 'primary' : 'secondary'}
                          onClick={() => onChange(submarket, { shouldDirty: true, shouldValidate: true })}
                          style={{ minWidth: '45px', width: 'fit-content', height: '32px' }}
                          {...field}
                        />
                      ))}
                    </>
                  )}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter style={{ display: 'flex', gridColumnGap: '12px', padding: '24px' }}>
        <Button
          variant="outlined"
          color="secondary"
          style={{ color: `${colorTernary('#d9d9d9', '#262626')}` }}
          onClick={onClose}
          disableElevation
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={loading || !formState.isValid}
          style={{ marginLeft: 0 }}
          onClick={handleSubmit(onSubmit)}
          disableElevation
        >
          {!loading ? 'Salvar' : <CircularProgress size={22} color="secondary" />}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SimulationOperation;
