import { Flex } from '@tradener/lumen';

import LeftContent from './LeftContent';
import RightContent from './RightContent';

function ActivitiesTab() {
  return (
    <Flex align="flex-start" gap="6" height="2xl" padding="2" width="full">
      <LeftContent />
      <RightContent />
    </Flex>
  );
}

export default ActivitiesTab;
