import { useState } from 'react';

import MultipleSelect from '@DEPRECATED/components/Form/MultipleSelect';
import difference from 'lodash/difference';
import union from 'lodash/union';

import { useProvider } from '~/DEPRECATED/pages/provider';

const SelectInternalAccounts = () => {
  const { allowedInternalAccounts, internalAccounts, setInternalAccounts } = useProvider();
  const [currentInternalAccounts, setCurrentInternalAccounts] = useState(internalAccounts);

  function handleChange({ target: { value } }) {
    if (value.includes('all') && !currentInternalAccounts.includes('all')) {
      value = ['all'];
    } else {
      value = difference(value, ['all']);
    }

    setCurrentInternalAccounts(value);
  }

  function handleClose() {
    const diff = difference(currentInternalAccounts, ['all']);

    setInternalAccounts(diff.length ? diff : allowedInternalAccounts.map(({ accountId: id }) => id));
  }

  return (
    <MultipleSelect
      value={currentInternalAccounts}
      options={union(
        [{ value: 'all', label: 'TODOS' }],
        allowedInternalAccounts.map(({ accountId: value, name: label }) => ({ value, label })),
      )}
      onChange={handleChange}
      onClose={handleClose}
      style={{ width: '125px', height: '40px' }}
    />
  );
};

export default SelectInternalAccounts;
