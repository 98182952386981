import { useState, useMemo } from 'react';

import { Box, Accordion, Typography, AccordionProps, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

import usePortfolio, { PortfolioProps } from '~/pages/Portfolio/usePortfolio';
import Switch from '~/theme/components/Switch';

import useStyles from './styles';

export interface SummaryProps extends Omit<AccordionProps, 'children'>, React.PropsWithChildren {
  label: string;
  filter: keyof PortfolioProps | Array<keyof PortfolioProps>;
  onDisable?: () => void;
}

const Summary: React.ComponentType<SummaryProps> = ({ children, label, filter, onDisable, ...props }) => {
  const { wrapper, expanded, text, summary, content, details } = useStyles();
  const [isExpanded, setExpanded] = useState(!!props?.defaultExpanded);
  const { resetField, ...state } = usePortfolio();

  const current = useMemo(() => {
    if (Array.isArray(filter)) return filter.filter((current) => state[current]);

    return state?.[filter];
  }, [filter, state]);

  const enabled =
    /boolean|number/.test(typeof current) || (Array.isArray(current) ? (current as unknown[]).length > 0 : !!current);

  const handleSwitch = () => {
    if (Array.isArray(filter)) {
      for (const current of filter) resetField(current as any);
    } else resetField(filter as any);

    if (onDisable) onDisable();
  };

  const handleChange = ({ target }: React.ChangeEvent<Record<string, unknown>>, bool: boolean) => {
    if (target.tagName !== 'INPUT') setExpanded(bool);
  };

  return (
    <Accordion
      classes={{ root: wrapper, expanded }}
      elevation={0}
      onChange={handleChange}
      expanded={isExpanded}
      square
      {...props}
    >
      <AccordionSummary classes={{ root: summary, content }}>
        <Box display="flex" flexDirection="row">
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
          <Typography className={text}>{label}</Typography>
        </Box>
        <Switch disabled={!enabled} checked={enabled} onClick={handleSwitch} color="default" />
      </AccordionSummary>
      <AccordionDetails classes={{ root: details }} style={{ padding: '1rem' }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default Summary;
