import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import denyList from '~/utils/denyList';

export type OpenTypes = 'COMPRA' | 'VENDA';

export type Views = 'quantityMwm' | 'price' | 'totalAmount';

export interface ExcludedsProps {
  sale: string[];
  purchase: string[];
}

export interface PortfolioHookProps {
  view: Views;
  isOpen: boolean;
  excludeds: ExcludedsProps;
  setExcludeds: (excludeds: ExcludedsProps) => void;
  setView: (view: Views) => void;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
}

const usePortfolioFilter = create(
  persist<PortfolioHookProps>(
    (set) => ({
      isOpen: false,
      view: 'quantityMwm',
      excludeds: {
        purchase: [],
        sale: [],
      },
      setExcludeds: (excludeds) => set({ excludeds }),
      setView: (view) => set({ view }),
      onOpen: () => set(() => ({ isOpen: true })),
      onClose: () => set(() => ({ isOpen: false })),
      onToggle: () => set((state) => ({ isOpen: !state.isOpen })),
    }),
    {
      name: 'PORTFOLIO',
      partialize: denyList<PortfolioHookProps>('isOpen'),
    },
  ),
);

export default usePortfolioFilter;
