import { Box, BoxProps } from '@material-ui/core';

import useColorMode from '~/hooks/useColorMode';
import { typography, grey } from '~/theme';

const TableSubheading: React.ComponentType<BoxProps> = (props) => {
  const { colorTernary } = useColorMode();

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      padding="9.5px 16px"
      fontWeight="normal"
      borderBottom={`1px solid ${grey[colorTernary(700, 200)]}`}
      paddingLeft="40px"
      whiteSpace="nowrap"
      {...typography.body2}
      {...props}
    />
  );
};

export default TableSubheading;
