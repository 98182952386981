import { Grid, GridProps, Theme, useTheme } from '@tradener/lumen';

import TableColumn from '../TableColumn';

export interface TableSectionProps extends GridProps {
  label: React.ReactNode;
}

const TableSection: React.ComponentType<TableSectionProps> = ({ label, children, ...props }) => {
  const { zIndices } = useTheme<Theme>();

  return (
    <Grid bgColor="orange.500" height="40px" position="sticky" top="0" zIndex={zIndices.docked + 1} {...props}>
      <TableColumn
        position="sticky"
        left="0"
        borderBottom="initial"
        justifyContent="flex-start"
        color="white"
        bgColor="orange.500"
        width={children ? 'auto' : 'fit-content'}
        animated={false}
        zIndex={zIndices.docked + 2}
        emphasis
      >
        {label}
      </TableColumn>
      {children}
    </Grid>
  );
};

export default TableSection;
