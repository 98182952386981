import { gql } from '@apollo/client';

export interface PayloadProps {
  id: string;
}

export interface AccountDetailsProps {
  attentionPoints?: number;
  longTermContracts?: boolean;
  balance?: number;
}

export interface ResponseProps {
  accountDetails: AccountDetailsProps;
}

const GET_ACCOUNT_DETAILS = gql`
  query accountDetails($id: String!) {
    accountDetails(accountId: $id) {
      attentionPoints
      longTermContracts
    }
  }
`;

export default GET_ACCOUNT_DETAILS;
