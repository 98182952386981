import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '952px',
    height: '272px',
    outline: '2px solid',
    outlineOffset: '-1px',
    borderRadius: '8px',
    overflow: 'hidden',
    marginBottom: '16px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '12px 12px 12px 24px',
    gap: '20px',
    width: '954px',
    height: '52px',
    border: '1px solid',
    overflow: 'hidden',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
    padding: '0px 12px 12px 12px',
  },
  iconButton: {
    height: '40px',
    border: `1px solid ${theme.palette.type === 'dark' ? '#5c5c5c' : '#b3b3b3'}`,
    borderRadius: '4px',
    '&:disabled': {
      borderColor: `${theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)'}`,
    },
  },
}));

export default useStyles;
