import { NonFunctionKeys } from 'utility-types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { SimulationProps } from '@graphql/query/simulation';

export type Simulations = 'simulationId' | 'portfolioId';

export type SimulationHook = Partial<SimulationProps> &
  { [K in Simulations]?: string } & {
    setSimulation: ({ id, ...simulation }: SimulationProps, isNewPortfolio?: boolean) => void;
    setFilter: <Key extends NonFunctionKeys<SimulationHook>>(key: Key, value: SimulationHook[Key]) => void;
  };

const useSimulation = create(
  persist<SimulationHook>(
    (set) => ({
      setSimulation: ({ id, ...simulation }: SimulationProps, isNewPortfolio = false) => {
        set({ [isNewPortfolio ? 'portfolioId' : 'simulationId']: id, ...simulation });
      },
      setFilter: (key, value) => {
        set({ [key]: value });
      },
    }),
    { name: 'PORTFOLIO_SIMULATION' },
  ),
);

export default useSimulation;
