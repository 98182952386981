import usePortfolioFilter from '~/pages/Portfolio/usePortfolioFilter';

export const decimalScales = {
  price: 2,
  totalAmount: 0,
  quantityMwm: 3,
};

const useNumberFormat = () => {
  const { view } = usePortfolioFilter();

  const format = (value: number | undefined, options?: Intl.NumberFormatOptions, placeholder: string | number = 0) => {
    if (value === undefined && typeof placeholder !== 'number') return String(placeholder);

    return new Intl.NumberFormat('pt-BRbr', {
      currency: 'BRL',
      style: view === 'totalAmount' ? 'currency' : 'decimal',
      minimumFractionDigits: decimalScales[view],
      maximumFractionDigits: decimalScales[view],
      ...options,
    }).format(value ?? (placeholder as number));
  };

  return { format, view };
};

export default useNumberFormat;
