import { useEffect, useCallback, useReducer } from 'react';

import { toast } from 'react-toastify';

import client from '~/services/apollo';
import dot from 'dot-object';
import gql from 'graphql-tag';
import merge from 'lodash/merge';
import PropTypes from 'prop-types';

import { transform, filterInitialValue } from './actions';
import ProposalsContext, { INITIAL_CONTEXT } from './context';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';

const UPSERT_ENERGY_QUOTE = gql`
  mutation upsertEnergyQuote($input: UpsertEnergyQuoteInput!) {
    upsertEnergyQuote(input: $input) {
      clientMutationId
      quote {
        id
        opportunity {
          channel
        }
      }
    }
  }
`;

function Proposals({ opportunityType, initialValue, operation, children }) {
  const contextRef = useRef(INITIAL_CONTEXT);
  const [, reRender] = useReducer((prev) => prev + 1, 0);
  const push = useNavigate();

  const setContext = (getState) => {
    const state = getState(contextRef.current);

    contextRef.current = state;
    reRender();
  }

  const mergeData = useCallback(
    (key, value) => {
      const tempData = { ...contextRef.current };

      if (key === null && typeof value === 'object') {
        setContext((prevState) => merge({}, prevState, value));

        return;
      }

      dot.set(key, value, tempData);
      setContext((prevState) => merge({}, prevState, tempData));
    },
    [],
  );

  const replaceData = useCallback(
    (key, value) => {
      const tempData = { ...contextRef.current };

      if (key === null && typeof value === 'object') {
        setContext((prevState) => ({ ...prevState, ...value }));

        return;
      }

      if (tempData.readOnly) return;

      dot.set(key, value, tempData);
      setContext((prevState) => ({ ...prevState, ...tempData }));
    },
    [],
  );

  async function submitProposal({ saveAndPropose = false, saveAndReject = false }) {
    try {
      const response = await client.mutate({
        mutation: UPSERT_ENERGY_QUOTE,
        variables: {
          input: { ...transform(contextRef.current), saveAndPropose, saveAndReject },
        },
      });

      const { quote } = response.data.upsertEnergyQuote;
      const channel = quote?.opportunity?.channel;

      toast.success('Proposta salva com sucesso!');

      if (saveAndPropose && channel !== 'portal') {
        push(`/book/list/send-proposal/${quote.id}`);
      } else {
        push('/book/list');
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  function mergeDataAndMarkEditedFields(name, value) {
    const temp = {
      [name]: value,
      options: { edited: { [name]: true } },
    };

    mergeData(null, temp);
  }

  useEffect(() => {
    replaceData(null, {
      ...filterInitialValue({ initialValue, operation, opportunityType }),
    });
  }, [initialValue]);

  return (
    <ProposalsContext.Provider
      value={{
        data: contextRef.current,
        mergeData,
        replaceData,
        submitProposal,
        mergeDataAndMarkEditedFields,
      }}
    >
      {children}
    </ProposalsContext.Provider>
  );
}

Proposals.defaultProps = {
  initialValue: {},
  operation: '',
  opportunityType: '',
};

Proposals.propTypes = {
  opportunityType: PropTypes.string,
  children: PropTypes.node.isRequired,
  initialValue: PropTypes.shape({}),
  operation: PropTypes.string,
};

export default Proposals;
