import { DateTime } from 'luxon';

import { ProductAnalyticsSeriesProps } from './useProductAnalytics';

export const COLORS = {
  gray100: 'var(--lumen-colors-gray-100)',
  gray500: 'var(--lumen-colors-gray-500)',
  gray600: 'var(--lumen-colors-gray-600)',
  gray900: 'var(--lumen-colors-gray-900)',
  orange100: 'var(--lumen-colors-orange-100)',
  orange500: 'var(--lumen-colors-orange-500)',
  orange700: 'var(--lumen-colors-orange-700)',
  blue100: 'var(--lumen-colors-blue-100)',
  blue500: 'var(--lumen-colors-blue-500)',
  blue700: 'var(--lumen-colors-blue-700)',
  purple500: 'var(--lumen-colors-purple-500)',
  purple700: 'var(--lumen-colors-purple-700)',
  aqua500: '#4EC5E8',
  aqua700: '#338299',
};

export const parsedPrices = (series: ProductAnalyticsSeriesProps[] | undefined) => {
  const filteredSeries = series
    ?.map((item) => {
      if (item.type) return null;

      return {
        timestamp: item.timestamp,
        firstPrice: Number(item.firstPrice),
        lastPrice: Number(item.lastPrice),
        maxPrice: Number(item.maxPrice),
        minPrice: Number(item.minPrice),
        pldTrend: Number(item.pldTrend),
        pldTrendHigh: Number(item.pldTrendHigh),
      };
    })
    .filter(Boolean);

  return Object.entries(filteredSeries ?? []).map(([_, values]) => [
    values?.timestamp,
    [values?.firstPrice, values?.lastPrice, values?.maxPrice, values?.minPrice, values?.pldTrend, null, values?.pldTrendHigh],
  ]);
};

export const parsedVolumes = (series: ProductAnalyticsSeriesProps[] | undefined) => {
  const filteredSeries = series
    ?.map((item) => {
      if (item.type) return null;

      return {
        timestamp: item.timestamp,
        quantityMwm: Number(item.quantityMwm),
      };
    })
    .filter(Boolean);

  return filteredSeries;
};

export const parsedExposures = (series: ProductAnalyticsSeriesProps[] | undefined) => {
  const allTimeStamps = [...new Set(series?.map(({ timestamp }) => timestamp))];
  const defaultSeries = series?.filter((serie) => !serie?.type) ?? [];
  const exposureSeries = [] as any[];
  const defaultOperation = { operationMwm: undefined, price: undefined, type: 'DEFAULT' };
  let lastExposure = {};

  allTimeStamps.forEach((timestamp, index) => {
    const defaultIndex = defaultSeries?.findIndex((serie) => serie.timestamp === timestamp);
    const hasDefault = defaultIndex >= 0 && defaultSeries[defaultIndex];

    if (hasDefault) lastExposure = defaultSeries[defaultIndex];

    exposureSeries.push({ ...lastExposure, ...defaultOperation, timestamp, index });
  });

  return exposureSeries?.sort((a, b) => a.timestamp - b.timestamp);
};

export const parsedScarters = (series: ProductAnalyticsSeriesProps[] | undefined, type?: string) => {
  const allTimeStamps = [...new Set(series?.map(({ timestamp }) => timestamp))];
  const operations = series?.filter((serie) => !!serie?.type && (serie?.type === type || !type)) ?? [];
  const scattersSeries = [] as any[];

  allTimeStamps.forEach((timestamp) => {
    const timestampOperations = operations?.filter((operation) => operation.timestamp === timestamp) ?? [];

    if (timestampOperations.length > 0) scattersSeries.push(...timestampOperations);
    else scattersSeries.push({ timestamp, operationMwm: undefined, price: undefined, type: 'DEFAULT' });
  });

  return scattersSeries?.sort((a, b) => a.timestamp - b.timestamp);
};

export const gradientOffset = (series: any) => {
  const dataMax = Math.max(...series?.map((serie) => Number(serie?.exposure)));
  const dataMin = Math.min(...series?.map((serie) => Number(serie?.exposure)));

  if (dataMax <= 0) return 0;
  if (dataMin >= 0) return 1;

  return dataMax / (dataMax - dataMin);
};

export const serieYears = (series: any) => [
  ...new Set(series?.map(({ timestamp }: any) => DateTime.fromMillis(timestamp).toFormat('yyyy'))),
];
