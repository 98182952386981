import { useState } from 'react';

import { Select, Option, Flex } from '@tradener/lumen';

import gql from 'graphql-tag';

import { DatePicker } from '@DEPRECATED/components/Form';
import Widget from '@DEPRECATED/components/Widget';
import WidgetContent from '@DEPRECATED/components/WidgetContent';
import WidgetHeader from '@DEPRECATED/components/WidgetHeader';
import { useQuery } from '@apollo/client';

import { useProvider } from '~/DEPRECATED/pages/provider';

import Chart, { FunnelMonth, ChartFragment } from './Chart';
import Funnel, { FunnelFragment, IFunnelFragment } from './Funnel';

interface QuoteFunnel extends IFunnelFragment {
  period: string;
  months: FunnelMonth[];
}

type QuoteFunnelData = {
  quoteFunnel: QuoteFunnel;
};

type QuoteFunnelVars = {
  year: string;
  tenantIds?: string[];
  internalAccounts?: string[];
  operation?: string;
};

export const GET_QUOTE_FUNNEL = gql`
  query quoteFunnel($year: String!, $internalAccounts: [String!], $operation: ContractOperation) {
    quoteFunnel(filter: { year: $year, internalAccounts: $internalAccounts, operation: $operation }) {
      period
      ...FunnelFragment
      ...ChartFragment
    }
  }
  ${FunnelFragment}
  ${ChartFragment}
`;

const OfferCounter = () => {
  const { internalAccounts } = useProvider();
  const [date, setDate] = useState(new Date());

  const { data, refetch, variables } = useQuery<QuoteFunnelData, QuoteFunnelVars>(GET_QUOTE_FUNNEL, {
    fetchPolicy: 'cache-first',
    variables: {
      year: date.getFullYear().toString(),
      internalAccounts,
    },
  });

  const handleChange = (value: string | number) => {
    if (value === '') value = undefined as unknown as string;

    refetch({ operation: value as string });
  };

  function handleDate(value: Date | null) {
    setDate((prevState) => value || prevState);
  }

  return (
    <Widget className="side">
      <WidgetHeader title="Contador de Ofertas">
        <Flex align="center" justify="flex-end" columnGap="2">
          <Select placeholder="Operação" onChange={handleChange} width="36" borderRadius="4px" bgColor="transparent" borderWidth="1px" borderColor="gray.200">
            <Option value="">Todos</Option>
            <Option value="PURCHASE">Compra</Option>
            <Option value="SALE">Venda</Option>
          </Select>
          <DatePicker views={['year']} format="yyyy" value={date} onChange={handleDate as any} style={{ width: 100 }} />
        </Flex>
      </WidgetHeader>
      <WidgetContent>
        {!data ? null : (
          <>
            <Funnel
              closed={data!.quoteFunnel.closed}
              negotiated={data!.quoteFunnel.negotiated}
              opened={data!.quoteFunnel.opened}
            />
            <Chart data={data!.quoteFunnel.months} />
          </>
        )}
      </WidgetContent>
    </Widget>
  );
};

export default OfferCounter;
