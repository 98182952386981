import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { QuoteStageActions } from '@graphql/mutation/changeQuoteStage';
import { Stages } from '@graphql/query/productOperations';

import { compareTwoEntries } from '~/utils/compare';
import fastProvider from '~/utils/fastProvider';

import { QuoteOptionsProps } from '.';
import useComplementDialog from './useComplementDialog';

export type StageActions = QuoteStageActions | StringElse;

export type Action = [StageActions, (action: StageActions) => Promise<unknown>];

const [withQuoteOptions, useQuoteOptions] = fastProvider((props: QuoteOptionsProps) => {
  const [[action, onSubmit], setAction] = useState([] as unknown as Action);
  const push = useNavigate();
  const complementDialog = useComplementDialog();

  const onOpen = (...action: Action) => setAction(action);

  const onClose = () => setAction([] as unknown as Action);

  const navigate = (params: { [key: string]: string }) => {
    const entries = { ...params, bbce_product_id: props.quote.opportunity.bbceProduct.id, quote_id: props.quote.id };
    const searchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(entries)) searchParams.set(key, value);

    const { type, buyer, seller } = props.quote.opportunity;

    push('/trading/list/offer?' + searchParams.toString(), {
      state: {
        operation: type,
        buyerId: buyer?.id,
        buyerName: buyer?.name,
        sellerId: seller?.id,
        sellerName: seller?.name,
      },
    });
  };

  const getStageAccess = (elements: Uppercase<Stages>[]) =>
    elements.some((element) => compareTwoEntries(element, props.quote.stage, 'i'));

  return {
    ...props.quote,
    getStageAccess,
    isOpen: !!action,
    onOpen,
    onClose,
    onSubmit,
    navigate,
    action,
    complementDialog,
  };
});

export { withQuoteOptions, useQuoteOptions };
