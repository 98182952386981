import { Fragment } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Flex,
  Grid,
  GridItem,
  Text,
  Badge,
  useTheme,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  PopoverArrow,
  Spinner,
} from '@tradener/lumen';
import ContractsDetails, { formatDate, formatPrice } from '~/pages/Portfolio/ContractsDetails';
import { numberFormat } from '@DEPRECATED/utils/format';

import GET_CONTRACTS, { PayloadProps, ResponseProps } from '@graphql/query/contractsDetails';
import { useQuery } from '@apollo/client';

export interface ContractsProps {
  taxId?: string;
  operation?: string;
}

const TEMPLATE_COLUMNS = '6.25rem 7.375rem 7.25rem repeat(2, 1fr)';

const Contracts: React.ComponentType<ContractsProps> = ({ taxId, operation }) => {
  const { colors } = useTheme();

  const { data, loading } = useQuery<ResponseProps, PayloadProps>(GET_CONTRACTS, {
    fetchPolicy: 'cache-and-network',
    skip: !taxId,
    variables: {
      type: 'ENERGY',
      filter: {
        search: taxId?.replace(/\D/g, ''),
        periodType: 'LONG',
        operation,
      },
    },
  });

  return (
    <Flex
      padding="3"
      gap="3"
      marginBottom="3"
      borderRadius="0.75rem"
      border="1px solid"
      borderColor={`${colors?.gray[900]}1F`}
      width="full"
    >
      <Accordion allowToggle width="full">
        <AccordionItem border="none">
          <h2>
            <AccordionButton>
              <Flex data-testid="contracts" justifyContent="space-between" alignItems="center" width="full">
                <Flex alignItems="flex-end">
                  <Text variant="body-1">Contratos LP</Text>
                  <Badge variant="outline" colorScheme="gray" marginLeft="3">
                    <Text variant="body-1" data-testid="contracts-count">
                      {data?.contracts?.length ?? '0'}
                    </Text>
                  </Badge>
                </Flex>
                {loading ? <Spinner size="sm" color="gray.500" /> : <AccordionIcon />}
              </Flex>
            </AccordionButton>
          </h2>
          {data?.contracts && data?.contracts?.length > 0 && (
            <AccordionPanel padding="0" maxHeight="58.5" overflowY="auto" overflowX="hidden">
              <Grid templateColumns={TEMPLATE_COLUMNS}>
                <GridItem colSpan={1} padding="4">
                  <Text variant="body-2-medium" color="gray.500">
                    Num.
                  </Text>
                </GridItem>
                <GridItem colSpan={1} padding="4">
                  <Text variant="body-2-medium" color="gray.500">
                    Vol. (MWm)
                  </Text>
                </GridItem>
                <GridItem colSpan={1} padding="4">
                  <Text variant="body-2-medium" color="gray.500">
                    Preço
                  </Text>
                </GridItem>
                <GridItem colSpan={1} padding="4">
                  <Text variant="body-2-medium" color="gray.500">
                    Início
                  </Text>
                </GridItem>
                <GridItem colSpan={1} padding="4">
                  <Text variant="body-2-medium" color="gray.500">
                    Fim
                  </Text>
                </GridItem>
              </Grid>
              <Flex flexDir="column" width="full">
                {data?.contracts?.map((contract) => {
                  const contractNumber = contract.contractNumber ?? ' - ';
                  const quantityMwm = numberFormat(contract.currentContractItem?.quantityMwm, 3, 3);
                  const amount = formatPrice(contract.currentContractItem?.amount);
                  const startDate = formatDate(contract.startDate);
                  const endDate = formatDate(contract.endDate);
                  const usageFactorFormatted = contract.usageFactor ? ` (${(contract.usageFactor * 100).toFixed()}%)` : '';

                  return (
                    <Fragment key={contract.id}>
                      <Popover
                        isLazy
                        arrowShadowColor="md"
                        gutter={50}
                        closeOnBlur
                        trigger="hover"
                        placement="left"
                        direction="rtl"
                      >
                        <PopoverTrigger>
                          <Grid
                            templateColumns={TEMPLATE_COLUMNS}
                            justifyContent="flex-start"
                            width="full"
                            _hover={{ bgColor: `${colors.gray[900]}0A` }}
                          >
                            <GridItem
                              colSpan={1}
                              display="flex"
                              borderTop="1px solid"
                              borderColor={`${colors.gray[900]}1F`}
                              padding="4"
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              <Text variant="body-2" textAlign="start">
                                {contractNumber}
                              </Text>
                            </GridItem>
                            <GridItem
                              colSpan={1}
                              display="flex"
                              borderTop="1px solid"
                              borderColor={`${colors.gray[900]}1F`}
                              padding="4"
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              <Text variant="body-2" textAlign="start">
                                {quantityMwm}
                                {usageFactorFormatted}
                              </Text>
                            </GridItem>
                            <GridItem
                              colSpan={1}
                              borderTop="1px
                              solid"
                              borderColor={`${colors.gray[900]}1F`}
                              padding="4"
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              <Text variant="body-2">{amount}</Text>
                            </GridItem>
                            <GridItem
                              colSpan={1}
                              borderTop="1px
                              solid"
                              borderColor={`${colors.gray[900]}1F`}
                              padding="4"
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              <Text variant="body-2-medium" color="gray.500" textAlign="start">
                                {startDate}
                              </Text>
                            </GridItem>
                            <GridItem
                              colSpan={1}
                              borderTop="1px
                              solid"
                              borderColor={`${colors.gray[900]}1F`}
                              padding="4"
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              <Text variant="body-2-medium" color="gray.500" textAlign="start">
                                {endDate}
                              </Text>
                            </GridItem>
                          </Grid>
                        </PopoverTrigger>
                        <PopoverContent
                          position="relative"
                          borderRadius="0.75rem"
                          border="1px solid"
                          borderColor={`${colors.gray[900]}1F`}
                          _focus={{ boxShadow: 'md' }}
                          _visited={{ boxShadow: 'md' }}
                        >
                          <PopoverArrow bg="gray.0" />
                          <PopoverBody borderRadius="0.75rem" padding="0" boxShadow="none" width="fit-content">
                            <ContractsDetails isLoaded contract={contract} />
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Fragment>
                  );
                })}
              </Flex>
            </AccordionPanel>
          )}
        </AccordionItem>
      </Accordion>
    </Flex>
  );
};

export default Contracts;
