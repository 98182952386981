import { memo } from 'react';

import { Flex, Text, Theme, useTheme } from '@tradener/lumen';
import { DateTime } from 'luxon';
import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Bar, ReferenceLine, ComposedChart } from 'recharts';

import CustomTooltip from '../components/CustomTooltip';
import TickLabelXAxis from '../components/TickLabelXAxis';
import { COLORS, parsedVolumes, serieYears } from '../configs';
import { useProductAnalytics } from '../useProductAnalytics';

const payloadConfig = (dataKey: string) => {
  if (dataKey === 'quantityMwm') return { iconColor: COLORS.blue500, iconType: 'square' };
};

function TradingVolumes() {
  const { colors } = useTheme<Theme>();
  const { series } = useProductAnalytics();
  const volumes = parsedVolumes(series);

  if (!volumes?.length) return null;

  const years = serieYears(volumes);

  return (
    <Flex data-testid="energy-volumes" flexDirection="column" alignItems="center" width="full" flex="1">
      <Flex
        flexDirection="column"
        bgColor="gray.0"
        height="25rem"
        width="full"
        borderRadius="xl"
        padding="5"
        gap="1"
        color={colors.gray[900]}
      >
        <Flex flexDirection="column" justifyContent="center" gap="0.5">
          <Text textStyle="subheading1-semibold">Volume negociado</Text>
        </Flex>
        <Flex
          direction="column"
          width="100%"
          height="100%"
          paddingBlock="1"
          overflow="visible"
          sx={{
            '.recharts-text': { fill: colors.gray[500] },
            '.recharts-cartesian-axis-line, .recharts-brush > rect': { stroke: 'none' },
            '.recharts-cartesian-axis-tick-line, line': { stroke: colors.gray[100] },
          }}
        >
          <ResponsiveContainer>
            <ComposedChart data={volumes} margin={{ top: 35, left: 15, right: 55 }} syncId="balanceId">
              <CartesianGrid vertical={false} strokeWidth={1} />
              <XAxis
                dataKey="timestamp"
                type="category"
                domain={['dataMin', 'dataMax']}
                tickLine={false}
                minTickGap={40}
                padding={{ left: 40, right: 40 }}
                stroke={colors.gray[200]}
                tick={<TickLabelXAxis mode="DAY" />}
              />
              <YAxis
                dataKey="quantityMwm"
                padding={{ top: 35 }}
                tickMargin={25}
                domain={['auto', 'auto']}
                label={{ value: 'MWm', position: 'top' }}
                tick={{ fill: colors.gray[600], fontSize: '0.875rem' }}
                tickLine={false}
              />
              {years?.map((year) => {
                const xAxis = volumes?.find(
                  ({ timestamp }: any) => DateTime.fromMillis(timestamp).toFormat('yyyy') === year,
                )?.timestamp;

                if (!xAxis) return null;

                return (
                  <ReferenceLine
                    key={Number(year)}
                    x={xAxis}
                    stroke={`${colors.gray[900]}29`}
                    strokeWidth={2}
                    ifOverflow="discard"
                    isFront={false}
                    strokeDasharray="6 6"
                    label={{ value: Number(year), position: 'insideTopLeft', fill: colors.gray[500] }}
                  />
                );
              })}
              <Bar
                name="Volume negociado"
                dataKey="quantityMwm"
                type="monotone"
                fill={colors.blue[500]}
                radius={4}
                maxBarSize={20}
                isAnimationActive={false}
              />
              <Tooltip
                filterNull
                useTranslate3d
                allowEscapeViewBox={{ x: true, y: true }}
                wrapperStyle={{ left: -235 }}
                isAnimationActive={false}
                cursor={{ stroke: `${colors.gray[900]}14`, radius: 2, opacity: 0.75 }}
                content={<CustomTooltip mode="DAY" config={payloadConfig} />}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default memo(TradingVolumes);
