import { DateTime } from 'luxon';

import { SimulationPeriodsProps } from '@graphql/query/simulation';

import usePortfolio from '../../usePortfolio';

const exposure = {
  balanceMwm: 0,
  pld: 0,
  simulationPld: null,
};

const balance = {
  price: 0,
  quantityMwm: 0,
  totalAmount: 0,
};

const operation = {
  ...balance,
  se: balance,
  s: balance,
  ne: balance,
  n: balance,
};

const compose = () => {
  const startYear = parseInt(usePortfolio.getState().startYear);
  const periods: SimulationPeriodsProps[] = [];

  for (let i = 1, current = DateTime.local(startYear); i <= 12; i++) {
    periods.push({
      period: `${current.toFormat('yyyy-MM')}`,
      balance: {
        balanceMwm: 0,
        originalTotalAmount: 0,
        totalAmount: 0,
        purchase: operation,
        sale: operation,
      },
      dre: {
        cceeAmount: 0,
        argentinaBalanceAmount: 0,
        uruguaiBalanceAmount: 0,
        walletBalanceAmount: 0,
        globalBallast: 0,
        highBalanceAmount: 0,
        lowBalanceAmount: 0,
        notSpecialBallast: 0,
        predictedBalanceAmount: 0,
        specialBallast: 0,
        pvFactor: 0,
        pvTotalAmount: 0,
      },
      exposure: {
        se: exposure,
        s: exposure,
        ne: exposure,
        n: exposure,
      },
    });

    current = current.plus({ month: 1 });
  }

  return periods;
};

export default compose;
