import { Input as InputRaw } from '@DEPRECATED/components/Form';
import TypographyRaw from '@material-ui/core/Typography';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    border-bottom: 1px solid #999;
    margin-top: 16px;
    padding: 0;

    p {
      display: none;
    }
  `}
`;

export const TextField = styled(InputRaw).attrs({
  fullWidth: true,
  variant: 'standard',
})`
  ${({ theme }) => css`
    && {
      .MuiInputBase-root {
        min-width: 100%;
      }

      .MuiInputBase-input {
        height: 17px;
        color: #999;
      }

      .MuiInputLabel-root {
        color: white
        transform: translate(0, 19px) scale(1);
      }
      .MuiInputLabel-shrink {
        transform: translate(0, 1.5px) scale(0.75);
        transform-origin: top left;
        font-weight: 700;
      }

      .Mui-error,
      .Mui-error input {
        color: #F6554E;
      }
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    display: block;
    color: white
    font-size: 14px;
    letter-spacing: 0.8px;
    line-height: 1;
    font-weight: 700;
  `}
`;

export const Typography = styled(TypographyRaw).attrs({
  variant: 'body1',
  component: 'div',
})`
  ${({ theme }) => css`
    && {
      color: #999;
      font-size: 16px;
      letter-spacing: 0.8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 8px;
    }
  `}
`;

export const Name = styled.div`
  ${({ theme }) => css`
    display: block;
    width: 140px;
    min-height: 45px;
    border-right: 1px solid #999;
  `}
`;

export const Email = styled.div`
  ${({ theme }) => css`
    display: block;
    width: 240px;
    min-height: 45px;
    border-right: 1px solid #999;
    padding-left: 16px;
  `}
`;

export const Role = styled.div`
  display: flex;
  width: 143px;
  min-height: 45px;
  align-items: center;
  justify-content: center;
`;
