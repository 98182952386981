import { Connection as BaseConnection, INTERNAL, logger } from '@rails/actioncable';

import store from '~/context';

import { getProtocols } from '.';
import renewSession, { shouldConnect, setSession } from '../../session';

class Connection extends BaseConnection {
  public protocols!: Array<string>;

  open() {
    this.protocols = [...INTERNAL.protocols, ...getProtocols()];

    if (!shouldConnect()) {
      logger.log('You cannot start a connection without being authenticated.');

      return false;
    }

    if (this.isActive()) {
      logger.log(`Attempted to open WebSocket, but existing socket is ${this.getState()}`);

      return false;
    }

    logger.log(`Opening WebSocket, current state is ${this.getState()}, subprotocols: ${this.protocols}`);

    if (this.webSocket) {
      this.uninstallEventHandlers();
    }

    this.webSocket = new WebSocket(this.consumer.url, this.protocols);
    this.installEventHandlers();
    this.monitor.start();

    return true;
  }
}

Connection.reopenDelay = 1000;

Connection.prototype.events = {
  ...BaseConnection.prototype.events,
  async message(event) {
    if (!this.isProtocolSupported()) return null;

    const data = JSON.parse(event.data);

    switch (data.type) {
      case INTERNAL.message_types.welcome:
        this.monitor.recordConnect();

        store.subscribe(() => {
          const { auth } = store.getState();

          if (!auth.token) {
            this.close();
          }
        });

        return this.subscriptions.reload();

      case INTERNAL.message_types.disconnect:
        logger.log(`Disconnecting. Reason: ${data?.reason}`);

        if (data?.reason === 'unauthorized') {
          const session = await renewSession();

          if (session) setSession(session);

          return this.reopen();
        }

        return this.close();

      case INTERNAL.message_types.ping:
        return this.monitor.recordPing();

      case INTERNAL.message_types.confirmation:
        return this.subscriptions.notify(data?.identifier, 'connected');

      case INTERNAL.message_types.rejection:
        return this.subscriptions.reject(data?.identifier);

      default:
        return this.subscriptions.notify(data?.identifier, 'received', data?.message);
    }
  },
};

export default Connection;
