import { useNavigate, useLocation, To } from 'react-router-dom';

function useBackward(fallback: To) {
  const navigate = useNavigate();
  const { key } = useLocation();

  const getBack = () => {
    navigate((key ? fallback : -1) as To);
  };

  return getBack;
}

export default useBackward;
