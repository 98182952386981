import { useState, useRef } from 'react';

import { MdAdd } from 'react-icons/md';

import VerticalScroll from '@DEPRECATED/components/VerticalScroll';
import { Popper, ClickAwayListener, MenuList } from '@material-ui/core';
import PropTypes from 'prop-types';

import ConditionItem from './ConditionItem';
import { Container, ConditionList, Fab, Paper, MenuItem } from './styles';
import useQuoteInformation from '~/hooks/useQuoteInformation';

function Condition({ readOnly, setEditingQuoteInformation }) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const scrollRef = useRef(null);

  const { getTemplates, getAvailableTemplates, addItem } = useQuoteInformation();

  function handleAddItem(newItem) {
    addItem(newItem);
    setOpen(false);
  }

  function handleToogle() {
    setOpen((prevOpen) => !prevOpen);
  }

  return (
    <Container>
      {!readOnly && (
        <Fab
          ref={anchorRef}
          color={open ? 'default' : 'secondary'}
          aria-label="add"
          size="small"
          variant="extended"
          onClick={handleToogle}
          style={{
            zIndex: 1,
          }}
        >
          <MdAdd />
        </Fab>
      )}

      <VerticalScroll>
        <ConditionList ref={scrollRef}>
          {getTemplates().map((item) => (
            <ConditionItem
              setEditingQuoteInformation={setEditingQuoteInformation}
              key={item.id}
              item={item}
              readOnly={readOnly}
            />
          ))}
        </ConditionList>

        <Popper open={open} anchorEl={anchorRef.current} placement="top-end" style={{ zIndex: 1500 }}>
          <Paper>
            <ClickAwayListener onClickAway={handleToogle}>
              <MenuList autoFocusItem={open}>
                {getAvailableTemplates().map((item) => (
                  <MenuItem key={`menu-item-${item.id}`} onClick={() => handleAddItem(item)} dense>
                    {item.name}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </VerticalScroll>
    </Container>
  );
}

Condition.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  setEditingQuoteInformation: PropTypes.func,
};

export default Condition;
