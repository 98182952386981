import { useEffect, useMemo, useRef } from 'react';

import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Box, IconButton, InputAdornment, Typography } from '@material-ui/core';
import { Search, ArrowBack, ChevronRight } from '@material-ui/icons';
import { removeKeys } from '@tradener/lumen';
import { DateTime } from 'luxon';

import { OperationTypes, SubmarketTypes, PayloadProps } from '@graphql/query/simulationContracts';

import useColorMode from '~/hooks/useColorMode';
import Badges from '~/pages/Portfolio/Badges';
import usePortfolio from '~/pages/Portfolio/usePortfolio';
import client from '~/services/apollo';
import { grey } from '~/theme';
import Chip from '~/theme/components/Chip';
import FilledInput from '~/theme/components/FilledInput';
import Container from '~/theme/layout/Container';

import Filter from '../../../../theme/layout/Filter';
import useExcludedsTemp from '../../hooks/useExcludedsTemp';
import useSimulation from '../../useSimulation';
import useSimulationRules from '../../useSimulationRules';
import SimulationSubheader from '../SimulationSubheader';
import SimulationName from '../SimulationSubheader/SimulationName';
import ApplyButton from './ContractApplyButton';
import ContractTable from './ContractTable';
import { useSimulationContract, withSimulationContract } from './context';
import useStyles from './styles';
import useSimulationContracts from './useSimulationContracts';
import useSimulationUpdateItems from './useUpdateSimulationItems';

export interface SimulationContractsProps {
  search: string;
  submarket?: SubmarketTypes | 'ALL';
}

export enum Contracts {
  OFFSET = 0,
  LIMIT = 50,
}

const initialValues: SimulationContractsProps = {
  search: '',
  submarket: 'ALL',
};

function SimulationContracts() {
  const formRef = useRef<HTMLFormElement>();
  const navigate = useNavigate();
  const { simulationId, portfolioId } = useSimulation();
  const { isPortfolio } = useSimulationRules();
  const { pickedSubmarket, notPickedSubmarket } = useStyles();
  const { handleSubmit, control, getValues } = useForm<SimulationContractsProps>({ defaultValues: initialValues });
  const { colorTernary } = useColorMode();
  const { excludedContracts, setFilter, ...rest } = usePortfolio();
  const filterValues = removeKeys(rest, 'tenantId', 'operation') as Partial<PayloadProps>;
  const { operation } = useParams();
  const { itemPushList, setItemPushList, contentRef } = useSimulationContract();
  const { excludedContractsTemp, cancelHandleExcludedsContracts, handleExcludedsContracts } = useExcludedsTemp();
  const updateSimulationItems = useSimulationUpdateItems();
  const { data, loading, fetchMore, refetch } = useSimulationContracts({
    search: getValues().search,
    submarket: getValues().submarket === 'ALL' ? undefined : (getValues().submarket as SubmarketTypes),
    limit: Contracts.LIMIT,
    offset: Contracts.OFFSET,
    operation: operation?.toUpperCase() as OperationTypes,
    simulationId: isPortfolio ? portfolioId! : simulationId!,
    ...filterValues,
  } as PayloadProps);

  const showApplyButton = useMemo(() => {
    const keysExcludedsTemp = Object.keys(excludedContractsTemp);
    const keysExcludeds = Object.keys(excludedContracts);
    const keysExcludedsDiff = keysExcludedsTemp.some((key) => !keysExcludeds.includes(key));
    const show = keysExcludedsDiff || itemPushList.length > 0 || keysExcludedsTemp.length !== keysExcludeds.length;

    return show;
  }, [excludedContractsTemp, excludedContracts, itemPushList]);

  const handleApply = async () => {
    setFilter('excludedContracts', { ...excludedContractsTemp });

    if (itemPushList.length > 0) await updateSimulationItems();
    else toast.success('Itens da simulação atualizados!');
  };

  const handleExpandedPeriods = (period: string) => {
    const isMonthly = period.includes('-');
    const date = DateTime.fromFormat(period, isMonthly ? 'yyyy-MM' : 'yyyy');

    setFilter(
      'expandedPeriods',
      filterValues?.expandedPeriods?.includes(date.year.toString())
        ? filterValues?.expandedPeriods?.filter((p) => p !== date.year.toString()) || []
        : [...(filterValues?.expandedPeriods as string[]), date.year.toString()],
    );
  };

  const onLoadMore = () => {
    if (isPortfolio ? portfolioId : simulationId) fetchMore({ variables: { offset: data?.simulationContracts?.length } });
  };

  const onSubmit: SubmitHandler<SimulationContractsProps> = ({ submarket, search }: Partial<SimulationContractsProps>) => {
    submarket = submarket?.toString() === 'ALL' ? undefined : (submarket as SubmarketTypes);
    refetch({ submarket, search, ...filterValues });
  };

  useEffect(
    () => () => {
      client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'simulationContracts' });
    },
    [],
  );

  return (
    <>
      <SimulationSubheader width="calc(100% - 20px)" zIndex={1} color={colorTernary(grey[400], grey[900])}>
        <IconButton
          onClick={() => navigate(`/portfolio/${isPortfolio ? 'table' : 'simulation'}/`)}
          style={{ borderRadius: '4px', padding: '9px', color: 'inherit' }}
        >
          <ArrowBack style={{ width: '22px', height: '22px' }} />
        </IconButton>
        <SimulationName />
        <Box padding="4px 8px" display="flex" alignItems="center" height="35px" color="inherit">
          <ChevronRight color="inherit" style={{ marginRight: '15px' }} />
          <Typography variant="h5" color="secondary" style={{ fontWeight: 400, color: colorTernary('#858585', '#a8a8a8') }}>
            {operation?.toUpperCase() === 'SALE' ? 'Venda' : 'Compra'}
          </Typography>
        </Box>
      </SimulationSubheader>
      <Badges />
      <Box {...{ ref: contentRef }} display="flex" justifyContent="space-between" overflow="hidden">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container position="relative" overflow="hidden">
            <Box display="flex" justifyContent="space-between" padding="16px">
              <Box display="flex" style={{ columnGap: '8px' }}>
                <Controller
                  control={control}
                  name="submarket"
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ...field } }) => (
                    <>
                      {['ALL', 'SE', 'S', 'NE', 'N'].map((submarket) => (
                        <Chip
                          className={value === submarket ? pickedSubmarket : notPickedSubmarket}
                          key={submarket}
                          label={submarket === 'ALL' ? 'Todos' : submarket}
                          color={value === submarket ? 'primary' : 'default'}
                          onClick={() => {
                            onChange(submarket);
                            onSubmit({
                              submarket: submarket?.toString() === 'ALL' ? undefined : (submarket as SubmarketTypes),
                              search: getValues().search,
                            });
                          }}
                          {...field}
                        />
                      ))}
                    </>
                  )}
                />
              </Box>
              <Controller
                control={control}
                name="search"
                render={({ field: { ...field } }) => (
                  <FilledInput
                    placeholder="Pesquisar"
                    style={{ width: '280px', margin: '0px' }}
                    endAdornment={
                      <InputAdornment position="end">
                        <Search style={{ width: '24px' }} />
                      </InputAdornment>
                    }
                    onKeyDown={({ code }) => code === 'Enter' && formRef.current?.submit()}
                    {...field}
                  />
                )}
              />
            </Box>
            <ContractTable
              data={data}
              loading={loading}
              onLoadMore={onLoadMore}
              handleExpandedPeriods={handleExpandedPeriods}
              operation={operation?.toString() as string}
              excludedContractsTemp={excludedContractsTemp}
              handleExcludedsContracts={handleExcludedsContracts}
            />
            {showApplyButton && (
              <ApplyButton
                onClick={handleApply}
                onCancel={() => {
                  setItemPushList([]);
                  cancelHandleExcludedsContracts();
                }}
              />
            )}
          </Container>
        </form>
        <Filter />
      </Box>
    </>
  );
}

export default withSimulationContract(SimulationContracts);
