import { createContext, useContext } from 'react';

import { FieldArrayWithId } from 'react-hook-form';

import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { nanoid } from 'nanoid';

import { SimulationOperationsProps } from '@graphql/query/simulation';

import useColorMode from '~/hooks/useColorMode';
import usePeriodComparison from '~/hooks/usePeriodComparison';

import usePeriods from '../../hooks/usePeriods';
import { TableColumn, TableHorizontalAxis, TableSection, TableVerticalAxis } from '../Table';
import { useSimulationTable } from '../withSimulationTable';

export interface FormProps {
  operations: SimulationOperationsProps[];
}

export interface OperationsProps {
  operation: FieldArrayWithId<FormProps, 'operations', 'id'>;
  index: number;
}

const Context = createContext({} as OperationsProps);

const useSimulationOperations = <Selected,>(selector: (state: OperationsProps) => Selected) => {
  const context = useContext(Context);

  return selector(context);
};

const withGuarantee =
  <T,>(Component: React.ComponentType<T>) =>
  (props: React.PropsWithChildren<T>) => {
    const { loading } = useSimulationTable();
    const { periods } = usePeriods();
    const getColor = usePeriodComparison();
    const { theme, colorTernary } = useColorMode();

    return loading ? (
      <>
        <TableSection label="Simulação" display="flex" alignItems="center" />
        <TableHorizontalAxis flexDirection="column">
          <Box display="grid" style={{ gridTemplateColumns: '200px 1fr' }}>
            <Box display="flex" width="200px" gridAutoRows="80px">
              <Box display="inherit" flexDirection="column" padding="9.5px 16px">
                <Box padding="9.5px 8px">
                  <Skeleton width={60} height={20} />
                </Box>
                <Box padding="9.5px 8px">
                  <Skeleton width={60} height={20} />
                </Box>
              </Box>
              <Box display="inherit" flexDirection="column" paddingY="9.5px">
                <Box padding="9.5px 8px">
                  <Skeleton width={60} height={20} />
                </Box>
                <Box padding="9.5px 8px">
                  <Skeleton width={60} height={20} />
                </Box>
              </Box>
            </Box>
            <Box display="flex" width="100%" height="80px" gridAutoColumns="138px">
              {periods.map((period) => {
                const bgColor = getColor(period);

                return (
                  <Box key={period} display="flex" flexDirection="column" bgcolor={bgColor}>
                    <Box
                      width="138px"
                      height="fit-content"
                      padding="9.5px 16px"
                      borderBottom={`1px solid ${theme.palette.grey[colorTernary(700, 200)]}`}
                    >
                      <Skeleton width={102} height={20} />
                    </Box>
                    <Box width="138px" height="80px" padding="9.5px 16px">
                      <Skeleton width={102} height={20} />
                    </Box>
                  </Box>
                );
              })}
              <TableVerticalAxis gridTemplateColumns="initial" width="100%">
                <TableColumn showSkeleton={false}>&nbsp;</TableColumn>
                <TableColumn showSkeleton={false}>&nbsp;</TableColumn>
              </TableVerticalAxis>
            </Box>
          </Box>
        </TableHorizontalAxis>
      </>
    ) : (
      <Component key={nanoid()} {...props} />
    );
  };

export { useSimulationOperations, Context };
export default withGuarantee;
