import { Box, BoxProps, Icon } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { DateTime } from 'luxon';

import usePortfolio from '~/pages/Portfolio/usePortfolio';
import Functions from '~/theme/icons/Functions';
import UnfoldLess from '~/theme/icons/UnfoldLess';
import UnfoldMore from '~/theme/icons/UnfoldMore';

import { useSimulationTable } from '../../withSimulationTable';
import useStyles from './styles';

export interface TablePeriodProps extends Omit<BoxProps, 'children'> {
  children: string;
  dedupe?: boolean;
}

const TablePeriod = ({ children, dedupe, ...props }: TablePeriodProps) => {
  const { period, icon } = useStyles();
  const { loading } = useSimulationTable();
  const { expandedPeriods, setFilter } = usePortfolio();
  const year = /-/.test(children) ? children.split('-')[0] : children;
  const isExpanded = expandedPeriods?.includes(year);

  const onToggle = () => {
    const periods = isExpanded ? expandedPeriods.filter((period) => period !== year) : [...expandedPeriods, year];

    setFilter('expandedPeriods', periods);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      gridColumnGap="12px"
      justifyContent={dedupe ? 'flex-end' : 'space-between'}
      alignItems="center"
      padding="9.5px 16px"
      color="white"
      fontWeight={500}
      bgcolor="#FA6800"
      className={loading ? undefined : period}
      fontSize="14px"
      height="40px"
      onClick={onToggle}
      {...props}
    >
      {!loading && (
        <>
          {dedupe ? (
            <Functions color="white" width="10px" height="13px" />
          ) : (
            <Icon className={icon} fontSize="small">
              {isExpanded ? <UnfoldLess /> : <UnfoldMore />}
            </Icon>
          )}
        </>
      )}
      {loading ? (
        <Skeleton width={102} height={20} animation="wave" />
      ) : (
        <>
          {/-/.test(children)
            ? DateTime.fromFormat(children, 'yyyy-MM').toFormat('LLL / yy', { locale: 'pt-BR' }).replace('.', '').toUpperCase()
            : children}
        </>
      )}
    </Box>
  );
};

export default TablePeriod;
