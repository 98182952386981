import { gql } from '@apollo/client';

import { SimulationProps } from '@graphql/query/simulation';

export interface CreateSimulationProps {
  errors?: string[];
  simulation: SimulationProps;
}

export interface InputProps {
  name: string | null;
  simulationBaseId?: string;
}

export interface PayloadProps {
  input: InputProps;
}

export interface ResponseProps {
  createSimulation: CreateSimulationProps;
}

const CREATE_SIMULATION = gql`
  mutation createSimulation($input: CreateSimulationInput!) {
    createSimulation(input: $input) {
      simulation {
        id
      }
    }
  }
`;

export default CREATE_SIMULATION;
