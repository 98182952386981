import { useBbceProduct } from '../../..';
import OfferListItem from '../OfferListItem';
import useProductQuote from '../useProductQuote';

function OfferCode() {
  const { offerCode } = useProductQuote();
  const { id } = useBbceProduct();
  const { opportunity } = useProductQuote();
  const isInternalTransfer = opportunity.bbceProduct?.id !== id;

  return <OfferListItem>{isInternalTransfer ? opportunity.bbceProductTransfer?.code : offerCode}</OfferListItem>;
}

export default OfferCode;
