import { Flex, Text } from '@tradener/lumen';

import { useDetails } from '..';

function ConditionsTab() {
  const { quote } = useDetails();
  const informations = [...(quote?.quoteInformation ?? [])];

  return (
    <Flex width="full" align="flex-start" gap="6" padding="2" height="2xl" overflowY="auto">
      <Flex width="full" height="fit-content" overflow="hidden" flexDirection="column" align="flex-start" gap="6">
        <Text textStyle="subheading2-semibold">Condições gerais</Text>
        {informations
          ?.sort((a, b) => a?.order - b?.order)
          .map(({ name, value }, index) => (
            <Flex key={index} width="full" flexDirection="column" align="flex-start" gap="2">
              <Text textStyle="body1-semibold">{name}</Text>
              <Text textStyle="body2-regular">{value}</Text>
            </Flex>
          ))}
      </Flex>
    </Flex>
  );
}

export default ConditionsTab;
