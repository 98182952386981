import { ReactComponent as CalendarOff } from '@DEPRECATED/assets/icons/calendar-off.svg';
import { ReactComponent as CalendarOn } from '@DEPRECATED/assets/icons/calendar-on.svg';
import { DateTimePicker as MuiDateTimePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';

import TextField from '../Input';
import InputAdornment from '../InputAdornment';
import { Container } from './styles';

function DateTimePicker({ disabled, ...other }) {
  return (
    <Container>
      <MuiDateTimePicker
        ampm={false}
        allowKeyboardControl
        disabled={disabled}
        InputProps={{
          endAdornment: <InputAdornment position="end">{disabled ? <CalendarOff /> : <CalendarOn />}</InputAdornment>,
        }}
        inputVariant="outlined"
        disablePast
        format="dd/MMM/yy HH:mm"
        {...other}
        TextFieldComponent={TextField}
      />
    </Container>
  );
}

DateTimePicker.defaultProps = {
  disabled: false,
};

DateTimePicker.propTypes = {
  disabled: PropTypes.bool,
};
export default DateTimePicker;
