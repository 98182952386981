import { darken, lighten } from '@material-ui/core';
import styled, { css, useTheme } from 'styled-components';


export const Outlined = styled.label`
  ${({ theme }) => css`
    display: flex;
    font-size: 16px;
    line-height: 1.5;

    input {
      appearance: none;

      & + span {
        margin-right: 8px;
        margin-bottom: 8px;
      }

      & + span {
        cursor: pointer;
        display: flex;

        justify-content: center;
        align-items: center;

        width: 44px;
        height: 22px;
      }

      & + span {
        border-radius: 2px;
        border: solid 2px #373737;
      }

      &:focus + span::before {
        border-color: #999;
      }

      &:checked + span {
        border-radius: 2px;
        background-color: #E96300;
        ${(theme?.palette?.type === 'light') &&
    `color: #373737` || `color: #cccccc`};
      }

      &:not(:checked) + span {
        ${(theme?.palette?.type === 'light') &&
    `background-color: #DDD` || `background-color: #373737`};
      }

      &:focus + span {
        border-color: #999;
      }

      &:checked:hover + span {
        background-color: ${lighten('#E96300', 0.08)};
      }
    }
  `}
`;

export const Material = styled.label`
  ${({ theme }) => css`
      position: relative;
      display: flex;
      font-size: 16px;
      line-height: 1.5;

      color: inherit;

      input[type='checkbox'] {
        appearance: none;
        z-index: -1;
        position: absolute;
        left: -10px;
        top: -8px;
        display: block;
        margin: 0;
        border-radius: 50%;
        background-color: #373737;
        box-shadow: none;
        outline: none;
        opacity: 0;
        transform: scale(1);
        pointer-events: none;
        transition: opacity 0.3s, transform 0.2s;

        & + span {
          cursor: pointer;
          margin-right: 8px;
        }

        & + span::before {
          box-sizing: content-box;
          content: '';
          display: inline-block;
          box-sizing: border-box;
          margin: 3px 11px 3px 1px;
          border: solid 2px; /* Safari */
          border-color: #373737;
          border-radius: 2px;
          width: 18px;
          height: 18px;
          vertical-align: top;
          transition: border-color 0.2s, background-color 0.2s;
        }

        & + span::after {
          box-sizing: content-box;
          content: '';
          display: block;
          position: absolute;
          top: 3px;
          left: 1px;
          width: 10px;
          height: 5px;
          border: solid 2px transparent;
          border-right: none;
          border-top: none;
          transform: translate(3px, 4px) rotate(-45deg);
        }

        &:checked,
        &:indeterminate {
          background-color: #999;
        }
        &:focus + span::before {
          border-color: #999;
        }

        &:checked + span::before,
        &:indeterminate + span::before {
          border-color: #999;
          background-color: #999;
        }

        &:checked + span::after,
        &:indeterminate + span::after {
          border-color: rgb(255, 255, 255);
        }

        &:indeterminate + span::after {
          border-left: none;
          transform: translate(4px, 3px);
        }

        /* Hover, Focus */
        &:hover {
          opacity: 0.04;
        }

        &:focus {
          opacity: 0.12;
        }

        &:hover:focus {
          opacity: 0.16;
        }

        /* Active */
        &:active {
          opacity: 1;
          transform: scale(0);
          transition: transform 0s, opacity 0s;
        }

        &:active + span::before {
          border-color: #999;
        }

        &:checked:active + span::before {
          border-color: transparent;
          background-color: rgba(0, 0, 0, 0.6);
        }

        /* Disabled */
        &:disabled {
          opacity: 0;
        }

        &:disabled + span {
          color: rgba(0, 0, 0, 0.38);
          cursor: initial;
        }

        &:disabled + span::before {
          border-color: currentColor;
        }

        &:checked:disabled + span::before,
        &:indeterminate:disabled + span::before {
          border-color: transparent;
          background-color: currentColor;
        }
      }

      input[type='radio'] {
        position: absolute;
        top: 4px;
        left: 0;
        width: 18px;
        height: 18px;
        opacity: 0;
        z-index: 0;

        & + span {
          display: block;
          padding-left: 24px;
          cursor: pointer;


          &:before {
            box-sizing: content-box;
            content: '';
            position: absolute;
            top: 3px;
            left: 0;
            width: 16px;
            height: 16px;
            background-color: transparent;
            border: 2px solid #373737;
            border-radius: 14px;
            z-index: 1;
            transition: border-color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
          }

          &:after {
            box-sizing: content-box;
            content: '';
            position: absolute;
            top: 8px;
            left: 5px;
            width: 10px;
            height: 10px;
            background-color: #999;
            border-radius: 50%;
            z-index: 2;
            transform: scale(0, 0);
            transition: transform 0.28s cubic-bezier(0.4, 0, 0.2, 1);
          }
        }

        &:checked + span {
          &:before {
              border-color: #999;
          }

          &:after {
              transform: scale(1, 1);
          }
        }
      }
    }
  `}
`;

export const Contained = styled.label`
  ${({ theme, size }) => css`
    display: flex;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;

    color: white;

    input {
      appearance: none;
      display: none;

      & + span {
        border-radius: 2px;
        border-style: solid;
        border-width: 2px;
        border-color: transparent;
        background-color: white;
      }

      & + span {
        margin-bottom: 16px;
      }

      &:hover + span {
        opacity: 0.9;
      }

      & + span {
        cursor: pointer;
        display: flex;

        justify-content: center;
        align-items: center;

        min-width: 40px;
        width: ${theme.spacing(size)}px;
        height: 40px;
      }

      &:checked + span {
        background-color: #E96300;
        color: white;
      }

      &:not(:checked) + span {
        ${(theme?.palette?.type === 'light') &&
    `background-color: #AAA` || `background-color: #373737`};
      }

      &:focus:not(:checked) + span {
        border-color: ${darken('#eee', 0.05)};
      }
    }
  `}
`;

export const Small = styled.label`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 1.5;
    font-weight: 500;
    word-break: break-word;
    width: 64px;

    input {
      appearance: none;

      & + span {
        border-radius: 2px;
        border-style: solid;
        border-width: 2px;
        border-color: transparent;
        background-color: white;
      }

      & + span {
        margin-right: 6px;
        margin-bottom: 6px;
      }

      &:hover + span {
        opacity: 0.9;
      }

      & + span {
        cursor: pointer;
        display: flex;

        justify-content: center;
        align-items: center;

        min-width: 24px;
        min-height: 24px;
        padding: 6px;
      }

      &:checked + span {
        background-color: #E96300;
        color: white;
      }

      &:not(:checked) + span {
        ${(theme?.palette?.type === 'light') &&
    `background-color: #cccccc` || `background-color: #373737`};
      }

      &:focus:not(:checked) + span {
        border-color: ${darken('#eee', 0.05)};
      }
    }
  `}
`;
