import { BoxProps, GridItem, useTheme } from '@tradener/lumen';

export interface HeaderItemProps extends BoxProps {
  children?: React.ReactNode;
}

export const HeaderItem: React.FC<HeaderItemProps> = ({ children, ...props }) => {
  const color = useTheme().colors.gray[900];

  return (
    <GridItem
      px={3}
      py={2.5}
      textStyle="body2-regular"
      fontWeight={400}
      color="gray.500"
      textTransform="none"
      border="0px"
      borderColor={`${color}14`}
      {...props}
    >
      {children}
    </GridItem>
  );
};
