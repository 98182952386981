/* eslint-disable no-confusing-arrow */
import { useCallback } from 'react';

import { SimulationContractProps, SimulationItemProps } from '@graphql/query/simulationContracts';

import useColorMode from '~/hooks/useColorMode';
import usePeriodComparison from '~/hooks/usePeriodComparison';
import usePortfolioFilter from '~/pages/Portfolio/usePortfolioFilter';
import useNumberFormat from '~/theme/layout/useNumberFormat';

import { useSimulationContract } from '../context';
import ContractEdition from './ContractEdition';

export enum InputStatus {
  'INITIAL',
  'TOUCHED',
  'COMMITTED',
}

export interface ContractPeriodsProps {
  contract: SimulationContractProps;
  excludedContract: boolean;
  periods: string[];
}

const ContractPeriods: React.ComponentType<ContractPeriodsProps> = ({ contract, excludedContract, periods }) => {
  const { colorTernary } = useColorMode();
  const { format } = useNumberFormat();
  const getColor = usePeriodComparison();
  const { view } = usePortfolioFilter();
  const { itemPushList, setItemPushList } = useSimulationContract();
  const { id, simulationItems } = contract;

  const handleInputChange = useCallback(
    (simulationItem: SimulationItemProps | undefined, period: string) => (value: string | undefined) => {
      const parsedValue = Number(parseFloat(value?.replace(/\./g, '').replace(/,/g, '.') || '0.0'));
      const inputToPush = itemPushList.find((item) => item.resourceId === id && item.competence === period);

      const price = view === 'price' ? Number(parsedValue) : (inputToPush ? inputToPush.price : simulationItem?.price) ?? 0.0;
      const quantityMwm =
        view === 'quantityMwm'
          ? Number(parsedValue)
          : (inputToPush ? inputToPush.quantityMwm : simulationItem?.quantityMwm) ?? 0.0;

      if (!inputToPush) {
        setItemPushList((prev) => [...prev, { resourceId: id, competence: period, price, quantityMwm }]);
      } else {
        setItemPushList(
          itemPushList.map((item) =>
            item.resourceId === id && item.competence === period ? { ...item, price, quantityMwm } : item,
          ),
        );
      }
    },
    [id, itemPushList, setItemPushList, view],
  );

  return (
    <>
      {periods.map((period) => {
        const bgColor = getColor(period);
        const simulationItem = simulationItems.find(({ competence }) => competence === period);
        const uniqueId = simulationItem?.id ?? `${id}-${period}`;

        const inputToPush = itemPushList.find((item) => item.resourceId === id && item.competence === period);

        const status = (() => {
          if (simulationItem) {
            if (inputToPush) {
              return InputStatus.TOUCHED;
            } else if (simulationItem?.edited) return InputStatus.COMMITTED;
            else return InputStatus.INITIAL;
          } else return InputStatus.INITIAL;
        })();

        return (
          <ContractEdition
            key={uniqueId}
            bgColor={bgColor}
            borderColor={colorTernary('#525252', '#26262612')}
            handleInputChange={handleInputChange(simulationItem, period)}
            inputStatus={status}
            resourceId={id}
            simulationItem={simulationItem}
            period={period}
            excludedContract={excludedContract}
          >
            {format(inputToPush ? inputToPush?.[view] : simulationItem?.[view], {}, '')}
          </ContractEdition>
        );
      })}
    </>
  );
};

export default ContractPeriods;
