import { useReducer } from 'react';

import { useFormContext } from 'react-hook-form';
import { IoMdTrash } from 'react-icons/io';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { Button, Flex, FlexProps, IconButton, pickByPaths, VisibilityIcon, VisibilityOffIcon } from '@tradener/lumen';

import DELETE_SIMULATION_OPERATION, { PayloadProps, ResponseProps } from '@graphql/mutation/deleteSimulationOperation';
import SIMULATION from '@graphql/query/simulation';

import useColorMode from '~/hooks/useColorMode';
import useExcludedsTemp from '~/pages/Portfolio/hooks/useExcludedsTemp';
import usePortfolio from '~/pages/Portfolio/usePortfolio';
import useSimulation from '~/pages/Portfolio/useSimulation';
import useSimulationRules from '~/pages/Portfolio/useSimulationRules';
import Modal, { ModalHeader, ModalBody, ModalFooter } from '~/theme/components/Modal';

import { FormProps, useSimulationOperations } from '../withGuarantee';

const OperationRemoval: React.ComponentType<FlexProps> = (props) => {
  const { id, operation, submarket, energyType, index } = useSimulationOperations(({ operation, index }) => ({
    ...pickByPaths(operation, 'id', 'operation', 'submarket', 'energyType', 'remove', 'index'),
    index,
  }));

  const { setFilter } = usePortfolio();
  const { excludedContractsTemp } = useExcludedsTemp();
  const { colorTernary, theme } = useColorMode();
  const [deleteSimulationOperation, { loading, client }] = useMutation<ResponseProps, PayloadProps>(DELETE_SIMULATION_OPERATION);
  const { simulationId } = useSimulation();
  const { isReadOnly } = useSimulationRules();
  const [showModal, toggleShowModal] = useReducer((prev) => !prev, false);
  const { setValue, getValues } = useFormContext<FormProps>();
  const isTemporary = id?.startsWith('temp_');

  const remove = (index: number) => {
    const operations = getValues('operations');

    operations.splice(index, 1);

    setValue('operations', operations, { shouldDirty: false, shouldTouch: false, shouldValidate: false });
  };

  const onDelete = async (shouldRemove: boolean) => {
    if (isTemporary) {
      toggleShowModal();
      remove(index);

      return;
    }

    if (shouldRemove) {
      await deleteSimulationOperation({
        variables: {
          input: {
            simulationId: simulationId as string,
            simulationOperationId: id,
          },
        },
        async onCompleted() {
          const operations = getValues('operations');

          operations.splice(index, 1);

          toggleShowModal();

          await client.refetchQueries({ include: [SIMULATION] });

          setValue('operations', operations, { shouldDirty: false, shouldTouch: false, shouldValidate: false });

          toast.success('Operação excluída com sucesso!');
        },
      });
    } else {
      if (id in excludedContractsTemp) {
        const { [id]: _, ...newExcludeds } = excludedContractsTemp;

        setFilter('excludedContracts', newExcludeds);
      } else {
        setFilter('excludedContracts', {
          ...excludedContractsTemp,
          [id]: `${operation === 'PURCHASE' ? 'Compra' : 'Venda'}-${submarket}-${energyType}`,
        });
      }
    }
  };

  return (
    <Flex
      className="removal"
      position="sticky"
      zIndex="2"
      top="0"
      right="0"
      minWidth="100px"
      justifyContent="center"
      boxShadow="0px 0px 7px #00000040"
      bgColor="gray.0"
      marginLeft="-100px"
    >
      <Flex alignItems="center" justifyContent="center" zIndex={theme.zIndex.tooltip} {...props}>
        {!isTemporary && (
          <IconButton aria-label="toggle hidden" colorScheme="gray" variant="ghost" size="sm" onClick={() => onDelete(false)}>
            {id in excludedContractsTemp ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        )}
        {!isReadOnly && (
          <IconButton
            aria-label="delete simulation operation row"
            colorScheme="gray"
            variant="ghost"
            size="sm"
            onClick={toggleShowModal}
          >
            <IoMdTrash style={{ width: '18px', height: '18px', color: `${colorTernary('#CCC', '#262626')}` }} />
          </IconButton>
        )}
        {showModal && (
          <Modal isOpen={showModal} onClose={toggleShowModal} maxWidth={false}>
            <ModalHeader width="560px">
              <Typography variant="h5">Excluir operação</Typography>
            </ModalHeader>
            <ModalBody style={{ height: '24px' }}>
              <Typography variant="body1" style={{ fontSize: '16px', color: `${colorTernary('#797979', '#868686')}` }}>
                Deseja excluir a operação criada?
              </Typography>
            </ModalBody>
            <ModalFooter style={{ height: '88px', gridColumnGap: '8px' }}>
              <Button onClick={toggleShowModal} variant="outline" colorScheme="gray" disabled={loading}>
                Cancelar
              </Button>
              <Button isLoading={loading} onClick={() => onDelete(true)}>
                Excluir
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </Flex>
    </Flex>
  );
};

export default OperationRemoval;
