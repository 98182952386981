import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  menu: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: `${theme.palette.type === 'dark' ? theme.palette.grey[800] : '#FFF'}`,
    padding: '0 8px',
    WebkitTapHighlightColor: 'transparent',
    '&:hover, &:focus': {
      backgroundColor: `${theme.palette.type === 'dark' ? theme.palette.grey[800] : '#FFF'}`,
    },
    '&:first-child': {
      paddingTop: '8px',
    },
    '&:last-child': {
      paddingBottom: '8px',
    },
  },
  menuItemBox: {
    cursor: 'pointer',
    transition: 'none',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    fontSize: '14px',
    padding: '0.469rem 0.75rem',
    borderRadius: '4px',
    height: '36px',
    backgroundColor: `${theme.palette.type === 'dark' ? theme.palette.grey[800] : '#FFF'}`,
    '&:hover, &:focus': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
    },
  },
  arrowDownIcon: {
    width: '6px',
    height: '8px',
    paddingBottom: '2px',
    color: `${theme.palette.type === 'dark' ? '#FFF' : theme.palette.grey[500]}`,
  },
}));

export default useStyles;
