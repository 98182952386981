import { nanoid } from 'nanoid';

import store from '~/context';
import { MessageProps, actions } from '~/context/ducks/notification';
import cable from '~/services/link/cable';
import sleep from '~/utils/sleep';

const received = async (value: MessageProps) => {
  await sleep(500);
  store.dispatch(actions.enqueue(value));
};

const notificationListener = async () =>
  cable.subscriptions.create({ sid: nanoid(), channel: 'WebNotificationsChannel' }, { received });

export default notificationListener;
