import {
  Input,
  InputGroup,
  InputRightElement,
  InputProps,
  IconButton,
  CloseIcon,
  SearchIcon,
  PopoverAnchor,
  Box,
} from '@tradener/lumen';

import { memo } from 'react';

import { Controller } from 'react-hook-form';

import { useAutocomplete } from '..';

export type AutoSearchProps = InputProps;

const SearchField: React.ComponentType<AutoSearchProps> = (props) => {
  const { anchorRef, control, isAnalytics, filtered, productName, reset, onConnect, onEnter, onClear, sendMessage } =
    useAutocomplete();

  return (
    <PopoverAnchor>
      <Box ref={anchorRef}>
        <Controller
          control={control}
          name="searchFor"
          render={({ field: { value, onBlur, onChange, ...field } }) => (
            <InputGroup key="inputGroup" width="74" height="full" textStyle="body2-regular">
              <Input
                {...field}
                bgColor="gray.200"
                textStyle="body2-regular"
                variant="filled"
                colorScheme="gray"
                placeholder="Pesquise por código do produto"
                autoComplete="off"
                overflow="clip"
                size="sm"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                value={value}
                _focus={{ bgColor: 'gray.100', borderColor: 'orange.500' }}
                {...props}
                onKeyDown={onEnter}
                onFocus={() => {
                  onConnect();
                  sendMessage(value);
                }}
                onChange={(event) => {
                  onChange(event);
                  sendMessage(event.target.value);
                }}
                onBlur={() => {
                  isAnalytics && reset({ searchFor: productName });
                  sendMessage('');
                  onBlur();
                }}
              />
              <InputRightElement height="full" color="gray.900">
                <IconButton
                  aria-label="unit-search-icon"
                  variant="ghost"
                  size="sm"
                  colorScheme="gray"
                  color="gray.900"
                  isDisabled={!value || isAnalytics}
                  _hover={{ bgColor: 'transparent' }}
                  _disabled={{ color: 'gray.900' }}
                  onClick={() => {
                    !isAnalytics && onClear();
                  }}
                >
                  {!isAnalytics && filtered ? (
                    <CloseIcon aria-label="close-icon" boxSize="5" />
                  ) : (
                    <SearchIcon aria-label="search-icon" boxSize="5" />
                  )}
                </IconButton>
              </InputRightElement>
            </InputGroup>
          )}
        />
      </Box>
    </PopoverAnchor>
  );
};

export default memo(SearchField);
