import { createIcon } from "@tradener/lumen";

const DashIcon = createIcon({
  displayName: 'DashIcon',
  viewBox: '0 0 13 2',
  path: (
    <>
      <path d="M0.5 1C0.5 0.447715 0.947715 0 1.5 0H4.5C5.05228 0 5.5 0.447715 5.5 1C5.5 1.55228 5.05228 2 4.5 2H1.5C0.947715 2 0.5 1.55228 0.5 1Z" fill="currentColor"/>
      <path d="M7.5 1C7.5 0.447715 7.94772 0 8.5 0H11.5C12.0523 0 12.5 0.447715 12.5 1C12.5 1.55228 12.0523 2 11.5 2H8.5C7.94772 2 7.5 1.55228 7.5 1Z" fill="currentColor"/>
    </>
  )
});

export default DashIcon;
