import ListItemRaw from '@material-ui/core/ListItem';
import TypographyRaw from '@material-ui/core/Typography';
import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const ListItem = styled(ListItemRaw)`
  ${({ theme }) => css`
    && {
      display: flex;
      border-bottom: 1px solid #999;
      margin-top: 24px;
      padding: 0;
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    display: block;
    color: white
    font-size: 14px;
    letter-spacing: 0.8px;
    line-height: 1;
    font-weight: 700;
  `}
`;

export const Typography = styled(TypographyRaw).attrs({
  variant: 'body1',
  component: 'div',
})`
  ${({ theme }) => css`
    && {
      color: #999;
      font-size: 16px;
      letter-spacing: 0.8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 8px;
    }
  `}
`;

export const Name = styled.div`
  ${({ theme }) => css`
    display: block;
    width: 136px;
    height: 45px;
    border-right: 1px solid #999;
  `}
`;

export const Email = styled.div`
  ${({ theme }) => css`
    display: block;
    width: 240px;
    height: 45px;
    border-right: 1px solid #999;
    padding-left: 16px;
  `}
`;

export const Role = styled.div`
  display: flex;
  width: 144px;
  height: 45px;
  align-items: center;
  justify-content: center;
`;
