import { useMemo, useState, useCallback } from 'react';

import { Box, useTheme } from '@material-ui/core';
import { Button, Flex, IconButton, ArrowBackIcon, ArrowFowardIcon, FlexProps } from '@tradener/lumen';
import { DateTime } from 'luxon';

import useCalendar from '~/hooks/useCalendar';

import { useMonthRange } from '..';

import useStyles, { getStyles } from './styles';

export interface RangeOutputProps extends FlexProps {
  onClose: () => void;
}

const RangeOutput: React.ComponentType<RangeOutputProps> = ({ onClose, ...props }) => {
  const { range, hasClear, onApply } = useMonthRange();
  const [lastPick, setLastPick] = useState<'start' | 'end'>('end');
  const [currentRange, setCurrentRange] = useState(range);
  const { palette } = useTheme();
  const styles = useStyles();

  const {
    reference: { year: referenceYear },
    getMonths,
    mutate,
    getPreviousYear,
    getNextYear,
  } = useCalendar({ year: currentRange?.startYear ?? DateTime.now().year });

  const isBetweenYears = useMemo(
    () => referenceYear >= Object(currentRange?.startYear) && referenceYear <= Object(currentRange?.endYear),
    [currentRange.startYear, currentRange.endYear, referenceYear],
  );

  const { months } = getMonths(referenceYear);

  const handleApply: React.MouseEventHandler<HTMLButtonElement> = () => {
    onApply(currentRange);
    onClose();
  };

  const handleOnClear = () => {
    const year = DateTime.now().year;

    onApply({
      startYear: year,
      startMonth: 1,
      endYear: year,
      endMonth: 12,
    });
    onClose();
  };

  const onPrevious = () => mutate(getPreviousYear());

  const onForward = () => mutate(getNextYear());

  const monthPicker = useCallback(
    (month: number) => {
      const year = referenceYear;
      const date = DateTime.fromObject({ year, month }).toMillis();

      if (
        lastPick === 'start' &&
        date > DateTime.fromObject({ year: currentRange.startYear, month: currentRange.startMonth }).toMillis()
      ) {
        setCurrentRange({
          ...currentRange,
          endYear: year,
          endMonth: month,
        });
        setLastPick('end');
      } else if (
        lastPick === 'end' ||
        date < DateTime.fromObject({ year: currentRange.startYear, month: currentRange.startMonth }).toMillis()
      ) {
        setCurrentRange({
          startYear: year,
          startMonth: month,
          endYear: year,
          endMonth: month,
        });
        setLastPick('start');
      }
    },
    [lastPick, currentRange, referenceYear],
  );

  const choseReferenceYear = useCallback(() => {
    setCurrentRange({
      startYear: referenceYear,
      startMonth: 1,
      endYear: referenceYear,
      endMonth: 12,
    });
  }, [referenceYear]);

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      width="280px"
      height="300px"
      padding="20px"
      borderRadius="base"
      boxShadow="base"
      bgColor="gray.0"
      {...props}
    >
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <Flex color="gray.700" textStyle="body1-semibold">
          <Button colorScheme="gray" variant="outline" onClick={() => choseReferenceYear()}>
            {referenceYear}
          </Button>
        </Flex>
        <Flex alignItems="center" justifyContent="center" width="fit-content" gap="2">
          <IconButton
            aria-label="voltar"
            display="flex"
            colorScheme="transparent"
            variant="ghost"
            width="fit-content"
            height="fit-content"
            minWidth="initial"
            boxSize="6"
            onClick={onPrevious}
            disabled={false}
          >
            <ArrowBackIcon boxSize="5" color="gray.500" />
          </IconButton>
          <IconButton
            aria-label="avançar"
            display="flex"
            colorScheme="transparent"
            variant="ghost"
            width="fit-content"
            height="fit-content"
            minWidth="initial"
            boxSize="6"
            onClick={onForward}
          >
            <ArrowFowardIcon boxSize="5" color="gray.500" />
          </IconButton>
        </Flex>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" flexWrap="wrap" rowGap="8px">
        {months.map((name, index) => (
          <Box
            key={`${referenceYear}.${name}`}
            width="120px"
            height="120px"
            className={`${styles.chip} ${index + 1 === currentRange.startMonth && isBetweenYears ? 'from-picked' : ''} ${
              index + 1 === currentRange.endMonth && isBetweenYears ? 'until-picked' : ''
            }`}
            style={getStyles(index, currentRange, referenceYear, isBetweenYears, palette)}
            onClick={() => monthPicker(index + 1)}
          >
            {((index + 1 === currentRange.startMonth && currentRange.startYear === referenceYear) ||
              (index + 1 === currentRange.endMonth && currentRange.endYear === referenceYear)) &&
            isBetweenYears ? (
              <Box
                className={`${styles.chip} ${'from-picked'} ${'until-picked'}`}
                style={{ backgroundColor: palette.primary.main, color: '#EEE', borderRadius: 500 }}
              >
                {name}
              </Box>
            ) : (
              name
            )}
          </Box>
        ))}
      </Flex>
      <Flex justifyContent="flex-end" gap="2">
        {hasClear && (
          <Button colorScheme="gray" variant="outline" onClick={handleOnClear}>
            Limpar
          </Button>
        )}
        <Button colorScheme="orange" variant="solid" onClick={handleApply}>
          Aplicar
        </Button>
      </Flex>
    </Flex>
  );
};

export default RangeOutput;
