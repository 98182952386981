import { Flex, Text } from '@tradener/lumen';

import DashIcon from '~/theme/icons/Dash';
import DashedSquareIcon from '~/theme/icons/DashedSquare';
import LineIcon from '~/theme/icons/Line';
import RoundedSquareIcon from '~/theme/icons/RoundedSquare';

const CustomLegend = ({
  payload,
  alignStart,
  config,
  onClick,
}: {
  payload?: any;
  alignStart?: boolean;
  config: (data: string) => any;
  onClick?: (data: any) => void;
}) => {
  if (payload && payload.length) {
    return (
      <Flex
        alignItems="center"
        justifyContent={alignStart ? 'flex-start' : 'center'}
        width="100%"
        gap="4"
        paddingTop="8"
        marginInline="1.5"
        flexWrap="wrap"
      >
        {payload.map(({ value, dataKey, name }: { value: string | number; dataKey: string; name: string }) => {
          const { iconColor, iconType, text, hidden, translucid, marginLeft, marginRight } = config(dataKey);

          if (hidden) return null;

          return (
            <Flex
              key={name + value}
              align="center"
              justify="flex-start"
              textStyle="caption1-regular"
              gap="2"
              onClick={() => onClick && onClick({ dataKey, value, text })}
              cursor={onClick ? 'pointer' : 'default'}
              opacity={translucid ? 0.5 : 1}
              marginLeft={marginLeft ?? 'unset'}
              marginRight={marginRight ?? 'unset'}
            >
              {iconType === 'square' && (
                <Flex alignItems="center" width="3.5" height="3.5" color={iconColor ?? 'gray.500'}>
                  <RoundedSquareIcon />
                </Flex>
              )}
              {iconType === 'line' && (
                <Flex alignItems="center" width="3.5" height=".125rem" color={iconColor ?? 'gray.500'}>
                  <LineIcon />
                </Flex>
              )}
              {iconType === 'dashed' && (
                <Flex alignItems="center" width="3.5" height=".125rem" color={iconColor ?? 'gray.500'}>
                  <DashIcon />
                </Flex>
              )}
              {iconType === 'dashed-square' && (
                <Flex alignItems="center" width="3.5" height="3.5" color={iconColor ?? 'gray.500'}>
                  <DashedSquareIcon />
                </Flex>
              )}
              <Text color="gray.500" flex="1">
                {text ?? value}
              </Text>
            </Flex>
          );
        })}
      </Flex>
    );
  }

  return null;
};

export default CustomLegend;
