import styled from 'styled-components';

export const Container = styled.div`
  margin: 8px 8px;
`;

export const Header = styled.header`
  border-bottom: solid 1px;
  padding: 8px 0;

  h5 {
    letter-spacing: 0.8px;
  }
`;

export const Body = styled.div`
  margin: 12px 0;
  display: flex;
  flex-wrap: wrap;

  flex: 1;
`;
