import { useQuery } from '@apollo/client';

import TRADING_USERS, { ResponseProps, UserProps } from '@graphql/query/tradingUsers';

import client from '~/services/apollo';

const useCachedTradingUsers = () => {
  const { data } = useQuery<ResponseProps>(TRADING_USERS, { fetchPolicy: 'cache-and-network' });

  return data?.tradingUsers ?? [];
};

const getCachedTradingUsers = () => {
  const cache = client.readQuery({ query: TRADING_USERS });

  return (cache?.tradingUsers as UserProps[]) ?? [];
};

const getTradingUsers = async () => {
  const { data } = await client.query<ResponseProps>({ query: TRADING_USERS });

  return data?.tradingUsers;
};

export { getCachedTradingUsers, getTradingUsers, useCachedTradingUsers };
