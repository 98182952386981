import { ModalHeader, Flex, ModalCloseButton, Text, Heading, Highlight } from '@tradener/lumen';

import { useQuoteOptions, StageActions } from '../../useQuoteOptions';

type TITLE = string;

type DESCRIPTION = string;

type ACTION_DETAILS = [TITLE, DESCRIPTION];

const DISPLAY_ACTION: { [K in StageActions]: ACTION_DETAILS } = {
  CLOSE: ['Aprovar', 'Deseja aprovar {offerCode}?'],
  CANCEL: ['Cancelar', 'Deseja cancelar {offerCode}?'],
  REJECT: ['Rejeitar', 'Deseja rejeitar {offerCode}?'],
  PROPOSE: ['Emitir', 'Deseja emitir {offerCode}?'],
  MOVE: ['Mover para o Book', 'Deseja mover a seguinte oferta de {offerCode} para o Book de Ofertas?'],
};

const getDisplay = (action: StageActions, offerCode: string): ACTION_DETAILS => {
  const [title, description] = DISPLAY_ACTION?.[action] ?? ['', ''];

  return [title, description.replace('{offerCode}', offerCode)];
};

function DialogHeader() {
  const { action, offerCode } = useQuoteOptions();

  if (!offerCode) return null;

  const [title, description] = getDisplay(action, offerCode);

  return (
    <ModalHeader>
      <Flex direction="column" align="flex-start" rowGap="0.5">
        <Heading textStyle="semibold-regular" color="gray.900" fontSize="xl">
          {title}
        </Heading>
        <Text textStyle="body1-regular" color="gray.500" display="inline">
          <Highlight query={offerCode} styles={{ color: 'gray.900' }}>
            {description}
          </Highlight>
        </Text>
      </Flex>
      <ModalCloseButton />
    </ModalHeader>
  );
}

export default DialogHeader;
