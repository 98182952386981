import { Skeleton } from '@tradener/lumen';

function ListSkeleton() {
  return (
    <>
      {new Array(4).fill(0).map((_, index) => (
        <Skeleton key={index} width="max(87rem, calc(100dvw - 7.5rem))" minHeight="20" borderRadius="lg" />
      ))}
    </>
  );
}

export default ListSkeleton;
