import { create } from 'zustand';

export interface SubmitProps {
  name: 'dre' | 'simulation' | 'contracts';
  onSubmit(): Promise<void>;
  onCancel(): void;
}

interface SimulationContextProps {
  queue: SubmitProps[];
  enqueue: (submit: SubmitProps) => void;
  unenqueue: (name: SubmitProps['name']) => void;
  checkSubmitEnqueued: (name: SubmitProps['name']) => boolean;
  clearQueue: () => void;
}

const useSubmitQueue = create<SimulationContextProps>((set, get) => ({
  queue: [],
  enqueue: ({ name, onSubmit, onCancel }) =>
    set((state) => {
      const queue = state.queue.filter((submit) => submit.name !== name);

      return {
        queue: [...queue, { name, onSubmit, onCancel }],
      };
    }),
  unenqueue: (name) => set((state) => ({ queue: state.queue.filter((submit) => submit.name !== name) })),
  checkSubmitEnqueued: (name) => get().queue.some((submit) => submit.name === name),
  clearQueue: () => set({ queue: [] }),
}));

export default useSubmitQueue;
