import { useFormContext } from 'react-hook-form';

import { FormControl, FormLabel, Select, Option, Skeleton } from '@tradener/lumen';

import DEFAULT_ACCOUNTS from '~/utils/getDefaultAccounts';

import { useOffer } from '../context';

function PickTenant() {
  const { setValue, watch } = useFormContext();
  const { quoteLoading } = useOffer();
  const operation = watch('operation');
  const buyerId = watch('buyerId');
  const sellerId = watch('sellerId');
  const filteredAccounts = DEFAULT_ACCOUNTS.filter(({ name }) => /^(pierp|tradener|tradener varejista|tradener rs)$/i.test(name));

  const onChange = (value: string | number) => {
    setValue(operation === 'SALE' ? 'sellerId' : 'buyerId', value);
    setValue(
      operation === 'SALE' ? 'sellerName' : 'buyerName',
      filteredAccounts.find((account) => account.accountId === value)?.name,
    );
  };

  return (
    <FormControl
      width="full"
      sx={{
        '& [role="dialog"]': {
          maxHeight: '200px',
        },
        '& [role="radiogroup"]': {
          overflow: 'auto !important',
        },
      }}
    >
      <FormLabel data-testid="modal-tenant">{operation === 'SALE' ? 'Vendedor' : 'Comprador'}</FormLabel>
      <Skeleton isLoaded={!quoteLoading}>
        <Select
          placeholder="Selecione"
          borderRadius="base"
          width="full"
          closeOnSelect={true}
          isToggleable={false}
          gap="2.5"
          bgColor="gray.100"
          defaultValue={operation === 'SALE' ? sellerId : buyerId}
          onChange={onChange}
        >
          {filteredAccounts?.map((account) => (
            <Option key={account.accountId} value={account.accountId}>
              {account.name}
            </Option>
          ))}
        </Select>
      </Skeleton>
    </FormControl>
  );
}

export default PickTenant;
