import { FormControl, FormLabel, Input } from '@tradener/lumen';

import useComplementForm from '../useComplementForm';

function PickGiro() {
  const { register } = useComplementForm();

  return (
    <FormControl display="flex" flexDirection="column" gap="1.5">
      <FormLabel margin="0">Giro</FormLabel>
      <Input id="giro" {...register('giro')} />
    </FormControl>
  );
}

export default PickGiro;
