import { useMemo } from 'react';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

import CustomChip from '../CustomChip';
import { ListItem, Name, Email, Role, Label, Typography } from './styles';

function Contact({ role, name, email, onClick }) {
  const checked = useMemo(() => role === 'commercial', [role]);

  return (
    <ListItem>
      <Name>
        <Label>Nome</Label>
        <Tooltip title={name ?? ""} placement="top" >
          <Typography>{name}</Typography>
        </Tooltip>
      </Name>
      <Email>
        <Label>Email</Label>
        <Tooltip title={email} placement="top">
          <Typography>{email}</Typography>
        </Tooltip>
      </Email>
      <Role>
        <ListItemIcon>
          <CustomChip checked={checked} onClick={() => onClick(email)} />
        </ListItemIcon>
      </Role>
    </ListItem>
  );
}

Contact.propTypes = {
  role: PropTypes.oneOf(['commercial', '']).isRequired,
  name: PropTypes.string,
  email: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Contact;
