import { lighten, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette, typography, zIndex }) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '0.5rem 4px 0.5rem 16px',
    backgroundColor: lighten(palette.grey[palette.type === 'dark' ? 900 : 100], 0.1),
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: palette.grey[palette.type === 'dark' ? 700 : 100],
  },
  paper: {
    backgroundColor: palette.type === 'dark' ? palette.grey[800] : palette.common.white,
    borderLeftWidth: '1px',
    borderLeftStyle: 'solid',
    borderLeftColor: palette.grey[palette.type === 'dark' ? 700 : 100],
    zIndex: zIndex.appBar + 1,
    position: 'relative',
    width: '282px',
  },
  label: typography.body2,
}));

export default useStyles;
