import { useEffect, useMemo, useState } from 'react';

import { useNavigate, useParams, useLocation} from 'react-router-dom';
import { toast } from 'react-toastify';

import Dialog from '@DEPRECATED/components/Dialog';
import client from '~/services/apollo';
import { useQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import gql from 'graphql-tag';

import Contact from './Contact';
import { Container, Header, Content, Footer } from './styles';

export const GET_QUOTE_CONTACTS = gql`
  query quote($id: ID!) {
    quote(id: $id) {
      id
      proposalUrl
      opportunity {
        proposalContacts {
          id
          email
          name
          proposalUrl
        }
        channel
      }
    }
  }
`;

export const SEND_PROPOSAL_EMAIL = gql`
  mutation sendProposalEmail($input: SendProposalEmailInput!) {
    sendProposalEmail(input: $input) {
      clientMutationId
    }
  }
`;

export default function SendProposal() {
  const { id } = useParams();
  const [contacts, setContacts] = useState([]);
  const push = useNavigate();
  const location = useLocation();

  const { data } = useQuery(GET_QUOTE_CONTACTS, {
    fetchPolicy: 'network-only',
    variables: { id },
  });

  const recipientIds = useMemo(() => contacts.filter((contact) => contact.checked).map((contact) => contact.id), [contacts]);

  const disablePropose = !recipientIds.length;

  function handleClose() {
    const path = location.pathname;

    if (path.includes('trading/list')) {
      push('/trading/list');
      return;
    }

    push('/book/list');
  }

  async function handleSend() {
    try {
      await client.mutate({
        mutation: SEND_PROPOSAL_EMAIL,
        variables: {
          input: { quoteId: id, recipientIds },
        },
      });

      toast.success('Proposta enviada com sucesso!');
      handleClose();
    } catch (error) {
      toast.error(error.message);
    }
  }



  function handleChange(contact) {
    setContacts((prevState) =>
      prevState.map((state) => {
        if (state.id === contact) {
          state.checked = !state.checked;
        }

        return state;
      }),
    );
  }

  useEffect(() => {
    setContacts(() => {
      if (!data) return [];

      return data?.quote.opportunity.proposalContacts
        .map((contact) => ({
          ...contact,
          checked: true,
          quoteId: data.quote.id,
        }));
    });
  }, [data]);

  if (data && data?.quote?.opportunity?.channel === 'portal') return null;

  return (
    <Dialog open disableEscapeKeyDown title="Proposta" id={id} onClose={handleClose}>
      <Container>
        <Header>
          <Typography variant="h5">Confirme a lista de contatos para envio da proposta:</Typography>
        </Header>
        <Content>
          {contacts.map((contact) => (
            <Contact key={contact.id} {...contact} onChange={handleChange} />
          ))}
        </Content>

        <Footer>
          <Divider light />
          <div>
            <Button
              variant="contained"
              color="secondary"
              href={data?.quote.proposalUrl}
              disabled={!data?.quote.proposalUrl}
              target="blank"
            >
              Baixar PDF
            </Button>
            <Button variant="contained" color="primary" onClick={handleSend} disabled={disablePropose}>
              Enviar Proposta
            </Button>
          </div>
        </Footer>
      </Container>
    </Dialog>
  );
}
