import { useRef, useMemo, useState, useCallback, forwardRef } from 'react';

import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import { useQuery } from '@apollo/client';
import {
  Box,
  AppBar,
  Button,
  useTheme,
  IconButton,
  Typography,
  OutlinedInput,
  CircularProgress,
  Table as MuiTable,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
} from '@material-ui/core';
import MuiModal, { ModalProps as MuiModalProps } from '@material-ui/core/Modal';
import { Close, Search } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { DateTime } from 'luxon';

import * as portfolioContracts from '@graphql/query/contracts';
import { PortfolioContractsProps, ContractsProps, ContractSubmarketProps, PayloadProps } from '@graphql/query/contracts';
import type { PortfolioProps } from '@graphql/query/portfolio';

import Convert from '~/pages/Portfolio/PortfolioTable_DEPRECATED/Header/Convert';
import usePortfolio from '~/pages/Portfolio/usePortfolio';
import { OpenTypes } from '~/pages/Portfolio/usePortfolioFilter';

import Table, { ImperativeTableProps } from './Table';
import useStyles from './styles';

export interface ResponseProps {
  portfolio: Pick<PortfolioContractsProps, 'purchaseContracts' | 'saleContracts'>;
}

export interface ModalProps extends Omit<MuiModalProps, 'children' | 'open' | 'onClose'> {
  onClose: () => void;
  open: OpenTypes;
  dispatch: React.Dispatch<React.SetStateAction<OpenTypes | undefined>>;
  balance: PortfolioProps['balance'] | undefined;
}

export interface DataProps extends ContractSubmarketProps {
  checked?: boolean;
}

let concurrency: NodeJS.Timeout;

const Modal = forwardRef<unknown, ModalProps>(({ open, balance, onClose, dispatch, ...props }, ref) => {
  const { palette } = useTheme();
  const { root, input, wrapper, sticky, table } = useStyles();
  const [search, setSearch] = useState<string>();
  const { excludedContracts, ...state } = usePortfolio();
  const { setFilter } = usePortfolio();
  const excludedsRef = useRef(Object.keys(excludedContracts));
  const parentRef = useRef<HTMLDivElement>(null);

  const fragment = useMemo(() => (!!open && open === 'COMPRA' ? 'purchaseContracts' : 'saleContracts'), [open]);

  const { loading, data } = useQuery<ResponseProps, PayloadProps>(portfolioContracts[fragment], {
    variables: { excludedContracts: Object.keys(excludedContracts), ...state },
    fetchPolicy: 'cache-first',
  });

  const periods = useMemo(() => balance?.map(({ period }) => period) ?? [], [balance]);

  const indicatives = useMemo(
    () =>
      periods.map((period) => {
        if (/^\d{4}-\d{2}$/.test(period)) {
          const [year, month] = period.split('-').map((date) => parseInt(date, 10));

          return DateTime.fromObject({ year, month }).setLocale('pt-br').toFormat('LLL / yy').replace('.', '').toUpperCase();
        }

        return period;
      }),
    [periods],
  );

  const contracts = useMemo(() => {
    if (!data?.portfolio?.[fragment]) return null;
    if (!search) return data.portfolio[fragment];

    const cache: ContractsProps = {} as ContractsProps;

    for (const [key, value] of Object.entries(data.portfolio[fragment])) {
      try {
        if (value) {
          const regExp = new RegExp(search, 'i');

          cache[key] = [];

          for (const current of value) {
            const found = [current?.accountName, current?.contractNumber].find((value) => !!regExp.exec(value));

            if (found) cache[key].push(current);
          }
        }
      } catch (exception) {
        return null;
      }
    }

    return cache;
  }, [fragment, data, search]);

  const handleSearch = ({ target }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (concurrency) clearTimeout(concurrency);
    concurrency = setTimeout(() => {
      setSearch(target.value);
    }, 500);
  };

  const filterIndicatives = useCallback(
    (value: Array<string>) => {
      if (state?.usageFactorByEnergyType) value = ['C. FONTE', ...value];
      if (state?.usageFactorByContract) value = ['C. CONTRATO', ...value];
      if (state?.usageFactor) value = ['C. PERÍODO', ...value];

      return value;
    },
    [state?.usageFactorByContract, state?.usageFactorByEnergyType, state?.usageFactor],
  );

  const contractsObjects = useMemo(() => {
    if (contracts) {
      const { __typename: _, ...rest } = contracts as any;

      let results: Array<ContractSubmarketProps> = [];

      for (const [_, value] of Object.entries(rest as ContractsProps)) {
        if (value) results = [...results, ...(value as typeof results)];
      }

      return results;
    } else {
      return [];
    }
  }, [contracts]);

  const handleApply = useCallback(() => {
    if (search) setSearch(undefined);

    const value: Array<string> = [];

    for (const contract of excludedsRef.current) value.push(contract);

    const excludedContractsObj = {} as typeof excludedContracts;

    value.forEach((contractId) => {
      const contract = contractsObjects.find((current) => current.id === contractId);

      if (contract) {
        excludedContractsObj[contractId] = contract.contractNumber;
      }
    });

    setFilter('excludedContracts' as any, excludedContractsObj);

    dispatch(undefined);
  }, [contractsObjects, dispatch, search, setFilter]);

  const parse = useCallback(
    (data: Array<DataProps> | undefined) => {
      if (!data || !Array.isArray(data)) return [];

      const contracts = data.map((contract) => {
        if (contract) return { ...contract, checked: !(contract.id in excludedContracts) };

        return contract;
      });

      return contracts.filter((current) => !!current);
    },
    [excludedContracts],
  );

  const mergeRefs = useCallback(
    (current: ImperativeTableProps | null) => {
      if (current && contracts) {
        const ids: Array<string> = [];

        for (const region of Object.values(contracts)) {
          for (const { id } of parse(region)) ids.push(id);
        }

        excludedsRef.current = [
          ...new Set([...excludedsRef.current.filter((id) => !current.preserveds.includes(id)), ...current.excludeds]),
        ];
      }
    },
    [contracts, parse],
  );

  return (
    <MuiModal ref={ref} className={root} open={!!open} {...props}>
      <ScrollSync>
        <Box className={wrapper}>
          <AppBar position="sticky" color="transparent" elevation={0}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" height="58px" mb="11px">
              <Typography variant="h2" style={{ textTransform: 'capitalize', fontWeight: 500 }}>
                {open?.toLowerCase()}
              </Typography>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <OutlinedInput
                classes={{ root: input }}
                placeholder={`${open === 'COMPRA' ? 'Pesquisar vendedor' : 'Pesquisar comprador'}, Numero ou CNPJ`}
                defaultValue={search}
                endAdornment={<Search color="secondary" />}
                onChange={handleSearch}
                disabled={loading}
              />
              <Convert disabled={loading} />
            </Box>
            <ScrollSyncPane>
              <Box overflow="hidden" marginTop="10px">
                <MuiTable classes={{ root: table }}>
                  <MuiTableHead>
                    <MuiTableRow>
                      <MuiTableCell width="350px" classes={{ root: sticky }} />
                      {filterIndicatives(indicatives)?.map((title) => (
                        <MuiTableCell key={title} width={`${/\d+/.test(title) ? 80 : 110}px`} height="40px" align="right">
                          {loading ? (
                            <Skeleton variant="rect" animation="wave" width={80} height={20} />
                          ) : (
                            <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                              {title}
                            </Typography>
                          )}
                        </MuiTableCell>
                      ))}
                      <MuiTableCell />
                    </MuiTableRow>
                  </MuiTableHead>
                </MuiTable>
              </Box>
            </ScrollSyncPane>
          </AppBar>
          {loading ? (
            <Box width="100%" height="calc(100% - 220px)" display="flex" alignItems="center" justifyContent="center">
              <CircularProgress color="secondary" />
            </Box>
          ) : (
            <>
              <ScrollSyncPane>
                <div
                  ref={parentRef}
                  style={{
                    overflow: 'auto',
                    height: 'calc(100% - 220px)',
                    borderBottom: `1px solid ${palette.grey[palette.type === 'dark' ? 600 : 200]}`,
                  }}
                >
                  {[
                    { region: 'SE/CO', key: 'se' },
                    { region: 'Sul', key: 's' },
                    { region: 'Nordeste', key: 'ne' },
                    { region: 'Norte', key: 'n' },
                  ].map(({ key, ...props }) => (
                    <Table
                      key={key}
                      ref={mergeRefs}
                      periods={periods}
                      searching={!!search}
                      data={parse(contracts?.[key])}
                      {...props}
                    />
                  ))}
                </div>
              </ScrollSyncPane>
              <Box display="flex" justifyContent="flex-end" flexDirection="row" paddingTop="20px">
                <Button color="primary" variant="contained" onClick={handleApply}>
                  Aplicar
                </Button>
              </Box>
            </>
          )}
        </Box>
      </ScrollSync>
    </MuiModal>
  );
});

Modal.displayName = 'Modal';

export default Modal;
