import { Box, BoxProps, Typography } from '@material-ui/core';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { DateTime } from 'luxon';

import usePortfolio from '~/pages/Portfolio/usePortfolio';
import Functions from '~/theme/icons/Functions';

import useStyles from './styles';

export interface PeriodColumnsProps extends BoxProps {
  periods: string[];
  handleExpandedPeriods: (period: string) => void;
}

const PeriodColumns: React.ComponentType<PeriodColumnsProps> = ({ periods, handleExpandedPeriods, ...props }) => {
  const { head } = useStyles();
  const { expandedPeriods } = usePortfolio();

  return (
    <>
      {periods.map((period) => {
        const isMonthly = period.includes('-');
        const date = DateTime.fromFormat(period, isMonthly ? 'yyyy-MM' : 'yyyy');
        const dedupe = expandedPeriods.includes(period);

        return (
          <Box
            key={period}
            display="flex"
            flexDirection="row"
            onClick={() => handleExpandedPeriods(period)}
            className={head}
            justifyContent="flex-end"
            alignItems="center"
            {...props}
          >
            <Box display="flex" alignItems="center" justifyContent="center">
              {isMonthly ? (
                <UnfoldLessIcon fontSize="small" style={{ transform: 'rotate(90deg)' }} />
              ) : (
                <UnfoldMoreIcon fontSize="small" style={{ transform: 'rotate(90deg)' }} />
              )}
              {dedupe && <Functions width="9px" height="11px" style={{ margin: '0 12px 5px 12px', display: 'block' }} />}
            </Box>
            <Typography
              variant="body2"
              color="inherit"
              style={{
                textTransform: 'uppercase',
                userSelect: 'none',
                fontWeight: 500,
              }}
            >
              {date
                .setLocale('pt-br')
                .toFormat(isMonthly ? 'LLL / yy' : 'yyyy')
                .replace('.', '')}
            </Typography>
          </Box>
        );
      })}
    </>
  );
};

export default PeriodColumns;
