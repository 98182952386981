import { useAutocomplete } from '..';

import { Text, Highlight, PopoverBody, Button, PopoverBodyProps, PopoverContent } from '@tradener/lumen';

export type AutoOptionsProps = Omit<PopoverBodyProps, 'onClose' | 'children'>;

const AutoOptions: React.ComponentType<AutoOptionsProps> = ({ ...props }) => {
  const { data, onSelectOption, watch } = useAutocomplete();
  const { searchFor } = watch();

  if (!data?.length) return null;

  return (
    <PopoverContent width="full" overflow="auto" boxShadow="base" maxH="40">
      <PopoverBody
        key="menu-list"
        width="full"
        padding="2"
        bgColor="gray.0"
        borderColor="transparent"
        borderRadius="lg"
        overflow="auto"
        {...props}
      >
        {data?.map((option) => (
          <Button
            key={option?.id}
            value={option?.id}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            colorScheme="gray"
            bgColor="gray.0"
            width="full"
            height="9"
            py="2.5"
            px="3"
            gap="2.5"
            borderRadius="base"
            overflow="clip"
            _hover={{ bgColor: 'gray.100' }}
            onClick={onSelectOption}
          >
            <Text overflowX="clip" textOverflow="ellipsis" whiteSpace="nowrap" color="gray.900">
              <Highlight key={option + 'hl'} query={searchFor ?? ''} styles={{ color: 'gray.900', fontWeight: 'bold' }}>
                {option?.name}
              </Highlight>
            </Text>{' '}
            <Text textStyle="body1-regular" color="gray.500">
              {option.taxId}
            </Text>
          </Button>
        ))}
      </PopoverBody>
    </PopoverContent>
  );
};

export default AutoOptions;
