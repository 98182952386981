import { lighten } from '@material-ui/core';

import useColorMode from '~/hooks/useColorMode';
import usePortfolio from '~/pages/Portfolio/usePortfolio';
import { grey } from '~/theme';
import getPeriodTime from '~/utils/getPeriodTime';

const usePeriodComparison = () => {
  const { colorTernary } = useColorMode();
  const { expandedPeriods } = usePortfolio();

  return (period: string) => {
    const color = {
      BEFORE: colorTernary(lighten(grey[800], 0.025), '#F6F6F6'),
      CURRENT: colorTernary(lighten(grey[800], 0.05), '#EEE'),
      AFTER: 'inherit',
    }[getPeriodTime(period)] as string;

    return !/-/.test(period) && expandedPeriods.includes(period) ? colorTernary('#FA680029', '#fff3ea') : color;
  };
};

export default usePeriodComparison;
