import { forwardRef } from 'react';

import { Chip as MuiChip, ChipProps } from '@material-ui/core';

import useStyles from './styles';

const Chip = forwardRef<HTMLInputElement, ChipProps>((props, ref) => {
  const classes = useStyles();

  return <MuiChip ref={ref} classes={classes} {...props} />;
});

export default Chip;
