import { ReactComponent as Storm } from '@DEPRECATED/assets/icons/storm.svg';

import { Container } from './styles';

export default function Loader() {
  return (
    <Container>
      <span className="circle" />
      <span className="circle" style={{ animationDelay: '1s' }} />
      <span className="circle" style={{ animationDelay: '2s' }} />
      <Storm color="#AAA"/>
    </Container>
  );
}

Loader.defaultProps = {};
