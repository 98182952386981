import { Box, BoxProps, IconButton } from '@material-ui/core';

import useColorMode from '~/hooks/useColorMode';
import { typography, grey } from '~/theme';
import OpenInNew from '~/theme/icons/OpenInNew';
import UnfoldLess from '~/theme/icons/UnfoldLess';
import UnfoldMore from '~/theme/icons/UnfoldMore';

export interface TableHeadingProps extends BoxProps {
  isOpen?: boolean;
  onToggle?: () => void;
  onOpen?: () => void;
}

const TableHeading: React.ComponentType<TableHeadingProps> = ({ isOpen, onToggle, onOpen, children, ...props }) => {
  const { colorTernary } = useColorMode();

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      padding="9.5px 16px"
      borderBottom={`1px solid ${grey[colorTernary(700, 200)]}`}
      fontWeight="600"
      whiteSpace="nowrap"
      {...typography.body2}
      {...props}
    >
      {children}
      <Box display="flex" flexDirection="row" alignItems="center">
        {onToggle && (
          <IconButton onClick={onToggle} size="small" style={{ border: 'initial' }}>
            {isOpen ? <UnfoldLess color="#CBCBCB" /> : <UnfoldMore color="#CBCBCB" />}
          </IconButton>
        )}
        {onOpen && (
          <IconButton onClick={onOpen} size="small" style={{ border: 'initial' }}>
            <OpenInNew color="#CBCBCB" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default TableHeading;
