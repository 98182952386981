import { NumericFormat } from 'react-number-format';

import { DeleteIcon, Flex, IconButton, Input, InputGroup, InputLeftElement, Text, useTheme } from '@tradener/lumen';

import { useOperationLimits } from '~/pages/OperationLimits/hooks/useOperationLimits';

export interface ItemProps {
  purchaseLimit?: number;
  saleLimit?: number;
  startRange?: number;
  endRange?: number;
  index: number;
}

export const Item: React.FC<ItemProps> = ({ purchaseLimit, saleLimit, startRange, endRange, index }) => {
  const { removeLimitRange, editField } = useOperationLimits();
  const color = useTheme().colors.gray[900];

  const handleChange = (label: string, value: string) => {
    const formattedValue = value.replace(/\./g, '').replace(',', '.');

    editField(index, label, Number(formattedValue));
  };

  return (
    <Flex alignItems="end" gap={3} p={3} border="solid 1px" borderColor={`${color}14`} borderRadius=".75rem">
      <Flex flexDir="column" gap={1} flex={1}>
        <Text textStyle="body2-medium">Início</Text>
        <InputGroup>
          <InputLeftElement pointerEvents="none" color="gray.500" textStyle="body1-regular">
            M
          </InputLeftElement>
          <Input
            type="number"
            defaultValue={startRange}
            placeholder="-"
            onChange={(e) => handleChange('startRange', e.target.value)}
          />
        </InputGroup>
      </Flex>
      <Flex flexDir="column" gap={1} flex={1}>
        <Text textStyle="body2-medium">Fim</Text>
        <InputGroup>
          <InputLeftElement pointerEvents="none" color="gray.500" textStyle="body1-regular">
            M
          </InputLeftElement>
          <Input
            type="number"
            defaultValue={endRange}
            placeholder="-"
            onChange={(e) => handleChange('endRange', e.target.value)}
          />
        </InputGroup>
      </Flex>
      <Flex flexDir="column" gap={1} flex={3}>
        <Text textStyle="body2-medium">Compra</Text>
        <InputGroup>
          <InputLeftElement pointerEvents="none" color="gray.500" textStyle="body1-regular">
            R$
          </InputLeftElement>
          <Input
            as={NumericFormat}
            thousandSeparator="."
            decimalSeparator=","
            fixedDecimalScale
            decimalScale={2}
            defaultValue={purchaseLimit}
            placeholder="-"
            onChange={(e) => {
              handleChange('purchaseLimit', e.target.value);
            }}
          />
        </InputGroup>
      </Flex>
      <Flex flexDir="column" gap={1} flex={3}>
        <Text textStyle="body2-medium">Venda</Text>
        <InputGroup>
          <InputLeftElement pointerEvents="none" color="gray.500" textStyle="body1-regular">
            R$
          </InputLeftElement>
          <Input
            as={NumericFormat}
            thousandSeparator="."
            decimalSeparator=","
            fixedDecimalScale
            defaultValue={saleLimit}
            decimalScale={2}
            placeholder="-"
            onChange={(e) => {
              handleChange('saleLimit', e.target.value);
            }}
          />
        </InputGroup>
      </Flex>
      <IconButton
        variant="outline"
        colorScheme="gray"
        borderColor={`${color}14`}
        icon={<DeleteIcon fontSize={24} />}
        aria-label={'Deletar'}
        onClick={() => removeLimitRange(index)}
      />
    </Flex>
  );
};
