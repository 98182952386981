import { withStyles } from '@material-ui/core';
import TableBodyRaw from '@material-ui/core/TableBody';
import TableCellRaw from '@material-ui/core/TableCell';

export const TableCell = withStyles((theme) => {
  const colorLeft = (theme?.palette?.type === 'light') &&
    `#7c7c7c` || `#FFF`;
  const backgroundColorLeft = (theme?.palette?.type === 'light') &&
  `#FFF` || `#232323`;

  return ({
    root: {
      border: 'inherit',
      color: '#7c7c7c',

      '&.negative': {
        color: '#ff0046',
      },
    },
    head: {
      fontSize: 16,
      lineHeight: '1.5px',
      letterSpacing: '0.4px',
    },
    body: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '19px',
    },
    footer: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '19px',
      backgroundColor: backgroundColorLeft,
      borderTop: '1px solid #232323',

      '&:last-child': {
        fontSize: 18,
        lineHeight: '24px',
      },
    },

    alignLeft: {
      fontSize: 16,
      lineHeight: '1.5px',
      letterSpacing: '0.4px',
      color: colorLeft,
    },

    sizeSmall: {
      padding: '8px 8px',
    },
  })
})(TableCellRaw);

export const TableBody = withStyles((theme) => ({
  root: {
    '& > :not(:first-child)': {
      borderTop: `1px solid #232323`,
    },
  },
}))(TableBodyRaw);
