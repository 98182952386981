import { gql } from '@apollo/client';

export interface UserProps {
  id: string;
  name: string;
  email: string;
}

export interface ResponseProps {
  tradingUsers: UserProps[];
}

const TRADING_USERS = gql`
  query tradingUsers {
    tradingUsers {
      id
      name
      email
    }
  }
`;

export default TRADING_USERS;
