import gql from 'graphql-tag';

export interface OperationLimitsDetailsSerieTypeProps {
  purchaseLimit?: number;
  saleLimit?: number;
  competence?: string;
  period?: number;
  startRange?: number;
  endRange?: number;
}

export interface PayloadProps {
  accountId: string;
  grouped?: boolean;
}

export interface OperationLimitDetailProps {
  accountName?: string;
  taxId?: string;
  series?: OperationLimitsDetailsSerieTypeProps[];
}

export interface ResponseProps {
  operationLimitsDetail: OperationLimitDetailProps;
}

const DETAILS_OPERATION_LIMITS = gql`
  query operationLimitsDetail($accountId: ID!, $grouped: Boolean) {
    operationLimitsDetail(accountId: $accountId, grouped: $grouped) {
      accountName
      taxId
      series {
        purchaseLimit
        saleLimit
        competence
        period
        startRange
        endRange
      }
    }
  }
`;

export default DETAILS_OPERATION_LIMITS;
