import styled from 'styled-components';

const isStaging = process.env.REACT_APP_ROLLBAR_ENV === 'staging';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  overflow-x: auto;
  overflow-y: auto;
  width: calc(100vw - 72px);

  & .apexcharts-gridline {
    stroke: ${({ color }) => color};
  }

  @media screen and (max-width: 600px) {
    .widget__container {
      margin-bottom: 8px;
    }
  }

  @media screen and (min-width: 600px) {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 144px 1fr;

    height: ${isStaging ? `calc(100vh - 116px)` : `calc(100vh - 72px)`};

    .side {
      grid-column-start: 2;
      grid-row-start: 1;
      grid-row-end: 3;
    }

    .body {
      grid-column-start: 1;
    }
  }
`;
