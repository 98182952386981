import { makeStyles, Theme } from '@material-ui/core';
import { Styles } from '@material-ui/styles';

import { PLDStatus } from '.';

const useStyles = (status: PLDStatus) =>
  makeStyles(({ palette }) => {
    const styles: Styles<Theme, object, 'cell'> = {
      cell: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '9.5px 16px',
        userSelect: 'none',
        transition: 'background-color 0.25s ease-in-out',
        '& > button': { display: 'none' },
        '&:hover > button': { display: 'block' },
      },
    };

    if (status === PLDStatus.INITIAL) {
      styles['cell'] = {
        ...styles.cell,
        borderBottom: `1px solid ${palette.grey[palette.type === 'dark' ? 700 : 200]}`,
        '&:hover': { backgroundColor: `${palette.type === 'dark' ? '#373737' : '#EEEEEE'}` },
      };
    }

    if (status === PLDStatus.TOUCHED) {
      styles['cell'] = {
        ...styles.cell,
        backgroundColor: 'rgba(255, 182, 36, 0.12)',
        outline: '2px dashed #FFB624',
        outlineOffset: '-1px',
        borderRadius: '4px',
        '& > button, & > button:hover': { backgroundColor: '#FFB624' },
      };
    }

    if (status === PLDStatus.COMMITTED) {
      styles['cell'] = {
        ...styles.cell,
        backgroundColor: 'rgba(67, 131, 247, 0.08)',
        outline: '2px solid #4383F7',
        outlineOffset: '-1px',
        borderRadius: '4px',
        '& > button, & > button:hover': { backgroundColor: '#4383F7' },
      };
    }

    return styles;
  });

export default useStyles;
