import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  body1: {
    fontFamily: 'Archivo',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '21px',
  },
  body2: {
    fontFamily: 'Archivo',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '17px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  factors: {
    justifySelf: 'flex-end',
    fontFamily: 'Archivo',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '17px',
    width: '80px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    paddingInline: '10px',
    textAlign: 'right',
  },
}));

export default useStyles;
