import { MenuDivider } from '@tradener/lumen';

import { Stages } from '@graphql/query/productOperations';

import { useQuoteOptions } from '../../useQuoteOptions';

export interface StageDividerProps {
  stages: Uppercase<Stages>[];
}

const StageDivider: React.ComponentType<StageDividerProps> = ({ stages }) => {
  const { getStageAccess } = useQuoteOptions();

  if (!getStageAccess(stages)) return null;

  return <MenuDivider />;
};

export default StageDivider;
