import { makeStyles, Theme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createPalette' {
  interface TypeBackground {
    light: React.CSSProperties['color'];
  }
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  formControl: {
    '& > .MuiOutlinedInput-root': {
      borderRadius: '2px',
      borderWidth: '2px',
    },
  },
  listItemText: {
    letterSpacing: '0.35px',
    font: 'Medium 14px/2px Halyard Display',
    '& span': {
      fontSize: '14px !important',
    },
  },
  menuItem: {
    padding: 0,
  },
}));

export default useStyles;
