import { Card, Flex, Text } from '@tradener/lumen';
import { DateTime } from 'luxon';
import { TooltipProps } from 'recharts';

import LineIcon from '~/theme/icons/Line';

import { GraphProps } from '../../priceKeys';

function format(value?: number) {
  return value ? value.formatCurrency('pt-BR', { style: 'currency', currency: 'BRL' }) : '-';
}

const PricesTooltip: React.ComponentType<TooltipProps<number[], string>> = ({ active, payload: data }) => {
  if (!active || !data) return null;

  const payload = data?.[0]?.payload as GraphProps | undefined;
  const [open, close] = payload?.range ?? [undefined, undefined];

  return (
    <Card boxShadow="md" minW="44" paddingBlock="2.5" paddingInline="3" gap="1">
      <Text textStyle="body2-medium" color="gray.900" textAlign="start">
        {DateTime.fromMillis(Number(payload?.timestamp)).toFormat('dd LLLL yyyy', { locale: 'pt-BR' }).toLowerCase()}
      </Text>
      <Flex justify="space-between" columnGap="2">
        <Text textStyle="body2-regular" color="gray.500">
          Abertura
        </Text>
        <Text textStyle="body2-medium" color="gray.900">
          {format(open)}
        </Text>
      </Flex>
      <Flex justify="space-between" columnGap="2">
        <Text textStyle="body2-regular" color="gray.500">
          Mínimo
        </Text>
        <Text textStyle="body2-medium" color="gray.900">
          {format(payload?.low)}
        </Text>
      </Flex>
      <Flex justify="space-between" columnGap="2">
        <Text textStyle="body2-regular" color="gray.500">
          Máximo
        </Text>
        <Text textStyle="body2-medium" color="gray.900">
          {format(payload?.high)}
        </Text>
      </Flex>
      <Flex justify="space-between" columnGap="2">
        <Text textStyle="body2-regular" color="gray.500">
          Fechamento
        </Text>
        <Text textStyle="body2-medium" color="gray.900">
          {format(close)}
        </Text>
      </Flex>
      <Flex justify="space-between" columnGap="1">
        <Flex alignItems="center" justifyContent="flex-start" gap="1">
          <Flex alignItems="center" width="3" color="red.500">
            <LineIcon width="3" />
          </Flex>
          <Text textStyle="body2-regular" color="gray.500">
            PLD alto
          </Text>
        </Flex>
        <Text textStyle="body2-medium" color="gray.900">
          {format(payload?.pldHigh)}
        </Text>
      </Flex>
      <Flex justify="space-between" columnGap="1">
        <Flex alignItems="center" justifyContent="flex-start" gap="1">
          <Flex alignItems="center" width="3" color="blue.500">
            <LineIcon width="3" />
          </Flex>
          <Text textStyle="body2-regular" color="gray.500">
            PLD previsto
          </Text>
        </Flex>
        <Text textStyle="body2-medium" color="gray.900">
          {format(payload?.pld)}
        </Text>
      </Flex>
    </Card>
  );
};

export default PricesTooltip;
