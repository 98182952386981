import { useSelector } from 'react-redux';

import { RootStateProps } from '../context';

function useContainer<State = RootStateProps, CurrentState = unknown>(
  selector: (state: State) => CurrentState,
  equalityFn?: (left: CurrentState, right: CurrentState) => boolean,
) {
  const state = useSelector(selector, equalityFn);

  return state;
}

export default useContainer;
