import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.li`
  height: 136px;
  min-width: 120px;
  flex: 1;
  margin-bottom: 16px;

  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

  @media screen and (min-width: 720px) {
    width: 100%;
  }

  :not(:first-child) {
    margin-left: 8px;
  }

  header {
    text-decoration: none;
    height: 40px;
    border-radius: 2px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;


    .disabled {
    }
  }

  section {
    display: flex;
    flex-flow: column wrap;
    margin-bottom: 8px;

    & .MuiFormControl-root {
      margin-top: 8px;
    }
  }
`;
