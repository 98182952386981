import { Flex } from '@tradener/lumen';

import LeftContent from './LeftContent';
import RightContent from './RightContent';

function Details() {
  return (
    <Flex align="flex-start" gap="6" height="2xl">
      <LeftContent />
      <RightContent />
    </Flex>
  );
}

export default Details;
