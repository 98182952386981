import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  chip: {
    marginRight: 10,
  },
  line: {
    cursor: 'pointer',
    '&:nth-child(1) div': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between !important',
      alignItems: 'center',
    },
    '&:nth-child(n+2)': {
      pointerEvents: 'none',
    },
  },
});

export default useStyles;
