import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  circular: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
  },
  h4: {
    fontSize: '24px',
    fontFamily: 'Roboto',
    fontWeight: 700,
    lineHeight: 1.5,
    letterSpacing: '1.6px',
  },
  paper: {
    width: '260px',
    marginLeft: '20px',
    borderRadius: '8px',
    padding: '8px',
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuItem: {
    padding: '12px',
    fontSize: 14,
  },
});

export default useStyles;
