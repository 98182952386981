import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  padding: 16px;
  height: calc(100% - 48px);
  letter-spacing: 0.8px;

  > div {
    padding: 4px;
  }

  hr {
    margin: 8px 0;
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
`;

export const CommonContent = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div {
    flex: 1;
    max-width: 240px;

    :not(:nth-child(even)) {
      margin-right: 16px;
    }

    .MuiOutlinedInput-root {
      width: 144px;
    }

    .payment {
      label span {
        margin-right: 8px;
        margin-bottom: unset;
        width: 48px;
      }

      .MuiOutlinedInput-root {
        width: 60px;
      }
    }

    .validity .MuiOutlinedInput-root {
      width: 184px;
      font-size: 14px;
    }
  }
`;

export const Readjustment = styled.section`
  margin-top: 24px;
`;

export const ReadjustmentHeader = styled.section`
  h1 {
    font-size: 16px;
    font-weight: 500;
  }
`;
export const ReadjustmentContent = styled.section`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;

  > div {
    flex: 1;
    max-width: 240px;

    :not(:nth-child(even)) {
      margin-right: 16px;
    }

    .MuiOutlinedInput-root {
      width: 152px;
    }
  }
`;

export const Extra = styled.section`
  margin-top: 24px;
`;

export const ExtraHeader = styled.header`
  h1 {
    font-size: 16px;
    font-weight: 500;
  }
`;
export const ExtraContent = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;

  > div {
    width: 100%;

    :not(:last-child) {
      max-width: 240px;
      .MuiOutlinedInput-root {
        width: 112px;
      }
    }

    :nth-child(1) {
      margin-right: 16px;
    }

    > span {
      display: block;
      margin-bottom: 12px;
      font-size: 16px;
    }
  }

  textarea {
    text-align: left !important;
  }
`;
