import { useContext, useMemo } from 'react';

import { FieldBox, SelectionControl } from '@DEPRECATED/components/Form';
import VerticalScroll from '@DEPRECATED/components/VerticalScroll';
import ProposalsContext from '@DEPRECATED/context/Proposals/context';
import Tooltip from '@material-ui/core/Tooltip';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import OpportunityFooter from '../OpportunityFooter';
import { ENERGY_TYPE } from '../keywords';
import Period from './Period';
import { Container, Content, ContentBox } from './styles';

const schema = Yup.object().shape({
  periodType: Yup.string().required(),
  endDate: Yup.date().min(Yup.ref('startDate')).required(),
  startDate: Yup.date().required(),
  priceType: Yup.string().required(),
  product: Yup.object().shape({
    shortName: Yup.string().required(),
  }),
  submarket: Yup.string().required(),
});

const dateSchema = Yup.object().shape({
  endDate: Yup.date().min(Yup.ref('startDate')).required(),
  startDate: Yup.date().required(),
});

function Product({ enableDialog, ...parent }) {
  const { index, handleNext, handlePrevious } = parent;
  const { data, mergeData } = useContext(ProposalsContext);

  const {
    product,
    priceType,
    submarket,
    endDate,
    startDate,
    opportunity: { channel },
  } = data;
  const { shortName } = product;

  const disabled = useMemo(() => !schema.isValidSync(data), [data]);

  const dateError = useMemo(() => {
    if (!endDate || !startDate) return false;

    return !dateSchema.isValidSync(data);
  }, [data, endDate, startDate]);

  if (isEmpty(data)) return null;

  const energyTypeFields = [
    { value: 'C', label: 'C' },
    { value: 'I5', label: 'I5' },
    { value: 'I1', label: 'I1' },
    { value: 'I0', label: 'I0' },
    { value: 'I8', label: 'I8' },
    { value: 'INE5', label: 'INE5' },
  ];

  const priceTypeFields = [
    { value: 'FIXO', label: 'FIXO' },
    { value: 'SPREAD', label: 'SPREAD' },
    { value: 'PERCENT_PLD', label: '% PLD' },
  ];

  const submarketFields = [
    { value: 'SE', label: 'SE' },
    { value: 'S', label: 'S' },
    { value: 'NE', label: 'NE' },
    { value: 'N', label: 'N' },
  ];

  function handleChangeEnergyType(e) {
    mergeData('product.shortName', e.target.value);
  }

  function handleChangePriceType(e) {
    mergeData('priceType', e.target.value);
  }

  function handleChangeSubmarket(e) {
    mergeData('submarket', e.target.value);
  }

  return (
    <>
      <Container>
        <VerticalScroll>
          <Period enableDialog={enableDialog} error={dateError} />
          <Content>
            <FieldBox title="Tipo de Energia">
              <ContentBox>
                {energyTypeFields.map((option) => (
                  <Tooltip key={option.value} title={ENERGY_TYPE[option.value]} placement="bottom">
                    <SelectionControl
                      key={option.value}
                      name="period"
                      variant="contained"
                      disabled={channel === 'PORTAL'}
                      checked={option.value === shortName}
                      onChange={handleChangeEnergyType}
                      {...option}
                    />
                  </Tooltip>
                ))}
              </ContentBox>
            </FieldBox>
            <FieldBox title="Modalidade de Preço">
              <ContentBox>
                {priceTypeFields.map((option) => (
                  <SelectionControl
                    key={option.value}
                    name="priceType"
                    variant="contained"
                    checked={option.value === priceType}
                    onChange={handleChangePriceType}
                    size={13}
                    {...option}
                  />
                ))}
              </ContentBox>
            </FieldBox>
          </Content>
          <Content>
            <FieldBox title="Submercado">
              <ContentBox>
                {submarketFields.map((option) => (
                  <SelectionControl
                    key={option.value}
                    name="submarket"
                    variant="contained"
                    disabled={channel === 'PORTAL'}
                    checked={option.value === submarket}
                    onChange={handleChangeSubmarket}
                    {...option}
                  />
                ))}
              </ContentBox>
            </FieldBox>
          </Content>
        </VerticalScroll>
      </Container>
      <OpportunityFooter
        index={index}
        handleNext={handleNext}
        labelNext="Oferta"
        disableNext={disabled}
        handlePrevious={handlePrevious}
        labelPrevious="Conta"
      />
    </>
  );
}

Product.propTypes = {
  enableDialog: PropTypes.bool.isRequired,
};

export default Product;
