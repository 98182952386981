import { useParams } from 'react-router-dom';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from '@tradener/lumen';

import useBackward from '~/hooks/useBackward';

import useProducts, { withProductsContext } from '../../useProducts';
import PriceEvolution from './PriceEvolution';
import RiskSimulation from './RiskSimulation';

function ProductChart() {
  const onClose = useBackward('/trading/list');
  const { getProduct } = useProducts();
  const { id } = useParams() as { [key: string]: string };

  const product = getProduct(id);

  return (
    <Modal size="4xl" onClose={onClose} isCentered isOpen>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text textStyle="subheading1-semibold">{product?.name}</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Tabs width="100%">
            <TabList height="12">
              <Tab>Evolução de preços</Tab>
              <Tab isDisabled>Simulação de risco</Tab>
            </TabList>
            <TabPanels>
              <TabPanel px="0">
                <PriceEvolution />
              </TabPanel>
              <TabPanel>
                <RiskSimulation />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default withProductsContext(ProductChart);
