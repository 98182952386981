import { ThumbUpIcon } from '@tradener/lumen';

import OptionItem from '../../OptionItem';
import useChangeQuoteStage from '../../useChangeQuoteStage';
import { useQuoteOptions } from '../../useQuoteOptions';

function ApproveButton() {
  const changeQuoteStage = useChangeQuoteStage();
  const { onOpen } = useQuoteOptions();

  const openDialog = () => {
    onOpen('CLOSE', changeQuoteStage);
  };

  return (
    <OptionItem stage="PROPOSED" onClick={openDialog} icon={<ThumbUpIcon boxSize="5" />}>
      Aprovar
    </OptionItem>
  );
}

export default ApproveButton;
