import { DateTime } from 'luxon';

export enum PERIOD {
  BEFORE = 'BEFORE',
  CURRENT = 'CURRENT',
  AFTER = 'AFTER',
}

const getMonthFormat = (date: DateTime) => date.toFormat('yyyy-MM');

const getPeriodTime = (period: string, now = DateTime.now()) => {
  const countWithMonths = /\w{4}-\w{2}$/.test(period);
  const format = `yyyy${countWithMonths ? '-MM' : ''}`;
  const entry = DateTime.fromFormat(period, format);
  const lessThanHalfMonth = now.day <= 15 ? 1 : 0;

  if (countWithMonths) {
    const months = Math.floor(now.diff(entry, 'months').toObject().months ?? 0);
    const correctedPeriod = now.minus({ month: lessThanHalfMonth ? 1 : 0 });

    if (getMonthFormat(entry) === getMonthFormat(correctedPeriod)) return PERIOD.CURRENT;
    if (months > 0) return PERIOD.BEFORE;

    return PERIOD.AFTER;
  }

  if (entry.year === now.minus({ year: now.month === 1 && lessThanHalfMonth ? 1 : 0 }).year) return PERIOD.CURRENT;
  if (entry.year < now.year) return PERIOD.BEFORE;

  return PERIOD.AFTER;
};

export default getPeriodTime;
