import { gql } from '@apollo/client';

export interface PayloadProps {
  accountId: string;
}

export interface ParentsProps {
  id: string;
  name: string;
  taxId: string;
}

export interface ResponseProps {
  accountParents?: ParentsProps[];
}

const GET_ACCOUNT_PARENTS = gql`
  query accountParents($accountId: ID!) {
    accountParents(accountId: $accountId) {
      id
      name
      taxId
    }
  }
`;

export default GET_ACCOUNT_PARENTS;
