import { Box, Typography, useTheme } from '@material-ui/core';

import { ReactComponent as Bullet } from './staging-bullet.svg';

const StagingBar = () => {
  const { zIndex } = useTheme();

  return (
    <Box
      position="sticky"
      top="0px"
      height="37px"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      margin="0px"
      padding="10px"
      bgcolor="#4383f7"
      color="white"
      zIndex={zIndex.appBar + 4}
    >
      <Bullet height="18px" width="18px" />
      <Typography variant="body2" color="inherit">
        Você está em ambiente de homologação
      </Typography>
    </Box>
  );
};

export default StagingBar;
