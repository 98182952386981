import { useState } from 'react';

import { FiCalendar } from 'react-icons/fi';

import {
  Box,
  Radio,
  Drawer,
  Checkbox,
  FormLabel,
  FormControl,
  IconButton,
  RadioGroup,
  Typography,
  FormControlLabel,
  FormGroup,
  InputAdornment,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import { NonFunctionKeys } from 'utility-types';

import { PeriodTypes, AccountingPortfolioTypes } from '@graphql/query/portfolio';

import TextField from '~/DEPRECATED/components/Filter/TextField';
import useColorMode from '~/hooks/useColorMode';
import usePortfolio, { PortfolioHookProps, PortfolioProps } from '~/pages/Portfolio/usePortfolio';
import usePortfolioFilter from '~/pages/Portfolio/usePortfolioFilter';

import ContractFlagFilter from './ContractFlagFilter';
import Summary from './Summary';
import useStyles from './styles';

function Filter() {
  const { paper, header, label } = useStyles();
  const { setFilter, resetField, removeField, ...state } = usePortfolio();
  const { isOpen, onClose } = usePortfolioFilter();
  const [giro, setGiro] = useState('');
  const { theme } = useColorMode();

  const handleCheckbox =
    <Key extends NonFunctionKeys<PortfolioHookProps>, Value extends PortfolioHookProps[Key]>(key: Key, value: Value) =>
    (_: React.ChangeEvent<object>, checked: boolean) => {
      if (/(usageFactorByContract|usageFactorByEnergyType)/.test(key)) value = checked as Value;

      if (Array.isArray(state[key])) {
        value = (
          checked ? [...(state[key] as unknown[]), value] : (state[key] as string[]).filter((param) => param !== value)
        ) as Value;
      }

      setFilter(key, value);
    };

  const fetchByGiro = (giro: string | undefined) => () => {
    if (giro) setFilter('giro', giro);
  };

  const onDisable = () => {
    setGiro('');
    resetField('giro');
    removeField('hasGiro');
  };

  return (
    <Drawer
      anchor="right"
      variant="persistent"
      classes={{ paper }}
      open={isOpen}
      style={{
        display: isOpen ? 'flex' : 'none',
        overflow: 'auto',
        position: 'relative',
      }}
      transitionDuration={0}
      elevation={0}
    >
      <Box className={header} position="sticky" top="0" zIndex={theme.zIndex.appBar + 1}>
        <Typography variant="h3">Filtro</Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <Summary label="Tipo de energia" filter="energyType">
        <Box width="100%" display="flex" flexDirection="row" alignItems="flex-start" justifyContent="space-between">
          <FormControl component="fieldset">
            <FormLabel component="legend" style={{ fontSize: '10px', lineHeight: '10.8px', fontWeight: 400 }}>
              ESPECIAL
            </FormLabel>
            <FormGroup>
              {[
                { key: 'energyType', value: 'I5' },
                { key: 'energyType', value: 'I8' },
                { key: 'energyType', value: 'I1' },
                { key: 'energyType', value: 'I0' },
              ].map(({ key, value }) => (
                <FormControlLabel
                  key={`${key}.${value}`}
                  classes={{ label }}
                  control={<Checkbox color="primary" />}
                  label={value}
                  checked={!!state[key]?.includes(value)}
                  onChange={handleCheckbox(key as keyof PortfolioProps, value)}
                />
              ))}
            </FormGroup>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend" style={{ fontSize: '10px', lineHeight: '10.8px', fontWeight: 400 }}>
              NÃO ESPECIAL
            </FormLabel>
            <FormGroup>
              {[
                { key: 'energyType', value: 'C' },
                { key: 'energyType', value: 'INE5' },
              ].map(({ key, value }) => (
                <FormControlLabel
                  key={`${key}.${value}`}
                  classes={{ label }}
                  control={<Checkbox color="primary" />}
                  label={value}
                  checked={!!state[key]?.includes(value)}
                  onChange={handleCheckbox(key as keyof PortfolioProps, value)}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      </Summary>
      <Summary label="Preço" filter="priceType">
        <FormControl component="fieldset">
          <FormGroup>
            {[
              { name: 'FIXO', key: 'priceType', value: 'FIXO' },
              { name: '% PLD', key: 'priceType', value: 'PERCENT_PLD' },
              { name: 'SPREAD', key: 'priceType', value: 'SPREAD' },
            ].map(({ name, key, value }) => (
              <FormControlLabel
                key={`${key}.${value}`}
                classes={{ label }}
                control={<Checkbox color="primary" />}
                label={name}
                checked={!!state[key]?.includes(value)}
                onChange={handleCheckbox(key as keyof PortfolioProps, value)}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Summary>
      <Summary label="Prazo" filter="periodType">
        <RadioGroup onChange={({ target }) => setFilter('periodType', target.value as PeriodTypes)}>
          <FormControlLabel
            classes={{ label }}
            checked={state.periodType === 'LONG'}
            value="LONG"
            control={<Radio color="primary" />}
            label="Longo"
          />
          <FormControlLabel
            classes={{ label }}
            checked={state.periodType === 'SHORT'}
            value="SHORT"
            control={<Radio color="primary" />}
            label="Curto"
          />
        </RadioGroup>
      </Summary>
      <Summary label="Coeficiente de utilização" filter={['usageFactorByContract', 'usageFactorByEnergyType', 'usageFactor']}>
        <FormControl component="fieldset">
          {[
            { key: 'usageFactorByContract', label: 'Por contrato', value: true },
            { key: 'usageFactorByEnergyType', label: 'Por fonte', value: true },
            { key: 'usageFactor', label: 'Por período', value: true },
          ].map(({ key, label, value }) => (
            <FormControlLabel
              key={`${key}.${value}`}
              classes={{ label }}
              control={<Checkbox color="primary" />}
              label={label}
              checked={!!state[key]}
              onChange={handleCheckbox(key as keyof PortfolioProps, value)}
            />
          ))}
        </FormControl>
      </Summary>
      <Summary label="Giro" filter="hasGiro" onDisable={onDisable}>
        <FormControl component="fieldset" fullWidth>
          <RadioGroup onChange={({ target }) => setFilter('hasGiro', target.value == 'com')}>
            <FormControlLabel
              classes={{ label }}
              checked={state.hasGiro === true}
              value="com"
              control={<Radio color="primary" />}
              label="Com"
            />
            <FormControlLabel
              classes={{ label }}
              checked={state.hasGiro == false}
              value="sem"
              control={<Radio color="primary" />}
              label="Sem"
            />
          </RadioGroup>
        </FormControl>
        <FormControl component="fieldset" fullWidth>
          <TextField
            label="Código do Giro"
            name="giro"
            onChange={({ target }) => setGiro(target.value)}
            onBlur={fetchByGiro(giro)}
            value={giro}
            autoComplete="off"
            disabled={state.hasGiro != true}
          />
        </FormControl>
      </Summary>
      <ContractFlagFilter />
      <Summary label="Data de fechamento" filter={['closeDateLte', 'closeDateGte']}>
        <FormControl component="fieldset" fullWidth>
          <DatePicker
            value={state.closeDateGte ? DateTime.fromISO(state.closeDateGte) : null}
            onChange={(date) => setFilter('closeDateGte', DateTime.fromJSDate(date as Date).toISODate())}
            inputVariant="outlined"
            variant="dialog"
            label="Inicio"
            format="dd/MM/yyyy"
            color="secondary"
            cancelLabel="cancelar"
            name="closeDateGte"
            id="closeDateGte"
            style={{
              marginBottom: 8,
            }}
            TextFieldComponent={TextField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FiCalendar />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
          <DatePicker
            value={state.closeDateLte ? DateTime.fromISO(state.closeDateLte) : null}
            onChange={(date) => setFilter('closeDateLte', DateTime.fromJSDate(date as Date).toISODate())}
            inputVariant="outlined"
            variant="dialog"
            label="Fim"
            format="dd/MM/yyyy"
            color="secondary"
            cancelLabel="cancelar"
            name="closeDateLte"
            id="closeDateLte"
            TextFieldComponent={TextField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FiCalendar />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </FormControl>
      </Summary>
      <Summary label="P. Contábil" filter="accountingPortfolio">
        <RadioGroup onChange={({ target }) => setFilter('accountingPortfolio', target.value as AccountingPortfolioTypes)}>
          <FormControlLabel
            classes={{ label }}
            checked={state.accountingPortfolio === 'WHOLESALE'}
            value="WHOLESALE"
            control={<Radio color="primary" />}
            label="Atacado"
          />
          <FormControlLabel
            classes={{ label }}
            checked={state.accountingPortfolio === 'TRADING'}
            value="TRADING"
            control={<Radio color="primary" />}
            label="Negociação"
          />
        </RadioGroup>
      </Summary>
      <Summary label="Tipo da conta" filter="accountTypes">
        <FormControl component="fieldset">
          {[
            { name: 'Comercializador / Gestor de Energia', value: 'Comercializador / Gestor de Energia', key: 'accountTypes' },
            { name: 'Consumidor', value: 'Consumidor', key: 'accountTypes' },
            { name: 'Distribuidor / Transmissor', value: 'Distribuidor / Transmissor', key: 'accountTypes' },
            { name: 'Gerador', value: 'Gerador', key: 'accountTypes' },
            { name: 'Outras Contas', value: 'Outras Contas', key: 'accountTypes' },
          ].map(({ name, key, value }) => (
            <FormControlLabel
              key={`${key}.${value}`}
              classes={{ label }}
              control={<Checkbox color="primary" />}
              label={name}
              checked={!!state[key]?.includes(value)}
              onChange={handleCheckbox(key as keyof PortfolioProps, value)}
            />
          ))}
        </FormControl>
      </Summary>
    </Drawer>
  );
}

export default Filter;
