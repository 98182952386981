import { FiArrowRight, FiArrowLeft } from 'react-icons/fi';

import Button from '@material-ui/core/Button';
import { Divider, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Container } from './styles';

function OpportunityFooter({
  children,
  index,
  handleNext,
  labelNext,
  disableNext,
  handlePrevious,
  labelPrevious,
  disablePrevious,
}) {
  return (
    <Box>
      <Divider />
      <Container>
        {handleNext && (
          <Button
            variant="contained"
            disableElevation
            className="next"
            disabled={disableNext}
            onClick={() => handleNext(index + 1)}
          >
            {labelNext} <FiArrowRight size={24} />
          </Button>
        )}
        {handlePrevious && (
          <Button
            variant="contained"
            className="previous"
            disableElevation
            disabled={disablePrevious}
            color="default"
            onClick={() => handlePrevious(index - 1)}
          >
            <FiArrowLeft size={24} /> {labelPrevious}
          </Button>
        )}

        {children}
      </Container>
    </Box>
  );
}

OpportunityFooter.defaultProps = {
  handleNext: undefined,
  labelNext: undefined,
  disableNext: undefined,
  handlePrevious: undefined,
  labelPrevious: undefined,
  disablePrevious: undefined,
  index: 0,
  children: null,
};

OpportunityFooter.propTypes = {
  handleNext: PropTypes.func,
  labelNext: PropTypes.string,
  disableNext: PropTypes.bool,
  handlePrevious: PropTypes.func,
  labelPrevious: PropTypes.string,
  disablePrevious: PropTypes.bool,
  index: PropTypes.number,
  children: PropTypes.node,
};

export default OpportunityFooter;
