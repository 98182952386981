import { gql } from '@apollo/client';

import { EnergyTypes } from '@graphql/query/contracts';
import { PriceTypes } from '@graphql/query/portfolio';

export type Submarkets = 'SE' | 'S' | 'NE' | 'N';

export interface MutationResponseProps {
  status: string;
}

export interface UpsertSimulationOperationsProps {
  errors?: string[];
  upsertSimulationOperations: MutationResponseProps[];
}

export interface SimulationItemInputProps {
  competence: string;
  price: number;
  quantityMwm: number;
}

export interface SimulationOperationInputProps {
  energyType: EnergyTypes;
  operation: 'PURCHASE' | 'SALE';
  submarket: Submarkets;
  priceType: PriceTypes;
  simulationItems: SimulationItemInputProps[];
}

export interface InputProps {
  simulationId: string | undefined;
  simulationOperations: SimulationOperationInputProps[];
}

export interface PayloadProps {
  input: InputProps;
}

export interface ResponseProps {
  upsertSimulationOperations: UpsertSimulationOperationsProps;
}

const UPSERT_SIMULATION_OPERATIONS = gql`
  mutation upsertSimulationOperations($input: UpsertSimulationOperationsInput!) {
    upsertSimulationOperations(input: $input) {
      upsertSimulationOperations {
        status
      }
      errors
    }
  }
`;

export default UPSERT_SIMULATION_OPERATIONS;
