import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette, typography }) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '280px',
    height: '316px',
    padding: '20px',
    borderRadius: '4px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
    backgroundColor: palette.type === 'dark' ? palette.grey[800] : palette.common.white,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  current: {
    ...typography.h5,
    color: palette.grey[palette.type === 'dark' ? 400 : 200],
  },
  control: {
    display: 'flex',
    flexDirection: 'row',
  },
  chevron: {
    color: palette.grey[200],
    '&:hover': {
      color: palette.grey[400],
    },
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gridRowGap: '8px',
  },
  chip: {
    ...typography.body2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60px',
    height: '36px',
    color: palette.grey[500],
    cursor: 'pointer',
    borderRadius: '500px',
    transition: 'all 0.1s',
    transformStyle: 'preserve-3d',
    '&.from-picked:after, &.until-picked:after': {
      content: '""',
      position: 'absolute',
      width: '60px',
      height: '36px',
      backgroundColor: palette.grey[100],
      transform: 'translateZ(-1px)',
    },
    '&.from-picked:after': {
      borderTopLeftRadius: '500px',
      borderBottomLeftRadius: '500px',
    },
    '&.until-picked:after': {
      borderTopRightRadius: '500px',
      borderBottomRightRadius: '500px',
    },
    '&:not(.from-picked):not(.until-picked):hover': {
      backgroundColor: `${palette.grey[200]} !important`,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
