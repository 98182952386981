import { Fragment, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { BoxProps, Typography } from '@material-ui/core';
import { Lock } from '@material-ui/icons';

import type { BalanceProps } from '@graphql/query/simulation';

import { submarkets } from '..';

import useColorMode from '~/hooks/useColorMode';
import usePeriodComparison from '~/hooks/usePeriodComparison';
import usePortfolioFilter from '~/pages/Portfolio/usePortfolioFilter';
import useSimulationRules from '~/pages/Portfolio/useSimulationRules';
import { grey } from '~/theme';
import useNumberFormat from '~/theme/layout/useNumberFormat';

import SimulationSection from '../SimulationSection';
import { TableColumn, TableHeading, TableHorizontalAxis, TableSubheading, TableVerticalAxis } from '../Table';
import { useSimulationTable } from '../withSimulationTable';

export type BalanceTableColumn = [string, BalanceProps];

export interface BalanceTableProps extends BoxProps {
  data: BalanceTableColumn[];
}

const subject = {
  purchase: 'Compra',
  sale: 'Venda',
};

function BalanceTable() {
  const { simulationPeriods } = useSimulationTable();
  const { isPortfolio } = useSimulationRules();
  const { view } = usePortfolioFilter();
  const { format } = useNumberFormat();
  const [isOpen, setOpen] = useState<(keyof typeof subject)[]>([]);
  const { colorTernary } = useColorMode();
  const navigate = useNavigate();
  const getColor = usePeriodComparison();

  const onToggle = (operation: keyof typeof subject) => () => {
    setOpen((prev) => (prev.includes(operation) ? prev.filter((item) => item !== operation) : [...prev, operation]));
  };

  return (
    <>
      <SimulationSection label="Balanço" />
      <TableHorizontalAxis>
        <TableVerticalAxis position="sticky" left="0" gridTemplateColumns="200px">
          {(Object.keys(subject) as typeof isOpen).map((operation) => (
            <Fragment key={operation}>
              <TableHeading
                isOpen={isOpen.includes(operation)}
                onToggle={onToggle(operation)}
                onOpen={() => navigate(`contracts/${operation}`)}
              >
                {subject[operation]}
              </TableHeading>
              {isOpen.includes(operation) &&
                Object.keys(submarkets).flatMap((submarket) => (
                  <TableSubheading key={`${operation}.${submarket}`}>{submarkets[submarket]}</TableSubheading>
                ))}
            </Fragment>
          ))}
          <TableHeading>Saldo</TableHeading>
          {!isPortfolio && (
            <TableHeading
              justifyContent="flex-start"
              gridColumnGap="10px"
              alignItems="flex-end"
              fontWeight="normal"
              borderBottom="initial"
              color={grey[colorTernary(600, 500)]}
            >
              <Lock color="inherit" fontSize="small" style={{ width: '20px', height: '20px' }} />
              <Typography variant="body2" color="inherit">
                Saldo original
              </Typography>
            </TableHeading>
          )}
        </TableVerticalAxis>
        {simulationPeriods?.map(({ period, balance }) => {
          const color = getColor(period);

          return (
            <TableVerticalAxis key={period} bgColor={color}>
              {(Object.keys(subject) as typeof isOpen).map((operation) => (
                <Fragment key={operation}>
                  <TableColumn emphasis>{format(balance[operation][view])}</TableColumn>
                  {isOpen.includes(operation) &&
                    Object.keys(submarkets).flatMap((submarket) => {
                      const predicate = [operation, submarket, view];

                      return (
                        <TableColumn key={predicate.join()}>
                          {format(predicate.reduce((prev, current) => prev[current], balance) as unknown as number)}
                        </TableColumn>
                      );
                    })}
                </Fragment>
              ))}
              <TableColumn emphasis>{`${view !== 'price' ? format(balance[view]) : ''}`}</TableColumn>
              {!isPortfolio && (
                <TableColumn borderBottom="initial">
                  {format(balance['originalTotalAmount'], { style: 'currency', minimumFractionDigits: 0 })}
                </TableColumn>
              )}
            </TableVerticalAxis>
          );
        })}
        <TableVerticalAxis gridTemplateColumns="initial" width="100%">
          {(Object.keys(subject) as typeof isOpen).map((operation) => (
            <Fragment key={operation}>
              <TableColumn showSkeleton={false}>&nbsp;</TableColumn>
              {isOpen.includes(operation) &&
                Object.keys(submarkets).flatMap((submarket) => (
                  <TableColumn key={`${submarket}.placeholder`}>&nbsp;</TableColumn>
                ))}
            </Fragment>
          ))}
          {!isPortfolio && <TableColumn showSkeleton={false}>&nbsp;</TableColumn>}
          <TableColumn borderBottom="initial" showSkeleton={false}>
            &nbsp;
          </TableColumn>
        </TableVerticalAxis>
      </TableHorizontalAxis>
    </>
  );
}

export default BalanceTable;
