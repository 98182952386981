import { gql } from '@apollo/client';

import { QUOTE_FRAGMENT, QuoteProps } from '../query/quote';

export type QuoteStageActions = 'PROPOSE' | 'REJECT' | 'CLOSE' | 'CANCEL';

export interface ChangeQuoteStageProps {
  quote: QuoteProps;
}

export interface ChangeQuoteStageInputProps {
  quoteId: string;
  action: QuoteStageActions;
}

export interface PayloadProps {
  input: ChangeQuoteStageInputProps;
}

export interface ResponseProps {
  changeQuoteStage: ChangeQuoteStageProps;
}

const CHANGE_QUOTE_STAGE = gql`
  ${QUOTE_FRAGMENT}

  mutation changeQuoteStage($input: ChangeQuoteStageInput!) {
    changeQuoteStage(input: $input) {
      quote {
        ...quote
      }
    }
  }
`;

export default CHANGE_QUOTE_STAGE;
