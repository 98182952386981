import { useMemo, useState } from 'react';

import {
  FiMoreVertical,
  FiDollarSign,
  FiEdit,
  FiCopy,
  FiFileText,
  FiDownload,
  FiMail,
  FiThumbsUp,
  FiThumbsDown,
  FiTrash2,
} from 'react-icons/fi';
import { MdInfoOutline } from 'react-icons/md';
import { SwapVertIcon } from '@tradener/lumen';

import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConfirmationDialog from '@DEPRECATED/components/ConfirmationDialog';
import client from '~/services/apollo';
import DialogContent from '@material-ui/core/DialogContent';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import gql from 'graphql-tag';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';
import PropTypes from 'prop-types';

import { ListItemIcon, PersonalizedIconButton as IconButton } from './styles';
import useColorMode from '~/hooks/useColorMode';
import useRestriction from '~/hooks/useRestriction';
import useRequestMove from '~/pages/Trading/TradingList/QuoteOptions/useRequestMove';

const CHANGE_QUOTE_STAGE = gql`
  mutation changeQuoteStage($input: ChangeQuoteStageInput!) {
    changeQuoteStage(input: $input) {
      clientMutationId
    }
  }
`;

const APPROVE_QUOTE_EXPIRED = gql`
  mutation quotesApproveExpired($input: ApproveExpiredInput!) {
    quotesApproveExpired(input: $input) {
      clientMutationId
    }
  }
`;

const DISPLAY_TOAST_MESSAGES = {
  CLOSE: 'Oferta fechada com sucesso',
  CANCEL: 'Oferta cancelada',
  REJECT: 'Oferta rejeitada',
  PROPOSE: 'Proposta Emitida',
};

const DISPLAY_DIALOG_MESSAGE = {
  propose: 'emitir a proposta da',
  close: 'aprovar a',
  reject: 'rejeitar a',
  cancel: 'cancelar a',
  move: 'movimentar para o trading a'
};

function BookActions({ quote }) {
  const { restrict } = useRestriction();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogApproveExpired, setOpenDialogApproveExpired] = useState(false);
  const [action, setAction] = useState('');
  const { colorTernary } = useColorMode();
  const details = usePopupState({ variant: 'popover', popupId: 'details' });
  const requestMove = useRequestMove(quote.id, details.close);
  const push = useNavigate();
  const parsedType = useMemo(() => quote.opportunity?.type?.toLowerCase(), [quote.opportunity.type]);

  const canReject = useMemo(() => {
    const list = ['opened', 'proposed'];

    return list.includes(quote.stage);
  }, [quote.stage]);

  const canDownload = useMemo(() => {
    const list = ['rejected', 'closed', 'proposed'];

    return list.includes(quote.stage);
  }, [quote.stage]);

  function handleSendProposal() {
    push(`/book/list/send-proposal/${quote.id}`);
    details.close();
  }

  async function handleQuoteStage(name) {
    name = name.toUpperCase();

    try {
      await client.mutate({
        mutation: CHANGE_QUOTE_STAGE,
        variables: {
          input: { quoteId: quote.id, action: name },
        },
      });

      const message = DISPLAY_TOAST_MESSAGES[name] || null;

      if (message) toast.success(message);
    } catch (error) {
      toast.error('Ocorreu um erro ao alterar proposta');
    }
  }

  const handleCloseConfirmation = async (confirmation) => {
    const isMoveAction = !!action && action === 'move';
    setOpenDialog(false);

    if (confirmation && !isMoveAction) {
      handleQuoteStage(action);
    } else if (confirmation && isMoveAction) {
      await requestMove('Solicitação de mover oferta para o Trading enviada');
    }
  }

  async function handleQuoteClose() {
    try {
      await client.mutate({
        mutation: APPROVE_QUOTE_EXPIRED,
        variables: {
          input: { id: quote.id },
        },
      });

      toast.success(DISPLAY_TOAST_MESSAGES['CLOSE']);
    } catch (error) {
      toast.error('Ocorreu um erro ao alterar proposta');
    }
  }

  const handleApproveExpiredConfirmation = async (confirmation) => {
    setOpenDialogApproveExpired(false);

    if (confirmation) {
      handleQuoteClose();
    }
  }

  function handleDialog(name) {
    setOpenDialog(true);
    details.close();

    setAction(name);
  }

  function handleDialogApproveExpired() {
    setOpenDialogApproveExpired(true);
    details.close();
  }

  return (
    <>
      <IconButton {...bindTrigger(details)} style={{ padding: 'unset', width: '30px', height: '30px' }}>
        <FiMoreVertical size={16} color={colorTernary('#fff', '#111')} />
      </IconButton>
      <Menu
        {...bindMenu(details)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {quote.stage === 'proposed' && quote.opportunity.channel === 'mesa' && (
          <NavLink
            style={{ textDecoration: 'none' }}
            to={`/book/list/opportunities/${parsedType}/${quote.id}?operation=renegotiate`}
            onClick={details.close}
          >
            <MenuItem>
              <ListItemIcon>
                <FiDollarSign />
              </ListItemIcon>
              <Typography variant="subtitle1">Renegociar</Typography>
            </MenuItem>
          </NavLink>
        )}
        {quote.stage === 'opened' && (
          <NavLink style={{ textDecoration: 'none' }} to={`/book/list/opportunities/${parsedType}/${quote.id}`} onClick={details.close}>
            <MenuItem>
              <ListItemIcon>
                <FiEdit />
              </ListItemIcon>
              <Typography variant="subtitle1">Editar</Typography>
            </MenuItem>
          </NavLink>
        )}
        <NavLink
          style={{ textDecoration: 'none' }}
          to={`/book/list/opportunities/${parsedType}/${quote.id}?operation=duplicate`}
          onClick={details.close}
        >
          <MenuItem>
            <ListItemIcon>
              <FiCopy />
            </ListItemIcon>
            <Typography variant="subtitle1">Duplicar</Typography>
          </MenuItem>
        </NavLink>
        {quote.stage === 'opened' && (
          <MenuItem onClick={() => handleDialog('propose')}>
            <ListItemIcon>
              <FiFileText />
            </ListItemIcon>
            <Typography variant="subtitle1">Emitir proposta</Typography>
          </MenuItem>
        )}
        {canDownload && (
          <MenuItem href={quote.proposalUrl} target="blank" component="a" onClick={details.close}>
            <ListItemIcon>
              <FiDownload />
            </ListItemIcon>
            <Typography variant="subtitle1">Download da proposta</Typography>
          </MenuItem>
        )}
        {quote.opportunity.currentLocation !== 'trading' && restrict(
          'REQUEST_MOVE',
            <MenuItem onClick={() => handleDialog('move')}>
              <ListItemIcon>
                <SwapVertIcon />
              </ListItemIcon>
              <Typography variant="subtitle1">Mover para trading</Typography>
            </MenuItem>
        )}
        {quote.stage === 'proposed' && quote.opportunity.channel !== 'portal' && (
          <MenuItem onClick={handleSendProposal}>
            <ListItemIcon>
              <FiMail />
            </ListItemIcon>
            <Typography variant="subtitle1">Enviar proposta</Typography>
          </MenuItem>
        )}
        {quote.stage === 'proposed' && (
          <MenuItem onClick={() => handleDialog('close')}>
            <ListItemIcon>
              <FiThumbsUp />
            </ListItemIcon>
            <Typography variant="subtitle1">Aprovar</Typography>
          </MenuItem>
        )}
        {quote.stage === 'rejected' && quote.expireDate <= quote.stageAt && restrict(
          'APPROVE_EXPIRED',
          <MenuItem onClick={() => handleDialogApproveExpired()}>
            <ListItemIcon>
              <FiThumbsUp />
            </ListItemIcon>
            <Typography variant="subtitle1">Aprovar</Typography>
          </MenuItem>
        )}
        {canReject && (
          <MenuItem onClick={() => handleDialog('reject')}>
            <ListItemIcon>
              <FiThumbsDown />
            </ListItemIcon>
            <Typography variant="subtitle1">Rejeitar</Typography>
          </MenuItem>
        )}
        <NavLink style={{ textDecoration: 'none' }} to={`/book/list/extra/${quote.id}`} onClick={details.close}>
          <MenuItem>
            <ListItemIcon>
              <MdInfoOutline />
            </ListItemIcon>
            <Typography variant="subtitle1">Complemento</Typography>
          </MenuItem>
        </NavLink>
        <MenuItem onClick={() => handleDialog('cancel')}>
          <ListItemIcon>
            <FiTrash2 />
          </ListItemIcon>
          <Typography variant="subtitle1">Cancelar</Typography>
        </MenuItem>
      </Menu>

      <ConfirmationDialog
        id="actions-dialog-confirmation"
        open={openDialog}
        onClose={handleCloseConfirmation}
        title="Atenção"
        fullWidth
      >
        <DialogContent
          dividers
          style={{
            minHeight: 80,
            background: colorTernary('#111', '#fff'),
          }}
        >
          {`Você deseja ${DISPLAY_DIALOG_MESSAGE[action]} oferta`}
          <strong> {quote.offerCode}</strong> ?
        </DialogContent>
      </ConfirmationDialog>

      <ConfirmationDialog
        id="actions-dialog-confirmation-approve-expired"
        open={openDialogApproveExpired}
        onClose={handleApproveExpiredConfirmation}
        title="Atenção"
        fullWidth
      >
        <DialogContent
          dividers
          style={{
            minHeight: 80,
            background: colorTernary('#111', '#fff'),
          }}
        >
          {`Você deseja ${DISPLAY_DIALOG_MESSAGE['close']} oferta`}
          <strong> {quote.offerCode}</strong> ?
        </DialogContent>
      </ConfirmationDialog>
    </>
  );
}

BookActions.propTypes = {
  quote: PropTypes.shape({
    id: PropTypes.string,
    stage: PropTypes.string,
    proposalUrl: PropTypes.string,
    offerCode: PropTypes.string,
    opportunity: PropTypes.shape({
      type: PropTypes.string,
      channel: PropTypes.oneOf(['mesa', 'bbce', 'salesforce', 'portal']),
    }),
  }).isRequired,
};

export default BookActions;
