import { ChatBubbleOutlineIcon, Tooltip } from '@tradener/lumen';

import OfferListItem from '../OfferListItem';
import useProductQuote from '../useProductQuote';

function OfferObservation() {
  const { notes } = useProductQuote();

  return (
    <OfferListItem>
      <Tooltip aria-label="mais" width="fit-content" bgColor="gray.900" color="gray.0" borderRadius="base" label={notes} hasArrow>
        <ChatBubbleOutlineIcon color="gray.500" boxSize={5} />
      </Tooltip>
    </OfferListItem>
  );
}

export default OfferObservation;
