import { useCallback } from 'react';

import { useTheme } from '@tradener/lumen';
import { DateTime } from 'luxon';

import MonthRange, { DateYearMonthProps } from '~/theme/components/MonthRange';

import useTrading from '../../useTrading';

const dateNow = DateTime.now().setLocale('pt-br');

const Period = () => {
  const { colors } = useTheme();
  const { setValues, filter } = useTrading();

  const getMonthRange = useCallback(
    (monthRange: DateYearMonthProps) => {
      if (monthRange.startYear && monthRange.startMonth && monthRange.endYear && monthRange.endMonth) {
        const startDate = `${monthRange.startYear}-${monthRange.startMonth}-01`;
        const endDate = `${monthRange.endYear}-${monthRange.endMonth}-01`;

        setValues({
          startDate: DateTime.fromFormat(startDate, 'yyyy-M-dd').toISODate(),
          endDate: DateTime.fromFormat(endDate, 'yyyy-M-dd').toISODate(),
        });
      } else {
        setValues({
          startDate: dateNow.startOf('year').toISODate(),
          endDate: dateNow.endOf('year').toISODate(),
        });
      }
    },
    [setValues],
  );

  return (
    <>
      <MonthRange
        id="period"
        onChange={getMonthRange}
        hasClear
        defaultValues={
          filter.startDate && filter.endDate
            ? {
                startYear: Number(filter.startDate.split('-')[0]),
                startMonth: Number(filter.startDate.split('-')[1]),
                endYear: Number(filter.endDate.split('-')[0]),
                endMonth: Number(filter.endDate.split('-')[1]),
              }
            : {
                startYear: dateNow.startOf('year').year,
                startMonth: dateNow.startOf('year').month,
                endYear: dateNow.endOf('year').year,
                endMonth: dateNow.endOf('year').month,
              }
        }
        variant="solid"
        bgColor="transparent"
        borderWidth="1px"
        borderColor={colors.gray[900] + '29'}
        height="8"
        color="gray.900"
        width="47"
      />
    </>
  );
};

export default Period;
