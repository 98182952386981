import { SvgIcon } from '@material-ui/core';

const Check = (props) => (
  <SvgIcon {...props}>
    <rect id="prefix__Retângulo_2054" width="24" height="24" data-name="Retângulo 2054" fill="#2294e3" />
    <g id="prefix__open_in_new-24px">
      <path id="prefix__Caminho_654" d="M0 0h32v32H0z" data-name="Caminho 654" fill="none" />
      <path
        id="prefix__Caminho_655"
        d="M17.222 17.222H4.778V4.778H11V3H4.778A1.777 1.777 0 0 0 3 4.778v12.444A1.777 1.777 0 0 0 4.778 19h12.444A1.783 1.783 0 0 0 19 17.222V11h-1.778zM12.778 3v1.778h3.191l-8.738 8.738 1.253 1.253 8.738-8.738v3.191H19V3z"
        data-name="Caminho 655"
        transform="translate(1.049 1.049)"
        fill="#fff"
      />
    </g>
  </SvgIcon>
);

export default Check;
