import { MenuBookIcon } from '@tradener/lumen';

import OptionItem from '../../OptionItem';
import { useQuoteOptions } from '../../useQuoteOptions';
import useRequestMove from '../../useRequestMove';

function ActionMoveBook() {
  const { id, onClose, onOpen } = useQuoteOptions();
  const requestMove = useRequestMove(id, onClose);

  const openDialog = () => {
    onOpen('MOVE', () => requestMove('Solicitação de mover oferta para o Book enviada'));
  };

  return (
    <OptionItem icon={<MenuBookIcon boxSize="5" />} onClick={openDialog}>
      Mover para Book
    </OptionItem>
  );
}

export default ActionMoveBook;
