import {
  Badge,
  Flex,
  Icon,
  NorthEastIcon,
  Text,
  SouthEastIcon,
  Popover,
  PopoverTrigger,
  PopoverBody,
  PopoverContent,
  Box,
} from '@tradener/lumen';

import EastIcon from '~/theme/icons/East';

import { useBbceProduct } from '..';

import ProductBadge from '../ProductBadge';
import createDynamicRange from '../createDynamicRange';

const getIcon = createDynamicRange(SouthEastIcon, EastIcon, NorthEastIcon);

function ProductPriceVariation() {
  const { lastPriceVariation, firstPrice, minPrice, maxPrice, lastPrice, name, getCurrency } = useBbceProduct();

  return (
    <Flex direction="column" width="60" rowGap="1" height="full" justifyContent="space-between">
      <Flex align="center" direction="row" columnGap="2">
        <Badge
          display="flex"
          alignItems="center"
          justifyContent="center"
          colorScheme={lastPriceVariation > 0 ? 'green' : lastPriceVariation < 0 ? 'red' : 'gray'}
          border="unset"
          variant="subtle"
          paddingBlock="0.5"
          paddingInline="1.5"
          gap="1"
          sx={{ '& > div': { gap: '1' } }}
        >
          <Icon as={getIcon(lastPriceVariation)} />
          <Text textStyle="body2-regular">{`${lastPriceVariation}%`}</Text>
        </Badge>
        <Popover trigger="hover" placement="top-start" strategy="fixed" isLazy>
          <PopoverTrigger>
            <Text cursor="default" minWidth="24" textStyle="subheading1-semibold" color={`gray.${lastPrice ? 900 : 500}`}>
              {getCurrency(lastPrice)}
            </Text>
          </PopoverTrigger>
          <Box sx={{ '& .chakra-popover__popper': { zIndex: 'popover' } }}>
            <PopoverContent>
              <PopoverBody display="flex" flexDirection="column" gap="1.5" width="full">
                <Flex direction="row" justify="space-between" gap="5" width="full" flex="1">
                  <Text textStyle="caption1-regular" color="gray.500">
                    Abertura
                  </Text>
                  <Text textStyle="caption1-regular">{getCurrency(firstPrice)}</Text>
                </Flex>
                <Flex direction="row" justify="space-between" gap="5" width="full" flex="1">
                  <Text textStyle="caption1-regular" color="gray.500">
                    Mínimo
                  </Text>
                  <Text textStyle="caption1-regular">{getCurrency(minPrice)}</Text>
                </Flex>
                <Flex direction="row" justify="space-between" gap="5" width="full" flex="1">
                  <Text textStyle="caption1-regular" color="gray.500">
                    Máximo
                  </Text>
                  <Text textStyle="caption1-regular">{getCurrency(maxPrice)}</Text>
                </Flex>
                <Flex direction="row" justify="space-between" gap="5" width="full" flex="1">
                  <Text textStyle="caption1-regular" color="gray.500">
                    Último
                  </Text>
                  <Text textStyle="caption1-regular">{getCurrency(lastPrice)}</Text>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Box>
        </Popover>
      </Flex>
      <ProductBadge textStyle="caption2-medium" maxW="64">
        {name?.toUpperCase()}
      </ProductBadge>
    </Flex>
  );
}

export default ProductPriceVariation;
