import { IconButton, DeleteIcon } from '@tradener/lumen';

import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';

import useEditable, { EditableProps } from '~/hooks/useEditable';
import useSimulationRules from '~/pages/Portfolio/useSimulationRules';

import { TableCellInput, TableCell, PLDStatus } from '..';

export interface TableCellEditionProps
  extends Omit<React.HTMLAttributes<React.PropsWithRef<HTMLElement>>, 'onSubmit'>,
    Partial<EditableProps> {
  onSubmit(value: string): void;
  render(value: string): JSX.Element;
  getStatus(defaultValue: string): PLDStatus;
  onDelete: React.MouseEventHandler<HTMLElement>;
  value: string;
}

const TableCellEdition = forwardRef<unknown, TableCellEditionProps>(
  ({ onSubmit, render, transform, getStatus, onDelete, value, isEditable, ...props }, ref) => {
    const parentRef = useRef<unknown>(null);
    const { isReadOnly } = useSimulationRules();
    const {
      isEditing,
      value: editedValue,
      initialValue,
      getParentProps,
      getEditionProps,
    } = useEditable(value, {
      isEditable: !isReadOnly && isEditable,
      transform,
    });

    const handleSubmit = (value: string) => {
      if (value !== editedValue) onSubmit(value);
    };

    const edited = useMemo(() => getStatus(initialValue), [getStatus, initialValue]);

    useImperativeHandle(ref, () => parentRef.current, []);

    return (
      <TableCell
        {...getParentProps({ ...props, edited, ref: parentRef })}
        style={{ cursor: 'text', pointerEvents: isEditable ? 'auto' : 'none' }}
      >
        {isEditing ? <TableCellInput {...getEditionProps({ onSubmit: handleSubmit })} /> : render(editedValue)}
        {!isReadOnly && edited !== PLDStatus.INITIAL && !isEditing && (
          <IconButton
            aria-label="edit cell"
            onFocus={(event) => {
              event.stopPropagation();
            }}
            onClick={onDelete}
            size="small"
            position="absolute"
            top="-3.5"
            right="-3.5"
            boxSize="6"
            border="initial"
            borderRadius="100%"
            zIndex="tooltip"
          >
            <DeleteIcon color="white" width="3" height="3.5" />
          </IconButton>
        )}
      </TableCell>
    );
  },
);

export { default as TableCellInput } from './TableCellInput';
export * from './TableCellInput';

export default TableCellEdition;
