import { Box } from '@material-ui/core';

import Filter from '~/theme/layout/Filter';

import Balance from './Balance';
import Layout from './layout';
import useStyles from './styles';

const isStaging = process.env.REACT_APP_ROLLBAR_ENV === 'staging';

function Portfolio() {
  const { wrapper } = useStyles();

  return (
    <Layout className={wrapper}>
      <Box
        position="relative"
        display="flex"
        height={isStaging ? 'calc(100vh - 116px)' : 'calc(100vh - 72px)'}
        flexDirection="row"
      >
        <Balance />
        <Filter />
      </Box>
    </Layout>
  );
}

export default Portfolio;
