import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  height: inherit;
  @media screen and (min-width: 600px) {
    flex-direction: row;
  }
`;

export const ContentTotal = styled.section`
  display: flex;
  flex: 1 1 104px;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 600px) {
    justify-content: flex-start;
    align-items: flex-start;

    justify-content: center;
    flex-direction: column;
  }
`;

export const Total = styled.span`
  font-size: 60px;
  height: 100px;
  line-height: 100px;
  @media screen and (min-width: 600px) {
    font-size: 88px;
  }
`;

export const ContentDetails = styled.section`
  flex: 2;

  margin: auto 0;
`;

export const Details = styled.section`
  display: flex;
  align-items: center;

  font-weight: 500;

  padding-bottom: 6px;
  border-bottom: 1px solid #232d44;

  margin-bottom: 24px;
`;

export const Title = styled.span`
  text-transform: uppercase;
`;

export const Value = styled.span`
  margin-left: auto;
`;

export const Graph = styled.div`
  display: flex;
`;

export const GraphContent = styled.div`
  height: 64px;
  display: flex;

  flex-direction: column;
`;

export const GraphDescription = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 18px;
  justify-content: center;
`;
export const DifferencePercent = styled.span`
  font-size: 20px;
  color: #ffffff;
  font-weight: 500;
`;
export const Difference = styled.span`
  color: #eee;
  font-size: 16px;
  font-weight: 500;
`;

export const ChartLabels = styled.div`
  display: flex;
  justify-content: space-between;
  width: 96px;
`;

export const Label = styled.span`
  font-size: 12px;
  font-weight: 700;
`;
