import { Container, Flex, Grid, Skeleton } from '@tradener/lumen';

import Header from '~/theme/layout/Header';

import InternalAccounts from '../TradingList/InternalAccounts';
import Period from '../TradingList/Period';
import Users from '../TradingList/Users';
import AccumulatedBalance from './AccumulatedBalance';
import EnergyBalance from './EnergyBalance';
import FinancialBalance from './FinancialBalance';
import Status from './components/Status';
import { useTradingChart, withTradingChart } from './useTradingChart';

function TradingChart() {
  const { lastMarketResultAccumulated, lastPldResultAccumulated, closedPositions, loading, series } = useTradingChart();
  const isLoaded = !loading && !!series?.length;

  return (
    <Container padding="0" maxW="unset" data-testid="trading-chart">
      <Header px="4" columnGap="3" align="center">
        <InternalAccounts />
        <Period />
        <Users />
      </Header>
      <>
        <Grid
          width="full"
          gridTemplateColumns={'repeat(3, 1fr)'}
          gap="3"
          data-testid="content"
          overflow="auto"
          paddingInline="5"
          paddingBlock="3"
          flex="1"
        >
          <Skeleton isLoaded={isLoaded} borderRadius="xl" height="5.5rem">
            <Status data-testid="market-result" title="Marcação a mercado" value={lastMarketResultAccumulated} />
          </Skeleton>
          <Skeleton isLoaded={isLoaded} borderRadius="xl" height="5.5rem">
            <Status data-testid="pld-result" title="Projeção interna" value={lastPldResultAccumulated} />
          </Skeleton>
          <Skeleton isLoaded={isLoaded} borderRadius="xl" height="5.5rem">
            <Status data-testid="closed-positions" title="Posições fechadas" value={closedPositions} />
          </Skeleton>
        </Grid>
        <Flex flexDirection="column" alignItems="center" width="full" gap="3" paddingInline="5" paddingBottom="3" flex="1">
          <Skeleton isLoaded={isLoaded} borderRadius="xl" height="28.125rem" width="full">
            <EnergyBalance />
          </Skeleton>
        </Flex>
        <Flex flexDirection="column" alignItems="center" width="full" gap="3" paddingInline="5" flex="1">
          <Flex width="full" alignItems="center" gap="3">
            <Skeleton isLoaded={isLoaded} borderRadius="xl" height="28.125rem" flex="1">
              <FinancialBalance />
            </Skeleton>
            <Skeleton isLoaded={isLoaded} borderRadius="xl" height="28.125rem" flex="1">
              <AccumulatedBalance />
            </Skeleton>
          </Flex>
        </Flex>
      </>
    </Container>
  );
}

export default withTradingChart(TradingChart);
