import { toast } from 'react-toastify';

import { useLazyQuery } from '@apollo/client';

import DOWNLOAD_LIST_CONTRACT, { ResponseProps, PayloadProps } from '@graphql/query/downloadListContract';

import usePortfolio, { getVariables } from '../../usePortfolio';
import useSimulation from '../../useSimulation';
import useSimulationRules from '../../useSimulationRules';

function useDownloadListContract() {
  const { isPortfolio } = useSimulationRules();
  const portfolio = usePortfolio();
  const { portfolioId, simulationId } = useSimulation();
  const id = isPortfolio ? portfolioId : simulationId;

  const {
    startYear,
    endYear,
    expandedPeriods,
    internalAccounts,
    energyType,
    periodType,
    priceType,
    usageFactorByContract,
    usageFactorByEnergyType,
    usageFactor,
    giro,
    hasGiro,
    contractFlags,
    accountingPortfolio,
    closeDateLte,
    closeDateGte,
  } = getVariables(portfolio);

  const [download, { loading }] = useLazyQuery<ResponseProps, PayloadProps>(DOWNLOAD_LIST_CONTRACT, {
    nextFetchPolicy: 'standby',
    onCompleted: ({ downloadListContract }) => {
      window.open(downloadListContract.downloadUrl, '_self');
    },
    onError: ({ message }) => toast.error(message),
  });

  const downloadUrl = () => {
    download({
      variables: {
        simulationId: id as string,
        startYear,
        endYear,
        expandedPeriods,
        internalAccounts,
        energyType,
        periodType,
        priceType,
        usageFactorByContract,
        usageFactorByEnergyType,
        usageFactor,
        giro,
        hasGiro,
        contractFlags,
        accountingPortfolio,
        closeDateLte,
        closeDateGte,
      },
    });
  };

  return { downloadContracts: downloadUrl, loadingDownloadContracts: loading };
}

export default useDownloadListContract;
