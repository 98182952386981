import { Children, cloneElement, HTMLAttributes, isValidElement } from 'react';

import { QuoteProps } from '@graphql/query/productOperations';

import { withQuoteOptions } from './useQuoteOptions';
export interface QuoteOptionsProps {
  quote: QuoteProps;
}

function QuoteOptions({ children, quote: _, ...props }: QuoteOptionsProps & HTMLAttributes<HTMLDivElement>) {
  return (
    <>
      {Children.map(children, (child) => {
        if (!isValidElement(child)) return null;

        return cloneElement(child, props);
      })}
    </>
  );
}

export default withQuoteOptions(QuoteOptions);
