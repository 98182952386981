import { Container } from './styles';

type WidgetContentProps = {
  children: React.ReactNode;
};

const WidgetContent: React.FC<WidgetContentProps> = (props) => {
  const { children } = props;

  return <Container>{children}</Container>;
};

export default WidgetContent;
