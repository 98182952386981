import { useToken } from '@tradener/lumen';
import { DateTime } from 'luxon';

const formatXAxisValue = (payload: any, mode: string) => {
  const dateTime = DateTime.fromISO(payload.value).setLocale('pt-BR');

  if (mode === 'HOUR') return [dateTime.hour + 1 + 'h'];
  else if (mode === 'DOW') {
    return [dateTime.toFormat('ccc'), dateTime.toFormat('dd MMM')];
  } else if (mode === 'DAY') {
    return [dateTime.toFormat('dd'), dateTime.toFormat('ccccc')];
  } else if (mode === 'MONTH') {
    return [dateTime.toFormat('MMM')];
  } else if (mode === 'YEAR') {
    return [dateTime.toFormat('MMM yy')];
  }

  return [payload.value];
};

const TickLabelXAxis = ({ x, y, payload, mode }: any) => {
  const [gray500, gray400] = useToken('colors', ['gray.600', 'gray.400']);

  return (
    <text
      orientation="bottom"
      width="854"
      height="30"
      x={x}
      y={y}
      stroke="none"
      fill="#666"
      className="recharts-text recharts-cartesian-axis-tick-value"
      textAnchor="middle"
    >
      {formatXAxisValue(payload, mode)?.map((value: string, index: number) => (
        <tspan key={value} x={x} dy={16} fill={index > 0 ? gray400 : gray500} fontSize="0.75rem">
          {value}
        </tspan>
      ))}
    </text>
  );
};

export default TickLabelXAxis;
