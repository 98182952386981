import TooltipRaw from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

// eslint-disable-next-line import/prefer-default-export
export const Tooltip = withStyles((theme) =>
  (theme?.palette?.type === 'dark') ?
    ({
      tooltip: {
        backgroundColor: '#232323',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: 1,
        maxWidth: 225,
      },
      arrow: {
        color: '#232323',
      }
    }) :
    ({
      tooltip: {
        backgroundColor: '#FFF',
        color: 'rgba(255, 255, 255, 0.87)',
        maxWidth: 225,
        boxShadow: '0px 6px 17px rgb(0 0 0 / 11%), 0px 2.21px 4px rgb(24 24 43 / 6%), 0px 2.07px 18.43px rgb(0 0 0 / 6%)',
      },
      arrow: {
        color: '#FFF',
      }
    })
)(TooltipRaw);
