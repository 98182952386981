import { gql } from '@apollo/client';

type DATE = string | number;

type OPEN_PRICE = number;

type CLOSE_PRICE = number;

type HIGH_PRICE = number;

type LOW_PRICE = number;

type PLD = number;

type QUANTITY_MWM = number;

type PLD_HIGH = number;

/**
 * @return [DATE, [OPEN, CLOSE, HIGH, LOW]]
 */
export type BbceProductPrices = [DATE, [OPEN_PRICE, CLOSE_PRICE, HIGH_PRICE, LOW_PRICE, PLD, QUANTITY_MWM, PLD_HIGH]];

export interface PayloadProps {
  bbceProductId: string;
}

export interface ResponseProps {
  bbceProductPrices: BbceProductPrices[];
}

const BBCE_PRODUCTS_PRICES = gql`
  query bbceProductsPrices($bbceProductId: ID!) {
    bbceProductPrices(bbceProductId: $bbceProductId)
  }
`;

export default BBCE_PRODUCTS_PRICES;
