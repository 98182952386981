import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  gap: 8px;
  height: 100%;
  flex: 1;

  & * {
    color: ${({ color }) => color};
  };
`;

export const Title = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  width: 100%;
  letter-spacing: 0px;
  font-weight: 700;
  line-height: 1.2;
  flex: 1;
`;

export const Values = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-row-gap: 24px;
  height: 100%;
  flex: 1;

  :first-child {
    padding-right: 36px;
  }

  :last-child {
    padding-left: 36px;
  }

  flex-flow: column nowrap;

  span {
    font-weight: 500;
    text-align: center;

    :first-child {
      font-size: 58px;
    }
    :last-child {
      font-size: 12px;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
`;

export const Fields = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 1;

  :before {
    content: '';
    position: absolute;
    left: 45%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 32px;
    width: 1px;
    background-color: #AAA;
  }
`;
