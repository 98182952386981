/* eslint-disable no-eval */
import { TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import MuiTable, { TableProps as MuiTableProps } from '@material-ui/core/Table';
import { Skeleton } from '@material-ui/lab';
import { DateTime } from 'luxon';
import { nanoid } from 'nanoid';

import { BalanceProps, DreProps } from '@graphql/query/portfolio';

import { RowProps } from '..';

import TableCell from './TableCell';
import useStyles from './styles';

export interface TableProps extends MuiTableProps {
  title: string;
  rows: Array<RowProps>;
  data: Array<BalanceProps | DreProps> | undefined;
  field?: string;
  loading: boolean;
}

const highlightCache = {};

const highlight = (period: string) => {
  if (!highlightCache[period]) {
    const data = { current: false, previous: false };
    let currentPeriod = DateTime.local()
      .minus({ months: DateTime.now().day > 15 ? 0 : 1 })
      .endOf('month');
    let periodDate = DateTime.local(...period.split('-').map((item) => Number(item))).endOf('month');

    if (/^\d{4}$/.test(period)) {
      currentPeriod = currentPeriod.endOf('year');
      periodDate = periodDate.endOf('year');
    }

    if (data) {
      if (periodDate.equals(currentPeriod)) {
        data.current = true;
      } else if (periodDate < currentPeriod) {
        data.previous = true;
      }
    }

    highlightCache[period] = data;
  }

  return highlightCache[period];
};

export const decimalScales = {
  price: 2,
  totalAmount: 0,
  quantityMwm: 3,
};

const dumbs = (total: number) => {
  const ids: Array<string> = [];

  for (let i = 1; i <= total; i += 1) ids.push(nanoid());

  return ids;
};

const Table: React.FC<TableProps> = ({ title, rows, data, field, loading, ...props }) => {
  const { tableContainer, tableRow } = useStyles();

  return (
    <TableContainer className={tableContainer}>
      <MuiTable size="small" aria-label="customized table" {...props}>
        <TableHead>
          <TableRow className={tableRow}>
            <TableCell head sticky>
              {title}
            </TableCell>
            {!loading
              ? data?.map((item) => (
                  <TableCell key={`${title}-${item.period}`} head foldable align="right" {...highlight(item.period)}>
                    {item.period}
                  </TableCell>
                ))
              : dumbs(24).map((id) => (
                  <TableCell key={id} head>
                    <Skeleton variant="rect" animation="wave" width={80} height={20} />
                  </TableCell>
                ))}
            <TableCell head />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            row.decimalScale = field ? decimalScales[field] : row.decimalScale;

            return (
              <TableRow key={row.path} className={tableRow}>
                <TableCell sticky scope="row" align="left" {...row} />
                {!loading
                  ? data?.map((_item) => {
                      const item_field = row.path + (field ? `.${field}` : '');

                      return (
                        <TableCell
                          key={`${row.path}-${_item.period}`}
                          negative={eval(item_field) < 0}
                          align="right"
                          {...highlight(_item.period)}
                          {...row}
                        >
                          {eval(item_field) ?? row.defaultValue}
                        </TableCell>
                      );
                    })
                  : dumbs(24).map((id) => (
                      <TableCell key={id} sticky scope="row" {...row}>
                        <Skeleton variant="rect" animation="pulse" width={80} height={16} />
                      </TableCell>
                    ))}
                <TableCell sticky scope="row" {...row}>
                  &nbsp;
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

Table.defaultProps = {
  defaultValue: 0,
};

export default Table;
