import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.section`
  margin-bottom: unset;
  grid-template-columns: 1fr;
  display: grid;
  grid-gap: 8px;

  width: 100%;
  opacity: 1;

  & * {
    color: ${({ color }) => color};
  }

  @media screen and (min-width: 600px) {
    display: flex;
    flex-flow: row nowrap;
    grid-gap: unset;

    & > div:not(:first-child) {
      margin-left: 16px;
    }
  }

  ${({ hidden }) =>
    (hidden
      ? css`
          height: 0;
          opacity: 0;

          @media screen and (min-width: 600px) {
            margin-bottom: 0;
          }
        `
      : css`
          opacity: 1;

          @media screen and (min-width: 600px) {
            min-height: 88px;
          }
        `)};

  transition: all 300ms ease;
`;
