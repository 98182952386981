import styled, { css } from 'styled-components';

type IContainer = {
  error: boolean;
};

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div<IContainer>`
  ${({ error }) =>
    error &&
    css`
      &&& {
        fieldset {
          border-color: #f6554e;
        }

        input {
          color: #f6554e;
        }

        input + div {
          background-color: #f6554e;
          color: #f6554e;
        }

        p {
          display: none;
        }
      }
    `}

  input {
    text-transform: uppercase;
    text-align: center;
  }
`;
