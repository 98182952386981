import { useState, forwardRef, useCallback } from 'react';

import { Popover, useTheme } from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CalendarToday from '@material-ui/icons/CalendarToday';

import useColorMode from '~/hooks/useColorMode';
import usePortfolio from '~/pages/Portfolio/usePortfolio';
import { YearRange, DateProps } from '~/theme/components';

const YearRangePopper = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { startYear, endYear, setFilter } = usePortfolio();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { palette } = useTheme();
  const { colorTernary } = useColorMode();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApply = useCallback(
    ({ startYear, endYear }: DateProps) => {
      if (startYear) setFilter('startYear', startYear.toString());

      setFilter('endYear', (endYear ?? (startYear as number)).toString());
      handleClose();
    },
    [setFilter],
  );

  return (
    <>
      <Button
        ref={ref}
        variant="outlined"
        color="secondary"
        style={{
          border: `1px solid ${colorTernary(palette.grey[400], '#DCDCDC')}`,
        }}
        onClick={handleClick}
        endIcon={<CalendarToday />}
        {...props}
      >
        {startYear !== endYear ? [startYear, endYear].join(' - ') : startYear}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <YearRange
          onApply={handleApply}
          defaults={{ startYear: parseInt(startYear, 10), endYear: parseInt(endYear as string, 10) }}
          minYear={2021}
        />
      </Popover>
    </>
  );
});

YearRangePopper.displayName = 'YearRangePopper';

export default YearRangePopper;
