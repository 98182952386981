import { ApolloLink, Operation, RequestHandler, split } from '@apollo/client';
import { HttpLink } from '@apollo/client/link/http';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { AxiosRequestConfig } from 'axios';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';

import { fetcher } from '../http';
import cable from './cable';

interface HttpLinkProps extends AxiosRequestConfig {
  body: any;
}

const uri = `${process.env.REACT_APP_CORE_API_URL}/graphql`;

const handleFetch = async (url: string, { body: data, ...options }: HttpLinkProps) => {
  const response = await fetcher(url, { ...options, data });
  const body = JSON.stringify(response);

  return new Response(body);
};

const httpLink = new HttpLink({ uri, fetch: handleFetch });

const uploadLink = createUploadLink({ uri, fetch: handleFetch }) as unknown as ApolloLink | RequestHandler;

const operation = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  new ActionCableLink({ cable }),
  httpLink,
);

const isFileUpload = ({ variables }: Operation) => {
  const inputs = Object.values('input' in variables ? variables.input : variables);

  return inputs.some((value) => value instanceof File || value instanceof Blob);
};

const link = split(isFileUpload, uploadLink, operation);

export default link;
