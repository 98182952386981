import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.section`
  display: grid;
  grid-gap: 8px;

  grid-template-columns: repeat(2, minmax(160px, 1fr));

  width: 100%;
  opacity: 1;

  @media screen and (min-width: 600px) {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;
    grid-gap: unset;

    div:not(:first-child) {
      margin-left: 16px;
    }
  }

  ${({ hidden }) => (hidden
    ? css`
          height: 0;
          opacity: 0;

          @media screen and (min-width: 600px) {
            margin-bottom: 0;
          }
        `
    : css`
          opacity: 1;

          @media screen and (min-width: 600px) {
            min-height: 88px;
          }
        `)};

  transition: all 300ms ease;
`;
