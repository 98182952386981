import { ContentCopyIcon } from '@tradener/lumen';

import OptionItem from '../../OptionItem';
import { useQuoteOptions } from '../../useQuoteOptions';

function ActionDuplicate() {
  const { navigate } = useQuoteOptions();

  const onPushToForm = () => navigate({ type: 'duplicate' });

  return (
    <OptionItem data-testid="duplicate" onClick={onPushToForm} icon={<ContentCopyIcon boxSize="5" />}>
      Duplicar
    </OptionItem>
  );
}

export default ActionDuplicate;
