import { timezone } from '@DEPRECATED/utils';
import ptBR from '@DEPRECATED/utils/chart/locale/pt-br.json';
import { zonedTimeToUtc } from 'date-fns-tz';
import { merge } from 'lodash';

export function getOptions(properties) {
  const defaultYaxis = {
    tickAmount: 8,
    crosshairs: {
      show: false,
    },
    forceNiceScale: true,
    labels: {
      style: {
        colors: '#AAA',
        fontWeight: 500,
        fontFamily: 'Roboto',
        fontSize: '14px',
      },
      formatter: (value) => Number(value).toFixed(0),
    },
    tooltip: {
      enabled: false,
    },
  };

  const options = merge(
    {
      chart: {
        defaultLocale: 'pt-br',
        locales: [ptBR],
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 500,
          animateGradually: {
            enabled: true,
            delay: 250,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 650,
          },
        },
      },
      grid: {
        borderColor: '#8c8c8c',
      },
      fill: {
        opacity: 1,
      },
      colors: ['#2558F8', '#EA7117', '#CCC', '#48E66D'],
      dataLabels: {
        enabled: true,
        enabledOnSeries: [0, 1, 2],
        offsetY: -10,
        background: {
          enabled: false,
          dropShadow: {
            enabled: false,
          },
        },
        style: {
          fontSize: '12px',
          fontFamily: 'Roboto, Arial, sans-serif',
          fontWeight: 500,
          colors: ['#AAA', '#AAA', '#CCC'],
        },
        formatter: (value) => Number(value).toFixed(0),
      },
      markers: {
        size: [0, 0, 4, 0],
        colors: '#CCC',
        strokeColors: '#CCC',
        strokeWidth: 2,
        fillOpacity: 0,
        hover: {
          size: 4,
          sizeOffset: 4,
        },
      },
      stroke: {
        width: [0, 0, 2, 2],
        curve: 'straight',
        dashArray: [0, 0, 0, 5],
      },
      xaxis: {
        type: 'datetime',
        crosshairs: {
          show: true,
          position: 'front',
        },
        labels: {
          style: {
            colors: '#AAA',
            fontWeight: 500,
            fontFamily: 'Roboto',
            fontSize: '14px',
          },
          format: 'yyyy',
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#8c8c8c',
          height: 5,
          offsetX: 0,
          offsetY: 0,
        },
        tickPlacement: 'between',
      },
      yaxis: [
        {
          ...defaultYaxis,
          seriesName: 'Compra',
          title: {
            text: 'COMPRA / VENDA',
            style: {
              color: '#a8adba',
            },
          },
        },
        {
          ...defaultYaxis,
          seriesName: 'Compra',
          show: false,
        },
        {
          ...defaultYaxis,
          seriesName: 'Saldo',
          opposite: true,
          title: {
            text: 'SALDO',
            style: {
              color: '#a8adba',
            },
          },
        },
        {
          ...defaultYaxis,
          seriesName: 'Saldo',
          show: false,
        },
      ],
      legend: {
        show: true,
        fontWeight: 500,
        fontFamily: 'Roboto',
        fontSize: '12px',
        labels: {
          colors: '#AAA',
        },
        markers: {
          width: 15,
          height: 2,
          radius: 0,
        },
        position: 'top',
        itemMargin: {
          horizontal: 10,
          vertical: 0,
        },
        formatter(seriesName) {
          return [`${seriesName.toUpperCase()}`];
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
      },
      tooltip: {
        enabled: true,
        theme: 'light',
        x: {
          show: true,
          format: 'yyyy',
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '90%',
        },
        dataLabels: {
          position: 'top',
        },
      },
    },
    properties,
  );

  if (properties.yaxis) {
    options.yaxis = properties.yaxis;
  }

  return options;
}

export function getCategories(summaries) {
  if (!summaries) return [];

  return summaries.map((summary) => zonedTimeToUtc(summary.period, timezone).toString());
}

export function getAmountSeries(data) {
  const options = [
    { name: 'Compra', type: 'bar', data: [] },
    { name: 'Venda', type: 'bar', data: [] },
    { name: 'Saldo', type: 'line', data: [] },
  ];

  if (!data) return options;

  data.forEach((period) => {
    options[0].data.push(period.purchase.quantityMwm);
    options[1].data.push(period.sale.quantityMwm);
    options[2].data.push(period.balanceMwm);
  });

  return options;
}

export function getSeriesAveragePrice(data) {
  const options = [
    { name: 'Compra', type: 'line', data: [] },
    { name: 'Venda', type: 'line', data: [] },
  ];

  if (!data) return options;

  data.forEach((period) => {
    options[0].data.push(period.purchase.averagePrice);
    options[1].data.push(period.sale.averagePrice);
  });

  return options;
}

export function getSeries(data, isAveragePrice) {
  const getValues = isAveragePrice ? getSeriesAveragePrice : getAmountSeries;

  return getValues(data);
}
