import { Flex } from '@tradener/lumen';

import DetailsCard from './DetailsCard';
import ResumeCard from './ResumeCard';

function LeftContent() {
  return (
    <Flex direction="column" align="flex-start" padding="2" gap="4">
      <DetailsCard />
      <ResumeCard />
    </Flex>
  );
}

export default LeftContent;
