import { useState } from 'react';

import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import { useQuery } from '@apollo/client';
import { Box, TableCellProps } from '@material-ui/core';

import Portfolio, { PayloadProps, ResponseProps } from '@graphql/query/portfolio';

import usePortfolio from '~/pages/Portfolio/usePortfolio';

import Badges from '../../Badges';
import usePortfolioFilter, { OpenTypes } from '../../usePortfolioFilter';
import Modal from './Modal';
import Table from './Table';
import { BALANCE_ROWS, DRE_ROWS } from './setting';
import useStyles from './styles';

export interface RowProps {
  children: (string | JSX.Element)[] | string;
  strong?: boolean;
  path: string;
  decimalScale?: number;
  defaultValue?: number | string;
}

export interface AttributeProps {
  prop: keyof TableCellProps;
  value: any;
}
export interface InterpolateProps {
  key: string;
  attribute: AttributeProps | Array<AttributeProps>;
}

function test(content: string, find: string) {
  const regExp = new RegExp(content, 'i');

  return regExp.exec(find);
}

function Balance() {
  const { line } = useStyles();
  const [open, setOpen] = useState<OpenTypes | undefined>(undefined);
  const { excludedContracts, setFilter: _, ...state } = usePortfolio();
  const { isOpen, view } = usePortfolioFilter();

  const { loading, data } = useQuery<ResponseProps, PayloadProps>(Portfolio, {
    variables: { excludedContracts: Object.keys(excludedContracts), ...state },
    fetchPolicy: 'cache-first',
  });

  const appendProperty = <K extends keyof TableCellProps>(prop: K, value: TableCellProps[K]) => ({ prop, value });

  const inject = (data: typeof BALANCE_ROWS, interpolate: Array<InterpolateProps>) => {
    for (const { key, attribute } of interpolate) {
      const index = data.findIndex(({ children }) => {
        if (typeof children === 'string') return test(children, key);

        return children.filter((ocurrence) => typeof ocurrence === 'string' && test(ocurrence, key)).length > 0;
      });

      if (index > -1) {
        if (Array.isArray(attribute)) for (const { prop, value } of attribute) data[index][prop] = value;
        else data[index][attribute.prop] = attribute.value;
      }
    }

    return data;
  };

  return (
    <Box width={`calc(100vw - ${isOpen ? '372px' : '96px'})`} overflow="auto">
      <Badges marginBottom="10px" />
      <ScrollSync>
        <Box display="flex" flexDirection="column">
          <ScrollSyncPane>
            <Table
              title="BALANÇO"
              rows={inject(BALANCE_ROWS, [
                ...['COMPRA', 'VENDA'].map((key) => ({
                  key,
                  attribute: [
                    appendProperty('onClick', () => setOpen(key as OpenTypes)),
                    appendProperty('classes', { root: line }),
                  ],
                })),
              ])}
              field={view}
              data={data?.portfolio.balance}
              loading={loading}
            />
          </ScrollSyncPane>
          <ScrollSyncPane>
            <Table title="EXPOSIÇÃO" rows={DRE_ROWS} data={data?.portfolio.dre} loading={loading} />
          </ScrollSyncPane>
        </Box>
      </ScrollSync>
      {open && <Modal open={open} dispatch={setOpen} onClose={() => setOpen(undefined)} balance={data?.portfolio.balance} />}
    </Box>
  );
}

export default Balance;
