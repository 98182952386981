import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import { Container, Grid } from './styles';

function InputLayout({ label, children, divider, ...others }) {
  return (
    <Container>
      <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap" {...others}>
        <Grid item className="label">
          <Typography component="span">{label}</Typography>
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
      {divider && <Divider />}
    </Container>
  );
}

InputLayout.defaultProps = {
  divider: true,
};

InputLayout.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  divider: PropTypes.bool,
};

export default InputLayout;
