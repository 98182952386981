import { Box, IconButton, CircularProgress } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { useBoolean } from '@tradener/lumen';

import useColorMode from '~/hooks/useColorMode';
import client from '~/services/apollo';
import Filter from '~/theme/icons/Filter';
import Header from '~/theme/layout/Header';

import usePortfolioFilter from '../../usePortfolioFilter';
import Convert from './Convert';
import InternalAccounts from './InternalAccounts';
import YearRange from './YearRange';

const Navbar: typeof Header = (props) => {
  const { onToggle } = usePortfolioFilter();
  const { theme, colorTernary } = useColorMode();
  const [loading, { on, off }] = useBoolean();

  const onRefresh = async () => {
    try {
      on();

      await Promise.all([
        client.resetStore(),
        client.refetchQueries({
          include: ['portfolio'],
          optimistic: true,
        }),
      ]);
    } finally {
      off();
    }
  };

  return (
    <Header {...props}>
      <Box display="flex" flexDirection="row" alignItems="center" paddingRight="20px" gridColumnGap="10px">
        <YearRange />
        <InternalAccounts style={{ width: '200px' }} />
        <Convert />
        <IconButton onClick={onToggle} size="small" style={{ border: 'none' }}>
          <Filter width="20px" height="20px" color={theme.palette.grey[colorTernary(500, 900)]} />
        </IconButton>
        <IconButton onClick={onRefresh} disabled={loading} size="small" style={{ border: 'none' }}>
          {loading ? <CircularProgress size={20} color="primary" /> : <Refresh />}
        </IconButton>
      </Box>
    </Header>
  );
};

export default Navbar;
