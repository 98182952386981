import { useEffect, useCallback } from 'react';

import { useParams, useNavigate, useLocation } from 'react-router-dom';

import Card from '~/DEPRECATED/components/ConfirmationDialog/Card';
import Proposals from '@DEPRECATED/context/Proposals';
import { useLazyQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import gql from 'graphql-tag';

import Navigation from './navigation';

export const GET_QUOTE = gql`
  query Quote($id: ID!) {
    quote(id: $id) {
      id
      offerCode
      accountName
      opportunity {
        account {
          id
          name
          crmId
          taxId
        }
        category
        channel
        tenant {
          id
          name
        }
        buyer {
          id
          name
          taxId
          crmRecordType
        }
        seller {
          id
          name
          taxId
          crmRecordType
        }
        type
      }
      baseDate
      dueDateType
      dueDateValue
      externalId
      startDate
      endDate
      expireDate
      flexNegative
      flexPositive
      giro
      readjustmentIndex
      readjustmentMonth
      loss
      modulation
      notes
      swap
      lockCharges
      periodType
      priceType
      quoteItems {
        id
        product {
          shortName
          unit
        }
        group
        amount
        requestedAmount
        spreadAmount
        quantity
        startDate
        endDate
      }
      retusdAmount
      sazoPercent
      submarket
      volumeType
      quoteInformation {
        id
        name
        type
        template
        value
        order
      }
      auditLogs {
        id
        action
        createdAt
        requester {
          ... on User {
            email
          }
          ... on ClientApp {
            name
          }
        }
      }
      emailMessages {
        id
        createdAt
        emailMessageEvents {
          event
        }
        recipient {
          ... on Contact {
            email
          }
          ... on User {
            email
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: 608,
    height: '100vh',
    borderRadius: 0,
    backgroundColor: theme.palette.background.default,
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Opportunities() {
  const { opportunityType = 'sale', id } = useParams();
  const query = useQuery();
  const push = useNavigate();
  const classes = useStyles();

  const [getData, { data }] = useLazyQuery(GET_QUOTE, {
    fetchPolicy: 'cache-and-network',
  });

  function handleClose() {
    push('/book/list');
  }

  const getQuote = useCallback(() => {
    if (id) getData({ variables: { id } });
  }, [getData, id]);

  useEffect(() => {
    getQuote();
  }, [getQuote]);

  return (
    <Proposals opportunityType={opportunityType} initialValue={data?.quote} operation={query.get('operation')}>
      <Modal open disableEscapeKeyDown>
        <Fade in>
          <Card
            title={opportunityType === 'sale' ? 'Nova Venda' : 'Nova Compra'}
            onClose={handleClose}
            classes={{
              root: classes.root,
            }}
          >
            <Navigation />
          </Card>
        </Fade>
      </Modal>
    </Proposals>
  );
}
