import { forwardRef } from 'react';

import MuiOutlinedInput from '@material-ui/core/OutlinedInput';

import { useStyles, OutlinedStyles } from './styles';

const OutlinedInput = forwardRef((props, ref) => {
  const classes = useStyles();

  return <MuiOutlinedInput classes={{ ...classes }} inputRef={ref} {...props} />;
});

OutlinedInput.defaultProps = {
  color: 'secondary',
};

export default OutlinedInput;
export { useStyles, OutlinedStyles };
