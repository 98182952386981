import styled, { css } from 'styled-components';

export const Container = styled.li`
  ${({ theme }) => css`
    margin-top: 8px;
    ${(theme?.palette?.type === 'light') && `background: #F6F6F6` || `background: #090909`};

    display: flex;

    padding: 16px 24px;

    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;

    hr {
      margin: 8px 0;
    }

    &:not(:disabled) svg {
      ${(theme?.palette?.type === 'light') &&
        `color: #444` || `color: #888`};
    }

    &:disabled svg {
      ${(theme?.palette?.type === 'light') &&
        `color: #222` || `color: #444`};
    }

    &:focus {
      ${(theme?.palette?.type === 'light') &&
        `background-color: #737373` || `background-color: #8c8c8c`};

      svg {
        ${(theme?.palette?.type === 'light') &&
          `color: #838383` || `color: #7c7c7c`};
      }
    }

    &:last-child {
      margin-bottom: 72px;
    }

    > div {
      :not(:first-child) {
        margin-left: 16px;
      }

      :last-child {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 32px;
        max-width: 32px;
        flex: 1;
      }
    }
  `}
`;

export const ButtonDelete = styled.button`
  ${({ theme }) => css`
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 50%;
    ${(theme?.palette?.type === 'light') &&
      `background-color: #AAA` || `background-color: #555`};

    &:not(:disabled) svg {
      ${(theme?.palette?.type === 'light') &&
        `color: #444` || `color: #BBB`};
    }

    &:disabled svg {
      ${(theme?.palette?.type === 'light') &&
        `color: #222` || `color: #DDD`};
    }

    &:focus {
      ${(theme?.palette?.type === 'light') &&
        `background-color: #737373` || `background-color: #8c8c8c`};

      svg {
        ${(theme?.palette?.type === 'light') &&
          `color: #838383` || `color: #7c7c7c`};
      }
    }
  `}
`;

export const PeriodView = styled.section`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  width: 160px;
  height: 100%;
  border-radius: 2px;

  span {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 80px;
    height: 16px;
    border-radius: 16px;

    font-size: 10px;
    font-weight: 500;

    text-transform: uppercase;

    margin-bottom: 8px;
  }

  strong {
    font-weight: 700;
    text-transform: uppercase;
  }
`;
