import useColorMode from '~/hooks/useColorMode';

import { withProvider } from '../../provider';
import BookHeader from '../BookHeader';
import OfferCounter from './OfferCounter';
import OfferNegotiation from './OfferNegotiation';
import OfferProgress from './OfferProgress';
import { Container } from './styles';
import SelectInternalAccounts from '~/DEPRECATED/components/SelectInternalAccounts';

const BookChart = () => {
  const { colorTernary } = useColorMode();

  return (
    <>
      <BookHeader>
        <SelectInternalAccounts />
      </BookHeader>
      <Container color={colorTernary('#373737', '#dedede')}>
        <OfferProgress />
        <OfferNegotiation />
        <OfferCounter />
      </Container>
    </>
  );
};

export default withProvider(BookChart);
