import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    maxWidth: 480,
  },
  card: {
    padding: '20px 30px',
    width: '100%',
    backgroundColor: palette.common.white,
  },
}));

export default useStyles;
