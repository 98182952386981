import { useMutation } from '@apollo/client';
import { useToast } from '@tradener/lumen';

import CHANGE_QUOTE_STAGE, { ResponseProps, PayloadProps, QuoteStageActions } from '@graphql/mutation/changeQuoteStage';

import { StageActions, useQuoteOptions } from './useQuoteOptions';

const CHANGE_QUOTE_STAGES: { [K in StageActions]: string } = {
  CLOSE: 'Oferta fechada com sucesso',
  CANCEL: 'Oferta cancelada',
  REJECT: 'Oferta rejeitada',
  PROPOSE: 'Proposta Emitida',
};

function useChangeQuoteStage() {
  const { id, onClose } = useQuoteOptions();
  const toast = useToast();
  const [mutateQuoteStage] = useMutation<ResponseProps, PayloadProps>(CHANGE_QUOTE_STAGE);

  const changeQuoteStage = (action: StageActions) =>
    mutateQuoteStage({
      variables: { input: { quoteId: id, action: action as QuoteStageActions } },
      onCompleted: () => {
        onClose();

        toast({ description: CHANGE_QUOTE_STAGES[action], status: 'success' });
      },
      onError: ({ message }) => toast({ description: message, status: 'error' }),
    });

  return changeQuoteStage;
}

export default useChangeQuoteStage;
