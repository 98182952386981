import TableBodyRaw from '@material-ui/core/TableBody';
import TableCellRaw from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    ${theme?.palette?.type === 'light' && `background-color: #FFF` || `background-color: #232323`};
    padding: 0 ${theme.spacing(1)}px;
    min-width: 160px;
  `}
`;

export const TableCell = withStyles((theme) => {
  const isLightTheme = theme?.palette?.type === 'light';

  return ({
    root: {
      border: 'inherit',
      color: isLightTheme ? '#000' : '#7c7c7c',
      '&.negative': {
        color: '#ff0046',
      },
    },
    body: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '19px',
      color: isLightTheme ? '#000' : '#7c7c7c',
    },
    alignLeft: {
      fontWeight: 700,
      color: isLightTheme ? '#111' : '#EEE',
      textTransform: 'uppercase',
    },
    sizeSmall: {
      padding: '8px 0 !important',
    },
  })
})(TableCellRaw);

export const TableBody = withStyles((theme) => {
  const isLightTheme = theme?.palette?.type === 'light';

  return ({
    root: {
      '& > :not(:first-child)': {
        borderTop: `1px solid ${isLightTheme ? `#EEE` : `#373737`}`,
      },
    },
  })
})(TableBodyRaw);
