import { Box, BoxProps, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import useStyles from './styles';

export interface ModalHeaderProps extends BoxProps {
  onClose?: () => void;
}

const ModalHeader: React.ComponentType<ModalHeaderProps> = ({ children, onClose, ...props }) => {
  const { root } = useStyles();

  return (
    <Box className={root} {...props}>
      <Box display="flex" flexDirection="column">
        {children}
      </Box>
      {onClose && (
        <IconButton onClick={onClose} size="small">
          <Close />
        </IconButton>
      )}
    </Box>
  );
};

ModalHeader.displayName = 'ModalHeader';

export default ModalHeader;
