import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-items: center;

  height: 42px;

  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
`;

export const Title = styled.span`
  font-size: 14px;
  font-weight: 500;

  @media screen and (min-width: 600px) {
    font-size: 18px;
  }
`;

export const Subtitle = styled.span`
  font-size: 12px;

  @media screen and (min-width: 600px) {
    font-size: 14px;
  }
`;
