import ptBR from '@DEPRECATED/utils/chart/locale/pt-br.json';
import { addMonths, startOfYear } from 'date-fns';

// eslint-disable-next-line import/prefer-default-export
export const options = {
  chart: {
    defaultLocale: 'pt-br',
    locales: [ptBR],
    stacked: true,
    toolbar: { show: false },
    zoom: { enabled: false },
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 500,
      animateGradually: { enabled: true, delay: 250 },
      dynamicAnimation: { enabled: true, speed: 650 },
    },
  },
  grid: { borderColor: '#232D44' },
  fill: { opacity: 1 },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: { position: 'bottom', offsetX: -10, offsetY: 0 },
      },
    },
  ],
  colors: ['#0EBE29', '#F9BC16', '#EEE'],
  dataLabels: { enabled: false },
  xaxis: {
    type: 'datetime',
    categories: Array.from({ length: 13 }).map((_, i) => {
      if (!i) return startOfYear(new Date()).toISOString();

      return addMonths(startOfYear(new Date()), i).toISOString();
    }),
    crosshairs: { show: false, position: 'front' },
    labels: {
      style: {
        colors: '#AAA',
        fontWeight: 500,
        fontFamily: 'Roboto',
        fontSize: '10px',
      },
      format: 'MMM',
      hideOverlappingLabels: false,
    },
    axisBorder: { show: false },
    axisTicks: {
      show: true,
      borderType: 'solid',
      color: '#232D44',
      height: 5,
      offsetX: 0,
      offsetY: 0,
    },
    tickPlacement: 'between',
  },
  yaxis: {
    min: (min: number) => min,
    max: (max: number) => max,
    crosshairs: { show: false },
    forceNiceScale: true,
    labels: {
      style: {
        colors: '#a8adba',
        fontWeight: 500,
        fontFamily: 'Roboto',
        fontSize: '12px',
      },
      formatter: (val: number) => Math.round(val),
    },
    tooltip: { enabled: false },
  },
  legend: { show: false },
  tooltip: {
    enabled: true,
    theme: 'dark',
    x: {
      show: true,
      format: 'MMM',
    },
  },
  plotOptions: { bar: { columnWidth: '48px' } },
};
