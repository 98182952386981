import OfferContainer from '../OfferContainer';
import OfferHeaderItem from './OfferHeaderItem';

function OfferHeader() {
  return (
    <OfferContainer top="0" position="sticky" bgColor="inherit" zIndex="1" borderBottom="1px solid" borderBottomColor="gray.200">
      <OfferHeaderItem>Operação</OfferHeaderItem>
      <OfferHeaderItem>Origem / Destino</OfferHeaderItem>
      <OfferHeaderItem>Código</OfferHeaderItem>
      <OfferHeaderItem>Canal</OfferHeaderItem>
      <OfferHeaderItem>Trader</OfferHeaderItem>
      <OfferHeaderItem>Obs.</OfferHeaderItem>
      <OfferHeaderItem justifyContent="center">Status</OfferHeaderItem>
      <OfferHeaderItem>Data / Expiração</OfferHeaderItem>
      <OfferHeaderItem justifyContent="flex-end">Valor</OfferHeaderItem>
    </OfferContainer>
  );
}

export default OfferHeader;
