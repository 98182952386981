import Widget from '@DEPRECATED/components/Widget';
import WidgetContent from '@DEPRECATED/components/WidgetContent';
import WidgetHeader from '@DEPRECATED/components/WidgetHeader';
import PropTypes from 'prop-types';

import useColorMode from '~/hooks/useColorMode';

import Operation from '../Operation';
import { Container } from './styles';

function PurchaseAndSale(props) {
  const { colorTernary } = useColorMode();
  const { subtitle, data, isAveragePrice } = props;

  return (
    <Container color={colorTernary('white', '#171717')}>
      <Widget color="#2558F8">
        <WidgetHeader title="Compra" subtitle={subtitle} />
        <WidgetContent>
          <Operation operation="purchase" data={data} isAveragePrice={isAveragePrice} color="#2558F8" />
        </WidgetContent>
      </Widget>
      <Widget color="#EB6100">
        <WidgetHeader title="Venda" subtitle={subtitle} />
        <WidgetContent>
          <Operation operation="sale" data={data} isAveragePrice={isAveragePrice} color="#EB6100" />
        </WidgetContent>
      </Widget>
    </Container>
  );
}

PurchaseAndSale.defaultProps = {
  isAveragePrice: false,
  data: {},
};

PurchaseAndSale.propTypes = {
  subtitle: PropTypes.string.isRequired,
  data: PropTypes.shape({}),
  isAveragePrice: PropTypes.bool,
};

export default PurchaseAndSale;
