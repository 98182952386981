import MenuItemRaw from '@material-ui/core/MenuItem';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme, fullHeight }) => css`
    height: calc(100% - 58px);
    width: 100%;

    .MuiTab-root {
      ${(theme?.palette?.type === 'light') && `background-color: #EEE` || `background-color: transparent`};
      min-width: fit-content;
      flex: 1;
    }

    .MuiTabs-flexContainer {
      align-items: stretch;
      justify-content: stretch;
      width: 100%;
    }

    ${fullHeight
    && css`
      min-height: 100vh;
    `}

    && {
      .Mui-selected,
      > hr {
        ${(theme?.palette?.type === 'light') && `background-color: #FFF` || `background-color: #373737`};
      }

      > hr {
        height: 4px;
      }
    }
  `}
`;

export const MenuItem = styled(MenuItemRaw)`
  width: 100%;
  height: 48px;
`;
