import { useQuery } from '@apollo/client';
import { Card, Flex, Text, Link } from '@tradener/lumen';

import GET_ACCOUNT_DETAILS, { PayloadProps, ResponseProps } from '@graphql/query/accountDetails';

import { useAutocomplete } from '..';

import OpenInNew from '~/theme/icons/OpenInNew';

import ParentAccount from './ParentAccount';

function CompanyDetails() {
  const { accountId, watch, data } = useAutocomplete();
  const { searchFor, chosenOption } = watch();

  const { data: details, loading } = useQuery<ResponseProps, PayloadProps>(GET_ACCOUNT_DETAILS, {
    skip: !accountId,
    variables: {
      id: accountId as string,
    },
  });

  const attentionPoints = details?.accountDetails.attentionPoints ?? 0;

  const longTermContracts = details?.accountDetails.longTermContracts ? 'Sim' : 'Não';

  const attentionLink = chosenOption
    ? `${process.env.REACT_APP_SALESFORCE_URL}/lightning/r/Account/${chosenOption.crmId}/view`
    : '';

  const lpContractsLink = chosenOption
    ? `${process.env.REACT_APP_SALESFORCE_URL}/lightning/r/Contract/${chosenOption.crmId}/related/Contracts/view`
    : '';

  if (!accountId || (loading && !searchFor && data?.length)) return null;

  return (
    <Card
      display="flex"
      flexDirection="column"
      width="full"
      height="fit-content"
      bgColor="inherit"
      padding="4"
      gap="10px"
      borderWidth="1px"
      borderColor="gray.300"
    >
      <ParentAccount />
      <Flex width="full" direction="column" align="flex-start" gap="4">
        <Flex width="full" align="center" justify="space-between">
          <Text textStyle="body2-regular" color="gray.500">
            Pontos de atenção
          </Text>
          <Flex align="center" color="orange.500">
            <Flex
              as={Link}
              href={attentionLink}
              isExternal
              target="_blank"
              data-testid="attention-points-link"
              align="center"
              textDecor="none"
              cursor="pointer"
              gap="1"
            >
              <Text textStyle="body2-regular">{attentionPoints}</Text>
              <OpenInNew
                style={{
                  width: '0.875rem',
                  height: '0.875rem',
                  fontSize: '0.875rem',
                  color: 'var(--lumen-colors-orange-500) !important',
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex width="full" direction="column" align="flex-start" gap="4">
        <Flex width="full" align="center" justify="space-between">
          <Text textStyle="body2-regular" color="gray.500">
            Possui contratos LP
          </Text>
          <Flex align="center" color="orange.500">
            <Flex
              as={Link}
              href={lpContractsLink}
              isExternal
              target="_blank"
              data-testid="lp-contracts-link"
              align="center"
              textDecor="none"
              cursor="pointer"
              gap="1"
            >
              <Text textStyle="body2-regular">{longTermContracts}</Text>
              <OpenInNew
                style={{
                  width: '0.875rem',
                  height: '0.875rem',
                  fontSize: '0.875rem',
                  color: 'var(--lumen-colors-orange-500) !important',
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}

export default CompanyDetails;
