import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  && {
    input {
      text-transform: uppercase;
    }
  }
`;
