import { Grid, GridProps } from '@tradener/lumen';

import useColorMode from '~/hooks/useColorMode';
import { grey } from '~/theme';

const TableVerticalAxis: React.ComponentType<GridProps> = ({ bgColor, ...props }) => {
  const { colorTernary } = useColorMode();

  return (
    <Grid gridTemplateColumns="138px" gridAutoRows="40px" bgColor={bgColor ?? colorTernary(grey[800], 'white')} {...props} />
  );
};

export default TableVerticalAxis;
