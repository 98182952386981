import { useState, useContext, useEffect } from 'react';

import { MdArrowDropDown } from 'react-icons/md';

import TabPanel from '@DEPRECATED/components/TabPanel';
import ProposalsContext from '@DEPRECATED/context/Proposals/context';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Menu from '@material-ui/core/Menu';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Divider } from '@material-ui/core';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';

import OpportunityFooter from '../OpportunityFooter';
import Condition from './Condition';
import Email from './Email';
import History from './History';
import Resume from './Resume';
import { Container, MenuItem } from './styles';
import useQuoteInformation from '~/hooks/useQuoteInformation';

export default function Proposed(props) {
  const { index, handlePrevious } = props;
  const { submitProposal, data } = useContext(ProposalsContext);
  const { generateInitialValues, invalidQuoteInformation } = useQuoteInformation();
  const [disable, setDisable] = useState(invalidQuoteInformation);
  const [editingQuoteInformation, setEditingQuoteInformation] = useState(false);

  const { auditLogs, emailMessages } = data;

  const buttonSave = usePopupState({
    variant: 'popover',
    popupId: 'button-save',
  });

  const [value, setValue] = useState(0);

  function handleChange(_, newValue) {
    setValue(newValue);
  }

  async function handleQuoteStage(action) {
    submitProposal(action);
    buttonSave.close();
  }

  function handleClick() {
    if (disable) return;
    setDisable(true);
    submitProposal({
      saveAndPropose: false,
      saveAndReject: false,
    });
  }

  useEffect(() => {
    generateInitialValues();
  }, []);

  useEffect(() => {
    setDisable(invalidQuoteInformation || editingQuoteInformation);
  }, [invalidQuoteInformation, editingQuoteInformation]);

  return (
    <>
      <Container fullHeight={!index}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Proposta"
          indicatorColor="primary"
          TabIndicatorProps={{
            hidden: true,
          }}
        >
          <Tab label="Resumo" />
          <Tab label="Condições Gerais" />
          <Tab label="Histórico" />
          <Tab label="E-mails" />
        </Tabs>
        <Divider />
        <TabPanel value={value} index={0}>
          <Resume />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Condition setEditingQuoteInformation={setEditingQuoteInformation} readOnly={!index} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <History logs={auditLogs} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Email data={emailMessages} />
        </TabPanel>
      </Container>
      {index && (
        <OpportunityFooter index={index} handlePrevious={handlePrevious} labelPrevious="Oferta">
          <ButtonGroup
            aria-label="split button"
            style={{
              order: 2,
            }}
          >
            <Button
              variant="contained"
              disableElevation
              color="primary"
              onClick={handleClick}
              disabled={disable}
              style={{
                width: 128,
              }}
            >
              Salvar
            </Button>
            <Button
              disabled={disable}
              color="primary"
              disableElevation
              variant="contained"
              size="small"
              {...bindTrigger(buttonSave)}
            >
              <MdArrowDropDown size={24} />
            </Button>
            <Menu
              variant="menu"
              {...bindMenu(buttonSave)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={() => handleQuoteStage({ saveAndReject: true })}>Salvar e Rejeitar Proposta</MenuItem>
              <MenuItem onClick={() => handleQuoteStage({ saveAndPropose: true })}>Salvar e Emitir Proposta</MenuItem>
            </Menu>
          </ButtonGroup>
        </OpportunityFooter>
      )}
    </>
  );
}
