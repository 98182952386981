import { gql } from '@apollo/client';

export interface BalanceSubmarketProps {
  totalAmount: number;
  quantityMwm: number;
  price: number;
}

export interface BalanceOperationProps {
  se: BalanceSubmarketProps;
  s: BalanceSubmarketProps;
  ne: BalanceSubmarketProps;
  n: BalanceSubmarketProps;
  totalAmount: number;
  quantityMwm: number;
  price: number;
}
export interface BalanceProps {
  period: string;
  quantityMwm: number;
  totalAmount: number;
  purchase: BalanceOperationProps;
  sale: BalanceOperationProps;
}

export interface DreSubmarketProps {
  balanceMwm: number;
  pld: number;
}

export interface DreProps {
  period: string;
  cceeAmount: number;
  balanceAmount: number;
  ballastCon?: number;
  ballastGlobal?: number;
  ballastInc?: number;
  se: DreSubmarketProps;
  s: DreSubmarketProps;
  ne: DreSubmarketProps;
  n: DreSubmarketProps;
}

export interface PortfolioProps {
  balance: Array<BalanceProps>;
  dre: Array<DreProps>;
}

export interface DataProps {
  portfolio: PortfolioProps;
}

export type PeriodTypes = 'LONG' | 'SHORT';

export type PriceTypes = 'FIXO' | 'PERCENT_PLD' | 'SPREAD';

export type AccountingPortfolioTypes = 'TRADING' | 'WHOLESALE';

export type ResponseProps = DataProps;

export interface PayloadProps {
  startYear: string;
  endYear?: string;
  energyType?: Array<string>;
  periodType?: PeriodTypes;
  priceType?: Array<PriceTypes>;
  operation?: string;
  expandedPeriods: Array<string>;
  usageFactorByContract: boolean;
  usageFactorByEnergyType: boolean;
  usageFactor: boolean;
  excludedContracts: Array<string>;
  tenantId?: Array<string>;
  internalAccounts?: Array<string>;
  giro?: string;
  hasGiro?: boolean;
  contractFlags?: Array<string>;
  accountingPortfolio?: AccountingPortfolioTypes;
  closeDateLte?: string;
  closeDateGte?: string;
  accountTypes?: Array<string>;
}

const PORTFOLIO_FRAGMENT = gql`
  fragment balanceSubmarket on BalanceSubmarket {
    totalAmount
    quantityMwm
    price
  }

  fragment balance on BalanceOperation {
    se {
      ...balanceSubmarket
    }
    s {
      ...balanceSubmarket
    }
    ne {
      ...balanceSubmarket
    }
    n {
      ...balanceSubmarket
    }
    totalAmount
    quantityMwm
    price
  }

  fragment dre on DreSubmarket {
    balanceMwm
    pld
  }

  query portfolio(
    $startYear: String!
    $endYear: String
    $energyType: [String!]
    $periodType: QuotePeriod
    $priceType: [QuotePrice!]
    $operation: String
    $expandedPeriods: [String!]
    $excludedContracts: [String!]
    $usageFactorByContract: Boolean!
    $usageFactorByEnergyType: Boolean!
    $usageFactor: Boolean!
    $tenantId: [ID!]!
    $giro: String
    $hasGiro: Boolean
    $contractFlags: [String!]
    $accountingPortfolio: ContractPortfolio
    $closeDateLte: ISO8601Date
    $closeDateGte: ISO8601Date
    $accountTypes: [String!]
  ) {
    portfolio(
      startYear: $startYear
      endYear: $endYear
      energyType: $energyType
      periodType: $periodType
      priceType: $priceType
      operation: $operation
      expandedPeriods: $expandedPeriods
      excludedContracts: $excludedContracts
      usageFactorByContract: $usageFactorByContract
      usageFactorByEnergyType: $usageFactorByEnergyType
      usageFactor: $usageFactor
      tenantId: $tenantId
      giro: $giro
      hasGiro: $hasGiro
      contractFlags: $contractFlags
      accountingPortfolio: $accountingPortfolio
      closeDateLte: $closeDateLte
      closeDateGte: $closeDateGte
      accountTypes: $accountTypes
    ) {
      balance {
        period
        quantityMwm: balanceMwm
        totalAmount
        purchase {
          ...balance
        }
        sale {
          ...balance
        }
      }
      dre {
        period
        cceeAmount
        balanceAmount
        ballastCon
        ballastGlobal
        ballastInc
        se {
          ...dre
        }
        s {
          ...dre
        }
        ne {
          ...dre
        }
        n {
          ...dre
        }
      }
    }
  }
`;

export default PORTFOLIO_FRAGMENT;
