import TableCell from '@material-ui/core/TableCell';
import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const TableCellBody = styled(TableCell)`
  ${({ transform, theme, onClick }) => css`
    && {
      height: 56px;
      font-size: 14px;
      line-height: 1.33;
      letter-spacing: 0.6px;
      font-weight: 700;
      padding: 4px 12px;
      border: 0;
      border-color: transparent;
      margin: 0;

      cursor: ${onClick ? 'pointer' : 'normal'};

      text-transform: ${transform || 'none'};

      &:first-child {
        border-bottom-left-radius: 2px;
        border-top-left-radius: 2px;
      }

      &:last-child {
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;
      }

      div {
        display: flex;
        align-items: center;
      }

      button span svg {
      }

      &.truncate {
        max-width: 40ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  `}
`;
