import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div.attrs({
  id: 'apex-year-container',
})`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  flex-flow: column nowrap;
  ${({ theme }) => (theme?.palette?.type === 'light') &&
    `background-color: #FFF` || `background-color: #111`};
  min-height: 347px;
  max-height: 480px;

  height: 100%;
`;
