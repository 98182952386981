import { useDeferredValue, useEffect } from 'react';

import { FormProvider, useFormContext, useForm } from 'react-hook-form';

import { pickByPaths } from '@tradener/lumen';

import { useQuoteOptions } from '../useQuoteOptions';

export interface ComplementFormProps {
  searchFor?: string;
  bbceProductTransfer?: string;
  bbceProductTransferId?: string;
  externalId?: string;
  notes?: string;
  giro?: string;
  swap?: boolean;
}

const withComplementForm =
  <T,>(Component: React.ComponentType<T>) =>
  (props: React.PropsWithChildren<T>) => {
    const options = useQuoteOptions();
    const defaultValues = useDeferredValue(pickByPaths(options, 'swap', 'notes', 'externalId', 'giro'));
    const { reset, watch, ...methods } = useForm<ComplementFormProps>({
      reValidateMode: 'onChange',
      mode: 'all',
      shouldUnregister: true,
      defaultValues,
    });

    useEffect(() => {
      const observer = watch((values, { name, type }) => {
        if (type && /change/.test(type) && name) {
          const current = values[name];
          const prev = defaultValues[name] ?? '';

          if (current === prev) reset({ [name]: prev });
        }
      });

      return () => {
        observer.unsubscribe();
      };
    }, [defaultValues, reset, watch]);

    return (
      <FormProvider {...{ ...methods, reset, watch }}>
        <Component {...props} />
      </FormProvider>
    );
  };

const useComplementForm = () => useFormContext<ComplementFormProps>();

export { withComplementForm };
export default useComplementForm;
