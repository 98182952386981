import useAuth from '~/hooks/useAuth';

import isAuthorized from '@DEPRECATED/utils/isAuthorized';
import PropTypes from 'prop-types';

function Can({ children, requires }) {
  const auth = useAuth();

  return isAuthorized({ auth, requires }) ? children : null;
}

Can.defaultProps = {
  requires: null,
};

Can.propTypes = {
  children: PropTypes.node.isRequired,
  requires: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default Can;
