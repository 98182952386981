import { gql } from '@apollo/client';

export interface ResponseProps {
  contractFlags: Array<string>;
}

const CONTRACT_FLAGS = gql`
  query contractFlags {
    contractFlags
  }
`;

export default CONTRACT_FLAGS;
