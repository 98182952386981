import { useCallback } from 'react';

import { Badge, Flex, Grid, GridItem, Text, Theme, Tooltip, useTheme } from '@tradener/lumen';
import { DateTime } from 'luxon';

import { useDetails } from '../..';

interface MessagesEventsProps {
  event: 'dropped' | 'bounce' | 'spamreport' | 'rendering_failur' | 'delivered' | 'open' | 'click';
}

function LeftContent() {
  const { sizes, colors } = useTheme<Theme>();
  const { quote } = useDetails();

  const getStatus = useCallback((message: Array<MessagesEventsProps>) => {
    switch (message?.[0]?.event ?? 'delivered') {
      case 'dropped':
      case 'bounce':
      case 'spamreport':
      case 'rendering_failur':
        return 'Devolvido';
      case 'open':
      case 'click':
        return 'Entregue';
      case 'delivered':
      default:
        return 'Enviado';
    }
  }, []);

  const getInfo = useCallback(
    (message: Array<MessagesEventsProps>) => {
      const sizeof = (data: MessagesEventsProps['event']) => message.filter(({ event }) => event === data)?.length ?? 0;
      let opened = 0;
      let clicked = 0;

      if (message.length) {
        if (getStatus(message) === 'Entregue') {
          opened = sizeof('open');
          clicked = sizeof('click');
        }
      }

      return { opened, clicked };
    },
    [getStatus],
  );

  const getBadgeStatusColor = useCallback((message?: string) => {
    switch (message) {
      case 'Devolvido':
        return 'yellow';
      case 'Entregue':
        return 'green';
      case 'Enviado':
        return 'blue';
      default:
        return 'yellow';
    }
  }, []);

  return (
    <Flex direction="column" align="flex-start" gap="3" width="33.75rem">
      <Text textStyle="subheading2-semibold">Emails</Text>
      <Grid
        width="full"
        height="fit-content"
        overflow="hidden"
        gridTemplateColumns={`${sizes['18']} 13rem auto auto auto`}
        sx={{
          '& .item': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            height: '10',
            whiteSpace: 'nowrap',
            padding: '4',
            borderColor: `${colors.gray[900]}29`,
          },
        }}
      >
        <GridItem className="item" textStyle="body2-medium" color="gray.500">
          Envio
        </GridItem>
        <GridItem className="item" textStyle="body2-medium" color="gray.500">
          Destinatário
        </GridItem>
        <GridItem className="item" textStyle="body2-medium" color="gray.500">
          Status
        </GridItem>
        <GridItem className="item" textStyle="body2-medium" color="gray.500">
          Aberto
        </GridItem>
        <GridItem className="item" textStyle="body2-medium" color="gray.500">
          Cliques
        </GridItem>
        {quote?.emailMessages?.map((email) => {
          const status = getStatus(email?.emailMessageEvents ?? []);
          const { opened, clicked } = getInfo(email?.emailMessageEvents ?? []);
          const badgeStatusColor = getBadgeStatusColor(status);

          return (
            <>
              <GridItem className="item" borderTop="1px solid" textStyle="body2-regular">
                {DateTime.fromISO(email?.createdAt ?? '').toFormat('dd/MM/yy') ?? '-'}
              </GridItem>
              <GridItem className="item" borderTop="1px solid" textStyle="body2-regular" width="full" overflow="hidden">
                <Tooltip bgColor="gray.900" color="gray.0" borderRadius="base" label={email?.recipient?.email ?? '-'} hasArrow>
                  <Text width="full" overflowX="clip" textOverflow="ellipsis" whiteSpace="nowrap">
                    {email?.recipient?.email ?? '-'}
                  </Text>
                </Tooltip>
              </GridItem>
              <GridItem className="item" borderTop="1px solid" textStyle="body2-regular">
                <Badge variant="solid" borderRadius="base" py="1px" px="1.5" borderWidth="0" colorScheme={badgeStatusColor}>
                  {status}
                </Badge>
              </GridItem>
              <GridItem className="item" borderTop="1px solid" textStyle="body2-regular">
                {opened ?? 0}
              </GridItem>
              <GridItem className="item" borderTop="1px solid" textStyle="body2-regular">
                {clicked ?? 0}
              </GridItem>
            </>
          );
        })}
      </Grid>
    </Flex>
  );
}

export default LeftContent;
