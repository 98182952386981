import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Title = styled.span`
  display: block;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 12px;

  margin-top: 24px;
  margin-bottom: 16px;

  @media screen and (min-width: 600px) {
    font-size: 14px;
    margin-bottom: 16px;
  }
`;
