import { useState, useMemo } from 'react';

import { MdLogout, MdBedtime } from 'react-icons/md';

import {
  Avatar,
  ButtonBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  Switch,
  useTheme,
  ButtonBaseProps,
} from '@material-ui/core';

import useAuth from '~/hooks/useAuth';
import useColorMode from '~/hooks/useColorMode';

import useStyles from './styles';

const Profile: React.FC<ButtonBaseProps> = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { circular, paper, list } = useStyles();
  const { palette } = useTheme();
  const { colorTernary, toggle, mode } = useColorMode();
  const { profile, signOut } = useAuth();

  const handleOpen = ({ currentTarget }: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(currentTarget);

  const handleClose = () => setAnchorEl(null);

  const styles = useMemo(() => {
    const props = {
      itemIcon: {
        minWidth: '28px',
      },
      icon: {
        color: palette.grey[colorTernary(400, 900)],
        width: '16px',
        height: '16px',
      },
    };

    return props as Record<keyof typeof props, React.CSSProperties>;
  }, [colorTernary, palette.grey]);

  return (
    <>
      <ButtonBase style={{ borderRadius: '100%' }} onClick={handleOpen}>
        <Avatar classes={{ circular }} src={profile?.imageUrl} />
      </ButtonBase>
      <Menu
        open={!!anchorEl}
        classes={{
          paper,
          list,
        }}
        onClose={handleClose}
        anchorEl={anchorEl}
        keepMounted
      >
        <List component="nav" aria-label="configuration">
          <ListItem onClick={toggle} style={{ height: '36px' }} button>
            <ListItemIcon style={styles.itemIcon}>
              <MdBedtime style={styles.icon} />
            </ListItemIcon>
            <ListItemText
              style={{ color: palette.grey[colorTernary(400, 900)] }}
              primaryTypographyProps={{
                variant: 'body2',
              }}
              primary="Tema escuro"
            />
            <ListItemSecondaryAction onClick={toggle}>
              <Switch color="primary" checked={mode === 'dark'} />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem onClick={signOut} style={{ height: '36px' }} button>
            <ListItemIcon style={styles.itemIcon}>
              <MdLogout style={styles.icon} />
            </ListItemIcon>
            <ListItemText
              style={{ color: palette.grey[colorTernary(400, 900)] }}
              primaryTypographyProps={{
                variant: 'body2',
              }}
              primary="Logout"
            />
          </ListItem>
        </List>
      </Menu>
    </>
  );
};

export default Profile;
