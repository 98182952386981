import { memo, useCallback, useRef } from 'react';

import {
  Input as LumenInput,
  InputGroup,
  InputRightElement,
  InputProps,
  IconButton,
  CloseIcon,
  SearchIcon,
  PopoverAnchor,
  Box,
} from '@tradener/lumen';
import { debounce } from 'lodash';

import { useOperationLimits } from '../../../hooks/useOperationLimits';

export type AutoSearchProps = InputProps;

const Input: React.ComponentType<AutoSearchProps> = (props) => {
  const { onSearch } = useOperationLimits();
  const inputSearch = useRef('');
  const inputRef = useRef<any>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    debounce(() => {
      onSearch(inputSearch.current);
    }, 500),
    [],
  );

  const handleClear = () => {
    if (inputRef.current) inputRef.current.value = '';
    inputSearch.current = '';
    onSearch('');
  };

  return (
    <PopoverAnchor>
      <Box>
        <InputGroup key="inputGroup" width="400px" height="full" textStyle="body2-regular">
          <LumenInput
            ref={inputRef}
            bgColor="gray.100"
            textStyle="body2-regular"
            variant="filled"
            colorScheme="grey"
            placeholder="Pesquisar por nome ou CNPJ"
            autoComplete="off"
            overflow="clip"
            _placeholder={{ color: 'gray.400' }}
            size="sm"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            {...props}
            onChange={(event) => {
              event.preventDefault();
              inputSearch.current = event.target.value;
              handleChange();
            }}
          />
          <InputRightElement height="full">
            <IconButton
              aria-label="unit-search-icon"
              variant="ghost"
              size="sm"
              colorScheme="blackAlpha"
              color="gray.400"
              _hover={{ bgColor: 'transparent' }}
            >
              {inputSearch.current.length > 0 ? (
                <CloseIcon
                  aria-label="close-icon"
                  boxSize="5"
                  onClick={() => {
                    handleClear();
                  }}
                />
              ) : (
                <SearchIcon aria-label="search-icon" boxSize="5" />
              )}
            </IconButton>
          </InputRightElement>
        </InputGroup>
      </Box>
    </PopoverAnchor>
  );
};

export default memo(Input);
