import { getColorByStatus } from '@DEPRECATED/utils';
import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.span`
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  justify-content: center;
  line-height: 1;
  width: 24px;
  height: 24px;

  border-radius: 50%;
  border-color: transparent;

  cursor: default;

  font-size: 20px;

  background-color: ${getColorByStatus};
  ${({ theme }) => (theme?.palette?.type === 'light') &&
    `color: #FFF` || `color: #222`};
`;
