import { useState, useEffect } from 'react';

import { numberFormat } from '@DEPRECATED/utils/format';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { Table } from './styles';

function TableOffers({ quotes, type }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    function parseQuotes() {
      setData(
        quotes.map((quote) => ({
          startDate: format(quote.startDate, 'dd/MM/yyyy'),
          endDate: format(quote.endDate, 'dd/MM/yyyy'),
          amount: numberFormat(Number(quote.amount), 2, 2),
          volume: {
            MWm: quote.volume.MWm.toLocaleString('pt-br', {
              minimumFractionDigits: 6,
            }),
            MWh: quote.volume.MWh.toLocaleString('pt-br', {
              minimumFractionDigits: 3,
            }),
          },
        })),
      );
    }

    parseQuotes();
  }, [quotes]);

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>DE</th>
            <th>ATÉ</th>
            <th>PREÇO (R$/MWh)</th>
            <th>Volume ({type})</th>
          </tr>
        </thead>
        <tbody>
          {data.map((quote) => (
            <tr key={quote.startDate}>
              <td>{quote.startDate}</td>
              <td>{quote.endDate}</td>
              <td>{quote.amount}</td>
              <td>{quote.volume[type]}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

TableOffers.propTypes = {
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.instanceOf(Date),
      endDate: PropTypes.instanceOf(Date),
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      MWm: PropTypes.number,
      MWh: PropTypes.number,
    }),
  ).isRequired,
  type: PropTypes.oneOf(['MWm', 'MWh']).isRequired,
};

export default TableOffers;
