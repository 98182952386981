import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { BoxProps } from '@material-ui/core';

import RENAME_SIMULATION, {
  ResponseProps as RenameSimulationResponseProps,
  PayloadProps as RenameSimulationPayloadProps,
} from '@graphql/mutation/renameSimulation';

import useEditable from '~/hooks/useEditable';
import useSimulation from '~/pages/Portfolio/useSimulation';
import useSimulationRules from '~/pages/Portfolio/useSimulationRules';

import useStyles from './styles';

const SimulationName: React.ComponentType<BoxProps> = (props) => {
  const { name, simulationId, setFilter } = useSimulation();
  const { isReadOnly } = useSimulationRules();
  const classes = useStyles();

  const { isEditing, value, getParentProps, getEditionProps } = useEditable(name, {
    isEditable: !isReadOnly,
    isRequired: true,
  });

  const [renameSimulation] = useMutation<RenameSimulationResponseProps, RenameSimulationPayloadProps>(RENAME_SIMULATION, {
    onCompleted: () => {
      toast.success('Nome da simulação atualizada.');
    },
    onError: ({ message }) => {
      toast.error(message);
    },
  });

  const handleRename = (newName: string) => {
    if (newName === '') {
      toast.error('A simulação deve ter um nome.');
    } else if (name !== newName) {
      renameSimulation({
        variables: { input: { simulationId: simulationId as string, name: newName as string } },
        onCompleted: () => setFilter('name', newName),
      });
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }} {...props} {...getParentProps()}>
      {!isReadOnly && isEditing ? (
        <div className={classes.focused} {...getEditionProps({ onSubmit: handleRename })}>
          {value}
        </div>
      ) : (
        <div className={`${classes.root} ${!isReadOnly ? '' : classes.disabled}`}>{value}</div>
      )}
    </div>
  );
};

export default SimulationName;
