import { Controller, useFormContext } from 'react-hook-form';

import { Flex, Divider, FormControl, FormLabel, Textarea, Input, Checkbox, Text } from '@tradener/lumen';

import { useOffer } from '../context';

function MoreOptions() {
  const { moreOptions } = useOffer();
  const { register, watch, control } = useFormContext();

  if (!moreOptions) return null;

  return (
    <>
      <Divider />
      <FormControl display="flex" flexDirection="column" gap="1.5">
        <FormLabel margin="0">Ref. Externa</FormLabel>
        <Input type="text" {...register('externalId')} />
      </FormControl>
      <FormControl display="flex" flexDirection="column" gap="1.5">
        <FormLabel margin="0">Observações</FormLabel>
        <Textarea resize="none" minWidth="full" maxWidth="full" minHeight="30" maxHeight="30" {...register('notes')} />
      </FormControl>
      <Flex alignItems="flex-start" gap="3">
        <Controller
          control={control}
          name="swap"
          render={({ field: { ref: _, onChange, ...field } }) => (
            <Checkbox marginTop="0.5" isChecked={watch('swap')} onChange={(event) => onChange(event.target.checked)} {...field} />
          )}
        />
        <Text textStyle="body2-regular">Swap</Text>
      </Flex>
    </>
  );
}

export default MoreOptions;
