import { createContext, memo, useContext } from 'react';

const fastProvider = <Props, Value>(useValue: (props: Props) => Value) => {
  const Context = createContext({} as Value);

  const useProvider = () => useContext(Context);

  const Provider: React.ComponentType<Props> = (props) => {
    const value = useValue(props);

    return <Context.Provider value={value} {...props} />;
  };

  const withProvider = <T extends Props>(Component: React.ComponentType<T>) =>
    memo((props: React.PropsWithChildren<T>) => (
      <Provider {...props}>
        <Component {...props} />
      </Provider>
    ));

  return [withProvider, useProvider] as [typeof withProvider, typeof useProvider];
};

export default fastProvider;
