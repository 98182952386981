import usePortfolio from '~/pages/Portfolio/usePortfolio';

import { TableSection, TablePeriod, TableSectionProps } from '../Table';
import { useSimulationTable } from '../withSimulationTable';

const SimulationSection: React.ComponentType<TableSectionProps> = ({ children, ...props }) => {
  const { expandedPeriods } = usePortfolio();
  const { simulationPeriods } = useSimulationTable();

  return (
    <TableSection
      gridTemplateColumns={`200px repeat(${simulationPeriods?.length}, 138px) max-content`}
      flexWrap="nowrap"
      position="sticky"
      zIndex="banner"
      top="0"
      {...props}
    >
      {children}
      {simulationPeriods?.map(({ period }) => (
        <TablePeriod key={period} dedupe={expandedPeriods.includes(period)}>
          {period}
        </TablePeriod>
      ))}
    </TableSection>
  );
};

export default SimulationSection;
