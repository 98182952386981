import { GridItem, GridItemProps } from '@tradener/lumen';

const OfferHeaderItem: React.ComponentType<GridItemProps> = (props) => (
  <GridItem
    display="flex"
    direction="column"
    align="flex-start"
    justify="center"
    height="14"
    whiteSpace="nowrap"
    padding="4"
    color="gray.500"
    textStyle="body2-medium"
    {...props}
  />
);

export default OfferHeaderItem;
