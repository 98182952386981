import { useState } from 'react';

import { ReactComponent as Clipboard } from '@DEPRECATED/assets/icons/clipboard.svg';
import { Input } from '@DEPRECATED/components/Form';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';

import { Container } from './styles';

function Contact(props) {
  const { id, checked, name, email, onChange, proposalUrl } = props;
  const [showTooltip, setShowTooltip] = useState(false);

  function copyToClipboard() {
    const url = proposalUrl;

    copy(url);
    setShowTooltip(true);
  }

  function handleTooltipClose() {
    setShowTooltip(false);
  }

  return (
    <Container>
      <Checkbox
        inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
        disableRipple
        disableFocusRipple
        onChange={() => onChange(id)}
        checked={checked}
        style={{
          top: 8,
        }}
      />
      <Input
        variant="standard"
        label="Nome"
        color="secondary"
        style={{
          marginRight: 24,
        }}
        fullWidth
        value={name}
        disabled
      />
      <Input
        variant="standard"
        label="E-mail"
        color="secondary"
        style={{
          marginRight: 16,
        }}
        fullWidth
        value={email}
        disabled
      />
      <Tooltip onClose={handleTooltipClose} open={showTooltip} leaveDelay={1000} title="Copiado!" arrow placement="bottom">
        <IconButton onClick={copyToClipboard}>
          <Clipboard />
        </IconButton>
      </Tooltip>
    </Container>
  );
}

Contact.propTypes = {
  id: PropTypes.string.isRequired,
  quoteId: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  email: PropTypes.string,
};

export default Contact;
