import { Controller, useFormContext } from 'react-hook-form';

import { FormControl, FormLabel, RadioSelector, SelectorItem, Skeleton } from '@tradener/lumen';

import { useOffer } from '../context';

function PickEnergyType() {
  const { bbceProductId, quoteLoading } = useOffer();
  const { control } = useFormContext();

  if (bbceProductId) return null;

  return (
    <FormControl data-testid="modal-energy-type" display="flex" flexDirection="column" gap="1.5">
      <FormLabel margin="0">Tipo de energia</FormLabel>
      <Skeleton isLoaded={!quoteLoading}>
        <Controller
          control={control}
          name="energyType"
          render={({ field: { ref: _, ...field } }) => (
            <RadioSelector {...field}>
              {['C', 'I0', 'I5', 'I8', 'I1', 'INE5'].map((energyType) => (
                <SelectorItem key={energyType} value={energyType}>
                  {energyType}
                </SelectorItem>
              ))}
            </RadioSelector>
          )}
        />
      </Skeleton>
    </FormControl>
  );
}

export default PickEnergyType;
