import { useMemo } from 'react';

import { getStatus } from '@DEPRECATED/utils';
import PropTypes from 'prop-types';

import { Container } from './styles';

function Stage(props) {
  const { status } = props;

  const label = useMemo(() => getStatus(status), [status]);

  return <Container {...props}>{label}</Container>;
}

Stage.propTypes = {
  status: PropTypes.oneOf(['denied', 'rejected', 'quoted', 'proposed', 'closed', 'opened']).isRequired,
};

export default Stage;
