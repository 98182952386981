import { useContext } from 'react';

import { InputLayout, DatePicker, DateTimePicker, Input, SelectionControl } from '@DEPRECATED/components/Form';
import VerticalScroll from '@DEPRECATED/components/VerticalScroll';
import ProposalsContext from '@DEPRECATED/context/Proposals/context';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { startOfMonth, formatISO, endOfMonth } from 'date-fns';
import keys from 'lodash/keys';
import Select from '@DEPRECATED/components/Form/Select';

import {
  Container,
  Content,
  CommonContent,
  Readjustment,
  ReadjustmentHeader,
  ReadjustmentContent,
  Extra,
  ExtraHeader,
  ExtraContent,
} from './styles';
import { Checkbox } from '@material-ui/core';

function Details() {
  const { data, mergeData, mergeDataAndMarkEditedFields } = useContext(ProposalsContext);

  const {
    modulation,
    dueDateType,
    dueDateValue,
    readjustmentIndex,
    baseDate,
    readjustmentMonth,
    expireDate,
    externalId,
    flexPositive,
    flexNegative,
    loss,
    sazoPercent,
    giro,
    retusdAmount,
    notes,
    startDate,
    product,
    swap,
    lockCharges,
    opportunity: { category },
  } = data;

  const isRetailer = [
    'TRD_RETAILER_GUARANTEED_GAIN',
    'TRD_RETAILER_SET_PRICE',
    'TRD_RETAILER_GUARANTEED_GAIN_FLEX_FREE',
    'TRD_RETAILER_SET_PRICE_FLEX_FREE',
  ].includes(category)

  const maxBaseDate = endOfMonth(
    new Date(Math.max(new Date(startDate).getTime(), new Date().getTime())),
  );

  function handleChangeModulation({ target }) {
    const { value } = target;

    const temp = {
      modulation: value,
      options: { edited: { modulation: true } },
    };

    const fields = ['flexPositive', 'flexNegative', 'loss', 'sazoPercent'];

    const notEdited = keys(data.options.edited).filter((key) => fields.includes(key) && !data.options.edited[key]);

    if (value === 'FLAT') {
      notEdited.forEach((field) => {
        temp[field] = '';
      });
    }
    mergeData(null, temp);
  }

  function handleChangeMerge(name) {
    return function event({ target }) {
      let { value } = target;

      if (target.type === 'number') value = +target.value;
      if (name === 'dueDateValue') {
        if (dueDateType === 'BUSINESS_DAY' && value > 20) return;
        if (dueDateType === 'FIXED' && value > 30) return;
      }
      mergeDataAndMarkEditedFields(name, value);
    };
  }

  function handleDateChangeMerge(name) {
    return function event(date) {
      const value = startOfMonth(date);

      mergeDataAndMarkEditedFields(name, value);
    };
  }

  function handleReadjustmentMonthChange() {
    return function event(date) {
      const value = startOfMonth(date);

      mergeDataAndMarkEditedFields('readjustmentMonth', value);
    };
  }

  function handleDateTimeChangeMerge(name) {
    return function event(date) {
      const value = formatISO(date);

      mergeDataAndMarkEditedFields(name, value);
    };
  }

  function handleReadjustmentIndex(event) {
    const { value } = event.target;

    if (value === '') {
      mergeData(null, {
        baseDate: null,
        readjustmentMonth: null,
        readjustmentIndex: '',
      });

      return;
    }

    mergeDataAndMarkEditedFields('readjustmentIndex', value);
  }

  function handleSwap() {
    mergeData(null, { swap: !swap });
  }

  function handleLockCharges() {
    mergeData(null, { lockCharges: !lockCharges });
  }

  const dueDateTypeFields = [
    { value: 'BUSINESS_DAY', label: 'U' },
    { value: 'FIXED', label: 'F' },
  ];

  return (
    <Container>
      <VerticalScroll>
        <Content>
          <CommonContent>
            <div>
              <InputLayout label="Modulação">
                <Select
                  value={modulation}
                  displayEmpty
                  onChange={handleChangeModulation}
                  options={[
                    { value: 'CARGA', label: 'Carga' },
                    { value: 'DECLARADA', label: 'Declarada' },
                    { value: 'FLAT', label: 'Flat' },
                    { value: 'GERACAO', label: 'Geração' },
                  ]}
                />
              </InputLayout>
              <InputLayout label="Flex +">
                <Input value={flexPositive} onChange={handleChangeMerge('flexPositive')} name="flexPositive" type="number" />
              </InputLayout>

              <InputLayout label="Perda %">
                <Input value={loss} onChange={handleChangeMerge('loss')} type="number" />
              </InputLayout>
              <InputLayout label="Pagto." className="payment">
                {dueDateTypeFields.map((option) => (
                  <SelectionControl
                    key={option.value}
                    name="dueDateType"
                    variant="small"
                    fullWidth
                    checked={option.value === dueDateType}
                    onChange={handleChangeMerge('dueDateType')}
                    {...option}
                  />
                ))}

                <Input
                  value={dueDateValue}
                  onChange={handleChangeMerge('dueDateValue')}
                  type="number"
                  fullWidth
                  inputProps={{
                    min: 0,
                  }}
                />
              </InputLayout>
              <InputLayout label="SWAP">
                <Checkbox onChange={handleSwap} checked={swap} />
              </InputLayout>
            </div>
            <div>
              <InputLayout label="Sazo. %">
                <Input value={sazoPercent} onChange={handleChangeMerge('sazoPercent')} type="number" />
              </InputLayout>

              <InputLayout label="Flex -">
                <Input value={flexNegative} onChange={handleChangeMerge('flexNegative')} type="number" />
              </InputLayout>

              <InputLayout label="Re-TUSD/T">
                <Input
                  value={retusdAmount}
                  disabled={product.shortName === 'C'}
                  onChange={handleChangeMerge('retusdAmount')}
                  type="number"
                />
              </InputLayout>
              <InputLayout label="Val." className="validity">
                <DateTimePicker value={expireDate} onChange={handleDateTimeChangeMerge('expireDate')} />
              </InputLayout>
            </div>
          </CommonContent>
          {/* Dados Reajuste */}
          <Readjustment>
            <ReadjustmentHeader>
              <Typography component="h1">Reajuste</Typography>
              <Divider />
            </ReadjustmentHeader>
            <ReadjustmentContent>
              <InputLayout label="Índice">
                <Select
                  displayEmpty
                  value={readjustmentIndex}
                  onChange={handleReadjustmentIndex}
                  options={[
                    { value: 'IPCA', label: 'IPCA' },
                    { value: 'IGPM', label: 'IGPM' },
                  ]}
                />
              </InputLayout>

              <InputLayout label="Data Base">
                <DatePicker
                  value={baseDate}
                  maxDate={maxBaseDate}
                  disabled={!readjustmentIndex}
                  onChange={handleDateChangeMerge('baseDate')}
                  format="MMM/yyyy"
                  views={['year', 'month']}
                />
              </InputLayout>

              <InputLayout label="Mês">
                <DatePicker
                  value={readjustmentMonth}
                  disabled={!readjustmentIndex}
                  views={['month']}
                  onChange={handleReadjustmentMonthChange()}
                  disableToolbar
                  format="MMMM"
                />
              </InputLayout>
            </ReadjustmentContent>
          </Readjustment>
        </Content>
        <Extra>
          <ExtraHeader>
            <Typography component="h1">Complemento</Typography>
            <Divider />
          </ExtraHeader>
          <ExtraContent>
            <InputLayout label="Giro">
              <Input value={giro} onChange={handleChangeMerge('giro')} />
            </InputLayout>

            <InputLayout label="Ref. Externa">
              <Input value={externalId} onChange={handleChangeMerge('externalId')} />
            </InputLayout>
            <div>
              <Typography style={{ marginBottom: '5px' }}>Observação</Typography>
              <Input value={notes} onChange={handleChangeMerge('notes')} multiline rows="4" fullWidth />
            </div>
          </ExtraContent>
        </Extra>
      </VerticalScroll>
    </Container>
  );
}

export default Details;
