import { useRef, useState } from 'react';

import { MdDelete, MdEdit, MdSave } from 'react-icons/md';

import PropTypes from 'prop-types';

import { Container, Header, Label, Input, Option, Body, Textarea, Divider, IconButton } from './styles';

import TextAreaTemplate from '@DEPRECATED/components/TextAreaTemplate';
import useQuoteInformation from '~/hooks/useQuoteInformation';

function ConditionItem({ item, readOnly, setEditingQuoteInformation }) {
  const { deleteItem, saveItem, getVariables } = useQuoteInformation();

  const variables = getVariables();

  const [edit, setEdit] = useState(item.template === '');
  const [name, setName] = useState(item.name);
  const [template, setTemplate] = useState(item.template);
  const [value, setValue] = useState(item.value);

  function handleSave() {
    const cleanedTemplate = template.replaceAll('[mention-editing]{{', '{{');

    saveItem(item.id, { name, value, template: cleanedTemplate });
    setEdit(false);
    setEditingQuoteInformation(false);
  }

  function handleEdit() {
    setEdit(true);
    setEditingQuoteInformation(true);
  }

  function handleDelete() {
    deleteItem(item.id);
  }

  return (
    <Container>
      <Header>
        <Label>
          <Input placeholder="Título" value={name} disabled={!edit} onChange={({ target }) => setName(target.value)} />
        </Label>
        {!readOnly && (
          <Option>
            <IconButton aria-label="delete" onClick={handleDelete}>
              <MdDelete fontSize="inherit" />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            {edit ? (
              <IconButton aria-label="save" onClick={handleSave}>
                <MdSave />
              </IconButton>
            ) : (
              <IconButton aria-label="edit" onClick={handleEdit}>
                <MdEdit />
              </IconButton>
            )}
          </Option>
        )}
      </Header>
      <Divider />
      <Body>
        <TextAreaTemplate
          disabled={!edit}
          template={template}
          variables={variables}
          setValue={setValue}
          setTemplate={setTemplate}
        />
      </Body>
    </Container>
  );
}

ConditionItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    template: PropTypes.string.isRequired,
    deleted: PropTypes.bool,
    added: PropTypes.bool,
  }).isRequired,
  readOnly: PropTypes.bool.isRequired,
  setEditingQuoteInformation: PropTypes.func,
};

export default ConditionItem;
