import { gql } from '@apollo/client';

import { PayloadProps as PortfolioPayloadProps } from '@graphql/query/portfolio';

export interface ResponseProps {
  downloadPortfolioData: {
    downloadUrl?: string;
  };
}

export type PayloadProps = PortfolioPayloadProps & {
  simulationId: string;
};

const DOWNLOAD_PORTFOLIO_DATA = gql`
  query downloadPortfolioData(
    $simulationId: ID!
    $startYear: String!
    $endYear: String
    $energyType: [String!]
    $periodType: QuotePeriod
    $priceType: [QuotePrice!]
    $expandedPeriods: [String!]
    $excludedContracts: [ID!]
    $tenantId: [ID!]
    $internalAccounts: [ID!]
    $usageFactorByContract: Boolean
    $usageFactorByEnergyType: Boolean
    $usageFactor: Boolean
    $giro: String
    $hasGiro: Boolean
    $contractFlags: [String!]
    $accountingPortfolio: ContractPortfolio
    $closeDateLte: ISO8601Date
    $closeDateGte: ISO8601Date
  ) {
    downloadPortfolioData(
      simulationId: $simulationId
      startYear: $startYear
      endYear: $endYear
      energyType: $energyType
      periodType: $periodType
      priceType: $priceType
      expandedPeriods: $expandedPeriods
      excludedContracts: $excludedContracts
      tenantId: $tenantId
      internalAccounts: $internalAccounts
      usageFactorByContract: $usageFactorByContract
      usageFactorByEnergyType: $usageFactorByEnergyType
      usageFactor: $usageFactor
      giro: $giro
      hasGiro: $hasGiro
      contractFlags: $contractFlags
      accountingPortfolio: $accountingPortfolio
      closeDateLte: $closeDateLte
      closeDateGte: $closeDateGte
    ) {
      downloadUrl
    }
  }
`;

export default DOWNLOAD_PORTFOLIO_DATA;
