import styled, { css } from 'styled-components';

import { Paper } from '@material-ui/core';

import Dialog from '@DEPRECATED/components/Dialog';

export const ScaleDialog = styled(Dialog)`
  && {
    ${({ theme }) => css`
      div > header {
        ${(theme?.palette?.type === 'light') &&
          `background: #c8c8c8` || `background: #373737`};
      }
      div > div > div {
        box-shadow: none;
      }
    `}
  }
`;


export const Container = styled(Paper)`
    height: 100%;

    > hr {
      margin: 0 16px;
    }
`;

export const List = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-flow: column wrap;

    padding: 32px 16px;
    height: calc(100% - 80px);
    overflow-y: auto;

    header {
      ${(theme?.palette?.type === 'light') &&
        `background: #EEE` || `background: #373737`};
    }
  `}
`;

export const Row = styled.li`
  display: flex;
  width: 100%;

  &:not(:first-child) {
    margin-top: 16px;
  }

  ul {
    list-style: none !important;
    list-style-type: none !important;
    margin: 0;
    padding-left: 12px;
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 80px;

  padding: 16px;

  button {
    width: 248px;
    height: 48px;
    border-radius: 2px;

    &:last-child {
      margin-left: 24px;
    }
  }
`;
