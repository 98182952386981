import { Box, BoxProps } from '@material-ui/core';

import useColorMode from '~/hooks/useColorMode';

const Logo: React.FC<BoxProps> = (props) => {
  const { colorTernary } = useColorMode();

  return (
    <Box {...props}>
      <img
        alt="Tradener Energy"
        src={colorTernary('/images/logo_dark.svg', '/images/logo_light.svg')}
        width="18px"
        height="34px"
      />
    </Box>
  );
};

export default Logo;
