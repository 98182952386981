import { useState } from 'react';

import { Flex, Text } from '@tradener/lumen';
import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Bar, LabelList, ComposedChart, Line, Legend } from 'recharts';

import CustomLegend from '../components/CustomLegend';
import CustomTooltip from '../components/CustomTooltip';
import TickLabelXAxis from '../components/TickLabelXAxis';
import { useTradingChart } from '../useTradingChart';

const COLORS = {
  gray100: 'var(--lumen-colors-gray-100)',
  gray500: 'var(--lumen-colors-gray-500)',
  gray600: 'var(--lumen-colors-gray-600)',
  gray900: 'var(--lumen-colors-gray-900)',
  orange100: 'var(--lumen-colors-orange-100)',
  orange500: 'var(--lumen-colors-orange-500)',
  orange700: 'var(--lumen-colors-orange-700)',
  blue100: 'var(--lumen-colors-blue-100)',
  blue500: 'var(--lumen-colors-blue-500)',
  blue700: 'var(--lumen-colors-blue-700)',
  purple500: 'var(--lumen-colors-purple-500)',
  purple700: 'var(--lumen-colors-purple-700)',
  aqua500: '#4EC5E8',
  aqua700: '#338299',
};

const DEFAULT_KEYS = ['saleMwm', 'purchaseMwm', 'balanceMwm', 'averageSalePrice', 'averagePurchasePrice', 'pldPrice'];

function EnergyBalance() {
  const { series } = useTradingChart();
  const [dataKeys, setDataKeys] = useState<string[]>(DEFAULT_KEYS);

  const onToggleDataKey = (dataKey: string) => {
    if (dataKeys.includes(dataKey)) setDataKeys(dataKeys.filter((key) => key !== dataKey));
    else setDataKeys([...dataKeys, dataKey]);
  };

  const hideByKey = (dataKey: string) => !dataKeys.includes(dataKey);

  const config = (dataKey: string) => {
    if (dataKey === 'purchaseMwm')
      return {
        iconColor: COLORS.blue100,
        iconType: 'square',
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
        translucid: hideByKey('purchaseMwm'),
        marginLeft: 'auto',
      };
    if (dataKey === 'saleMwm')
      return {
        iconColor: COLORS.orange100,
        iconType: 'square',
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
        translucid: hideByKey('saleMwm'),
      };
    if (dataKey === 'balanceMwm')
      return {
        iconColor: COLORS.aqua500,
        iconType: 'line',
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
        translucid: hideByKey('balanceMwm'),
        marginRight: 'auto',
      };
    if (dataKey === 'averageSalePrice')
      return {
        iconColor: COLORS.orange500,
        iconType: 'line',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        translucid: hideByKey('averageSalePrice'),
        marginLeft: 'auto',
      };
    if (dataKey === 'averagePurchasePrice')
      return {
        iconColor: COLORS.blue500,
        iconType: 'line',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        translucid: hideByKey('averagePurchasePrice'),
      };
    if (dataKey === 'pldPrice')
      return {
        iconColor: COLORS.purple500,
        iconType: 'line',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        translucid: hideByKey('pldPrice'),
        marginRight: 'auto',
      };
  };

  if (!series?.length) return null;

  return (
    <Flex
      data-testid="energy-balance"
      flexDirection="column"
      bgColor="gray.0"
      height="28.125rem"
      width="full"
      borderRadius="xl"
      padding="5"
      color={COLORS.gray900}
    >
      <Flex flexDirection="column" justifyContent="center" gap="0.5">
        <Text textStyle="subheading1-semibold">Exposição</Text>
      </Flex>
      <Flex
        direction="column"
        width="100%"
        height="100%"
        paddingBlock="1"
        sx={{
          '.recharts-text': { fill: COLORS.gray500 },
          '.recharts-cartesian-axis-line, .recharts-brush > rect': { stroke: 'none' },
          '.recharts-cartesian-axis-tick-line, line': { stroke: COLORS.gray100 },
        }}
        overflow="visible"
      >
        <ResponsiveContainer>
          <ComposedChart data={series} syncId="balanceId" margin={{ top: 35 }} stackOffset="sign">
            <CartesianGrid vertical={false} strokeWidth={1} />
            <XAxis dataKey="competence" interval={0} tickLine={false} tick={<TickLabelXAxis mode="YEAR" />} />
            <YAxis
              yAxisId="left"
              orientation="left"
              padding={{ top: 35 }}
              label={{ value: 'MWm', position: 'top' }}
              stroke={COLORS.orange100}
              tick={{ fill: COLORS.gray600, fontSize: '0.75rem' }}
              tickMargin={16}
              interval={0}
              tickLine={false}
              scale="linear"
              domain={([dataMin, dataMax]) => {
                const absMax = Math.max(Math.abs(dataMin), Math.abs(dataMax));

                return [-(Number(dataMin) < 0 ? absMax * 1.5 : 0), absMax * 1.5];
              }}
              tickFormatter={(value) =>
                value.formatCurrency('pt-BR', { notation: 'compact', minimumFractionDigits: 0, maximumFractionDigits: 1 })
              }
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              padding={{ top: 35 }}
              label={{ value: 'R$', position: 'top' }}
              stroke={COLORS.orange100}
              tick={{ fill: COLORS.gray600, fontSize: '0.75rem' }}
              domain={[0, 'dataMax']}
              tickMargin={16}
              interval={0}
              tickLine={false}
              scale="linear"
            />
            <Tooltip
              cursor={{ stroke: `${COLORS.gray900}14`, fill: `${COLORS.gray900}14`, radius: 2 }}
              content={<CustomTooltip mode="MONTH" config={config} />}
            />
            <Bar
              yAxisId="left"
              name="Compra"
              dataKey="purchaseMwm"
              stackId="balanceBars"
              fill={COLORS.blue100}
              radius={6}
              hide={hideByKey('purchaseMwm')}
            >
              <LabelList
                dataKey="purchaseMwm"
                position="top"
                fontSize=".625rem"
                formatter={(value) =>
                  value.formatCurrency('pt-BR', { notation: 'compact', minimumFractionDigits: 0, maximumFractionDigits: 1 })
                }
                style={{ fill: COLORS.blue700 }}
              />
            </Bar>
            <Bar
              yAxisId="left"
              name="Venda"
              dataKey="saleMwm"
              stackId="balanceBars"
              fill={COLORS.orange100}
              radius={6}
              hide={hideByKey('saleMwm')}
            >
              <LabelList
                dataKey="saleMwm"
                position="top"
                fontSize=".625rem"
                formatter={(value) =>
                  value.formatCurrency('pt-BR', { notation: 'compact', minimumFractionDigits: 0, maximumFractionDigits: 1 })
                }
                style={{ fill: COLORS.orange700 }}
              />
            </Bar>
            <Line
              yAxisId="left"
              type="monotone"
              name="Saldo"
              dataKey="balanceMwm"
              strokeWidth={2}
              stroke={COLORS.aqua500}
              color={COLORS.aqua500}
              dot={{ stroke: COLORS.aqua500, fill: COLORS.aqua500, strokeWidth: 0.15 }}
              activeDot={{ stroke: COLORS.aqua500, fill: COLORS.aqua500, strokeWidth: 0.15 }}
              hide={hideByKey('balanceMwm')}
            >
              <LabelList
                dataKey="balanceMwm"
                position="top"
                fontSize=".625rem"
                formatter={(value) => value.formatCurrency('pt-BR', { notation: 'compact' })}
                style={{ fill: COLORS.aqua700 }}
              />
            </Line>
            <Line
              yAxisId="right"
              type="monotone"
              name="Preço médio venda"
              dataKey="averageSalePrice"
              strokeWidth={2}
              stroke={COLORS.orange500}
              dot={{ stroke: COLORS.orange500, fill: COLORS.orange500, strokeWidth: 0.15 }}
              activeDot={{ stroke: COLORS.orange500, fill: COLORS.orange500, strokeWidth: 0.15 }}
              hide={hideByKey('averageSalePrice')}
            >
              <LabelList
                dataKey="averageSalePrice"
                position="top"
                fontSize=".625rem"
                formatter={(value) => value.formatCurrency('pt-BR', { notation: 'compact' })}
                style={{ fill: COLORS.orange700 }}
              />
            </Line>
            <Line
              yAxisId="right"
              type="monotone"
              name="Preço médio compra"
              dataKey="averagePurchasePrice"
              strokeWidth={2}
              stroke={COLORS.blue500}
              dot={{ stroke: COLORS.blue500, fill: COLORS.blue500, strokeWidth: 0.15 }}
              activeDot={{ stroke: COLORS.blue500, fill: COLORS.blue500, strokeWidth: 0.15 }}
              hide={hideByKey('averagePurchasePrice')}
            >
              <LabelList
                dataKey="averagePurchasePrice"
                position="top"
                fontSize=".625rem"
                formatter={(value) => value.formatCurrency('pt-BR', { notation: 'compact' })}
                style={{ fill: COLORS.blue700 }}
              />
            </Line>
            <Line
              yAxisId="right"
              type="monotone"
              name="PLD Previsto"
              dataKey="pldPrice"
              strokeWidth={2}
              stroke={COLORS.purple500}
              dot={{ stroke: COLORS.purple500, fill: COLORS.purple500, strokeWidth: 0.15 }}
              activeDot={{ stroke: COLORS.purple500, fill: COLORS.purple500, strokeWidth: 0.15 }}
              hide={hideByKey('pldPrice')}
            >
              <LabelList
                dataKey="pldPrice"
                position="top"
                fontSize=".625rem"
                formatter={(value) => value.formatCurrency('pt-BR', { notation: 'compact' })}
                style={{ fill: COLORS.purple700 }}
              />
            </Line>
            <Legend
              content={<CustomLegend config={config} />}
              onClick={({ dataKey }) => dataKey && onToggleDataKey(dataKey as string)}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Flex>
    </Flex>
  );
}

export default EnergyBalance;
