import styled from 'styled-components';

export const Button = styled.button`
  background-color: #8c8c8c;
  color: #fff;
  align-self: flex-end;
  position: absolute;
  top: 10px;
  right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 0;
  z-index: 1;
`;

export const Container = styled.div.attrs({
  id: 'apex-month-container',
})`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  flex-flow: column nowrap;
  ${({ theme }) => (theme?.palette?.type === 'light') &&
    `background-color: #FFF` || `background-color: #111`};
  min-height: 347px;
  max-height: 480px;

  height: 100%;
`;
