import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';

import DatePicker from '../DatePicker';

function RangeInput(props) {
  const { startDate, index, endDate, onChange, maxDate } = props;

  function handleChange(date) {
    onChange({ date, index });
  }

  return (
    <>
      <DatePicker
        label="Início"
        name="startDate"
        format="MMM/yy"
        views={['year', 'month']}
        value={startDate}
        onChange={handleChange}
        disabled
      />

      <Divider />
      <DatePicker
        label="Fim"
        name="endDate"
        format="MMM/yy"
        views={['year', 'month']}
        value={endDate}
        onChange={handleChange}
        minDate={startDate}
        maxDate={maxDate}
      />
    </>
  );
}

RangeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  maxDate: PropTypes.instanceOf(Date).isRequired,
  index: PropTypes.number.isRequired,
};

export default RangeInput;
