import { forwardRef } from 'react';

import { makeStyles } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

import { useStyles as OutlinedStyles } from '../Outlined';

const STYLES = {
  outlined: OutlinedStyles,
  standard: () => ({}),
  filled: () => ({}),
};

export const useLabelStyles = makeStyles({
  outlined: {
    zIndex: 1,
    transform: 'translate(14px, 10px) scale(1)',
    pointerEvents: 'none',
  },
});

const TextField = forwardRef((props, ref) => {
  const { variant, InputProps, InputLabelProps } = props;

  const useStylesVariant = STYLES[variant];

  const classesVariant = useStylesVariant();
  const classesLabel = useLabelStyles();

  return (
    <MuiTextField
      {...props}
      InputProps={{
        classes: classesVariant,
        ...InputProps,
      }}
      InputLabelProps={{
        classes: classesLabel,
        ...InputLabelProps,
      }}
      color="secondary"
      inputRef={ref}
    />
  );
});

TextField.defaultProps = {
  variant: 'outlined',
  InputProps: {},
  InputLabelProps: {},
};

TextField.propTypes = {
  variant: PropTypes.string,
  InputProps: PropTypes.shape({}),
  InputLabelProps: PropTypes.shape({}),
};

export default TextField;
