import { useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';

import { useProvider } from '../../provider';
import BalanceMonth from './BalanceMonth';
import BalanceYear from './BalanceYear';

import './tooltip.css';

export const GET_BALANCE_YEAR = gql`
  query portfolioSummary($period: Int, $internalAccounts: [String!]) {
    portfolioSummary(filter: { period: $period, internalAccounts: $internalAccounts }) {
      period
      balanceMwm
      purchase {
        averagePrice
        quantityMwm
      }
      sale {
        averagePrice
        quantityMwm
      }
    }
  }
`;

function Balance({ data, isAveragePrice, onChangePeriod, onSwitch }) {
  const { internalAccounts } = useProvider();
  const [isZoomOut, setIsZoomOut] = useState(false);
  const [getBalance, { loading, data: dataYear }] = useLazyQuery(GET_BALANCE_YEAR);

  const handleZoomIn = (date) => {
    onChangePeriod(date);
    setIsZoomOut((prevState) => !prevState);
  };

  const handleZoomOut = () => {
    getBalance({
      variables: {
        internalAccounts: internalAccounts,
      },
    });
    setIsZoomOut((prevState) => !prevState);
  };

  return (
    <>
      <BalanceYear isAveragePrice={isAveragePrice} hide={!isZoomOut} onZoomIn={handleZoomIn} data={dataYear} loading={loading} />
      <BalanceMonth data={data} isAveragePrice={isAveragePrice} onSwitch={onSwitch} onZoom={handleZoomOut} hide={isZoomOut} />
    </>
  );
}

Balance.defaultProps = {
  data: null,
  internalAccounts: undefined,
};

Balance.propTypes = {
  data: PropTypes.shape({}),
  isAveragePrice: PropTypes.bool.isRequired,
  onSwitch: PropTypes.func.isRequired,
  onChangePeriod: PropTypes.func.isRequired,
  internalAccounts: PropTypes.arrayOf(PropTypes.string),
};

export default Balance;
