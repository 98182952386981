/* eslint-disable react-hooks/exhaustive-deps */
import { useRef } from 'react';

import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Flex, Popover } from '@tradener/lumen';
import { DateTime } from 'luxon';

import useChannel from '~/hooks/useChannel';
import fastProvider from '~/utils/fastProvider';

import useTrading from '../../useTrading';
import SearchField from './SearchField';
import SearchOptions from './SearchOptions';

export interface FormProps {
  searchFor: string;
}

const dateNow = DateTime.now().setLocale('pt-br');
const [startList, endList] = [dateNow.minus({ year: 1 }).toISO(), dateNow.plus({ year: 1 }).toISO()];

const [withAutocomplete, useAutocomplete] = fastProvider(() => {
  const { id } = useParams() as { [key: string]: string };
  const location = useLocation();
  const isAnalytics = location.pathname?.includes('trading/list/analytics/');
  const navigate = useNavigate();
  const { filter, setFilter } = useTrading();
  const defaultValues = { searchFor: filter.productName ?? '', options: [] };
  const { setValue, reset, ...methods } = useForm<FormProps>({ defaultValues });
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const productName = filter.productName ?? '';
  const filtered = !!productName;

  const { data, sendMessage, subscribeChannel, unsubscribeChannel, clearChannel } = useChannel<string[]>(
    'BbceProductNamesChannel',
    { autoStart: false, showId: isAnalytics },
  );

  const getProducts = (productName: string) => {
    setValue('searchFor', productName);
    setFilter('productName', productName);
    setProductId(productName);
    clearChannel();
  };

  const setProductId = (productName: string) => {
    const productId = isAnalytics && data?.find((product) => product?.[0] === productName)?.[1];

    if (isAnalytics && productId && productId !== id) {
      navigate(`/trading/list/analytics/${productId}`);
    }
  };

  const onConnect = () => {
    subscribeChannel({
      start_date: filter.startDate ? filter.startDate : startList,
      end_date: filter.endDate ? filter.endDate : endList,
    });
  };

  const onClear = () => {
    reset({ searchFor: '' });
    clearChannel();
    setFilter('productName', '');
  };

  const onEnter: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.code === 'Enter') getProducts(event.currentTarget.value);
  };

  const onSelectOption: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    const productName = event.currentTarget.value;

    if (productName) getProducts(event.currentTarget.value);
  };

  return {
    anchorRef,
    data,
    filtered,
    productName,
    isAnalytics,
    sendMessage,
    subscribeChannel,
    unsubscribeChannel,
    setValue,
    setFilter,
    reset,
    onConnect,
    onEnter,
    onClear,
    onSelectOption,
    ...methods,
  };
});

function ListSearch() {
  const { anchorRef } = useAutocomplete();

  return (
    <Flex flexDirection="column" align="center" justify="center" width="74" height="8">
      <Popover isOpen initialFocusRef={anchorRef} gutter={4} placement="bottom-start" closeOnBlur closeOnEsc matchWidth isLazy>
        <SearchField />
        <SearchOptions />
      </Popover>
    </Flex>
  );
}

export { useAutocomplete };
export default withAutocomplete(ListSearch);
