import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    backgroundColor: palette.type === 'dark' ? '#111' : '#EEE',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    height: '40px',
    borderRadius: '4px',
    margin: 0,
    padding: '8px 10px 8px 16px',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: palette.type === 'dark' ? '#333' : '#DDD',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
      '&$disabled': {
        backgroundColor: 'transparent',
      },
    },
  },
  input: {
    flex: 1,
    margin: 0,
    padding: 0,
  },
  adornedEnd: {
    paddingRight: '10px',
  },
}));

export default useStyles;
