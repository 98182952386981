import { BoxProps } from '@material-ui/core';

import useColorMode from '~/hooks/useColorMode';

import { Container } from './styles';

const Widget: React.ComponentType<BoxProps> = ({ children, ...rest }) => {
  const { colorTernary } = useColorMode();

  return (
    <Container style={{ backgroundColor: colorTernary('#111', '#FFF') }} {...rest}>
      {children}
    </Container>
  );
};

export default Widget;
