import numeral from 'numeral';

numeral.register('locale', 'pt-BR', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'mil',
    million: 'mi',
    billion: 'bi',
    trillion: 'tri',
  },
});

numeral.locale('pt-BR');

export default numeral;
