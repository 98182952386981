import { Flex, Popover } from '@tradener/lumen';

import Input from './Input';

export const SearchField: React.FC = () => (
  <Flex flexDirection="column" align="center" justify="center" width="400px" height="8">
    <Popover isOpen gutter={4} placement="bottom-start" closeOnBlur closeOnEsc matchWidth isLazy>
      <Input />
    </Popover>
  </Flex>
);
