import { NavLink } from 'react-router-dom';

import { Flex, FlexProps } from '@tradener/lumen';

import useProductQuote from '../useProductQuote';

const OfferListItem: React.ComponentType<FlexProps> = ({ children, ...props }) => {
  const { id } = useProductQuote();

  return (
    <Flex
      as={NavLink}
      to={`/trading/list/${id}/show`}
      align="center"
      justify="space-between"
      height="14"
      padding="4"
      color="gray.900"
      whiteSpace="nowrap"
      textStyle="body2-regular"
      cursor="pointer"
      {...props}
    >
      {children}
    </Flex>
  );
};

export default OfferListItem;
