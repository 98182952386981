import { useMemo, useContext } from 'react';

import VerticalScroll from '@DEPRECATED/components/VerticalScroll';
import ProposalsContext from '@DEPRECATED/context/Proposals/context';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { SUBMARKET, PRICE_TYPE, ENERGY_TYPE, CATEGORY } from '../../keywords';
import TableOffers from './TableOffers';
import { Container, List, ListItem } from './styles';

export default function Resume() {
  const { data } = useContext(ProposalsContext);

  const flexibility = [
    'TRD_RETAILER_SET_PRICE_FLEX_FREE',
    'TRD_RETAILER_GUARANTEED_GAIN_FLEX_FREE'
  ].includes(data.opportunity.category) ? 'LIVRE' : `-${data.flexNegative}% A +${data.flexPositive}%`;

  const dueDateType = useMemo(() => {
    const formated = {
      BUSINESS_DAY: `${data.dueDateValue}º Dia útil`,
      FIXED: `Todo dia ${data.dueDateValue}`,
    };

    return formated[data.dueDateType] || null;
  }, [data.dueDateType, data.dueDateValue]);

  const expireDate = useMemo(() => {
    const parseDate = new Date(data.expireDate);

    return format(parseDate, "HH:mm 'DE' dd/MM/yyyy");
  }, [data.expireDate]);

  const finishDate = useMemo(() => {
    const parseDate = new Date(data.endDate);

    return format(parseDate, 'MMMyyyy', { locale: ptBR });
  }, [data.endDate]);

  const initialDate = useMemo(() => {
    const parseDate = new Date(data.startDate);

    return format(parseDate, 'MMMyyyy', { locale: ptBR });
  }, [data.startDate]);

  const periodProduct = useMemo(() => {
    if (initialDate === finishDate) return initialDate;

    return `${initialDate}/${finishDate}`;
  }, [finishDate, initialDate]);

  const productName = useMemo(
    () => `${data.submarket} ${data.product.shortName} ${periodProduct} ${PRICE_TYPE[data.priceType]}`,
    [data.priceType, data.product.shortName, data.submarket, periodProduct],
  );

  const retusdAmount = useMemo(
    () =>
      data.retusdAmount
        ? `${Number(data.retusdAmount).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}/ MWh`
        : '-',
    [data.retusdAmount],
  );

  const isRetailer = [
    'TRD_RETAILER_GUARANTEED_GAIN',
    'TRD_RETAILER_SET_PRICE',
    'TRD_RETAILER_GUARANTEED_GAIN_FLEX_FREE',
    'TRD_RETAILER_SET_PRICE_FLEX_FREE',
  ].includes(data.opportunity.category);

  return (
    <Container>
      <VerticalScroll>
        <List>
          <ListItem>
            <Typography align="left" component="strong">
              Vendedor
            </Typography>
            <Typography align="right" color="secondary" variant="body1">
              {data.opportunity.seller.name}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography align="left" component="strong">
              Comprador
            </Typography>
            <Typography align="right" color="secondary" variant="body1">
              {data.opportunity.buyer.name}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography align="left" component="strong">
              Produto
            </Typography>
            <Typography align="right" color="secondary" variant="body1">
              {productName}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography align="left" component="strong">
              Categoria
            </Typography>
            <Typography align="right" color="secondary" variant="body1">
              {CATEGORY[data.opportunity.category]}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography align="left" component="strong">
              Período de Contratação
            </Typography>
            <Typography align="right" color="secondary" variant="body1">
              {`${initialDate} À ${finishDate}`}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography align="left" component="strong">
              Tipo de Energia
            </Typography>
            <Typography align="right" color="secondary" variant="body1">
              {data.product.shortName} - {ENERGY_TYPE[data.product.shortName]}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography align="left" component="strong">
              Ponto de Entrega
            </Typography>
            <Typography align="right" color="secondary" variant="body1">
              {SUBMARKET[data.submarket]}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography align="left" component="strong">
              Modalidade de Preço
            </Typography>
            <Typography align="right" color="secondary" variant="body1">
              {PRICE_TYPE[data.priceType]}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography align="left" component="strong">
              Pagamento
            </Typography>
            <Typography align="right" color="secondary" variant="body1">
              {dueDateType}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography align="left" component="strong">
              Validade da Proposta
            </Typography>
            <Typography align="right" color="secondary" variant="body1">
              {expireDate}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography align="left" component="strong">
              Sazonalização
            </Typography>
            <Typography align="right" color="secondary" variant="body1">
              {data.sazoPercent}%
            </Typography>
          </ListItem>
          <ListItem>
            <Typography align="left" component="strong">
              Flexibilização Mensal
            </Typography>
            <Typography align="right" color="secondary" variant="body1">
              {flexibility}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography align="left" component="strong">
              Perdas de transmissão e/ou distribuição
            </Typography>
            <Typography align="right" color="secondary" variant="body1">
              {data.loss}%
            </Typography>
          </ListItem>
          <ListItem>
            <Typography align="left" component="strong">
              Modulação Horária
            </Typography>
            <Typography align="right" color="secondary" variant="body1">
              {data.modulation}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography align="left" component="strong">
              Ressarcimento TUSD/T
            </Typography>
            <Typography
              align="right"
              color="secondary"
              variant="body1"
              style={{
                textTransform: 'none',
              }}
            >
              {retusdAmount}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography align="left" component="strong">
              SWAP
            </Typography>
            <Typography align="right" color="secondary" variant="body1">
              {data.swap ? 'Sim' : 'Não'}
            </Typography>
          </ListItem>
          {isRetailer && (
            <ListItem>
              <Typography align="left" component="strong">
                Trava Encargos
              </Typography>
              <Typography align="right" color="secondary" variant="body1">
                {data.lockCharges ? 'Sim' : 'Não'}
              </Typography>
            </ListItem>
          )}
          <ListItem className="table">
            <Typography align="left" component="strong" style={{ display: 'block' }}>
              Oferta
            </Typography>
            <TableOffers quotes={data.quoteItems} type={data.volumeType} />
          </ListItem>
        </List>
      </VerticalScroll>
    </Container>
  );
}
