import { useCallback } from 'react';

import { Navigate } from 'react-router-dom';

import notificationListener from '~/connection/notification';
import useAuth from '~/hooks/useAuth';

export default function useAbstraction() {
  const auth = useAuth();

  const withSession = useCallback(
    <T,>(Component: React.ComponentType<T>) =>
      (props?: React.PropsWithChildren<T>) => {
        if (!auth.isUserIn) return <Navigate to="/" />;

        notificationListener();

        return <Component {...(props as T)} />;
      },
    [auth.isUserIn],
  );

  const withNoSession = useCallback(
    <T,>(Component: React.ComponentType<T>) =>
      (props?: React.PropsWithChildren<T>) => {
        if (auth.isUserIn) return <Navigate to="/book/list" />;

        return <Component {...(props as NonNullable<typeof props>)} />;
      },
    [auth.isUserIn],
  );

  return {
    withNoSession,
    withSession,
  };
}
