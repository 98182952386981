import { FetchResult, useMutation } from '@apollo/client';
import { useToast } from '@tradener/lumen';

import UPLOAD_OPERATION_LIMITS, { ResponseProps, PayloadProps } from '@graphql/mutation/operationLimits/upload';

interface UploadOperationLimitsProps {
  upload: (payload: { variables: PayloadProps }) => Promise<FetchResult<ResponseProps, Record<string, any>, Record<string, any>>>;
  loading: boolean;
}

const useUploadOperationLimits = () => {
  const toast = useToast();

  const [upload, { loading }] = useMutation<ResponseProps, PayloadProps>(UPLOAD_OPERATION_LIMITS, {
    onCompleted: () => {
      toast({ title: 'Sucesso', status: 'success', description: 'Upload realizado com sucesso!' });
    },
    onError: ({ message }) => {
      toast({ title: 'Erro', status: 'error', description: message || 'Erro ao realizar upload!' });
    },
  });

  return {
    upload,
    loading,
  } as UploadOperationLimitsProps;
};

export default useUploadOperationLimits;
