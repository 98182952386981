import { useMemo } from 'react';

import { MdFlashOn } from 'react-icons/md';

import { Input } from '@DEPRECATED/components/Form';
import InputAdornment from '@material-ui/core/InputAdornment';
import { format, isSameMonth } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import PropTypes from 'prop-types';

import { Container } from './styles';

const LOCALE = { locale: ptBR };

function PeriodItem(props) {
  const { startDate, endDate, volume, volumeType, id, index, onChange, disabled } = props;
  const { MWm, MWh } = volume;

  const parsedPeriod = useMemo(() => {
    if (isSameMonth(startDate, endDate)) {
      return `${format(startDate, 'MMM/yy', LOCALE)}`;
    }

    return `${format(startDate, 'MMM/yy', LOCALE)} à ${format(endDate, 'MMM/yy', LOCALE)}`;
  }, [startDate, endDate]);

  return (
    <Container disabled={disabled}>
      <header id="period-item_header">{parsedPeriod}</header>
      <section id="period-item_body">
        <Input
          label="MWm"
          name="MWm"
          placeholder="-"
          type="number"
          fullWidth
          value={MWm}
          disabled={volumeType !== 'MWm'}
          onChange={(e) => onChange(e, 'MWm', id, index)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ marginTop: 2 }}>
                <MdFlashOn size={16} />
              </InputAdornment>
            ),
          }}
        />

        <Input
          label="MWh"
          name="MWh"
          placeholder="-"
          type="number"
          fullWidth
          value={MWh}
          disabled={volumeType !== 'MWh'}
          onChange={(e) => onChange(e, 'MWh', id, index)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ marginTop: 2 }}>
                <MdFlashOn size={16} />
              </InputAdornment>
            ),
          }}
        />
      </section>
    </Container>
  );
}

PeriodItem.defaultProps = {
  disabled: false,
};

PeriodItem.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  volumeType: PropTypes.oneOf(['MWm', 'MWh']).isRequired,
  volume: PropTypes.shape({
    MWm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    MWh: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default PeriodItem;
