import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Table = styled.table`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 4px;
    border-spacing: 0;

    tr {
      height: 32px;
    }

    th {
      font-weight: 700;
      font-size: 14px;
    }

    background-color: ${({ theme }) => theme.palette.background.odd};

    th,
    td {

      text-align: center;
    }

    tbody {
      font-size: 14px;

  `}
`;
