import gql from 'graphql-tag';

import { BbceProductProps } from '@graphql/query/bbceProducts';
import { CreatedByProps, OpportunityProps, PriceTypes, Stages, Submarkets } from '@graphql/query/productOperations';

export type ContractDraftStatuses = 'DOCUMENTATION_REQUESTED' | 'DRAFT_IN_PROGRESS' | 'DRAFT_SENT' | 'DRAFT_CHANGES_REQUESTED';

export interface ProductProps {
  unit: string;
  shortName: string;
}

export interface QuoteItemProps {
  id: string;
  product: ProductProps;
  startDate: string;
  endDate: string;
  quantity: number;
  amount: number;
  requestedAmount: number;
  total: number;
}

export interface QuoteInformationProps {
  name: string;
  type?: string;
  template?: string;
  value: string;
  order: number;
}

export interface QuoteProps {
  id: string;
  amountTotal?: number;
  bbceProduct: Pick<BbceProductProps, 'id'>;
  accountName: string;
  opportunity: Partial<OpportunityProps>;
  stage: Stages;
  forwarded: boolean;
  offerCode: string;
  price: string;
  quantity: number;
  expireDate: string;
  startDate: string;
  endDate: string;
  submarket: Submarkets;
  priceType: PriceTypes;
  dueDateType: string;
  dueDateValue: number;
  retusdAmount: number;
  baseDate: string;
  readjustmentMonth: number;
  readjustmentIndex: string;
  modulation: string;
  flexNegative: number;
  flexPositive: number;
  loss: number;
  sazoPercent: number;
  proposalUrl?: string;
  notes?: string;
  quoteInformation: QuoteInformationProps[];
  quoteItems: QuoteItemProps[];
  contractDraftStatus: ContractDraftStatuses;
  createdBy?: CreatedByProps;
}

export interface ResponseProps {
  upsertQuote: QuoteProps;
}

const QUOTE = gql`
  fragment quote on Quote {
    id
    amountTotal
    accountName
    stage
    forwarded
    offerCode
    price
    quantity
    expireDate
    startDate
    endDate
    submarket
    priceType
    dueDateType
    dueDateValue
    retusdAmount
    baseDate
    readjustmentMonth
    readjustmentIndex
    modulation
    flexNegative
    flexPositive
    loss
    sazoPercent
    proposalUrl
    notes
    contractDraftStatus
    externalId
    giro
    swap
    updatedAt
    opportunity {
      id
      type
      channel
      tenant {
        id
        name
      }
      account {
        id
        name
      }
      buyer {
        id
        name
      }
      seller {
        id
        name
      }
      bbceProduct {
        id
        name
      }
      bbceProductTransfer {
        id
        code
        quantityMwm
        amountTotal
        bbceProductFrom {
          id
          name
        }
        bbceProductTo {
          id
          name
        }
      }
    }
    quoteInformation {
      name
      value
      type
      template
      order
    }
    quoteItems {
      id
      product {
        unit
        shortName
      }
      startDate
      endDate
      quantity
      amount
    }
    createdBy {
      ... on User {
        id
        name
        email
      }
      ... on ClientApp {
        id
      }
    }
  }
`;

const UPSERT_QUOTE = gql`
  ${QUOTE}

  subscription {
    upsertQuote {
      ...quote
    }
  }
`;

export { QUOTE };
export default UPSERT_QUOTE;
