import { useEffect, useRef } from 'react';

import { useField } from '@rocketseat/unform';
import PropTypes from 'prop-types';

import SelectionControl from '../SelectionControl';

function Choice({ name, options, multiple, ...rest }) {
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const ref = useRef([]);

  const nativeField = multiple ? 'checkbox' : 'radio';

  function checked(value) {
    if (!defaultValue) return false;
    if (typeof defaultValue === 'string') return [defaultValue].includes(value);

    return Array.from(defaultValue).includes(value);
  }

  function parseValue(choiceRef) {
    const values = choiceRef.filter((i) => i.checked).map((i) => i.value);

    return multiple ? values : values[0];
  }

  useEffect(() => {
    ref.current = ref.current.slice(0, options.length);
    registerField({
      name: fieldName,
      path: '',
      ref: ref.current,
      parseValue,
      clearValue: (choiceRef) => {
        choiceRef.forEach((i) => {
          i.checked = false;
        });
      },
    });
    // eslint-disable-next-line
  }, [fieldName]);

  return (
    <>
      {options.map(({ value, label }, idx) => {
        const checkboxId = `${fieldName}-${value}`;

        return (
          <SelectionControl
            key={checkboxId}
            ref={(el) => {
              ref.current[idx] = el;
            }}
            type={nativeField}
            id={checkboxId}
            name={fieldName}
            aria-label={checkboxId}
            value={value}
            defaultChecked={checked(value)}
            label={label}
            {...rest}
          />
        );
      })}
      {error && <span>{error}</span>}
    </>
  );
}

Choice.defaultProps = {
  multiple: false,
};

Choice.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }).isRequired,
  ).isRequired,
  multiple: PropTypes.bool,
};

export default Choice;
