import { useNavigate } from 'react-router-dom';

import { Flex, TextProps, Text, useToken } from '@tradener/lumen';

import { Permissions } from '~/context/ducks/auth';
import useAuth from '~/hooks/useAuth';
import DeployedCodeIcon from '~/theme/icons/DeployedCode';

import { useBbceProduct } from '..';

export interface ProductBadgeProps extends TextProps {
  readonly?: boolean;
}

const ProductBadge: React.ComponentType<ProductBadgeProps> = ({ readonly = false, ...props }) => {
  const [gray900] = useToken('colors', ['gray.900']);
  const { permissions } = useAuth();
  const navigate = useNavigate();
  const isAllowed = permissions.includes(Permissions.TRADING_ANALYTICS);
  const { id, isAnalytics } = useBbceProduct();
  const canNavigate = !isAnalytics && isAllowed && !readonly;

  const onNavigate = () => {
    if (!canNavigate) return;

    navigate(`analytics/${id}`);
  };

  return (
    <Flex
      width="fit-content"
      gap="1"
      paddingInlineEnd="1.5"
      paddingInlineStart="1"
      borderRadius="base"
      borderWidth="1px"
      alignItems="center"
      justifyContent="center"
      height="5"
      bgColor={`${gray900}0A`}
      borderColor={`${gray900}0A`}
      cursor={canNavigate ? 'pointer' : 'default'}
      onClick={onNavigate}
    >
      <DeployedCodeIcon color="gray.900" boxSize="3" />
      <Text overflowX="clip" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" {...props} />
    </Flex>
  );
};

export default ProductBadge;
