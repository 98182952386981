import { gql } from '@apollo/client';

export interface SimulationPldInputProps {
  period: string;
  submarket: string;
  price: number;
}

export interface UpdateSimulationPldsProps {
  simulationId: string;
  simulationPlds: SimulationPldInputProps[];
}

export interface MutationResponseProps {
  status: string;
}

export interface UpdateSimulationPldsPayloadProps {
  updateSimulationPlds: MutationResponseProps;
  errors: string[];
}

export interface PayloadProps {
  input: UpdateSimulationPldsProps;
}

export interface ResponseProps {
  updateSimulationPlds: UpdateSimulationPldsPayloadProps;
}

const UPDATE_SIMULATION_PLDS = gql`
  mutation updateSimulationPlds($input: UpdateSimulationPldsInput!) {
    updateSimulationPlds(input: $input) {
      updateSimulationPlds {
        status
      }
      errors
    }
  }
`;

export default UPDATE_SIMULATION_PLDS;
