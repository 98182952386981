import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    padding: 14px 16px;
    ${(theme?.palette?.type === 'light') && `background-color: #FFF` || `background-color: #000`};
    height: calc(100% - 48px);
    overflow-y: auto;
    font-size: 12px;

    table {
      width: 100%;
      border-spacing: unset;

      thead {
        ${(theme?.palette?.type === 'light') && `background-color: #c8c8c8` || `background-color: #373737`};
        ${(theme?.palette?.type === 'light') && `color: #111` || `color: #EEE`};
      }

      th,
      td {
        height: 36px;
        padding: 0 10px;
      }

      th {
        text-transform: uppercase;

        &:first-child {
          text-align: left;
        }
      }

      tbody {
        ${(theme?.palette?.type === 'light') && `color: #666` || `color: #999`};
        font-weight: 500;

        tr {
          td {
            text-align: left;
          }

          td:not(:first-child) {
            ${(theme?.palette?.type === 'light') && `border-left: 1px solid #DDD` || `border-left: 1px solid #373737`};
          }
        }

        tr:nth-child(even) {
          ${(theme?.palette?.type === 'light') && `background-color: #c8c8c8` || `background-color: #373737`};
        }
      }
    }
  `}
`;
