import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 6px;
  font-size: 16px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 22px;
  &:nth-child(3) {
    margin-bottom: 14px;
  }
`;

export const RowEnd = styled.div`
  display: flex;
  wrap: nowrap;
  grid-column-gap: 8px;
  align-items: center;
`;
