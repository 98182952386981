import { useMemo } from 'react';

import Elipse from '@DEPRECATED/components/Elipse';
import { numberFormat } from '@DEPRECATED/utils/format';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';

import { Container, TableBody, TableCell } from './styles';

function TableCellValue(props) {
  const { children } = props;

  const isNegative = useMemo(() => children < 0, [children]);
  const value = useMemo(() => numberFormat(children, 1, 1), [children]);

  return (
    <TableCell className={isNegative ? 'negative' : ''} {...props}>
      {value} MWm
    </TableCell>
  );
}

TableCellValue.propTypes = {
  children: PropTypes.number.isRequired,
};

function TableCellName(props) {
  const { children } = props;

  const color = useMemo(
    () => (['con', 'c', 'ine5', 'ine8'].includes(children.toLowerCase()) ? '#F9B850' : '#6A51F6'),
    [children],
  );

  const isNotEnergyType = useMemo(() => ['total', 'saldo'].includes(children.toLowerCase()), [children]);

  return (
    <TableCell {...props}>
      {!isNotEnergyType && <Elipse color={color} size={8} />}
      <span>{children}</span>
    </TableCell>
  );
}

TableCellName.propTypes = {
  children: PropTypes.string.isRequired,
};

function TooltipContent({ data }) {
  return (
    <Container>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {Object.keys(data).map((key) => (
              <TableRow key={key}>
                <TableCellName align="left" scope="row">
                  {key}
                </TableCellName>
                <TableCellValue align="right">{data[key]}</TableCellValue>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

TooltipContent.propTypes = {
  data: PropTypes.objectOf(PropTypes.number).isRequired,
};

export default TooltipContent;
