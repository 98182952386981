import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Text = styled.text`
  fill: ${({ children, theme }) => (children.match('-') ? '#ff0046' : '#AAA')};
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 700;
  line-height: 1.38;
`;
