import { makeStyles } from '@material-ui/core/styles';

export function OutlinedStyles() {
  return {
    root: {
      fontSize: 16,
      borderRadius: 2,
      minHeight: 40,
      '&:focus .MuiOutlinedInput-notchedOutline': {
        borderWidth: 2,
      },
    },
    input: {
      textAlign: 'center',
      padding: 7,
    },
    adornedEnd: {
      paddingRight: 5,
    },
  };
}

export const useStyles = makeStyles(OutlinedStyles);
