import { useReducer, useRef, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { toast } from 'react-toastify';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Box,
  Button as MuiButton,
  IconButton,
  Popover,
  Typography,
  Switch,
  Divider,
  InputLabel,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import { Settings, GetApp, Publish, Close, Description } from '@material-ui/icons';
import { Button, parseSafeFloat } from '@tradener/lumen';
import { nanoid } from 'nanoid';

import UPDATE_SIMULATION_USAGE_FACTORS, { ResponseProps, PayloadProps } from '@graphql/mutation/updateSimulationUsageFactors';
import DOWNLOAD_SIMULATION_CONTRACT_USAGE_FACTOR, {
  ResponseProps as DownloadResponseProps,
  PayloadProps as DownloadPayloadProps,
} from '@graphql/query/downloadSimulationContractUsageFactor';

import useColorMode from '~/hooks/useColorMode';
import usePortfolio from '~/pages/Portfolio/usePortfolio';
import client from '~/services/apollo';
import FilledInput from '~/theme/components/FilledInput';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '~/theme/components/Modal';

import useSimulation from '../../useSimulation';
import useSimulationRules from '../../useSimulationRules';
import { useStyles } from './styles';

export interface CoefficientsProps {
  conventionalUsageFactor: number;
  incentivizedUsageFactor: number;
}

const SimulationCoefficients: React.FC = () => {
  const classes = useStyles();
  const { colorTernary } = useColorMode();
  const [showModal, toggleShowModal] = useReducer((prev) => !prev, false);
  const { simulationId, conventionalUsageFactor, incentivizedUsageFactor } = useSimulation();
  const { isReadOnly } = useSimulationRules();
  const { usageFactorByContract, usageFactorByEnergyType, usageFactor, setFilter } = usePortfolio();
  const [file, setFile] = useState<File | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const handleClick = () => hiddenFileInput?.current?.click();
  const handleCancel = () => setFile(null);

  const { control, handleSubmit } = useForm<CoefficientsProps>();

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => setFile(target?.files?.[0] as File);

  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  const handleClosePopover = () => setAnchorEl(null);

  const dispatchSwitch = (name: 'usageFactorByContract' | 'usageFactorByEnergyType' | 'usageFactor') =>
    setFilter(name, switchConditional(name));

  const switchConditional = (name: string) => {
    if (name === 'usageFactor') {
      return !usageFactor;
    } else if (name === 'usageFactorByContract') {
      return !usageFactorByContract;
    } else if (name === 'usageFactorByEnergyType') {
      return !usageFactorByEnergyType;
    } else {
      return false;
    }
  };

  const changeUsageFactor = (name) => {
    dispatchSwitch(name);

    if (name === 'usageFactor' && usageFactor === false) {
      setFilter('usageFactorByContract', false);
      setFilter('usageFactorByEnergyType', false);
    } else if (name === 'usageFactorByContract' || name === 'usageFactorByEnergyType') {
      setFilter('usageFactor', false);
    }
  };

  const [downloadUsageFactor, { loading: downloadLoading }] = useLazyQuery<DownloadResponseProps, DownloadPayloadProps>(
    DOWNLOAD_SIMULATION_CONTRACT_USAGE_FACTOR,
    {
      variables: { simulationId: simulationId! },
      onCompleted: (data) => {
        window.open(data.downloadSimulationContractUsageFactor.downloadUrl, '_self');
      },
      onError: ({ message }) => toast.error(message),
    },
  );

  const [updateSimulationUsageFactors, { loading }] = useMutation<ResponseProps, PayloadProps>(UPDATE_SIMULATION_USAGE_FACTORS, {
    onCompleted: async ({ updateSimulationUsageFactors }: ResponseProps) => {
      if (updateSimulationUsageFactors?.updateSimulationUsageFactors) {
        toggleShowModal();
        toast.success('Coeficientes alterados com sucesso!');
        await client.refetchQueries({ include: ['simulation'] });
        setFile(null);
      } else toast.error(updateSimulationUsageFactors?.errors?.join('\n') ?? 'Erro ao alterar os coeficientes.');
    },
  });

  const onSubmit = ({ conventionalUsageFactor, incentivizedUsageFactor }: CoefficientsProps) => {
    updateSimulationUsageFactors({
      variables: {
        input: {
          simulationId,
          conventionalUsageFactor: conventionalUsageFactor / 100.0,
          incentivizedUsageFactor: incentivizedUsageFactor / 100.0,
          file: file as File,
        },
      },
    });
  };

  return (
    <>
      <Button
        variant="outline"
        size="sm"
        colorScheme="gray"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClickPopover}
      >
        Coeficiente
      </Button>
      <Popover
        id="dropdown-menu-popover"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClosePopover}
        style={{ borderRadius: '8px' }}
      >
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" p="8px" width="220px">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            padding="0 8px"
            width="204px"
            style={{ gap: '10px' }}
          >
            <Typography variant="body2">Coef. por contrato</Typography>
            <Switch
              color="primary"
              checked={usageFactorByContract}
              onClick={() => changeUsageFactor('usageFactorByContract')}
              className={classes.switch}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            padding="0 8px"
            width="204px"
            style={{ gap: '10px' }}
          >
            <Typography variant="body2">Coef. por fonte</Typography>
            <Switch
              color="primary"
              checked={usageFactorByEnergyType}
              onClick={() => changeUsageFactor('usageFactorByEnergyType')}
              className={classes.switch}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            padding="0 8px"
            width="204px"
            style={{ gap: '10px' }}
          >
            <Typography variant="body2">Coef. por período</Typography>
            <Switch
              color="primary"
              checked={usageFactor}
              onClick={() => changeUsageFactor('usageFactor')}
              className={classes.switch}
            />
          </Box>
          {!isReadOnly && (
            <>
              <Divider style={{ width: '100%' }} />
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                padding="10px 2px 0px"
                width="204px"
                style={{ gap: '10px' }}
              >
                <MuiButton
                  onClick={toggleShowModal}
                  startIcon={
                    <Settings
                      style={{ border: 'none', width: '22px', height: '22px', color: `${colorTernary('#EEE', '#111')}}}` }}
                    />
                  }
                  variant="outlined"
                  disableElevation
                  style={{ border: 'none', height: '40px', width: '204px', justifyContent: 'start', fontSize: '14px' }}
                >
                  Configurar
                </MuiButton>
              </Box>
            </>
          )}
        </Box>
      </Popover>

      {showModal && (
        <Modal isOpen={showModal} onClose={toggleShowModal}>
          <ModalHeader style={{ height: '64px', width: '520px' }}>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" width="472px">
              <Typography variant="h5">Configurar coeficientes</Typography>
              <IconButton color="inherit" onClick={toggleShowModal}>
                <Close style={{ width: '20px', height: '20px' }} />
              </IconButton>
            </Box>
          </ModalHeader>
          <ModalBody style={{ height: '67px', width: '520px' }}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              style={{ columnGap: '20px' }}
            >
              <Box display="flex" flexDirection="column" alignItems="center" style={{ rowGap: '6px' }}>
                <InputLabel
                  htmlFor="conventionalUsageFactor"
                  style={{
                    color: `${colorTernary('#d9d9d9', '#262626')}`,
                    fontSize: '14px',
                    alignSelf: 'flex-start',
                    fontWeight: 600,
                  }}
                >
                  Convencional
                </InputLabel>
                <Controller
                  name="conventionalUsageFactor"
                  control={control}
                  defaultValue={parseSafeFloat(String(conventionalUsageFactor))! * 100.0}
                  render={({ field: { onChange, ref, ...field } }) => (
                    <NumericFormat
                      customInput={FilledInput}
                      getInputRef={ref}
                      decimalScale={2}
                      allowNegative={false}
                      decimalSeparator=","
                      onValueChange={({ value }) => onChange(parseSafeFloat(value))}
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      style={{ width: '226px' }}
                      fixedDecimalScale
                      allowLeadingZeros
                      {...field}
                    />
                  )}
                />
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center" style={{ rowGap: '6px' }}>
                <InputLabel
                  htmlFor="incentivizedUsageFactor"
                  style={{
                    color: `${colorTernary('#d9d9d9', '#262626')}`,
                    fontSize: '14px',
                    alignSelf: 'flex-start',
                    fontWeight: 600,
                  }}
                >
                  Incentivada
                </InputLabel>
                <Controller
                  name="incentivizedUsageFactor"
                  control={control}
                  defaultValue={parseSafeFloat(String(incentivizedUsageFactor))! * 100.0}
                  render={({ field: { onChange, ref, ...field } }) => (
                    <NumericFormat
                      customInput={FilledInput}
                      getInputRef={ref}
                      decimalScale={2}
                      allowNegative={false}
                      decimalSeparator=","
                      onValueChange={({ value }) => onChange(parseSafeFloat(value))}
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      style={{ width: '226px' }}
                      fixedDecimalScale
                      allowLeadingZeros
                      {...field}
                    />
                  )}
                />
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter style={{ height: '88px', width: '520px', justifyContent: 'space-between' }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="520px">
              <Box display="flex" alignItems="center" justifyContent="flex-start" width="100%" style={{ columnGap: '12px' }}>
                <IconButton
                  className={classes.iconButton}
                  onClick={() => downloadUsageFactor()}
                  color="secondary"
                  disabled={downloadLoading}
                >
                  {!downloadLoading ? (
                    <GetApp style={{ color: `${colorTernary('#EEE', '#262626')}`, width: '20px', height: '20px' }} />
                  ) : (
                    <CircularProgress size={20} color="secondary" />
                  )}
                </IconButton>
                <>
                  {!file ? (
                    <MuiButton
                      startIcon={<Publish />}
                      variant="outlined"
                      style={{ color: `${colorTernary('#EEE', '#262626')}`, height: '40px' }}
                      onClick={handleClick}
                    >
                      Importar coef. de contratos
                    </MuiButton>
                  ) : (
                    <MuiButton
                      startIcon={<Description style={{ color: `${colorTernary('#EEE', '#868686')}` }} />}
                      endIcon={<Close style={{ color: `${colorTernary('#EEE', '#868686')}` }} fontSize="large" />}
                      variant="contained"
                      className={classes.buttonCoef}
                      onClick={handleCancel}
                    >
                      {file.name}
                    </MuiButton>
                  )}
                  <input
                    name="file"
                    type="file"
                    key={file?.name?.toString() ?? nanoid()}
                    ref={hiddenFileInput}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                </>
              </Box>
              <MuiButton
                variant="contained"
                color="primary"
                style={{ width: '82px', height: '40px' }}
                onClick={handleSubmit(onSubmit)}
                disabled={loading}
                disableElevation
              >
                {!loading ? 'Aplicar' : <CircularProgress size={22} color="secondary" />}
              </MuiButton>
            </Box>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default SimulationCoefficients;
