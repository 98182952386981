import { List } from '@material-ui/icons';

export const BALANCE_ROWS = [
  {
    children: ['COMPRA', <List key="compra" color="primary" />],
    strong: true,
    path: '_item.purchase',
  },
  {
    children: 'SE/CO',
    path: '_item.purchase.se',
  },
  {
    children: 'Sul',
    path: '_item.purchase.s',
  },
  {
    children: 'Nordeste',
    path: '_item.purchase.ne',
  },
  {
    children: 'Norte',
    path: '_item.purchase.n',
  },
  {
    children: ['VENDA', <List key="venda" color="primary" />],
    strong: true,
    path: '_item.sale',
  },
  {
    children: 'SE/CO',
    path: '_item.sale.se',
  },
  {
    children: 'Sul',
    path: '_item.sale.s',
  },
  {
    children: 'Nordeste',
    path: '_item.sale.ne',
  },
  {
    children: 'Norte',
    path: '_item.sale.n',
  },
  {
    children: 'SALDO',
    strong: true,
    path: '_item',
  },
];

export const DRE_ROWS = [
  {
    children: 'SE/CO',
    strong: true,
    path: '_item.se.balanceMwm',
  },
  {
    children: 'PLD',
    path: '_item.se.pld',
    decimalScale: 2,
  },
  {
    children: 'Sul',
    strong: true,
    path: '_item.s.balanceMwm',
  },
  {
    children: 'PLD',
    path: '_item.s.pld',
    decimalScale: 2,
  },
  {
    children: 'Nordeste',
    strong: true,
    path: '_item.ne.balanceMwm',
  },
  {
    children: 'PLD',
    path: '_item.ne.pld',
    decimalScale: 2,
  },
  {
    children: 'Norte',
    strong: true,
    path: '_item.n.balanceMwm',
  },
  {
    children: 'PLD',
    path: '_item.n.pld',
    decimalScale: 2,
  },
  {
    children: 'LIQUIDAÇÃO CCEE',
    strong: true,
    path: '_item.cceeAmount',
    decimalScale: 0,
  },
  {
    children: 'SALDO',
    strong: true,
    path: '_item.balanceAmount',
    decimalScale: 0,
  },
  {
    children: 'LASTRO ESPECIAL',
    path: '_item.ballastInc',
    decimalScale: 3,
    defaultValue: '',
  },
  {
    children: 'LASTRO NÃO ESPECIAL',
    path: '_item.ballastCon',
    decimalScale: 3,
    defaultValue: '',
  },
  {
    children: 'LASTRO GLOBAL',
    path: '_item.ballastGlobal',
    decimalScale: 3,
    defaultValue: '',
  },
];

export const translate = (key: string, value: boolean | string | string[]) => {
  let label = key;

  switch (key) {
    case 'periodType':
      label = 'Prazo';
      break;
    case 'priceType':
      label = 'Preço';
      break;
    case 'energyType':
      label = 'Tipo de energia';
      break;
    case 'operation':
      label = 'Operação';
      break;
    case 'usageFactorByContract':
      label = 'Coef. por contrato';
      break;
    case 'usageFactorByEnergyType':
      label = 'Coef. por fonte';
      break;
    case 'giro':
      label = 'Cód. giro';
      break;
    case 'hasGiro':
      label = 'Giro';
      break;
  }

  if (Array.isArray(value)) {
    value = value
      .map((current) => {
        switch (current) {
          case 'PERCENT_PLD':
            return '% PLD';
          default:
            return current;
        }
      })
      .join(', ');
  }

  if (key == 'hasGiro') {
    value = value ? 'Com' : 'Sem';
  }

  if (typeof value === 'boolean') {
    value = value ? 'Sim' : 'Não';
  }

  return `${label}: ${value}`;
};
