import { Flex } from '@tradener/lumen';

import PurchaseIcon from '~/theme/icons/Purchase';
import SaleIcon from '~/theme/icons/Sale';
import SwapHorizIcon from '~/theme/icons/SwapHoriz';

import { useBbceProduct } from '../../../..';
import useProductQuote from '../../useProductQuote';

function OperationFlag() {
  const { id } = useBbceProduct();
  const { opportunity } = useProductQuote();
  const isInternalTransfer = opportunity.bbceProduct?.id !== id;
  const isSaleOpportunity = opportunity?.type === 'SALE';

  if (isInternalTransfer) {
    return (
      <Flex width="10" height="10" align="center" justify="center" borderRadius="xl" bgColor="blue.50">
        <SwapHorizIcon boxSize="5" color="blue.500" />{' '}
      </Flex>
    );
  }

  return (
    <Flex
      width="10"
      height="10"
      align="center"
      justify="center"
      borderRadius="xl"
      bgColor={isSaleOpportunity ? 'orange.50' : 'blue.50'}
    >
      {isSaleOpportunity ? <SaleIcon boxSize="6" color="orange.500" /> : <PurchaseIcon boxSize="6" color="blue.500" />}
    </Flex>
  );
}

export default OperationFlag;
