import { useMemo, useState, useEffect, useCallback } from 'react';

import Chart from 'react-apexcharts';
import { MdZoomOut } from 'react-icons/md';

import { numberFormat } from '@DEPRECATED/utils/format';
import { addDays } from 'date-fns';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';

import { getSeries, getAnnotations, getOptions, getCategories } from './BalanceMonthHelper';
import { Button, Container } from './styles';

function BalanceMonth({ onSwitch, data, isAveragePrice, onZoom, hide }) {
  const [config, setConfig] = useState({});
  const [hasAnnotation, setHasAnnotation] = useState(false);

  const series = useMemo(() => getSeries(data, isAveragePrice), [data, isAveragePrice]);

  const handleAnnotations = useCallback(
    (date) => {
      setConfig((prevState) => ({
        ...prevState,
        annotations: {
          xaxis: !hasAnnotation ? getAnnotations(date) : [],
        },
      }));

      if (!hasAnnotation && !date) return;
      setHasAnnotation(!hasAnnotation);
    },
    [hasAnnotation],
  );

  useEffect(() => {
    setConfig(() => {
      const categories = getCategories(data);
      const max = addDays(new Date(categories.slice(-1).pop()), 12).getTime();
      const averagePriceColors = ['#2558F8', '#EA7117', '#56CCF2', '#27AE60', '#BB6BD9', '#F2C94C'];

      const properties = {
        xaxis: { categories, max },
        chart: {
          events: {
            click: (event) => {
              const { j } = event.target.attributes;

              handleAnnotations(properties.xaxis.categories[j?.value]);
              onSwitch(j?.value);
            },
          },
        },
      };

      if (isAveragePrice) {
        properties.stroke = {
          width: [3, 3, 1, 1, 1, 1],
          dashArray: [0, 0, 0, 0, 0, 0],
        };
        properties.colors = averagePriceColors;
        properties.markers = {
          size: [4, 4, 2, 2, 2, 2],
          colors: averagePriceColors,
          strokeWidth: 0,
        };
        properties.chart.stacked = false;
        properties.dataLabels = {
          style: {
            colors: ['#4671F9', '#E0804A'],
          },
          enabledOnSeries: [1, 1, 0, 0, 0, 0],
          formatter(val) {
            if (val) {
              return numberFormat(val, 0);
            }

            return 0;
          },
        };
        properties.yaxis = {
          tickAmount: 8,
          crosshairs: {
            show: false,
          },
          forceNiceScale: true,
          labels: {
            style: {
              colors: '#a8adba',
              fontWeight: 500,
              fontFamily: 'Roboto',
              fontSize: '14px',
            },
            formatter: (value) => Number(value).toFixed(0),
          },
          tooltip: {
            enabled: false,
          },
        };
      }

      return getOptions(properties);
    });
  }, [data, handleAnnotations, isAveragePrice, onSwitch]);

  const isLoading = useMemo(() => !data, [data]);

  return (
    <Container hide={hide}>
      <Button onClick={onZoom}>
        <MdZoomOut size={24} />
      </Button>
      {!isLoading && <Chart options={config} series={series} height="100%" />}
    </Container>
  );
}

BalanceMonth.fragments = {
  balance: gql`
    fragment BalanceFields on Month {
      period
      balanceMwm
      purchase {
        averagePrice
        quantityMwm
        inc {
          quantityMwm
          averagePrice
        }
        con {
          quantityMwm
          averagePrice
        }
      }
      sale {
        averagePrice
        quantityMwm
        inc {
          quantityMwm
          averagePrice
        }
        con {
          quantityMwm
          averagePrice
        }
      }
    }
  `,
};

BalanceMonth.defaultProps = {
  onSwitch: () => ({}),
  onZoom: () => ({}),
  data: null,
  isAveragePrice: false,
};

BalanceMonth.propTypes = {
  onSwitch: PropTypes.func,
  onZoom: PropTypes.func,
  data: PropTypes.shape({
    months: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  isAveragePrice: PropTypes.bool,
  hide: PropTypes.bool.isRequired,
};

export default BalanceMonth;
