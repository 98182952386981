import ptBR from '@DEPRECATED/utils/chart/locale/pt-br.json';
import { numberFormat } from '@DEPRECATED/utils/format';
import { ApexOptions } from 'apexcharts';
import { format, addMonths } from 'date-fns';
import { ptBR as locale } from 'date-fns/locale';
import merge from 'lodash/merge';

export const barStyle = {
  plotOptions: {
    bar: {
      columnWidth: '90%',
      dataLabels: {
        position: 'top',
      },
    },
  },
};

export const lineStyle = {
  stroke: {
    width: 2,
  },

  tooltip: {
    enabled: true,
    shared: true,
    y: {
      formatter: (value) => Intl.NumberFormat('pt-BR').format(value),
    }
  },

  dataLabels: {
    offsetY: -10,
    style: {
      colors: ['#4671F9', '#E0804A'],
    },
  },
};

export function getOptions(options: ApexOptions) {
  return merge<ApexOptions, ApexOptions>(
    {
      chart: {
        defaultLocale: 'pt-br',
        locales: [ptBR],
        toolbar: { show: false },
        zoom: { enabled: false },
      },
      markers: {
        size: 5,
        strokeColors: ['#2558F8', '#EB6100'],
        showNullDataPoints: false,
      },
      grid: { borderColor: '#232D44' },
      fill: { opacity: 1 },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: { position: 'bottom', offsetX: -10, offsetY: 0 },
          },
        },
      ],
      colors: ['#2558F8', '#EB6100'],
      xaxis: {
        type: 'category',
        crosshairs: { show: false, position: 'front' },
        labels: {
          style: {
            colors: '#AAA',
            fontWeight: 500,
            fontFamily: 'Roboto',
            fontSize: '13px',
          },
          formatter: (value) => {
            if (typeof value === 'string' && value.length > 4) {
              return format(addMonths(new Date(`${value}-01`), 1), 'MMMyy', {
                locale,
              }).toUpperCase();
            }

            return value;
          },
          hideOverlappingLabels: false,
        },
        axisBorder: { show: false },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#232323',
          height: 5,
          offsetX: 0,
          offsetY: 0,
        },
        tickPlacement: 'between',
      },
      yaxis: {
        min: (min) => min,
        max: (max) => max + max * 0.2,
        crosshairs: { show: false },
        forceNiceScale: true,
        labels: {
          style: {
            colors: '#AAA',
            fontWeight: 500,
            fontFamily: 'Roboto',
            fontSize: '14px',
          },
          minWidth: 5,
          formatter: (val) => Math.round(val).toString(),
        },
        tooltip: { enabled: false },
      },
      legend: {
        show: true,
        fontWeight: 500,
        fontFamily: 'Roboto',
        fontSize: '12px',
        labels: {
          colors: '#AAA',
        },
        markers: {
          width: 15,
          height: 2,
          radius: 0,
        },
        position: 'top',
        itemMargin: {
          horizontal: 10,
          vertical: 0,
        },
        formatter(seriesName) {
          return seriesName.toUpperCase();
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
      },
      tooltip: {
        enabled: false,
        theme: 'dark',
        followCursor: true,
        x: {
          show: true,
          format: 'MMMyy',
        },
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [0, 1],
        offsetY: -20,
        background: {
          enabled: false,
          dropShadow: {
            enabled: false,
          },
        },
        style: {
          fontSize: '12px',
          fontFamily: 'Roboto, Arial, sans-serif',
          fontWeight: 500,
          colors: ['#AAA'],
        },
        // eslint-disable-next-line consistent-return
        formatter(val: number, opts?: any): string | any {
          if (val) {
            return numberFormat(val, opts?.ctx?.opts?.chart?.type === 'line' ? 2 : 1);
          }
        },
      },
    },
    options,
  );
}
