/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { createContext, memo, useContext } from 'react';

import { useQuery } from '@apollo/client';

import BALANCE_RESULTS, { PayloadProps, ResponseProps, SeriesProps } from '@graphql/query/balanceResults';

import useTrading from '../useTrading';

export interface TradingChartProps {
  series?: SeriesProps[];
  lastMarketResultAccumulated?: number;
  lastPldResultAccumulated?: number;
  closedPositions?: number;
  loading: boolean;
}

const Context = createContext({} as unknown as TradingChartProps);

const useTradingChart = () => useContext(Context);

const Provider: React.ComponentType<any> = (props) => {
  const { filter } = useTrading();
  const { data, loading } = useQuery<ResponseProps, PayloadProps>(BALANCE_RESULTS, {
    skip: !filter?.internalAccountId || !filter?.startDate || !filter?.endDate,
    variables: {
      startDate: filter?.startDate!,
      endDate: filter?.endDate!,
      internalAccountId: filter?.internalAccountId!,
      userId: filter?.userId,
    },
  });

  const series = data?.balanceResults?.series;
  const lastMarketResultAccumulated = series?.[series.length - 1]?.marketResultAccumulated;
  const lastPldResultAccumulated = series?.[series.length - 1]?.pldResultAccumulated;
  const closedPositions = series?.reduce((acc, { finalResult }) => acc + (finalResult || 0), 0);

  const value = {
    series,
    lastMarketResultAccumulated,
    lastPldResultAccumulated,
    closedPositions,
    loading: !filter.startDate || !filter.endDate || loading,
  };

  return <Context.Provider value={value} {...props} />;
};

const withTradingChart = <T,>(Component: React.ComponentType<T>) =>
  memo((props: React.PropsWithChildren<T>) => (
    <Provider {...props}>
      <Component {...props} />
    </Provider>
  ));

export { withTradingChart, useTradingChart };
