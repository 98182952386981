import styled from 'styled-components';

export const Container = styled.section`
  padding: 48px;

  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.8px;

  background: ${({ color }) => color};
`;

export const ExtraHeader = styled.header`
  h1 {
    font-size: 16px;
    font-weight: 500;
  }
`;
export const ExtraContent = styled.div`
  display: flex;
  flex-flow: row wrap;

  > div {
    width: 100%;

    :not(:last-child) {
      max-width: 240px;
      .MuiOutlinedInput-root {
        width: 112px;
      }
    }

    :nth-child(1) {
      margin-right: 16px;
    }

    > span {
      display: block;
      margin-bottom: 12px;
      font-size: 16px;
    }
  }

  hr {
    margin-top: 8px;
    margin-bottom: 16px;
  }

  textarea {
    text-align: left !important;
  }

  button {
    margin-top: 24px;
    height: 48px;
    border-radius: 2px;
    max-width: 496px;
  }
`;
