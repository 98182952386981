import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    height: calc(100% - 48px);
    margin: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    ${(theme?.palette?.type === 'light') && `background-color: #EEE` || `background-color: #000`};

    p {
      text-transform: uppercase;
    }
  `}
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 10px;
  margin: 0;
  width: 100%;
  padding: 14px 16px;
  ${({ theme }) => css`
    ${(theme?.palette?.type === 'light') && `background-color: #FFF` || `background-color: #000`};
  `}
`;

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  padding: 0 16px;

  min-height: 40px;

  &.table {
    display: block;

    padding: unset;

    strong {
      padding-left: 16px;
    }
  }

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.palette.background.even};
  }

  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.palette.background.odd};
  }

  strong {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.69;
    letter-spacing: normal;
    text-align: left;
  }

  p {
    line-height: 1.38;
    font-weight: 500;
    letter-spacing: 0.8px;
    font-size: 16px;
  }
`;
