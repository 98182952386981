import { BoxProps, Th as ChakraTh, useTheme } from '@tradener/lumen';

export const Th: React.FC<BoxProps> = ({ children, ...props }) => {
  const color = useTheme().colors.gray[900];

  return (
    <ChakraTh
      px={3}
      py={2.5}
      textStyle="body2-regular"
      fontWeight={400}
      color="gray.500"
      textTransform="none"
      border="0px"
      borderBottom="solid 1px"
      borderColor={`${color}14`}
      {...props}
    >
      {children}
    </ChakraTh>
  );
};
