import { Consumer } from '@rails/actioncable';

import Connection from './connection';

class ActionCable extends Consumer {
  constructor(url: string) {
    super(url);
    this.connection = new Connection(this);
  }
}

export default ActionCable;
