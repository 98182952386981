import styled, { css } from 'styled-components';

export const Title = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 16px 24px;
    height: 68px;

    h4 {
      text-transform: uppercase;
    }

    button {
      padding: 0;
      margin-left: auto;
    }
  `}
`;

export const Content = styled.div`
  ${({theme}) => css`
    height: calc(100% - 68px);
    ${(theme?.palette?.type === 'light') && `background-color: #EEE` || `background-color: #232323`};
  `}
`;
