import JwtDecode from 'jwt-decode';

import store from '~/context';
import { actions, AuthenticationProps, getPermissions } from '~/context/ducks/auth';

import http from './http';
import { getStoredSession } from './sessionAlive';

export interface TokenProps {
  exp: number;
}

const isExpired = (token: string) => {
  if (/Bearer/i.test(token)) token = token.replace(/Bearer/i, '');

  const { exp } = JwtDecode<TokenProps>(token);
  const exceddingTime = 5 * (1000 * 60);

  return exp < (Date.now() + exceddingTime) / 1000;
};

const getCurrentSession = () => {
  const { token, refreshToken } = store.getState().auth;

  return { token, refreshToken };
};

const setSession = (auth: AuthenticationProps) => {
  const action = actions.setRefreshToken({
    token: auth.token,
    refreshToken: auth.refresh_token,
    ...getPermissions(auth.token),
  });

  store.dispatch(action as any);
};

const refreshToken = async (refreshToken: string | undefined) => {
  if (!refreshToken) throw Error('Invalid refresh token');

  const {
    data: { token, refresh_token },
  } = await http.post<AuthenticationProps>('/auth/token', {
    refresh_token: refreshToken,
  });

  return { token, refresh_token };
};

const shouldRefresh = () => {
  const { refreshToken, token } = store.getState().auth;

  if (!refreshToken) return false;

  return isExpired(token!);
};

const shouldConnect = () => {
  const token = store.getState().auth.refreshToken;

  const session = getStoredSession();

  return !!session || !!token;
};

const renewSession = async () => {
  try {
    const { auth } = store.getState();
    const data = await refreshToken(auth.refreshToken);

    return data;
  } catch (exception) {
    store.dispatch(actions.signOut());
  }
};

export { refreshToken, shouldRefresh, shouldConnect, setSession, isExpired, getCurrentSession };
export default renewSession;
