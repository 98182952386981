import { PropsWithChildren } from 'react';

import { Container, Title, Subtitle } from './styles';

type WidgetHeaderProps = {
  title: string;
  subtitle?: string;
};

const WidgetHeader: React.FC<PropsWithChildren<WidgetHeaderProps>> = (props) => {
  const { title, subtitle, children, ...rest } = props;

  return (
    <Container {...rest}>
      <Title>{title}</Title>
      {subtitle && <Subtitle style={{ color: '#7c7c7c' }}>{subtitle}</Subtitle>}
      {children}
    </Container>
  );
};

export default WidgetHeader;
