import { gql } from '@apollo/client';

import { SubmarketTypes } from './simulationContracts';

export interface SimulationPLDProps {
  id: string;
  competence: string;
  submarket: SubmarketTypes;
  type: string;
  price: number;
}

export interface PayloadProps {
  simulationId: string;
  startYear: string;
  endYear?: string;
}

export interface ResponseProps {
  predicted: SimulationPLDProps[];
  low: SimulationPLDProps[];
  high: SimulationPLDProps[];
}

const SIMULATION_PLDS = gql`
  query simulationPlds($simulationId: String!, $startYear: String!, $endYear: String) {
    predicted: simulationPlds(simulationId: $simulationId, startYear: $startYear, endYear: $endYear, type: PREDICTED) {
      id
      competence
      submarket
      type
      price
    }
    low: simulationPlds(simulationId: $simulationId, startYear: $startYear, endYear: $endYear, type: LOW) {
      id
      competence
      submarket
      type
      price
    }
    high: simulationPlds(simulationId: $simulationId, startYear: $startYear, endYear: $endYear, type: HIGH) {
      id
      competence
      submarket
      type
      price
    }
  }
`;

export default SIMULATION_PLDS;
