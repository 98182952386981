import { useRef, useState, Fragment } from 'react';

import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { IoMdTrash } from 'react-icons/io';
import { toast } from 'react-toastify';

import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Typography,
  Button,
  Radio,
  InputAdornment,
  IconButton,
  FormControlLabel,
  TableContainer,
  Switch,
  TableCell,
  Table,
  TableBody,
  TableHead,
  TableRow,
  CircularProgress,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { EmptyStateIcon } from '@tradener/lumen';
import { DateTime } from 'luxon';

import DELETE_SIMULATION, {
  ResponseProps as DeleteSimulationResponseProps,
  PayloadProps as DeleteSimulationPayloadProps,
} from '@graphql/mutation/deleteSimulation';
import SIMULATIONS, { PayloadProps as SimulationsPayloadProps, PayloadProps } from '@graphql/query/simulations';

import useAuth from '~/hooks/useAuth';
import useColorMode from '~/hooks/useColorMode';
import client from '~/services/apollo';
import FilledInput from '~/theme/components/FilledInput';
import Modal, { ModalHeader, ModalBody, ModalFooter, ModalProps } from '~/theme/components/Modal';

import useSimulation from '../../useSimulation';
import { SubheaderDirectives, useModalContext } from '../SimulationSubheader';
import { useStyles } from './styles';

export interface FormProps extends PayloadProps {
  deleteSimulationId?: string;
}

export interface SimulationListProps extends Omit<ModalProps, 'isOpen' | 'onClose'> {
  id: SubheaderDirectives;
}

const FULL_DATE_FORMAT = 'dd/LL/yyyy HH:mm';

const defaultValues: SimulationsPayloadProps = {
  search: '',
  shared: false,
};

const SimulationsList: React.ComponentType<SimulationListProps> = (props) => {
  const { id: userId } = useAuth();
  const classes = useStyles();
  const form = useRef<HTMLFormElement>();
  const { setFilter, simulationId } = useSimulation();
  const [currentSimulationId, setCurrentSimulationId] = useState(simulationId);
  const { colorTernary } = useColorMode();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const { onClose } = useModalContext();

  const { handleSubmit, setValue, getValues, control } = useForm<FormProps>({ defaultValues });

  const { data, loading, refetch } = useQuery(SIMULATIONS, {
    fetchPolicy: 'no-cache',
    variables: defaultValues,
  });

  const [deleteSimulation] = useMutation<DeleteSimulationResponseProps, DeleteSimulationPayloadProps>(DELETE_SIMULATION, {
    onError: async ({ message }) => {
      toast.error(message);
    },
  });

  const onSubmit: SubmitHandler<FormProps> = ({ shared, search }) => refetch({ shared, search });

  const handleLoader = () => {
    setFilter('simulationId', currentSimulationId);
    onClose();
  };

  const onCloseDeletion = () => {
    setValue('deleteSimulationId', undefined);
    setOpenDeleteModal(false);
  };

  const handleDeletion = async () => {
    const id = getValues('deleteSimulationId');

    if (id) {
      await deleteSimulation({
        variables: { input: { id } },
        onCompleted: async () => {
          setValue('deleteSimulationId', undefined);

          if (id === simulationId) {
            setFilter('simulationId', undefined);

            onClose();
          } else {
            await client.refetchQueries({ include: [SIMULATIONS] });
          }
        },
      });
    }
    setOpenDeleteModal(false);
  };

  return (
    <Modal maxWidth={false} onClose={onClose} style={{ width: '924px', margin: 'auto' }} isOpen fullWidth {...props}>
      <ModalHeader style={{ height: '90px' }}>
        <Typography variant="h5">Simulações</Typography>
        <Typography
          variant="h4"
          style={{
            fontSize: '16px',
            color: `${colorTernary('#797979', '#868686')}`,
            fontWeight: 'normal',
            letterSpacing: 'normal',
          }}
        >
          Selecione uma simulação abaixo para aplicar.
        </Typography>
      </ModalHeader>
      <ModalBody
        style={{
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          height: '100%',
        }}
      >
        <Box
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          marginBottom="20px"
          width="100"
          height="40px"
          style={{
            flexWrap: 'nowrap',
          }}
        >
          <Controller
            control={control}
            name="search"
            render={({ field: { onChange, ...field } }) => (
              <FilledInput
                placeholder="Pesquisar"
                style={{ minWidth: '565px', margin: '0px' }}
                onChange={onChange}
                endAdornment={
                  <InputAdornment position="end">
                    <Search style={{ width: '24px' }} />
                  </InputAdornment>
                }
                onKeyDown={({ code }) => {
                  if (code === 'Enter') form.current?.submit();
                }}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="shared"
            render={({ field: { onChange, ...field } }) => (
              <FormControlLabel
                labelPlacement="end"
                style={{ width: '223px', marginLeft: '15px', padding: '0px' }}
                control={
                  <Switch
                    color="primary"
                    checked={!field.value}
                    className={classes.switch}
                    onChange={(_, shared) => {
                      onChange(!shared);
                      refetch({ shared: !shared });
                    }}
                    {...field}
                  />
                }
                label={
                  <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>
                    Apenas minhas simulações
                  </Typography>
                }
              />
            )}
          />
        </Box>
        {data?.simulations.length ? (
          <TableContainer>
            <Table className={classes.table} stickyHeader aria-label="customized table">
              <TableHead>
                <TableRow style={{ width: '100%' }}>
                  <TableCell className={classes.tableCellHead} style={{ width: '56px' }} />
                  <TableCell className={classes.tableCellHead} align="left" style={{ width: '244px' }}>
                    Nome
                  </TableCell>
                  <TableCell className={classes.tableCellHead} align="left" style={{ width: '142px' }}>
                    Data de criação
                  </TableCell>
                  <TableCell className={classes.tableCellHead} align="left" style={{ width: '142px' }}>
                    Data de alteração
                  </TableCell>
                  <TableCell className={classes.tableCellHead} align="left" style={{ width: '227px' }}>
                    Responsável
                  </TableCell>
                  <TableCell className={classes.tableCellHead} />
                </TableRow>
              </TableHead>
              <TableBody>
                {data.simulations.map(({ id, name, createdAt, updatedAt, user }) => (
                  <Fragment key={id}>
                    <TableRow className={classes.tableRow} style={{ width: '100%' }} hover>
                      <TableCell className={classes.tableCellBody} component="th" scope="row">
                        <Radio
                          color="primary"
                          size="small"
                          name="simulationId"
                          checked={id === currentSimulationId}
                          onChange={() => setCurrentSimulationId(id)}
                        />
                      </TableCell>
                      <TableCell
                        className={classes.tableCellBody}
                        align="left"
                        style={{ fontSize: '14px', color: `${colorTernary('#d9d9d9', '#262626')}` }}
                      >
                        {name}
                      </TableCell>
                      <TableCell className={classes.tableCellBody} align="left">
                        {DateTime.fromISO(createdAt).toFormat(FULL_DATE_FORMAT)}
                      </TableCell>
                      <TableCell className={classes.tableCellBody} align="left">
                        {DateTime.fromISO(updatedAt).toFormat(FULL_DATE_FORMAT)}
                      </TableCell>
                      <TableCell
                        className={classes.tableCellBody}
                        align="left"
                        style={{ overflow: 'auto', fontSize: '14px', color: `${colorTernary('#d9d9d9', '#262626')}` }}
                      >
                        {user.name}
                      </TableCell>
                      <TableCell className={classes.tableCellBody} align="left">
                        {userId === user.id && (
                          <IconButton
                            size="medium"
                            onClick={() => {
                              setValue('deleteSimulationId', id);
                              setOpenDeleteModal(true);
                            }}
                          >
                            <IoMdTrash size={22} fill={colorTernary('#d9d9d9', '#262626')} />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
                {openDeleteModal && (
                  <Modal isOpen onClose={() => setValue('deleteSimulationId', undefined)}>
                    <ModalHeader>
                      <Typography variant="h5">Excluir</Typography>
                    </ModalHeader>
                    <ModalBody>
                      <Typography variant="body1" style={{ fontSize: '16px', color: `${colorTernary('#797979', '#868686')}` }}>
                        Deseja excluir a simulação? Essa ação não poderá ser desfeita.
                      </Typography>
                    </ModalBody>
                    <ModalFooter style={{ height: '88px' }}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        style={{ color: `${colorTernary('#d9d9d9', '#262626')}`, marginRight: '15px' }}
                        disabled={loading}
                        onClick={onCloseDeletion}
                        disableElevation
                      >
                        Cancelar
                      </Button>
                      <Button variant="contained" color="primary" disabled={loading} onClick={handleDeletion} disableElevation>
                        {!loading ? 'Sim' : <CircularProgress size="small" color="secondary" />}
                      </Button>
                    </ModalFooter>
                  </Modal>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start" margin="auto">
            <EmptyStateIcon fontSize={156} />
            <Typography
              variant="h6"
              style={{ marginTop: '20px', fontSize: '20px', color: `${colorTernary('#dcdcdc', '#232323')}` }}
            >
              Nenhuma simulação encontrada.
            </Typography>
            <Typography variant="body1" style={{ fontSize: '16px', color: `${colorTernary('#797979', '#868686')}` }}>
              Salve uma nova simulação para visualizar aqui.
            </Typography>
          </Box>
        )}
      </ModalBody>
      <ModalFooter style={{ height: '88px', paddingBlock: '0px', gridColumnGap: '8px' }}>
        <Button
          variant="outlined"
          color="secondary"
          style={{ color: `${colorTernary('#d9d9d9', '#262626')}` }}
          onClick={onClose}
          disableElevation
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!currentSimulationId}
          onClick={handleLoader}
          style={{ marginLeft: 0 }}
          disableElevation
        >
          Carregar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SimulationsList;
