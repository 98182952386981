import { gql } from '@apollo/client';

import { QUOTE_FRAGMENT, QuoteProps } from '../query/quote';

export interface RequestMoveProps {
  quote: QuoteProps;
}

export interface InputProps {
  quoteId: string;
}

export interface PayloadProps {
  input: InputProps;
}

export interface ResponseProps {
  quotesRequestMove: RequestMoveProps;
}

const QUOTES_REQUEST_MOVE = gql`
  ${QUOTE_FRAGMENT}

  mutation quotesRequestMove($input: RequestMoveInput!) {
    quotesRequestMove(input: $input) {
      quote {
        ...quote
      }
    }
  }
`;

export default QUOTES_REQUEST_MOVE;
