/* eslint-disable react/jsx-key */
import {
  Flex,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Skeleton as LumenSkeleton,
  useTheme,
  Button,
  AddIcon,
  ModalFooter,
} from '@tradener/lumen';

import { useOperationLimits } from '../../hooks/useOperationLimits';
import { Item } from './Item';
import { Skeleton } from './Skeleton';

export interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const EditLimitsModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const color = useTheme().colors.gray[900];
  const { dataEditDetails, loadingEditDetails, addLimitRange, onSave, loadingUpsert } = useOperationLimits();

  return (
    <ChakraModal isCentered isOpen={isOpen} size="4xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="xl">
        <ModalHeader py={6}>Editar limites</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDir="column" gap={5} w="full">
            <Flex flexDir="column" gap={1}>
              <LumenSkeleton borderRadius={'md'} isLoaded={!loadingEditDetails} w={loadingEditDetails ? '100' : 'auto'}>
                <Text textStyle="body1-medium" textOverflow="ellipsis" overflow="hidden" whiteSpace="no-wrap" noOfLines={3}>
                  {dataEditDetails?.accountName ?? '-'}
                </Text>
              </LumenSkeleton>
              <LumenSkeleton borderRadius={'md'} isLoaded={!loadingEditDetails} w={loadingEditDetails ? '60' : 'auto'}>
                <Text textStyle="body2-regular" color="gray.500">
                  {dataEditDetails?.taxId?.formatTaxId() ?? '-'}
                </Text>
              </LumenSkeleton>
            </Flex>
            <Flex flexDir="column" gap={3} w="full">
              {loadingEditDetails ? (
                <Skeleton />
              ) : (
                dataEditDetails?.series?.map((item, index) => (
                  <Item
                    key={`${index}${JSON.stringify(item)}`}
                    index={index}
                    purchaseLimit={item.purchaseLimit}
                    saleLimit={item.saleLimit}
                    startRange={item.startRange}
                    endRange={item.endRange}
                  />
                ))
              )}
              <Button
                variant="outline"
                colorScheme="gray"
                borderColor={`${color}14`}
                leftIcon={<AddIcon fontSize={24} />}
                onClick={() => addLimitRange()}
              >
                Adicionar limite
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="gray" variant="outline" borderColor={`${color}14`} onClick={onClose}>
            Cancelar
          </Button>
          <Button colorScheme="orange" ml={3} onClick={onSave} isLoading={loadingUpsert}>
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};
