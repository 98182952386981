import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  iconButton: {
    width: '40px',
    height: '40px',
    border: `1px solid ${theme.palette.type === 'dark' ? '#5c5c5c' : '#b3b3b3'}`,
    borderRadius: '4px',
    '&:disabled': {
      borderColor: `${theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)'}`,
    },
  },
  buttonCoef: {
    height: '40px',
    width: 'fit-content',
    minWidth: '300px',
    borderRadius: '4px',
    justifyContent: 'space-between',
    boxShadow: 'none',
    background: `${theme.palette.type === 'dark' ? '#111' : '#EEE'}`,
    color: `${theme.palette.type === 'dark' ? '#EEE' : '#262626'}`,
    '&:hover': {
      background: `${theme.palette.type === 'dark' ? '#333' : '#DDD'}`,
      boxShadow: 'none',
    },
  },
  switch: {
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'tranpxslateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        left: 12,
      },
      '&:after': {
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
        },
      },
    },
  },
}));
