import { forwardRef } from 'react';

import { useTheme } from '@material-ui/core';

import useColorMode from '~/hooks/useColorMode';
import { grey } from '~/theme';

const TableCellEdition = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ style, ...props }, ref) => {
  const { colorTernary } = useColorMode();
  const { zIndex } = useTheme();

  return (
    <div
      ref={ref}
      style={{
        position: 'absolute',
        left: '0px',
        width: '100%',
        height: '100%',
        backgroundColor: colorTernary(grey[600], 'white'),
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
        borderRadius: '4px',
        padding: 'inherit',
        transform: 'scale(1.1)',
        whiteSpace: 'nowrap',
        zIndex: zIndex.tooltip,
        ...style,
      }}
      {...props}
    />
  );
});

export default TableCellEdition;
