import {
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MoreVertIcon,
  MenuList,
  theme,
  Button,
  ThumbUpIcon,
  ThumbDownIcon,
  IconButton,
} from '@tradener/lumen';

import ComplementDialog from '~/pages/Trading/TradingList/QuoteOptions/ComplementDialog';
import ConfirmDialog from '~/pages/Trading/TradingList/QuoteOptions/ConfirmDialog';
import ActionCancel from '~/pages/Trading/TradingList/QuoteOptions/OptionActions/ActionCancel';
import ActionComplement from '~/pages/Trading/TradingList/QuoteOptions/OptionActions/ActionComplement';
import ActionDownloadProposal from '~/pages/Trading/TradingList/QuoteOptions/OptionActions/ActionDownloadProposal';
import ActionDuplicate from '~/pages/Trading/TradingList/QuoteOptions/OptionActions/ActionDuplicate';
import ActionEdit from '~/pages/Trading/TradingList/QuoteOptions/OptionActions/ActionEdit';
import ActionEmitProposal from '~/pages/Trading/TradingList/QuoteOptions/OptionActions/ActionEmitProposal';
import ActionMoveBook from '~/pages/Trading/TradingList/QuoteOptions/OptionActions/ActionMoveBook';
import ActionRenegotiate from '~/pages/Trading/TradingList/QuoteOptions/OptionActions/ActionRenegotiate';
import ActionSendProposal from '~/pages/Trading/TradingList/QuoteOptions/OptionActions/ActionSendProposal';
import useChangeQuoteStage from '~/pages/Trading/TradingList/QuoteOptions/useChangeQuoteStage';
import { useQuoteOptions } from '~/pages/Trading/TradingList/QuoteOptions/useQuoteOptions';

function MenuDetails() {
  const { onOpen, getStageAccess, updatedAt } = useQuoteOptions();
  const changeQuoteStage = useChangeQuoteStage();

  const openAproveDialog = () => onOpen('CLOSE', changeQuoteStage);

  const openRejectDialog = () => onOpen('REJECT', changeQuoteStage);

  return (
    <Flex align="center" justify="flex-end" gap="2" key={updatedAt}>
      {getStageAccess(['PROPOSED']) && (
        <Button
          aria-label="aprovar"
          variant="outline"
          colorScheme="gray"
          height="40px"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          color="gray.900"
          leftIcon={<ThumbUpIcon boxSize="5" />}
          onClick={openAproveDialog}
        >
          Aprovar
        </Button>
      )}
      {getStageAccess(['OPENED', 'PROPOSED']) && (
        <Button
          aria-label="rejeitar"
          variant="outline"
          colorScheme="gray"
          height="40px"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          color="gray.900"
          leftIcon={<ThumbDownIcon boxSize="5" />}
          onClick={openRejectDialog}
        >
          Rejeitar
        </Button>
      )}
      <Menu colorScheme="gray" autoSelect={false} lazyBehavior="unmount" isLazy>
        <MenuButton
          as={IconButton}
          data-testid="options"
          aria-label="mais"
          variant="unstyled"
          colorScheme="gray"
          icon={<MoreVertIcon boxSize="6" />}
        />
        <MenuList
          bgColor="gray.0"
          color="gray.900"
          width="43.5"
          padding="2"
          borderRadius="lg"
          boxShadow="md"
          zIndex={theme.zIndices.popover + 1}
        >
          <ActionEmitProposal />
          <ActionRenegotiate />
          <ActionSendProposal />
          {getStageAccess(['OPENED', 'PROPOSED']) && <MenuDivider />}
          <ActionEdit />
          <ActionDuplicate />
          <ActionComplement />
          <ActionDownloadProposal />
          <ActionMoveBook />
          {getStageAccess(['CLOSED', 'PROPOSED', 'OPENED', 'REJECTED', 'DENIED']) && <MenuDivider />}
          <ActionCancel />
        </MenuList>
      </Menu>
      <ConfirmDialog />
      <ComplementDialog />
    </Flex>
  );
}

export default MenuDetails;
