import { Card, Flex, Text } from '@tradener/lumen';

import { useDetails } from '../../..';

function DetailsCard() {
  const { quote } = useDetails();

  return (
    <Card width="80" height="fit-content" bgColor="gray.50" padding="6" gap="8">
      <Flex width="full" direction="column" align="flex-start" gap="4">
        <Text textStyle="subheading2-semibold">Detalhes</Text>
        <Flex width="full" align="center" justify="space-between">
          <Text textStyle="body2-regular" color="gray.500">
            Montante
          </Text>
          <Text textStyle="body2-regular">{quote?.quantity} MWm</Text>
        </Flex>
        <Flex width="full" align="center" justify="space-between">
          <Text textStyle="body2-regular" color="gray.500">
            Preço cliente
          </Text>
          <Text textStyle="body2-regular">
            {quote?.requestedAmount?.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
            }) ?? '-'}
          </Text>
        </Flex>
        <Flex width="full" align="center" justify="space-between">
          <Text textStyle="body2-regular" color="gray.500">
            Preço final
          </Text>
          <Text textStyle="body2-regular">
            {quote?.price?.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
            }) ?? '-'}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}

export default DetailsCard;
