import { useContext } from 'react';

import ProposalsContext from '@DEPRECATED/context/Proposals/context';

import { useQuery } from '@apollo/client';

import { DateTime } from 'luxon';

import { injectDefaultValues } from '@DEPRECATED/context/Proposals/actions';

import QUOTE_CONFIGURATION_DEFAULT from '@graphql/query/quoteDefaultConfigurations';

function parseDate(date) {
  return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
}

function useConfiguration() {
  const { data, mergeData } = useContext(ProposalsContext);
  const counterPart = data?.opportunity?.type === 'SALE' ? data?.opportunity?.buyer : data?.opportunity?.seller;

  const { data: response, loading } = useQuery(QUOTE_CONFIGURATION_DEFAULT, {
    skip: !data?.startDate || !data?.endDate || !data?.periodType || !data?.opportunity?.type || data?.options.filled,
    fetchPolicy: 'no-cache',
    variables: {
      filter: {
        accountId: counterPart?.id,
        operation: data?.opportunity?.type,
        periodType: data?.periodType,
        priceType: data?.priceType,
        startDate: parseDate(data?.startDate),
        endDate: parseDate(data?.endDate),
        energyType: data?.product?.shortName,
        submarket: data?.submarket,
        category: data?.opportunity?.category,
      },
    },
    onCompleted: ({ quoteConfigurationDefault }) => {
      mergeData(null, injectDefaultValues(data, quoteConfigurationDefault));
    },
  });

  return { loading, data: response?.quoteConfigurationDefault };
}

export default useConfiguration;
