import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import { FormControl, FormLabel, InputGroup, Input, InputLeftElement, Text, Skeleton, parseSafeFloat } from '@tradener/lumen';

import { useOffer } from '../context';

function AmountInput() {
  const { control } = useFormContext();
  const { quoteLoading } = useOffer();

  return (
    <FormControl data-testid="modal-amount" display="flex" flexDirection="column" flex="1" gap="1.5">
      <FormLabel margin="0">Preço</FormLabel>
      <Skeleton isLoaded={!quoteLoading}>
        <InputGroup alignItems="flex-end">
          <InputLeftElement width="auto" color="gray.500" paddingInline="2.5" py="2" alignItems="flex-end">
            <Text textStyle="body1-regular">R$</Text>
          </InputLeftElement>
          <Controller
            name="quoteItems.0.amount"
            control={control}
            defaultValue={0.0}
            render={({ field: { onChange, ref, value, ...field } }) => (
              <NumericFormat
                customInput={Input}
                getInputRef={ref}
                placeholder="0,00"
                decimalScale={2}
                allowNegative={false}
                decimalSeparator=","
                fixedDecimalScale
                allowLeadingZeros
                paddingRight="2.5"
                paddingLeft="8.5"
                py="2"
                maxLength={12}
                value={value}
                color={value === 0.0 ? 'gray.500' : 'gray.900'}
                _focus={{ bgColor: 'gray.50', borderColor: 'orange.500' }}
                onValueChange={({ value }) => onChange(parseSafeFloat(value))}
                {...field}
              />
            )}
          />
        </InputGroup>
      </Skeleton>
    </FormControl>
  );
}

export default AmountInput;
