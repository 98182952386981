import { useCallback, useEffect } from 'react';

import { SubmitHandler } from 'react-hook-form';
import { useMatch } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { useToast } from '@tradener/lumen';

import UPDATE_ENERGY_QUOTE_EXTRA, {
  ResponseProps as ResponseProps,
  PayloadProps as PayloadProps,
} from '@graphql/mutation/updateEnergyQuoteExtra';
import PRODUCT_QUOTES, { QuoteProps as ProductQuoteProps } from '@graphql/query/productOperations';
import { QUOTE_FRAGMENT } from '@graphql/query/quote';
import QUOTE_DETAILS, { QUOTE_DETAILS_FRAGMENT, QuoteProps } from '@graphql/query/quoteDetails';

import { useQuoteOptions } from '../useQuoteOptions';
import useComplementForm, { ComplementFormProps } from './useComplementForm';

const useComplement = () => {
  const toast = useToast();
  const { id, opportunity, complementDialog } = useQuoteOptions();
  const { formState, setValue, watch, reset } = useComplementForm();
  const isQuoteDetails = useMatch('/trading/list/:id/show');

  const [updateQuote, { loading, client }] = useMutation<ResponseProps<QuoteProps | ProductQuoteProps>, Partial<PayloadProps>>(
    UPDATE_ENERGY_QUOTE_EXTRA(isQuoteDetails ? QUOTE_DETAILS_FRAGMENT : QUOTE_FRAGMENT),
  );

  const onSubmit: SubmitHandler<ComplementFormProps> = (data) => {
    Object.keys(data).forEach((key) => (data[key] === '' || key === 'bbceProductTransfer') && delete data[key]);

    updateQuote({
      variables: { input: { ...data, id } },
      onCompleted() {
        client.refetchQueries({ include: [isQuoteDetails ? QUOTE_DETAILS : PRODUCT_QUOTES] });

        toast({ status: 'success', title: 'Successo', description: 'Cotação atualizada' });

        complementDialog.onClose();
      },
      onError: ({ message }) => toast({ status: 'error', description: message }),
    });
  };

  const onSelect = useCallback(
    (id: string, name: string) => {
      setValue('bbceProductTransfer', name, { shouldDirty: false, shouldTouch: false });
      setValue('bbceProductTransferId', id, { shouldDirty: false, shouldTouch: false });
    },
    [setValue],
  );

  useEffect(() => {
    if (!watch('bbceProductTransferId') && opportunity.bbceProductTransfer && !formState.isDirty) {
      const { bbceProductFrom, bbceProductTo } = opportunity.bbceProductTransfer;

      if (opportunity?.type === 'SALE') onSelect(bbceProductFrom.id, bbceProductFrom.name);
      else onSelect(bbceProductTo.id, bbceProductTo.name);
    }
  }, [formState.isDirty, opportunity, onSelect, watch]);

  useEffect(() => {
    if (!complementDialog.isOpen && formState.isDirty) reset();
  }, [complementDialog.isOpen, formState.isDirty, reset]);

  return {
    bbceProductTransfer: opportunity.bbceProductTransfer,
    loading,
    complementDialog,
    onSubmit,
  };
};

export default useComplement;
