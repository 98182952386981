/* eslint-disable no-underscore-dangle */
import { useState, useMemo, useContext, useEffect, useCallback } from 'react';

import ConfirmationDialog from '@DEPRECATED/components/ConfirmationDialog';
import { DatePicker, FieldBox, SelectionControl } from '@DEPRECATED/components/Form';
import ProposalsContext from '@DEPRECATED/context/Proposals/context';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  addMonths,
  subMonths,
  addYears,
  endOfMonth,
  startOfMonth,
  startOfYear,
  endOfYear,
  differenceInMonths,
  differenceInBusinessDays,
  isAfter,
  setMonth,
  isBefore,
  isEqual,
} from 'date-fns';
import PropTypes from 'prop-types';

import { Container, Left, Right, RightHeader, RightContent } from './styles';

function Period({ enableDialog, error }) {
  const { data, replaceData } = useContext(ProposalsContext);

  const {
    periodType,
    endDate,
    startDate,
    opportunity: { channel },
  } = data;

  const [openDialog, setOpenDialog] = useState(false);
  const [custom, setCustom] = useState(false);

  const [content, setContent] = useState({});

  const getDifferenceMonths = useCallback(() => {
    const value = differenceInMonths(endDate, startDate);

    if (Number.isNaN(value)) return null;

    return differenceInMonths(endDate, startDate) + 1;
  }, [endDate, startDate]);

  const options = useMemo(
    () =>
      periodType === 'SHORT'
        ? [
            { value: 1, label: '1M' },
            { value: 3, label: '3M' },
            { value: 6, label: '6M' },
          ]
        : [
            { value: 12, label: '1A' },
            { value: 36, label: '3A' },
            { value: 60, label: '5A' },
          ],
    [periodType],
  );

  const differenceMonths = useMemo(() => getDifferenceMonths() ?? 3, [getDifferenceMonths]);

  function persistData(contentData) {
    replaceData(null, contentData);
  }

  function handleClose(confirmation) {
    setOpenDialog(false);
    if (confirmation) {
      persistData({
        ...content,
        priceBook: undefined,
        options: {
          ...data.options,
          edited: {
            ...data.options.edited,
            quoteItemPrice: false
          },
        },
      });
    }
  }

  // eslint-disable-next-line no-shadow
  function addPeriod(value, periodType) {
    let initialDate = new Date();
    let finaleDate;

    if (value >= 12) {
      initialDate = startOfYear(addYears(initialDate, 1));
      finaleDate = endOfYear(addYears(initialDate, Math.round(value / 12) - 1));
    } else {
      finaleDate = addMonths(initialDate, value - 1);

      switch (Number(value)) {
        case 1:
          if (differenceInBusinessDays(initialDate, startOfMonth(initialDate)) < 8) {
            initialDate = subMonths(initialDate, 1);
          }
          finaleDate = addMonths(initialDate, value - 1);

          break;
        case 3:
          if (
            isAfter(initialDate, endOfMonth(setMonth(initialDate, 0))) &&
            (isBefore(initialDate, endOfMonth(setMonth(initialDate, 3))) ||
              isEqual(initialDate, endOfMonth(setMonth(initialDate, 3))))
          ) {
            initialDate = setMonth(initialDate, 3);
            finaleDate = setMonth(initialDate, 5);
          } else if (
            isAfter(initialDate, endOfMonth(setMonth(initialDate, 3))) &&
            (isBefore(initialDate, endOfMonth(setMonth(initialDate, 6))) ||
              isEqual(initialDate, endOfMonth(setMonth(initialDate, 6))))
          ) {
            initialDate = setMonth(initialDate, 6);
            finaleDate = setMonth(initialDate, 8);
          } else if (
            isAfter(initialDate, endOfMonth(setMonth(initialDate, 6))) &&
            (isBefore(initialDate, endOfMonth(setMonth(initialDate, 9))) ||
              isEqual(initialDate, endOfMonth(setMonth(initialDate, 9))))
          ) {
            initialDate = setMonth(initialDate, 9);
            finaleDate = setMonth(initialDate, 11);
          } else {
            initialDate = setMonth(initialDate, 0);
            finaleDate = setMonth(initialDate, 2);
          }

          break;
        case 6:
          if (
            isAfter(initialDate, endOfMonth(setMonth(initialDate, 0))) &&
            (isBefore(initialDate, endOfMonth(setMonth(initialDate, 6))) ||
              isEqual(initialDate, endOfMonth(setMonth(initialDate, 6))))
          ) {
            initialDate = setMonth(initialDate, 6);
            finaleDate = setMonth(initialDate, 11);
          } else {
            initialDate = setMonth(initialDate, 0);
            finaleDate = setMonth(initialDate, 5);
          }

          break;
        default:
          break;
      }
    }

    initialDate = startOfMonth(initialDate);
    finaleDate = endOfMonth(finaleDate);

    const contentData = {
      periodType: periodType || data.periodType,
      startDate: initialDate,
      endDate: finaleDate,
      quoteItems: [],
    };

    if (enableDialog) {
      setOpenDialog(true);
      setContent(contentData);
    } else {
      persistData(contentData);
    }
  }

  function changePeriod({ target }) {
    const period = target.value === 'SHORT' ? 3 : 36;

    setCustom(false);
    addPeriod(period, target.value);
  }

  function updateDate(date, name) {
    let _startDate;
    let _endDate;
    let contentData = {};

    if (!custom && periodType === 'SHORT') {
      const diff = differenceInMonths(endDate, startDate);

      if (name === 'endDate') {
        _startDate = startOfMonth(addMonths(date, -diff));
        _endDate = date;
      } else if (name === 'startDate') {
        _startDate = date;
        _endDate = endOfMonth(addMonths(date, diff));
      }

      contentData = {
        startDate: _startDate,
        endDate: _endDate,
      };
    } else {
      contentData[name] = date;
    }

    contentData.quoteItems = [];

    if (enableDialog) {
      setOpenDialog(true);
      setContent(contentData);
    } else {
      persistData(contentData);
    }
  }

  function handleCustom() {
    setCustom((prevState) => !prevState);
  }

  useEffect(() => {
    function loadInitialValues() {
      if (!startDate || !endDate) addPeriod(3, 'SHORT');
    }

    setTimeout(loadInitialValues, 50);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FieldBox title="Vigência">
      <Container>
        <Left>
          <SelectionControl
            name="period_type"
            value="SHORT"
            disabled={channel === 'PORTAL'}
            checked={periodType === 'SHORT'}
            onChange={changePeriod}
            label="Curto Prazo"
          />

          <SelectionControl
            name="period_type"
            value="LONG"
            disabled={channel === 'PORTAL'}
            checked={periodType === 'LONG'}
            onChange={changePeriod}
            label="Longo Prazo"
          />
        </Left>
        <Right>
          <RightHeader>
            <SelectionControl
              name={periodType}
              variant="contained"
              disabled={channel === 'PORTAL'}
              checked={custom}
              onChange={handleCustom}
              value="C"
              label="C"
            />

            {options.map((option) => (
              <SelectionControl
                key={option.value}
                name={periodType}
                variant="contained"
                disabled={channel === 'PORTAL'}
                checked={custom ? false : option.value === differenceMonths}
                onChange={({ target }) => {
                  addPeriod(target.value, periodType);
                  setCustom(false);
                }}
                {...option}
              />
            ))}
          </RightHeader>
          <RightContent>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography component="span">Início</Typography>
              </Grid>
              <Grid item>
                <DatePicker
                  id="startDate"
                  disabled={channel === 'PORTAL'}
                  format="MMM/yyyy"
                  views={['year', 'month']}
                  value={startDate}
                  onChange={(date) => updateDate(date, 'startDate')}
                />
              </Grid>
            </Grid>
            <Divider />
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography component="span">Fim</Typography>
              </Grid>
              <Grid item>
                <DatePicker
                  id="endDate"
                  disabled={channel === 'PORTAL'}
                  format="MMM/yyyy"
                  views={['year', 'month']}
                  value={endDate}
                  onChange={(date) => updateDate(date, 'endDate')}
                  minDate={error ? startDate : undefined}
                  error={error}
                />
              </Grid>
            </Grid>
          </RightContent>
        </Right>

        <ConfirmationDialog
          id="period-dialog-confirmation"
          disableEscapeKeyDown
          open={openDialog}
          onClose={handleClose}
          title="Atenção"
        >
          <DialogContent dividers>
            A mudança da vigência fará com que os dados da oferta (preço e período) sejam redefinidos. Deseja continuar?
          </DialogContent>
        </ConfirmationDialog>
      </Container>
    </FieldBox>
  );
}

Period.propTypes = {
  enableDialog: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
};

export default Period;
