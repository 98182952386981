import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette, shadows }) => {
  const backgroundColor = palette.type === 'dark' ? palette.grey[800] : palette.common.white;

  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    input: {
      width: 'calc(100% - 140px)',
    },
    wrapper: {
      width: '80vw',
      height: '80vh',
      padding: '24px',
      borderRadius: '4px',
      boxShadow: shadows[2],
      backgroundColor,
    },
    sticky: {
      left: 0,
      position: 'sticky',
      backgroundColor,
    },
    table: {
      '& th:first-child, & th:nth-last-child(2)': {
        padding: '0 16px',
      },
    },
  };
});

export default useStyles;
