import { Avatar, Badge, Flex, Text, Tooltip } from '@tradener/lumen';

import { BbceProductTransferProps } from '@graphql/query/productOperations';

import TransferIcon from '~/theme/icons/Transfer';

import { useBbceProduct } from '../../..';
import ProductBadge from '../../../ProductBadge';
import TransferContainer from '../../TransferContainer';
import TransferItem from './TransferItem';

export interface TransferListItemProps {
  transfer: BbceProductTransferProps;
  hasBorder: boolean;
}

const TransferListItem: React.ComponentType<TransferListItemProps> = ({ transfer, hasBorder }) => {
  const { id } = useBbceProduct();
  const productTo = transfer?.bbceProductTo;
  const productFrom = transfer?.bbceProductFrom;
  const isTransferFrom = id === productFrom?.id;
  const transferName = isTransferFrom ? `${productTo?.name}` : `${productFrom?.name}`;

  return (
    <Flex direction="column" borderBottom={hasBorder ? '1px solid' : 'none'} borderBottomColor="gray.200">
      <TransferContainer>
        <TransferItem gap="4" justifyContent="flex-start">
          <Flex width="10" height="10" align="center" justify="center" borderRadius="xl" bgColor="purple.50">
            <TransferIcon boxSize="6" color="purple.500" />
          </Flex>
          <Flex flexDirection="column" alignItems="flex-start">
            <Flex alignItems="flex-end" columnGap="1">
              <Text textStyle="body2-regular">
                {Math.abs(Number(transfer.quantityMwm))?.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
              <Text textStyle="body2-regular">MWm</Text>
            </Flex>
            <Text textStyle="body2-regular" color="gray.500">
              {Number(transfer?.price).formatCurrency()}
            </Text>
          </Flex>
        </TransferItem>
        <TransferItem justifyContent="flex-start" gap="2">
          <Text textAlign="center" justifySelf="center" textStyle="body2-regular" color="gray.900">
            {isTransferFrom ? 'PARA: ' : 'DE: '}
          </Text>
          <Tooltip
            bgColor="gray.900"
            color="gray.0"
            borderRadius="base"
            label={transferName}
            hasArrow
            maxWidth="56"
            width="fit-content"
            shouldWrapChildren
          >
            <ProductBadge textStyle="caption1-medium" maxW="56" readonly>
              {transferName}
            </ProductBadge>
          </Tooltip>
        </TransferItem>
        <TransferItem>{transfer?.code ?? '-'}</TransferItem>
        <TransferItem>-</TransferItem>
        <TransferItem columnGap="3">
          <Avatar name={transfer?.createdBy?.name} size="sm" />
          <Tooltip
            width="fit-content"
            bgColor="gray.900"
            color="gray.0"
            borderRadius="base"
            label={transfer?.createdBy?.name}
            hasArrow
          >
            <Text width="calc(82%)" overflowX="clip" textOverflow="ellipsis" whiteSpace="nowrap">
              {transfer?.createdBy?.name}
            </Text>
          </Tooltip>
        </TransferItem>
        <TransferItem>-</TransferItem>
        <TransferItem justify="center">
          <Badge borderRadius="base" py="1px" px="1.5" bgColor="blue.500" color="white">
            Transferido
          </Badge>
        </TransferItem>
        <TransferItem>-</TransferItem>
        <TransferItem justifyContent="flex-end">
          <Text textStyle="body2-regular" color={isTransferFrom ? 'green.500' : 'red.500'}>
            {isTransferFrom ? '+ ' : '- '}
            {Math.abs(Number(transfer?.amountTotal))?.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
        </TransferItem>
        <Flex />
      </TransferContainer>
    </Flex>
  );
};

export default TransferListItem;
