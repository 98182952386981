import { useEffect } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import '~/prototype';

import { withProvider } from '~/provider';
import Routes from '~/routes';
import renewSession, { setSession } from '~/services/session';
import { keepSessionAlive } from '~/services/sessionAlive';

import 'react-toastify/dist/ReactToastify.css';
import './styles.css';

keepSessionAlive();

let toastId: string | number;

function App() {
  const networkStatus = async ({ type }: Event) => {
    if (toast.isActive(toastId)) toast.dismiss();

    if (type === 'online') {
      toastId = toast.success('Acesso a internet estabelecido.');

      const session = await renewSession();

      if (session) setSession(session);
    }

    if (type === 'offline') {
      toastId = toast.error('Oops... Sem conexão com a internet!', { autoClose: false });
    }
  };

  useEffect(() => {
    window.addEventListener('offline', networkStatus);
    window.addEventListener('online', networkStatus);

    return () => {
      window.removeEventListener('offline', networkStatus);
      window.removeEventListener('online', networkStatus);
    };
  }, []);

  return (
    <>
      <Routes />
      <ToastContainer position="top-center" hideProgressBar pauseOnHover closeButton={false} autoClose={5000} theme="colored" />
    </>
  );
}

export default withProvider(App);
