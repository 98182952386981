import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.footer`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;

    margin-top: 2px;
    padding-top: 12px;

    button,
    div {
      border-radius: 0;
      height: 40px;

      &.next {
        order: 2;

        &:enabled {
          color: #FFF;
          background: ${theme?.palette?.primary?.main};
        }

        span {
          margin-left: 16px;
        }

        svg {
          margin-left: 24px;
        }
      }

      &.previous {
        order: 1;
        ${(theme?.palette?.type === 'light') &&
          `border: 1px solid #CCC`};

        ${(theme?.palette?.type === 'dark') &&
          `color: #F1F1F1`};

        ${(theme?.palette?.type === 'light') &&
          `background: #EEE` || `background: #373737`};

        margin-right: auto;

        span {
          margin-right: 16px;
        }

        svg {
          margin-right: 24px;
        }
      }
    }
  `}
`;
