import BackgroundImage from '@DEPRECATED/assets/images/storm_background.jpg';
import styled from 'styled-components';

export const Container = styled.div`
  min-width: 100vw;
  background-color: #ffffff;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  height: 80vh;
  background: transparent url(${BackgroundImage}) 50% 50% no-repeat padding-box;
  background-size: cover;

  img {
    margin: 186px 0 96px;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: space-around;

  img {
    max-width: 20%;
  }

  height: 20vh;
  max-width: 960px;
  margin: 0 auto;
`;
