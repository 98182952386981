import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: palette.grey[50],
    '&$checked': {
      transform: 'translateX(12px)',
      color: palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: palette.primary.main,
        borderColor: palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: palette.grey[palette.type === 'dark' ? 600 : 500],
  },
  checked: {},
}));

export default useStyles;
