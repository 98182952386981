export const getDefaultAccounts = (env: string | undefined) => {
  const production = env === 'production';

  return [
    {
      name: 'TRADENER',
      accountId: production ? '5d74217f-dea4-4aec-9bea-e84808feef26' : '7baa918b-a9c3-489c-9b85-e597c85dd08e',
    },
    {
      name: 'TRADENER RS',
      accountId: production ? '46390446-c973-4790-988e-d2f3e3e688b5' : '6e5a376e-fb49-4ceb-8359-c116936ba6bb',
    },
    {
      name: 'TRADENER VAREJISTA',
      accountId: production ? 'f31314a3-1c2d-4ef9-a2a9-446fec7d0b42' : 'ccb9c613-7507-4d01-a49d-91754ef479c0',
    },
    {
      name: 'RONDINHA',
      accountId: production ? '5e003b50-3c2c-4cf2-8797-92569b472822' : 'ad77639d-75f2-4c65-b814-014b073ff944',
    },
    {
      name: 'PIERP',
      accountId: production ? 'a0486bfb-b771-4538-85ab-d4aa10e2ecdc' : 'e00251b7-f1bb-4e4a-ba1a-841dda28b1a1',
    },
    {
      name: 'TAÍBA',
      accountId: production ? 'c4ff377a-d9ae-4d3a-bba2-5581d1d324fc' : 'b1dca3c3-004d-459e-9afb-32c7dcab1ebb',
    },
  ];
};

export default getDefaultAccounts(process.env.REACT_APP_ROLLBAR_ENV);
