import { forwardRef } from 'react';

import { Dialog, DialogProps } from '@material-ui/core';

import useStyles from './styles';

export interface ModalProps extends Omit<DialogProps, 'open' | 'onClose' | 'ref'> {
  isOpen: boolean;
  onClose: (event: unknown, reason: 'backdropClick' | 'escapeKeyDown' | 'manually') => void;
}

const Modal = forwardRef<HTMLDivElement, ModalProps>(({ children, isOpen, onClose, ...props }, ref) => {
  const classes = useStyles();

  return (
    <Dialog
      ref={ref}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-dialog"
      aria-describedby="modal-description"
      classes={classes}
      {...props}
    >
      {children}
    </Dialog>
  );
});

Modal.displayName = 'Modal';

export { default as ModalHeader } from './ModalHeader';
export { default as ModalBody } from './ModalBody';
export { default as ModalFooter } from './ModalFooter';

export default Modal;
