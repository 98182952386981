import { useState } from 'react';

import TabPanel from '@DEPRECATED/components/TabPanel';
import Divider from '@material-ui/core/Divider';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { CircularProgress, Box } from '@material-ui/core';

import OpportunityFooter from '../OpportunityFooter';
import Details from './Details';
import Price from './Price';
import { Container } from './styles';

import useConfiguration from '../useConfiguration';

function Offer(parent) {
  const { index, handleNext, handlePrevious } = parent;
  const [value, setValue] = useState(0);
  const { loading } = useConfiguration();

  function handleChange(_, newValue) {
    setValue(newValue);
  }

  return (
    <>
      <Container>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          aria-label="Oferta"
          TabIndicatorProps={{
            hidden: true,
          }}
        >
          <Tab label="Preço" />
          <Tab label="Detalhes" />
        </Tabs>
        <Divider />
        {!loading ? (
          <>
            <TabPanel value={value} index={0}>
              <Price />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Details />
            </TabPanel>
          </>
        ) : (
          <Box height="100%" display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        )}
      </Container>
      <OpportunityFooter
        index={index}
        handleNext={handleNext}
        labelNext="Proposta"
        handlePrevious={handlePrevious}
        labelPrevious="Produto"
      />
    </>
  );
}

export default Offer;
