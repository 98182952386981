import Widget from '@DEPRECATED/components/Widget';
import { Box } from '@material-ui/core';
import useColorMode from '~/hooks/useColorMode';

import { Content, Title, Fields, Values } from './styles';

export type OperationValue = {
  opened: number;
  negotiated: number;
};

type OfferProgressOperationProps = {
  color: string;
  title: string;
  data: OperationValue | undefined;
};

const OfferProgressOperation: React.FC<OfferProgressOperationProps> = ({ color, title, data }) => {
  const { colorTernary } = useColorMode();

  return (
    <Widget color={color}>
      <Content color={colorTernary('white', '#262626')}>
        <Title>{title}</Title>
        <Fields>
          <Values>
            <span>{data?.opened || 0}</span>
            <span style={{ color: colorTernary('#999', '##A8A88') }}>Abertas</span>
          </Values>
          <Values>
            <span>{data?.negotiated || 0}</span>
            <span style={{ color: colorTernary('#999', '##A8A88') }}>Em Negociação</span>
          </Values>
        </Fields>
      </Content>
    </Widget>
  );
};

export default OfferProgressOperation;
