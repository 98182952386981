import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    boxSizing: 'border-box',
    height: '40px',
    border: '2px solid transparent',
    transition: 'all 0.25s ease-in-out',
    borderRadius: '4px',
    padding: '6px 16px',
    fontSize: 20,
    width: 'auto',
    lineHeight: '24px',
    fontWeight: 400,
    color: `${palette.type === 'dark' ? '#FFF' : '#262626'}`,
    '&:hover': {
      backgroundColor: `${palette.type === 'dark' ? '#373737' : '#EEEEEE'}`,
    },
  },

  focused: {
    boxSizing: 'border-box',
    display: 'inline-flex',
    height: '40px',
    border: `2px solid ${palette.primary.main}`,
    transition: 'all 0.25s ease-in-out',
    borderRadius: '4px',
    padding: '6px 16px',
    fontSize: 20,
    width: 'auto',
    lineHeight: '24px',
    fontWeight: 400,
    color: `${palette.type === 'dark' ? '#FFF' : '#262626'}`,
  },

  disabled: {
    color: `${palette.type === 'dark' ? '#FFF' : '#262626'}`,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default useStyles;
