import { useNavigate, useParams, useLocation } from 'react-router-dom';

import Dialog from '@DEPRECATED/components/Dialog';
import Proposals from '@DEPRECATED/context/Proposals';
import { useQuery } from '@apollo/client';
import CardContent from '@material-ui/core/CardContent';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { GET_QUOTE } from '../Opportunities';
import Proposed from '../Opportunities/Proposed';

export default function QuoteDetails() {
  const { id } = useParams();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const push = useNavigate();
  const location = useLocation();

  const { data } = useQuery(GET_QUOTE, {
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
  });

  function handleClose() {
    const path = location.pathname;

    if (path.includes('trading/list')) {
      push('/trading/list');
      return;
    }

    push('/book/list');
  }

  if (!data) return null;

  return (
    <Proposals initialValue={{ ...data.quote, readOnly: true }}>
      <Dialog onClose={handleClose} title={data.quote.offerCode} fullScreen={fullScreen} fullWidth open>
        <CardContent>
          <Proposed />
        </CardContent>
      </Dialog>
    </Proposals>
  );
}
