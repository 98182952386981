import { gql } from '@apollo/client';

export interface UpdateSimulationInterestRateInputProps {
  simulationId: string;
  annualInterestRate: number;
}

export interface UpdateSimulationInterestRatePayloadProps {
  status: string;
}

export interface PayloadProps {
  input: UpdateSimulationInterestRateInputProps;
}

export interface ResponseProps {
  updateSimulationInterestRate: UpdateSimulationInterestRatePayloadProps;
}

const UPDATE_SIMULATION_INTEREST_RATE = gql`
  mutation updateSimulationInterestRate($input: UpdateSimulationInterestRateInput!) {
    updateSimulationInterestRate(input: $input) {
      updateSimulationInterestRate {
        status
      }
      errors
    }
  }
`;

export default UPDATE_SIMULATION_INTEREST_RATE;
