import { memo } from 'react';

import { useParams } from 'react-router-dom';

import { Box, BoxProps, Tooltip, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { SimulationContractProps } from '@graphql/query/simulationContracts';

import useColorMode from '~/hooks/useColorMode';
import usePortfolio from '~/pages/Portfolio/usePortfolio';

import ContractNumber from './ContractNumber';
import useStyles from './styles';

interface ContractLabelProps extends BoxProps {
  handleExcludedsContracts: (id: string, contractNumber: string) => void;
  contract: SimulationContractProps;
  excludedContract: boolean;
}

const ContractLabel: React.ComponentType<ContractLabelProps> = ({
  handleExcludedsContracts,
  contract,
  excludedContract,
  ...props
}) => {
  const { colorTernary, theme } = useColorMode();
  const { body1, body2, factors } = useStyles();
  const { usageFactorByContract, usageFactorByEnergyType, usageFactor } = usePortfolio();
  const { operation } = useParams();
  const accountNameWidth = usageFactorByContract && usageFactorByEnergyType && operation === 'sale' ? '98px' : '178px';

  const percentValue = (value: number | undefined | null) => {
    if (value) {
      return Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value * 100) + '%';
    } else {
      return '';
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-start"
      position="sticky"
      left="0"
      bgcolor={colorTernary(theme.palette.grey[800], 'white')}
      zIndex={2}
      {...props}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="40px"
        width="64px"
        padding="0 8px"
        borderTop={`1px solid ${colorTernary('#525252', '#26262612')}`}
        onClick={() => handleExcludedsContracts(contract.id, contract.contractNumber)}
        style={{ cursor: 'pointer' }}
      >
        {excludedContract ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" color="primary" />}
      </Box>
      <Box
        display="grid"
        gridTemplateColumns={'72px 24px 32px 1fr'}
        alignItems="center"
        justifyContent="flex-end"
        gridGap="6px"
        height="40px"
        borderTop={`1px solid ${colorTernary('#525252', '#26262612')}`}
      >
        <ContractNumber contractId={contract.id} contractNumber={contract.contractNumber} />
        <Typography color="inherit" className={body1}>
          {contract.submarket}
        </Typography>
        <Typography color="inherit" className={body1}>
          {contract.energyType}
        </Typography>
        <Tooltip title={contract.accountName} placement="top" arrow>
          <Typography color="inherit" className={body2} style={{ width: accountNameWidth }}>
            {contract.accountName}
          </Typography>
        </Tooltip>
      </Box>
      {operation === 'sale' && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          width="100%"
          marginLeft="auto"
          height="40px"
          borderTop={`1px solid ${colorTernary('#525252', '#26262612')}`}
        >
          {usageFactorByContract && (
            <Typography color="inherit" className={factors}>
              {percentValue(contract.usageFactor)}
            </Typography>
          )}
          {usageFactorByEnergyType && (
            <Typography color="inherit" className={factors}>
              {percentValue(contract.energyTypeUsageFactor)}
            </Typography>
          )}
          {(usageFactorByContract || usageFactorByEnergyType || usageFactor) && (
            <Typography color="inherit" className={factors}>
              {percentValue(contract.appliedUsageFactor)}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default memo(ContractLabel);
