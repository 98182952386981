import { Avatar, Tooltip, Text } from '@tradener/lumen';

import OfferListItem from '../OfferListItem';
import useProductQuote from '../useProductQuote';

function OfferTrader() {
  const { createdBy } = useProductQuote();

  return (
    <OfferListItem columnGap="3">
      <Avatar name={createdBy?.name} size="sm" />
      <Tooltip width="fit-content" bgColor="gray.900" color="gray.0" borderRadius="base" label={createdBy?.name} hasArrow>
        <Text width="calc(82%)" overflowX="clip" textOverflow="ellipsis" whiteSpace="nowrap">
          {createdBy?.name}
        </Text>
      </Tooltip>
    </OfferListItem>
  );
}

export default OfferTrader;
