import OfferListItem from '../OfferListItem';
import useProductQuote from '../useProductQuote';

function OfferChannel() {
  const { opportunity } = useProductQuote();

  return <OfferListItem textTransform="capitalize">{opportunity?.channel?.toUpperCase()}</OfferListItem>;
}

export default OfferChannel;
