import {
  Modal,
  ModalOverlay,
  ModalContent,
  Flex,
  ModalBody,
  ModalHeader,
  Text,
  Tabs,
  TabList,
  Tab,
  ModalCloseButton,
  TabPanels,
  TabPanel,
} from '@tradener/lumen';

import { QuoteProps } from '@graphql/query/productOperations';

import useBackward from '~/hooks/useBackward';
import QuoteOptions from '~/pages/Trading/TradingList/QuoteOptions';

import ActivitiesTab from './ActivitiesTab';
import ConditionsTab from './ConditionsTab';
import DetailsTab from './DetailsTab';
import MenuDetails from './MenuDetails';
import { useDetails, withDetails } from './useDetails';

function OfferDetails() {
  const { quote, loading } = useDetails();

  const onClose = useBackward('/trading/list');

  if (loading) return null;

  return (
    <>
      <Modal size="unset" onClose={onClose} closeOnEsc isCentered isOpen>
        <ModalOverlay />
        <ModalContent width="4xl">
          <ModalHeader>
            <Flex alignItems="center" gap="2" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
              <Text textStyle="subheading1-semibold">
                {quote?.opportunity.bbceProduct?.name.toUpperCase() ?? quote?.product.toUpperCase()}
              </Text>
              <Text textStyle="subheading1-semibold">•</Text>
              <Text textStyle="subheading1-semibold">{quote?.offerCode}</Text>
            </Flex>
            {quote && (
              <Flex margin="-0.25rem 3rem 0 auto">
                <QuoteOptions key={quote?.updatedAt} quote={quote as unknown as QuoteProps}>
                  <MenuDetails />
                </QuoteOptions>
              </Flex>
            )}
            <ModalCloseButton
              width="10"
              height="10"
              sx={{
                '& > svg': {
                  boxSize: '3.5',
                },
              }}
            />
          </ModalHeader>
          <ModalBody flexDirection="column" padding="0" gap="0">
            <Tabs>
              <TabList height="12" px="6">
                <Tab>Detalhes</Tab>
                <Tab>Condições gerais</Tab>
                <Tab>Atividades</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <DetailsTab />
                </TabPanel>
                <TabPanel>
                  <ConditionsTab />
                </TabPanel>
                <TabPanel>
                  <ActivitiesTab />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export { useDetails };
export default withDetails(OfferDetails);
