import { makeStyles } from '@material-ui/core';
import { Palette } from '@material-ui/core/styles/createPalette';
import { DateTime } from 'luxon';

import { DateYearMonthProps } from '..';

const useStyles = makeStyles(() => ({
  chip: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60px',
    height: '36px',
    cursor: 'pointer',
    transition: 'all 0.1s',
    transformStyle: 'preserve-3d',
    '&.from-picked:after, &.until-picked:after': {
      content: '""',
      position: 'absolute',
      width: '60px',
      height: '36px',
      transform: 'translateZ(-1px)',
    },
    '&.from-picked:after': {
      borderTopLeftRadius: '500px',
      borderBottomLeftRadius: '500px',
    },
    '&.until-picked:after': {
      borderTopRightRadius: '500px',
      borderBottomRightRadius: '500px',
    },
    '&:not(.from-picked):not(.until-picked):hover': {
      backgroundColor: '#AAAAAA50 !important',
    },
  },
}));

export const getMonthColor = (month: number, referenceYear: number, isBetweenYears: boolean, range: any) => {
  if (isBetweenYears) {
    const isYearRangeEquals = range.startYear === range.endYear;

    if (isYearRangeEquals) {
      if (month === range.startMonth || month === range.endMonth) return 'primary';
      else if (month > range.startMonth && month < range.endMonth) return 'secondary';
    } else {
      const isEqualsToStartYear = referenceYear === range.startYear;
      const isEqualsToEndYear = referenceYear === range.endYear;

      if (isEqualsToStartYear === isEqualsToEndYear) {
        return 'secondary';
      } else if (isEqualsToStartYear) {
        if (month === range.startMonth) return 'primary';
        else if (month > range.startMonth) return 'secondary';
      } else if (isEqualsToEndYear) {
        if (month === range.endMonth) return 'primary';
        else if (month < range.endMonth) return 'secondary';
      }
    }
  }
};

export const getStyles = (
  index: number,
  range: DateYearMonthProps,
  referenceYear: number,
  isBetweenYears: boolean,
  palette: Palette,
) => {
  const { common, grey, type: mode } = palette;

  const dateNow = DateTime.local();

  const styles: React.CSSProperties = {};
  const month = index + 1;

  if ([0, 4, 8].includes(index)) {
    styles.borderTopLeftRadius = 500;
    styles.borderBottomLeftRadius = 500;
  } else if ([3, 7, 11].includes(index)) {
    styles.borderTopRightRadius = 500;
    styles.borderBottomRightRadius = 500;
  }

  if (getMonthColor(month, referenceYear, isBetweenYears, range) === 'primary') {
    styles.backgroundColor = mode === 'dark' ? '#262626' : '#EEE';
    styles.color = common.white;
    if (month === range.startMonth) {
      styles.borderTopLeftRadius = 500;
      styles.borderBottomLeftRadius = 500;
    }
    if (month === range.endMonth) {
      styles.borderTopRightRadius = 500;
      styles.borderBottomRightRadius = 500;
    }
  } else if (getMonthColor(month, referenceYear, isBetweenYears, range) === 'secondary') {
    styles.backgroundColor = mode === 'dark' ? '#262626' : '#EEE';
  } else if (month === dateNow.month && referenceYear === dateNow.year) {
    styles.borderTopLeftRadius = 500;
    styles.borderBottomLeftRadius = 500;
    styles.borderTopRightRadius = 500;
    styles.borderBottomRightRadius = 500;
    styles.border = `1px solid ${grey[mode === 'dark' ? 600 : 300]}`;
  }

  if (referenceYear < 1970) styles.cursor = 'not-allowed';

  return styles;
};

export default useStyles;
