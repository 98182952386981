import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@tradener/lumen';

import ComplementExternalId from './ComplementExternalId';
import ComplementGiro from './ComplementGiro';
import ComplementNotes from './ComplementNotes';
import ComplementSwap from './ComplementSwap';
import useComplement from './useComplement';
import useComplementForm, { withComplementForm } from './useComplementForm';

function ComplementDialog() {
  const { handleSubmit, formState } = useComplementForm();
  const {
    onSubmit,
    loading,
    complementDialog: { isOpen, onClose },
  } = useComplement();

  if (!isOpen) return null;

  return (
    <Modal size="lg" onClose={onClose} isCentered isOpen>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Complemento
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody width="full" flexDirection="column" alignItems="flex-start" justifyContent="center" gap="2">
          <ComplementExternalId />
          <ComplementGiro />
          <ComplementNotes />
          <ComplementSwap />
        </ModalBody>
        <ModalFooter gap="3">
          <Button colorScheme="gray" variant="outline" onClick={onClose}>
            Cancelar
          </Button>
          <Button isLoading={loading} isDisabled={!formState.isDirty} onClick={handleSubmit(onSubmit)}>
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default withComplementForm(ComplementDialog);
