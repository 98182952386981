import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  paper: {
    borderRadius: '12px',
    backgroundColor: palette.background.paper,
  },
}));

export default useStyles;
