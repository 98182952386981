import { forwardRef, useState, useImperativeHandle, useEffect } from 'react';

import { Box, Button, IconButton, BoxProps, useTheme } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

import useCalendar, { DateTypes } from '~/hooks/useCalendar';
import useColorMode from '~/hooks/useColorMode';

import useStyles from './styles';

export interface DateProps {
  startYear?: number;
  endYear?: number;
}

export interface YearRangeProps extends BoxProps {
  onApply?: (value: DateProps) => void;
  defaults?: DateProps;
  minYear?: number;
}

const YearRange = forwardRef<DateProps, YearRangeProps>(
  ({ onApply, defaults = {}, minYear = DateTypes.minYear, ...props }, ref) => {
    const styles = useStyles();
    const { colorTernary } = useColorMode();
    const {
      palette: { primary, grey, common },
    } = useTheme();
    const { reference, getYears } = useCalendar();
    const [date, setDate] = useState(defaults);
    const [from, setFrom] = useState(DateTypes.minYear);

    const yearPicker = (year: number) => () => {
      if (year >= minYear) {
        if (!date.startYear || year < date.startYear || (date.startYear && date.endYear)) {
          setDate({ startYear: year });
        } else if (year > date.startYear) {
          setDate((prev) => ({ ...prev, endYear: year }));
        }
      }
    };

    const getStyles = (year: number, index: number) => {
      const styles: React.CSSProperties = {};

      if (year === date.startYear || year === date.endYear) {
        styles.backgroundColor = primary.main;
        styles.color = common.white;
      } else if (date.startYear && date.endYear && year > date.startYear && year < date.endYear) {
        if ([0, 4, 8, 12].includes(index)) {
          styles.borderTopRightRadius = 0;
          styles.borderBottomRightRadius = 0;
        } else if ([3, 7, 11, 15].includes(index)) {
          styles.borderTopLeftRadius = 0;
          styles.borderBottomLeftRadius = 0;
        } else {
          styles.borderRadius = 0;
        }

        styles.backgroundColor = grey['100'];
      }

      if (minYear && year < minYear) {
        styles.cursor = 'not-allowed';
      }

      return styles;
    };

    const handleForward = () => {
      setFrom((prev) => prev + DateTypes.yearAmount);
    };

    const handleBack = () => {
      if (from - DateTypes.yearAmount >= DateTypes.minYear) {
        setFrom(
          from - DateTypes.yearAmount * 2 >= DateTypes.minYear ? (prev) => prev - DateTypes.yearAmount : DateTypes.minYear % from,
        );
      }
    };

    useImperativeHandle(ref, () => date, [date]);

    useEffect(() => {
      let { year } = reference;

      for (let from = DateTypes.minYear; from <= reference.year; from += DateTypes.yearAmount) {
        year = from;
      }

      setFrom(year);
    }, [reference, reference.year]);

    return (
      <Box className={styles.wrapper} {...props}>
        <Box className={styles.header}>
          <Box className={styles.current}>
            {date.startYear ?? from}
            {' - '}
            {date.endYear ? (
              <span style={{ color: grey[colorTernary(50, 800)] }}>{date.endYear}</span>
            ) : (
              date.endYear ?? from + DateTypes.yearAmount
            )}
          </Box>
          <Box className={styles.control}>
            <IconButton
              onClick={handleBack}
              classes={{ root: styles.chevron }}
              size="small"
              disabled={from === DateTypes.minYear}
            >
              <ChevronLeft />
            </IconButton>
            <IconButton onClick={handleForward} classes={{ root: styles.chevron }} size="small">
              <ChevronRight />
            </IconButton>
          </Box>
        </Box>
        <Box className={styles.body}>
          {getYears(from).map((year, index) => (
            <Box
              key={year}
              className={`${styles.chip} ${year === date.startYear ? 'from-picked' : ''} ${
                year === date.endYear ? 'until-picked' : ''
              }`}
              style={getStyles(year, index)}
              onClick={yearPicker(year)}
            >
              {year}
            </Box>
          ))}
        </Box>
        <Box className={styles.footer}>
          <Button color="primary" variant="contained" onClick={() => onApply?.(date)}>
            Aplicar
          </Button>
        </Box>
      </Box>
    );
  },
);

YearRange.displayName = 'YearRange';

export default YearRange;
