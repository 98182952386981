export const SUBMARKET = {
  N: 'NORTE',
  NE: 'NORDESTE',
  S: 'SUL',
  SE: 'SUDESTE/CENTRO-OESTE',
};

export const PRICE_TYPE = {
  FIXO: 'FIXO',
  PERCENT_PLD: '% PLD',
  SPREAD: 'SPREAD',
};

export const LABEL_PRICE_TYPE = {
  FIXO: 'R$',
  PERCENT_PLD: 'PLD + %PLD',
  SPREAD: 'PLD + R$',
};

export const ENERGY_TYPE = {
  C: 'CONVENCIONAL',
  I5: 'ESPECIAL 50%',
  I1: 'ESPECIAL 100%',
  I0: 'CONVENCIONAL ESPECIAL',
  I8: 'ESPECIAL 80%',
  INE5: 'NÃO ESPECIAL 50%',
};

export const CATEGORY = {
  TRD_DEFAULT: 'TRADENER (Padrão)',
  TRD_FLAT: 'TRADENER (Flat)',
  TRD_PLUS: 'TRD PLUS',
  TRD_RETAILER_SET_PRICE: 'TRD VAREJISTA (Preço Definido)',
  TRD_RETAILER_SET_PRICE_FLEX_FREE: 'TRD VAREJISTA (Preço Definido + Flex LIVRE)',
  TRD_RETAILER_GUARANTEED_GAIN: 'TRD VAREJISTA (Ganho Garantido)',
  TRD_RETAILER_GUARANTEED_GAIN_FLEX_FREE: 'TRD VAREJISTA (Ganho Garantido + Flex LIVRE)',
  TRD_TRIGGER: 'TRD GATILHO',
};
