import { NavLink } from 'react-router-dom';

import { Flex, Button } from '@tradener/lumen';

import { useBbceProduct } from '..';

function ProductOptions() {
  const { id } = useBbceProduct();

  return (
    <Flex columnGap="3" height="full" alignItems="center">
      <Button
        as={NavLink}
        to={`/trading/list/offer?type=create&bbce_product_id=${id}`}
        aria-label="trade product"
        variant="outline"
        colorScheme="gray"
        size="sm"
        borderRadius="md"
        textStyle="body2-regular"
        color="gray.900"
      >
        Negociar
      </Button>
    </Flex>
  );
}

export default ProductOptions;
