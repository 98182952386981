import { Flex, Text } from '@tradener/lumen';

import { useBbceProduct } from '..';

import createDynamicRange from '../createDynamicRange';

const formatPoints = new Intl.NumberFormat('pt-BR', {
  style: 'decimal',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
}).format;

const getColor = createDynamicRange('red.500', 'gray.900', 'green.500');

const { format } = new Intl.NumberFormat('pt-BR', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
});

function ProductOverview() {
  const {
    avgSalePrice,
    avgPurchasePrice,
    saleVolume,
    purchaseVolume,
    balanceVolume,
    resultValue,
    internalResultValue,
    getCurrency,
  } = useBbceProduct();

  return (
    <Flex alignItems="center" justifyContent="flex-end" columnGap="10" paddingLeft="3" height="full">
      <Flex direction="column" columnGap="1" justifyContent="space-between" alignItems="flex-end" height="full" flex="1">
        <Text textStyle="subheading1-semibold" color="gray.900" whiteSpace="nowrap" textAlign="end">
          {`${format(balanceVolume)} MWm`}
        </Text>
        <Flex justifyContent="flex-end" columnGap="1" textStyle="body2-regular" color="gray.500">
          <Flex align="baseline">
            <Text whiteSpace="nowrap">{'C ' + formatPoints(purchaseVolume)}</Text>
          </Flex>
          <Flex align="baseline">
            <Text whiteSpace="nowrap">{'V ' + formatPoints(saleVolume)}</Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        direction="column"
        columnGap="1"
        textStyle="body2-regular"
        color="gray.500"
        justifyContent="space-between"
        height="full"
        flex="1"
      >
        <Text textStyle="subheading1-regular" color="gray.500">
          {getCurrency(avgPurchasePrice)}
        </Text>
        <Text whiteSpace="nowrap">Preço médio C</Text>
      </Flex>
      <Flex
        direction="column"
        columnGap="1"
        textStyle="body2-regular"
        color="gray.500"
        justifyContent="space-between"
        height="full"
        flex="1"
      >
        <Text textStyle="subheading1-regular" color="gray.500">
          {getCurrency(avgSalePrice)}
        </Text>
        <Text whiteSpace="nowrap">Preço médio V</Text>
      </Flex>
      <Flex
        direction="column"
        columnGap="1"
        minWidth="38"
        alignItems="flex-end"
        justifyContent="space-between"
        height="full"
        flex="1"
      >
        <Text textStyle="subheading1-semibold" whiteSpace="nowrap" color={getColor(resultValue)}>
          {getCurrency(resultValue, { maximumFractionDigits: 0 })}
        </Text>
        <Text whiteSpace="nowrap" color="gray.500">
          Marcação a mercado
        </Text>
      </Flex>
      <Flex
        direction="column"
        columnGap="1"
        minWidth="38"
        alignItems="flex-end"
        justifyContent="space-between"
        height="full"
        flex="1"
      >
        <Text textStyle="subheading1-semibold" whiteSpace="nowrap" color={getColor(Number(internalResultValue))}>
          {getCurrency(Number(internalResultValue), { maximumFractionDigits: 0 })}
        </Text>
        <Text whiteSpace="nowrap" color="gray.500">
          Projeção interna
        </Text>
      </Flex>
    </Flex>
  );
}

export default ProductOverview;
