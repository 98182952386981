import styled from 'styled-components';

const isStaging = process.env.REACT_APP_ROLLBAR_ENV === 'staging';

export const Container = styled.div`
  @media screen and (max-width: 600px) {
    .widget__container {
      margin-top: 8px;
    }
  }

  @media screen and (min-width: 600px) {
    grid-template-columns: 2fr 1fr;
    grid-gap: 16px;
    display: grid;
    overflow-x: auto;
    overflow-y: auto;

    grid-template-rows: 1fr;

    height: ${(isStaging) ? `calc(100vh - 116px)` : `calc(100vh - 72px)`};
    width: calc(100vw - 72px);
  }

`;

export const StatusInfo = styled.div``;
