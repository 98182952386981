import { createIcon } from "@tradener/lumen";

const SaleIcon = createIcon({
  displayName: 'SaleIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.62863 5.0713C6.14141 4.86619 6.72339 5.1156 6.9285 5.62839L11.5358 17.1466C11.7034 17.5657 12.2966 17.5657 12.4643 17.1466L17.0715 5.62839C17.2767 5.1156 17.8586 4.86619 18.3714 5.0713C18.8842 5.27641 19.1336 5.85838 18.9285 6.37117L14.3212 17.8894C13.4831 19.9847 10.517 19.9847 9.67883 17.8894L5.07155 6.37117C4.86643 5.85838 5.11585 5.27641 5.62863 5.0713Z" fill="currentColor"/>
    </>
  )
});

export default SaleIcon;
