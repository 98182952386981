import { gql } from '@apollo/client';

import { PeriodTypes, PriceTypes } from './portfolio';

export interface FilterProps {
  operation: 'SALE' | 'PURCHASE';
  periodType: PeriodTypes;
  tenantId?: string;
  accountId?: string;
  priceType?: PriceTypes;
  startDate?: string;
  endDate?: string;
  energyType?: string;
  category?: string;
  submarket?: string;
}

export interface PriceBookProps {
  [key: string]: string | number;
}

export interface QuoteConfigurationDefaultProps {
  readjustmentIndex?: string;
  flexPositive?: number;
  flexNegative?: number;
  loss?: number;
  sazoPercent?: number;
  modulation?: string;
  retusdAmount?: number;
  dueDateType?: string;
  dueDateValue?: string;
  volumeType?: string;
  readjustmentMonth?: string;
  priceBook?: PriceBookProps;
  baseDate?: string;
  expireDate?: string;
}

export interface PayloadProps {
  filter: FilterProps;
}

export interface ResponseProps {
  quoteConfigurationDefault?: QuoteConfigurationDefaultProps;
}

export const QUOTE_CONFIGURATION_DEFAULT = gql`
  query quoteConfigurationDefault($filter: QuoteConfigurationDefaultFilter!) {
    quoteConfigurationDefault(filter: $filter) {
      readjustmentIndex
      flexPositive
      flexNegative
      loss
      sazoPercent
      modulation
      retusdAmount
      dueDateType
      dueDateValue
      volumeType
      readjustmentMonth
      baseDate
      priceBook
      expireDate
    }
  }
`;

export default QUOTE_CONFIGURATION_DEFAULT;
