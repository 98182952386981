import { useCallback, useEffect, useRef } from 'react';

import { useForm, useFormContext } from 'react-hook-form';

import { fastProvider, Flex, FormControl, FormLabel, Popover } from '@tradener/lumen';

import useChannel from '~/hooks/useChannel';

import AutoOptions from './AutoOptions';
import AutoSearch from './AutoSearch';
import CompanyDetails from './CompanyDetails';

export interface OptionProps {
  id: string;
  name: string;
  crmId: string;
  taxId: string;
  entityName: string;
}

export interface FormProps {
  searchFor: string;
  chosenOption?: Partial<OptionProps>;
}

const [withAutocomplete, useAutocomplete] = fastProvider(() => {
  const { setValue: setFilter, watch } = useFormContext();
  const { operation, buyerId, sellerId, buyerName, sellerName } = watch();
  const defaultValues = { searchFor: '', options: [] };
  const { setValue, reset, ...methods } = useForm<FormProps>({ defaultValues });
  const { data, sendMessage, subscribeChannel, unsubscribeChannel } = useChannel<OptionProps[]>('AccountTypeaheadChannel');
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const fieldCompanyId = operation === 'SALE' ? 'buyerId' : 'sellerId';
  const fieldCompanyName = operation === 'SALE' ? 'buyerName' : 'sellerName';
  const companyId = operation === 'SALE' ? buyerId : sellerId;
  const companyName = operation === 'SALE' ? buyerName : sellerName;

  const onConnect = () => {
    subscribeChannel();
  };

  const onClear = () => {
    reset({ searchFor: '' });
    sendMessage('');
    setFilter(fieldCompanyId, '');
    setFilter(fieldCompanyName, '');
  };

  const onSelectOption: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      const accountId = event.currentTarget.value;
      const company = data?.find((option) => option.id === accountId);
      const companyName = company?.name;

      if (companyName) {
        setFilter(fieldCompanyId, accountId);
        setFilter(fieldCompanyName, companyName);
        setValue('searchFor', companyName);
        setValue('chosenOption', {
          id: accountId,
          name: companyName,
          crmId: company.crmId,
          taxId: company.taxId,
          entityName: company.entityName,
        });
        sendMessage('');
      }
    },
    [data, fieldCompanyId, fieldCompanyName, sendMessage, setFilter, setValue],
  );

  useEffect(() => setValue('searchFor', companyName), [buyerName, companyName, operation, sellerName, setValue]);

  return {
    accountId: companyId,
    operation,
    anchorRef,
    data,
    sendMessage,
    subscribeChannel,
    unsubscribeChannel,
    setValue,
    setFilter,
    reset,
    onConnect,
    onClear,
    onSelectOption,
    ...methods,
  };
});

function Autocomplete() {
  const { anchorRef, operation } = useAutocomplete();

  return (
    <FormControl data-testid="modal-company" width="full">
      <FormLabel>{operation === 'SALE' ? 'Comprador' : 'Vendedor'}</FormLabel>
      <Flex flexDirection="column" align="center" justify="center" width="full" gap="2">
        <Popover isOpen initialFocusRef={anchorRef} gutter={4} placement="bottom-start" closeOnBlur closeOnEsc matchWidth isLazy>
          <AutoSearch />
          <AutoOptions />
          <CompanyDetails />
        </Popover>
      </Flex>
    </FormControl>
  );
}

export { useAutocomplete };
export default withAutocomplete(Autocomplete);
