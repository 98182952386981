import { createContext, useContext } from 'react';

import { QuoteProps, Stages } from '@graphql/query/productOperations';

import { compareTwoEntries } from '~/utils/compare';

const Context = createContext({} as QuoteProps);

const useProductQuote = () => {
  const value = useContext(Context);

  const getStageAccess = (stages: Uppercase<Stages>[]) => stages.some((stage) => compareTwoEntries(stage, value.stage, 'i'));

  return { ...value, getStageAccess };
};

export { Context };
export default useProductQuote;
