import { REHYDRATE } from 'redux-persist/lib/constants';
import { fork, all, take } from 'redux-saga/effects';

import { saga as auth } from './ducks/auth';
import { saga as notification } from './ducks/notification';

const effects = [auth, notification];

export default function* sagas() {
  yield take(REHYDRATE);
  yield all([...effects.map((saga) => fork(saga))]);
}
