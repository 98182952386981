import { Outlet, useOutletContext } from 'react-router-dom';

import { Box } from '@material-ui/core';

import Header from '~/pages/Portfolio/PortfolioTable_DEPRECATED/Header';

function Layout() {
  const { staging } = useOutletContext() as { [key: string]: unknown };

  return (
    <>
      <Header top={staging ? '37px' : '0px'} />
      <Box display="flex" flexDirection="column" gridRowGap="12px" marginBottom="20px">
        <Outlet />
      </Box>
    </>
  );
}

export default Layout;
