import { gql } from '@apollo/client';

export interface MutationResponseProps {
  status: string;
}

export interface RenameSimulationProps {
  renameSimulation: MutationResponseProps;
  errors?: string[];
}

export interface InputProps {
  simulationId: string;
  name: string;
}

export interface PayloadProps {
  input: InputProps;
}

export interface ResponseProps {
  renameSimulation: RenameSimulationProps;
}

const RENAME_SIMULATION = gql`
  mutation renameSimulation($input: RenameSimulationInput!) {
    renameSimulation(input: $input) {
      renameSimulation {
        status
      }
      errors
    }
  }
`;

export default RENAME_SIMULATION;
