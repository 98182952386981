/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { createContext, memo, useContext } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useLazyQuery, useQuery } from '@apollo/client';
import { useToast } from '@tradener/lumen';
import { DateTime } from 'luxon';

import { BbceProductProps } from '@graphql/query/bbceProducts';
import FIND_BBCE_PRODUCTS, {
  PayloadProps as ProductPayloadProps,
  ResponseProps as ProductResponseProps,
} from '@graphql/query/findBbceProduct';
import PRODUCT_ANALYTICS, { PayloadProps, ResponseProps } from '@graphql/query/productAnalytics';

import useTrading from '~/pages/Trading/useTrading';

export interface ProductAnalyticsSeriesProps {
  timestamp: number;
  exposure?: number;
  firstPrice?: number;
  lastPrice?: number;
  maxPrice?: number;
  minPrice?: number;
  pldTrend?: number;
  pldTrendHigh?: number;
  quantityMwm?: number;
  operationMwm?: number;
  price?: number;
  type?: string;
}

export interface TradingAnayliticsProps {
  series?: ProductAnalyticsSeriesProps[];
  groupedSeries?: any[];
  loading: boolean;
  product?: BbceProductProps;
}

const Context = createContext({} as unknown as TradingAnayliticsProps);

const useProductAnalytics = () => useContext(Context);

const Provider: React.ComponentType<any> = (props) => {
  const toast = useToast();
  const { id } = useParams() as { [key: string]: string };
  const navigate = useNavigate();
  const { filter, setFilter } = useTrading();

  const [fetchProductAnalytics, { data, loading: loadingAnalytics }] = useLazyQuery<ResponseProps, PayloadProps>(
    PRODUCT_ANALYTICS,
    {
      fetchPolicy: 'no-cache',
      variables: { bbceProductId: id as string, userId: filter.userId },
      onError: () => {
        navigate('/trading/list', { replace: true });

        toast({ title: 'Erro', status: 'error', description: 'Erro ao carregar o produto!' });
      },
    },
  );

  const { data: productData, loading: loadingProduct } = useQuery<ProductResponseProps, ProductPayloadProps>(FIND_BBCE_PRODUCTS, {
    skip: !id,
    fetchPolicy: 'no-cache',
    variables: { id, userId: filter?.userId },
    onCompleted: (data) => {
      const product = data?.findBbceProduct;

      if (!product) {
        toast({ title: 'Problema', description: 'Produto não encontrado!' });

        navigate('/trading/list', { replace: true });

        return;
      }

      if (product && product.name !== filter?.productName) setFilter('productName', product.name);

      fetchProductAnalytics();
    },
    onError: () => {
      toast({ title: 'Erro', status: 'error', description: 'Erro ao carregar o produto!' });

      navigate('/trading/list', { replace: true });
    },
  });

  const analytics = data?.productAnalytics;
  const series = analytics
    ?.map((item) => ({ ...item, timestamp: DateTime.fromISO(item.timestamp).toMillis() }))
    .sort((a, b) => a.timestamp - b.timestamp);
  const product = productData?.findBbceProduct;
  const loading = loadingAnalytics || loadingProduct;

  const value = {
    series,
    loading,
    product,
  };

  return <Context.Provider value={value} {...props} />;
};

const withProductAnalytics = <T,>(Component: React.ComponentType<T>) =>
  memo((props: React.PropsWithChildren<T>) => (
    <Provider {...props}>
      <Component {...props} />
    </Provider>
  ));

export { withProductAnalytics, useProductAnalytics };
