import { Permissions } from '~/context/ducks/auth';

import useAuth from './useAuth';

export type Rules = keyof typeof Permissions;

export type Restriction<T> = T & { rule: Rules };

const useRestriction = () => {
  const { permissions } = useAuth();

  const restrict = <T,>(rule: Rules, data: T) => {
    const isAllowed = permissions.includes(Permissions[rule]);

    return isAllowed ? data : null;
  };

  const withBlocking =
    <T extends {}>(Component: React.ComponentType<T>) =>
    ({ rule, ...props }: React.PropsWithChildren<T & { rule: Rules }>) => {
      const Element = Component as React.ComponentType<T>;

      return restrict(rule, <Element {...(props as T)} />);
    };

  return { withBlocking, restrict };
};

export default useRestriction;
