import { forwardRef } from 'react';

import Dialog from '@DEPRECATED/components/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import useColorMode from '~/hooks/useColorMode';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    color: palette.type === 'dark' ? '#fff' : '#111',
  },
}));

const ConfirmationDialog = forwardRef((props, ref) => {
  const { onClose, open, title, children, ...other } = props;
  const classes = useStyles();
  const { colorTernary } = useColorMode();

  function handleCancel() {
    onClose(false);
  }

  function handleOk() {
    onClose(true);
  }

  return (
    <Dialog maxWidth="xs" open={open} title={title} ref={ref} {...other}>
      {children}
      <DialogActions style={{ backgroundColor: colorTernary('#232323', '#fff') }}>
        <Button
          autoFocus
          onClick={handleCancel}
          classes={{
            root: classes.root,
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleOk}
          classes={{
            root: classes.root,
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
});

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ConfirmationDialog;
