import { gql } from '@apollo/client';

export interface MutationResponseProps {
  status: string;
}

export interface DeleteSimulationProps {
  deleteSimulation: MutationResponseProps;
}

export interface InputProps {
  id: string;
}

export interface PayloadProps {
  input: InputProps;
}

export interface ResponseProps {
  deleteSimulation: DeleteSimulationProps;
  errors?: string[];
}

const DELETE_SIMULATION = gql`
  mutation deleteSimulation($input: DeleteSimulationInput!) {
    deleteSimulation(input: $input) {
      deleteSimulation {
        status
      }
    }
  }
`;

export default DELETE_SIMULATION;
