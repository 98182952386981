import { gql } from '@apollo/client';

export interface TenantProps {
  id: string;
  name: string;
}

export interface ResponseProps {
  tenants: TenantProps[];
}

const TENANTS = gql`
  query tenants {
    tenants {
      id
      name
    }
  }
`;

export default TENANTS;
