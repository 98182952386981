import React from 'react';

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    outline: 0;
    box-sizing: border-box;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance:textfield;
    }
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  #root {
    margin: 0 auto;
  }

  button {
    cursor: pointer;
  }
`;

export default GlobalStyle as React.ComponentType;
