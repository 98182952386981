import { Box, Text, Highlight, PopoverBody, Button, PopoverBodyProps, PopoverContent, Tooltip } from '@tradener/lumen';

import { useAutocomplete } from '..';

export type AutoOptionsProps = Omit<PopoverBodyProps, 'onClose' | 'children'>;

const SearchOptions: React.ComponentType<AutoOptionsProps> = (props) => {
  const { data, anchorRef, isAnalytics, onSelectOption, watch } = useAutocomplete();
  const { searchFor } = watch();

  if (!data?.length || !anchorRef) return null;

  const onClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    onSelectOption(event);
  };

  return (
    <Box sx={{ '& .chakra-popover__popper': { zIndex: 'popover' } }}>
      <PopoverContent width="74" boxShadow="base" overflow="hidden">
        <PopoverBody
          key="menu-list"
          width="74"
          padding="2"
          bgColor="gray.0"
          borderColor="transparent"
          borderRadius="lg"
          boxShadow="base"
          overflow="hidden"
          style={{ maxHeight: '40vh', overflowY: 'auto' }}
          {...props}
        >
          {data?.map((option) => (
            <Button
              key={isAnalytics ? option?.[0] : option}
              value={isAnalytics ? option?.[0] : option}
              display="flex"
              alignItems="center"
              justifyContent="flex-start !important"
              colorScheme="gray"
              bgColor="gray.0"
              width="full"
              size="sm"
              height="8"
              py="2.5"
              px="3"
              overflow="hidden"
              borderRadius="base"
              _hover={{ bgColor: 'gray.100' }}
              onClick={onClick}
            >
              <Tooltip
                label={isAnalytics ? option?.[0] : option}
                placement="top"
                bgColor="gray.900"
                color="gray.0"
                textStyle="body2-regular"
                borderRadius="base"
                maxWidth="full"
                closeOnScroll
                hasArrow
              >
                <Text overflowX="hidden" textOverflow="ellipsis" whiteSpace="nowrap" color="gray.900" textStyle="body2-regular">
                  <Highlight key={option + 'hl'} query={searchFor ?? ''} styles={{ color: 'gray.900', fontWeight: 'bold' }}>
                    {isAnalytics ? option?.[0] : option}
                  </Highlight>
                </Text>
              </Tooltip>
            </Button>
          ))}
        </PopoverBody>
      </PopoverContent>
    </Box>
  );
};

export default SearchOptions;
