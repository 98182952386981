import { DownloadIcon } from '@tradener/lumen';

import OptionItem from '../../OptionItem';
import { useQuoteOptions } from '../../useQuoteOptions';

function ActionDownload() {
  const { proposalUrl } = useQuoteOptions();

  if (!proposalUrl) return null;

  return (
    <OptionItem
      stage={['CLOSED', 'PROPOSED', 'OPENED', 'REJECTED', 'DENIED']}
      onClick={() => proposalUrl && window.open(proposalUrl, '_blank')}
      icon={<DownloadIcon boxSize="5" />}
    >
      Baixar proposta
    </OptionItem>
  );
}

export default ActionDownload;
