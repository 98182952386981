import { useCallback, useMemo, useRef, useReducer, forwardRef } from 'react';

import { toast } from 'react-toastify';

import { Box, BoxProps, Button, Chip, Menu, MenuItem, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { removeKeys } from '@tradener/lumen';

import useColorMode from '~/hooks/useColorMode';
import usePortfolio from '~/pages/Portfolio/usePortfolio';
import ArrowDown from '~/theme/icons/ArrowDown';
import Close from '~/theme/icons/Close';

import useExcludedsTemp from '../hooks/useExcludedsTemp';
import { translate } from './setting';
import useStyles from './styles';

export type BadgesProps = Omit<React.HTMLAttributes<React.PropsWithRef<HTMLElement>>, 'color' | 'css'> | Partial<BoxProps>;

const Badges = forwardRef<HTMLDivElement, BadgesProps>((props, ref) => {
  const { resetField, setFilter, ...state } = usePortfolio();
  const { container, menu, menuItem, arrowDown } = useStyles();
  const { excludedContractsTemp, removeAllExcludedContracts, handleExcludedsContracts } = useExcludedsTemp();
  const { colorTernary, theme } = useColorMode();
  const anchorRef = useRef<null | HTMLElement>(null);
  const [isOpen, toggle] = useReducer((prev) => !prev, false);

  const showApplyButton = useMemo(() => {
    const excludedContractsTempKeys = Object.keys(excludedContractsTemp);
    const keysExcludedContracts = Object.keys(state.excludedContracts);
    const keysExcludedContractsDiff = excludedContractsTempKeys.some((key) => !keysExcludedContracts.includes(key));

    return excludedContractsTempKeys.length !== keysExcludedContracts.length || keysExcludedContractsDiff;
  }, [excludedContractsTemp, state.excludedContracts]);

  const handleApply = useCallback(() => {
    if (showApplyButton) {
      setFilter('excludedContracts', { ...excludedContractsTemp });

      toast.success('Itens da simulação atualizados!');
    } else {
      removeAllExcludedContracts();
    }
  }, [excludedContractsTemp, removeAllExcludedContracts, setFilter, showApplyButton]);

  const badges = useMemo(
    () =>
      Object.entries(
        removeKeys(
          state,
          'startYear',
          'endYear',
          'tenantId',
          'internalAccounts',
          'expandedPeriods',
          'view',
          'excludeds',
          'removeField',
        ),
      )
        .map(([key, value], index) => {
          if (value == undefined) return null;

          if ((Array.isArray(value) && value.length === 0) || (key != 'hasGiro' && !value)) return null;

          if (key === 'excludedContracts') {
            const contractsIdsList = Object.keys(value);

            if (anchorRef === null) return null;

            if (Array.isArray(value)) {
              setFilter('excludedContracts', {} as any);

              return null;
            }

            if (contractsIdsList.length === 0) {
              isOpen && toggle();

              return null;
            }

            return (
              <Box key={index}>
                <Box
                  {...{ ref: anchorRef }}
                  className={container}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  onClick={toggle}
                  aria-controls="excluded-contracts-chip"
                  aria-haspopup="true"
                  borderRadius="12px"
                  bgcolor={theme.palette.primary.main}
                  paddingRight="10px"
                >
                  <Chip size="small" color="primary" label={translate(key, value as string)} style={{ cursor: 'pointer' }} />
                  <ArrowDown className={arrowDown} />
                </Box>
                <Menu
                  id="excluded-contracts-menu"
                  className={menu}
                  anchorEl={anchorRef.current}
                  open={isOpen}
                  elevation={0}
                  onClose={toggle}
                  getContentAnchorEl={null}
                  transitionDuration={0}
                  keepMounted={false}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  {contractsIdsList.map((contractId, index) => (
                    <MenuItem
                      key={index}
                      className={menuItem}
                      onClick={() => handleExcludedsContracts(contractId, value[contractId])}
                    >
                      <Box fontSize="14px">{value[contractId]}</Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        height="100%"
                        style={{ cursor: 'pointer' }}
                        color={colorTernary(theme.palette.grey[200], '#2626263D')}
                      >
                        {contractId in excludedContractsTemp ? (
                          <VisibilityOff fontSize="small" style={{ height: '20px', width: '20px' }} />
                        ) : (
                          <Visibility color="primary" fontSize="small" style={{ height: '20px', width: '20px' }} />
                        )}
                      </Box>
                    </MenuItem>
                  ))}
                  <Button
                    variant={showApplyButton ? 'contained' : 'outlined'}
                    color={showApplyButton ? 'primary' : 'default'}
                    onClick={handleApply}
                    style={{ marginTop: '10px', width: '155px' }}
                  >
                    <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                      <Typography variant="body2" color="inherit">
                        {showApplyButton ? 'Aplicar' : 'Desocultar todos'}
                      </Typography>
                    </Box>
                  </Button>
                </Menu>
              </Box>
            );
          }

          return (
            <Chip
              className={container}
              key={key}
              size="small"
              color="primary"
              label={translate(key, value as string)}
              onDelete={() => resetField(key as any)}
              deleteIcon={<Close />}
            />
          );
        })
        .filter(Boolean) as JSX.Element[],
    [
      arrowDown,
      colorTernary,
      container,
      excludedContractsTemp,
      handleApply,
      handleExcludedsContracts,
      isOpen,
      menu,
      menuItem,
      resetField,
      setFilter,
      showApplyButton,
      state,
      theme.palette.grey,
      theme.palette.primary.main,
    ],
  );

  return (
    <>
      {badges?.length > 0 && (
        <div ref={ref}>
          <Box display="flex" flexDirection="row" flexWrap="wrap" marginBottom={badges.length > 0 ? '2px' : 0} {...props}>
            {badges}
          </Box>
        </div>
      )}
    </>
  );
});

export default Badges;
