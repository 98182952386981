import { useRoutes } from "react-router-dom";

import Extra from './Extra';
import Opportunities from './Opportunities';
import QuoteDetails from './QuoteDetails';
import SendProposal from './SendProposal';

const useOperation = () => {
  const element = useRoutes([
    { path: "opportunities/:opportunityType/", element: <Opportunities /> },
    { path: "opportunities/:opportunityType/:id", element: <Opportunities /> },
    { path: "send-proposal/:id", element: <SendProposal /> },
    { path: "show/:id", element: <QuoteDetails /> },
    { path: "extra/:id", element: <Extra /> },
  ]);

  return element;
};

export default useOperation;
