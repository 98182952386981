import { FormControl, Select, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';

import useColorMode from '~/hooks/useColorMode';

import Period from './Period';
import { Container, FilterButton } from './styles';
import SelectInternalAccounts from '~/DEPRECATED/components/SelectInternalAccounts';

function PortfolioFilter({ onChange, onChangeConversion, currentConversion, period, isMonth }) {
  const { colorTernary } = useColorMode();

  return (
    <Container color={colorTernary('white', '#171717')}>
      <Period period={period} isMonth={isMonth} />
      <SelectInternalAccounts onChange={onChange} />
      <FormControl variant="outlined">
        <Select
          value={currentConversion}
          onChange={({ target: { value } }) => onChangeConversion(value)}
          style={{ width: '125px', height: '40px', textAlign: 'center' }}
        >
          <MenuItem value={false} defaultChecked>
            MWm
          </MenuItem>
          <MenuItem value={true}>R$/MWh</MenuItem>
        </Select>
      </FormControl>
      <FilterButton onClear={() => ({})} />
    </Container>
  );
}

PortfolioFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  isMonth: PropTypes.bool.isRequired,
  period: PropTypes.string.isRequired,
};

export default PortfolioFilter;
