import { Grid, useTheme, Theme, GridProps } from '@tradener/lumen';

const TransferContainer: React.ComponentType<GridProps> = (props) => {
  const { sizes } = useTheme<Theme>();

  return (
    <Grid
      gridTemplateColumns={`
        ${sizes['44']}
        minmax(${sizes['28']}, auto)
        ${sizes['28']}
        ${sizes['18']}
        ${sizes['47']}
        ${sizes['18']}
        ${sizes['32']}
        ${sizes['34']}
        ${sizes['31']}
        ${sizes['14']}
      `}
      {...props}
    />
  );
};

export default TransferContainer;
