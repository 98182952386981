import { memo, useEffect } from 'react';

import { Flex, Collapse } from '@tradener/lumen';

import UPSERT_QUOTE from '@graphql/subscription/upsertQuote';

import { useBbceProduct } from '..';

import client from '~/services/apollo';

import OfferHeader from './OfferHeader';
import OfferSkeleton from './OfferSkeleton';
import OperationList from './OperationList';
import useProductOperations from './useProductOperations';

const withCollapse = <T,>(Component: React.ComponentType<T>) =>
  memo((props: React.PropsWithChildren<T>) => {
    const { isOpen } = useBbceProduct();

    return (
      <Collapse in={isOpen} animateOpacity unmountOnExit>
        <Component {...props} />
      </Collapse>
    );
  });

function ProductOffers() {
  const { id, filter } = useBbceProduct();
  const { data, loading, subscribeToMore } = useProductOperations('cache-and-network');

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: UPSERT_QUOTE,
      updateQuery: (prev, { subscriptionData }: SubscriptionData<any>) => {
        if (!subscriptionData.data) return prev;

        const { upsertQuote } = subscriptionData.data;
        const internalAccount =
          upsertQuote.opportunity.type === 'SALE' ? upsertQuote.opportunity.seller : upsertQuote.opportunity.buyer;

        if (upsertQuote.opportunity.bbceProduct?.id !== id || internalAccount?.id !== filter.internalAccountId) {
          return prev;
        }

        const productOperations = [...prev.productOperations];

        const index = productOperations.findIndex((quote) => quote.id === upsertQuote.id);

        if (index > -1) {
          productOperations.splice(index, 1);
        }

        if (/canceled|succeeded/.test(upsertQuote.stage)) return { productOperations };

        return {
          productOperations: [upsertQuote, ...productOperations],
        };
      },
    });

    return () => {
      unsubscribe();
      client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'productQuotes' });
    };
  }, [subscribeToMore, id, filter.internalAccountId]);

  if (!loading && !data?.productOperations?.length) {
    return (
      <Flex
        width="full"
        align="center"
        justify="center"
        textStyle="body2-regular"
        color="gray.500"
        height="15"
        padding="5"
        transition="fade 1s"
      >
        Você não realizou nenhuma operação.
      </Flex>
    );
  }

  return (
    <Flex direction="column" bgColor="gray.0" maxH="96" overflowY="auto">
      <OfferHeader />
      {loading ? <OfferSkeleton /> : <OperationList productOperations={data?.productOperations} />}
    </Flex>
  );
}

export default withCollapse(ProductOffers);
