import { Flex } from '@tradener/lumen';

import { BbceProductTransferProps, ProductOperationsProps, QuoteProps } from '@graphql/query/productOperations';

import OfferContainer from '../OfferContainer';
import OfferAmount from './OfferAmount';
import OfferChannel from './OfferChannel';
import OfferCode from './OfferCode';
import OfferExpire from './OfferExpire';
import OfferName from './OfferName';
import OfferObservation from './OfferObservation';
import OfferOperation from './OfferOperation';
import OfferOptions from './OfferOptions';
import OfferStage from './OfferStage';
import OfferTrader from './OfferTrader';
import TransferItem from './TransferList';
import { Context } from './useProductQuote';

export interface OperationListProps {
  productOperations?: ProductOperationsProps[];
}

const OperationList: React.ComponentType<OperationListProps> = ({ productOperations }) => {
  const operations = (productOperations ?? []) as ProductOperationsProps[];

  return (
    <>
      {operations?.map((operation, index, array) => {
        if (operation.__typename === 'BbceProductTransfer') {
          return (
            <TransferItem
              key={(operation as BbceProductTransferProps).id ?? index}
              transfer={operation as BbceProductTransferProps}
              hasBorder={index < array.length - 1}
            />
          );
        }

        return (
          <Context.Provider key={(operation as QuoteProps).id ?? index} value={operation as QuoteProps}>
            <Flex direction="column" borderBottom={index < array.length - 1 ? '1px solid' : 'none'} borderBottomColor="gray.200">
              <OfferContainer>
                <OfferOperation />
                <OfferName />
                <OfferCode />
                <OfferChannel />
                <OfferTrader />
                <OfferObservation />
                <OfferStage />
                <OfferExpire />
                <OfferAmount />
                <OfferOptions quote={operation as QuoteProps} />
              </OfferContainer>
            </Flex>
          </Context.Provider>
        );
      })}
    </>
  );
};

export default OperationList;
