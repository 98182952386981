import React, { useState } from 'react';

import { Popover } from '@material-ui/core';
import { Button, ButtonProps, Text, CalendarTodayIcon, theme } from '@tradener/lumen';

import { RangeOutput } from '~/theme/components';
import fastProvider from '~/utils/fastProvider';

export interface DateYearMonthProps {
  startYear: number | undefined;
  startMonth: number | undefined;
  endYear: number | undefined;
  endMonth: number | undefined;
}

export interface MonthRangePopperProps extends Omit<ButtonProps, 'onChange' | 'defaultValue'> {
  onChange: (values: DateYearMonthProps) => void;
  defaultValues?: DateYearMonthProps;
  hasClear?: boolean;
}

const MONTHS_NAMES = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

const [withMonthRange, useMonthRange] = fastProvider(({ onChange, defaultValues, ...props }: MonthRangePopperProps) => {
  const { hasClear, ...restProps } = props;
  const [range, setRange] = useState(
    (defaultValues ?? {
      startYear: undefined,
      startMonth: undefined,
      endYear: undefined,
      endMonth: undefined,
    }) as DateYearMonthProps,
  );

  const onApply = (range: DateYearMonthProps) => {
    setRange(range);
    onChange(range);
  };

  return { hasClear, range, props: restProps, onApply };
});

function MonthRange() {
  const { range, props } = useMonthRange();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onToggle = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  const onClose = () => setAnchorEl(null);

  return (
    <>
      <Button
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        colorScheme="gray"
        bgColor="gray.100"
        borderRadius="base"
        py="2"
        paddingRight="2.5"
        paddingLeft="4"
        _hover={{ bgColor: 'gray.200' }}
        onClick={onToggle}
        rightIcon={<CalendarTodayIcon width="5" height="6" color="gray.900" />}
        {...props}
      >
        {range.startYear && range.startMonth && range.endYear && range.endMonth ? (
          <Text textStyle="body2-regular" colorScheme="gray" color={props?.color ?? 'gray.600'}>
            {`${MONTHS_NAMES[range.startMonth - 1]}/${range.startYear} - ${MONTHS_NAMES[range.endMonth - 1]}/${range.endYear}`}
          </Text>
        ) : (
          <Text textStyle="body1-regular" colorScheme="gray" color="gray.500">
            Selecione
          </Text>
        )}
      </Button>
      {Boolean(anchorEl) && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl as unknown as Element}
          onClose={onClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          style={{ zIndex: theme.zIndices.tooltip, borderRadius: '8px' }}
        >
          <RangeOutput onClose={onClose} />
        </Popover>
      )}
    </>
  );
}

MonthRange.displayName = 'MonthRangePopper';

MonthRange.defaultProps = {
  defaultValues: {
    startYear: undefined,
    startMonth: undefined,
    endYear: undefined,
    endMonth: undefined,
  },
};

export { useMonthRange };
export default withMonthRange(MonthRange);
