import { useState } from 'react';

import fastProvider from '~/utils/fastProvider';
import { getCachedTenants } from '~/utils/getTenants';
import DEFAULT_ACCOUNTS from '~/utils/getDefaultAccounts';

const [withProvider, useProvider] = fastProvider(() => {
  const [internalAccounts, setInternalAccounts] = useState([DEFAULT_ACCOUNTS
    .find(({ name }) => name === 'TRADENER')?.accountId as string].filter(Boolean));
  const tenants = getCachedTenants();
  const [tenantIds, setTenantIds] = useState<string[]>(
    [tenants?.find(({ name }) => name === 'TRADENER')?.id as string].filter(Boolean),
  );


  return {
    internalAccounts,
    allowedInternalAccounts: DEFAULT_ACCOUNTS.filter(({ name }) => /^(tradener|tradener rs|pierp|tradener varejista)$/i.test(name)),
    setInternalAccounts,
    tenants,
    tenantIds,
    allowedTenants: tenants.filter(({ name }) => /^(tradener|pierp|tradener varejista)$/i.test(name)),
    setTenantIds,
  };
});

export { withProvider, useProvider };
