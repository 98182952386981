import { useMemo } from 'react';

import { numberFormat } from '@DEPRECATED/utils/format';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';

import Operation from '../Operation';
import { balanceSubmarket } from '../PortfolioHelper';
import Map from './Map';
import { Container } from './styles';

function Submarket({ data, isAveragePrice }) {
  const roundValue = useMemo(() => (isAveragePrice ? 2 : 1), [isAveragePrice]);

  function parseSubmarket(submarket, round) {
    return {
      balance: numberFormat(submarket.balance, round, round),
      pld: numberFormat(submarket.pld, round, round),
      purchase: numberFormat(submarket.purchase?.averagePrice, round, round),
      sale: numberFormat(submarket.sale?.averagePrice, round, round),
      tooltip: balanceSubmarket(submarket),
    };
  }

  const north = useMemo(() => parseSubmarket(data.n, roundValue), [data.n, roundValue]);
  const northeast = useMemo(() => parseSubmarket(data.ne, roundValue), [data.ne, roundValue]);
  const south = useMemo(() => parseSubmarket(data.s, roundValue), [data.s, roundValue]);
  const southeast = useMemo(() => parseSubmarket(data.se, roundValue), [data.se, roundValue]);

  return (
    <Container>
      <Map north={north} northeast={northeast} south={south} southeast={southeast} isAveragePrice={isAveragePrice} />
    </Container>
  );
}

Submarket.fragments = {
  submarket: gql`
    fragment SubmarketFields on SubmarketField {
      balance
      pld
      balanceCon
      balanceInc
      purchase {
        ...OperationFields
      }
      sale {
        ...OperationFields
      }
    }

    fragment SubmarketGroupFields on Submarket {
      n {
        ...SubmarketFields
      }
      ne {
        ...SubmarketFields
      }
      se {
        ...SubmarketFields
      }
      s {
        ...SubmarketFields
      }
    }
    ${Operation.fragments.operation}
  `,
};

const submarketDefaultValue = {
  purchase: { averagePrice: 0 },
  sale: { averagePrice: 0 },
  pld: 0,
  balance: 0,
};

Submarket.defaultProps = {
  data: {
    n: submarketDefaultValue,
    ne: submarketDefaultValue,
    se: submarketDefaultValue,
    s: submarketDefaultValue,
  },
  isAveragePrice: false,
};

const propTypeSubmarketValue = PropTypes.shape({
  purchase: PropTypes.shape({ averagePrice: PropTypes.number }),
  sale: PropTypes.shape({ averagePrice: PropTypes.number }),
  pld: PropTypes.number,
  balance: PropTypes.number,
});

Submarket.propTypes = {
  data: PropTypes.shape({
    n: propTypeSubmarketValue,
    ne: propTypeSubmarketValue,
    se: propTypeSubmarketValue,
    s: propTypeSubmarketValue,
  }),
  isAveragePrice: PropTypes.bool,
};

export default Submarket;
