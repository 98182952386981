import { ReactComponent as CalendarOff } from '@DEPRECATED/assets/icons/calendar-off.svg';
import { ReactComponent as CalendarOn } from '@DEPRECATED/assets/icons/calendar-on.svg';
import { DatePicker as MuiDatePicker, DatePickerProps as MuIDatePickerProps } from '@material-ui/pickers';

import TextField from '../Input';
import InputAdornment from '../InputAdornment';
import { Container } from './styles';

interface DatePickerProps extends MuIDatePickerProps {
  error?: boolean;
  disabled?: boolean;
}

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const { error, disabled } = props;

  return (
    <Container error={!!error}>
      <MuiDatePicker
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{disabled ? <CalendarOff /> : <CalendarOn />}</InputAdornment>
          ),
        }}
        inputVariant="outlined"
        size="small"
        TextFieldComponent={TextField}
        {...props}
      />
    </Container>
  );
};

DatePicker.defaultProps = {
  disabled: false,
  error: false,
} as Pick<DatePickerProps, 'disabled' | 'error'>;

export default DatePicker;
