import { Flex, FlexProps } from '@tradener/lumen';

const TransferItem: React.ComponentType<FlexProps> = ({ children, ...props }) => (
  <Flex
    align="center"
    justify="space-between"
    height="14"
    padding="4"
    color="gray.900"
    whiteSpace="nowrap"
    textStyle="body2-regular"
    cursor="default"
    {...props}
  >
    {children}
  </Flex>
);

export default TransferItem;
