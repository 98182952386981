import { useRef } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select, { SelectProps } from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';

type IMultipleSelectOptions = {
  value: string | number;
  label: string | number;
  disabled?: boolean;
};

interface IMultipleSelect extends SelectProps {
  options: IMultipleSelectOptions[];
  label?: string;
}

const MultipleSelect: React.FC<IMultipleSelect> = ({ name, label, options, value, inputProps, variant, error, ...rest }) => {
  const ref = useRef(null);
  const classes = useStyles();

  const props = {
    ...rest,
    value: [],
    name,
    inputProps: {
      ...inputProps,
      ref,
      name,
      id: name,
      'aria-label': name,
    },
  };

  return (
    <div>
      <FormControl error={!!error} variant={variant} size="small" fullWidth className={classes.formControl}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Select
          {...props}
          name={name}
          value={value}
          multiple
          renderValue={(selected) => {
            const fields = options.filter((option) => (selected as string).includes(String(option.value)));

            return <Typography variant="body2">{fields.map((field) => field.label).join(', ')}</Typography>;
          }}
        >
          {options.map(({ value: optionValue, label: optionLabel, disabled = false }) => (
            <MenuItem classes={{ root: classes.menuItem }} key={optionLabel} value={optionValue} disabled={disabled}>
              <Checkbox
                size="small"
                color="secondary"
                checked={(value as React.ReactText[]).indexOf(optionValue) > -1}
              />
              <ListItemText className={classes.listItemText} primary={optionLabel} />
            </MenuItem>
          ))}
        </Select>

        {!!error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </div>
  );
};

MultipleSelect.defaultProps = {
  label: '',
  name: undefined,
  error: false,
  value: [],
  variant: 'outlined',
  options: [],
  inputProps: {},
};

export default MultipleSelect;
