import { Chip } from '@DEPRECATED/components/Form';
import PropTypes from 'prop-types';

import { Container } from './styles';

function Resume({ children, ...props }) {
  return (
    <Container>
      <div>
        <Chip {...props} />
        <strong>{children}</strong>
      </div>
    </Container>
  );
}

Resume.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Resume;
