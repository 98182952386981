import { forwardRef } from 'react';

import useColorMode from '~/hooks/useColorMode';
import { PLDStatus, TableCellEdition } from '~/pages/Portfolio/Simulation/SimulationTable/Table';

import { useOperation, useOperationInput } from '../../Operation';
import { useSimulationOperations } from '../../withGuarantee';

export interface OperationEditionProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
  onSubmit: (value: string) => void;
  transform: (value: string) => string;
  onDelete: React.MouseEventHandler<HTMLElement>;
  value: string;
}

const OperationEdition = forwardRef<HTMLDivElement, OperationEditionProps>(({ value, ...props }, ref) => {
  const id = useSimulationOperations(({ operation }) => operation.id);
  const { period } = useOperationInput();
  const { hidden } = useOperation();
  const { colorTernary } = useColorMode();

  return (
    <TableCellEdition
      ref={ref}
      isEditable={/-/.test(period)}
      getStatus={(defaultValue) => PLDStatus[value !== defaultValue ? 'TOUCHED' : 'INITIAL']}
      style={{ color: hidden.includes(id) ? colorTernary('#AAA', '#CCC') : 'inherit' }}
      render={(value) => <>{value}</>}
      value={value}
      {...props}
    />
  );
});

export default OperationEdition;
