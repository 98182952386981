import { memo } from 'react';

import { useNavigate } from 'react-router-dom';

import { Flex, Theme, useTheme } from '@tradener/lumen';
import { AreaChart, Area } from 'recharts';

import { useBbceProduct } from '..';

import createDynamicRange from '../createDynamicRange';

function ProductAreaChart() {
  const { colors } = useTheme<Theme>();
  const navigate = useNavigate();
  const { id, isAnalytics, priceHistory, lastPriceVariation } = useBbceProduct();

  const data = priceHistory?.map(([date, price = 0]) => ({ date, price }));

  const color = createDynamicRange(colors.red[500], colors.gray[400], colors.green[500])(lastPriceVariation);

  const handleClick = () => {
    if (isAnalytics) return;

    navigate(`${id}/chart`);
  };

  return (
    <Flex
      align="center"
      borderWidth=".0625rem"
      borderRadius="lg"
      height="full"
      borderColor={`${colors.gray[900]}1F`}
      overflow="hidden"
      cursor={isAnalytics ? 'default' : 'pointer'}
      onClick={handleClick}
    >
      <AreaChart width={120} height={40} data={data} margin={{ top: 0, bottom: 0, left: 0, right: 0 }}>
        <defs>
          <linearGradient id={`colorPrice.${id}`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={color} stopOpacity={1} />
            <stop offset="90%" stopColor={color} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          type="basis"
          dataKey="price"
          stroke={color}
          strokeWidth="2"
          fill={`url(#colorPrice.${id})`}
          cursor={isAnalytics ? 'default' : 'pointer'}
        />
      </AreaChart>
    </Flex>
  );
}

export default memo(ProductAreaChart);
