import InfiniteScroll from 'react-infinite-scroll-component';
import { Outlet } from 'react-router-dom';

import { As, Flex } from '@tradener/lumen';

import { usePagination } from '../../useTrading';
import ListItem from './ListItem';
import useProducts, { withProductsContext } from './useProducts';

export interface ProductsContextProps {
  isExpanded: string | undefined;
  offset: number;
  onUnfold: (id: string) => void;
  onFold: () => void;
}

function ListProducts() {
  const { setOffset } = usePagination();
  const { data, offset } = useProducts();
  const pinneds = [...new Set(data?.bbceProducts?.pinneds)] ?? [];
  const products = [...new Set(data?.bbceProducts?.products)] ?? [];

  const onLoadMore = () => {
    if (offset > 0) setOffset(offset);
  };

  return (
    <Flex direction="column" rowGap="10" width="fit-content">
      <Flex direction="column" rowGap="2" hidden={!data?.bbceProducts?.pinneds?.length}>
        {pinneds?.map((product) => (
          <ListItem key={product.id} {...product} />
        ))}
      </Flex>
      <Flex
        as={InfiniteScroll as unknown as As}
        dataLength={offset}
        hasMore={offset % 50 === 0}
        next={onLoadMore}
        direction="column"
        rowGap="2"
      >
        {products?.map((product) => (
          <ListItem key={product.id} {...product} />
        ))}
      </Flex>
      <Outlet />
    </Flex>
  );
}

export default withProductsContext(ListProducts);
