import { gql } from '@apollo/client';

import { PRODUCT_FRAGMENT, BbceProductProps } from '../query/bbceProducts';

export interface SubscriptionBbceProductFilterProps {
  endDate: string;
  startDate: string;
  restrictIntervalDate?: boolean;
  tenantId?: string;
  internalAccountId?: string;
  userId?: string;
}

export interface PayloadProps {
  filter: SubscriptionBbceProductFilterProps;
}

export interface ResponseProps {
  updateBbceProduct: BbceProductProps;
}

const UPDATE_BBCE_PRODUCTS = gql`
  ${PRODUCT_FRAGMENT}

  subscription updateBbceProduct($filter: SubscriptionBbceProductFilter!) {
    updateBbceProduct(filter: $filter) {
      ...product
    }
  }
`;

export default UPDATE_BBCE_PRODUCTS;
