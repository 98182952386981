import PropTypes from 'prop-types';

import { Container } from './styles';

function Option({ name, entityName, taxId }) {
  return (
    <Container>
      <strong>Conta: {name}</strong>
      <span>Razão Social: {entityName}</span>
      <span>CNPJ: {taxId}</span>
    </Container>
  );
}

Option.defaultProps = {
  entityName: null,
  taxId: null,
};

Option.propTypes = {
  name: PropTypes.string.isRequired,
  entityName: PropTypes.string,
  taxId: PropTypes.string,
};

export default Option;
