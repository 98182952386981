import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.li`
  display: flex;

  align-self: center;

  flex: 1;

  align-items: flex-start;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    gap: 0.5rem;
    margin-right: 0.5vw;
  }


  div strong {
    width: 100%;
    display: flex;
    flex-direction: row !important;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: center;
    flex: 1;

    label {
      align-self: stretch;
      width: 100%;
    }
  }
`;
