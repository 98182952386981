import { forwardRef } from 'react';

import { makeStyles } from '@material-ui/core';
import TextFieldRaw from '@material-ui/core/TextField';
import styled from 'styled-components';

export const Container = styled.div`
  width: 116px;

  @media screen and (min-width: 600px) {
    width: 150px;
  }
`;

const useStyles = makeStyles({
  root: {
    width: '140px',
    '& > .MuiOutlinedInput-root': {
      borderRadius: '2px',
      borderWidth: '2px',
      height: '40px',
    },
  },
  adornedEnd: {
    paddingRight: '1px',
    '& > .MuiInputAdornment-root': {
      borderRadius: '0 2px 2px 0',
      padding: '20px 10px',
    },
  },
});

export const TextField = forwardRef((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { InputProps } = props;
  const classes = useStyles();

  return (
    <TextFieldRaw
      classes={{
        root: classes.root,
      }}
      {...props}
      InputProps={{
        classes: {
          adornedEnd: classes.adornedEnd,
        },
        ...InputProps,
      }}
      inputRef={ref}
    />
  );
});
