import { DateTime } from 'luxon';

import { DateYearMonthProps } from '~/theme/components/MonthRange';

const calculateMonthRange = ({ startYear, startMonth, endYear, endMonth }: DateYearMonthProps) => {
  const list: Array<string> = [];
  const startDate = DateTime.fromObject({ year: startYear, month: startMonth });
  const endDate = DateTime.fromObject({ year: endYear, month: endMonth });

  let currentMonth = startDate;

  while (currentMonth <= endDate) {
    list.push(currentMonth.toFormat('yyyy-MM'));
    currentMonth = currentMonth.startOf('month').plus({ months: 1 });
  }

  return list;
};

export default calculateMonthRange;
