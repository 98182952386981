import { useLocation } from 'react-router-dom';

function useQueryParam<T extends string>(...params: T[]) {
  const location = useLocation();
  const url = new URLSearchParams(location.search);
  const queries = {} as { [key in T]: string | null };

  for (const param of params) {
    queries[param as T] = url.get(param);
  }

  return queries;
}

export default useQueryParam;
