import { Box } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Skeleton } from '@material-ui/lab';
import { parseSafeFloat } from '@tradener/lumen';

import useColorMode from '~/hooks/useColorMode';
import { PLDStatus, TableCellEdition, TableCellEditionProps } from '~/pages/Portfolio/Simulation/SimulationTable/Table';
import { useSimulationTable } from '~/pages/Portfolio/Simulation/SimulationTable/withSimulationTable';
import { typography } from '~/theme';
import ShowChart from '~/theme/icons/ShortChart';

import { useExposure } from '../../SimulationExposure';

export interface TablePLDProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
  emphasis?: boolean;
  isEditable: boolean;
  value: string;
  onSubmit: TableCellEditionProps['onSubmit'];
  onDelete: React.MouseEventHandler<HTMLButtonElement>;
  edited: PLDStatus;
}

const TablePLDColumn: React.ComponentType<TablePLDProps> = ({ edited, ...props }) => {
  const { getNumberFormat } = useExposure();
  const { colorTernary } = useColorMode();
  const { loading } = useSimulationTable();

  const transform = (value: string) => getNumberFormat(parseSafeFloat(value)!);

  return (
    <TableCellEdition
      getStatus={() => edited}
      transform={transform}
      render={(value) => (
        <>
          {loading ? (
            <Skeleton width={58} height={19} />
          ) : (
            <Box
              display="flex"
              fontWeight={600}
              bgcolor={colorTernary(grey[800], 'rgba(38, 38, 38, 0.08)')}
              color={grey[colorTernary(300, 800)]}
              padding="2px 6px"
              borderRadius="4px"
              gridColumnGap="6px"
              style={{ pointerEvents: 'none' }}
              {...typography.body2}
            >
              <ShowChart width="18px" height="18px" />
              <span>{transform(value)}</span>
            </Box>
          )}
        </>
      )}
      {...props}
    />
  );
};

export default TablePLDColumn;
