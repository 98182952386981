import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  pickedSubmarket: {
    padding: '6px 4px',
    height: '36px',
  },
  notPickedSubmarket: {
    padding: '6px 4px',
    height: '36px',
    backgroundColor: 'transparent',
    color: `${theme.palette.type === 'dark' ? '#CCC' : '#868686'}`,
  },
  bodyInfinitescroll: {
    display: 'grid',
    position: 'sticky',
    top: '0',
    color: `${theme.palette.type === 'dark' ? '#CCC' : '#868686'}`,
    backgroundColor: `${theme.palette.type === 'dark' ? theme.palette.grey[800] : '#FFF'}`,
    width: 'fit-content',
  },
  columnContract: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '60px',
    paddingBlock: '9.5px',
    paddingLeft: '0',
    paddingRight: '12px',
    position: 'sticky',
    left: '0',
    backgroundColor: `${theme.palette.type === 'dark' ? theme.palette.grey[800] : '#FFF'}`,
  },
  columnFactors: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '15px',
    backgroundColor: `${theme.palette.type === 'dark' ? theme.palette.grey[800] : '#FFF'}`,
  },
}));

export default useStyles;
