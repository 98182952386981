import Energy from '@DEPRECATED/assets/images/energy_for_all.svg';
import Logo from '@DEPRECATED/assets/images/storm_white.svg';
import Tradener from '@DEPRECATED/assets/images/tradener.svg';
import { useGoogleLogin } from '@react-oauth/google';
import { Button, Flex } from '@tradener/lumen';

import useAuth from '~/hooks/useAuth';
import StagingBar from '~/theme/components/StagingBar';
import GoogleIcon from '~/theme/icons/Google';

import { Container, Content, Footer } from './styles';

const isStaging = process.env.REACT_APP_ROLLBAR_ENV === 'staging';

export default function SignIn() {
  const { signIn } = useAuth();

  const login = useGoogleLogin({
    onSuccess: signIn,
  });

  const handleLogin = () => {
    login();
  };

  return (
    <Container>
      <Content>
        {isStaging && <StagingBar />}
        <img src={Logo} alt="Storm Logo" />
        <div>
          <Button
            pl="0"
            onClick={handleLogin}
            leftIcon={
              <Flex boxSize="10" align="center" justify="center" bgColor="white" borderRadius="sm">
                <GoogleIcon />
              </Flex>
            }
            colorScheme="blue"
          >
            Entrar usando google
          </Button>
        </div>
      </Content>
      <Footer>
        <img src={Tradener} alt="Tradener Logo" />
        <img src={Energy} alt="Energy for all" />
      </Footer>
    </Container>
  );
}
