import { DateTime } from 'luxon';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { BbceProductFilterProps } from '@graphql/query/bbceProducts';

import cache from '~/services/link/cache';

export interface TradingPaginationHookProps {
  limit: number;
  offset: number;
  setLimit: (value: number) => void;
  setOffset: (value: number) => void;
}

export interface TradingHookProps {
  filter: BbceProductFilterProps;
  setFilter: <Key extends keyof BbceProductFilterProps, Payload extends BbceProductFilterProps[Key]>(
    key: Key,
    payload: Payload,
  ) => void;
  setValues: <S extends BbceProductFilterProps>(prev: Partial<S> | ((prev: BbceProductFilterProps) => Partial<S>)) => void;
  clearFields: <Keys extends keyof BbceProductFilterProps>(...keys: Keys[]) => void;
}

const dateNow = DateTime.now().setLocale('pt-BR');

const usePagination = create(
  immer<TradingPaginationHookProps>((set) => ({
    limit: 50,
    offset: 0,
    setLimit: (limit) => set({ limit }),
    setOffset: (offset) => set({ offset }),
  })),
);

const useTrading = create(
  persist(
    immer<TradingHookProps>((set) => ({
      filter: {
        startDate: dateNow.startOf('year').toISODate(),
        endDate: dateNow.endOf('year').toISODate(),
      },
      setFilter: (key, payload) => {
        if (!/pinnedIds/.test(key)) cache.evict({ id: 'ROOT_QUERY', fieldName: 'bbceProducts', broadcast: false });

        usePagination.setState({ offset: 0 });
        set((state) => {
          state.filter[key] = payload;
        });
      },
      setValues(getState) {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'bbceProducts', broadcast: false });

        usePagination.setState({ offset: 0 });
        set((state) => {
          const current = getState instanceof Function ? getState(state.filter) : getState;

          for (const key in current) {
            state.filter[key as keyof typeof state] = current[key];
          }
        });
      },
      clearFields(...keys) {
        set((state) => {
          for (const key of keys) state.filter[key] = undefined;
        });
      },
    })),
    { name: 'TRADING' },
  ),
);

export { usePagination };
export default useTrading;
