import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  table: {
    display: 'block',
    width: '100%',
    overflow: 'auto',
    height: '35vh',
    ['@media (max-height:434px)']: {
      height: '20vh',
    },
  },
  tableCellHead: {
    fontSize: 14,
    border: 0,
    height: '40px',
    backgroundColor: theme.palette.background.paper,
    padding: 0,
  },
  tableCellBody: {
    fontSize: 14,
    height: '40px',
    color: '#868686',
    borderTop: '1px solid #e0e0e0',
    borderBottom: 'none',
    padding: 0,
  },
  tableRow: {
    margin: 0,
    paddingRight: '10px',
    '&$hover:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '& td:last-child > button': {
      visibility: 'hidden',
    },
    '&:hover td:last-child > button': {
      visibility: 'visible',
    },
  },
  iconButton: {
    '$hover:hover &': {
      visibility: 'visible !important',
    },
  },
  switch: {
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'tranpxslateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        left: 12,
      },
      '&:after': {
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
        },
      },
    },
  },
}));
