import { gql } from '@apollo/client';

import { PriceTypes, PeriodTypes } from '@graphql/query/portfolio';

import { EnergyTypes } from './contracts';

export type OperationTypes = 'PURCHASE' | 'SALE';

export type SubmarketTypes = 'SE' | 'S' | 'NE' | 'N';

export type AccountingPortfolioTypes = 'TRADING' | 'WHOLESALE';

export interface SimulationContractProps {
  id: string;
  contractNumber: string;
  submarket: SubmarketTypes;
  accountName: string;
  energyType: string;
  usageFactor: number | null;
  energyTypeUsageFactor: number | null;
  crmId: string;
  appliedUsageFactor: number | null;
  simulationItems: SimulationItemProps[];
}

export interface SimulationItemProps {
  id?: string;
  simulationItemId?: string;
  competence: string;
  operation: OperationTypes;
  submarket: SubmarketTypes;
  periodType?: PeriodTypes;
  energyType: EnergyTypes;
  priceType: PriceTypes;
  price: number;
  quantityMwm: number;
  oldPrice: number;
  oldQuantityMwm: number;
  totalAmount: number;
  contractUsageFactor?: number;
  usageFactor?: number;
  edited: boolean;
}

export interface PayloadProps {
  simulationId: string;
  operation: OperationTypes;
  startYear: string;
  tenantId?: string[];
  internalAccounts?: string[];
  submarket?: SubmarketTypes;
  search?: string;
  endYear?: string;
  energyType?: string[];
  periodType?: PeriodTypes;
  priceType?: PriceTypes[];
  expandedPeriods?: string[];
  usageFactorByContract?: boolean;
  usageFactorByEnergyType?: boolean;
  usageFactor?: boolean;
  giro?: string;
  hasGiro?: boolean;
  contractFlags?: Array<string>;
  accountingPortfolio?: AccountingPortfolioTypes;
  closeDateLte?: string;
  closeDateGte?: string;
  accountTypes?: Array<string>;
  limit?: number;
  offset?: number;
}

export interface ResponseProps {
  simulationContracts: SimulationContractProps[];
}

const SIMULATION_CONTRACTS = gql`
  query simulationContracts(
    $search: String
    $operation: ContractOperation!
    $simulationId: ID!
    $submarket: SubmarketEnum
    $startYear: String!
    $expandedPeriods: [String!]
    $tenantId: [ID!]
    $internalAccounts: [ID!]
    $endYear: String
    $energyType: [String!]
    $periodType: QuotePeriod
    $priceType: [QuotePrice!]
    $usageFactorByContract: Boolean
    $usageFactorByEnergyType: Boolean
    $usageFactor: Boolean
    $giro: String
    $hasGiro: Boolean
    $contractFlags: [String!]
    $accountingPortfolio: ContractPortfolio
    $closeDateLte: ISO8601Date
    $closeDateGte: ISO8601Date
    $accountTypes: [String!]
    $limit: Int
    $offset: Int
  ) {
    simulationContracts(
      search: $search
      operation: $operation
      simulationId: $simulationId
      submarket: $submarket
      startYear: $startYear
      expandedPeriods: $expandedPeriods
      tenantId: $tenantId
      internalAccounts: $internalAccounts
      endYear: $endYear
      energyType: $energyType
      periodType: $periodType
      priceType: $priceType
      usageFactorByContract: $usageFactorByContract
      usageFactorByEnergyType: $usageFactorByEnergyType
      usageFactor: $usageFactor
      giro: $giro
      hasGiro: $hasGiro
      contractFlags: $contractFlags
      accountingPortfolio: $accountingPortfolio
      closeDateLte: $closeDateLte
      closeDateGte: $closeDateGte
      accountTypes: $accountTypes
      limit: $limit
      offset: $offset
    ) {
      id
      accountName
      contractNumber
      energyType
      submarket
      usageFactor
      energyTypeUsageFactor
      crmId
      appliedUsageFactor
      simulationItems {
        id
        simulationItemId
        competence
        quantityMwm
        price
        oldPrice
        oldQuantityMwm
        totalAmount: amountTotal
        edited
        usageFactor
      }
    }
  }
`;

export default SIMULATION_CONTRACTS;
