import { gql } from '@apollo/client';

import { OPERATION_LIMITS_TYPE } from '~/pages/OperationLimits/types/OperationTypes';

export interface PayloadProps {
  filter: {
    type: OPERATION_LIMITS_TYPE;
  };
}

export interface ResponseProps {
  downloadOperationLimits: {
    downloadUrl?: string;
  };
}

const DOWNLOAD_OPERATION_LIMITS = gql`
  query downloadOperationLimits($filter: DownloadOperationLimitsFilter!) {
    downloadOperationLimits(filter: $filter) {
      downloadUrl
    }
  }
`;

export default DOWNLOAD_OPERATION_LIMITS;
