import { Controller } from 'react-hook-form';

import { Checkbox, Flex, Text } from '@tradener/lumen';

import useComplementForm from '../useComplementForm';

function PickSwap() {
  const { control } = useComplementForm();

  return (
    <Flex align="flex-start" gap="3">
      <Controller
        control={control}
        name="swap"
        render={({ field: { ref: _, onChange, value, ...field } }) => (
          <Checkbox
            marginTop="0.5"
            onChange={(event) => onChange(event.target.checked)}
            value={String(value)}
            isChecked={Boolean(value)}
            {...field}
          />
        )}
      />
      <Text textStyle="body2-regular">Swap</Text>
    </Flex>
  );
}

export default PickSwap;
