function format(value = 0.0) {
  return parseFloat(value.toFixed(1));
}

function calculateBalance({ purchase, sale }, type) {
  let purchaseValue = purchase.quantityMwm;
  let saleValue = sale.quantityMwm;

  if (type !== 'total') {
    purchaseValue = purchase[type]?.quantityMwm;
    saleValue = sale[type]?.quantityMwm;
  }

  return format(format(purchaseValue) - format(saleValue));
}

function balanceEnergyTypeGroup(data) {
  return {
    inc: calculateBalance(data, 'inc'),
    con: calculateBalance(data, 'con'),
    total: format(data.balanceMwm),
  };
}

function balanceByEnergyTypeGroup({ purchase, sale }, energyTypeGroup) {
  const energyType = energyTypeGroup === 'con' ? ['c', 'ine5', 'ine8'] : ['i0', 'i1', 'i5', 'i8'];

  const newData = {
    purchase: {
      quantityMwm: purchase[energyTypeGroup]?.quantityMwm,
      ...purchase[energyTypeGroup]?.energyType,
    },
    sale: {
      quantityMwm: sale[energyTypeGroup]?.quantityMwm,
      ...sale[energyTypeGroup]?.energyType,
    },
  };

  const data = energyType.reduce((memo, type) => {
    memo[type] = calculateBalance(newData, type);

    return memo;
  }, {});

  data.total = calculateBalance(newData, 'total');

  return data;
}

function balanceEnergyBallast({ energyBallast }) {
  return {
    inc: format(energyBallast.balanceInc),
    con: format(energyBallast.balanceCon),
    total: format(energyBallast.balance),
  };
}

function balanceSubmarket(submarket) {
  return {
    inc: format(submarket.balanceInc),
    con: format(submarket.balanceCon),
    total: format(submarket.balance),
  };
}

function listEnergyTypeGroupByOperation(payload, operation) {
  const conEnergyType = ['c', 'ine5', 'ine8'];
  const incEnergyType = ['i0', 'i1', 'i5', 'i8'];

  const conList = conEnergyType.reduce((memo, type) => {
    memo[type] = format(payload[operation]?.con?.energyType[type]?.quantityMwm);

    return memo;
  }, {});

  const incList = incEnergyType.reduce((memo, type) => {
    memo[type] = format(payload[operation]?.inc?.energyType[type]?.quantityMwm);

    return memo;
  }, {});

  conList.total = format(payload[operation]?.con?.quantityMwm);
  incList.total = format(payload[operation]?.inc?.quantityMwm);

  return {
    con: conList,
    inc: incList,
  };
}

export {
  balanceSubmarket,
  balanceEnergyBallast,
  balanceEnergyTypeGroup,
  balanceByEnergyTypeGroup,
  listEnergyTypeGroupByOperation,
};
