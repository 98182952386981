import { updateFieldsVolume } from '@DEPRECATED/pages/Book/BookList/Opportunities/Offer/Price/reducer';
import { convertMWhToMWm, convertMWmToMWh, removeKeysEmpty, removeTypename } from '@DEPRECATED/utils';
import { setMonth, format, getMonth, endOfMonth, getYear } from 'date-fns';
import produce from 'immer';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import merge from 'lodash/merge';
import { v4 as uuid } from 'uuid';

import { INITIAL_CONTEXT } from './context';

function parseDate(date) {
  return format(date, 'yyyy-MM-dd');
}

export function injectDefaultValues(context, defaultValues) {
  const configuration = { ...defaultValues };
  const data = {};

  function getDefaultsValues(field, value) {
    const path = `${field}`;
    const { readjustmentIndex, modulation, dueDateType, dueDateValue, readjustmentMonth, baseDate } = configuration;

    if (field === 'readjustmentIndex' && readjustmentIndex) {
      configuration.readjustmentIndex = readjustmentIndex.toUpperCase();
    }

    if (field === 'readjustmentMonth' && !(readjustmentMonth instanceof Date)) {
      configuration.readjustmentMonth = endOfMonth(setMonth(new Date(), readjustmentMonth - 1));
    }

    if (field === 'modulation' && modulation) {
      configuration.modulation = modulation.toUpperCase();
    }

    if ((field === 'dueDateType' && dueDateType) || (field === 'dueDateValue' && dueDateValue)) {
      configuration.dueDateType = 'BUSINESS_DAY';
      configuration.dueDateValue = 6;
    }

    if (field === 'flexPositive' && context.opportunity.category === 'TRD_PLUS') {
      if (context.quoteItems.length > 0 && context.quoteItems[0].volume.MWm > 0.5) {
        configuration.flexPositive = 50;
      }
    }

    if (field === 'baseDate' && typeof baseDate === 'string') {
      configuration.baseDate = new Date(`${baseDate}T00:00:00-03:00`);
    }

    return get(configuration, path, value);
  }

  const modifiedFields = keys(context.options.edited).filter((field) => context.options.edited[field]);

  const ignoreFields = ['modulation', 'sazoPercent', 'flexPositive', 'flexNegative', 'loss'];

  const notModifiedFields = keys(configuration).filter((field) => {
    if (context.options.edited.modulation && context.periodType === 'LONG') {
      return !modifiedFields.includes(field) && !ignoreFields.includes(field);
    }

    return !modifiedFields.includes(field);
  });

  notModifiedFields.forEach((key) => {
    data[key] = getDefaultsValues(key, configuration[key] ?? '');
  });

  modifiedFields.forEach((key) => {
    data[key] = context[key];
  });

  return data;
}

function parseQuoteItemToServer(quoteItem, volumeType) {
  return {
    amount: parseFloat(quoteItem.amount),
    requestedAmount: parseFloat(quoteItem.requestedAmount),
    spreadAmount: parseFloat(quoteItem.spreadAmount),
    quantity: parseFloat(quoteItem.volume[volumeType]),
    startDate: parseDate(quoteItem.startDate),
    endDate: parseDate(quoteItem.endDate),
    group: quoteItem.group,
  };
}

export function transform(data) {
  removeTypename(data);
  const prevState = removeKeysEmpty(data);

  return produce(prevState, (draftState) => {
    delete draftState.readOnly;
    delete draftState.offerCode;
    delete draftState.accountName;
    delete draftState.auditLogs;
    delete draftState.emailMessages;
    delete draftState.priceBook;
    delete draftState.quoteInformationTypes;

    draftState.opportunity.buyerId = draftState.opportunity.buyer.id;
    draftState.opportunity.sellerId = draftState.opportunity.seller.id;

    delete draftState.opportunity.tenant;
    delete draftState.opportunity.account;
    delete draftState.opportunity.buyer;
    delete draftState.opportunity.seller;

    draftState.product.unit = draftState.volumeType;

    const quoteItems = [];

    draftState.quoteItems.forEach((quoteItem) => {
      if (quoteItem?.subItems?.length) {
        quoteItem.subItems.forEach((item) => {
          quoteItems.push(
            parseQuoteItemToServer(
              {
                ...item,
                amount: quoteItem.amount,
                requestedAmount: quoteItem.requestedAmount,
                spreadAmount: quoteItem.spreadAmount,
                group: `${getYear(quoteItem.startDate)}-${quoteItem.group}`,
              },
              draftState.volumeType,
            ),
          );
        });
      } else {
        quoteItems.push(parseQuoteItemToServer(quoteItem, draftState.volumeType));
      }
    });

    draftState.quoteItems = quoteItems;

    draftState.quoteInformation = draftState.quoteInformation
      .filter((information) => !information.deleted)
      .map(({ type, name, template, value }, index) => ({ type, name, template, value, order: index + 1 }));

    draftState.readjustmentMonth = getMonth(draftState.readjustmentMonth) + 1;

    draftState.startDate = parseDate(draftState.startDate);
    draftState.endDate = parseDate(draftState.endDate);

    delete draftState.options;
  });
}

function parseQuoteItemFromServer(data) {
  function parseItem(item) {
    let MWh = item.quantity;
    let MWm = item.quantity;

    const startDate = new Date(item.startDate);
    const endDate = new Date(item.endDate);

    if (item.product.unit === 'MWh') {
      MWm = convertMWhToMWm({
        value: item.quantity,
        endDate,
        startDate,
      });
    } else {
      MWh = convertMWmToMWh({
        value: item.quantity,
        endDate,
        startDate,
      });
    }

    return {
      id: uuid(),
      startDate,
      endDate,
      amount: parseFloat(item.amount).toFixed(2),
      requestedAmount: item.requestedAmount && parseFloat(item.requestedAmount).toFixed(2),
      spreadAmount: item.spreadAmount && parseFloat(item.spreadAmount).toFixed(2),
      volume: { MWh, MWm },
    };
  }

  if (!data.quoteItems.length) return;
  data.product = data.quoteItems[0].product;

  let quoteItems = [];

  const groupedQuoteItems = groupBy(data.quoteItems, (item) => item.group);

  keys(groupedQuoteItems).forEach((key) => {
    if (key === 'null') {
      quoteItems = groupedQuoteItems[key].map(parseItem);

      return;
    }
    const [, group] = key.split('-');

    const firstItem = groupedQuoteItems[key][0];
    const lastItem = groupedQuoteItems[key][groupedQuoteItems[key].length - 1];

    const quoteItem = {
      ...parseItem({
        startDate: firstItem.startDate,
        endDate: lastItem.endDate,
        amount: firstItem.amount,
        requestedAmount: firstItem.requestedAmount,
        quantity: firstItem.quantity,
        product: firstItem.product,
      }),
      group,
      volume: { MWm: 0, MWh: 0 },
    };

    quoteItem.subItems = groupedQuoteItems[key].map(parseItem);

    quoteItem.volume.MWh = quoteItem.subItems.reduce((acc, curr) => acc + parseFloat(curr.volume.MWh), 0);

    updateFieldsVolume({ data: quoteItem, type: 'MWh' });

    data.options.edited.quoteItemPrice = true;
    data.options.edited.quoteItemVolume = true;

    quoteItems.push(quoteItem);
  });

  data.quoteItems = quoteItems;
}

export function filterInitialValue({ initialValue, operation, opportunityType }) {
  if (isEmpty(initialValue)) {
    return merge(
      {},
      { ...INITIAL_CONTEXT },
      opportunityType && {
        opportunity: { type: opportunityType.toUpperCase() },
      },
    );
  }

  const data = merge({}, INITIAL_CONTEXT, initialValue);

  switch (operation) {
    case 'duplicate':
      delete data.id;
      data.opportunity.channel = 'mesa';
      data.expireDate = INITIAL_CONTEXT.expireDate;
      break;
    case 'renegotiate':
      data.succeededId = data.id;
      delete data.id;
      break;
    default:
      break;
  }

  merge(data.options, {
    filled: true,
    edited: {
      modulation: true,
      sazoPercent: true,
      flexPositive: true,
      flexNegative: true,
      loss: true,
      readjustmentIndex: true,
      quoteInformation: true,
      volumeType: true,
      retusdAmount: true,
      dueDateValue: true,
      dueDateType: true,
      quoteItems: true,
    },
  });

  data.startDate = new Date(`${data.startDate.slice(0, 10)}T00:00:00-03:00`);
  data.endDate = new Date(`${data.endDate.slice(0, 10)}T23:59:59-03:00`);
  data.expireDate = new Date(data.expireDate);

  data.baseDate = data.baseDate && new Date(`${data.baseDate}T00:00:00-03:00`);

  data.opportunity.channel = data.opportunity.channel.toUpperCase();

  if (opportunityType) {
    data.opportunity.type = opportunityType.toUpperCase();
  }

  data.readjustmentMonth = data.readjustmentMonth && endOfMonth(setMonth(new Date(), data.readjustmentMonth - 1));

  data.quoteInformationTypes = data.quoteInformation.map(({ type }) => type);

  parseQuoteItemFromServer(data);

  return data;
}
