import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    '&:focus': {
      backgroundColor: 'unset',
    },
  },
  icon: {
    width: 24,
    height: 24,
    right: 4,
    color: 'unset',
    position: 'absolute',
    pointerEvents: 'none',
  }
}));

export default useStyles;
