import { Item } from '../Item';

export interface SkeletonProps {
  loading: boolean;
}

export const Skeleton: React.FC<SkeletonProps> = ({ loading }) => (
  <>
    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
      <Item key={item} loading={loading} />
    ))}
  </>
);
