import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  ${({ theme, expired }) => css`
    display: flex;
    align-items: center;
    justify-content: left;

    svg {
      padding: 6px;
      border-radius: 50%;

      min-height: 32px;
      min-width: 32px;
    }

    span {
      margin-left: 8px;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  `}
`;
