import { gql } from '@apollo/client';

import { ContractProps } from './contractsDetails';

export interface ResponseProps {
  energyContract: ContractProps;
}

export interface PayloadProps {
  id: string;
}

const ENERGY_CONTRACT = gql`
  query energyContract($id: ID!) {
    energyContract(id: $id) {
      id
      crmId
      contractNumber
      periodType
      operation
      startDate
      endDate
      energyType
      modulation
      sazoPercent
      flexPositive
      flexNegative
      dueDateType
      dueDateValue
      contractFlags
      submarket
      usageFactor
      tenant {
        name
      }
      account {
        name
      }
      cceeRepresentative {
        name
      }
      currentContractItem {
        amount
        quantityMwm
        finalAmount
        finalQuantityMwm
        readjustedAmount
        calculatedAmount
      }
    }
  }
`;

export default ENERGY_CONTRACT;
