import { Children, cloneElement, isValidElement } from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

function Tabs(props) {
  const { onChange, children, value } = props;

  return (
    <Container>
      {Children.map(children, (child, index) => {
        if (!isValidElement(child)) {
          return null;
        }

        return cloneElement(child, {
          onClick: onChange,
          value: index,
          current: index === +value,
        });
      })}
    </Container>
  );
}

Tabs.propTypes = {
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default Tabs;
