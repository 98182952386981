export function getOptions(color) {
  return {
    chart: {
      type: 'area',
      sparkline: {
        enabled: true,
      },
    },
    tooltip: {
      enabled: false,
    },
    colors: [color],
    yaxis: {
      min: 0,
      max: (max) => max,
      forceNiceScale: true,
    },
    stroke: {
      width: 1,
      curve: 'straight',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        shadeIntensity: 0.4,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 0,
        stops: [0, 100],
        colorStops: [],
      },
    },
  };
}

export function getSeries({ currentValue, previousValue, currentPeriod, previousPeriod }) {
  return [
    {
      name: 'Comparison',
      data: [
        {
          x: previousPeriod,
          y: previousValue,
        },
        {
          x: currentPeriod,
          y: currentValue,
        },
      ],
    },
  ];
}
