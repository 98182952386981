import { Flex, Skeleton, Text, Tr } from '@tradener/lumen';
import { DateTime } from 'luxon';

import { OperationLimitsDetailsSerieTypeProps } from '@graphql/query/operationLimits/detail';

import { Td } from '../Td';

interface TrProps extends OperationLimitsDetailsSerieTypeProps {
  period?: number;
  competence?: string;
  purchaseLimit?: number;
  saleLimit?: number;
  loading?: boolean;
}

export const Item: React.FC<TrProps> = ({ period, competence, purchaseLimit, saleLimit, loading }, key) => (
  <Tr key={key}>
    <Td>
      <Flex flexDir="column" gap={1}>
        <Skeleton borderRadius="md" isLoaded={!loading}>
          <Text
            textStyle="body2-regular"
            textOverflow="ellipsis"
            overflow="hidden"
            color="gray.900"
            whiteSpace="no-wrap"
            noOfLines={3}
          >
            M{period ?? '-'}
          </Text>
        </Skeleton>
        <Skeleton borderRadius={'md'} isLoaded={!loading}>
          <Text textStyle="caption2-regular" color="gray.500">
            {DateTime.fromFormat(competence || '', 'yyyy-MM')
              .toFormat('LLL/yy', { locale: 'pt-BR' })
              .replace('.', '')
              .toUpperCase()}
          </Text>
        </Skeleton>
      </Flex>
    </Td>
    <Td color="gray.900">
      <Skeleton borderRadius={'md'} isLoaded={!loading}>
        {(purchaseLimit || 0).formatCurrency()}
      </Skeleton>
    </Td>
    <Td color="gray.900">
      <Skeleton borderRadius={'md'} isLoaded={!loading}>
        {(saleLimit || 0).formatCurrency()}
      </Skeleton>
    </Td>
    <Td>
      <Skeleton borderRadius={'md'} isLoaded={!loading}>
        -
      </Skeleton>
    </Td>
    <Td>
      <Skeleton borderRadius={'md'} isLoaded={!loading}>
        -
      </Skeleton>
    </Td>
    <Td>
      <Skeleton borderRadius={'md'} isLoaded={!loading}>
        -
      </Skeleton>
    </Td>
    <Td>
      <Skeleton borderRadius={'md'} isLoaded={!loading}>
        -
      </Skeleton>
    </Td>
  </Tr>
);
