import { Flex, Text } from '@tradener/lumen';

import { useBbceProduct } from '../../..';
import OfferListItem from '../OfferListItem';
import useProductQuote from '../useProductQuote';
import OperationFlag from './OperationFlag';

function OfferOverview() {
  const { id } = useBbceProduct();
  const { quantity, price, opportunity } = useProductQuote();
  const isInternalTransfer = opportunity.bbceProduct?.id !== id;

  return (
    <OfferListItem gap="4" justifyContent="flex-start">
      <OperationFlag />
      <Flex flexDirection="column" alignItems="flex-start">
        <Flex alignItems="flex-end" columnGap="1">
          <Text textStyle="body2-regular">
            {(isInternalTransfer ? opportunity.bbceProductTransfer?.quantityMwm : quantity)?.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
          <Text textStyle="body2-regular">MWm</Text>
        </Flex>
        <Text textStyle="body2-regular" color="gray.500">
          {price}
        </Text>
      </Flex>
    </OfferListItem>
  );
}

export default OfferOverview;
