export function numberFormat(value, maximumFractionDigits = 0, minimumFractionDigits = 0, locale = 'pt-BR') {
  return Intl.NumberFormat(locale, {
    maximumFractionDigits,
    minimumFractionDigits,
  }).format(value);
}

export function numberFormatOrReturnDash(value, maximumFractionDigits = 0, minimumFractionDigits = 0) {
  if (!value) return '-';

  return numberFormat(value, maximumFractionDigits, minimumFractionDigits);
}
