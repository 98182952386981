import { FetchResult, useMutation } from '@apollo/client';
import { useToast } from '@tradener/lumen';

import UPSERT_OPERATION_LIMITS, { ResponseProps, PayloadProps } from '@graphql/mutation/operationLimits/upsert';

interface UpsertOperationLimitsProps {
  upsert: (payload: { variables: PayloadProps }) => Promise<FetchResult<ResponseProps, Record<string, any>, Record<string, any>>>;
  loading: boolean;
}

const useUpsertOperationLimits = () => {
  const toast = useToast();

  const [upsert, { loading }] = useMutation<ResponseProps, PayloadProps>(UPSERT_OPERATION_LIMITS, {
    onCompleted: ({ upsertOperationLimits }) => {
      const status = upsertOperationLimits.status;

      if (status === 'ok') {
        toast({ title: 'Sucesso', status: 'success', description: 'Limites atualizados com sucesso!' });
      } else {
        toast({ title: 'Erro', status: 'error', description: 'Erro ao atualizar limites!' });
      }
    },
    onError: ({ message }) => {
      toast({ title: 'Erro', status: 'error', description: message || 'Erro ao atualizar limites!' });
    },
  });

  return {
    upsert,
    loading,
  } as UpsertOperationLimitsProps;
};

export default useUpsertOperationLimits;
