export enum QuoteStatus {
  DENIED = 'denied',
  REJECTED = 'rejected',
  QUOTED = 'quoted',
  PROPOSED = 'proposed',
  CLOSED = 'closed',
  OPENED = 'opened',
}

export enum Permissions {
  MESA = 'mesa',
  PORTFOLIO = 'portfolio',
}

export enum Roles {}

export enum Scope {
  USER = 'user',
}

export type JWTToken = {
  exp: number;
  data: {
    id: string;
    scope: Scope;
    permissions: Permissions[];
  };
};

export type GoogleProfile = {
  googleId: string;
  imageUrl: string;
  email: string;
  name: string;
  givenName: string;
  familyName: string;
};
