import { FormControl, FormLabel } from '@tradener/lumen';

import MonthRange from '~/theme/components/MonthRange';

import { useOffer } from '../context';

function PickPeriod() {
  const { getMonthRange, bbceProductId } = useOffer();

  if (bbceProductId) return null;

  return (
    <FormControl data-testid="modal-period" display="flex" flexDirection="column" flex="1.65" gap="1.5">
      <FormLabel margin="0">Período</FormLabel>
      <MonthRange id="period" onChange={getMonthRange} style={{ width: 'auto' }} />
    </FormControl>
  );
}

export default PickPeriod;
