import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => (theme?.palette?.type === 'light') &&
    `border: 0.5px solid #AAA;` || `border: 0.5px solid #5c5c5c`};
  border-radius: 6px;

  margin: 6px 8px;

  min-height: 100px;
  width: 232px;
`;

export const Header = styled.header`
  color: #8c8c8c;
  border-bottom: 0.2px solid #8c8c8c;
  padding: 8px;

  h5 {
    font-size: 14px;
    letter-spacing: 0.8px;
  }
`;

export const Body = styled.div`
  margin: 12px 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${({ type }) => type === 'column'
    && css`
      flex-direction: column;
    `}
`;
