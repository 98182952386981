import {
  Flex,
  ExpandMoreIcon,
  ChevronRightIcon,
  PushPinIcon,
  PushPinOutlineIcon,
  IconButton,
  Icon,
  useBoolean,
  CircularProgress,
} from '@tradener/lumen';

import useTrading from '~/pages/Trading/useTrading';

import { useBbceProduct } from '..';

function ProductAction() {
  const { filter, setFilter } = useTrading();
  const { id, isOpen, isPinned, onOpen, onClose } = useBbceProduct();
  const [loading, { on: onLoading }] = useBoolean();

  const onTogglePin = () => {
    const ids = filter?.pinnedIds;
    const finalIds = ids ? (ids.includes(id) ? ids.filter((pinnedId) => pinnedId !== id) : [...ids, id]) : [id];

    onLoading();
    setFilter('pinnedIds', finalIds);
  };

  return (
    <Flex align="center">
      {loading ? (
        <Flex align="center" justify="center" boxSize="10">
          <CircularProgress color="gray.500" size="5" isIndeterminate />
        </Flex>
      ) : (
        <IconButton
          aria-label="pin or unpin list item"
          variant="ghost"
          colorScheme="gray"
          size="sm"
          onClick={onTogglePin}
          icon={isPinned ? <PushPinIcon color="orange.500" boxSize="5" /> : <PushPinOutlineIcon color="gray.400" boxSize="5" />}
        />
      )}
      <IconButton
        aria-label="fold or unfold list item"
        variant="ghost"
        colorScheme="gray"
        size="sm"
        icon={<Icon as={isOpen ? ExpandMoreIcon : ChevronRightIcon} color="gray.400" />}
        onClick={isOpen ? onClose : onOpen}
      />
    </Flex>
  );
}

export default ProductAction;
