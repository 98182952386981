import { useRef, useEffect } from 'react';

export default function useUpdateEffect(effect: React.EffectCallback, deps: React.DependencyList = []) {
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) effect();
    else isMounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
