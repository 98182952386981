import { FormControl, FormLabel, Textarea } from '@tradener/lumen';

import useComplementForm from '../useComplementForm';

function PickNotes() {
  const { register } = useComplementForm();

  return (
    <FormControl display="flex" flexDirection="column" gap="1.5">
      <FormLabel margin="0">Observações</FormLabel>
      <Textarea id="notes" resize="none" minWidth="full" maxWidth="full" minHeight="30" maxHeight="30" {...register('notes')} />
    </FormControl>
  );
}

export default PickNotes;
