import { gql } from '@apollo/client';

import { BbceProductProps } from './bbceProducts';
import { OperationTypes } from './productOperations';

export interface AccountProps {
  id: string;
  name: string;
}

export interface TenantProps {
  id: string;
  name: string;
}

export interface OpportunityProps {
  id: string;
  type: OperationTypes;
  buyer: AccountProps;
  seller: AccountProps;
  account: AccountProps;
  tenant: TenantProps;
  bbceProduct?: Pick<BbceProductProps, 'id'>;
  bbceProductTransfer?: BbceProductTransferProps;
}

export interface QuoteItemProps {
  id: string;
  startDate: string;
  endDate: string;
  quantity: number;
  amount: number;
}

export interface BbceProductTransferProps {
  id: string;
  quantityMwm: number;
  bbceProductFrom: Pick<BbceProductProps, 'id' | 'name'>;
  bbceProductTo: Pick<BbceProductProps, 'id' | 'name'>;
}

export interface QuoteProps {
  id: string;
  opportunity: OpportunityProps;
  notes?: string;
  swap?: boolean;
  externalId?: string;
  giro?: boolean;
  quoteItems?: QuoteItemProps[];
}

export interface PayloadProps {
  id: string;
}

export interface ResponseProps {
  quote: QuoteProps;
}

const QUOTE_FRAGMENT = gql`
  fragment quote on Quote {
    id

    opportunity {
      id
      type
      buyer {
        id
        name
      }
      seller {
        id
        name
      }
      account {
        id
        name
      }
      tenant {
        id
        name
      }
      bbceProduct {
        id
      }
      bbceProductTransfer {
        id
        quantityMwm
        bbceProductFrom {
          id
          name
        }
        bbceProductTo {
          id
          name
        }
      }
    }
    externalId
    notes
    giro
    swap
    quoteItems {
      id
      amount
      quantity
      startDate
      endDate
    }
  }
`;

const QUOTE = gql`
  ${QUOTE_FRAGMENT}

  query Quote($id: ID!) {
    quote(id: $id) {
      ...quote
    }
  }
`;

export { QUOTE_FRAGMENT };
export default QUOTE;
