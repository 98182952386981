import { AttachMoneyIcon } from '@tradener/lumen';

import OptionItem from '../../OptionItem';
import { useQuoteOptions } from '../../useQuoteOptions';

function ActionRenegotiate() {
  const { navigate } = useQuoteOptions();

  const onPushToForm = () => navigate({ type: 'renegotiate' });

  return (
    <OptionItem stage="PROPOSED" onClick={onPushToForm} icon={<AttachMoneyIcon boxSize="5" />}>
      Renegociar
    </OptionItem>
  );
}

export default ActionRenegotiate;
