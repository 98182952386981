import { gql } from '@apollo/client';

export interface MutationResponseProps {
  status: string;
}

export interface SyncSimulationProps {
  syncSimulation: MutationResponseProps;
  errors?: string[];
}

export interface InputProps {
  simulationId: string;
}

export interface PayloadProps {
  input: InputProps;
}

export interface ResponseProps {
  syncSimulation: SyncSimulationProps;
}

const SYNC_SIMULATION = gql`
  mutation syncSimulation($input: SyncSimulationInput!) {
    syncSimulation(input: $input) {
      syncSimulation {
        status
      }
      errors
    }
  }
`;

export default SYNC_SIMULATION;
