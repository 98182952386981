import { combineReducers } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';
import createFilter from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage';

import { RootStateProps } from '.';
import auth from './ducks/auth';
import colorMode from './ducks/colorMode';
import notification from './ducks/notification';

const reducers = combineReducers({
  auth,
  colorMode,
  notification,
});

const config: PersistConfig<RootStateProps> = {
  key: 'storm',
  whitelist: ['auth', 'colorMode', 'portfolio'],
  blacklist: ['notification'],
  transforms: [createFilter('bbceProducts', ['filter'])],
  storage,
};

export default persistReducer(config, reducers);
