import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';

import UPDATE_SIMULATION_ITEMS, { ResponseProps, PayloadProps } from '@graphql/mutation/updateSimulationItems';
import SIMULATION_CONTRACTS from '@graphql/query/simulationContracts';

import client from '~/services/apollo';

import useSimulation from '../../useSimulation';
import { useSimulationContract } from './context';

function useSimulationUpdateItems() {
  const { simulationId } = useSimulation();
  const { itemPushList, setItemPushList } = useSimulationContract();

  const [updateSimulationItems] = useMutation<ResponseProps, PayloadProps>(UPDATE_SIMULATION_ITEMS, {
    variables: {
      input: {
        simulationId: simulationId as string,
        simulationItems: itemPushList.map(({ resourceId, competence, price, quantityMwm }) => ({
          resourceId,
          competence,
          price,
          quantityMwm,
        })),
      },
    },
    async onCompleted() {
      setItemPushList([]);
      await client.refetchQueries({ include: [SIMULATION_CONTRACTS] });
      toast.success('Itens dos contratos atualizados!');
    },
    onError: ({ message }) => toast.error(message),
  });

  return updateSimulationItems;
}

export default useSimulationUpdateItems;
