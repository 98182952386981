import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '24px',
    marginRight: '10px',
    padding: '0px 6px 0px 8px',
  },
  arrowDown: {
    color: '#FFFFFFA3',
    marginRight: '6px',
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  menu: {
    '& [class*="MuiPaper-root"]': {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: `${theme.palette.type === 'dark' ? theme.palette.grey[800] : '#FFF'}`,
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '164px',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
      padding: '8px',
      gap: '8px',
      marginTop: '1px',
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    backgroundColor: `${theme.palette.type === 'dark' ? theme.palette.grey[800] : '#FFF'}`,
    padding: '9.5px 12px',
    gap: '10px',
    height: '36px',
    borderRadius: '4px',
    width: '156px',
  },
}));

export default useStyles;
