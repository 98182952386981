import { Flex, useToken, Text } from '@tradener/lumen';
import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Legend, Tooltip, Line, LabelList, LineChart } from 'recharts';

import CustomLegend from '../components/CustomLegend';
import CustomTooltip from '../components/CustomTooltip';
import TickLabelXAxis from '../components/TickLabelXAxis';
import { useTradingChart } from '../useTradingChart';

function AccumulatedBalance() {
  const [gray100, gray500, gray600, gray900, blue500, purple500] = useToken('colors', [
    'gray.100',
    'gray.500',
    'gray.600',
    'gray.900',
    'blue.500',
    'purple.500',
  ]);
  const { series } = useTradingChart();

  const config = (dataKey: string) => {
    if (dataKey === 'marketResultAccumulated')
      return { iconColor: blue500, iconType: 'line', minimumFractionDigits: 0, maximumFractionDigits: 0 };
    if (dataKey === 'pldResultAccumulated')
      return { iconColor: '#4EC5E8', iconType: 'line', minimumFractionDigits: 0, maximumFractionDigits: 0 };
    if (dataKey === 'finalResultAccumulated')
      return { iconColor: purple500, iconType: 'line', minimumFractionDigits: 0, maximumFractionDigits: 0 };
  };

  if (!series?.length) return null;

  return (
    <Flex
      data-testid="accumulated-balance"
      flexDirection="column"
      bgColor="gray.0"
      height="450px"
      borderRadius="xl"
      padding="5"
      flex="1"
      width="full"
      color={gray900}
    >
      <Flex flexDirection="column" justifyContent="center" gap="0.5">
        <Text textStyle="subheading1-semibold">Resultado acumulado</Text>
        <Text textStyle="body2-regular" color="gray.500">
          R$ (mil)
        </Text>
      </Flex>
      <Flex
        direction="column"
        width="100%"
        height="100%"
        paddingBlock="1"
        sx={{
          '.recharts-text': { fill: gray500 },
          '.recharts-cartesian-axis-line, .recharts-brush > rect': { stroke: 'none' },
          '.recharts-cartesian-axis-tick-line, line': { stroke: gray100 },
        }}
        overflow="visible"
      >
        <ResponsiveContainer>
          <LineChart data={series} syncId="balanceId" margin={{ top: 15 }}>
            <CartesianGrid vertical={false} strokeWidth={1} />
            <XAxis
              dataKey="competence"
              interval={0}
              tickLine={false}
              tick={<TickLabelXAxis mode="MONTH" />}
              padding={{ left: 20, right: 40 }}
            />
            <YAxis
              orientation="left"
              stroke={blue500}
              tick={{ fill: gray600, fontSize: '0.75rem' }}
              tickMargin={16}
              interval={0}
              tickLine={false}
              scale="linear"
              domain={([dataMin, dataMax]) => {
                const absMax = Math.max(Math.abs(dataMin), Math.abs(dataMax));

                return [-(Number(dataMin) < 0 ? absMax * 1.25 : 0), absMax * 1.25];
              }}
              tickFormatter={(value) =>
                (value / 1000).formatCurrency('pt-BR', {
                  notation: 'standard',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
              }
            />
            <Tooltip
              cursor={{ stroke: `${gray900}14`, fill: `${gray900}14`, radius: 2 }}
              content={<CustomTooltip mode="MONTH" config={config} />}
            />
            <Line
              name="Marcação a mercado"
              dataKey="marketResultAccumulated"
              stroke={blue500}
              type="monotone"
              strokeWidth={2}
              dot={{ stroke: blue500, fill: blue500, strokeWidth: 1 }}
            >
              <LabelList
                dataKey="marketResultAccumulated"
                position="top"
                fontSize=".625rem"
                formatter={(value) =>
                  (value / 1000).formatCurrency('pt-BR', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                }
                style={{ fill: blue500 }}
              />
            </Line>
            <Line
              name="Projeção interna"
              dataKey="pldResultAccumulated"
              stroke="#4EC5E8"
              type="monotone"
              strokeWidth={2}
              dot={{ stroke: '#4EC5E8', fill: '#4EC5E8', strokeWidth: 1 }}
            >
              <LabelList
                dataKey="pldResultAccumulated"
                position="top"
                fontSize=".625rem"
                formatter={(value) =>
                  (value / 1000).formatCurrency('pt-BR', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                }
                style={{ fill: '#4EC5E8' }}
              />
            </Line>
            <Line
              name="Posições fechadas"
              dataKey="finalResultAccumulated"
              stroke={purple500}
              type="monotone"
              strokeWidth={2}
              dot={{ stroke: purple500, fill: purple500, strokeWidth: 1 }}
            >
              <LabelList
                dataKey="finalResultAccumulated"
                position="top"
                fontSize=".625rem"
                formatter={(value) =>
                  (value / 1000).formatCurrency('pt-BR', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                }
                style={{ fill: purple500 }}
              />
            </Line>
            <Legend content={<CustomLegend config={config} />} />
          </LineChart>
        </ResponsiveContainer>
      </Flex>
    </Flex>
  );
}

export default AccumulatedBalance;
