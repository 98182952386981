import { forwardRef } from 'react';

import useStyles from './styles';

export enum PLDStatus {
  'INITIAL',
  'TOUCHED',
  'COMMITTED',
}

export interface TableCellProps extends React.HTMLAttributes<React.PropsWithRef<HTMLElement>> {
  edited: PLDStatus;
}

const TableCell = forwardRef<HTMLDivElement, TableCellProps>(({ edited, ...props }, ref) => {
  const { cell } = useStyles(edited)();

  return <div ref={ref} className={cell} {...props} />;
});

export default TableCell;
