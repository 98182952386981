import { useState } from 'react';

import {
  Box,
  Popover,
  useTheme,
  PopoverContent,
  PopoverBody,
  Button,
  Text,
  PopoverTrigger,
  ArrowDropdownIcon,
  useDisclosure,
} from '@tradener/lumen';

import { useCachedTradingUsers } from '~/utils/getTradingUsers';

import useTrading from '../../useTrading';
import UserOption from './UserOption';

export interface UserProps {
  isLoaded?: boolean;
}

const Users: React.ComponentType<UserProps> = ({ isLoaded = true }) => {
  const { colors } = useTheme();
  const { isOpen, onClose, onToggle } = useDisclosure();
  const users = useCachedTradingUsers();
  const { setFilter, filter } = useTrading();
  const [userId, setUserId] = useState<string | undefined>(filter?.userId);

  const handleChange = (value: string | undefined) => {
    setUserId(value);
    setFilter('userId', value);
    onClose();
  };

  if (!users || !isLoaded) return null;

  return (
    <Box
      minWidth="45"
      textStyle="body2-regular"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      overflow="clip"
      sx={{
        '& > div': {
          height: '8',
          bgColor: colors.gray[900] + '14',
        },
      }}
    >
      <Popover matchWidth isOpen={isOpen} onClose={onClose} closeOnBlur closeOnEsc isLazy gutter={0}>
        <PopoverTrigger>
          <Button
            data-testid="users-button"
            color="gray.900"
            justifyContent="space-between"
            width="45"
            height="8"
            variant="outline"
            colorScheme="gray"
            textStyle="body2-regular"
            borderColor={colors.gray[900] + 29}
            rightIcon={<ArrowDropdownIcon boxSize="5" transform={`rotate(${isOpen ? 180 : 0}deg)`} />}
            onClick={onToggle}
          >
            <Text casing="capitalize" overflow="hidden" textOverflow="ellipsis" data-testid="users-dropdown">
              {users.find((user) => user.id === userId)?.name ?? 'Todos'}
            </Text>
          </Button>
        </PopoverTrigger>
        <PopoverContent width="45">
          <PopoverBody
            width="45"
            display="flex"
            flexDirection="column"
            paddingBlock="2"
            paddingInline="1.5"
            gap="0"
            maxHeight="70dvh"
            overflow="auto"
          >
            <UserOption
              data-testid="users-option-1"
              key="all"
              name="Todos"
              isSelected={userId === undefined}
              handleChange={handleChange}
            />
            {users?.length &&
              users.map(({ id, name }) => (
                <UserOption key={id} id={id} name={name} isSelected={userId === id} handleChange={handleChange} />
              ))}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default Users;
