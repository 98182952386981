import { AiFillCaretRight } from 'react-icons/ai';

import styled, { css } from 'styled-components';

export const Container = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Content = styled.div`
  ${({ theme }) => css`
  display: flex;
  flex: 1;

  justify-content: center;
  align-items: center;
  align-self: center;

  cursor: pointer;

  border-bottom: 4px solid transparent;

  font-size: 18px;
  font-weight: 500;

  height: 100%;

  ${(theme?.palette?.type === 'light') && `color: rgba(38, 38, 38, 0.24)` || `color: #5c5c5c`};

  ${({ current }) => current
      && css`
      ${(theme?.palette?.type === 'light') && `color: #252525` || `color: #FFF`};
      border-bottom: 4px solid #FA6800;
    `}
  `}
`;

export const Arrow = styled(AiFillCaretRight)`
  align-self: center;
  width: 24px;
  height: 24px;
`;
