import { forwardRef, useState, useCallback } from 'react';

import { MenuItem, Select, Checkbox, Typography } from '@material-ui/core';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';

import useUpdate from '~/hooks/useUpdateEffect';
import usePortfolio from '~/pages/Portfolio/usePortfolio';
import DEFAULT_ACCOUNTS from '~/utils/getDefaultAccounts';

const InternalAccounts = forwardRef<HTMLDivElement, FormControlProps>((props, ref) => {
  const { setFilter, internalAccounts } = usePortfolio();
  const [accounts, setAccounts] = useState<Array<string>>(internalAccounts ?? []);
  const data = DEFAULT_ACCOUNTS?.filter(({ name }) => /^(pierp|tradener|tradener rs|tradener varejista)$/i.test(name));

  const handleChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<Replace<HTMLInputElement, 'value', string>>) => {
      setAccounts((prev) => {
        if (value === 'all') return data.map(({ accountId: id }) => id);

        if (prev.includes(value)) return prev.filter((id) => id !== value);

        return [...prev, value];
      });
    },
    [data],
  );

  useUpdate(() => {
    setFilter('internalAccounts', accounts);
  }, [setFilter, accounts]);

  return (
    <FormControl ref={ref} variant="outlined" {...props}>
      <Select
        onChange={handleChange as any}
        color="primary"
        fullWidth
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        style={{
          overflow: 'hidden',
        }}
        value={internalAccounts}
        renderValue={(values) => {
          const selecteds = (values as Array<string>).filter((id) => id !== 'all');
          const placeholder =
            selecteds.length === data.length
              ? 'Todos'
              : selecteds.map((current) => data.find(({ accountId: id }) => id === current)?.name).join(', ');

          return (
            <Typography
              variant="body2"
              color="secondary"
              style={{
                width: 'calc(100% - 5px)',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {selecteds.length === 0 ? 'Selecione' : placeholder}
            </Typography>
          );
        }}
      >
        <MenuItem value="all" defaultChecked={internalAccounts?.length === data.length}>
          Todos
        </MenuItem>
        {data.map(({ accountId: id, name }) => (
          <MenuItem key={id} value={id} disabled={internalAccounts?.length === 1 && internalAccounts[0] === id}>
            <Checkbox
              size="small"
              defaultChecked={internalAccounts?.includes(id)}
              style={{
                pointerEvents: 'none',
                height: '20px',
                width: '20px',
                marginRight: '7px',
              }}
            />
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

export default InternalAccounts;
