import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette, typography }) => ({
  wrapper: {
    backgroundColor: 'transparent',
    '&:before': {
      display: 'none',
    },
  },
  expanded: {
    margin: '0 !important',
    backgroundColor: palette.grey[palette.type === 'dark' ? 900 : 100],
  },
  text: {
    ...typography.body2,
    paddingLeft: '10px',
    lineHeight: '22px',
  },
  summary: {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: palette.grey[palette.type === 'dark' ? 700 : 200],
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  details: {
    padding: '0 16px',
  },
}));

export default useStyles;
