import { useRef } from 'react';

import { MdExpandMore } from 'react-icons/md';

import { FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import MUISelect, { SelectProps } from '@material-ui/core/Select';

import MenuItem from '@material-ui/core/MenuItem';

import useStyles from './styles';

type ISelectOptions = {
  value: string | number;
  label: string | number;
  disabled?: boolean;
};

interface ISelect extends SelectProps {
  options: ISelectOptions[];
}

const Select: React.FC<ISelect> = ({ name, label, options, value, displayEmpty, native, inputProps, error, ...rest }) => {
  const ref = useRef(null);
  const classes = useStyles();

  const props = {
    ...rest,
    native,
    value: undefined,
    name,
    inputProps: {
      ...inputProps,
      ref,
      name,
      id: name,
      'aria-label': name,
    },
  };

  return (
    <FormControl error={!!error}>
      {!!label && <InputLabel htmlFor={name}>{label}</InputLabel>}

      <MUISelect
        {...props}
        displayEmpty={displayEmpty}
        IconComponent={MdExpandMore}
        value={value}
        classes={{
          root: classes.root,
          icon: classes.icon,
        }}
      >
        {displayEmpty &&
          (native ? (
            <option value="" label="Selecione" disabled />
          ) : (
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
          ))}

        {options.map(({ value: optionValue, label: optionLabel, disabled }) => (
          <MenuItem key={optionValue} value={optionValue} disabled={disabled}>
            {optionLabel}
          </MenuItem>
        ))}
      </MUISelect>

      {!!error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

Select.defaultProps = {
  label: '',
  name: undefined,
  displayEmpty: true,
  error: false,
  value: '',
  native: false,
  options: [],
  inputProps: {},
  variant: 'outlined',
  margin: 'dense',
};

export default Select;
