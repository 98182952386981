import { Text, Tooltip, Avatar, Flex, Button, ButtonProps, useTheme } from '@tradener/lumen';

export interface CompanyNameProps extends ButtonProps {
  id?: string;
  name: string;
  isSelected: boolean;
  handleChange: (id?: string) => void;
}

const UserOption: React.FunctionComponent<CompanyNameProps> = ({ id, name, isSelected, handleChange, ...props }) => {
  const { colors } = useTheme();

  const onChangeOption = () => handleChange(id);

  return (
    <Button
      key={id + name}
      data-testid="user-button"
      alignItems="center"
      justifyContent="space-between"
      variant={isSelected ? undefined : 'ghost'}
      colorScheme={isSelected ? 'orange' : 'gray'}
      color={isSelected ? 'gray.0' : 'gray.900'}
      py="2.25"
      px="3"
      gap="2"
      height="9"
      textStyle="body1-regular"
      _hover={{ bgColor: !isSelected ? colors.gray[900] + 14 : undefined }}
      onClick={onChangeOption}
      {...props}
    >
      <Avatar name={name} size="xs" />
      <Flex width="full" align="center" key={id + name} height="9">
        <Tooltip
          label={name.toLowerCase()}
          placement="top"
          bgColor="gray.900"
          color="gray.0"
          textStyle="body2-regular"
          borderRadius="base"
          maxWidth="full"
          textTransform="capitalize"
          hasArrow
        >
          <Text casing="capitalize" maxW="82%" overflow="hidden" textOverflow="ellipsis">
            {name.toLowerCase()}
          </Text>
        </Tooltip>
      </Flex>
    </Button>
  );
};

export default UserOption;
