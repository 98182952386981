import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { actions, ProfileProps } from '~/context/ducks/auth';

import useContainer from './useContainer';

export interface GoogleProps {
  access_token: string;
  expires_in: number;
  hd?: string;
  prompt: string;
  token_type: string;
  scope: string;
  state?: string;
  profile?: ProfileProps;
}

function useAuth() {
  const auth = useContainer(({ auth }) => auth);
  const dispatch = useDispatch();

  const signIn = useCallback(
    ({ access_token: accessToken, profile }: GoogleProps) => {
      dispatch(actions.signInRequest({ accessToken, profile }));
    },
    [dispatch],
  );

  const signInApp: ReplaceReturn<typeof actions.signInAppRequest, void> = useCallback(
    (...params) => {
      dispatch(actions.signInAppRequest(...params));
    },
    [dispatch],
  );

  const refresher: ReplaceReturn<typeof actions.setRefreshToken, void> = useCallback(
    (...params) => {
      dispatch(actions.setRefreshToken(...params));
    },
    [dispatch],
  );

  const signOut: ReplaceReturn<typeof actions.signOut, void> = useCallback(() => {
    dispatch(actions.signOut());
  }, [dispatch]);

  return {
    ...auth,
    isUserIn: !!auth.token,
    signIn,
    signOut,
    signInApp,
    refresher,
  };
}

export default useAuth;
