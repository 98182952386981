import { forwardRef } from 'react';

import { Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from '@material-ui/core';

import useStyles from './styles';

const Switch = forwardRef<HTMLButtonElement, MuiSwitchProps>((props, ref) => {
  const classes = useStyles();

  return <MuiSwitch ref={ref} classes={classes} {...props} />;
});

export default Switch;
