import { forwardRef } from 'react';

import { useVariant } from '@DEPRECATED/utils';
import PropTypes from 'prop-types';

import * as Label from './styles';

const SelectionControl = forwardRef((props, ref) => {
  const { id, type, name, value, defaultChecked, label, onClick, onChange, variant, ...rest } = props;

  const parseId = id || `${name}-${value}`;

  const Component = useVariant({
    component: Label,
    variant,
  });

  const other = {
    ...rest,
    id: parseId,
    ref,
    type,
    name,
    onClick,
    onChange,
    value,
    defaultChecked,
    'aria-label': parseId,
  };

  return (
    <Component htmlFor={parseId} {...rest}>
      <input {...other} />
      <span>{label}</span>
    </Component>
  );
});

SelectionControl.defaultProps = {
  id: undefined,
  type: 'radio',
  value: undefined,
  defaultChecked: undefined,
  label: undefined,
  variant: 'material',
  size: 5,
  onClick: undefined,
  onChange: undefined,
};

SelectionControl.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(['checkbox', 'radio']),
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultChecked: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['contained', 'material', 'outlined', 'small']),
  size: PropTypes.number,
};

export default SelectionControl;
