import { Box, BoxProps } from '@material-ui/core';

import useStyles from './styles';

const Container: React.FC<BoxProps> = ({ children, gridArea, ...props }) => {
  const { wrapper } = useStyles();

  return (
    <Box className={wrapper} gridArea={gridArea}>
      <Box {...props}>{children}</Box>
    </Box>
  );
};

export default Container;
