import { useCallback, useMemo, useState } from 'react';

import { DateObject, DateObjectUnits, DateTime } from 'luxon';

export enum DateTypes {
  minYear = 1970,
  yearAmount = 16,
}

export type InputDataTypes = DateObject | string;

export interface DayProps {
  day: number;
  month: number;
  weekday: number;
}

export interface MonthProps {
  days: Array<DayProps>;
  daysInMonth: number;
  monthLong: string;
  monthShort: string;
  today: number;
  year: number;
  month: number;
}

export const parseInput = (current: DateTime, input?: InputDataTypes) => {
  const getDate = (value: DateObjectUnits) => DateTime.fromObject(current.toObject()).set(value);

  if (typeof input === 'string') {
    if (/(\/)/.test(input)) {
      const [month, year] = input.split('/').map((value) => parseInt(value, 10));

      if (month >= 1 && month <= 12 && year > DateTypes.minYear) {
        return getDate({ month, year });
      } else {
        throw Error(`
          String input must be like this 01/2021\n
          Month must be between 1 and 12\n
          Year must be higher than ${DateTypes.minYear}
        `);
      }
    } else {
      throw Error('Wrong string input given');
    }
  } else if (typeof input === 'object') {
    return getDate(input);
  }

  return current;
};

/**
 * @param input [optional]
 * @type string or InputDataProps [partial]
 * @example useCalendar('01/2021') or useCalendar({ month: 1, year: 2021 })
 */

function useCalendar(input?: InputDataTypes) {
  const [current, setCurrent] = useState(parseInput(DateTime.now(), input));

  const getPreviousMonth = (year: number, month: number) => {
    if (month > 1) {
      return [year, month - 1];
    }

    if (year - 1 < DateTypes.minYear) {
      throw Error(`Year before ${DateTypes.minYear} cannot be parsed`);
    }

    return [year - 1, 12];
  };

  const getNextMonth = (year: number, month: number) => {
    if (month < 12) {
      return [year, month + 1];
    }

    return [year + 1, 1];
  };

  const getPreviousYear = () => current.minus({ year: 1 }).toObject();

  const getNextYear = () => current.plus({ year: 1 }).toObject();

  const placeholder = ({ days, daysInMonth, ...month }: MonthProps) => {
    const diff = 7 - days[0].weekday;
    const prevDays: Array<DayProps> = [];

    for (let weekday = 1; weekday <= diff; weekday += 1) {
      const period = DateTime.local(...getPreviousMonth(month.year, month.month));

      prevDays.push({
        day: period.daysInMonth - weekday,
        month: period.month,
        weekday,
      });
    }

    days = [...prevDays, ...days];

    if (days.length < 35) {
      for (let jump = days.length; jump < 35; jump += 1) {
        const day = jump - daysInMonth;
        const period = DateTime.local(...getNextMonth(month.year, month.month), day);

        days.push({
          day,
          month: period.month,
          weekday: period.weekday,
        });
      }
    }

    return { days, daysInMonth, ...month };
  };

  const mutate = useCallback((input: InputDataTypes) => setCurrent(parseInput(current, input)), [current]);

  const getYears = useCallback(
    (from = current.year, amount = DateTypes.yearAmount) => {
      if (from < DateTypes.minYear) throw Error(`Year cannot start from ${from}`);
      const years: Array<number> = [];

      for (let current = from; current < from + amount; current += 1) {
        years.push(current);
      }

      return years;
    },
    [current.year],
  );

  const getMonths = useCallback((year: number) => {
    const { month } = DateTime.now().set({ year });
    const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

    return { months, month };
  }, []);

  const calendar = useMemo(() => {
    const period = DateTime.fromObject(current.toObject());
    const month = {
      days: [],
      daysInMonth: period.daysInMonth,
      monthLong: period.monthLong,
      monthShort: period.monthShort,
      month: period.month,
      today: DateTime.now().day,
      year: period.year,
    } as MonthProps;

    for (let day = 1; day <= period.daysInMonth; day += 1) {
      const date = period.set({ day });

      month.days.push({
        day,
        month: period.month,
        weekday: date.weekday,
      });
    }

    return month;
  }, [current]);

  return {
    calendar,
    reference: current,
    mutate,
    getYears,
    getMonths,
    placeholder,
    getPreviousMonth,
    getNextMonth,
    getPreviousYear,
    getNextYear,
  };
}

export default useCalendar;
