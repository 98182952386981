import { gql } from '@apollo/client';

import { PriceTypes, PeriodTypes } from './portfolio';
import { SubmarketTypes, AccountingPortfolioTypes } from './simulationContracts';

export interface ResponseProps {
  downloadListContract: {
    downloadUrl?: string;
  };
}

export interface PayloadProps {
  simulationId: string;
  startYear: string;
  endYear?: string;
  tenantId?: string[];
  internalAccounts?: string[];
  submarket?: SubmarketTypes;
  search?: string;
  energyType?: string[];
  periodType?: PeriodTypes;
  priceType?: PriceTypes[];
  expandedPeriods?: string[];
  usageFactorByContract?: boolean;
  usageFactorByEnergyType?: boolean;
  usageFactor?: boolean;
  giro?: string;
  hasGiro?: boolean;
  contractFlags?: Array<string>;
  accountingPortfolio?: AccountingPortfolioTypes;
  closeDateLte?: string;
  closeDateGte?: string;
}

const DOWNLOAD_LIST_CONTRACT = gql`
  query downloadListContract(
    $simulationId: ID!
    $search: String
    $submarket: SubmarketEnum
    $startYear: String!
    $endYear: String
    $expandedPeriods: [String!]
    $tenantId: [ID!]
    $internalAccounts: [ID!]
    $energyType: [String!]
    $periodType: QuotePeriod
    $priceType: [QuotePrice!]
    $usageFactorByContract: Boolean
    $usageFactorByEnergyType: Boolean
    $usageFactor: Boolean
    $giro: String
    $hasGiro: Boolean
    $contractFlags: [String!]
    $accountingPortfolio: ContractPortfolio
    $closeDateLte: ISO8601Date
    $closeDateGte: ISO8601Date
  ) {
    downloadListContract(
      simulationId: $simulationId
      search: $search
      submarket: $submarket
      startYear: $startYear
      endYear: $endYear
      expandedPeriods: $expandedPeriods
      tenantId: $tenantId
      internalAccounts: $internalAccounts
      energyType: $energyType
      periodType: $periodType
      priceType: $priceType
      usageFactorByContract: $usageFactorByContract
      usageFactorByEnergyType: $usageFactorByEnergyType
      usageFactor: $usageFactor
      giro: $giro
      hasGiro: $hasGiro
      contractFlags: $contractFlags
      accountingPortfolio: $accountingPortfolio
      closeDateLte: $closeDateLte
      closeDateGte: $closeDateGte
    ) {
      downloadUrl
    }
  }
`;

export default DOWNLOAD_LIST_CONTRACT;
