import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: calc(100% - 58px);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  flex-flow: row wrap;
  align-self: center;

  margin: 24px 0px;

  > div {
    width: 40%;
  }

  > div:nth-child(3) {
    width: 100%;
  }
`;

export const ContentBox = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  align-items: center;
  gap: 16px;

  label span {
    margin: 0;
  }
`;

ContentBox.defaultProps = {
  columns: 4,
};
