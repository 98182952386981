import { forwardRef } from 'react';

import { MenuItem, Select, Typography } from '@material-ui/core';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';

import usePortfolioFilter from '~/pages/Portfolio/usePortfolioFilter';

const keys = {
  quantityMwm: 'MWm',
  price: 'R$/MWh',
  totalAmount: 'R$',
};

const Convert = forwardRef<HTMLDivElement, FormControlProps>((props, ref) => {
  const { view, setView } = usePortfolioFilter();

  const handleView = ({ target: { value } }) => {
    setView(value);
  };

  return (
    <FormControl ref={ref} variant="outlined" {...props}>
      <Select
        value={view}
        onChange={handleView}
        style={{ width: '125px' }}
        renderValue={(value) => (
          <Typography variant="body2" color="secondary">
            {keys[value as string]}
          </Typography>
        )}
      >
        <MenuItem value="quantityMwm">MWm</MenuItem>
        <MenuItem value="price">R$/MWh</MenuItem>
        <MenuItem value="totalAmount">R$</MenuItem>
      </Select>
    </FormControl>
  );
});

export default Convert;
