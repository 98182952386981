import { Controller, useFormContext } from 'react-hook-form';

import { FormControl, Skeleton, Tab, TabList, Tabs, Text } from '@tradener/lumen';

import { useOffer } from '../context';

function PickOperation() {
  const { control, reset, getValues, watch } = useFormContext();
  const { quoteLoading } = useOffer();
  const operation = watch('operation');
  const { ...prev } = getValues();

  return (
    <FormControl width="full">
      <Controller
        control={control}
        name="operation"
        render={({ field: { onChange: _, ...field } }) => (
          <Tabs
            height="full"
            variant="solid-rounded"
            index={operation === 'SALE' ? 0 : 1}
            onChange={(index) => {
              reset({
                ...prev,
                operation: index === 0 ? 'SALE' : 'PURCHASE',
                buyerId: prev?.sellerId,
                sellerId: prev?.buyerId,
                buyerName: prev?.sellerName,
                sellerName: prev?.buyerName,
              });
            }}
            {...field}
          >
            <TabList>
              <Skeleton isLoaded={!quoteLoading} borderRadius="full">
                <Tab value="SALE" py="2.5" data-testid="modal-sale">
                  <Text textStyle="body1-regular">Vender</Text>
                </Tab>
              </Skeleton>
              <Skeleton isLoaded={!quoteLoading} borderRadius="full">
                <Tab value="PURCHASE" py="2.5" data-testid="modal-purchase">
                  <Text textStyle="body1-regular">Comprar</Text>
                </Tab>
              </Skeleton>
            </TabList>
          </Tabs>
        )}
      />
    </FormControl>
  );
}

export default PickOperation;
