import { useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { Box, Button, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { GridProps } from '@tradener/lumen';

import { EnergyTypes } from '@graphql/query/contracts';
import { SimulationOperationsProps } from '@graphql/query/simulation';
import { SubmarketTypes } from '@graphql/query/simulationContracts';

import useColorMode from '~/hooks/useColorMode';
import useSimulationRules from '~/pages/Portfolio/useSimulationRules';
import ArrowDown from '~/theme/icons/ArrowDown';

import { TableVerticalAxis } from '../../Table';
import { useOperation } from '../Operation';
import { FormProps, useSimulationOperations } from '../withGuarantee';
import useStyles from './styles';

const OperationMenu: React.ComponentType<GridProps> = (props) => {
  const { colorTernary } = useColorMode();
  const { isReadOnly } = useSimulationRules();
  const { menu, menuItem, menuItemBox, arrowDownIcon } = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElPriceType, setAnchorElPriceType] = useState<null | HTMLElement>(null);
  const { register, setValue, getValues } = useFormContext<FormProps>();
  const { index } = useSimulationOperations((state) => state);
  const { hidden } = useOperation();
  const path = useMemo(() => `operations.${index}`, [index]) as 'operations.0';
  const operation = getValues(path);

  if (!operation) return null;

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (!isReadOnly) setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => setAnchorEl(null);

  const handleClickPriceTypeMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (!isReadOnly) setAnchorElPriceType(event.currentTarget);
  };

  const handleClosePriceTypeMenu = () => setAnchorElPriceType(null);

  const setMenu = <Key extends keyof SimulationOperationsProps>(key: Key, value: SimulationOperationsProps[Key]) => {
    setValue(`${path}.${key}`, value as never, { shouldDirty: true, shouldValidate: true });

    handleCloseMenu();
    handleClosePriceTypeMenu();
  };

  return (
    <TableVerticalAxis
      position="sticky"
      left="0"
      gridTemplateColumns="200px"
      gridAutoRows="initial"
      height="80px"
      alignItems="center"
      borderBottom={`1px solid ${grey[colorTernary(700, 200)]}`}
      zIndex={1}
      {...props}
    >
      <Box display="flex" position="relative" padding="6px 34px 6px 8px" alignItems="center">
        <Box display="flex" flexDirection="column" gridGap="4px">
          <Tooltip title="Tipo de operação">
            <Button
              id={`${operation?.id}-operation-type`}
              onClick={handleClickMenu}
              endIcon={<ArrowDown className={arrowDownIcon} />}
              disableElevation={isReadOnly}
              disableFocusRipple={isReadOnly}
              disableRipple={isReadOnly}
              disableTouchRipple={isReadOnly}
              style={{ width: '82px' }}
              {...register(`operations.${index}.operation`)}
            >
              {operation.operation === 'PURCHASE' ? 'Compra' : 'Venda'}
            </Button>
          </Tooltip>
          <Menu
            id={`${operation?.id}-operation-type-menu`}
            className={menu}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            open={anchorEl?.id === `${operation?.id}-operation-type`}
            onClose={handleCloseMenu}
            transitionDuration={0}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <MenuItem className={menuItem} onClick={() => setMenu('operation', 'SALE')}>
              <Box className={menuItemBox}>Venda</Box>
            </MenuItem>
            <MenuItem className={menuItem} onClick={() => setMenu('operation', 'PURCHASE')}>
              <Box className={menuItemBox}>Compra</Box>
            </MenuItem>
          </Menu>

          <Tooltip title="Tipo de Preço">
            <Button
              id={`${operation?.id}-price-type`}
              onClick={handleClickPriceTypeMenu}
              endIcon={<ArrowDown className={arrowDownIcon} />}
              disableElevation={isReadOnly}
              disableFocusRipple={isReadOnly}
              disableRipple={isReadOnly}
              disableTouchRipple={isReadOnly}
              style={{ width: '82px' }}
              {...register(`operations.${index}.priceType`)}
            >
              {operation.priceType?.replace('FIXO', 'Fixo').replace('SPREAD', 'Spread')}
            </Button>
          </Tooltip>
          <Menu
            id={`${operation?.id}-price-type-menu`}
            className={menu}
            anchorEl={anchorElPriceType}
            getContentAnchorEl={null}
            open={anchorElPriceType?.id === `${operation?.id}-price-type`}
            onClose={handleClosePriceTypeMenu}
            transitionDuration={0}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <MenuItem className={menuItem}>
              <Box className={menuItemBox} onClick={() => setMenu('priceType', 'FIXO')}>
                Fixo
              </Box>
            </MenuItem>
            <MenuItem className={menuItem}>
              <Box className={menuItemBox} onClick={() => setMenu('priceType', 'SPREAD')}>
                Spread
              </Box>
            </MenuItem>
          </Menu>
        </Box>

        <Box display="flex" flexDirection="column" gridGap="4px">
          <Tooltip title="Submercado">
            <Button
              id={`${operation?.id}-submarket-type`}
              onClick={handleClickMenu}
              endIcon={<ArrowDown className={arrowDownIcon} />}
              disableElevation={isReadOnly}
              disableFocusRipple={isReadOnly}
              disableRipple={isReadOnly}
              disableTouchRipple={isReadOnly}
              {...register(`operations.${index}.submarket`)}
            >
              <span style={{ width: '30px' }}>{operation.submarket}</span>
            </Button>
          </Tooltip>
          <Menu
            id={`${operation?.id}-submarket-type-menu`}
            className={menu}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            open={anchorEl?.id === `${operation?.id}-submarket-type`}
            onClose={handleCloseMenu}
            transitionDuration={0}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            {['SE', 'S', 'NE', 'N'].map((submarket) => (
              <MenuItem key={submarket} onClick={() => setMenu('submarket', submarket as SubmarketTypes)} className={menuItem}>
                <Box className={menuItemBox}>{submarket}</Box>
              </MenuItem>
            ))}
          </Menu>
          <Tooltip title="Tipo de energia">
            <Button
              id={`${operation?.id}-energy-type`}
              onClick={handleClickMenu}
              endIcon={<ArrowDown className={arrowDownIcon} />}
              disableElevation={isReadOnly}
              disableFocusRipple={isReadOnly}
              disableRipple={isReadOnly}
              disableTouchRipple={isReadOnly}
              {...register(`operations.${index}.energyType`)}
            >
              <span style={{ width: '30px' }}>{operation.energyType}</span>
            </Button>
          </Tooltip>
          <Menu
            id={`${operation?.id}-energy-type-menu`}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            open={anchorEl?.id === `${operation?.id}-energy-type`}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            {['C', 'I0', 'I5', 'I8', 'I1', 'INE5'].map((energyType) => (
              <MenuItem key={energyType} onClick={() => setMenu('energyType', energyType as EnergyTypes)} className={menuItem}>
                <Box className={menuItemBox}>{energyType}</Box>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>
      {hidden.includes(operation.id) && (
        <Box
          height="80px"
          width="100vw"
          style={{
            position: 'absolute',
            top: '0',
            background: `${colorTernary('#AAAAAA80', '#FFFFFF80')}`,
            backdropFilter: 'blur(0.5px)',
          }}
        />
      )}
    </TableVerticalAxis>
  );
};

export default OperationMenu;
