import { createIcon } from "@tradener/lumen";

const RoundedSquareIcon = createIcon({
  displayName: 'RoundedSquareIcon',
  viewBox: '0 0 13 12',
  path: (
    <>
      <path d="M0.5 3C0.5 1.34315 1.84315 0 3.5 0H9.5C11.1569 0 12.5 1.34315 12.5 3V9C12.5 10.6569 11.1569 12 9.5 12H3.5C1.84315 12 0.5 10.6569 0.5 9V3Z" fill="currentColor"/>
    </>
  )
});

export default RoundedSquareIcon;
