import { Tooltip, Text } from '@tradener/lumen';

import { useBbceProduct } from '../../..';
import OfferListItem from '../OfferListItem';
import useProductQuote from '../useProductQuote';

function OfferName() {
  const { id } = useBbceProduct();
  const { opportunity, accountName } = useProductQuote();
  const isInternalTransfer = opportunity.bbceProduct?.id !== id;

  return (
    <OfferListItem>
      <Tooltip
        bgColor="gray.900"
        color="gray.0"
        borderRadius="base"
        label={accountName}
        hasArrow
        maxWidth="64"
        width="fit-content"
        shouldWrapChildren
      >
        <Text
          width="fit-content"
          maxWidth="64"
          overflowX="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          color={isInternalTransfer ? 'gray.500' : 'gray.900'}
        >
          {isInternalTransfer ? 'Transferência interna' : accountName}
        </Text>
      </Tooltip>
    </OfferListItem>
  );
}

export default OfferName;
