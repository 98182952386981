import { SkeletonProps, Skeleton as LumenSkeleton } from '@tradener/lumen';

import { Item } from '../Item';

export const Skeleton: React.FC<SkeletonProps> = () => (
  <>
    {[0].map((item, index) => (
      <LumenSkeleton key={item} isLoaded={false}>
        <Item index={index} />
      </LumenSkeleton>
    ))}
  </>
);
