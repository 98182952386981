import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import dot from 'dot-object';

import store from '~/context';
import { actions } from '~/context/ducks/auth';

import renewSession, { getCurrentSession, isExpired, setSession } from './session';

const http = axios.create({
  baseURL: process.env.REACT_APP_CORE_API_URL,
  headers: { 'content-type': 'application/json' },
  transformResponse: (response, headers) => {
    if (headers && /application\/json/i.test(headers['content-type'])) {
      const data = JSON.parse(response);

      if ('errors' in data || 'error' in data) {
        const message =
          data.errors?.map(({ full_messages, message }) => full_messages ?? message).join('\n') ?? data.error?.message;

        throw Error(message);
      }

      return data;
    }

    return response;
  },
});

const fetcher = async <T>(url: string, config: AxiosRequestConfig<T> = {}) => {
  const { token, refreshToken } = getCurrentSession();

  if (token && isExpired(token) && refreshToken) {
    const session = await renewSession();

    if (session) {
      dot.set('headers.authorization', `Bearer ${session.token}`, config);

      setSession(session);
    }
  }

  if (!refreshToken) {
    store.dispatch(actions.signOut());
  } else {
    const { data } = await http({ url, method: 'POST', ...config });

    return data as T;
  }
};

const handleFulfilled = (response: AxiosResponse) => {
  const { token, refreshToken } = getCurrentSession();
  const { headers } = response.config;
  const authorization = headers?.['Authorization'];

  if (authorization) {
    const [, auth] = authorization.split(' ');

    if (token !== auth) setSession({ token: auth, refresh_token: refreshToken! });
  }

  return response;
};

const handleRejected = async (error: any) => {
  if (error?.response?.status === 401) {
    const session = await renewSession();

    if (session) {
      dot.set('headers.authorization', `Bearer ${session.token}`, error.config);

      setSession(session);
    }

    return http(error.config);
  }

  return Promise.reject(error);
};

http.interceptors.response.use(handleFulfilled, handleRejected);

export { fetcher };
export default http;
