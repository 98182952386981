import { forwardRef } from 'react';

import { DialogContent, DialogContentProps } from '@material-ui/core';

import useStyles from './styles';

const ModalBody = forwardRef<HTMLDivElement, DialogContentProps>(({ children, ...props }, ref) => {
  const classes = useStyles();

  return (
    <DialogContent ref={ref} classes={classes} {...props}>
      {children}
    </DialogContent>
  );
});

ModalBody.displayName = 'ModalBody';

export default ModalBody;
