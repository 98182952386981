import { useCallback, useRef, useState } from 'react';

import { removeEmpty } from '@tradener/lumen';

import useOutsideClick from './useOutsideClick';
import useUpdateEffect from './useUpdateEffect';

export interface EditableProps {
  isEditable: boolean;
  isRequired: boolean;
  transform: (value: string) => string;
}

export interface EditionProps extends Omit<React.HTMLAttributes<HTMLElement>, 'onInput' | 'onSubmit'> {
  onSubmit(value: string): void;
  onFocus?: () => void;
}

const defaultConfig: EditableProps = { isEditable: true, isRequired: false, transform: (value) => value };

function useEditable(initialValue = '', config: Partial<EditableProps> = defaultConfig) {
  const { isEditable, isRequired, transform } = { ...defaultConfig, ...removeEmpty(config) };
  const parentRef = useRef<HTMLElement>(null);
  const editionRef = useRef<HTMLElement>(null);
  const onSubmitRef = useRef<EditionProps['onSubmit']>();
  const defaultValue = useRef(transform(initialValue));
  const [contentEditable, setContentEditable] = useState<boolean>();
  const [value, setValue] = useState(defaultValue.current);
  const [currentValue, setCurrentValue] = useState(defaultValue.current);

  const onBlur = useCallback(() => {
    if (contentEditable) {
      setContentEditable(currentValue || !isRequired ? undefined : true);

      if (isRequired || currentValue !== value) {
        const formatted = transform(currentValue);

        setValue(formatted);

        onSubmitRef.current?.(formatted);
      }
    }
  }, [isRequired, contentEditable, currentValue, value, transform]);

  const handleKeyDown = useCallback(
    (onKeyDown?: React.KeyboardEventHandler<HTMLElement>): React.KeyboardEventHandler<HTMLElement> =>
      (event) => {
        if (onKeyDown) onKeyDown(event);

        if (event.key === 'Enter') onBlur();
      },
    [onBlur],
  );

  const onInput: React.FormEventHandler<HTMLElement> = ({ target }) => {
    const currentValue = (target as HTMLElement).innerText.trim();

    setCurrentValue(currentValue);
  };

  const onClick = () => setContentEditable(true);

  const getParentProps = <T = React.PropsWithRef<React.HTMLAttributes<HTMLElement>>>(props?: T) => {
    if (!isEditable) return props as ReturnType<typeof getParentProps>;

    return {
      ...props,
      ref: parentRef,
      tabIndex: 0,
      onFocus: onClick,
      onClick,
      onBlur,
    } as ReturnType<typeof getParentProps>;
  };

  const getEditionProps = useCallback(
    ({ onKeyDown, onSubmit, ...props }: EditionProps) => {
      if (onSubmit) onSubmitRef.current = onSubmit;

      return {
        ref: editionRef,
        children: value,
        suppressContentEditableWarning: true,
        tabIndex: -1,
        onKeyDown: handleKeyDown(onKeyDown),
        onInput,
        contentEditable,
        ...props,
      } as React.PropsWithRef<React.HTMLAttributes<HTMLElement>>;
    },
    [contentEditable, handleKeyDown, value],
  );

  const reset = useCallback((value?: string) => {
    setValue(value ?? defaultValue.current);
    setCurrentValue(value ?? defaultValue.current);
    setContentEditable(false);
  }, []);

  useUpdateEffect(() => {
    if (contentEditable && editionRef.current) {
      window.getSelection()?.selectAllChildren(editionRef.current);
    }
  }, [contentEditable]);

  useUpdateEffect(() => {
    setValue(initialValue);
    setCurrentValue(initialValue);
  }, [initialValue]);

  useOutsideClick({
    ref: parentRef,
    enabled: isEditable ? contentEditable : false,
    handler: onBlur,
  });

  return {
    isEditing: contentEditable,
    initialValue: defaultValue.current,
    value,
    getParentProps,
    getEditionProps,
    reset,
  };
}

export default useEditable;
