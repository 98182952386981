import { useState } from 'react';

import { Button, CircularProgress } from '@material-ui/core';
import { Flex, FlexProps } from '@tradener/lumen';

import useColorMode from '~/hooks/useColorMode';

import useSubmitQueue from '../context';

const SimulationCommit: React.ComponentType<FlexProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const { queue, clearQueue } = useSubmitQueue();
  const { colorTernary, theme } = useColorMode();

  const onClose = () => {
    for (const { onCancel } of queue) {
      onCancel();
    }
    clearQueue();
  };

  const onApply = async () => {
    try {
      setLoading(true);
      for await (const { onSubmit } of queue) {
        await onSubmit();
      }
    } finally {
      setLoading(false);
      clearQueue();
      onClose();
    }
  };

  if (!queue.length) return null;

  return (
    <Flex
      position="sticky"
      left="0"
      right="0"
      bottom="0"
      width="calc(100vw - 92px)"
      justifyContent="center"
      alignItems="center"
      gridColumnGap="2"
      height="16"
      bgColor={colorTernary(theme.palette.grey[700], 'white')}
      zIndex="popover"
      boxShadow="0px -4px 12px rgba(0, 0, 0, 0.12);"
      {...props}
    >
      <Button variant="outlined" color="secondary" style={{ height: '40px' }} onClick={onClose} disabled={loading}>
        Cancelar
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{ backgroundColor: loading ? theme.palette.grey[300] : '#4383F7', height: '40px' }}
        onClick={onApply}
        disabled={loading}
      >
        {loading ? <CircularProgress size="24px" color="inherit" /> : 'Aplicar'}
      </Button>
    </Flex>
  );
};

export default SimulationCommit;
