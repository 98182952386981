import MenuItemRaw from '@material-ui/core/MenuItem';
import TableBodyRaw from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHeadRaw from '@material-ui/core/TableHead';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  overflow-x: auto;
`;

export const TableHead = styled(TableHeadRaw)`
  && {
    border-radius: 12px;
    height: 56px !important;
    tr {
      height: 56px !important;
      th {
        height: 56px !important;
      }
    }
  }
`;

export const TableCellHead = styled(TableCell).attrs((props) => ({
  align: props.align || 'left',
}))`
  ${({ transform }) => css`
    && {
      font-size: 16px;
      line-height: 17px;
      font-weight: bold;
      text-transform: ${transform || 'uppercase'};
      border-color: transparent;
    }
  `}
`;

export const MenuItem = styled(MenuItemRaw)`
  && {
  }
`;
