import { cloneElement, isValidElement } from 'react';

import { Brush as RechartsBrush, BrushProps, Layer } from 'recharts';

export interface BrushTravellerProps {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
}

export type BrushTravellerType =
  | React.ReactElement<SVGElement>
  | ((props: BrushTravellerProps) => React.ReactElement<SVGElement>);

class Brush extends RechartsBrush {
  constructor(props: BrushProps) {
    super(props);
  }

  static renderDefaultTraveller({ id, x, y, width }: BrushTravellerProps) {
    const isLeftSide = /startX/.test(id);

    return (
      <>
        <g filter={`url(#${id})`}>
          <rect x={isLeftSide ? x + 5 : x - 5} y={y + 13} width={width} height="32" rx="5" fill="white" />
          <rect x={isLeftSide ? x + 9 : x - 1} y={y + 19} width={width * 0.2} height="20" rx="1" fill="#EEEEEE" />
        </g>
        <defs>
          <filter
            id={id}
            x={isLeftSide ? x : x - 5}
            y={y + 10}
            width="18"
            height="40"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6842_28667" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6842_28667" result="shape" />
          </filter>
        </defs>
      </>
    );
  }

  static renderTraveller(traveller: BrushTravellerType | undefined, props: BrushTravellerProps) {
    if (isValidElement(traveller)) return cloneElement(traveller, props);

    if (traveller instanceof Function) return traveller(props);

    return Brush.renderDefaultTraveller(props);
  }

  renderTravellerLayer(xAxis: number, id: string) {
    return (
      <Layer
        className="recharts-brush-traveller"
        onMouseEnter={this.handleEnterSlideOrTraveller}
        onMouseLeave={this.handleLeaveSlideOrTraveller}
        onMouseDown={this.travellerDragStartHandlers?.[id]}
        onTouchStart={this.travellerDragStartHandlers?.[id]}
        style={{ cursor: 'col-resize' }}
      >
        {Brush.renderTraveller(this.props.traveller, {
          x: Math.max(xAxis, this.props?.x ?? 0),
          y: this.props.y ?? 0,
          width: this.props.travellerWidth ?? 0,
          height: this.props.height,
          id,
        })}
      </Layer>
    );
  }

  renderSlide(startX: number, endX: number) {
    const width = this.props.travellerWidth ?? 0;
    const absoluteWidth = Math.abs(endX - startX);

    return (
      <rect
        className="recharts-brush-slide"
        onMouseEnter={this.handleEnterSlideOrTraveller}
        onMouseLeave={this.handleLeaveSlideOrTraveller}
        onMouseDown={this.handleSlideDragStart}
        onTouchStart={this.handleSlideDragStart}
        x={Math.min(startX, endX) + width}
        y={this.props.y}
        width={Math.max(absoluteWidth - width, 0)}
        height={this.props.height}
        fillOpacity="0.08"
        fill="var(--lumen-colors-gray-900)"
        stroke="none"
        rx="8"
        style={{ cursor: 'move' }}
      />
    );
  }
}

export default Brush;
