import { Provider as ReduxProvider, useDispatch } from 'react-redux';
import { BrowserRouter as RouterProvider } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import { LumenProvider } from '@tradener/lumen';
import { ptBR } from 'date-fns/locale';
import { LocalizationProvider } from 'material-picker-next';
import LuxonUtils from 'material-picker-next/adapter/luxon';
import { SnackbarProvider } from 'notistack';
import { PersistGate } from 'redux-persist/integration/react';

import store, { persistor } from '~/context';
import { actions } from '~/context/ducks/notification';
import useAuth from '~/hooks/useAuth';
import useColorMode from '~/hooks/useColorMode';
import client from '~/services/apollo';
import Snackbar from '~/theme/components/Snackbar';
import ThemeProvider from '~/theme/index';
import { getTenants } from '~/utils/getTenants';
import { getTradingUsers } from '~/utils/getTradingUsers';

const withReduxProvider =
  <T,>(Component: React.ComponentType<T>) =>
  (props: React.PropsWithChildren<T>) =>
    (
      <ReduxProvider store={store}>
        <Component {...props} />
      </ReduxProvider>
    );

const Provider = withReduxProvider<React.PropsWithChildren>(({ children }) => {
  const color = useColorMode();
  const { isUserIn } = useAuth();
  const dispatch = useDispatch();

  const handleNotistack = (value: SnackbarProvider) => dispatch(actions.ref(value));

  const handleBeforeLift = async () => {
    if (isUserIn) {
      await getTenants();
      await getTradingUsers();
    }
  };

  return (
    <RollbarProvider
      config={{
        enabled: process.env.NODE_ENV === 'production',
        environment: process.env.REACT_APP_ROLLBAR_ENV,
        accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          client: {
            javascript: {
              code_version: '1.0.0',
              source_map_enabled: true,
            },
          },
        },
      }}
    >
      <ErrorBoundary>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
          <PersistGate onBeforeLift={handleBeforeLift} persistor={persistor}>
            <ApolloProvider client={client}>
              <RouterProvider>
                <LumenProvider theme="TRADENER" colorMode={color.mode}>
                  <ThemeProvider>
                    <CssBaseline />
                    <LocalizationProvider dateAdapter={LuxonUtils} locale="pt-BR">
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                        <SnackbarProvider
                          ref={handleNotistack}
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          content={(key, message) => <Snackbar id={key as string} message={message} />}
                          maxSnack={3}
                        >
                          {children}
                        </SnackbarProvider>
                      </MuiPickersUtilsProvider>
                    </LocalizationProvider>
                  </ThemeProvider>
                </LumenProvider>
              </RouterProvider>
            </ApolloProvider>
          </PersistGate>
        </GoogleOAuthProvider>
      </ErrorBoundary>
    </RollbarProvider>
  );
});

const withProvider =
  <T,>(Component: React.ComponentType<T>) =>
  (props: React.PropsWithChildren<T>) =>
    (
      <Provider>
        <Component {...props} />
      </Provider>
    );

export { withProvider, withReduxProvider };
export default Provider;
