import { Routes as Switch, Route, Navigate } from 'react-router-dom';

import AppLogin_DEPRECATED from '@DEPRECATED/pages/AppLogin';
import BookChart_DEPRECATED from '@DEPRECATED/pages/Book/BookChart';
import BookList_DEPRECATED from '@DEPRECATED/pages/Book/BookList';
import Portfolio_DEPRECATED from '@DEPRECATED/pages/Portfolio';

import SendProposal from '~/DEPRECATED/pages/Book/BookList/SendProposal';
import OperationLimits from '~/pages/OperationLimits';
import PortfolioTable from '~/pages/Portfolio/PortfolioTable';
import PortfolioTable_DEPRECATED from '~/pages/Portfolio/PortfolioTable_DEPRECATED';
import SimulationLayout from '~/pages/Portfolio/Simulation/Layout';
import SimulationContracts from '~/pages/Portfolio/Simulation/SimulationContracts';
import SimulationTable from '~/pages/Portfolio/Simulation/SimulationTable';
import SignIn from '~/pages/SignIn';
import TradingChart from '~/pages/Trading/TradingChart';
import TradingList from '~/pages/Trading/TradingList';
import ListOffer from '~/pages/Trading/TradingList/FormOffer';
import ProductAnalytics from '~/pages/Trading/TradingList/ListProducts/ListItem/ProductAnalytics';
import ProductChart from '~/pages/Trading/TradingList/ListProducts/ListItem/ProductChart';
import OfferDetails from '~/pages/Trading/TradingList/ListProducts/ListItem/ProductOffers/OperationList/OfferDetails';
import Layout from '~/theme/layout';

import useSession from './useSession';

export default function Routes() {
  const { withSession, withNoSession } = useSession();
  const SessionRequired = withSession(Layout);
  const GoogleOAuth = withNoSession(SignIn);
  const AppLogin = withNoSession(AppLogin_DEPRECATED);

  return (
    <Switch>
      <Route path="/" element={<GoogleOAuth />} />
      <Route path="/app" element={<AppLogin />} />
      <Route path="/portfolio" element={<SessionRequired />}>
        <Route path="simulation" element={<SimulationLayout />}>
          <Route path="contracts/:operation" element={<SimulationContracts />} />
          <Route element={<SimulationTable />} index />
        </Route>
        <Route path="table/*" element={<SimulationLayout />}>
          <Route path="contracts/:operation" element={<SimulationContracts />} />
          <Route path="old" element={<PortfolioTable_DEPRECATED />} />
          <Route element={<PortfolioTable />} index />
        </Route>
        <Route path="chart" element={<Portfolio_DEPRECATED />} />
      </Route>
      <Route path="/book" element={<SessionRequired />}>
        <Route path="list/*" element={<BookList_DEPRECATED />} />
        <Route path="chart" element={<BookChart_DEPRECATED />} />
        <Route element={<Navigate to="list" />} index />
      </Route>
      <Route path="/trading/*" element={<SessionRequired />}>
        <Route path="list/analytics/:id/*" element={<ProductAnalytics />} />
        <Route path="list/*" element={<TradingList />}>
          <Route path="offer/*" element={<ListOffer />} />
          <Route path=":id/*">
            <Route path="chart" element={<ProductChart />} />
            <Route path="show" element={<OfferDetails />} />
            <Route path="send-proposal" element={<SendProposal />} />
          </Route>
        </Route>
        <Route path="dashboard" element={<TradingChart />} />
        <Route path="*" element={<Navigate to="list" />} />
      </Route>
      <Route path="/limits" element={<SessionRequired />}>
        <Route path="*" element={<OperationLimits />} />
      </Route>
      <Route path="*" element={<Navigate to="/book/list" />} />
    </Switch>
  );
}
