import cable from '~/services/link/cable';

export type Channels = 'WebNotificationsChannel' | 'AccountTypeaheadChannel' | 'BbceProductNamesChannel';

export interface SocketSubProps<T> {
  sid: string;
  channel: Channels;
  onMessage: (value: T) => void;
  [key: string]: unknown;
}

const createSocketSub = <T>({ onMessage, ...config }: SocketSubProps<T>) =>
  cable.subscriptions.create(config as { channel: string }, { received: onMessage });

export default createSocketSub;
