import { useCallback } from 'react';

import { Container } from './styles';

interface MessagesEventsProps {
  event: 'dropped' | 'bounce' | 'spamreport' | 'rendering_failur' | 'delivered' | 'open' | 'click';
}

interface EmailMessagesProps {
  id: string;
  createdAt: string;
  emailMessageEvents: Array<MessagesEventsProps>;
  recipient: {
    email: string;
  };
}

interface QuoteProps {
  data: Array<EmailMessagesProps>;
}

const Email: React.FC<QuoteProps> = ({ data }) => {
  const getDate = useCallback((createdAt: string) => {
    function twoDigits(value: number) {
      const parsed = String(value);

      if (parsed.length < 2) {
        return `0${value}`;
      }

      return parsed;
    }
    const date = new Date(createdAt);
    const day = twoDigits(date.getDate());
    const month = twoDigits(date.getMonth() + 1);
    const year = date.getFullYear();
    const hours = twoDigits(date.getHours());
    const minutes = twoDigits(date.getMinutes());

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }, []);
  const getStatus = useCallback((message: Array<MessagesEventsProps>) => {
    switch (message?.[0]?.event ?? 'delivered') {
      case 'dropped':
      case 'bounce':
      case 'spamreport':
      case 'rendering_failur':
        return 'DEVOLVIDO';
      case 'open':
      case 'click':
        return 'ENTREGUE';
      case 'delivered':
      default:
        return 'ENVIADO';
    }
  }, []);
  const getInfo = useCallback(
    (message: Array<MessagesEventsProps>) => {
      const sizeof = (data: MessagesEventsProps['event']) => message.filter(({ event }) => event === data)?.length ?? 0;
      let opened = 0;
      let clicked = 0;

      if (message.length) {
        if (getStatus(message) === 'ENTREGUE') {
          opened = sizeof('open');
          clicked = sizeof('click');
        }
      }

      return { opened, clicked };
    },
    [getStatus],
  );

  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th>Enviado em</th>
            <th>Destinatário</th>
            <th>Status</th>
            <th>Aberto</th>
            <th>Clicks</th>
          </tr>
        </thead>
        <tbody>
          {data.map(({ recipient, emailMessageEvents, ...message }) => (
            <tr key={message.id}>
              <td>{getDate(message.createdAt)}</td>
              <td>{recipient.email}</td>
              <td>{getStatus(emailMessageEvents)}</td>
              <td style={{ textAlign: 'center' }}>{getInfo(emailMessageEvents).opened}</td>
              <td style={{ textAlign: 'center' }}>{getInfo(emailMessageEvents).clicked}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

export default Email;
