import { EditIcon } from '@tradener/lumen';

import OptionItem from '../../OptionItem';
import { useQuoteOptions } from '../../useQuoteOptions';

function ActionEdit() {
  const { navigate } = useQuoteOptions();

  const onPushToForm = () => navigate({ type: 'edit' });

  return (
    <OptionItem stage="OPENED" onClick={onPushToForm} icon={<EditIcon boxSize="5" />}>
      Editar
    </OptionItem>
  );
}

export default ActionEdit;
