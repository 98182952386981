import { useTheme, Theme } from '@tradener/lumen';

import { GraphProps } from '../../priceKeys';

export interface CandleShapeProps extends GraphProps {
  x: number;
  y: number;
  width: number;
  height: number;
}

const CandleShape: React.ComponentType<CandleShapeProps> = ({ x, y, width, height, low, high, range }) => {
  const { colors } = useTheme<Theme>();

  const [open, close] = range ?? [0, 0];
  const isOpenCloseEqual = !!open && !!close && open === close;
  const ascending = open <= close;
  const color = colors[ascending ? 'green' : 'red'][500];
  const ratio = isOpenCloseEqual ? 1 : Math.abs(height / (open - close));

  if (isNaN(ratio)) return null;

  return (
    <g stroke={color} fill={color} strokeWidth="3">
      <path
        d={`M ${x}, ${ascending ? y - 1.5 : y + 1.5} L ${x}, ${y + height} L ${x + width}, ${y + height} L ${
          x + width
        }, ${y} L ${x}, ${y}`}
      />
      {ascending ? (
        <path d={`M ${x + width / 2}, ${y + height}v ${(open - Number(low)) * ratio}`} />
      ) : (
        <path d={`M ${x + width / 2}, ${y}v ${(close - Number(low)) * ratio}`} />
      )}
      {ascending ? (
        <path d={`M ${x + width / 2}, ${y} v ${(close - Number(high)) * ratio}`} />
      ) : (
        <path d={`M ${x + width / 2}, ${y + height} v ${(open - Number(high)) * ratio}`} />
      )}
    </g>
  );
};

export default CandleShape;
