import { GridItem, BoxProps, useTheme } from '@tradener/lumen';

export interface TdProps extends BoxProps {
  children?: React.ReactNode;
  colSpan?: number;
  props?: BoxProps;
}

export const Td: React.FC<TdProps> = ({ children, colSpan, ...props }) => {
  const color = useTheme().colors.gray[900];

  return (
    <GridItem
      p={3}
      alignContent="center"
      colSpan={colSpan}
      border="0px"
      borderTop="solid 1px"
      borderColor={`${color}14`}
      {...props}
    >
      {children}
    </GridItem>
  );
};
