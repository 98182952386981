import MUIChip from '@material-ui/core/Chip';
import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled(MUIChip)`
  && {
    display: flex;
    text-transform: uppercase;
    font-weight: 700;
    height: 16px;
    font-size: 10px;
  }
`;
