import { LazyQueryExecFunction, useLazyQuery } from '@apollo/client';
import { useToast } from '@tradener/lumen';

import DOWNLOAD_OPERATION_LIMITS, { ResponseProps, PayloadProps } from '@graphql/query/operationLimits/download';

interface DownloadOperationLimitsProps {
  loading: boolean;
  download: LazyQueryExecFunction<ResponseProps, PayloadProps>;
}

const useDownloadOperationLimits = () => {
  const toast = useToast();

  const [download, { loading }] = useLazyQuery<ResponseProps, PayloadProps>(DOWNLOAD_OPERATION_LIMITS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'standby',
    onCompleted: ({ downloadOperationLimits }) => {
      window.open(downloadOperationLimits?.downloadUrl, '_self');
    },
    onError: ({ message }) => toast({ title: 'Erro', status: 'error', description: message || 'Erro ao baixar dados!' }),
  });

  return {
    download,
    loading,
  } as DownloadOperationLimitsProps;
};

export default useDownloadOperationLimits;
