import { SendIcon } from '@tradener/lumen';

import OptionItem from '../../OptionItem';
import useChangeQuoteStage from '../../useChangeQuoteStage';
import { useQuoteOptions } from '../../useQuoteOptions';

function ActionEmit() {
  const changeQuoteStage = useChangeQuoteStage();
  const { onOpen } = useQuoteOptions();

  const openDialog = () => {
    onOpen('PROPOSE', changeQuoteStage);
  };

  return (
    <OptionItem stage="OPENED" onClick={openDialog} icon={<SendIcon boxSize="5" />}>
      Emitir proposta
    </OptionItem>
  );
}

export default ActionEmit;
