import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import { StateProps as AuthProps } from './ducks/auth';
import { StateProps as LuminanceProps } from './ducks/colorMode';
import { StateProps as NotificationProps } from './ducks/notification';
import reducers from './reducers';
import sagas from './sagas';

export interface RootStateProps {
  auth: AuthProps;
  colorMode: LuminanceProps;
  notification: NotificationProps;
}

const sagaMiddleware = createSagaMiddleware();

const composedEnhancer = composeWithDevTools(applyMiddleware(sagaMiddleware));

const store = createStore(reducers, composedEnhancer);

sagaMiddleware.run(sagas);

export const persistor = persistStore(store);

export default store;
