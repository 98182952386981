import { gql } from '@apollo/client';

export interface PayloadProps {
  simulationId: string;
}

export interface ResponseProps {
  downloadSimulationContractUsageFactor: DownloadSimulationContractUsageFactorProps;
}

export interface DownloadSimulationContractUsageFactorProps {
  status: string;
  downloadUrl: string;
}

const DOWNLOAD_SIMULATION_CONTRACT_USAGE_FACTOR = gql`
  query downloadSimulationContractUsageFactor($simulationId: String!) {
    downloadSimulationContractUsageFactor(simulationId: $simulationId) {
      status
      downloadUrl
    }
  }
`;

export default DOWNLOAD_SIMULATION_CONTRACT_USAGE_FACTOR;
