import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  period: {
    '& > span': {
      visibility: 'hidden',
    },
    '&:hover > span': {
      visibility: 'visible',
    },
    cursor: 'pointer',
  },
  icon: {
    transform: 'rotate(90deg)',
    marginTop: '-2px',
  },
});

export default useStyles;
