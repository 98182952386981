import { Outlet } from 'react-router-dom';

import { Box } from '@material-ui/core';

import useColorMode from '~/hooks/useColorMode';
import StagingBar from '~/theme/components/StagingBar';

import LeftSideBar from './LeftSideBar';

function Layout() {
  const staging = process.env.REACT_APP_ROLLBAR_ENV === 'staging';
  const { mode } = useColorMode();

  return (
    <>
      {staging && <StagingBar />}
      <Box
        display="grid"
        gridTemplateColumns="4.5rem 1fr"
        bgcolor={mode === 'dark' ? '#171717' : 'inherit'}
        fontFamily="Archivo, roboto, sans-serif !important"
        minHeight={!staging ? '100dvh' : 'calc(100dvh - 2.3125rem)'}
      >
        <LeftSideBar style={{ top: staging ? '2.3125rem' : '0rem' }} />
        <Box height="100%" width="3.5rem" style={{ top: staging ? '2.3125rem' : '0px' }} />
        <Box display="flex" flexDirection="column" bgcolor={mode === 'dark' ? '#171717' : 'inherit'}>
          <Outlet context={{ staging }} />
        </Box>
      </Box>
    </>
  );
}

export default Layout;
