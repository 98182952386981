import { useCallback, useState } from 'react';

import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useLocation, useNavigate, To } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';
import { fastProvider, pickByPaths, removeKeys, useToast } from '@tradener/lumen';
import { DateTime } from 'luxon';

import UPSERT_TRADING_QUOTE, { ResponseProps, PayloadProps } from '@graphql/mutation/upsertTradingQuote';
import GET_QUOTE, {
  ResponseProps as QuoteResponseProps,
  PayloadProps as QuotePayloadProps,
  QuoteItemProps,
} from '@graphql/query/quote';

import useQueryParam from '~/hooks/useQueryParam';
import { DateYearMonthProps } from '~/theme/components/MonthRange';

import { FormProps } from '.';
import useProducts from '../ListProducts/useProducts';

export type FormActionType = 'create' | 'edit' | 'duplicate' | 'renegotiate';

export interface QueryParamsProps {
  type?: FormActionType;
  bbce_product_id?: string;
  quote_id?: string;
}

const translateType = (type?: string) => {
  switch (type) {
    case 'create':
      return 'criada';
    case 'edit':
      return 'editada';
    case 'duplicate':
      return 'duplicada';
    case 'renegotiate':
      return 'renegociada';
    default:
      return '';
  }
};

const [withOffer, useOffer] = fastProvider(() => {
  const navigate = useNavigate();
  const toast = useToast();
  const { onUnfold } = useProducts();
  const { setValue, getValues, reset, trigger } = useFormContext<FormProps>();
  const [moreOptions, setMoreOptions] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const { key } = useLocation();
  const {
    type,
    bbce_product_id: bbceProductId,
    quote_id: quoteId,
  } = useQueryParam('type', 'bbce_product_id', 'quote_id') as QueryParamsProps;

  const [createQuote, { loading }] = useMutation<ResponseProps, PayloadProps>(UPSERT_TRADING_QUOTE);

  const onClose = () => {
    navigate((key ? '/trading/list' : -1) as To);
  };

  const { data: quote, loading: quoteLoading } = useQuery<QuoteResponseProps, QuotePayloadProps>(GET_QUOTE, {
    fetchPolicy: 'no-cache',
    variables: { id: quoteId as string },
    skip: !(type && /edit|duplicate|renegotiate/.test(type) && quoteId),
    onCompleted(data) {
      if (data) {
        const { opportunity, quoteItems } = data.quote;
        const bbceProductFrom = opportunity.bbceProductTransfer?.bbceProductFrom;
        const bbceProductTo = opportunity.bbceProductTransfer?.bbceProductTo;
        const quote = pickByPaths(data.quote, 'notes', 'swap', 'externalId', 'bbceProductTransfer');

        Object.entries({
          ...(quote as Partial<FormProps>),
          id: type && /edit/.test(type) ? quoteId! : undefined,
          succeededId: type && /renegotiate/.test(type) ? quoteId! : undefined,
          bbceProductTransferId: opportunity.type === 'SALE' ? bbceProductFrom?.id : bbceProductTo?.id,
          bbceProductId: opportunity.bbceProduct?.id,
          operation: opportunity.type,
          buyerName: opportunity.buyer?.name,
          sellerName: opportunity.seller?.name,
          buyerId: opportunity.buyer?.id,
          sellerId: opportunity.seller?.id,
          quoteItems: quoteItems?.map(({ amount, quantity }) => ({ amount, quantity })) as QuoteItemProps[],
        }).forEach(([name, value]: any) => setValue(name, value));

        if (type && /duplicate/.test(type)) {
          setValue('externalId', undefined);
          setValue('notes', undefined);
          setValue('swap', false);
          setValue('bbceProductTransfer', undefined);
        }

        trigger();
      }
    },
  });

  const onSubmit: SubmitHandler<FormProps> = ({ ...quote }) => {
    const data = removeKeys(
      quote,
      'accountName',
      'accountId',
      'tenantId',
      'buyerName',
      'sellerName',
      'bbceProductTransfer',
    ) as FormProps;
    const quoteItem = getValues('quoteItems.0');

    createQuote({
      variables: {
        input: {
          ...data,
          quoteItems: [{ ...quoteItem }],
        },
      },
      onCompleted: () => {
        toast({ status: 'success', title: 'Successo', description: `Cotação ${translateType(type!)}` });

        if (bbceProductId) {
          onUnfold(bbceProductId);
        }

        onClose();
        reset();
      },

      onError: ({ message }) => toast({ status: 'error', description: message }),
    });
  };

  const getMonthRange = useCallback(
    (monthRange: DateYearMonthProps) => {
      const { startYear, startMonth, endYear, endMonth } = monthRange;

      if (startYear && startMonth && endYear && endMonth) {
        const startDate = DateTime.fromFormat(`${startYear}-${startMonth}-01`, 'yyyy-M-dd').toISODate();
        const endDate = DateTime.fromFormat(
          `${endYear}-${endMonth}-${DateTime.local(endYear, endMonth).daysInMonth}`,
          'yyyy-M-dd',
        ).toISODate();

        setStartDate(startDate);
        setEndDate(endDate);
        setValue('quoteItems.0.startDate', startDate);
        setValue('quoteItems.0.endDate', endDate);
      }
    },
    [setValue],
  );

  return {
    onClose,
    getMonthRange,
    setMoreOptions,
    onSubmit,
    startDate,
    endDate,
    moreOptions,
    loading,
    quoteLoading,
    bbceProductId,
    quote,
  };
});

export { useOffer, withOffer, useFormContext };
