import { Controller } from 'react-hook-form';

import {
  Input,
  InputGroup,
  InputRightElement,
  InputProps,
  IconButton,
  CloseIcon,
  SearchIcon,
  PopoverAnchor,
  Box,
  Skeleton,
} from '@tradener/lumen';

import { useAutocomplete } from '..';

import { useOffer } from '../../context';

export type AutoSearchProps = InputProps;

const AutoSearch: React.ComponentType<AutoSearchProps> = ({ ...props }) => {
  const { anchorRef, control, onConnect, onClear, sendMessage } = useAutocomplete();
  const { quoteLoading } = useOffer();

  return (
    <PopoverAnchor>
      <Box ref={anchorRef} width="full">
        <Skeleton isLoaded={!quoteLoading}>
          <Controller
            control={control}
            name="searchFor"
            render={({ field: { value, onBlur, onChange, ...field } }) => (
              <InputGroup key="inputGroup" width="full" height="full">
                <Input
                  {...field}
                  bgColor="gray.100"
                  fontSize="md"
                  variant="filled"
                  colorScheme="gray"
                  placeholder="Pesquise por nome ou CNPJ"
                  autoComplete="off"
                  overflow="clip"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  width="full"
                  onFocus={() => {
                    sendMessage(value);
                    onConnect();
                  }}
                  onBlur={() => {
                    sendMessage('');
                    onBlur();
                  }}
                  onChange={(event) => {
                    onChange(event);
                    sendMessage(event.target.value);
                  }}
                  value={value}
                  _focus={{ bgColor: 'gray.100', borderColor: 'orange.500' }}
                  {...props}
                />
                <InputRightElement height="full" color="gray.900">
                  <IconButton
                    variant="ghost"
                    aria-label="unit-search-icon"
                    size="small"
                    colorScheme="gray"
                    color="gray.900"
                    onClick={onClear}
                    disabled={!value}
                    _disabled={{ color: 'gray.900' }}
                  >
                    {value ? (
                      <CloseIcon aria-label="close-icon" boxSize="5" />
                    ) : (
                      <SearchIcon aria-label="search-icon" boxSize="5" />
                    )}
                  </IconButton>
                </InputRightElement>
              </InputGroup>
            )}
          />
        </Skeleton>
      </Box>
    </PopoverAnchor>
  );
};

export default AutoSearch;
