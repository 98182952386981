import { createContext } from 'react';

import { setHours, setMinutes, endOfWeek } from 'date-fns';

export const INITIAL_CONTEXT = {
  readOnly: false,
  id: undefined,
  succeededId: undefined,
  options: {
    filled: false,
    edited: {
      modulation: false,
      sazoPercent: false,
      flexPositive: false,
      flexNegative: false,
      loss: false,
      readjustmentIndex: false,
      quoteInformation: false,
      priceType: false,
      volumeType: false,
      dueDateValue: false,
      dueDateType: false,
      expireDate: false,
      quoteItemPrice: false,
      quoteItemVolume: false,
    },
  },
  opportunity: {
    type: undefined,
    channel: 'MESA',
    buyer: {
      id: undefined,
      name: undefined,
      taxId: undefined,
      crmRecordType: undefined,
    },
    seller: {
      id: undefined,
      name: undefined,
      taxId: undefined,
      crmRecordType: undefined,
    },
    tenant: {
      id: undefined,
      name: undefined,
    },
    category: 'TRD_DEFAULT',
    account: {
      id: undefined,
      name: undefined,
      taxId: undefined,
    },
  },
  quoteItems: [],
  product: {
    shortName: undefined,
    unit: 'MWh',
  },
  quoteInformation: [],
  quoteInformationTypes: [],
  giro: undefined,
  externalId: undefined,
  modulation: undefined,
  flexPositive: undefined,
  flexNegative: undefined,
  loss: undefined,
  retusdAmount: undefined,
  sazoPercent: undefined,
  readjustmentIndex: 'IPCA',
  readjustmentMonth: null,
  baseDate: null,
  priceType: undefined,
  submarket: undefined,
  volumeType: 'MWm',
  periodType: 'SHORT',
  dueDateType: 'BUSINESS_DAY',
  dueDateValue: 6,
  startDate: null,
  endDate: null,
  expireDate: setHours(setMinutes(endOfWeek(new Date(), { weekStartsOn: 6 }), 0), 18),
  notes: undefined,
  swap: false,
  lockCharges: false,
  auditLogs: [],
  emailMessages: [],
  priceBook: undefined,
};

export default createContext({
  data: INITIAL_CONTEXT,
  mergeData: () => ({}),
  replaceData: () => ({}),
  submitProposal: () => ({}),
});
