import { useEffect, useState } from 'react';

import { NumericFormat } from 'react-number-format';

import { useMutation } from '@apollo/client';
import { Flex, InputGroup, Input, InputRightAddon, useToast, Skeleton, parseSafeFloat } from '@tradener/lumen';

import UPDATE_SIMULATION_INTEREST_RATE, { ResponseProps, PayloadProps } from '@graphql/mutation/updateSimulationInterestRate';
import SIMULATION from '@graphql/query/simulation';

import useRestriction from '~/hooks/useRestriction';
import useSimulation from '~/pages/Portfolio/useSimulation';
import changeEvent from '~/utils/changeEvent';

import { useSimulationTable } from '../../SimulationTable/withSimulationTable';

function SimulationInterestRate() {
  const toast = useToast();
  const { restrict } = useRestriction();
  const { simulationId } = useSimulation();
  const { data } = useSimulationTable();
  const annualInterestRate = data?.simulation?.simulationPv?.annualInterestRate;
  const [interestRate, setInterestRate] = useState(annualInterestRate);

  const [updateSimulationInterestRate, { client }] = useMutation<ResponseProps, PayloadProps>(UPDATE_SIMULATION_INTEREST_RATE);

  const handleUpdateRate: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (simulationId && event.key === 'Enter') {
      const annualInterestRate = interestRate! / 100;

      event.currentTarget.blur();

      updateSimulationInterestRate({
        variables: {
          input: {
            simulationId,
            annualInterestRate,
          },
        },
        async onCompleted() {
          await client.refetchQueries({ include: [SIMULATION] });

          toast({ description: 'Taxa de juros atualizada', status: 'success' });
        },
      });
    }
  };

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
    if (event.currentTarget.value === '') setInterestRate(annualInterestRate ?? 0);
  };

  useEffect(() => {
    if (interestRate === undefined && annualInterestRate) setInterestRate(annualInterestRate * 100);
  }, [annualInterestRate, interestRate]);

  return restrict(
    'SIMULATION_INTEREST_RATE',
    <Flex gridColumnGap="2">
      <Skeleton isLoaded={!!data?.simulation}>
        <Input
          as={NumericFormat}
          defaultValue={(annualInterestRate ?? 0) * 100}
          onKeyDown={handleUpdateRate}
          onChange={changeEvent(setInterestRate, parseSafeFloat)}
          onBlur={handleBlur}
          allowedDecimalSeparators={[',']}
          decimalSeparator=","
          decimalScale={2}
          value={interestRate}
          title="Juros anual"
          suffix=" %"
          size="sm"
          width="20"
          allowLeadingZeros
          allowNegative
        />
      </Skeleton>
      <Skeleton isLoaded={!!data?.simulation}>
        <InputGroup size="sm" width="52">
          <Input
            paddingRight="0"
            value={Intl.NumberFormat('pt-br', {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 0,
            }).format(data?.simulation?.simulationPv?.totalAmount ?? 0)}
            readOnly
          />
          <InputRightAddon>total</InputRightAddon>
        </InputGroup>
      </Skeleton>
    </Flex>,
  );
}

export default SimulationInterestRate;
