import { forwardRef, useContext } from 'react';

import ProposalsContext from '@DEPRECATED/context/Proposals/context';
import Card from '~/DEPRECATED/components/ConfirmationDialog/Card';
import MuiDialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';

const Dialog = forwardRef(({ children, onClose, ...rest }, ref) => {
  const { data } = useContext(ProposalsContext);

  return (
    <MuiDialog onClose={onClose} {...rest} ref={ref}>
      <Card title={data.offerCode} onClose={onClose} elevation={0}>
        {children}
      </Card>
    </MuiDialog>
  );
});

Dialog.defaultProps = {
  onClose: null,
};

Dialog.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

export default Dialog;
