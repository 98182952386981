import { useReducer, useRef } from 'react';

import { useQuery } from '@apollo/client';
import { Flex, Text, Popover, PopoverContent, PopoverBody, PopoverAnchor, CorporateFareIcon } from '@tradener/lumen';

import GET_ACCOUNT_PARENTS, { PayloadProps, ResponseProps } from '@graphql/query/accountParents';

import AccountTree from '~/theme/icons/AccountTree';

import { useAutocomplete } from '../..';
function ParentAccount() {
  const { accountId } = useAutocomplete();
  const [isOpen, toggle] = useReducer((prev) => !prev, false);
  const anchorRef = useRef<HTMLDivElement | null>(null);

  const { data: result } = useQuery<ResponseProps, PayloadProps>(GET_ACCOUNT_PARENTS, {
    skip: !accountId,
    variables: {
      accountId: accountId as string,
    },
  });

  return (
    <Flex width="full" direction="column" align="flex-start" gap="4" minH="fit-content">
      <Popover
        gutter={4}
        isOpen={isOpen}
        onClose={toggle}
        initialFocusRef={anchorRef}
        placement="bottom-end"
        closeOnBlur
        closeOnEsc
        matchWidth
        isLazy
      >
        <PopoverAnchor>
          <Flex data-testid="anchor" width="full" align="center" justify="space-between">
            <Text textStyle="body2-regular" color="gray.500">
              Conta pai
            </Text>
            <Flex cursor="pointer" onClick={toggle} align="center" gap="1" textStyle="body2-regular" color="orange.500">
              <CorporateFareIcon boxSize="3" mt="0.5" />
              Ver hierarquia
            </Flex>
          </Flex>
        </PopoverAnchor>
        <PopoverContent width="full" maxHeight="56" data-testid="popover-content">
          <PopoverBody
            width="32.375rem"
            height="fit-content"
            maxHeight="56"
            overflowY="auto"
            overflow="clip"
            bgColor="gray.0"
            borderColor="transparent"
            borderRadius="lg"
          >
            <Flex textStyle="body2-semibold" width="full" py="2.5" px="2" align="center" gap="2" height="12.5">
              <Flex align="center" justify="center">
                <AccountTree boxSize="3.5" />
              </Flex>
              <Text mb="1px">Hierarquia</Text>
            </Flex>
            <Flex direction="column" px="3" paddingTop="0" paddingBottom="3" gap="3">
              {result?.accountParents?.map(({ id, name, taxId }, index) => (
                <Flex key={id} paddingLeft={`${index * 12}px`} width="full" height="6" align="center" justify="space-between">
                  <Flex maxWidth="60%" direction="row" align="center" gap="1">
                    <CorporateFareIcon boxSize="3" mt="0.5" />
                    <Text maxWidth="full" overflowX="clip" textOverflow="ellipsis" whiteSpace="nowrap" color="gray.900">
                      {name}
                    </Text>
                  </Flex>
                  <Text maxWidth="40%" textStyle="body1-regular" color="gray.500">
                    {taxId?.replace(/(\d+)(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5') ?? '-'}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
}

export default ParentAccount;
