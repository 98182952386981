import { createIcon } from "@tradener/lumen";

const LineIcon = createIcon({
  displayName: 'LineIcon',
  viewBox: '0 0 13 2',
  path: (
    <>
      <path d="M0.5 1C0.5 0.447715 0.947715 0 1.5 0H11.5C12.0523 0 12.5 0.447715 12.5 1C12.5 1.55228 12.0523 2 11.5 2H1.5C0.947715 2 0.5 1.55228 0.5 1Z" fill="currentColor"/>
    </>
  )
});

export default LineIcon;
