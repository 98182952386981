import styled from 'styled-components';

export const Container = styled.div`
  min-width: 160px;
  height: 48px;
  border-radius: 4px;
  display: flex;

  @media screen and (min-width: 600px) {
    width: 100%;
    min-width: 260px;
    height: 88px;
    border-top: 4px solid ${({ color }) => color};
  }
`;

export const AvatarContent = styled.section`
  height: 100%;
  color: white;
  padding: 10px;

  div {
    height: 24px;
    width: 24px;
    background-color: ${({ color }) => color};

    svg {
      strokeWidth: 0;
      fill: white;
      width: 16px;
      height: 16px;
    }
  }

  @media screen and (min-width: 600px) {
    padding: 20px;

    div {
      height: 48px;
      width: 48px;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
`;

export const Content = styled.section`
  width: 100%;
  height: 100%;
  padding: 4px 10px;

  span {
    display: block;
    font-weight: 500;

    &.title {
      text-transform: uppercase;
      font-size: 8px;
    }

    &.red {
      color: #ff0046;
    }

    font-size: 18px;
  }

  @media screen and (min-width: 600px) {
    padding: 20px;
    padding-left: 0;

    span {
      display: block;
      font-weight: 500;

      &.title {
        text-transform: uppercase;
        font-size: 12px;
        color: white;
      }

      font-size: 26px;
    }
  }
`;
