declare global {
  interface Number {
    formatCurrency(locale?: string, options?: Intl.NumberFormatOptions): string;
  }
}

Number.prototype.formatCurrency = function (
  locale = 'pt-BR',
  options: Intl.NumberFormatOptions | undefined = { style: 'currency', currency: 'BRL' },
) {
  return Intl.NumberFormat(locale, options).format(this as number);
};

export default Number;
