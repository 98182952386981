import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  head: {
    height: '100%',
    cursor: 'pointer',
    padding: '6px',
    flexWrap: 'nowrap',
    flex: 1,
    '& svg': {
      height: '22px',
      paddingTop: '4px',
      marginRight: '5px',
      display: 'none',
    },
    '&:hover svg': {
      display: 'block',
    },
  },
}));

export default useStyles;
