import { Grid, GridItem, Flex, Skeleton } from '@tradener/lumen';

function ContractsDetailsSkeleton() {
  return (
    <Flex
      flexDir="column"
      padding="3"
      gap=".75rem"
      bgColor="gray.0"
      borderRadius=".75rem"
      width="25rem"
      height="20.875rem"
      data-testid="skeleton-loader"
    >
      <Skeleton borderRadius=".5rem" width="full" padding="7" />
      <Grid templateColumns="repeat(3, 1fr)" width="full" rowGap="1rem" padding="2">
        <GridItem colSpan={3}>
          <Skeleton borderRadius=".375rem" height="4" width="15" />
        </GridItem>
        <GridItem colSpan={1}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center" gap="1.5">
            <Skeleton borderRadius=".375rem" height="4" width="15" />
            <Skeleton borderRadius=".375rem" height="4" width="28" />
          </Flex>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center" gap="1.5">
            <Skeleton borderRadius=".375rem" height="4" width="15" />
            <Skeleton borderRadius=".375rem" height="4" width="28" />
          </Flex>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center" gap="1.5">
            <Skeleton borderRadius=".375rem" height="4" width="15" />
            <Skeleton borderRadius=".375rem" height="4" width="28" />
          </Flex>
        </GridItem>

        <GridItem colSpan={3}>
          <Skeleton borderRadius=".375rem" height="4" width="15" />
        </GridItem>
        <GridItem colSpan={1}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center" gap="1.5">
            <Skeleton borderRadius=".375rem" height="4" width="15" />
            <Skeleton borderRadius=".375rem" height="4" width="28" />
          </Flex>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center" gap="1.5">
            <Skeleton borderRadius=".375rem" height="4" width="15" />
            <Skeleton borderRadius=".375rem" height="4" width="28" />
          </Flex>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center" gap="1.5">
            <Skeleton borderRadius=".375rem" height="4" width="15" />
            <Skeleton borderRadius=".375rem" height="4" width="28" />
          </Flex>
        </GridItem>

        <GridItem colSpan={1}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center" gap="1.5">
            <Skeleton borderRadius=".375rem" height="4" width="15" />
            <Skeleton borderRadius=".375rem" height="4" width="28" />
          </Flex>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center" gap="1.5">
            <Skeleton borderRadius=".375rem" height="4" width="15" />
            <Skeleton borderRadius=".375rem" height="4" width="28" />
          </Flex>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center" gap="1.5">
            <Skeleton borderRadius=".375rem" height="4" width="15" />
            <Skeleton borderRadius=".375rem" height="4" width="28" />
          </Flex>
        </GridItem>
      </Grid>
    </Flex>
  );
}

export default ContractsDetailsSkeleton;
