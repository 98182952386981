import { useMemo } from 'react';

import { DEPRECATED_SELECT } from '@DEPRECATED/components/Form';
import { getYear } from 'date-fns';
import PropTypes from 'prop-types';

import { Container } from './styles';

function PeriodSelector(props) {
  const { startDate, value, onChange, index } = props;

  const yearLabel = useMemo(() => getYear(startDate), [startDate]);

  function handleChange({ target }) {
    const { value: selectValue } = target;

    onChange({ value: selectValue, index });
  }

  return (
    <Container>
      <strong id="label">{yearLabel}</strong>
      <DEPRECATED_SELECT
        style={{
          width: 96,
        }}
        options={[
          { value: 12, label: 'Mensal' },
          { value: 4, label: 'Trimestre' },
          { value: 2, label: 'Semestre' },
          { value: 1, label: 'Anual' },
        ]}
        inputProps={{
          tabIndex: -1,
        }}
        value={Number(value)}
        onChange={handleChange}
      />
    </Container>
  );
}

PeriodSelector.defaultProps = {
  value: undefined,
};

PeriodSelector.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PeriodSelector;
