export const translate = (key: string, value: boolean | string | string[]) => {
  let label = key;

  switch (key) {
    case 'periodType':
      label = 'Prazo';
      break;
    case 'priceType':
      label = 'Preço';
      break;
    case 'energyType':
      label = 'Tipo de energia';
      break;
    case 'operation':
      label = 'Operação';
      break;
    case 'usageFactorByContract':
      label = 'Coef. por contrato';
      break;
    case 'usageFactorByEnergyType':
      label = 'Coef. por fonte';
      break;
    case 'usageFactor':
      label = 'Coef. por período';
      break;
    case 'giro':
      label = 'Cód. giro';
      break;
    case 'hasGiro':
      label = 'Giro';
      break;
    case 'excludedContracts':
      label = 'Contratos ocultos';
      break;
    case 'contractFlags':
      label = 'Sinalizadores';
      break;
    case 'accountingPortfolio':
      label = 'P. Contábil';
      break;
    case 'closeDateGte':
      label = 'Data de fechamento (INÍCIO)';
      break;
    case 'closeDateLte':
      label = 'Data de fechamento (FIM)';
      break;
    case 'accountTypes':
      label = 'Tipo da conta';
      break;
  }

  if (key == 'contractFlags') {
    const flags = (value as Array<string>).length;

    value = `${flags} selecionado${flags > 1 ? 's' : ''}`;
  }

  if (Array.isArray(value)) {
    value = value
      .map((current) => {
        switch (current) {
          case 'PERCENT_PLD':
            return '% PLD';
          default:
            return current;
        }
      })
      .join(', ');
  }

  if (key == 'hasGiro') {
    value = value ? 'Com' : 'Sem';
  }

  if (typeof value === 'boolean') {
    value = value ? 'Sim' : 'Não';
  }

  if (key === 'excludedContracts') {
    value = Object.keys(value).toString().split(',').length.toString();
  }

  if (key == 'accountingPortfolio') {
    value = value == 'WHOLESALE' ? 'ATACADO' : 'NEGOCIAÇÃO';
  }

  return `${label}: ${value}`;
};
