import { useNavigate } from 'react-router-dom';

import { MailIcon } from '@tradener/lumen';

import OptionItem from '../../OptionItem';
import { useQuoteOptions } from '../../useQuoteOptions';

function ActionSendProposal() {
  const { id } = useQuoteOptions();
  const navigate = useNavigate();

  return (
    <OptionItem stage="PROPOSED" onClick={() => navigate(`/trading/list/${id}/send-proposal`)} icon={<MailIcon boxSize="5" />}>
      Enviar proposta
    </OptionItem>
  );
}

export default ActionSendProposal;
