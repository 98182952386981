import { useQuery } from '@apollo/client';

import SIMULATION_CONTRACTS, { ResponseProps, PayloadProps } from '@graphql/query/simulationContracts';

import useSimulation from '../../useSimulation';
import useSimulationRules from '../../useSimulationRules';

function useSimulationContracts(variables: PayloadProps) {
  const { simulationId, portfolioId } = useSimulation();
  const { isPortfolio } = useSimulationRules();

  const response = useQuery<ResponseProps, PayloadProps>(SIMULATION_CONTRACTS, {
    skip: !isPortfolio ? !simulationId : !portfolioId,
    variables,
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    initialFetchPolicy: 'no-cache',
  });

  return response;
}

export default useSimulationContracts;
