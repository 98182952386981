import { useState } from 'react';

import { Box, Button, makeStyles, Popover } from '@material-ui/core';
import { IconButton } from '@tradener/lumen';

import BlankPage from '~/theme/icons/BlankPage';
import ContentCopy from '~/theme/icons/ContentCopy';

import { useModalContext } from '..';

const useStyles = makeStyles(() => ({
  root: {
    '&:focus': {
      backgroundColor: 'rgba(38, 38, 38, 0.08)',
    },
  },
}));

const NewSimulation = () => {
  const classes = useStyles();
  const { onOpen } = useModalContext();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal: typeof onOpen = (action) => () => {
    handleClose();
    onOpen(action)();
  };

  return (
    <>
      <IconButton aria-label="new simulation icon button" onClick={handleClick} size="sm" colorScheme="gray" variant="outline">
        <BlankPage fontSize="small" />
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          height="88px"
          padding="8px"
          borderRadius="8px"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.2)"
        >
          <Button
            onClick={handleOpenModal('CREATE')}
            size="small"
            startIcon={<BlankPage width="20px" height="20px" color="inherit" />}
            classes={classes}
            fullWidth
          >
            Nova simulação
          </Button>
          <Button
            onClick={handleOpenModal('DUPLICATE')}
            size="small"
            startIcon={<ContentCopy width="20px" height="20px" color="inherit" />}
            classes={classes}
            fullWidth
          >
            Duplicar a atual
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default NewSimulation;
