import { createIcon } from '@tradener/lumen';

const DeployedCodeIcon = createIcon({
  displayName: 'DeployedCodeIcon',
  viewBox: '0 0 12 12',
  path: (
    <>
      <mask id="mask0_10329_984" style={{ maskType:"alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="12">
      <rect width="12" height="12" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_10329_984)">
      <path d="M5.5 10.8625L2 8.85C1.84167 8.75833 1.71875 8.6375 1.63125 8.4875C1.54375 8.3375 1.5 8.17083 1.5 7.9875V4.0125C1.5 3.82917 1.54375 3.6625 1.63125 3.5125C1.71875 3.3625 1.84167 3.24167 2 3.15L5.5 1.1375C5.65833 1.04583 5.825 1 6 1C6.175 1 6.34167 1.04583 6.5 1.1375L10 3.15C10.1583 3.24167 10.2813 3.3625 10.3688 3.5125C10.4563 3.6625 10.5 3.82917 10.5 4.0125V7.9875C10.5 8.17083 10.4563 8.3375 10.3688 8.4875C10.2813 8.6375 10.1583 8.75833 10 8.85L6.5 10.8625C6.34167 10.9542 6.175 11 6 11C5.825 11 5.65833 10.9542 5.5 10.8625ZM5.5 6.2875V9.7125L6 10L6.5 9.7125V6.2875L9.5 4.55V4.025L8.9625 3.7125L6 5.425L3.0375 3.7125L2.5 4.025V4.55L5.5 6.2875Z" fill="currentColor"/>
      </g>
    </>
  ),
});

export default DeployedCodeIcon;
