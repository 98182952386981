import { InMemoryCache } from '@apollo/client';
import { offsetLimitPagination, Reference } from '@apollo/client/utilities';

const cache = new InMemoryCache({
  typePolicies: {
    SimulationContract: {
      keyFields: ['id', 'energyType'],
    },
    Query: {
      fields: {
        quotes: offsetLimitPagination(['id']),
        operationLimitsList: offsetLimitPagination(['taxId']),
        simulationContracts: offsetLimitPagination(['contractNumber', 'energyType', 'accountName', 'simulationItems']),
        bbceProducts: {
          keyArgs: (args) => {
            if (!args) return false;

            return Object.keys(args?.filter ?? {});
          },
          read(existing) {
            return existing ?? { pinneds: [], products: [] };
          },
          merge(existing, incoming, { args }) {
            if (!incoming) return existing;

            const merged = existing?.products.slice(0) ?? [];

            if (args) {
              const { offset = 0 } = args;

              for (let i = 0; i < incoming.products.length; ++i) {
                merged[offset + i] = incoming.products[i];
              }
            }

            const pinnedIds = ((args?.filter?.pinnedIds ?? []) as string[]).map((id) => `BbceProduct:${id}`);

            return {
              ...incoming,
              products: merged.filter((product: Reference) => !pinnedIds.includes(product['__ref'])),
            };
          },
        },
      },
    },
  },
});

export default cache;
