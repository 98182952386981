import { useMemo } from 'react';

import Elipse from '@DEPRECATED/components/Elipse';
import numeral from '@DEPRECATED/utils/numeral';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';

import { TableCell, TableBody } from './styles';

function TableCellAmount(props) {
  const { children } = props;

  const isNegative = useMemo(() => parseFloat(children) < 0, [children]);

  const totalAmount = useMemo(() => numeral(children).format('0[.]0 a'), [children]);

  return (
    <TableCell className={isNegative ? 'negative' : ''} {...props}>
      {totalAmount}
    </TableCell>
  );
}

TableCellAmount.propTypes = {
  children: PropTypes.number.isRequired,
};

function Dre({ data }) {
  const { revenue, expense, ccee, total } = data;

  function createData(name, values) {
    return {
      name,
      ...values,
    };
  }

  const rows = [createData('Receita', revenue), createData('Despesa', expense), createData('CCEE', ccee)];

  const totalized = createData('TOTAL', total);

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center">
              <Elipse color="#F9B850" size={12} />
              <span>CON</span>
            </TableCell>
            <TableCell align="center">
              <Elipse color="#6A51F6" size={12} />
              <span>INC</span>
            </TableCell>
            <TableCell align="center">TOTAL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell align="left" component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCellAmount align="center">{row.con.totalAmount}</TableCellAmount>
              <TableCellAmount align="center">{row.inc.totalAmount}</TableCellAmount>
              <TableCellAmount align="center">{row.total.totalAmount}</TableCellAmount>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell align="left">{totalized.name}</TableCell>
            <TableCellAmount align="center">{totalized.con.totalAmount}</TableCellAmount>
            <TableCellAmount align="center">{totalized.inc.totalAmount}</TableCellAmount>
            <TableCellAmount align="center">{totalized.total.totalAmount}</TableCellAmount>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

Dre.fragments = {
  dre: gql`
    fragment DreFields on DreField {
      con {
        totalAmount
      }
      inc {
        totalAmount
      }
      total {
        totalAmount
      }
    }

    fragment Dre on Dre {
      revenue {
        ...DreFields
      }
      expense {
        ...DreFields
      }
      ccee {
        ...DreFields
      }
      total {
        ...DreFields
      }
    }
  `,
};

const dreDefaultValue = {
  con: { totalAmount: 0.0 },
  inc: { totalAmount: 0.0 },
  total: { totalAmount: 0.0 },
};

Dre.defaultProps = {
  data: {
    revenue: dreDefaultValue,
    expense: dreDefaultValue,
    ccee: dreDefaultValue,
    total: dreDefaultValue,
  },
};

const propTypeDreValue = PropTypes.shape({
  con: PropTypes.shape({ totalAmount: PropTypes.number }),
  inc: PropTypes.shape({ totalAmount: PropTypes.number }),
  total: PropTypes.shape({ totalAmount: PropTypes.number }),
});

Dre.propTypes = {
  data: PropTypes.shape({
    revenue: propTypeDreValue,
    expense: propTypeDreValue,
    ccee: propTypeDreValue,
    total: propTypeDreValue,
  }),
};

export default Dre;
