import { createContext, memo, useContext } from 'react';

import { Card, Flex } from '@tradener/lumen';
import { isNumber } from 'lodash';

import { BbceProductProps } from '@graphql/query/bbceProducts';

import useTrading from '~/pages/Trading/useTrading';

import useProducts from '../useProducts';
import ProductAction from './ProductAction';
import ProductAreaChart from './ProductAreaChart';
import ProductOffers from './ProductOffers';
import ProductOptions from './ProductOptions';
import ProductOverview from './ProductOverview';
import ProductPriceVariation from './ProductPriceVariation';

const BbceProductContext = createContext({} as BbceProductProps);

const withBbceProductContext = <T extends BbceProductProps>(Component: React.ComponentType<T>) =>
  memo((props: React.PropsWithChildren<T>) => (
    <BbceProductContext.Provider value={props}>
      <Component {...props} />
    </BbceProductContext.Provider>
  ));

const getCurrency = (value: number | undefined, options?: Intl.NumberFormatOptions, placeholder = 'R$ -') => {
  const currency = isNumber(value)
    ? new Intl.NumberFormat('pt-BR', {
        currency: 'BRL',
        style: 'currency',
        ...options,
      }).format(value)
    : placeholder;

  return currency;
};

const useBbceProduct = () => {
  const { filter } = useTrading();
  const { isExpanded, onFold, onUnfold } = useProducts();
  const isAnalytics = window.location.pathname?.includes('trading/list/analytics/');
  const value = useContext(BbceProductContext);

  return {
    ...value,
    isOpen: isExpanded === value.id,
    isPinned: !!filter?.pinnedIds?.includes(value.id),
    filter: filter,
    isAnalytics,
    onOpen: () => onUnfold(value.id),
    onClose: onFold,
    getCurrency,
  };
};

function ListItem() {
  const { isAnalytics } = useBbceProduct();

  return (
    <Flex
      as={Card}
      data-testid="list-item"
      paddingBlock=".8125rem"
      paddingInline="4"
      direction="column"
      justifyContent="center"
      minHeight="20"
      borderRadius="xl"
      overflowX={isAnalytics ? 'auto' : 'visible'}
      width={isAnalytics ? 'full' : 'max(87rem, calc(100dvw - 7.5rem))'}
    >
      <Flex width="auto" justify="space-between" overflowX={isAnalytics ? 'auto' : 'visible'} height="13.5">
        <Flex columnGap="5" height="full">
          <Flex columnGap="3" height="full">
            {!isAnalytics && <ProductAction />}
            <ProductAreaChart />
          </Flex>
          <ProductPriceVariation />
        </Flex>
        <Flex direction="row" alignItems="center" justifyContent="flex-end" flex="1" columnGap="10" height="full">
          <ProductOverview />
          <ProductOptions />
        </Flex>
      </Flex>
      <ProductOffers />
    </Flex>
  );
}

export { withBbceProductContext, useBbceProduct };
export default withBbceProductContext(ListItem);
