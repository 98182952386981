/* eslint-disable max-len */
import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/core';

import TooltipDetail from '../../TooltipDetail';
import { ReactComponent as BalanceIcon } from './balance_icon.svg';
import PldIcon from './pld_icon';
import { ReactComponent as PurchaseIcon } from './purchase_icon.svg';
import { ReactComponent as SaleIcon } from './sale_icon.svg';
import { Text } from './styles';

function Map(props) {
  const { palette } = useTheme();
  const { north, northeast, south, southeast, isAveragePrice } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="405.556" height="408.505" viewBox="0 0 405.556 408.505">
      <defs>
        <clipPath>
          <circle cx="8" cy="8" r="8"
            fill={palette.secondary.light}
            />
        </clipPath>
      </defs>
      <g transform="translate(-1407.305 -193.266)">
        <g>
          <path
            d="M3400.913,253.346l12.137,1.816,16.727,3.933,17.43,6.8,22.379,12.3,9.423-6.284,10.627,1.418,5.029-3.922,4.315-5.16,9.224-6.383,5.446,1.233,7.516,6.8h27.745l11.307-1.649,1.168-7.774h2.428l3.531,2.623v10.311l10.682,5.911,67.121,2.2-1.458,22.879-1.1,6.025,3.6,2.9h18.6l21.521,1.179,1.48,14.6,7.171,6.578,15.563-2.16,16.752,5.449,13.44,9.168,4.017,1.276,5.4,1.031-3.75,10.556,10.729,14.582v7.39l-16.4,16.488v11.78l-22.161,14.7h-13.4l-18.845,4.149-18.845,14.8V435.5l-11.516-9.423-2.291-11.808-16.6-4.763-10.355-2.275-11.635,2.275-16.127,16.571-9.673-9.423-6.6-7.148-9.423,1.343-9.423-3.617V371.967l2.09-11.845-6.814-9.423-1.011-7.061-19.818-2.362s-.3-13.446,0-15.066-5.087-9.034-5.087-9.034l-28.3-11.225h-13.4l-5.861-17.815v-8.15h-11.664l-2.262,4.755h-7.766l-9.433,9.423-8.766-4.185h-7.914l-5.911-5.238v-9.423l-6.348,4.668h-12.5v-4.668h-7.023l4.166-5.911-10.715-6.579Z"
            transform="translate(-1992.172 73.042)"
            fill={palette.background.light}
          />
          {isAveragePrice ? (
            <>
              <g transform="translate(1431.305 308.6)">
                <Text transform="translate(178.979 96)">{southeast.purchase}</Text>
                <PurchaseIcon />
              </g>
              <g transform="translate(1431.305 340.6)">
                <Text transform="translate(178.979 96)">{southeast.sale}</Text>
                <SaleIcon />
              </g>
              <g transform="translate(1431.305 372.6)">
                <Text transform="translate(178.979 96)">{southeast.pld}</Text>
                <PldIcon circleFill="#BB6BD9" />
              </g>
            </>
          ) : (
            <TooltipDetail data={southeast.tooltip} component="a">
              <g transform="translate(1461.305 346)">
                <Text transform="translate(178.979 96)">{southeast.balance}</Text>
                <BalanceIcon />
              </g>
            </TooltipDetail>
          )}
        </g>
        <g>
          <path
            d="M3401.57,270.092l11.325,1.768,18.845,4.711,21.456,9.423,16.235,9.423,7.51-4.8,1.912-1.472,9.423,1.472,9.422-8.985,9.423-7.217,7.521,2.159,9.075,6.139h27l7.26-1.08,1.4-8.61h2.143l7.766,3.552v9.423l10.039,6.1,69.134,3.321-2.621,25.563v4.763l30.89,1.2h11.947V311.621l4.761-10.065V285.994l6.219-11.834,5.341-1.768,9.423-5.243h6.422l3-6V252.82l3.292-7.07V235.587l6.13-10.654h-6.13l-7.885-4.9L3705,226.161V212.953l-18.845-8.494L3674.6,201.19h-4.4l-2.886,4.939v4.484l-5.981,3.764-3.441,10.556V214.377l-9.423-1.424,12.864-2.341s2.976-11.849,2.916-12.733-1.964-2.308-1.964-2.308h-13.816l-2.621-1.424-4.48,7.041-2.32,6.116-9.423,3.306v-4.484l13.29-17.184,5.555-8.493v-7.529h-5.555l-3.867-9.423s1.309-11.672,0-12.752-6.021-6.093-6.021-6.093v6.093l-5.3,3.329-7.528,16.1-7.38,2.744-14.672-5.683-6.215-3.74-3.664,9.423H3573.08l-15.1,9.423-8.7-9.423-4.473-7.266,4.473-11.58L3544.813,147l2.866-6.677h-9.7L3535.39,147h-7.452l-8.123,2.514-3.271,4.563h-9.422l-4.953-4.563-4.47-4.859-3.058,4.859,3.058,4.563v11.58l9.423,4.522L3492.8,182.345h-13.942l-11.8-12.167-7.049,5.112h-18.845v7.055h6.375v3.55l-8.719,2.062,2.344,6.628,6.375,4.222-10.326,40.074h-18.121l-12.647,14.366v8.74l-6.1,2.553Z"
            transform="translate(-1992.018 52.943)"
            fill={palette.background.light}
          />
          {isAveragePrice ? (
            <>
              <g transform="translate(1402.327 153.266)">
                <Text transform="translate(178.979 96)">{north.purchase}</Text>
                <PurchaseIcon />
              </g>
              <g transform="translate(1402.327 185.266)">
                <Text transform="translate(178.979 96)">{north.sale}</Text>
                <SaleIcon />
              </g>
              <g transform="translate(1402.327 217.266)">
                <Text transform="translate(178.979 96)">{north.pld}</Text>
                <PldIcon circleFill="#56CCF2" />
              </g>
            </>
          ) : (
            <TooltipDetail data={north.tooltip} component="a">
              <g transform="translate(1402.327 202.266)">
                <Text transform="translate(178.979 96)">{north.balance}</Text>
                <BalanceIcon />
              </g>
            </TooltipDetail>
          )}
        </g>
        <g>
          <path
            d="M3646.017,320.281l.913,9.423,1.517,3.2,3.44,3,13.412-3,7.32,3,11.04,3.228L3699,348.549l11.706,2.275L3705.35,363l9.67,11.538,10.118-23.714-4.91-30.543h7.041l5.625-11.7,16.96-19.3,11.337-11.685V244.9l-5.655-4.763-11.335-4.66-7.51-1.919-6.326-11.047-12.519-5.879-9.423-4.186L3699,216.632h-9.423l-2.358,11.525v6.452l-3.562,6.775V251.4l-3.194,6.235h-7.846l-7.32,4.467-7.21,1.915-4.88,10.507V287.34l-3.352,9.7-1.41,4.4v13.4Z"
            transform="translate(-1948.33 65.769)"
            fill={palette.background.light}
          />
          {isAveragePrice ? (
            <>
              <g transform="translate(1553.306 245.426)">
                <Text transform="translate(178.979 95)">{northeast.purchase}</Text>
                <PurchaseIcon />
              </g>
              <g transform="translate(1553.306 277.426)">
                <Text transform="translate(178.979 96)">{northeast.sale}</Text>
                <SaleIcon />
              </g>
              <g transform="translate(1553.306 309.426)">
                <Text transform="translate(178.979 96)">{northeast.pld}</Text>
                <PldIcon circleFill="#27AE60" />
              </g>
            </>
          ) : (
            <TooltipDetail data={northeast.tooltip} component="a">
              <g transform="translate(1553.306 273.426)">
                <Text transform="translate(178.979 96)">{northeast.balance}</Text>
                <BalanceIcon />
              </g>
            </TooltipDetail>
          )}
        </g>
        <g>
          <path
            d="M3575.817,404.8c1.212-.357,15.039-16.084,15.039-16.084l11.2-1.15,23.807,6.761,1,11.187,11.286,7.68-.683,17.285,1.934,15.955-11.575,14.828-4.892,9.082-9.422,7.248-7.08,6.6-7.32,12.248-7.439,9.423-6.43-5.37v-4.052l-18.845-18.845-6.831,3.258-5.267-10.507-10.385,2.259,8.48-11.341,4.581-7.807,12.737-7.021,13.42-9.23,2.11-16.147-9.423,1.909Z"
            transform="translate(-1966.488 95.911)"
            fill={palette.background.light}
          />
          {isAveragePrice ? (
            <>
              <g transform="translate(1466.305 420.477)">
                <Text transform="translate(178.979 96)">{south.purchase}</Text>
                <PurchaseIcon />
              </g>
              <g transform="translate(1466.305 452.477)">
                <Text transform="translate(178.979 96)">{south.sale}</Text>
                <SaleIcon />
              </g>
              <g transform="translate(1466.305 484.477)">
                <Text transform="translate(178.979 96)">{south.pld}</Text>
                <PldIcon circleFill="#F2C94C" />
              </g>
            </>
          ) : (
            <TooltipDetail data={south.tooltip} component="a">
              <g transform="translate(1466.305 452)">
                <Text transform="translate(178.979 96)">{south.balance}</Text>
                <BalanceIcon />
              </g>
            </TooltipDetail>
          )}
        </g>
      </g>
    </svg>
  );
}

const submarketDefaultValue = {
  purchase: 0,
  sale: 0,
  balance: 0,
};

Map.defaultProps = {
  north: submarketDefaultValue,
  northeast: submarketDefaultValue,
  south: submarketDefaultValue,
  southeast: submarketDefaultValue,
  isAveragePrice: false,
};

const propTypeSubmarketValue = PropTypes.shape({
  purchase: PropTypes.string,
  sale: PropTypes.string,
  balance: PropTypes.string,
});

Map.propTypes = {
  north: propTypeSubmarketValue,
  northeast: propTypeSubmarketValue,
  southeast: propTypeSubmarketValue,
  south: propTypeSubmarketValue,
  isAveragePrice: PropTypes.bool,
};

export default Map;
