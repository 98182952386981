import { endOfMonth, addMonths } from 'date-fns';

function getStartDate({ currentDate, startDate }) {
  if (currentDate >= startDate) return currentDate;

  return startDate;
}

function isDisabled({ currentDate, startDate, endDate, months = 1 }) {
  const tempEndDate = endOfMonth(addMonths(currentDate, months - 1));

  if (tempEndDate < startDate || currentDate > endDate) return true;
  if (startDate > currentDate && startDate < tempEndDate) return false;
  if (endDate > currentDate && endDate < tempEndDate) return false;
  if (currentDate >= startDate || currentDate <= endDate) return false;

  return true;
}

function getEndDate({ endDate, tempEndDate }) {
  if (tempEndDate <= endDate) return tempEndDate;

  return endDate;
}

export { getStartDate, getEndDate, isDisabled };
