import { Fragment, useCallback, useReducer, useState } from 'react';

import { submarkets } from '..';

import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { Flex, IconButton, parseSafeFloat, useDisclosure } from '@tradener/lumen';

import UPDATE_SIMULATION_PLDS, {
  SimulationPldInputProps,
  ResponseProps,
  PayloadProps,
} from '@graphql/mutation/updateSimulationPlds';
import { ExposureSubmarketProps } from '@graphql/query/simulation';

import usePeriodComparison from '~/hooks/usePeriodComparison';
import useUpdateEffect from '~/hooks/useUpdateEffect';
import useSimulation from '~/pages/Portfolio/useSimulation';
import useSimulationRules from '~/pages/Portfolio/useSimulationRules';
import UnfoldLess from '~/theme/icons/UnfoldLess';
import UnfoldMore from '~/theme/icons/UnfoldMore';
import fastProvider from '~/utils/fastProvider';
import getPeriodTime from '~/utils/getPeriodTime';

import useSubmitQueue from '../../context';
import SimulationPLD from '../SimulationPLD';
import SimulationSection from '../SimulationSection';
import { PLDStatus, TableColumn, TableHeading, TableHorizontalAxis, TablePLDColumn, TableVerticalAxis } from '../Table';
import { useSimulationTable } from '../withSimulationTable';

export type PldInputCompare = Pick<SimulationPldInputProps, 'period' | 'submarket'>;

const [withExposure, useExposure] = fastProvider(() => {
  const getNumberFormat = (value: number) => {
    const formatted = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      minimumFractionDigits: 2,
    }).format(value);

    return formatted;
  };

  return { getNumberFormat };
});

function SimulationExposure() {
  const { isOpen, onToggle } = useDisclosure();
  const getColor = usePeriodComparison();
  const [showPLDModal, toggleShowPLDModal] = useReducer((prev) => !prev, false);
  const { simulationPeriods } = useSimulationTable();
  const { enqueue, unenqueue, checkSubmitEnqueued } = useSubmitQueue();
  const { getNumberFormat } = useExposure();
  const { simulationId } = useSimulation();
  const { isReadOnly } = useSimulationRules();
  const [simulationPlds, setSimulationPlds] = useState<SimulationPldInputProps[]>([]);

  const [updateSimulationPlds, { client }] = useMutation<ResponseProps, PayloadProps>(UPDATE_SIMULATION_PLDS, {
    onCompleted: () => {
      toast.success('PLDs atualizados com sucesso!');
    },
    onError: ({ message }) => {
      toast.error(message);
    },
  });

  const getPldId = (pld: PldInputCompare) => `${pld.period}.${pld.submarket}`;

  const comparePldInput = (pld_1: PldInputCompare, pld_2: PldInputCompare) => getPldId(pld_1) === getPldId(pld_2);

  const onCancel = () => setSimulationPlds([]);

  const onSubmit = useCallback(async () => {
    if (simulationId) {
      await updateSimulationPlds({
        variables: {
          input: {
            simulationId,
            simulationPlds: simulationPlds.map(({ submarket, ...pld }) => ({ ...pld, submarket: submarket.toUpperCase() })),
          },
        },
      });

      await client.refetchQueries({ include: ['simulation'] });
    }
  }, [client, simulationId, simulationPlds, updateSimulationPlds]);

  const upsertSimulation = async ({ period, submarket, price }: SimulationPldInputProps) => {
    const index = simulationPlds.findIndex((current) => comparePldInput(current, { period, submarket }));

    if (index > -1) {
      setSimulationPlds((prev) => {
        const plds = [...prev];

        plds.splice(index, 1, { period, submarket, price });

        return plds;
      });
    } else {
      setSimulationPlds((prev) => [...prev, { period, submarket, price }]);
    }
  };

  useUpdateEffect(() => {
    if (simulationPlds.length) enqueue({ name: 'dre', onSubmit, onCancel });
  }, [onSubmit, enqueue, simulationPlds.length]);

  useUpdateEffect(() => {
    if (!simulationPlds.length && checkSubmitEnqueued('dre')) unenqueue('dre');
  }, [unenqueue, simulationPlds.length, checkSubmitEnqueued]);

  return (
    <>
      <SimulationSection
        label={
          <Flex flex="1" alignItems="center" justify="space-between">
            Exposição
            <IconButton aria-label="collpase toggle" onClick={onToggle} color="white" size="xs">
              {isOpen ? <Flex as={UnfoldLess} boxSize="5" /> : <Flex as={UnfoldMore} boxSize="5" />}
            </IconButton>
          </Flex>
        }
      />
      {isOpen && (
        <TableHorizontalAxis>
          <TableVerticalAxis position="sticky" left="0" gridTemplateColumns="200px" zIndex="2">
            {Object.keys(submarkets).map((submarket) => (
              <Fragment key={submarket}>
                <TableHeading fontWeight={400}>{`${submarkets[submarket]} (MWm)`}</TableHeading>
                <TableHeading onOpen={toggleShowPLDModal}>PLD (R$/MWh)</TableHeading>
              </Fragment>
            ))}
          </TableVerticalAxis>
          {simulationPeriods?.map(({ period, exposure }) => {
            const color = getColor(period);

            return (
              <TableVerticalAxis key={period} id={period} bgColor={color}>
                {Object.keys(submarkets).map((submarket) => {
                  const uniqueId = getPldId({ period, submarket });
                  const currentSubmarket = exposure[submarket] as ExposureSubmarketProps;
                  const currentPld = simulationPlds.find((current) => comparePldInput(current, { period, submarket }));
                  const value = currentPld ? currentPld?.price : currentSubmarket['pld'];
                  let edited = PLDStatus.INITIAL;

                  if (currentSubmarket.simulationPld?.edited) {
                    edited = PLDStatus.COMMITTED;
                  }

                  if (currentPld) {
                    edited = PLDStatus.TOUCHED;
                  }

                  return (
                    <Fragment key={uniqueId}>
                      <TableColumn>{getNumberFormat(currentSubmarket['balanceMwm'])}</TableColumn>
                      <TablePLDColumn
                        id={uniqueId}
                        isEditable={!isReadOnly && /CURRENT|AFTER/.test(getPeriodTime(period))}
                        edited={edited}
                        value={String(value)}
                        onSubmit={(value) => {
                          upsertSimulation({
                            submarket,
                            period,
                            price: parseSafeFloat(value)!,
                          });
                        }}
                        onDelete={(event) => {
                          event.stopPropagation();

                          if (currentPld) {
                            setSimulationPlds((current) =>
                              current.filter((current) => !comparePldInput(current, { period, submarket })),
                            );
                          } else {
                            upsertSimulation({
                              submarket,
                              period,
                              price: currentSubmarket.simulationPld?.price as number,
                            });
                          }
                        }}
                      />
                    </Fragment>
                  );
                })}
              </TableVerticalAxis>
            );
          })}
          <TableVerticalAxis gridTemplateColumns="initial" width="100%">
            {Object.keys(submarkets).map((submarket) => (
              <Fragment key={submarket}>
                <TableColumn>&nbsp;</TableColumn>
                <TableColumn>&nbsp;</TableColumn>
              </Fragment>
            ))}
          </TableVerticalAxis>
        </TableHorizontalAxis>
      )}
      <SimulationPLD isOpen={showPLDModal} onClose={toggleShowPLDModal} />
    </>
  );
}

export { useExposure };
export default withExposure(SimulationExposure);
