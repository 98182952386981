import PropTypes from 'prop-types';

import PeriodItem from './PeriodItem';
import { Container } from './styles';

function PeriodList(props) {
  const { data, volumeType, id, onChange } = props;

  return (
    <Container>
      {data.map((item, index) => (
        <PeriodItem
          id={id}
          key={item.id}
          startDate={item.startDate}
          endDate={item.endDate}
          volume={item.volume}
          volumeType={volumeType}
          onChange={onChange}
          index={index}
          disabled={item.disabled}
        />
      ))}
    </Container>
  );
}

PeriodList.defaultProps = {
  data: [],
};

PeriodList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      volume: PropTypes.shape({}),
    }),
  ),
  volumeType: PropTypes.oneOf(['MWh', 'MWm']).isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PeriodList;
