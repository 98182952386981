import { IconButton } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import { Form as UForm, Input as UInput } from '@rocketseat/unform';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Button = styled(MuiButton)`
  ${() => css`
    && {
      text-transform: capitalize;
      font-weight: 700;
      letter-spacing: 0.8px;
      height: 32px;
      box-shadow: unset;

      svg {
        color: #e96300;
      }
    }
  `}
`;

export const ExportButton = styled(IconButton)`
  ${({ theme }) => css`
    && {
      box-shadow: none;
      animation: none;
      transition: none;

      &:hover {
        cursor: pointer;
        box-shadow: none;
        animation: none;
        transition: none;
        background-color: transparent;
      }
      svg {
        font-size: 20px;
      }
    }
  `}
`;

export const Form = styled(UForm)`
  position: relative;
  width: 580px;
`;

export const Content = styled.div`
  max-height: 780px;
  display: flex;
  align-items: stretch;
  justify-content: start;
  flex-flow: row wrap;
  overflow-y: auto;

  padding: 24px 32px 16px;

  width: 100%;
  height: 100%;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;

  margin: 32px 36px;

  button {
    height: 32px;
    max-width: 232px;
    border-radius: 2px;

    box-shadow: unset;

    &:first-child {
      margin-right: 24px;
    }
  }
`;

export const Input = styled(UInput)`
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 10px;

  width: 100%;
  height: 40px;
  border-radius: 2px;

  outline: 0;
  border: 0;
`;
