import { Box } from '@material-ui/core';
import styled, { AnyStyledComponent, css } from 'styled-components';

export interface WidgetProps {
  className?: string;
  color?: string;
}

export const Container = styled(Box as AnyStyledComponent).attrs<WidgetProps>({
  className: 'widget__container',
  role: 'presentation',
})`
  padding: 16px;
  width: 100%;
  border-radius: 4px;

  ${({ color }) =>
    color &&
    css`
      border-top: 4px solid ${color};
    `};

  hr {
    margin-top: 8px;
    border: 1px solid #eee;
    height: 0;
  }
`;
