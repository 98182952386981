import { emphasize, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ shadows, palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '500px',
    margin: 0,
  },
  colorSecondary: {
    backgroundColor: palette.type === 'light' ? palette.grey[100] : palette.grey[600],
    color: palette.type === 'light' ? palette.grey[800] : palette.grey[100],
  },
  clickable: {
    userSelect: 'none',
    WebkitTapHighlightColor: 'transparent',
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: '#AAA',
      color: '#EEE',
    },
    '&:active': {
      boxShadow: shadows[1],
      backgroundColor: palette.primary.main,
    },
  },
  clickableColorPrimary: {
    '&:hover, &:focus': {
      backgroundColor: emphasize(palette.primary.main, 0.08),
    },
  },
}));

export default useStyles;
