import { useState, useContext, useEffect, useMemo } from 'react';

import Tab from '@DEPRECATED/components/Tab';
import TabPanel from '@DEPRECATED/components/TabPanel';
import Tabs from '@DEPRECATED/components/Tabs';
import { injectDefaultValues } from '@DEPRECATED/context/Proposals/actions';
import ProposalsContext from '@DEPRECATED/context/Proposals/context';

import Account from './Account';
import Offer from './Offer';
import Product from './Product';
import Proposed from './Proposed';
import { Content } from './styles';

export default function Navigation() {
  const { data, mergeData } = useContext(ProposalsContext);
  const { filled } = data.options;
  const [value, setValue] = useState(0);
  const [maxStep, setMaxStep] = useState(0);

  const enableDialog = useMemo(() => maxStep > 1, [maxStep]);

  useEffect(() => {
    setMaxStep(filled ? 3 : 0);
  }, [filled]);

  function handlePrevious(newValue) {
    setValue(newValue);
  }

  function handleNext(newValue) {
    setValue(newValue);
    setMaxStep((prevState) => (newValue > prevState ? newValue : prevState));

    mergeData(null, injectDefaultValues(data, data));
  }

  function handleChange(newValue) {
    if (newValue <= maxStep) handleNext(newValue);
  }

  const tabContent = {
    value,
    handleNext,
    handlePrevious,
  };

  return (
    <>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Conta" />
        <Tab label="Produto" />
        <Tab label="Oferta" />
        <Tab label="Proposta" />
      </Tabs>
      <Content>
        <TabPanel {...tabContent} index={0}>
          <Account />
        </TabPanel>
        <TabPanel {...tabContent} index={1}>
          <Product enableDialog={enableDialog} />
        </TabPanel>
        <TabPanel {...tabContent} index={2}>
          <Offer />
        </TabPanel>
        <TabPanel {...tabContent} index={3}>
          <Proposed />
        </TabPanel>
      </Content>
    </>
  );
}
