import { useVariant } from '@DEPRECATED/utils';
import gql from 'graphql-tag';

import { useTheme } from '@material-ui/core';

import BgClosed from './closed.svg';
import BgNegotiated from './negotiated.svg';
import BgOpened from './opened.svg';
import { FunnelPartStyled, Title, Content, Label, Value, Cell } from './styles';
import useColorMode from '~/hooks/useColorMode';

type StageValue = {
  total: number;
  percentage: number;
};

interface FunnelPartProps {
  variant: 'opened' | 'negotiated' | 'closed';
  value: number;
}

export interface IFunnelFragment {
  opened: StageValue;
  negotiated: StageValue;
  closed: StageValue;
}

const components = {
  Closed: BgClosed,
  Negotiated: BgNegotiated,
  Opened: BgOpened,
};

const FunnelPart: React.FC<FunnelPartProps> = ({ variant, value }) => {
  const Component = useVariant({
    component: components,
    variant,
  });

  return (
    <FunnelPartStyled>
      <img src={Component as string} alt={variant} />
      <span>{value}</span>
    </FunnelPartStyled>
  );
};

const Funnel: React.FC<IFunnelFragment> = (props) => {
  const { theme, colorTernary } = useColorMode();
  const { negotiated, opened, closed } = props;

  return (
    <>
      <Title>Funil de Ofertas</Title>
      <Content color={colorTernary('#A8A8A8', '#7c7c7c')}>
        <Cell color={colorTernary('#232323', '#eeeeee')}>
          <Label color={theme.palette.secondary.light}>Abertas</Label>
        </Cell>
        <Cell color={colorTernary('#232323', '#eeeeee')}>
          <FunnelPart variant="opened" value={opened.total} />
        </Cell>
        <Cell color={colorTernary('#232323', '#eeeeee')}>
          <Value>{`${Math.round(opened.percentage)}%`}</Value>
        </Cell>
        <Cell color={colorTernary('#232323', '#eeeeee')}>
          <Label color="#F9BC16">Negociadas</Label>
        </Cell>
        <Cell color={colorTernary('#232323', '#eeeeee')}>
          <FunnelPart variant="negotiated" value={negotiated.total} />
        </Cell>
        <Cell color={colorTernary('#232323', '#eeeeee')}>
          <Value>{`${Math.round(negotiated.percentage)}%`}</Value>
        </Cell>
        <Cell color={colorTernary('#232323', '#eeeeee')}>
          <Label color="#0EBE29">Fechadas</Label>
        </Cell>
        <Cell color={colorTernary('#232323', '#eeeeee')}>
          <FunnelPart variant="closed" value={closed.total} />
        </Cell>
        <Cell color={colorTernary('#232323', '#eeeeee')}>
          <Value>{`${Math.round(closed.percentage)}%`}</Value>
        </Cell>
      </Content>
    </>
  );
};

export const FunnelFragment = gql`
  fragment FunnelFragment on QuoteFunnel {
    opened {
      ...StageValue
    }
    negotiated {
      ...StageValue
    }
    closed {
      ...StageValue
    }
  }

  fragment StageValue on StageValue {
    total
    percentage
  }
`;

export default Funnel;
