import MUIGrid from '@material-ui/core/Grid';
import styled from 'styled-components';

export const Container = styled.div``;

export const Grid = styled(MUIGrid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;

  &.label {
    overflow-x: hidden;
    text-overflow: ellipsis;
    margin-right: 6px;
  }

  span {
    font-size: 15px;
  }
`;
