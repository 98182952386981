import { useContext, useMemo } from 'react';

import { SelectionControl } from '@DEPRECATED/components/Form';
import VerticalScroll from '@DEPRECATED/components/VerticalScroll';
import ProposalsContext from '@DEPRECATED/context/Proposals/context';
import { formatDistanceStrict } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { LABEL_PRICE_TYPE } from '../../keywords';
import QuoteItemList from './QuoteItemList';
import Resume from './Resume';
import { Container, Header, Content } from './styles';

function Price() {
  const { data, replaceData } = useContext(ProposalsContext);

  const { priceType, quoteItems, startDate, endDate, volumeType, periodType } = data;

  const selectedPeriod = useMemo(() => {
    if (!startDate || !endDate) return null;

    return formatDistanceStrict(startDate, endDate, {
      locale: ptBR,
    });
  }, [startDate, endDate]);

  function handleToggleVolumeType(event) {
    replaceData('volumeType', event.target.value);
  }

  const volumeTypeFields = [
    { value: 'MWm', label: 'MWm' },
    { value: 'MWh', label: 'MWh' },
  ];

  function onQuoteItemsUnmount(array) {
    replaceData(null, { quoteItems: array });
  }

  return (
    <Container>
      <Header>
        <Resume label="Periodo">{selectedPeriod}</Resume>
        <Resume label="Tipo de Preço">{LABEL_PRICE_TYPE[priceType]}/MWh</Resume>
        <Resume label="volume">
          {volumeTypeFields.map((option) => (
            <SelectionControl
              key={option.value}
              name="volumeType"
              variant="material"
              checked={option.value === data.volumeType}
              onChange={handleToggleVolumeType}
              {...option}
            />
          ))}
        </Resume>
      </Header>
      <Content>
        <VerticalScroll>
          <QuoteItemList
            initialState={quoteItems}
            startDate={startDate}
            endDate={endDate}
            volumeType={volumeType}
            periodType={periodType}
            onUnmount={onQuoteItemsUnmount}
          />
        </VerticalScroll>
      </Content>
    </Container>
  );
}

export default Price;
