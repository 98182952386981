import { useEffect, useState } from 'react';

import { Box, Select, Option, useTheme } from '@tradener/lumen';

import useUpdate from '~/hooks/useUpdateEffect';
import DEFAULT_ACCOUNTS from '~/utils/getDefaultAccounts';

import useTrading from '../../useTrading';

function InternalAccounts() {
  const { colors } = useTheme();
  const internalAccounts = DEFAULT_ACCOUNTS.filter(({ name }) => /^(pierp|tradener|tradener varejista|tradener rs)$/i.test(name));

  const { setFilter, filter } = useTrading();
  const [account, setAccount] = useState<string | undefined>(
    filter?.internalAccountId ?? internalAccounts?.find(({ name }) => /^(tradener)$/i.test(name))?.accountId,
  );

  const handleChange = (value: string | number) => {
    setAccount(internalAccounts.find(({ accountId: id }) => id === value)?.accountId);
  };

  useUpdate(() => {
    setFilter('internalAccountId', account);
  }, [setFilter, account]);

  useEffect(() => {
    if (!filter.internalAccountId) {
      setFilter('internalAccountId', internalAccounts.find(({ name }) => /^tradener$/i.test(name))?.accountId);
    }
  }, [filter.internalAccountId, internalAccounts, setFilter]);

  useEffect(() => {
    if (filter.tenantId) {
      setFilter('tenantId', undefined);
    }
  }, [filter.tenantId, setFilter]);

  return (
    <Box
      minWidth="52"
      textStyle="body2-regular"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      overflow="clip"
      borderRadius="base"
      outline="1px solid"
      outlineOffset="-1px"
      outlineColor={colors.gray[900] + '29'}
      height="8"
      cursor="pointer"
      _hover={{
        '&:first-of-type:div': { bgColor: `${colors.gray[900]}14` },
      }}
      sx={{
        '& > div': { height: '8', cursor: 'pointer' },
        '.chakra-popover__content': { top: '1', padding: '0' },
      }}
    >
      <Select
        placeholder="Selecione"
        defaultValue={account}
        onChange={handleChange}
        color="gray.900"
        bgColor="transparent"
        borderRadius="base"
        isSmall
        gap="2.5"
        closeOnSelect={true}
        textStyle="body2-regular"
        width="52"
        padding="0"
        paddingBlock=".3438rem"
        paddingLeft="3"
        paddingRight="2"
        _focus={{ boxShadow: 'md' }}
        sx={{ '& > div': { overflow: 'hidden' } }}
      >
        {internalAccounts.map(({ accountId: id, name }) => (
          <Option
            key={id}
            value={id}
            height="8"
            width="47"
            cursor="pointer"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {name}
          </Option>
        ))}
      </Select>
    </Box>
  );
}

export default InternalAccounts;
