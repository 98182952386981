import { FlexProps, EmptyStateIcon, Flex, Text } from '@tradener/lumen';

export interface FallbackProps extends FlexProps {
  message?: string;
  iconSize?: number;
  icon?: React.ReactNode;
  props?: FlexProps;
}

export const Fallback: React.FC<FallbackProps> = ({ message, icon, iconSize, ...props }) => (
  <Flex w="100%" h="100%" {...props}>
    <Flex flexDir="column" m="auto" alignItems="center" gap={6}>
      {icon ? icon : <EmptyStateIcon fontSize={iconSize ? iconSize : 164} aria-label="Sem dados" />}
      <Text textStyle="subheading1-regular" color="gray">
        {message ? message : 'Ops! Nada para mostrar aqui ainda.'}
      </Text>
    </Flex>
  </Flex>
);
