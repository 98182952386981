import { Controller, useFormContext } from 'react-hook-form';

import { FormControl, FormLabel, RadioSelector, SelectorItem } from '@tradener/lumen';

import { useOffer } from '../context';

function PickSubmarket() {
  const { bbceProductId } = useOffer();
  const { control } = useFormContext();

  if (bbceProductId) return null;

  return (
    <FormControl data-testid="modal-submarket" display="flex" flexDirection="column" gap="1.5">
      <FormLabel margin="0">Submercado</FormLabel>
      <Controller
        control={control}
        name="submarket"
        render={({ field: { ref: _, ...field } }) => (
          <RadioSelector {...field}>
            <SelectorItem value="SE">Sudeste / Centro-Oeste</SelectorItem>
            <SelectorItem value="S">Sul</SelectorItem>
            <SelectorItem value="NE">Nordeste</SelectorItem>
            <SelectorItem value="N">Norte</SelectorItem>
          </RadioSelector>
        )}
      />
    </FormControl>
  );
}

export default PickSubmarket;
