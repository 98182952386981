const createDynamicRange =
  <T>(negative: T, stable: T, positive: T) =>
  (value: number) => {
    if (value > 0) return positive;
    else if (value < 0) return negative;

    return stable;
  };

export default createDynamicRange;
