import { useRef } from 'react';

import { Tooltip, Typography, useTheme } from '@material-ui/core';
import { Flex } from '@tradener/lumen';
import { DateTime } from 'luxon';

import Container from '~/theme/layout/Container';
import Filter from '~/theme/layout/Filter';

import Badges from '../Badges';
import SimulationSubheader, { SimulationName } from '../Simulation/SimulationSubheader';
import SimulationBalance from '../Simulation/SimulationTable/SimulationBalance';
import SimulationDRE from '../Simulation/SimulationTable/SimulationDRE';
import SimulationExposure from '../Simulation/SimulationTable/SimulationExposure';
import { withSimulationTable } from '../Simulation/SimulationTable/withSimulationTable';
import useSimulation from '../useSimulation';

const isStaging = process.env.REACT_APP_ROLLBAR_ENV === 'staging';

export const submarkets = {
  se: 'SE/CO',
  s: 'Sul',
  ne: 'Nordeste',
  n: 'Norte',
};

const FULL_DATE = 'dd LLL yyyy';
const DATETIME = 'HH:mm:ss';

function PortfolioTable() {
  const { synchronizedAt } = useSimulation();
  const { zIndex } = useTheme();
  const badgesRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <SimulationSubheader width="calc(100% - 20px)" zIndex={zIndex.appBar}>
        <SimulationName />
        <Tooltip
          title={DateTime.fromISO(synchronizedAt as string).toFormat(`${FULL_DATE} 'às' ${DATETIME}`, { locale: 'pt-BR' })}
        >
          <Typography color="secondary" style={{ fontSize: '14px' }}>
            {synchronizedAt && `Atualizado ${DateTime.fromISO(synchronizedAt).toRelative({ locale: 'pt-BR' })}`}
          </Typography>
        </Tooltip>
      </SimulationSubheader>
      <Badges ref={badgesRef} />
      <Flex
        direction="row"
        justify="space-between"
        position="sticky"
        align="flex-start"
        top="300px"
        maxHeight={
          isStaging
            ? `calc(100vh - 197px - ${badgesRef.current ? '48px' : '0px'})`
            : `calc(100vh - 160px - ${badgesRef.current ? '48px' : '0px'})`
        }
      >
        <Container display="grid" overflow="auto" gridAutoColumns="repeat(auto-fit, minmax(100vw, 1fr))">
          <SimulationBalance />
          <SimulationDRE />
          <SimulationExposure />
        </Container>
        <Filter />
      </Flex>
    </>
  );
}

export default withSimulationTable(PortfolioTable);
