import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Dialog from '@DEPRECATED/components/Dialog';
import { InputLayout, Input } from '@DEPRECATED/components/Form';
import client from '~/services/apollo';
import { useQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import gql from 'graphql-tag';

import { Container, ExtraContent } from './styles';
import useColorMode from '~/hooks/useColorMode';

export const GET_EXTRA_QUOTE = gql`
  query extraQuote($id: ID!) {
    quote(id: $id) {
      id
      externalId
      giro
      notes
      swap
      opportunity {
        channel
      }
    }
  }
`;

const SAVE_EXTRA_QUOTE = gql`
  mutation saveExtraQuote($input: UpdateEnergyQuoteExtraInput!) {
    updateEnergyQuoteExtra(input: $input) {
      quote {
        id
      }
    }
  }
`;

export default function Extra() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [isBbce, setIsBbce] = useState(false);
  const push = useNavigate();
  const { colorTernary } = useColorMode();

  const { data: extraQuote } = useQuery(GET_EXTRA_QUOTE, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
  });

  useEffect(() => {
    if (extraQuote) {
      const { opportunity } = extraQuote.quote;

      setIsBbce(opportunity.channel === 'bbce');
      setData(extraQuote.quote);
    }
  }, [extraQuote]);

  if (!extraQuote) return null;

  const { externalId, giro, notes, swap } = data;

  function handleClose() {
    push('/book/list');
  }

  function handleChange({ target }) {
    const { name, value, checked } = target;

    setData((prevData) => ({
      ...prevData,
      [name]: name === 'swap' ? checked : value,
    }));
  }

  async function handleSubmit() {
    try {
      await client.mutate({
        mutation: SAVE_EXTRA_QUOTE,
        variables: {
          input: { id, externalId, giro, notes, swap },
        },
      });

      toast.success('Complemento alterado com sucesso!');
      push('/book/list');
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Dialog id={id} title="Complemento da Oferta" open onClose={handleClose} fullWidth>
      <Container color={colorTernary('#111', '#fff')}>
        <ExtraContent>
          <InputLayout label="Giro">
            <Input name="giro" value={giro} onChange={handleChange} />
          </InputLayout>

          <InputLayout label="Ref. Externa">
            <Input name="externalId" value={externalId} onChange={handleChange} disabled={isBbce} />
          </InputLayout>
          <div>
            <span>Observação</span>
            <Input
              name="notes"
              value={notes}
              onChange={handleChange}
              multiline
              rows="4"
              style={{
                width: '100%',
                maxWidth: 496,
              }}
            />
            <Box display='flex' alignItems='center'>
              <span>Swap</span>
              <Checkbox name="swap" color="primary" checked={Boolean(swap)} onChange={handleChange} />
            </Box>
            <Button fullWidth variant="contained" color="primary" onClick={handleSubmit}>
              SALVAR
            </Button>
          </div>
        </ExtraContent>
      </Container>
    </Dialog>
  );
}
