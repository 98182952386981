import { useState, useEffect } from 'react';

import Dialog from '@DEPRECATED/components/Dialog';
import { Button } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { startOfMonth, startOfYear, addMonths, endOfMonth } from 'date-fns';
import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { updateFieldsVolume } from '../../reducer';
import { getStartDate, isDisabled, getEndDate } from './ExpandQuoteItemsHelper';
import PeriodList from './PeriodList';
import PeriodSelector from './PeriodSelector';
import { ScaleDialog, Container, List, Row, Footer } from './styles';

function ExpandQuoteItems({ quoteItems, volumeType, open, onClose, onSave }) {
  const [state, setState] = useState(quoteItems);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    function parseQuoteItems() {
      const newQuoteItems = quoteItems.map((quoteItem) => {
        if (isEmpty(quoteItem.subItems)) {
          return {
            ...quoteItem,
            group: 1,
            subItems: [
              {
                id: uuid(),
                volume: { ...quoteItem.volume },
                startDate: quoteItem.startDate,
                endDate: quoteItem.endDate,
              },
            ],
          };
        }

        return { ...quoteItem };
      });

      setState(newQuoteItems);
    }

    parseQuoteItems();
  }, [quoteItems]);

  function handleFragmentedRange({ value, index }) {
    let { startDate, endDate } = state[index];

    endDate = endOfMonth(endDate);
    startDate = startOfMonth(startDate);

    let currentDate = startOfYear(startDate);
    let count = 0;

    const months = 12 / value;

    const newValues = produce(state, (draft) => {
      draft[index].group = value;
      draft[index].subItems = [];

      while (endDate > currentDate) {
        const tempEndDate = endOfMonth(addMonths(currentDate, months - 1));

        const data = {
          currentDate,
          startDate,
          tempEndDate,
          endDate,
          months,
        };

        if (!isDisabled(data)) {
          draft[index].subItems.push({
            id: uuid(),
            volume: { ...draft[index].volume },
            startDate: getStartDate(data),
            endDate: getEndDate(data),
          });

          updateFieldsVolume({
            data: draft[index].subItems[count],
            type: 'MWm',
          });

          count += 1;
        }

        currentDate = startOfMonth(addMonths(currentDate, months));
      }
    });

    setState(newValues);
  }

  function handleVolume({ target }, type, id, index) {
    const { value } = target;

    const newValues = produce(state, (draft) => {
      const item = draft.find((quoteItem) => quoteItem.id === id);

      item.subItems[index].volume = updateFieldsVolume({
        data: item.subItems[index],
        type,
        value,
      });
    });

    setState(newValues);
  }

  function handleSave() {
    const newState = produce(state, (draft) => {
      draft.forEach((quoteItem) => {
        quoteItem.volume.MWh = quoteItem.subItems.reduce((acc, curr) => acc + Number(curr.volume.MWh), 0);

        updateFieldsVolume({ data: quoteItem, type: 'MWh' });
      });
    });

    onSave(newState);
    onClose();
  }

  return (
    <ScaleDialog
      id="offer split"
      open={open}
      title="Oferta escalonada"
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      fullScreen={fullScreen}
    >
      <Container>
        <List>
          {state.map((quoteItem, index) => (
            <Row key={quoteItem.id}>
              <PeriodSelector
                index={index}
                startDate={quoteItem.startDate}
                value={quoteItem.group}
                onChange={handleFragmentedRange}
              />
              <PeriodList data={quoteItem.subItems} volumeType={volumeType} id={quoteItem.id} onChange={handleVolume} />
            </Row>
          ))}
        </List>
        <Divider />

        <Footer>
          <Button variant="contained" color="secondary" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Salvar
          </Button>
        </Footer>
      </Container>
    </ScaleDialog>
  );
}

ExpandQuoteItems.propTypes = {
  quoteItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      startDate: PropTypes.instanceOf(Date),
      subItems: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ).isRequired,
  volumeType: PropTypes.oneOf(['MWm', 'MWh']).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ExpandQuoteItems;
