import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { Button, CircularProgress, Typography } from '@material-ui/core';

import SYNC_SIMULATION, {
  ResponseProps as SyncSimulationResponseProps,
  PayloadProps as SyncSimulationPayloadProps,
} from '@graphql/mutation/syncSimulation';
import SIMULATION from '@graphql/query/simulation';

import useColorMode from '~/hooks/useColorMode';
import Modal, { ModalBody, ModalHeader, ModalFooter, ModalProps } from '~/theme/components/Modal';

import useSimulation from '../../useSimulation';
import SimulationCreation from '../SimulationCreation';
import { useModalContext, withIsOpenController, withModalContext } from '../SimulationSubheader';

const SimulationSync: React.ComponentType<ModalProps> = ({ isOpen, onClose, ...props }) => {
  const { colorTernary } = useColorMode();
  const { simulationId } = useSimulation();
  const { onOpen } = useModalContext();
  const SimulationCreationController = withIsOpenController(SimulationCreation);

  const [syncSimulation, { loading, client }] = useMutation<SyncSimulationResponseProps, SyncSimulationPayloadProps>(
    SYNC_SIMULATION,
    {
      onCompleted: async () => {
        if (client) {
          await client.refetchQueries({ include: [SIMULATION] });
        }

        toast.success('Simulação sincronizada.');
      },
      onError: async ({ message }) => {
        toast.error(message);
      },
    },
  );

  const handleOnClickNewSimulation: React.MouseEventHandler = (event) => {
    onClose(event, 'backdropClick');
    onOpen('CREATE')();
  };

  const handleSync: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    await syncSimulation({
      variables: { input: { simulationId: simulationId as string } },
    });
    await client.refetchQueries({
      include: ['simulations'],
    });

    onClose(event, 'manually');
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} {...props}>
        <ModalHeader style={{ height: '64px' }}>
          <Typography variant="h5">Deseja atualizar a base?</Typography>
        </ModalHeader>
        <ModalBody style={{ height: '72px' }}>
          <Typography variant="body1" style={{ color: `${colorTernary('#797979', '#868686')}` }}>
            Ao clicar em atualizar, toda a base de contratos, saldos e PLD serão ajustados para os valores mais recentes em nossa
            base de dados, e irão sobrescrever os valores atuais. Essa operação não poderá ser desfeita.
          </Typography>
        </ModalBody>
        <ModalFooter style={{ height: '88px' }}>
          <Button
            variant="outlined"
            color="secondary"
            disableElevation
            style={{ color: `${colorTernary('#d9d9d9', '#262626')}`, marginRight: '10px' }}
            onClick={(event) => onClose(event, 'backdropClick')}
          >
            Cancelar
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            disableElevation
            style={{ color: `${colorTernary('#d9d9d9', '#262626')}`, marginRight: '10px' }}
            onClick={handleOnClickNewSimulation}
          >
            Nova simulação
          </Button>
          <Button variant="contained" color="primary" onClick={handleSync} disabled={loading} disableElevation>
            {loading ? <CircularProgress size="20px" color="secondary" /> : 'Atualizar'}
          </Button>
        </ModalFooter>
      </Modal>
      <SimulationCreationController id="CREATE" />
    </>
  );
};

export default withModalContext(SimulationSync);
