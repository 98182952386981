import { memo } from 'react';

import { Flex, Text, Theme, useTheme } from '@tradener/lumen';
import { DateTime } from 'luxon';
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Cell,
  Line,
  ReferenceLine,
  Tooltip as RechartsTooltip,
  ComposedChart,
  Legend,
} from 'recharts';

import CandleBar from '../components/CandleBar';
import CustomLegend from '../components/CustomLegend';
import Tooltip from '../components/PricesTooltip';
import TickLabelXAxis from '../components/TickLabelXAxis';
import { parsedPrices } from '../configs';
import parseKeys from '../priceKeys';
import { useProductAnalytics } from '../useProductAnalytics';

function ProductPrices() {
  const { colors } = useTheme<Theme>();
  const { series } = useProductAnalytics();
  const prices = parsedPrices(series);
  const [coords] = parseKeys(prices as any);

  if (!prices?.length || !coords?.length) return null;

  const years = [
    ...new Set(prices?.map(([timestamp]) => DateTime.fromMillis(timestamp as unknown as number)?.year.toString() as string)),
  ];
  const dataMax = Math.max(...prices.map(([_, item]) => Math.max(...(item as any).map((value?: number) => Number(value)))));

  return (
    <Flex data-testid="product-prices" flexDirection="column" alignItems="center" width="full" flex="1">
      <Flex
        flexDirection="column"
        bgColor="gray.0"
        height="25rem"
        width="full"
        borderRadius="xl"
        padding="5"
        gap="1"
        color={colors.gray[900]}
      >
        <Flex flexDirection="column" justifyContent="center" gap="0.5">
          <Text textStyle="subheading1-semibold">Preço</Text>
        </Flex>
        <Flex
          direction="column"
          width="100%"
          height="100%"
          paddingTop="1"
          overflow="visible"
          sx={{
            '.recharts-text': { fill: colors.gray[500] },
            '.recharts-cartesian-axis-line, .recharts-brush > rect': { stroke: 'none' },
            '.recharts-cartesian-axis-tick-line, line': { stroke: colors.gray[100] },
          }}
        >
          <ResponsiveContainer width="100%">
            <ComposedChart data={coords} margin={{ top: 35, left: 15, right: 55 }} syncId="balanceId">
              <XAxis
                dataKey="timestamp"
                type="category"
                domain={['dataMin', 'dataMax']}
                tickLine={false}
                minTickGap={40}
                padding={{ left: 40, right: 40 }}
                stroke={colors.gray[200]}
                tick={<TickLabelXAxis mode="DAY" />}
              />
              <YAxis
                tickCount={5}
                tickMargin={10}
                padding={{ top: 35 }}
                domain={['auto', dataMax * 1.25]}
                label={{ value: 'R$', position: 'top' }}
                tick={{ fill: colors.gray[600], fontSize: '0.875rem' }}
                tickLine={false}
              />
              <CartesianGrid vertical={false} strokeWidth={1} stroke={colors.gray[200]} />
              <RechartsTooltip
                filterNull
                useTranslate3d
                allowEscapeViewBox={{ x: true, y: true }}
                wrapperStyle={{ left: -195 }}
                isAnimationActive={false}
                cursor={{ stroke: `${colors.gray[900]}14`, radius: 2, opacity: 0.75 }}
                content={<Tooltip />}
              />
              {years?.map((year) => {
                const xAxis = coords.find(({ timestamp }) => DateTime.fromMillis(timestamp).toFormat('yyyy') === year)?.timestamp;

                if (!xAxis) return null;

                return (
                  <ReferenceLine
                    key={year}
                    x={xAxis}
                    stroke={`${colors.gray[900]}29`}
                    strokeWidth={2}
                    ifOverflow="discard"
                    isFront={false}
                    strokeDasharray="6 6"
                    label={{ value: year, position: 'insideTopLeft', fill: colors.gray[500] }}
                  />
                );
              })}
              <Bar dataKey="range" barSize={8} isAnimationActive={false} shape={(props) => <CandleBar {...props} />}>
                {coords.map(({ date }) => (
                  <Cell key={date} />
                ))}
              </Bar>
              <Line
                name="PLD alto"
                dot={false}
                strokeWidth={2}
                type="monotone"
                dataKey="pldHigh"
                stroke={colors.red[500]}
                isAnimationActive={false}
              />
              <Line
                name="PLD previsto"
                dot={false}
                strokeWidth={2}
                type="monotone"
                dataKey="pld"
                stroke={colors.blue[500]}
                isAnimationActive={false}
              />
              <Legend content={<CustomLegend />} />
            </ComposedChart>
          </ResponsiveContainer>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default memo(ProductPrices);
