/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRef, useState, useEffect, useCallback, useMemo, forwardRef, useImperativeHandle } from 'react';

import { NumericFormat } from 'react-number-format';

import {
  Box,
  Checkbox,
  Chip,
  Collapse,
  darken,
  FormControlLabel,
  IconButton,
  lighten,
  Link,
  Tooltip,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  Typography,
  useTheme,
} from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { DateTime } from 'luxon';
import { nanoid } from 'nanoid';

import { PeriodProps } from '@graphql/query/contracts';

import useColorMode from '~/hooks/useColorMode';
import usePortfolio from '~/pages/Portfolio/usePortfolio';
import useUpdate from '~/hooks/useUpdateEffect';
import usePortfolioFilter from '~/pages/Portfolio/usePortfolioFilter';
import sleep from '~/utils/sleep';

import { decimalScales } from '../../Table';
import useStyles from './styles';

import { DataProps } from '..';

export interface TableContainerProps {
  region: string;
  data: Array<DataProps>;
  periods: Array<string>;
  searching: boolean;
}

export type ImperativeTableProps = Record<'excludeds' | 'preserveds', Array<string>>;

const { year, month, day } = DateTime.now();

const Table = forwardRef<ImperativeTableProps, TableContainerProps>(({ region, data, periods, searching }, ref) => {
  const { sticky, ellipsis, chip, table, stickyHead } = useStyles();
  const [expanded, setExpanded] = useState(true);
  const [items, setItems] = useState<Array<DataProps>>([]);
  const { colorTernary } = useColorMode();
  const { ...state } = usePortfolio();
  const tableRef = useRef<HTMLTableElement>(null);
  const { view } = usePortfolioFilter();
  const {
    palette: { grey, common },
  } = useTheme();
  const [{ excludeds, preserveds }, setContracts] = useState<ImperativeTableProps>({
    excludeds: data.filter(({ checked }) => !checked).map(({ id }) => id),
    preserveds: data.filter(({ checked }) => checked).map(({ id }) => id),
  });

  const relay = useMemo(() => colorTernary(lighten, darken), [colorTernary]);

  const toggleExpansion = useCallback(() => {
    if (expanded) setItems([]);

    setExpanded(!expanded);
  }, [expanded]);

  const handleToggle = useCallback(
    (id: string) => () => {
      const unfold = excludeds.includes(id);

      const filter = (data: Array<string>) => data.filter((contract) => contract !== id);

      setContracts((prev) => ({
        ...prev,
        excludeds: unfold ? filter(prev.excludeds) : [...prev.excludeds, id],
        preserveds: !unfold ? filter(prev.preserveds) : [...prev.preserveds, id],
      }));
    },
    [excludeds],
  );

  const handleToggleAll = useCallback(
    () =>
      setContracts((prev) => ({
        excludeds: prev.excludeds.length === 0 ? data.map(({ id }) => id) : [],
        preserveds: prev.preserveds.length === data.length ? data.map(({ id }) => id) : [],
      })),
    [data],
  );

  const exclusion = useCallback(
    (data: Array<DataProps>) =>
      data.map((contract) => {
        if (excludeds.includes(contract.id)) return { ...contract, checked: false };

        return contract;
      }),
    [excludeds],
  );

  const cellColor = useCallback(
    (period: string) => {
      const styles: React.CSSProperties = {};

      if (/^\d{4}$/.test(period) && period === year.toString()) {
        styles.backgroundColor = relay(colorTernary(grey[900], common.white), 0.075);
      } else if (/^\d{4}-\d{2}$/.test(period)) {
        const date = period.split('-').map((value) => parseInt(value, 10));

        const aproxMonth = day > 15 ? month : month - 1;

        if (date[0] === year && date[1] <= aproxMonth) {
          const current = DateTime.local(...date).equals(DateTime.fromObject({ year, month: aproxMonth }));

          styles.backgroundColor = relay(colorTernary(grey[800], common.white), current ? 0.075 : 0.03);
        }
      }

      return styles;
    },
    [colorTernary, common.white, grey, relay],
  );

  const placeholder = useCallback(
    (contractPeriods: Array<PeriodProps>) => {
      if (!contractPeriods) return [];

      return periods.map((period) => {
        const index = contractPeriods.findIndex(({ period: current }) => current === period);

        return index > -1
          ? contractPeriods[index]
          : {
              period,
              price: -1,
              quantityMwm: -1,
              totalAmount: -1,
            };
      });
    },
    [periods],
  );

  const populate = useCallback(async () => {
    let tmp: Array<DataProps> = [];

    for await (const current of data) {
      tmp.push(current);

      const cursor = data.indexOf(current);

      if (cursor > 0 && cursor % 15 === 0) {
        setItems((prev) => [...prev, ...tmp]);

        await sleep(50);

        tmp = [];
      }
    }

    if (tmp.length > 0) setItems((prev) => [...prev, ...tmp]);
  }, [data]);

  useImperativeHandle(
    ref,
    () => ({
      excludeds,
      preserveds,
    }),
    [excludeds, preserveds],
  );

  useUpdate(() => {
    if (!searching) setItems([]);
  }, [searching]);

  useUpdate(() => {
    if (searching) {
      if (data.length > 0) setItems([]);
      if (!expanded) setExpanded(true);
    }
  }, [data.length, expanded, searching]);

  useEffect(() => {
    if (expanded && items.length === 0) {
      populate();
    }
  }, [expanded, items.length, populate]);

  return (
    <>
      <MuiTable classes={{ root: table }} className={stickyHead}>
        <MuiTableHead>
          <MuiTableRow>
            <MuiTableCell width="350px" classes={{ root: sticky }} style={{ backgroundColor: 'inherit' }}>
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" width="312px">
                <FormControlLabel
                  control={
                    <Checkbox
                      indeterminate={excludeds.length > 0 && excludeds.length < data.length}
                      checked={excludeds.length === 0 && data.length > 0}
                      onChange={handleToggleAll}
                      disabled={data.length === 0}
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                      {region}
                    </Typography>
                  }
                />
                <IconButton size="small" onClick={toggleExpansion} disabled={data.length === 0}>
                  {expanded && data.length > 0 ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Box>
            </MuiTableCell>
            {(() => {
              const placeholders: Array<string> = [];
              let i = 1;

              while (i <= periods.length) {
                placeholders.push(nanoid());
                i += 1;
              }

              return placeholders.map((id) => <MuiTableCell key={id} width="80px" />);
            })()}
            <MuiTableCell />
          </MuiTableRow>
        </MuiTableHead>
      </MuiTable>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <MuiTable ref={tableRef} classes={{ root: table }}>
          <MuiTableBody>
            {data.length > 0 &&
              exclusion(items).map((contract) => (
                <MuiTableRow key={contract?.id ?? nanoid()}>
                  <MuiTableCell width="350px" classes={{ root: sticky }}>
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={!excludeds.includes(contract?.id)}
                            onChange={handleToggle(contract?.id)}
                          />
                        }
                        label={
                          <Box className={ellipsis}>
                            <Tooltip title={contract?.accountName} placement="top">
                              <Link
                                href={`${process.env.REACT_APP_SALESFORCE_URL}/lightning/r/Contract/${contract?.crmId}/view`}
                                target="blank"
                                color="inherit"
                              >
                                {`${contract?.contractNumber ? `${contract?.contractNumber} -` : ''} ${contract?.accountName}`}
                              </Link>
                            </Tooltip>
                          </Box>
                        }
                      />
                      <Box display="flex" justifyContent="center" alignItems="center" width="40px">
                        <Chip color="primary" label={contract?.energyType} size="small" classes={{ root: chip }} />
                      </Box>
                    </Box>
                  </MuiTableCell>
                  {[state?.usageFactorByContract, state?.usageFactorByEnergyType].map(
                    (coef, index) =>
                      coef && (
                        <MuiTableCell key={nanoid()} width="110px" align="right">
                          <NumericFormat
                            displayType="text"
                            value={contract[index === 0 ? 'usageFactorByContract' : 'usageFactorByEnergyType']}
                            decimalScale={3}
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale
                          />
                        </MuiTableCell>
                      ),
                  )}
                  {placeholder(contract?.periods).map(({ period, ...contract }) => (
                    <MuiTableCell key={period} width="80px" align="right" style={cellColor(period)}>
                      {contract.price > -1 && (
                        <Typography variant="caption">
                          <NumericFormat
                            displayType="text"
                            value={contract[view]}
                            decimalScale={decimalScales[view]}
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale
                          />
                        </Typography>
                      )}
                    </MuiTableCell>
                  ))}
                  <MuiTableCell />
                </MuiTableRow>
              ))}
          </MuiTableBody>
        </MuiTable>
      </Collapse>
    </>
  );
});

Table.defaultProps = {
  periods: [],
};

Table.displayName = 'Table';

export default Table;
