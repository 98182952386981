import { useState, useMemo } from 'react';

import { FiUserPlus } from 'react-icons/fi';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import CustomChip from '../CustomChip';
import { Container, Name, Email, Role, TextField } from './styles';

function ContactForm({ addContact }) {
  const theme = useTheme();
  const [showInput, setShowInput] = useState(false);
  const [isCommercial, setCommercial] = useState(true);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  function handleRole() {
    setCommercial(!isCommercial);
  }

  function reset() {
    setName('');
    setEmail('');
    setCommercial(false);
    setShowInput(false);
  }

  const emptyName = useMemo(() => !name, [name]);
  const emptyEmail = useMemo(() => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

    return !email || !email.match(emailRegex);
  }, [email]);

  function handleSubmit() {
    addContact({ name, email, commercial: isCommercial });
    reset();
  }

  return (
    <>
      <Box hidden={!showInput}>
        <Container>
          <Name>
            <TextField
              label="Nome"
              name="name"
              value={name}
              error={emptyName}
              onChange={({ target }) => setName(target.value)}
              autoComplete="off"
            />
          </Name>
          <Email>
            <TextField
              label="Email"
              name="email"
              type="email"
              value={email}
              error={emptyEmail}
              onChange={({ target }) => setEmail(target.value)}
              autoComplete="off"
            />
          </Email>
          <Role>
            <ListItemIcon>
              <CustomChip checked={isCommercial} type="button" onClick={handleRole} />
            </ListItemIcon>
          </Role>
        </Container>
      </Box>

      <Box margin="10px 0">
        {showInput && (
          <Button
            color="secondary"
            variant="contained"
            startIcon={<FiUserPlus />}
            fullWidth
            disableElevation
            style={{
              borderRadius: 0,
            }}
            onClick={handleSubmit}
            disabled={emptyName || emptyEmail}
          >
            Salvar
          </Button>
        )}
        {!showInput && (
          <Button
            color="secondary"
            variant="contained"
            startIcon={<FiUserPlus />}
            fullWidth
            disableElevation
            style={{
              borderRadius: 0,
            }}
            type="button"
            onClick={() => setShowInput(!showInput)}
          >
            Cadastrar novo contato
          </Button>
        )}
      </Box>
    </>
  );
}

ContactForm.propTypes = {
  addContact: PropTypes.func.isRequired,
};

export default ContactForm;
