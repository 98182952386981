import { DateTime } from 'luxon';

import { BbceProductPrices } from '@graphql/query/bbceProductsPrices';

type OPEN_PRICE = number;

type CLOSE_PRICE = number;

export interface GraphProps {
  date: string;
  timestamp: number;
  high?: number;
  low?: number;
  trend?: number;
  range?: [OPEN_PRICE, CLOSE_PRICE];
  pld?: number;
  quantityMwm?: number;
  pldHigh?: number;
}

const priceKeys = (products: BbceProductPrices[] = []) => {
  const data: GraphProps[] = [];
  let trend = undefined as number | undefined;

  if (!products.length) return [data];

  for (const [milles, product] of products) {
    if (product.length !== 7) continue;

    const [open, close, high, low, pld, _, pldHigh] = product;
    const index = products.findIndex(([currentDate]) => currentDate === milles);

    if (index > -1) {
      const date = DateTime.fromMillis(milles as number, { locale: 'pt-BR' });

      if (close > 0) trend = close;

      data[index] = {
        date: date.toFormat('dd LLL yy', { locale: 'pt-br' }),
        timestamp: date.toMillis(),
        high,
        low,
        trend,
        range: [open, close],
        pld: pld ?? undefined,
        pldHigh: pldHigh ?? undefined,
      };
    }
  }

  return [data] as [GraphProps[]];
};

export default priceKeys;
