declare global {
  interface String {
    capitalize(): string;
    formatTaxId(): string;
    unFormatTaxId(): string;
  }
}

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};

String.prototype.formatTaxId = function () {
  return this.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
};

String.prototype.unFormatTaxId = function () {
  return this.replace(/\D/g, '');
};

export default String;
