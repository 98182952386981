import { Box, BoxProps } from '@material-ui/core';

import useColorMode from '~/hooks/useColorMode';
import usePortfolioFilter from '~/pages/Portfolio/usePortfolioFilter';

const Container: React.ComponentType<BoxProps> = (props) => {
  const { theme, colorTernary } = useColorMode();
  const { isOpen } = usePortfolioFilter();

  return (
    <Box
      width={`calc(100vw - ${isOpen ? 360 : 72 + 20}px)`}
      bgcolor={colorTernary(theme.palette.grey[800], 'white')}
      style={{ borderRadius: '6px', padding: '0' }}
      paddingRight="2px"
      {...props}
    />
  );
};

export default Container;
