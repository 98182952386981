import { getStoredSession } from '~/services/sessionAlive';

import ActionCable from './consumer';

export const getProtocols = () => {
  const token = getStoredSession();

  return [`actioncable-access-token-${token}`];
};

const cable = new ActionCable(`${process.env.REACT_APP_CORE_API_URL}/cable`);

export default cable;
