import Energy from '@DEPRECATED/assets/images/energy_for_all.svg';
import Tradener from '@DEPRECATED/assets/images/tradener.svg';

import { FiUser, FiLock } from 'react-icons/fi';
import { Button } from '@material-ui/core';
import MuiInput from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Input } from '@rocketseat/unform';
import StagingBar from '~/theme/components/StagingBar';

import useAuth from '~/hooks/useAuth';

import { Container, PersonalizedForm, Footer, Content } from './styles';

const isStaging = process.env.REACT_APP_ROLLBAR_ENV === 'staging';

export default function AppLogin() {
  const { signInApp } = useAuth();

  return (
    <Container>
      <Content>
        {isStaging && <StagingBar /> }
        <div>
          <PersonalizedForm onSubmit={signInApp}>
            <MuiInput
              inputComponent={Input}
              name="client_app_key"
              startAdornment={
                <InputAdornment position="start">
                  <FiUser />
                </InputAdornment>
              }
              placeholder="Client App Key"
              autoComplete="off"
            />

            <MuiInput
              inputComponent={Input}
              name="client_secret_key"
              startAdornment={
                <InputAdornment position="start">
                  <FiLock />
                </InputAdornment>
              }
              type="password"
              placeholder="Client Secret Key"
            />

            <Button type="submit" color="primary" variant="contained" disableElevation>
              ACESSAR
            </Button>
          </PersonalizedForm>
        </div>
      </Content>
      <Footer>
        <img src={Tradener} alt="Tradener Logo" />
        <img src={Energy} alt="Energy for all" />
      </Footer>
    </Container>
  );
}
