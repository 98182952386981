import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { Flex, Theme, useTheme } from '@tradener/lumen';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Cell,
  Line,
  ComposedChart,
  LineChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  ReferenceLine,
} from 'recharts';

import BBCE_PRODUCTS_PRICES, { ResponseProps, PayloadProps } from '@graphql/query/bbceProductsPrices';

import Brush from './Brush';
import CandleBar from './CandleBar';
import Tooltip from './Tooltip';
import parseKeys from './parseKeys';

export interface BrushPositionProps {
  startIndex: number;
  endIndex: number;
}

function PriceEvolution() {
  const { colors } = useTheme<Theme>();
  const { id: bbceProductId } = useParams() as { [key: string]: string };

  const { data } = useQuery<ResponseProps, PayloadProps>(BBCE_PRODUCTS_PRICES, {
    variables: { bbceProductId },
  });

  const [coords] = parseKeys(data?.bbceProductPrices);
  const years = [...new Set(data?.bbceProductPrices?.map(([date]) => (date as string).slice(0, 4)))];

  return (
    <Flex
      rowGap="3"
      marginTop="1"
      paddingBottom="5"
      flexDirection="column"
      sx={{
        '.recharts-text': { fill: colors.gray[500] },
        '.recharts-cartesian-axis-line, .recharts-brush > rect': { stroke: 'none' },
      }}
    >
      <ResponsiveContainer width="100%" height={289}>
        <ComposedChart data={coords} margin={{ top: 10 }}>
          <XAxis dataKey="date" minTickGap={24} padding={{ left: 20, right: 20 }} stroke={colors.gray[200]} />
          <YAxis domain={['auto', 'auto']} tickCount={4} tickMargin={10} tickLine={false} />
          <CartesianGrid vertical={false} strokeWidth={1} stroke={colors.gray[200]} />
          <RechartsTooltip content={<Tooltip />} />
          {years?.map((year) => {
            const xAxis = coords.find(({ dateTime }) => dateTime.toFormat('yyyy') === year)?.date;

            if (!xAxis) return null;

            return (
              <ReferenceLine
                key={year}
                x={xAxis}
                stroke={`${colors.gray[900]}29`}
                strokeWidth={2}
                ifOverflow="discard"
                isFront={false}
                strokeDasharray="6 6"
                label={{ value: year, position: 'insideTopLeft', fill: colors.gray[500] }}
              />
            );
          })}
          <Bar dataKey="range" barSize={8} shape={(props) => <CandleBar {...props} />}>
            {coords.map(({ date }) => (
              <Cell key={date} />
            ))}
          </Bar>
          <Line name="PLD alto" dot={false} strokeWidth={2} type="monotone" dataKey="pldHigh" stroke={colors.red[500]} />
          <Line name="PLD previsto" dot={false} strokeWidth={2} type="monotone" dataKey="pld" stroke={colors.blue[500]} />
          <Brush
            dataKey="trend"
            stroke={colors.gray[300]}
            fill="transparent"
            height={60}
            travellerWidth={10}
            tickFormatter={() => ''}
            padding={{ left: 20, right: 20 }}
          >
            <LineChart data={coords}>
              <CartesianGrid horizontal={false} verticalPoints={[78, 156, 234, 312, 390, 468, 546, 624, 702]} />
              <Line type="monotone" dataKey="trend" stroke={colors.gray[500]} strokeWidth="2" fill="none" dot={false} />
            </LineChart>
          </Brush>
        </ComposedChart>
      </ResponsiveContainer>
    </Flex>
  );
}

export default PriceEvolution as React.ComponentType;
