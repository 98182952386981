import {
  Text,
  Modal,
  ModalContent,
  Button,
  ModalBody,
  ModalFooter,
  Flex,
  NorthIcon,
  SouthIcon,
  Avatar,
  ModalOverlay,
  Tooltip,
  Box,
  useBoolean,
  useTheme,
} from '@tradener/lumen';

import { useQuoteOptions } from '../useQuoteOptions';
import DialogHeader from './DialogHeader';

function ConfirmDialog() {
  const { colors } = useTheme();
  const [loading, { on, off }] = useBoolean();
  const { action, isOpen, onClose, onSubmit, opportunity, quantity, price, offerCode, accountName, createdBy } =
    useQuoteOptions();

  const handleSubmit = async () => {
    try {
      on();

      await onSubmit(action);
    } finally {
      off();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="unset">
      <ModalOverlay />
      <ModalContent width="43.75rem">
        <DialogHeader />
        <ModalBody width="full" alignItems="center" justifyContent="center">
          <Flex
            width="full"
            align="center"
            justify="space-between"
            borderWidth="1px"
            borderRadius="lg"
            borderColor={`${colors.gray[900]}14`}
          >
            <Flex gap="4" padding="4">
              <Flex
                width="10"
                height="10"
                align="center"
                justify="center"
                borderRadius="xl"
                bgColor={opportunity?.type === 'SALE' ? 'green.50' : 'gray.100'}
              >
                {opportunity?.type === 'SALE' ? (
                  <NorthIcon boxSize="5" color="green.500" />
                ) : (
                  <SouthIcon boxSize="5" color="gray.900" />
                )}
              </Flex>
              <Flex flexDirection="column" align="flex-start">
                <Flex align="flex-end" columnGap="1">
                  <Text textStyle="body2-regular">
                    {quantity?.toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Text>
                  <Text textStyle="body2-regular">MWm</Text>
                </Flex>
                <Text textStyle="body2-regular" color="gray.500">
                  {price}
                </Text>
              </Flex>
            </Flex>
            <Flex textStyle="body2-regular" align="center" padding="4" whiteSpace="nowrap">
              {offerCode}
            </Flex>
            <Flex flex="1" textStyle="body2-regular" align="center" padding="4" maxWidth="58">
              <Tooltip bgColor="gray.900" color="gray.0" borderRadius="base" label={accountName} hasArrow>
                <Text width="full" overflowX="clip" textOverflow="ellipsis" whiteSpace="nowrap">
                  {accountName}
                </Text>
              </Tooltip>
            </Flex>
            <Flex textStyle="body2-regular" align="center" padding="4">
              {opportunity?.channel?.toUpperCase()}
            </Flex>
            <Flex padding="4">
              <Tooltip width="full" bgColor="gray.900" color="gray.0" borderRadius="base" label={createdBy?.name} hasArrow>
                <Box width="full">
                  <Avatar name={createdBy?.name} size="sm" />
                </Box>
              </Tooltip>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter gap="3">
          <Button colorScheme="gray" isDisabled={loading} onClick={onClose} variant="outline">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} isLoading={loading}>
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ConfirmDialog;
