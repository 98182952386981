import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex: 1;

  @media screen and (min-width: 600px) {
    height: 144px;
    flex-direction: row;

    .widget__container:last-child {
      margin-left: 16px;
    }
  }
`;
