import { useCallback, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { useMediaQuery, useTheme } from '@material-ui/core';

import { actions } from '../context/ducks/colorMode';
import useContainer from './useContainer';

function useColorMode() {
  const dispatch = useDispatch();
  const { mode } = useContainer(({ colorMode }) => colorMode);
  const preference = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = useTheme();

  const colorTernary = useCallback((dark: any, light: any) => (mode === 'dark' ? dark : light), [mode]);

  const toggle = useCallback(() => dispatch(actions.toggle()), [dispatch]);

  useEffect(() => {
    if (localStorage.getItem('chakra-ui-color-mode') !== mode) {
      localStorage.setItem('chakra-ui-color-mode', mode);
    }
  }, [mode]);

  return {
    theme,
    mode,
    userPreference: preference ? 'dark' : 'light',
    toggle,
    colorTernary,
  };
}

export default useColorMode;
