import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';

import { Box, BoxProps, ComponentWithAs, Icon, IconProps } from '@tradener/lumen';

import usePortfolioFilter from '~/pages/Portfolio/usePortfolioFilter';

export interface NavItemProps extends BoxProps {
  icon: ComponentWithAs<'svg', IconProps>;
  to: string;
}

const NavItem: React.ComponentType<NavItemProps> = ({ icon, ...props }) => {
  const resolved = useResolvedPath(props.to);
  const match = useMatch({ path: resolved.pathname.split('/')[1], end: false });
  const { isOpen, onClose } = usePortfolioFilter();

  const handlePopout = () => {
    if (isOpen) onClose();
  };

  return (
    <Box
      as={NavLink}
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="8px"
      width="40px"
      height="40px"
      bgColor={match ? 'orange.500' : 'transparent'}
      onClick={handlePopout}
      {...props}
    >
      <Icon as={icon} color={match ? 'white' : 'gray.700'} boxSize="6" />
    </Box>
  );
};

export default NavItem;
