import { useState, useEffect, useContext, useMemo } from 'react';

import {
  addMonths,
  endOfMonth,
  startOfMonth,
  isAfter,
  differenceInMonths,
  endOfYear,
  addYears,
  startOfYear,
  isBefore,
} from 'date-fns';
import produce from 'immer';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { DateTime } from 'luxon';

import { updateFieldsVolume, removeQuoteItem } from '../../reducer';

import ProposalsContext from '@DEPRECATED/context/Proposals/context';
import { convertMWhToMWm } from '~/DEPRECATED/utils';

function generateInitialQuote({ startDate, endDate }) {
  return [
    {
      id: uuid(),
      startDate,
      endDate,
      amount: '0',
      requestedAmount: '0',
      spreadAmount: '0',
      volume: {
        MWh: '0',
        MWm: '0',
      },
    },
  ];
}

function useQuoteItemState({ initialState, startDate, endDate }) {
  const { data, replaceData } = useContext(ProposalsContext);
  const [quoteItems, setQuoteItems] = useState(initialState);
  const [isExpanded, setIsExpanded] = useState(false);
  const priceBook = useMemo(() => data?.priceBook, [data?.priceBook]);
  const editedItemPrice = useMemo(() => data?.options.edited.quoteItemPrice, [data?.options.edited.quoteItemPrice]);
  const editedItemVolume = useMemo(() => data?.options.edited.quoteItemVolume, [data?.options.edited.quoteItemVolume]);
  const isFilled = useMemo(() => data?.options.filled, [data?.options.filled]);

  useEffect(() => {
    if (!initialState?.length || !(editedItemPrice || editedItemVolume || isFilled)) {
      const initialQuoteItems = generateInitialQuote({ startDate, endDate });

      if (priceBook && !!initialQuoteItems?.length) {
        initialQuoteItems[0].amount = Math.max(...Object.values(priceBook)).toString();
      }

      setQuoteItems(initialQuoteItems);
    } else {
      setQuoteItems(initialState);
    }
  }, [endDate, initialState, startDate]);

  function handleChangeDatePicker({ date, index }) {
    setQuoteItems(
      produce(quoteItems, (draft) => {
        if (index === draft.length - 1) {
          const itemVolume = { ...draft[index].volume };

          const initialDate = startOfMonth(addMonths(date, 1));

          if (isAfter(initialDate, endDate)) return;

          draft[index].endDate = date;
          draft.push({
            ...draft[index],
            id: uuid(),
            volume: itemVolume,
            startDate: initialDate,
            endDate: endOfMonth(endDate),
          });
        } else {
          const aditional = isAfter(date, draft[index + 1].startDate) ? 1 : 0;

          draft[index].endDate = endOfMonth(date);
          draft[index + 1].startDate = startOfMonth(
            addMonths(draft[index + 1].startDate, differenceInMonths(date, draft[index + 1].startDate) + aditional),
          );
        }

        updateFieldsVolume({ data: draft[index + 1], type: 'MWm' });

        updateFieldsVolume({ data: draft[index], type: 'MWm' });
      }),
    );
  }

  function handleVolume({ target }, type, index) {
    const { value } = target;

    if (data?.opportunity.category === 'TRD_PLUS' && !data?.options.edited.flexPositive) {
      const valueMWm = Number(type === 'MWm' ? value : convertMWhToMWm(value));

      if (valueMWm <= 0.5) {
        replaceData('flexPositive', 100);
      } else {
        replaceData('flexPositive', 50);
      }
    }

    const newValues = produce(quoteItems, (draft) => {
      draft[index].volume = updateFieldsVolume({
        data: draft[index],
        type,
        value,
      });
    });

    replaceData('options.edited.quoteItemVolume', true);
    setQuoteItems(newValues);
  }

  function handleRemove() {
    const newValues = produce(quoteItems, (draft) => {
      removeQuoteItem({ data: draft });
    });

    setQuoteItems(newValues);
    setIsExpanded(false);
  }

  function handleEnergyOffer({ target }, type, index) {
    const { value } = target;

    const newValues = produce(quoteItems, (draft) => {
      draft[index][type] = value;
    });

    replaceData('options.edited.quoteItemPrice', true);
    setQuoteItems(newValues);
  }

  function handleAnnualSplitRange() {
    if (endDate.getYear() == startDate.getYear()) {
      setIsExpanded(true);
      return;
    }

    let initialDate = startDate;
    let count = 0;

    const newValues = produce(quoteItems, (draft) => {
      draft[0].endDate = endOfYear(initialDate);
      updateFieldsVolume({ data: draft[0], type: 'MWm' });

      if (!editedItemPrice && !isFilled) {
        draft[0].amount = priceBook[DateTime.fromJSDate(draft[0].endDate).year];
      }

      while (endDate.getYear() > initialDate.getYear()) {
        initialDate = startOfYear(addYears(initialDate, 1));

        const tempEndDate = endOfYear(initialDate);
        const newEndDate = DateTime.fromJSDate(isBefore(endDate, tempEndDate) ? endDate : tempEndDate);
        const newAmount = editedItemPrice || isFilled ? quoteItems?.[0].amount : priceBook[newEndDate.year];

        draft.push({
          ...draft[count],
          id: uuid(),
          volume: { ...quoteItems?.[0].volume },
          amount: newAmount,
          startDate: initialDate,
          endDate: newEndDate.toJSDate(),
        });

        count += 1;
        updateFieldsVolume({ data: draft[count], type: 'MWm' });
      }
    });

    setQuoteItems(newValues);
    setIsExpanded(true);
    replaceData('options.edited.quoteItemPrice', true);
  }

  function overwrite(array) {
    setQuoteItems(array);
  }

  function reset() {
    setQuoteItems(generateInitialQuote({ startDate, endDate }));
    setIsExpanded(false);
  }

  return {
    quoteItems,
    isExpanded,
    handleChangeDatePicker,
    handleVolume,
    handleRemove,
    handleEnergyOffer,
    handleAnnualSplitRange,
    overwrite,
    reset,
  };
}

export const useQuoteItemStateTypes = {
  initialState: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.instanceOf(Date).isRequired,
      endDate: PropTypes.instanceOf(Date).isRequired,
      quantity: PropTypes.string,
      amount: PropTypes.string.isRequired,
      requestedAmount: PropTypes.string,
      spreadAmount: PropTypes.string,
    }).isRequired,
  ),
};

export const useQuoteItemStateTypesDefaultsProps = {
  initialState: [],
};

useQuoteItemState.defaultProps = useQuoteItemStateTypesDefaultsProps;
useQuoteItemState.propTypes = useQuoteItemStateTypes;

export default useQuoteItemState;
