import FilterButtonRaw from '@DEPRECATED/components/Filter/FilterButton';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 160px;

  & .MuiSelect-outlined {
    color: ${({ color }) => color};
  }

  @media screen and (min-width: 600px) {
    width: auto;
    grid-column-gap: 10px;
  }
`;

export const FilterButton = styled(FilterButtonRaw)`
  && {
    display: unset;

    @media screen and (min-width: 600px) {
      display: none;
    }
  }
`;
