import { Badge } from '@material-ui/core';
import PropTypes from 'prop-types';

import Filter from '~/theme/icons/Filter';

import { PersonalizedIconButton as IconButton } from './styles';

function FilterButton({ isFiltered, ...rest }) {
  return (
    <IconButton {...rest}>
      <Badge variant="dot" color="primary" invisible={!isFiltered}>
        <Filter width="20px" height="20px" />
      </Badge>
    </IconButton>
  );
}

FilterButton.defaultProps = {
  isFiltered: false,
};

FilterButton.propTypes = {
  isFiltered: PropTypes.bool,
};

export default FilterButton;
