import { useMemo } from 'react';

import BoxStatus from '@DEPRECATED/components/BoxStatus';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';

import { balanceByEnergyTypeGroup, balanceEnergyTypeGroup, balanceEnergyBallast } from '../PortfolioHelper';
import TooltipDetail from '../TooltipDetail';
import { Container } from './styles';

function BoxStatusInfo({ hidden, data }) {
  const balanceCon = useMemo(() => data && balanceByEnergyTypeGroup(data, 'con'), [data]);

  const balanceInc = useMemo(() => data && balanceByEnergyTypeGroup(data, 'inc'), [data]);

  const balance = useMemo(() => data && balanceEnergyTypeGroup(data), [data]);

  const balanceBallast = useMemo(() => data?.energyBallast && balanceEnergyBallast(data), [data]);

  return (
    <Container hidden={hidden}>
      <TooltipDetail data={balance}>
        <BoxStatus color="#CCC" title="Saldo total" value={balance?.total} />
      </TooltipDetail>
      <TooltipDetail data={balanceCon}>
        <BoxStatus color="#F9B850" title="Saldo Convencional" value={balanceCon?.total} />
      </TooltipDetail>
      <TooltipDetail data={balanceInc}>
        <BoxStatus color="#6A51F6" title="Saldo Incentivada" value={balanceInc?.total} />
      </TooltipDetail>
      <TooltipDetail data={balanceBallast}>
        <BoxStatus color="#48E66D" title="Lastro" value={balanceBallast?.total} />
      </TooltipDetail>
    </Container>
  );
}

BoxStatusInfo.fragments = {
  energyBallast: gql`
    fragment EnergyBallast on Month {
      energyBallast {
        balance
        balanceCon
        balanceInc
      }
    }
  `,
};

BoxStatusInfo.defaultProps = {
  hidden: false,
  data: {
    purchase: {
      quantityMwm: 0,
    },
    sale: {
      quantityMwm: 0,
    },
  },
};

BoxStatusInfo.propTypes = {
  hidden: PropTypes.bool,
  data: PropTypes.shape({
    energyBallast: PropTypes.shape({}),
  }),
};

export default BoxStatusInfo;
