import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  ${({ theme }) => css`
    height: calc(100% - 58px);

    && {
      .Mui-selected,
      > hr {
        ${(theme?.palette?.type === 'light') && `background-color: #FFF` || `background-color: #282828`};
      }

      > hr {
        height: 4px;
      }
    }
  `}
`;
