import { Children, isValidElement, cloneElement } from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

function TabPanel({ children, value, index, handleNext, handlePrevious, ...other }) {
  if (value !== index) return null;

  return (
    <Container {...other}>
      {Children.map(children, (child) => {
        if (!isValidElement(child)) {
          return null;
        }

        return cloneElement(child, {
          index: Number(index),
          handleNext,
          handlePrevious,
        });
      })}
    </Container>
  );
}

TabPanel.defaultProps = {
  handleNext: () => ({}),
  handlePrevious: () => ({}),
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  handleNext: PropTypes.func,
  handlePrevious: PropTypes.func,
};

export default TabPanel;
