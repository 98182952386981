import { MenuItem, MenuItemProps, Button } from '@tradener/lumen';

import { Stages } from '@graphql/query/productOperations';

import { useQuoteOptions } from '../useQuoteOptions';

export interface OptionItemProps extends MenuItemProps {
  stage?: Uppercase<Stages> | Uppercase<Stages>[];
}

const OptionItem: React.ComponentType<OptionItemProps> = ({ stage, ...props }) => {
  const { getStageAccess } = useQuoteOptions();

  if (stage && !getStageAccess(Array.isArray(stage) ? stage : [stage as Uppercase<Stages>])) return null;

  return (
    <MenuItem
      as={Button}
      variant="ghost"
      colorScheme="gray"
      height="36px"
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      bgColor="gray.0"
      color="gray.900"
      {...props}
    />
  );
};

export default OptionItem;
