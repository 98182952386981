import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  height: 100%;
  position: relative;
  width: 100%;

  ${({ hidden }) => hidden
    && css`
      display: none;
    `};
`;
