import { RetryLink } from '@apollo/client/link/retry';

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    jitter: true,
    max: 10000,
  },
  attempts: {
    max: 10,
    retryIf: ({ message }) => /401/.test(message),
  },
});

export default retryLink;
