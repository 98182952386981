import { SnackbarKey, SnackbarProvider } from 'notistack';
import { Action, Reducer } from 'redux';
import { delay, put, throttle, takeEvery } from 'redux-saga/effects';

import state from '~/utils/state';

export enum ActionTypes {
  broadcast = 'notification/broadcast',
  message = 'notification/message',
  dismiss = 'notification/dismiss',
  reset = 'notification/reset',
  ref = 'notistack/ref',
}

export interface MessageProps {
  title: string;
  body: string;
  download_url: string;
  type: string;
  status: 'pending' | 'success' | 'error';
  job_id: string;
}

export interface StateProps {
  broadcast?: MessageProps;
  notistack?: SnackbarProvider;
  messages: Array<MessageProps>;
}

export interface ActionProps extends Action<ActionTypes> {
  value?: any;
}

const initialState: StateProps = {
  messages: [],
};

const reducer: Reducer<StateProps, ActionProps> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.broadcast:
      return { ...state, broadcast: action.value };
    case ActionTypes.message:
      return { ...state, messages: [...state.messages, action.value] };
    case ActionTypes.dismiss:
      return { ...state, messages: state.messages.filter(({ job_id }) => job_id !== action.value) };
    case ActionTypes.ref:
      return { ...state, notistack: action.value };
    case ActionTypes.reset:
      return initialState;
    default:
      return state;
  }
};

function* message({ value }: ActionProps) {
  const { messages } = yield state(({ notification }) => notification);

  if (messages.find(({ job_id }) => job_id === value.job_id)) {
    yield delay(500);
    yield put({ type: ActionTypes.dismiss, value: value.job_id });
  }

  yield delay(750);
  yield put({ type: ActionTypes.broadcast, value });
}

function* dismiss({ value }: ActionProps) {
  const { notistack, broadcast } = yield state(({ notification }) => notification);

  if (value === broadcast?.job_id) {
    yield put({ type: ActionTypes.broadcast, value: undefined });
  }

  notistack.closeSnackbar(value);
}

export function* saga() {
  yield throttle(1000, ActionTypes.message, message);
  yield takeEvery(ActionTypes.dismiss, dismiss);
}

export const actions = {
  enqueue: (value: MessageProps) => ({ type: ActionTypes.message, value }),
  dismiss: (value: SnackbarKey) => ({ type: ActionTypes.dismiss, value }),
  ref: (value: SnackbarProvider) => ({ type: ActionTypes.ref, value }),
  reset: () => ({ type: ActionTypes.reset }),
};

export default reducer;
