import { gql } from '@apollo/client';

import { QuoteItemProps } from '@graphql/query/quote';

import { EnergyTypes } from '../query/contracts';
import { OperationTypes, Submarkets } from '../query/productOperations';

export interface InputProps {
  id?: string;
  succeededId?: string;
  buyerId: string;
  sellerId: string;
  bbceProductId?: string;
  clientMutationId?: string;
  energyType?: EnergyTypes;
  externalId?: string;
  notes?: string;
  operation?: OperationTypes;
  quoteItems: Partial<QuoteItemProps>[];
  saveAndPropose: boolean;
  saveAndReject: boolean;
  submarket?: Submarkets;
  swap: boolean;
  bbceProductTransferId?: string;
}

export interface PayloadProps {
  input: InputProps;
}

export interface ResponseProps {
  clientMutationId?: string;
}

const UPSERT_TRADING_QUOTE = gql`
  mutation upsertTradingQuote($input: UpsertTradingQuoteInput!) {
    upsertTradingQuote(input: $input) {
      clientMutationId
    }
  }
`;

export default UPSERT_TRADING_QUOTE;
