import { memo } from 'react';

import { Flex, Text, Theme, useTheme } from '@tradener/lumen';
import { DateTime } from 'luxon';
import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Area, ReferenceLine, ComposedChart } from 'recharts';

import CustomTooltip from '../components/CustomTooltip';
import TickLabelXAxis from '../components/TickLabelXAxis';
import { gradientOffset, parsedExposures, serieYears } from '../configs';
import { useProductAnalytics } from '../useProductAnalytics';
import Scatter from './Scatters';

const payloadConfig = (dataKey: string) => {
  if (dataKey === 'exposure') return { minimumFractionDigits: 0, maximumFractionDigits: 1 };
};

function EnergyBalance() {
  const { colors } = useTheme<Theme>();
  const { series } = useProductAnalytics();
  const exposures = parsedExposures(series);

  if (!exposures?.length) return null;

  const off = gradientOffset(exposures);
  const years = serieYears(exposures);

  return (
    <Flex data-testid="energy-balance" flexDirection="column" alignItems="center" width="full" flex="1">
      <Flex
        flexDirection="column"
        bgColor="gray.0"
        height="25rem"
        width="full"
        borderRadius="xl"
        padding="5"
        gap="1"
        color={colors.gray[900]}
      >
        <Flex flexDirection="column" justifyContent="center" gap="0.5">
          <Text textStyle="subheading1-semibold">Exposição</Text>
        </Flex>
        <Flex
          direction="column"
          width="100%"
          height="100%"
          paddingBlock="1"
          position="relative"
          overflow="visible"
          sx={{
            '.recharts-text': { fill: colors.gray[500] },
            '.recharts-cartesian-axis-line, .recharts-brush > rect': { stroke: 'none' },
            '.recharts-cartesian-axis-tick-line, line': { stroke: `${colors.gray[400]}3D` },
          }}
        >
          <Scatter scartterType="TRANSFER" showAxis />
          <Scatter scartterType="PURCHASE" />
          <Scatter scartterType="SALE" />
          <ResponsiveContainer style={{ position: 'absolute', top: 0 }}>
            <ComposedChart data={exposures} syncId="balanceId" margin={{ top: 30, left: 15, right: 15 }} height={400}>
              <CartesianGrid vertical={false} strokeWidth={1} />
              <XAxis
                xAxisId="bottom"
                dataKey="timestamp"
                type="category"
                domain={['dataMin', 'dataMax']}
                tickLine={false}
                minTickGap={40}
                stroke={colors.gray[200]}
                padding={{ left: 40, right: 40 }}
                tick={<TickLabelXAxis mode="DAY" />}
              />
              <YAxis
                yAxisId="right-hidden"
                orientation="right"
                type="number"
                padding={{ top: 35, bottom: 35 }}
                tickLine={false}
                tickMargin={25}
              />
              <YAxis
                yAxisId="left"
                orientation="left"
                dataKey="exposure"
                padding={{ top: 35, bottom: 35 }}
                tickLine={false}
                tickMargin={25}
                tick={{ fill: colors.gray[600], fontSize: '0.875rem' }}
                label={{ value: 'MWm', position: 'top' }}
                interval={0}
                scale="linear"
                domain={([dataMin, dataMax]) => {
                  const absMax = Math.max(Math.abs(dataMin), Math.abs(dataMax)) * 2;

                  return [-absMax, absMax];
                }}
              />
              {years?.map((year) => {
                const xAxis = exposures?.find(
                  ({ timestamp }: any) => DateTime.fromMillis(timestamp).toFormat('yyyy') === year,
                )?.timestamp;

                if (!xAxis) return null;

                return (
                  <ReferenceLine
                    key={Number(year)}
                    x={xAxis}
                    xAxisId="bottom"
                    yAxisId="left"
                    stroke={`${colors.gray[900]}29`}
                    strokeWidth={2}
                    ifOverflow="discard"
                    isFront={false}
                    strokeDasharray="6 6"
                    label={{ value: Number(year), position: 'insideTopLeft', fill: colors.gray[500] }}
                  />
                );
              })}
              <defs>
                <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                  <stop offset={off} stopColor={`${colors.green[500]}CC`} stopOpacity={1} />
                  <stop offset={off} stopColor={`${colors.red[500]}CC`} stopOpacity={1} />
                </linearGradient>
              </defs>
              <Area
                xAxisId="bottom"
                name="Exposição"
                yAxisId="left"
                dataKey="exposure"
                type="step"
                stroke={`${colors.gray[900]}29`}
                connectNulls
                isAnimationActive={false}
                fill="url(#splitColor)"
                activeDot={(props) => {
                  const { dataKey: _, ...payload } = props;

                  if (Number(props?.payload?.exposure) >= 0)
                    return <circle {...payload} r={3} strokeWidth={0} fill={`${colors.green[500]}CC`} />;

                  return <circle {...payload} r={3} strokeWidth={0} fill={`${colors.red[500]}CC`} />;
                }}
              />
              <Tooltip
                filterNull
                useTranslate3d
                allowEscapeViewBox={{ x: true, y: true }}
                wrapperStyle={{ left: -235 }}
                isAnimationActive={false}
                cursor={{ stroke: `${colors.gray[900]}14`, radius: 5, opacity: 0.75 }}
                content={<CustomTooltip mode="DAY" config={payloadConfig} />}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default memo(EnergyBalance);
