import { forwardRef } from 'react';

import { MdClose } from 'react-icons/md';

import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import { Title, Content } from './styles';
import useColorMode from '~/hooks/useColorMode';

const Card = forwardRef(({ children, title, onClose, ContentProps, ...others }, ref) => {
  const { colorTernary } = useColorMode();

  return (
    <Paper {...others} ref={ref}>
      <Title>
        <Typography variant="h4">{title}</Typography>
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose}>
            <MdClose color={colorTernary('#fff', '#111')} />
          </IconButton>
        ) : null}
      </Title>
      <Content {...ContentProps}>{children}</Content>
    </Paper>
  )
});

Card.defaultProps = {
  elevation: 1,
  onClose: null,
  ContentProps: {},
};

Card.propTypes = {
  elevation: PropTypes.number,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  ContentProps: PropTypes.shape({}),
};

export default Card;
