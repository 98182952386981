import { useEffect, useState, useMemo, useCallback } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';

import ContactForm from './ContactForm';
import ContactItem from './ContactItem';
import { Container, Row, RowEnd } from './styles';
import Information from '~/theme/icons/Information';

const options = {
  COMMERCIAL: 'commercial',
};

const GET_CONTACT_LIST = gql`
  query contacts($id: ID!) {
    accountContacts(filter: { accountId: $id }) {
      contact {
        name
        email
      }
      roles
    }
  }
`;

const INSERT_COMMERCIAL_CONTACT = gql`
  mutation inserCommercialContact($input: UpsertCommercialAccountContactInput!) {
    upsertCommercialAccountContact(input: $input) {
      accountContacts {
        contact {
          name
          email
        }
        roles
      }
    }
  }
`;

export const GET_ACCOUNT_DETAILS = gql`
  query accountDetails($id: String!) {
    accountDetails(accountId: $id) {
      attentionPoints
      longTermContracts
    }
  }
`;

function ContactList({ id }) {
  const [contacts, setContacts] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [isPersisted, setPersistence] = useState(false);

  const { data: query } = useQuery(GET_CONTACT_LIST, {
    fetchPolicy: 'network-only',
    variables: { id },
  });

  const [addContact] = useMutation(INSERT_COMMERCIAL_CONTACT, {
    ignoreResults: true,
  });

  const commercialContacts = useMemo(
    () => contacts?.filter((contact) => contact.role === options.COMMERCIAL).length || 0,
    [contacts],
  );

  const { data: accountQuery } = useQuery(GET_ACCOUNT_DETAILS, { fetchPolicy: 'network-only', variables: { id } });

  const handlePersistence = useCallback(() => {
    if (contacts.length) {
      addContact({
        variables: {
          input: {
            accountId: id,
            contacts,
          },
        },
      });
    }
  }, [addContact, contacts, id]);

  function handleContact({ name, email, commercial }) {
    const data = { name, email, role: commercial ? options.COMMERCIAL : '' };

    setContacts((prevState) => [...prevState, data]);
    setPersistence(true);
  }

  function sanitizeQuery(values) {
    const data = values?.accountContacts.map(({ contact: { name, email }, roles }) => ({
      name,
      email,
      role: roles.includes(options.COMMERCIAL) ? options.COMMERCIAL : '',
    }));

    return data;
  }

  function sanitizeAccountQuery(values) {
    const accountData = {
      attentionPoints: values?.accountDetails?.attentionPoints ?? 0,
      longTermContracts: values?.accountDetails?.longTermContracts ? 'Sim' : 'Não',
    };

    return accountData;
  }

  function updateContact(param) {
    setContacts((prevState) =>
      prevState.map((contact) => {
        if (contact.email === param) {
          return {
            ...contact,
            role: contact.role === options.COMMERCIAL ? '' : options.COMMERCIAL,
          };
        }

        return contact;
      }),
    );

    setPersistence(true);
  }

  useEffect(() => {
    setContacts((prevState) => sanitizeQuery(query) ?? prevState);
  }, [query]);

  useEffect(() => {
    setAccountData((prevState) => sanitizeAccountQuery(accountQuery) ?? prevState);
  }, [accountQuery]);

  useEffect(() => {
    if (isPersisted) {
      handlePersistence();
      setPersistence(false);
    }
  }, [handlePersistence, isPersisted]);

  if (!id) return null;

  return (
    <Container>
      <Row>
        <Typography>Pontos de atenção</Typography>
        <RowEnd>
          {accountData.attentionPoints > 0 && <Information fontSize="inherit" color="primary" />}
          <Typography color={accountData.attentionPoints === 0 ? 'secondary' : 'primary'}>
            {accountData.attentionPoints}
            {accountData.attentionPoints === 1 ? ' ponto de atenção' : ' pontos de atenção'}
          </Typography>
        </RowEnd>
      </Row>
      <Row>
        <Typography>Contatos</Typography>
        <Typography color="secondary">
          {contacts.length > 0 ? `Contato comercial selecionado (${commercialContacts})` : 'Nenhum contato comercial encontrado'}
        </Typography>
      </Row>
      <Divider />
      <List dense>
        {contacts?.map(({ name, email, role }) => (
          <ContactItem key={email} name={name} email={email} role={role} onClick={updateContact} />
        ))}
      </List>
      <ContactForm addContact={handleContact} />
    </Container>
  );
}

ContactList.defaultProps = {
  id: undefined,
};

ContactList.propTypes = {
  id: PropTypes.string,
};

export default ContactList;
