import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  parent: {
    '& > .removal': { display: 'none' },
    '&:hover > .removal': { display: 'flex' },
  },
});

export default useStyles;
