import { LocalOffer } from '@material-ui/icons';
import { Card, Flex, Text, Tooltip, useTheme } from '@tradener/lumen';
import { DateTime } from 'luxon';

import { useDetails } from '../../..';

export enum PRICE_TYPE_ENUM {
  FIXO = 'FIXO',
  PERCENT_PLD = '% PLD',
  SPREAD = 'SPREAD',
}

export enum SUBMARKET_ENUM {
  N = 'Norte',
  NE = 'Nordeste',
  S = 'Sul',
  SE = 'Sudeste/Centro-Oeste',
}

function ResumeCard() {
  const { quote } = useDetails();
  const { colors } = useTheme();
  const accountLabel = quote?.opportunity?.type === 'PURCHASE' ? 'Vendedor' : 'Comprador';
  const tenantLabel = quote?.opportunity.type === 'SALE' ? 'Vendedor' : 'Comprador';

  const dueDateType = (() => {
    const formated = {
      BUSINESS_DAY: `${quote?.dueDateValue}º Dia útil`,
      FIXED: `Todo dia ${quote?.dueDateValue}`,
    };

    return formated[quote?.dueDateType ?? ''] || null;
  })();

  const expireDate = (() => {
    if (!quote?.expireDate) return null;
    const parseDate = new Date(quote?.expireDate);

    // eslint-disable-next-line prettier/prettier
    return DateTime.fromJSDate(parseDate).toFormat('dd/MM/yy \'-\' HH\':\'mm');
  })();

  const productName = (() => {
    if (!quote) return null;

    return quote?.opportunity.bbceProduct?.name ?? quote?.product ?? '-';
  })();

  return (
    <Card width="80" height="fit-content" bgColor="gray.50" padding="6" gap="8">
      <Flex width="full" direction="column" align="flex-start" gap="4">
        <Text textStyle="subheading2-semibold">Resumo</Text>
        <Flex width="full" align="center" justify="space-between">
          <Text textStyle="body2-regular" color="gray.500">
            {tenantLabel}
          </Text>
          <Flex textStyle="body2-regular" maxWidth="50%">
            <Tooltip bgColor="gray.900" color="gray.0" borderRadius="base" label={quote?.opportunity.tenant.name} hasArrow>
              <Text width="full" overflowX="clip" textOverflow="ellipsis" whiteSpace="nowrap">
                {quote?.opportunity.tenant.name}
              </Text>
            </Tooltip>
          </Flex>
        </Flex>
        <Flex width="full" align="center" justify="space-between">
          <Text textStyle="body2-regular" color="gray.500">
            {accountLabel}
          </Text>
          <Flex textStyle="body2-regular" maxWidth="50%">
            <Tooltip bgColor="gray.900" color="gray.0" borderRadius="base" label={quote?.opportunity.account.name} hasArrow>
              <Text width="full" overflowX="clip" textOverflow="ellipsis" whiteSpace="nowrap">
                {quote?.opportunity.account.name}
              </Text>
            </Tooltip>
          </Flex>
        </Flex>
        <Flex width="full" align="center" justify="space-between">
          <Text textStyle="body2-regular" color="gray.500">
            Produto
          </Text>
          <Flex
            align="center"
            textStyle="body2-regular"
            maxWidth="60%"
            px="1.5"
            py="1px"
            borderWidth="1px"
            borderRadius="md"
            gap="1.5"
            borderColor={`${colors.gray[900]}3D`}
          >
            <LocalOffer style={{ color: colors.gray[400], height: '0.875rem', width: '0.875rem', transform: 'rotate(90deg)' }} />
            <Tooltip bgColor="gray.900" color="gray.0" borderRadius="base" label={productName} hasArrow>
              <Text width="full" overflowX="clip" textOverflow="ellipsis" whiteSpace="nowrap">
                {productName}
              </Text>
            </Tooltip>
          </Flex>
        </Flex>
        <Flex width="full" align="center" justify="space-between">
          <Text textStyle="body2-regular" color="gray.500">
            Ponto de entrega
          </Text>
          <Flex textStyle="body2-regular" maxWidth="55%">
            <Tooltip
              bgColor="gray.900"
              color="gray.0"
              borderRadius="base"
              label={SUBMARKET_ENUM[quote?.submarket as string]}
              hasArrow
            >
              <Text width="full" overflowX="clip" textOverflow="ellipsis" whiteSpace="nowrap">
                {SUBMARKET_ENUM[quote?.submarket as string]}
              </Text>
            </Tooltip>
          </Flex>
        </Flex>
        <Flex width="full" align="center" justify="space-between">
          <Text textStyle="body2-regular" color="gray.500">
            Pagamento
          </Text>
          <Text maxWidth="60%" textStyle="body2-regular" overflow="clip" textOverflow="ellipsis" whiteSpace="nowrap">
            {dueDateType}
          </Text>
        </Flex>
        <Flex width="full" align="center" justify="space-between">
          <Text textStyle="body2-regular" color="gray.500">
            Val. da proposta
          </Text>
          <Text maxWidth="60%" textStyle="body2-regular" overflow="clip" textOverflow="ellipsis" whiteSpace="nowrap">
            {expireDate}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}

export default ResumeCard;
