import { Form } from '@rocketseat/unform';
import styled from 'styled-components';
import BackgroundImage from '@DEPRECATED/assets/images/storm_background.jpg';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100vw;
  height: 100vh;
  background-color: #FFF;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 80vh;
  width: 100vw;
  background: transparent url(${BackgroundImage}) 50% 50% no-repeat padding-box;
  background-size: cover;

  img {
    margin: 186px 0 96px;
  }
`;

export const PersonalizedForm = styled(Form)`
  display: flex;
  justify-self: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25vh;
  gap: 10px;
  padding: 30px;
  background-color: rgb(0, 0, 0, 0.8);
  color: #FFF !important;
  border-radius: 8px;

  > div {
    color: #FFF !important;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #FFF;

  height: 20vh;
  width: 100vw;
  max-width: 960px;
  margin: 0 auto;
`;
