import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import Filter from '@DEPRECATED/components/Filter';
import Loader from '@DEPRECATED/components/Loader';
import { getStatus } from '@DEPRECATED/utils';
import { useQuery } from '@apollo/client';
import { Button, Box } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import gql from 'graphql-tag';

import useColorMode from '~/hooks/useColorMode';
import Container from '~/theme/layout/Container';
import Buy from '~/theme/icons/Buy';
import Sell from '~/theme/icons/Sell';

import BookHeader from '../BookHeader';
import BookItem from './BookItem';
import { Container as TableContainer, TableHead, TableCellHead as TableCellHeadStyled } from './styles';
import useOperation from './useOperation';
import Can from '~/DEPRECATED/components/Can';

const ShallowFragment = `
    id
    quantity
    price
    product
    swap
    stage
    requestedAmount
    offerCode
    expireDate
    stageAt
    accountName
    proposalUrl
    opportunity {
      category
      type
      channel
      currentLocation
    }

`;

const GET_QUOTES = gql`
  query Quotes($offset: Int, $limit: Int, $filter: QuotesFilter) {
    quotes(offset: $offset, limit: $limit, filter: $filter) {
      ${ShallowFragment}
      __typename
    }
  }
`;

const NEW_QUOTE = gql`
  subscription {
    upsertQuote {
      ${ShallowFragment}
    }
  }
`;

const QUOTE_LIMIT = 30;

function TableCellHead(props) {
  const { colorTernary } = useColorMode();

  return (
    <TableCellHeadStyled style={{ backgroundColor: colorTernary('#232323', '#FFF') }} {...props} />
  )
}

const BookList = () => {
  const push = useNavigate();
  const operation = useOperation();

  const { loading, error, data, fetchMore, subscribeToMore, refetch, client } = useQuery(GET_QUOTES, {
    variables: {
      offset: 0,
      limit: QUOTE_LIMIT,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const offset = data?.quotes?.length ?? 0;

  const loadMore = () => {
    fetchMore({
      variables: {
        offset,
        limit: QUOTE_LIMIT,
      },
    });
  };

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: NEW_QUOTE,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData || !subscriptionData.data) return prev;

        const { upsertQuote } = subscriptionData.data;

        let quotes = [];

        if (prev.quotes) {
          quotes = prev.quotes.filter((quote) => quote.id !== upsertQuote.id);
        }

        if (getStatus(upsertQuote.stage) !== 'Inválido') {
          quotes = [upsertQuote, ...quotes];
        }

        return { ...prev, quotes };
      },
    });

    window.addEventListener('online', refetch);
    window.addEventListener('offline', unsubscribe);

    return () => {
      unsubscribe();
      window.removeEventListener('online', refetch);
      window.removeEventListener('offline', unsubscribe);
      client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'quotes', broadcast: false });
    };
  }, [subscribeToMore]);

  if (!data && loading) return <Loader />;

  if (error) return <span>Error! {error.message}</span>;

  return (
    <>
      <BookHeader>
        <Box display="flex" gridColumnGap="12px" flexDirection="row" alignItems="center" style={{ height: '32px' }}>
          <Can requires="quote:upsert">
            <Button
              startIcon={<Buy width="20px" height="20px" />}
              variant="contained"
              color="primary"
              onClick={() => push('opportunities/purchase/')}
              disableElevation
              style={{ color: '#FFF', height: '100%' }}
            >
              Comprar
            </Button>
            <Button
              startIcon={<Sell width="20px" height="20px" />}
              variant="contained"
              color="primary"
              onClick={() => push('opportunities/sale/')}
              disableElevation
              style={{ color: '#FFF', height: '100%' }}
            >
              Vender
            </Button>
          </Can>
          <Filter onFilter={refetch} />
        </Box>
      </BookHeader>
      <Container overflow="auto">
        <TableContainer>
          {loading && <Loader />}
          <Table stickyHeader aria-label="offer-book" style={{ borderSpacing: '0', tableLayout: 'auto' }}>
            <TableHead>
              <TableRow>
                <TableCellHead />
                <TableCellHead>Produto</TableCellHead>
                <TableCellHead align="right" transform="none">
                  MWm
                </TableCellHead>
                <TableCellHead align="left">Preço</TableCellHead>
                <TableCellHead>Solicitado</TableCellHead>
                <TableCellHead>Conta</TableCellHead>
                <TableCellHead align="left">Canal</TableCellHead>
                <TableCellHead align="left">Categoria</TableCellHead>
                <TableCellHead align="center">Sinalizador</TableCellHead>
                <TableCellHead align="center">Estágio</TableCellHead>
                <TableCellHead>Fechamento/Validade</TableCellHead>
                <TableCellHead />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.quotes?.map((quote, index) => (
                <BookItem key={quote.id} quote={quote} isEven={index % 2 === 0} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {offset % QUOTE_LIMIT === 0 && (
          <div style={{ display: 'flex', justifyContent: 'center', margin: '10px' }}>
            <Button type="button" onClick={loadMore} color="secondary" disabled={loading}>
              {loading ? 'Carregando...' : 'Ver mais'}
            </Button>
          </div>
        )}
        {operation}
      </Container>
    </>
  );
};

export default BookList;
