import { useRef } from 'react';

import { MdExpandMore } from 'react-icons/md';

import { FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import MUISelect from '@material-ui/core/Select';
import PropTypes from 'prop-types';

import OutlinedInput from '../Outlined';
import useStyles from './styles';

function DEPRECATED_SELECT({ name, label, options, value, displayEmpty, native, inputProps, error, ...rest }) {
  const ref = useRef(null);
  const classes = useStyles();

  const props = {
    ...rest,
    native,
    value: undefined,
    name,
    inputProps: {
      ...inputProps,
      ref,
      name,
      id: name,
      'aria-label': name,
    },
  };

  return (
    <FormControl error={!!error}>
      {!!label && <InputLabel htmlFor={name}>{label}</InputLabel>}

      <MUISelect
        {...props}
        input={<OutlinedInput />}
        displayEmpty={displayEmpty}
        IconComponent={MdExpandMore}
        value={value}
        classes={{
          root: classes.root,
          icon: classes.icon,
        }}
      >
        {displayEmpty
          && (native ? (
            <option value="" label="Selecione" disabled />
          ) : (
            <MenuItem
              value=""
              classes={{
                root: classes.menuItem,
                selected: classes.selected,
              }}
            >
              <em>Selecione</em>
            </MenuItem>
          ))}

        {options.map(({ value: optionValue, label: optionLabel, disabled = false }) => {
          if (native) {
            return (
              <option key={optionValue} value={optionValue} disabled={disabled}>
                {optionLabel}
              </option>
            );
          }

          return (
            <MenuItem
              key={optionValue}
              value={optionValue}
              disabled={disabled}
              classes={{
                root: classes.menuItem,
                selected: classes.selected,
              }}
            >
              {optionLabel}
            </MenuItem>
          );
        })}
      </MUISelect>

      {!!error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

DEPRECATED_SELECT.defaultProps = {
  label: '',
  name: undefined,
  displayEmpty: true,
  error: false,
  value: '',
  native: false,
  options: [],
  inputProps: {},
};

DEPRECATED_SELECT.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
  native: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  inputProps: PropTypes.shape({}),
};

export default DEPRECATED_SELECT;
