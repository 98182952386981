import Can from '@DEPRECATED/components/Can';
import history from '@DEPRECATED/services/history';
import { numberFormat } from '@DEPRECATED/utils/format';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';

import BookActions from './BookActions';
import OfferStatus from './OfferStatus';
import Stage from './Stage';
import Timer from './Timer';
import { TableCellBody } from './styles';
import useColorMode from '~/hooks/useColorMode';
import { useNavigate } from 'react-router-dom';

const CATEGORY = {
  'TRD_DEFAULT': 'TRADENER (Padrão)',
  'TRD_FLAT': 'TRADENER (Flat)',
  'TRD_PLUS': 'TRD PLUS',
  'TRD_RETAILER_SET_PRICE': 'TRD VAREJISTA (Preço Definido)',
  'TRD_RETAILER_SET_PRICE_FLEX_FREE': 'TRD VAREJISTA (Preço Definido + Flex LIVRE)',
  'TRD_RETAILER_GUARANTEED_GAIN': 'TRD VAREJISTA (Ganho Garantido)',
  'TRD_RETAILER_GUARANTEED_GAIN_FLEX_FREE': 'TRD VAREJISTA (Ganho Garantido + Flex LIVRE)',
  'TRD_TRIGGER': 'TRD GATILHO',
}

function BookItem({ quote, isEven }) {
  const push = useNavigate();
  const { colorTernary } = useColorMode();
  const timerDate = quote.stage === 'closed' ? quote.stageAt : quote.expireDate;

  function showDetails() {
    push(`show/${quote.id}`);
  }

  return (
    <TableRow style={{ backgroundColor: colorTernary(isEven ? '#373737' : '#232323', isEven ? '#F6F6F6' : 'white'), }}>
      <TableCellBody onClick={showDetails} align="left" >
        <OfferStatus status={quote.stage} type={quote.opportunity.type} />
      </TableCellBody>
      <TableCellBody onClick={showDetails} transform="uppercase" >
        {quote.product}
      </TableCellBody>
      <TableCellBody onClick={showDetails} align="right" >
        {numberFormat(quote.quantity, 2, 2)}
      </TableCellBody>
      <TableCellBody onClick={showDetails} align="left" >
        {quote.price || '-'}
      </TableCellBody>
      <TableCellBody onClick={showDetails} align="left" >
        {quote.requestedAmount || '-'}
      </TableCellBody>
      <TableCellBody onClick={showDetails} className="truncate" transform="uppercase" >
        {quote.accountName}
      </TableCellBody>
      <TableCellBody onClick={showDetails} align="left" transform="uppercase" >
        {quote.opportunity.channel}
      </TableCellBody>
      <TableCellBody onClick={showDetails} align="left" >
        {CATEGORY[quote.opportunity.category]?.toUpperCase() || '-'}
      </TableCellBody>
      <TableCellBody onClick={showDetails} align="center" >
        {quote.swap ? "SWAP": "-"}
      </TableCellBody>
      <TableCellBody onClick={showDetails} align="center" >
        <Stage status={quote.stage} alignSelf="center" />
      </TableCellBody>
      <TableCellBody onClick={showDetails} >
        {timerDate && <Timer status={quote.stage} date={timerDate} />}
      </TableCellBody>
      <TableCellBody style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Can requires="quote:upsert">
          <BookActions quote={quote} />
        </Can>
      </TableCellBody>
    </TableRow>
  );
}

BookItem.propTypes = {
  quote: PropTypes.shape({
    stage: PropTypes.oneOf(['canceled', 'denied', 'rejected', 'proposed', 'closed', 'opened']),
    id: PropTypes.string,
    accountName: PropTypes.string,
    product: PropTypes.string,
    swap: PropTypes.bool,
    quantity: PropTypes.number,
    price: PropTypes.string,
    requestedAmount: PropTypes.string,
    expireDate: PropTypes.string,
    opportunity: PropTypes.shape({
      type: PropTypes.string,
      channel: PropTypes.string,
    }),
  }).isRequired,
};

export default BookItem;
