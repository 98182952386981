import { useCallback, useMemo, useReducer, useRef, memo } from 'react';

import FilterButton from '@DEPRECATED/components/Filter/FilterButton';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
  MenuItem,
  TextField,
  Typography,
  Button,
  Grid,
  FormGroup,
  OutlinedInputProps,
  InputAdornment,
} from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { startOfDay, endOfDay, startOfMonth } from 'date-fns';
import { usePopupState, bindTrigger, bindPopover } from 'material-ui-popup-state/hooks';

import { Container } from './styles';

import { initialFilterState, QuoteSummaryAction, QuoteSummaryProps, reducer, useOfferFilter } from '..';
import CurrencyInput from '~/utils/currencyInput';

const OfferNegotiationFilter = () => {
  const initialOfferFilterState = useRef({
    ...initialFilterState,
    closedAfter: startOfMonth(new Date()) as unknown as string,
    closedBefore: new Date(),
    priceType: 'all',
    submarket: 'all',
    product: 'all',
    periodType: 'all',
    swap: undefined,
    hasGiro: undefined,
    addtionalPrice: undefined,
    additionalEnergyType: 'all',
  } as unknown as QuoteSummaryProps);
  const { filter, replaceFilter, resetFilter } = useOfferFilter();
  const [inMemoryFilter, dispatch] = useReducer(reducer, initialOfferFilterState.current);
  const isProposed = useMemo(() => inMemoryFilter.stage.includes('PROPOSED'), [inMemoryFilter.stage]);

  const setFilter = useCallback(<K extends keyof QuoteSummaryProps, S extends QuoteSummaryProps[K]>(type: K, value: S) => {
    dispatch({ type, value });
  }, []);

  const handleDateChange = useCallback(
    <K extends keyof QuoteSummaryProps>(type: K) =>
      (date: MaterialUiPickersDate | null, _value?: string | null) => {
        setFilter(type, date as unknown as QuoteSummaryProps[K]);
      },
    [setFilter],
  );

  const handleInputChange = useCallback(
    <K extends keyof QuoteSummaryProps>(type: K): OutlinedInputProps['onChange'] =>
      ({ target }) => {
        setFilter(type, target.value as QuoteSummaryProps[K]);
      },
    [setFilter],
  );

  const handleAdditionalPriceChange = useCallback((_, value?: number) => {
    setFilter('additionalPrice', value);
  }, [setFilter]);

  const handleCheckBoolean = useCallback((type: keyof QuoteSummaryProps) => ({ target }) => {
    setFilter(type, target.value === 'all' ? undefined : JSON.parse(target.value));
  }, [setFilter]);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'macro-filter',
  });

  const handleFilter: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();
      const payload = {} as QuoteSummaryProps;

      for (const [key, value] of Object.entries({
        ...inMemoryFilter,
        closedAfter: (inMemoryFilter?.closedAfter && !isProposed
          ? startOfDay(inMemoryFilter.closedAfter as unknown as Date)
          : undefined) as unknown as string,
        closedBefore: (inMemoryFilter?.closedBefore && !isProposed
          ? endOfDay(inMemoryFilter.closedBefore as unknown as Date)
          : undefined) as unknown as string,
      })) {
        payload[key] = value === 'all' ? undefined : value;
      }

      replaceFilter(payload);
      popupState.close();
    },
    [inMemoryFilter, isProposed, popupState, replaceFilter],
  );

  const handleReset = useCallback(() => {
    resetFilter();
    dispatch({
      type: 'all',
      value: initialOfferFilterState.current,
    } as unknown as QuoteSummaryAction);
    setFilter('additionalPrice', undefined);
  }, [resetFilter]);

  return (
    <>
      <FilterButton size="small" isFiltered={Object.keys(filter ?? {}).length > 1} {...bindTrigger(popupState)} />
      <Popover
        disablePortal
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 58,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <form onSubmit={handleFilter}>
          <Container>
            <Grid container spacing={1}>
              <Grid item xs>
                <Typography variant="subtitle1">Visualização de ofertas</Typography>
              </Grid>
            </Grid>
            <RadioGroup
              aria-label="view"
              name="view"
              value={inMemoryFilter.stage[0]}
              onChange={(_, value: string) => setFilter('stage', [value])}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControlLabel value="PROPOSED" control={<Radio color="primary" />} label="Em negociação" />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel value="CLOSED" control={<Radio color="primary" />} label="Fechadas" />
                </Grid>
              </Grid>
            </RadioGroup>
            <Grid container spacing={3}>
              <Grid item xs sm={6}>
                <FormGroup>
                  <KeyboardDatePicker
                    disableToolbar
                    disabled={isProposed}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    label="De"
                    size="small"
                    invalidDateMessage="Data inválida"
                    value={inMemoryFilter?.closedAfter}
                    onChange={handleDateChange('closedAfter')}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs sm={6}>
                <FormGroup>
                  <KeyboardDatePicker
                    disableToolbar
                    disabled={isProposed}
                    autoOk
                    minDate={inMemoryFilter?.closedAfter}
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    label="Até"
                    size="small"
                    invalidDateMessage="Data inválida"
                    value={inMemoryFilter?.closedBefore}
                    onChange={handleDateChange('closedBefore')}
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs>
                <Typography variant="subtitle1">Outras opções</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs sm={6}>
                <FormGroup>
                  <TextField
                    size="small"
                    select
                    label="Modalidade de Preço"
                    value={inMemoryFilter?.priceType}
                    onChange={handleInputChange('priceType')}
                    variant="outlined"
                  >
                    <MenuItem value="all">Todos</MenuItem>
                    <MenuItem value="FIXO">Fixo</MenuItem>
                    <MenuItem value="PERCENT_PLD">% PLD</MenuItem>
                    <MenuItem value="SPREAD">SPREAD</MenuItem>
                  </TextField>
                </FormGroup>
              </Grid>
              <Grid item xs sm={6}>
                <FormGroup>
                  <TextField
                    size="small"
                    select
                    label="Submercado"
                    value={inMemoryFilter?.submarket}
                    onChange={handleInputChange('submarket')}
                    variant="outlined"
                  >
                    <MenuItem value="all">Todos</MenuItem>
                    <MenuItem value="N">Norte</MenuItem>
                    <MenuItem value="NE">Nordeste</MenuItem>
                    <MenuItem value="SE">Sudeste/Centro-Oeste</MenuItem>
                    <MenuItem value="S">Sul</MenuItem>
                  </TextField>
                </FormGroup>
              </Grid>
              <Grid item xs sm={6}>
                <FormGroup>
                  <TextField
                    size="small"
                    select
                    label="Tipo de energia"
                    value={inMemoryFilter?.product}
                    onChange={handleInputChange('product')}
                    variant="outlined"
                  >
                    <MenuItem value="all">Todos</MenuItem>
                    <MenuItem value="C">C</MenuItem>
                    <MenuItem value="I5">I5</MenuItem>
                    <MenuItem value="I1">I1</MenuItem>
                    <MenuItem value="I0">I0</MenuItem>
                    <MenuItem value="I8">I8</MenuItem>
                    <MenuItem value="INE5">INE5</MenuItem>
                  </TextField>
                </FormGroup>
              </Grid>
              <Grid item xs sm={6}>
                <FormGroup>
                  <TextField
                    size="small"
                    select
                    label="Tipo de contrato"
                    value={inMemoryFilter?.periodType}
                    onChange={handleInputChange('periodType')}
                    variant="outlined"
                  >
                    <MenuItem value="all">Todos</MenuItem>
                    <MenuItem value="SHORT">Curto prazo</MenuItem>
                    <MenuItem value="LONG">Longo prazo</MenuItem>
                  </TextField>
                </FormGroup>
              </Grid>
              <Grid item xs sm={6}>
                <FormGroup>
                  <TextField
                    size="small"
                    select
                    label="Swap"
                    value={inMemoryFilter?.swap?.toString() ?? 'all'}
                    onChange={handleCheckBoolean('swap')}
                    variant="outlined"
                  >
                    <MenuItem value='all'>Todos</MenuItem>
                    <MenuItem value="true">Com Swap</MenuItem>
                    <MenuItem value="false">Sem Swap</MenuItem>
                  </TextField>
                </FormGroup>
              </Grid>
              <Grid item xs sm={6}>
                <FormGroup>
                  <TextField
                    size="small"
                    select
                    label="Giro"
                    value={inMemoryFilter?.hasGiro?.toString() ?? 'all'}
                    onChange={handleCheckBoolean('hasGiro')}
                    variant="outlined"
                  >
                    <MenuItem value='all'>Todos</MenuItem>
                    <MenuItem value="true">Com Giro</MenuItem>
                    <MenuItem value="false">Sem Giro</MenuItem>
                  </TextField>
                </FormGroup>
              </Grid>
              <Grid item xs sm={12}>
                <Grid item xs>
                  <Typography variant="subtitle1">Preço adicional</Typography>
                </Grid>
                <Grid container spacing={3} alignItems='flex-end'>
                  <Grid item xs sm={6}>
                    <FormGroup>
                      <CurrencyInput
                        value={inMemoryFilter?.additionalPrice}
                        onChange={handleAdditionalPriceChange}
                        component={TextField}
                        name="additionalPrice"
                        size="small"
                        label="R$"
                        variant='outlined'
                        InputProps={{
                          startAdornment: <InputAdornment position="start" />,
                          style: { height: '2rem' }
                        }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs sm={6}>
                    <FormGroup>
                      <TextField
                        name="additionalEnergyType"
                        size="small"
                        select
                        label="Tipo de energia"
                        value={inMemoryFilter?.additionalEnergyType ?? 'all'}
                        onChange={handleInputChange('additionalEnergyType')}
                        variant="outlined"
                      >
                        <MenuItem value="all">Todos</MenuItem>
                        <MenuItem value="C">C</MenuItem>
                        <MenuItem value="I5">I5</MenuItem>
                        <MenuItem value="I1">I1</MenuItem>
                        <MenuItem value="I0">I0</MenuItem>
                        <MenuItem value="I8">I8</MenuItem>
                        <MenuItem value="INE5">INE5</MenuItem>
                      </TextField>
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs sm={6}>
                <FormGroup>
                  <Button variant="contained" color="secondary" onClick={handleReset}>
                    LIMPAR
                  </Button>
                </FormGroup>
              </Grid>
              <Grid item xs sm={6}>
                <FormGroup>
                  <Button variant="contained" color="primary" type="submit">
                    APLICAR FILTRO
                  </Button>
                </FormGroup>
              </Grid>
            </Grid>
          </Container>
        </form>
      </Popover>
    </>
  );
};

export default memo(OfferNegotiationFilter);
