import { IconButton } from '@material-ui/core';
import ButtonRaw from '@material-ui/core/Button';
import styled, { css } from 'styled-components';

const Button = styled(ButtonRaw)`
  && {
    height: 32px;
    min-width: 32px;
    width: 32px;
    padding: unset;
    box-shadow: unset;

    svg {
      width: 13px;
      height: 13px;
    }

    @media screen and (min-width: 600px) {
      padding: 6px 16px;
      width: unset;
      text-transform: capitalize;
      font-weight: 700;
      letter-spacing: 0.8px;
      height: 32px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export const PersonalizedIconButton = styled(IconButton)`
  ${({ theme }) => css`
    && {
      box-shadow: none;
      animation: none;
      transition: none;

      &:hover {
        cursor: pointer;
        box-shadow: none;
        animation: none;
        transition: none;
        background-color: transparent;
      }
      svg {
      }
    }
  `}
`;

export const Filter = styled(Button).attrs({
  variant: 'contained',
  color: 'primary',
})`
  ${({ theme }) => css`
    && {
      span span {
        display: none;

        @media screen and (min-width: 600px) {
          display: initial;
          margin-left: 8px;
        }
      }
    }
  `}
`;
