import { Badge } from '@tradener/lumen';

import { compareTwoEntries } from '~/utils/compare';

import { useBbceProduct } from '../../..';
import OfferListItem from '../OfferListItem';
import useProductQuote from '../useProductQuote';

export const stages = [
  { id: 'PROPOSED', label: 'Negociação', bgColor: 'yellow.500', color: 'white' },
  { id: 'OPENED', label: 'Aberto', bgColor: 'gray.500', color: 'white' },
  { id: 'CLOSED', label: 'Fechado', bgColor: 'green.500', color: 'white' },
  { id: 'REJECTED', label: 'Rejeitado', bgColor: 'red.500', color: 'white' },
  { id: 'DENIED', label: 'Rejeitado', bgColor: 'red.500', color: 'white' },
];

function OfferStage() {
  const { id } = useBbceProduct();
  const { opportunity, stage } = useProductQuote();
  const isInternalTransfer = opportunity.bbceProduct?.id !== id;
  const props = stages.find(({ id }) => compareTwoEntries(stage, id));

  if (!stage) return <OfferListItem />;

  return (
    <OfferListItem justifyContent="center">
      {isInternalTransfer ? (
        <Badge borderRadius="base" py="1px" px="1.5" bgColor="blue.500" color="white">
          Transferido
        </Badge>
      ) : (
        <Badge borderRadius="base" py="1px" px="1.5" borderWidth="0" sx={props}>
          {props?.label}
        </Badge>
      )}
    </OfferListItem>
  );
}

export default OfferStage;
