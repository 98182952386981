import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import { Container, Header, Body } from './styles';

export default function FieldBox({ title, children, type, ...other }) {
  return (
    <Container {...other}>
      <Header>
        <Typography variant="h5">{title}</Typography>
      </Header>
      <Body type={type}>{children}</Body>
    </Container>
  );
}

FieldBox.defaultProps = {
  type: 'row',
};

FieldBox.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['row', 'column']),
};
