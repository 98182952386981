import { Flex, Text, useToken } from '@tradener/lumen';
import { DateTime } from 'luxon';

import DashIcon from '~/theme/icons/Dash';
import DashedSquareIcon from '~/theme/icons/DashedSquare';
import LineIcon from '~/theme/icons/Line';
import RoundedSquareIcon from '~/theme/icons/RoundedSquare';

import { useProductAnalytics } from '../../useProductAnalytics';

const formatTooltip = (value: any, mode: string) => {
  let dateTime;

  if (typeof value === 'number') dateTime = DateTime.fromMillis(value).setLocale('pt-BR');
  else dateTime = DateTime.fromISO(value).setLocale('pt-BR');

  if (mode === 'HOUR') {
    return dateTime.hour + 1 + 'h';
  } else if (mode === 'DOW') {
    return dateTime.toFormat('ccc') + ' ' + dateTime.toFormat('dd MMM');
  } else if (mode === 'DAY') {
    return dateTime.toFormat('dd LLLL yyyy');
  } else if (mode === 'MONTH') {
    return dateTime.toFormat('MMM yyyy');
  } else if (mode === 'YEAR') {
    return dateTime.toFormat('yyyy');
  }

  return '';
};

const CustomTooltip = ({ active, payload, label, customlabel, mode, config }: any) => {
  const [gray0, red500] = useToken('colors', ['gray.0', 'red.500']);
  const { series } = useProductAnalytics();

  if (active && payload && payload.length) {
    const operations =
      !!series && series.length > 0 && !!payload?.[0]?.payload?.timestamp
        ? series
            ?.filter((item: any) => !!item?.type && Number(item?.timestamp) === Number(payload?.[0]?.payload?.timestamp))
            .filter(Boolean)
        : [];

    return (
      <Flex
        bgColor={gray0}
        py="2"
        px="3"
        flexDirection="column"
        justifyContent="space-between"
        width="13.75rem"
        borderRadius="lg"
        boxShadow="lg"
        gap="2"
        maxHeight="50"
      >
        <Text color="gray.900" textStyle="body2-medium">
          {customlabel ?? formatTooltip(label, mode)}
        </Text>
        <Flex flexDirection="column" justifyContent="space-between" rowGap="2" columnGap="5" maxHeight="40" flexWrap="wrap">
          <>
            {payload?.map(({ value, dataKey, name }: any, index: number) => {
              if (['timestamp', 'price', 'operationMwm', 'type'].includes(dataKey) || !dataKey) return null;

              const {
                iconColor,
                iconType,
                text,
                minimumFractionDigits,
                maximumFractionDigits,
                style,
                currency,
                hidden,
                hiddenTooltip,
              } = config(dataKey);

              const newIconColor = dataKey === 'balanceMwm' && Number(value) < 0 ? red500 : iconColor;

              if (hidden || hiddenTooltip) return null;

              return (
                <Flex key={dataKey + value + index} alignItems="center" justifyContent="space-between" gap="5">
                  <Flex alignItems="center" gap="2">
                    {iconType === 'square' && (
                      <Flex alignItems="center" width="3.5" height="3.5" color={newIconColor ?? 'gray.500'}>
                        <RoundedSquareIcon />
                      </Flex>
                    )}
                    {iconType === 'line' && (
                      <Flex alignItems="center" width="3.5" height=".125rem" color={iconColor ?? 'gray.500'}>
                        <LineIcon />
                      </Flex>
                    )}
                    {iconType === 'dashed' && (
                      <Flex alignItems="center" width="3.5" height=".125rem" color={iconColor ?? 'gray.500'}>
                        <DashIcon />
                      </Flex>
                    )}
                    {iconType === 'dashed-square' && (
                      <Flex alignItems="center" width="3.5" height="3.5" color={iconColor ?? 'gray.500'}>
                        <DashedSquareIcon />
                      </Flex>
                    )}
                    <Text color="gray.500" flex="1" textStyle="body2-regular">
                      {text ?? name}
                    </Text>
                  </Flex>
                  <Text color="gray.900" textStyle="body2-medium">
                    {Number(value).formatCurrency('pt-BR', {
                      style: style ?? 'decimal',
                      currency: currency ?? 'BRL',
                      minimumFractionDigits: minimumFractionDigits ?? 2,
                      maximumFractionDigits: maximumFractionDigits ?? 2,
                    })}
                    {dataKey === 'exposure' && ' MWm'}
                  </Text>
                </Flex>
              );
            })}
            {payload?.map(({ dataKey }) => dataKey).includes('exposure') &&
              operations?.length > 0 &&
              operations.map((operation: any, index: number) => {
                if (index > 3) return null;

                if (index === 3)
                  return (
                    <Flex key={`operation-${operation?.timestamp}-${index}`} alignItems="center" gap="5">
                      <Flex justifyContent="flex-end" alignItems="center" gap="2" width="full">
                        <Text color="gray.500" textStyle="body2-regular">
                          ({operations.length - 3}) {operations.length - 3 > 1 ? 'outras operações' : 'outra operação'}
                        </Text>
                      </Flex>
                    </Flex>
                  );

                return (
                  <Flex
                    key={`operation-${operation?.timestamp}-${index}`}
                    alignItems="center"
                    justifyContent="space-between"
                    gap="5"
                  >
                    <Flex alignItems="center" gap="2">
                      {index === 3 ? (
                        <Text color="gray.500" flex="1" textStyle="body2-regular">
                          {operations.length - 3} outra(s) operação(ões)
                        </Text>
                      ) : (
                        <Text
                          flex="1"
                          textStyle="body2-regular"
                          color={
                            operation?.type === 'SALE' ? 'orange.300' : operation?.type === 'PURCHASE' ? 'blue.300' : 'purple.300'
                          }
                        >
                          {operation?.type === 'SALE' ? 'Venda' : operation?.type === 'PURCHASE' ? 'Compra' : 'Transferência'}
                        </Text>
                      )}
                    </Flex>
                    <Flex alignItems="center" justifyContent="flex-end" gap="2" flex="1">
                      <Text color="gray.900" textStyle="body2-medium">
                        {Number(operation?.operationMwm).formatCurrency('pt-BR', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1,
                        }) + ' MWm'}
                      </Text>
                      <Text color="gray.900" textStyle="body2-medium">
                        {Number(operation?.price).formatCurrency('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Text>
                    </Flex>
                  </Flex>
                );
              })}
          </>
        </Flex>
      </Flex>
    );
  }

  return null;
};

export default CustomTooltip;
