import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  tableContainer: {
    backgroundColor: palette.type === 'dark' ? palette.grey[900] : palette.common.white,
    borderRadius: 4,
    marginBottom: 20,
  },
  tableRow: {
    '& td:first-child, & th:first-child': {
      paddingLeft: 16,
      '& div': {
        justifyContent: 'flex-start',
      },
    },
  },
}));

export default useStyles;
