import { createIcon } from "@tradener/lumen";

const TransferIcon = createIcon({
  displayName: 'TransferIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path fillRule="evenodd" clipRule="evenodd" d="M5 5.75C5 5.19772 5.44772 4.75 6 4.75H18C18.5523 4.75 19 5.19772 19 5.75C19 6.30228 18.5523 6.75 18 6.75H13V18.25C13 18.8023 12.5523 19.25 12 19.25C11.4477 19.25 11 18.8023 11 18.25V6.75H6C5.44772 6.75 5 6.30228 5 5.75Z" fill="currentColor"/>
    </>
  )
});

export default TransferIcon;
