import { memo, useEffect } from 'react';

import { InputStatus } from '..';

import { IconButton } from '@material-ui/core';
import zIndex from '@material-ui/core/styles/zIndex';
import { Delete } from '@material-ui/icons';

import { SimulationItemProps } from '@graphql/query/simulationContracts';

import useColorMode from '~/hooks/useColorMode';
import useEditable from '~/hooks/useEditable';
import usePortfolioFilter from '~/pages/Portfolio/usePortfolioFilter';
import useSimulationRules from '~/pages/Portfolio/useSimulationRules';
import getPeriodTime from '~/utils/getPeriodTime';

import { useSimulationContract } from '../../context';
import EditionParent from './styles';

export interface ContractEditionProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  bgColor: string;
  borderColor: string;
  inputStatus: InputStatus;
  simulationItem: SimulationItemProps | undefined;
  resourceId: string;
  period: string;
  handleInputChange: (value: string) => void;
  excludedContract: boolean;
}

const ContractEdition: React.ComponentType<ContractEditionProps> = ({
  children,
  bgColor,
  borderColor,
  inputStatus,
  resourceId,
  simulationItem,
  period,
  handleInputChange,
  excludedContract,
  ...props
}) => {
  const { colorTernary } = useColorMode();
  const { isReadOnly } = useSimulationRules();
  const { view } = usePortfolioFilter();
  const { itemPushList, setItemPushList } = useSimulationContract();
  const isCurrentOrAfter = /CURRENT|AFTER/.test(getPeriodTime(period));

  const {
    reset,
    getParentProps,
    getEditionProps,
    value: editedValue,
    isEditing,
  } = useEditable(children?.toString(), {
    isEditable:
      !isReadOnly && !!simulationItem && !!resourceId && !excludedContract && view !== 'totalAmount' && isCurrentOrAfter,
    transform: (value) => value.replace(/[^\d,]/g, ''),
  });

  const handleAppendTo = (value: string | undefined) => {
    if (value) {
      const formattedValue = value.trim().replace(/[^\d,]/g, '');

      if (formattedValue !== editedValue) handleInputChange(formattedValue);
    }
  };

  const handleDelete = (event) => {
    event.stopPropagation();
    if (inputStatus === InputStatus.TOUCHED) {
      setItemPushList((current) => current.filter((item) => item.resourceId !== resourceId || item.competence !== period));
    } else {
      const newValue = view === 'price' ? simulationItem?.oldPrice : simulationItem?.oldQuantityMwm;

      if (newValue) handleInputChange(newValue.toString().replace('.', ','));
    }
  };

  useEffect(() => {
    if (itemPushList?.length === 0) {
      reset(children?.toString());
    }
  }, [children, itemPushList?.length, reset]);

  return (
    <EditionParent {...getParentProps()} bgColor={bgColor} borderColor={borderColor} edited={inputStatus} {...props}>
      {isEditing ? (
        <div
          style={{
            position: 'absolute',
            display: 'inline-flex',
            alignItems: 'center',
            margin: 0,
            right: 0,
            width: '100%',
            background: `${colorTernary('#111', '#FFF')}`,
            height: '40px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
            borderRadius: '4px',
            padding: '10px 16px',
            transform: 'scale(1.1)',
            whiteSpace: 'nowrap',
            zIndex: 1,
          }}
          {...getEditionProps({ onSubmit: handleAppendTo })}
        />
      ) : (
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flex: '1',
            alignItems: 'center',
            justifyContent: 'flex-end',
            backgroundColor: bgColor + ' !important',
            height: '38px',
            padding: '6px',
            margin: 0,
          }}
        >
          {editedValue}
        </div>
      )}
      {!isReadOnly && inputStatus !== InputStatus.INITIAL && !isEditing && (
        <IconButton
          onFocus={(event) => {
            event.stopPropagation();
          }}
          onClick={handleDelete}
          size="small"
          style={{
            position: 'absolute',
            top: -15,
            right: -15,
            width: 22,
            height: 22,
            border: 'initial',
            borderRadius: '100%',
            backgroundColor: `${inputStatus === InputStatus.TOUCHED ? '#FFB624' : '#4383F7'}`,
            zIndex: zIndex.tooltip,
          }}
        >
          <Delete
            htmlColor="#FFF"
            style={{
              width: '12px',
              height: '14px',
              backgroundColor: `${inputStatus === InputStatus.TOUCHED ? '#FFB624' : '#4383F7'}`,
            }}
          />
        </IconButton>
      )}
    </EditionParent>
  );
};

export default memo(ContractEdition);
