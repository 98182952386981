import { SkeletonProps } from '@tradener/lumen';

import { Item } from '../Table/Item';

export const Skeleton: React.FC<SkeletonProps> = () => (
  <>
    {[0, 1, 2].map((item) => (
      <Item key={item} loading={true} />
    ))}
  </>
);
