import { useMatch } from 'react-router-dom';

import useAuth from '~/hooks/useAuth';

import useSimulation from './useSimulation';

function useSimulationRules() {
  const { id } = useAuth();
  const isPortfolio = !!useMatch('/portfolio/table/*');
  const isContractPage = !!useMatch(`/portfolio${isPortfolio ? '/table' : '/simulation'}/contracts/*`);
  const { user } = useSimulation();

  return {
    isPortfolio,
    isReadOnly: user?.id !== id || isPortfolio,
    isContractPage,
  };
}

export default useSimulationRules;
