import { useReducer, useRef } from 'react';

import { IconButton, Tooltip, Typography, useTheme } from '@material-ui/core';
import { Flex } from '@tradener/lumen';
import { DateTime } from 'luxon';

import Update from '~/theme/icons/Update';
import Container from '~/theme/layout/Container';
import Filter from '~/theme/layout/Filter';

import Badges from '../../Badges';
import useSimulation from '../../useSimulation';
import useSimulationRules from '../../useSimulationRules';
import SimulationCommit from '../SimulationCommit';
import SimulationSubheader, { SimulationName } from '../SimulationSubheader';
import SimulationSync from '../SimulationSync';
import SimulationBalance from './SimulationBalance';
import SimulationDRE from './SimulationDRE';
import SimulationExposure from './SimulationExposure';
import SimulationOperations from './SimulationOperations';
import { withSimulationTable } from './withSimulationTable';

const isStaging = process.env.REACT_APP_ROLLBAR_ENV === 'staging';

export const submarkets = {
  se: 'SE/CO',
  s: 'S',
  ne: 'NE',
  n: 'N',
};

const FULL_DATE = 'dd LLL yyyy';
const DATETIME = 'HH:mm:ss';

const SimulationTable = () => {
  const { synchronizedAt } = useSimulation();
  const { isReadOnly } = useSimulationRules();
  const [isOpen, toggle] = useReducer((prev) => !prev, false);
  const { zIndex } = useTheme();
  const badgesRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <SimulationSubheader width="calc(100% - 20px)" zIndex={zIndex.appBar}>
        <SimulationName />
        {!isReadOnly && (
          <IconButton onClick={toggle} size="small" style={{ border: 'none' }}>
            <Update width="20px" height="20px" />
          </IconButton>
        )}
        <Tooltip
          title={DateTime.fromISO(synchronizedAt as string).toFormat(`${FULL_DATE} 'às' ${DATETIME}`, { locale: 'pt-BR' })}
        >
          <Typography color="secondary" style={{ fontSize: '14px' }}>
            {synchronizedAt && `Atualizado ${DateTime.fromISO(synchronizedAt).toRelative({ locale: 'pt-BR' })}`}
          </Typography>
        </Tooltip>
      </SimulationSubheader>
      <Badges ref={badgesRef} />
      <Flex
        direction="row"
        justify="space-between"
        position="sticky"
        align="flex-start"
        top="300px"
        maxHeight={
          isStaging
            ? `calc(100vh - 197px - ${badgesRef.current ? '48px' : '0px'})`
            : `calc(100vh - 160px - ${badgesRef.current ? '48px' : '0px'})`
        }
      >
        <Container display="grid" overflow="auto" gridAutoColumns="repeat(auto-fit, minmax(100vw, 1fr))">
          <SimulationBalance />
          <SimulationDRE />
          <SimulationOperations />
          <SimulationExposure />
          <SimulationCommit />
        </Container>
        <Filter />
      </Flex>
      <SimulationSync isOpen={isOpen} onClose={toggle} />
    </>
  );
};

export default withSimulationTable(SimulationTable);
