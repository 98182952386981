import React, { Fragment } from 'react';

import { Flex, Switch, Text, Highlight, IconButton, EditIcon, Skeleton, Box } from '@tradener/lumen';

import useRestriction from '~/hooks/useRestriction';

import { useOperationLimits } from '../../hooks/useOperationLimits';
import { MODAL } from '../../types/Modal';
import { Td } from '../Td';

export interface ItemProps {
  resourceId?: string;
  accountName?: string;
  taxId?: string;
  totalMonths?: number;
  purchaseLimit?: number;
  saleLimit?: number;
  loading?: boolean;
  ref?: React.Ref<HTMLTableRowElement>;
}
export const Item = React.forwardRef<HTMLTableRowElement, ItemProps>(
  ({ resourceId, accountName, taxId, totalMonths, purchaseLimit, saleLimit, loading }, ref) => {
    const purchaseLimitValue = `${purchaseLimit ? purchaseLimit.formatCurrency() : '-'}`;
    const saleLimitValue = `${saleLimit ? saleLimit.formatCurrency() : '-'}`;
    const { onOpen } = useOperationLimits();
    const { restrict } = useRestriction();

    return (
      <Fragment>
        <Td p={3} onClick={() => onOpen(resourceId || '', MODAL.DETAILS)} cursor="pointer">
          <Flex ref={ref} flexDir="column" gap={1}>
            <Skeleton borderRadius={'md'} isLoaded={!loading} maxW={loading ? '70%' : 'auto'}>
              <Text textStyle="body2-regular" textOverflow="ellipsis" overflow="hidden" whiteSpace="no-wrap" noOfLines={3}>
                {accountName ? accountName : '-'}
              </Text>
            </Skeleton>
            <Skeleton borderRadius={'md'} isLoaded={!loading} maxW={loading ? '40%' : 'auto'}>
              <Text textStyle="caption1-regular" color="gray.500">
                {taxId ? taxId?.formatTaxId() ?? '-' : '-'}
              </Text>
            </Skeleton>
          </Flex>
        </Td>
        <Td p={3}>
          <Skeleton borderRadius={'md'} isLoaded={!loading}>
            <Text textStyle="body2-regular">{totalMonths ? `${totalMonths} meses` : '-'}</Text>
          </Skeleton>
        </Td>
        <Td p={3}>
          <Skeleton borderRadius={'md'} isLoaded={!loading}>
            <Text textStyle="body2-regular" color="gray.500">
              <Highlight query={purchaseLimitValue} styles={{ color: 'gray.900' }}>
                {`${purchaseLimitValue}`}
              </Highlight>
            </Text>
          </Skeleton>
        </Td>
        <Td p={3}>
          <Skeleton borderRadius={'md'} isLoaded={!loading}>
            <Text textStyle="body2-regular" color="gray.500">
              <Highlight query={saleLimitValue} styles={{ color: 'gray.900' }}>
                {`${saleLimitValue}`}
              </Highlight>
            </Text>
          </Skeleton>
        </Td>
        <Td p={3} textAlign="center">
          <Skeleton borderRadius={'md'} isLoaded={!loading}>
            <Switch disabled colorScheme="gray" />
          </Skeleton>
        </Td>
        <Td p={0} textAlign="center">
          {restrict(
            'OPERATION_LIMITS_UPSERT',
            <Box p={3}>
              <Skeleton isLoaded={!loading}>
                <IconButton
                  variant="ghost"
                  colorScheme="gray"
                  icon={<EditIcon fontSize={24} />}
                  aria-label={'Editar'}
                  onClick={() => onOpen(resourceId || '', MODAL.EDIT)}
                />
              </Skeleton>
            </Box>,
          )}
        </Td>
      </Fragment>
    );
  },
);
