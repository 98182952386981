import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import refresh from '../session';

const errorLink = onError(({ networkError, operation, forward }) => {
  if (networkError?.message) {
    if (/(401)/.test(networkError.message)) {
      return fromPromise(refresh().then((data) => data))
        .filter((value) => !!value)
        .flatMap((value) => {
          if (value) {
            const { headers } = operation.getContext();

            operation.setContext({
              headers: {
                ...headers,
                Authorization: `Bearer ${value.token}`,
              },
            });
          }

          return forward(operation);
        });
    }
  }

  return undefined;
});

export default errorLink;
