import { createIcon } from "@tradener/lumen";

const EastIcon = createIcon({
  displayName: 'EastIcon',
  viewBox: '0 0 14 14',
  path: (
    <>
<path d="M8.33579 3.33078C8.10829 3.55828 8.10829 3.92578 8.33579 4.15328L10.5991 6.41661H1.74996C1.42913 6.41661 1.16663 6.67911 1.16663 6.99995C1.16663 7.32078 1.42913 7.58328 1.74996 7.58328H10.605L8.34163 9.84661C8.11413 10.0741 8.11413 10.4416 8.34163 10.6691C8.56913 10.8966 8.93663 10.8966 9.16413 10.6691L12.425 7.40828C12.6525 7.18078 12.6525 6.81328 12.425 6.58578L9.15829 3.33078C8.93663 3.10328 8.56329 3.10328 8.33579 3.33078Z" fill="currentColor"/>
</>
  )
});

export default EastIcon;
