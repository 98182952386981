import { gql } from '@apollo/client';
import { ISO8601DateTime } from '@tradener/lumen';

import { BbceProductProps } from './bbceProducts';
import { BbceProductTransferProps, QuoteInformationProps } from './productOperations';

export interface RequesterProps {
  email: string;
  name: string;
}

export interface AuditLogsProps {
  id: string;
  action: string;
  createdAt: string;
  requester: RequesterProps;
}

export interface EmailMessageEventsProps {
  event: any;
}

export interface RecipientProps {
  email?: string;
}

export interface EmailMessageProps {
  id: string;
  createdAt: string;
  emailMessageEvents: EmailMessageEventsProps[];
  recipient: RecipientProps;
}

export interface AccountProps {
  id: string;
  name: string;
}

export interface TenantProps {
  id: string;
  name: string;
}

export interface OpportunityProps {
  id: string;
  type: string;
  account: AccountProps;
  tenant: TenantProps;
  buyer: AccountProps;
  seller: AccountProps;
  bbceProduct?: BbceProductProps;
  bbceProductTransfer?: BbceProductTransferProps;
}

export interface QuoteItemProps {
  id: string;
  startDate: string;
  endDate: string;
  quantity: number;
  amount: number;
}

export interface QuoteProps {
  id: string;
  price: number;
  product: string;
  quantity: number;
  offerCode: string;
  opportunity: OpportunityProps;
  quoteItems: QuoteItemProps[];
  baseDate?: string;
  requestedAmount?: number;
  dueDateType?: string;
  dueDateValue?: string;
  startDate: string;
  endDate: string;
  expireDate: string;
  submarket: string;
  volumeType?: string;
  accountName?: string;
  channel?: string;
  priceType?: string;
  stage: string;
  notes?: string;
  swap?: boolean;
  externalId?: string;
  giro?: boolean;
  updatedAt?: ISO8601DateTime;
  quoteInformation?: QuoteInformationProps[];
  auditLogs?: AuditLogsProps[];
  emailMessages?: EmailMessageProps[];
}

export interface PayloadProps {
  id: string;
}

export interface ResponseProps {
  quote: QuoteProps;
}

const QUOTE_DETAILS_FRAGMENT = gql`
  fragment quote on Quote {
    id
    price
    requestedAmount
    quantity
    offerCode
    accountName
    baseDate
    dueDateType
    dueDateValue
    startDate
    endDate
    expireDate
    priceType
    submarket
    volumeType
    stage
    product
    externalId
    giro
    notes
    swap
    updatedAt
    opportunity {
      id
      account {
        id
        name
      }
      channel
      tenant {
        id
        name
      }
      buyer {
        id
        name
      }
      seller {
        id
        name
      }
      type
      bbceProduct {
        id
        name
      }
      bbceProductTransfer {
        id
        quantityMwm
        bbceProductFrom {
          id
          name
        }
        bbceProductTo {
          id
          name
        }
      }
    }
    quoteItems {
      id
      amount
      quantity
      startDate
      endDate
    }
    quoteInformation {
      id
      name
      type
      template
      value
      order
    }
    auditLogs {
      id
      action
      createdAt
      requester {
        ... on User {
          email
        }
        ... on ClientApp {
          name
        }
      }
    }
    emailMessages {
      id
      createdAt
      emailMessageEvents {
        event
      }
      recipient {
        ... on Contact {
          email
        }
        ... on User {
          email
        }
      }
    }
  }
`;

const QUOTE_DETAILS = gql`
  ${QUOTE_DETAILS_FRAGMENT}

  query Quote($id: ID!) {
    quote(id: $id) {
      ...quote
    }
  }
`;

export { QUOTE_DETAILS_FRAGMENT };
export default QUOTE_DETAILS;
