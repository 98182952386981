import { Box, Checkbox, FormControl, FormControlLabel, FormGroup } from '@material-ui/core';

import useContractFlags from '~/hooks/useContractFlags';
import usePortfolio from '~/pages/Portfolio/usePortfolio';

import Summary from '../Summary';
import useStyles from '../styles';

const ContractFlagFilter = () => {
  const { label } = useStyles();
  const { setFilter, ...state } = usePortfolio();
  const { contractFlags, loading } = useContractFlags();

  const handleToggleAll = (_: React.ChangeEvent<object>, checked: boolean) => {
    setFilter('contractFlags', checked ? contractFlags : []);
  };

  const handleCheckbox = (flag: string) => (_: React.ChangeEvent<object>, checked: boolean) => {
    setFilter(
      'contractFlags',
      checked ? [...(state.contractFlags ?? []), flag] : (state.contractFlags ?? []).filter((f) => f !== flag),
    );
  };

  return (
    <Summary label="Sinalizadores" filter="contractFlags">
      <Box maxHeight="200px" width="100%" overflow="auto">
        <FormControl component="fieldset">
          {!loading && (
            <FormGroup>
              <FormControlLabel
                classes={{ label }}
                control={
                  <Checkbox
                    color="primary"
                    indeterminate={
                      state.contractFlags &&
                      contractFlags &&
                      state.contractFlags.length > 0 &&
                      state.contractFlags.length < contractFlags.length
                    }
                  />
                }
                label="Selecionar todos"
                checked={state.contractFlags && contractFlags && state.contractFlags.length === contractFlags.length}
                onChange={handleToggleAll}
              />
              {contractFlags?.map((value) => (
                <FormControlLabel
                  key={`contractFlags.${value}`}
                  classes={{ label }}
                  control={<Checkbox color="primary" />}
                  label={value}
                  checked={!!state.contractFlags?.includes(value)}
                  onChange={handleCheckbox(value)}
                />
              ))}
            </FormGroup>
          )}
        </FormControl>
      </Box>
    </Summary>
  );
};

export default ContractFlagFilter;
