import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, To, Navigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  FormControl,
  FormLabel,
  Switch,
} from '@tradener/lumen';
import * as Yup from 'yup';

import type { InputProps } from '@graphql/mutation/upsertTradingQuote';
import { BbceProductTransferProps } from '@graphql/query/quote';

import useQueryParam from '~/hooks/useQueryParam';
import DEFAULT_ACCOUNTS from '~/utils/getDefaultAccounts';

import AmountInput from './AmountInput';
import MoreOptions from './MoreOptions';
import PickCompany from './PickCompany';
import PickEnergyType from './PickEnergyType';
import PickOperation from './PickOperation';
import PickPeriod from './PickPeriod';
import PickSubmarket from './PickSubmarket';
import PickTenant from './PickTenant';
import SaveButton from './SaveButton';
import VolumeInput from './VolumeInput';
import { FormActionType, useOffer, withOffer } from './context';

export interface FormProps extends InputProps {
  buyerName?: string;
  sellerName?: string;
  bbceProductTransfer?: BbceProductTransferProps;
}

const schema = Yup.object({
  buyerId: Yup.string().required('Campo obrigatório'),
  sellerId: Yup.string().required('Campo obrigatório'),
  operation: Yup.string().required('Campo obrigatório'),
  quoteItems: Yup.array().of(
    Yup.object({
      amount: Yup.number(),
      quantity: Yup.number(),
      startDate: Yup.string().notRequired(),
      endDate: Yup.string().notRequired(),
    }),
  ),
});

const initialValues = {
  operation: 'SALE',
  saveAndPropose: false,
  saveAndReject: false,
  quoteItems: [{ quantity: 0, amount: 0 }],
} as FormProps;

const withFormOffer =
  <T,>(Component: React.ComponentType<T>) =>
  (props: React.PropsWithChildren<T>) => {
    const { state } = useLocation();
    const { bbce_product_id: bbceProductId = '' } = useQueryParam('bbce_product_id') as { [key: string]: string };
    const defaultAccount = DEFAULT_ACCOUNTS.find((item) => item.name === 'TRADENER');

    const defaultValues =
      state?.buyerId || state?.sellerId
        ? { ...initialValues, ...state, bbceProductId }
        : {
            ...initialValues,
            buyerId: initialValues.operation === 'PURCHASE' ? defaultAccount?.accountId : undefined,
            sellerId: initialValues.operation === 'SALE' ? defaultAccount?.accountId : undefined,
            buyerName: initialValues.operation === 'PURCHASE' ? defaultAccount?.name : undefined,
            sellerName: initialValues.operation === 'SALE' ? defaultAccount?.name : undefined,
            bbceProductId,
          };

    const methods = useForm<FormProps>({
      mode: 'all',
      criteriaMode: 'all',
      resolver: yupResolver(schema),
      defaultValues,
    });

    return (
      <FormProvider {...methods}>
        <Component {...props} />
      </FormProvider>
    );
  };

const FormOffer = withOffer(() => {
  const navigate = useNavigate();
  const { key } = useLocation();
  const { type } = useQueryParam('type') as { [key: string]: FormActionType };
  const { setMoreOptions, moreOptions } = useOffer();
  const backTo = (key ? '/trading/list' : -1) as To;

  const onClose = () => {
    navigate(backTo);
  };

  if (!type) return <Navigate to={backTo} />;

  return (
    <Modal onClose={onClose} size="unset" isCentered isOpen>
      <ModalOverlay />
      <ModalContent width="37.5rem">
        <ModalHeader>
          <Text>Negociar</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody flexDir="column" py="2" px="6">
          <PickOperation />
          <Flex direction="column" gap="5">
            <PickTenant />
            <PickCompany />
          </Flex>
          <Flex align="center" justify="space-between" gap="5">
            <PickPeriod />
            <VolumeInput />
            <AmountInput />
          </Flex>
          <PickSubmarket />
          <PickEnergyType />
          <MoreOptions />
        </ModalBody>
        <ModalFooter alignItems="center" justifyContent="space-between" width="full">
          <FormControl gap="3" display="flex" alignItems="center">
            <Switch id="more-options" isChecked={moreOptions} onChange={() => setMoreOptions(!moreOptions)} />
            <FormLabel htmlFor="more-options" margin="0">
              <Text textStyle="body2-regular">Mais Opções</Text>
            </FormLabel>
          </FormControl>
          <Flex gap="3">
            <Button colorScheme="gray" variant="outline" onClick={onClose}>
              Cancelar
            </Button>
            <SaveButton />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export default withFormOffer(FormOffer);
