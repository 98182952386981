import http from '~/services/http';

import store from '../context';

const dispatchToHttp = (token: string) => {
  http.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const keepSessionAlive = () => {
  store.subscribe(() => {
    const { auth } = store.getState();

    if (auth.token) dispatchToHttp(auth.token);
  });
};

const getStoredSession = () => {
  const storage = localStorage.getItem('persist:storm');
  let session!: string | undefined;

  if (storage) {
    const { auth } = JSON.parse(storage);
    const payload = JSON.parse(auth);

    if ('token' in payload) session = payload.token;
  }

  return session;
};

export { keepSessionAlive, dispatchToHttp, getStoredSession };
