import styled from 'styled-components';

import { InputStatus } from '..';

export interface EditionParentProps {
  edited: InputStatus;
  bgColor: string;
  borderColor: string;
}

const EditionParent = styled.div<EditionParentProps>`
  ${({ edited, bgColor, borderColor }) => {
    const defaultStyles = `
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0px;
      height: 40px;
      flex: 1;
      user-select: none;
      border-top: 1px solid ${borderColor};
      background-color: ${bgColor};
      &:hover { cursor: text; }
      & > button { display: none };
      &:hover > button { display: block };
    `;

    if (edited === InputStatus.TOUCHED) {
      return `
        ${defaultStyles}
        background-color: rgba(255, 182, 36, 0.12);
        border: 2px dashed #FFB624 !important;
        borderRadius: 4px;
        & > button & > button:hover {
          background-color: #FFB624 !important;
        }
      `;
    }

    if (edited === InputStatus.COMMITTED) {
      return `
        ${defaultStyles}
        background-color: rgba(67, 131, 247, 0.08);
        border: 2px solid #4383F7 !important;
        border-radius: 4px;
        & > button & > button:hover {
          background-color: #4383F7;
        }
      `;
    }

    return defaultStyles;
  }}
`;

export default EditionParent;
