import { forwardRef } from 'react';

import { DialogActions, DialogActionsProps } from '@material-ui/core';

import useStyles from './styles';

const ModalFooter = forwardRef<HTMLDivElement, DialogActionsProps>(({ children, ...props }, ref) => {
  const classes = useStyles();

  return (
    <DialogActions ref={ref} classes={classes} {...props}>
      {children}
    </DialogActions>
  );
});

ModalFooter.displayName = 'ModalFooter';

export default ModalFooter;
