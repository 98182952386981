import { gql } from '@apollo/client';

export interface SimulationPLDProps {
  status: string;
}

export interface ImportSimulationPldsProps {
  errors?: string[];
  importSimulationPlds: SimulationPLDProps;
}

export interface PayloadProps {
  simulationId: string;
  file: File;
}

export interface ResponseProps {
  importSimulationPlds: ImportSimulationPldsProps;
  errors?: string[];
}

const IMPORT_SIMULATION_PLDS = gql`
  mutation importSimulationPlds($simulationId: ID!, $file: Upload!) {
    importSimulationPlds(input: { simulationId: $simulationId, file: $file }) {
      importSimulationPlds {
        status
      }
      errors
    }
  }
`;

export default IMPORT_SIMULATION_PLDS;
