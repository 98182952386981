import IntlCurrencyInput from 'react-intl-currency-input';

export type LocaleTag = 'pt-BR' | 'en-US';

export type IntlFormatterConfig = {
  locale: LocaleTag;
  formats: {
    number: {
      [key: string]: Intl.NumberFormatOptions;
    };
  };
};

export type IntlCurrencyInputProps = React.PropsWithChildren & {
  component: React.ElementType;
  defaultValue: number;
  value: number;
  max: number;
  currency: string;
  config: IntlFormatterConfig;
  autoFocus: boolean;
  autoSelect: boolean;
  autoReset: boolean;
  onChange: (...args: any[]) => void;
  onBlur: (...args: any[]) => void;
  onFocus: (...args: any[]) => void;
  onKeyPress: (...args: any[]) => void;
  inputRef: null | ((...args: any[]) => any) | Record<string, any>;
} & Record<string, any>;

export const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const CurrencyInput: React.ComponentType<Partial<IntlCurrencyInputProps>> = ({ component, ...props }) => (
  <IntlCurrencyInput currency="BRL" config={currencyConfig} component={component} {...props} />
);

// To mantain compatibility
(window as any).global = window;

export default CurrencyInput;
