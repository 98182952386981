import { createContext, useContext, useState } from 'react';

import { BoxProps } from '@material-ui/core';

import { SimulationItemProps } from '@graphql/query/simulationContracts';

import usePortfolio from '~/pages/Portfolio/usePortfolio';
import fastProvider from '~/utils/fastProvider';

import usePeriods from '../../../hooks/usePeriods';
import { TableColumn, TableHorizontalAxis, TableVerticalAxis } from '../../Table';
import { useSimulationTable } from '../../withSimulationTable';
import OperationInput from '../OperationInput';
import OperationsMenu from '../OperationMenu';
import OperationRemoval from '../OperationRemoval';
import { useSimulationOperations } from '../withGuarantee';
import useStyles from './styles';

export interface InputContextProps {
  simulationItem: SimulationItemProps;
  period: string;
  index: number;
}

const [withContext, useOperation] = fastProvider(() => {
  const { excludedContracts, setFilter } = usePortfolio();
  const [hidden, setHidden] = useState<string[]>(Object.keys(excludedContracts));

  const onOperationHidden = (hiddenId: string) => {
    const newHidden = hidden.includes(hiddenId) ? hidden.filter((id) => id !== hiddenId) : [...hidden, hiddenId];

    setFilter('excludedContracts', newHidden as any);
    setHidden(newHidden);
  };

  return {
    hidden,
    setHidden,
    onOperationHidden,
  };
});

const Context = createContext({} as InputContextProps);

const useOperationInput = () => {
  const context = useContext(Context);

  return context;
};

const Operation: React.ComponentType<BoxProps> = (props) => {
  const { periods } = usePeriods();
  const classes = useStyles();
  const { suffix } = useSimulationTable();
  const { id, simulationItems } = useSimulationOperations(({ operation }) => ({
    simulationItems: operation.simulationItems,
    id: operation.id,
  }));

  return (
    <TableHorizontalAxis position="relative" className={classes.parent} {...props}>
      <OperationsMenu />
      {periods.map((period, index) => {
        const simulationItem = simulationItems.find(({ competence }) => competence === period) as SimulationItemProps;

        return (
          <Context.Provider key={`${id}.${period}.${suffix}`} value={{ simulationItem, period, index }}>
            <OperationInput />
          </Context.Provider>
        );
      })}
      <TableVerticalAxis gridTemplateColumns="initial" width="100%">
        <TableColumn>&nbsp;</TableColumn>
        <TableColumn>&nbsp;</TableColumn>
      </TableVerticalAxis>
      <OperationRemoval />
    </TableHorizontalAxis>
  );
};

export { useOperation, useOperationInput };
export default withContext(Operation);
