import { forwardRef } from 'react';

import { FilledInput as MuiFilledInput, FilledInputProps as MuiFilledInputProps } from '@material-ui/core';

import useStyles from './styles';

const FilledInput = forwardRef<HTMLInputElement, MuiFilledInputProps>((props, ref) => {
  const classes = useStyles();

  return <MuiFilledInput ref={ref} classes={classes} fullWidth disableUnderline {...props} />;
});

export default FilledInput;
