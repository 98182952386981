import { gql } from '@apollo/client';

export interface OperationLimitsProps {
  purchaseLimit?: number;
  saleLimit?: number;
  startRange?: number;
  endRange?: number;
}

export interface PayloadProps {
  resourceId: string;
  operationLimits?: OperationLimitsProps[];
}

export interface ResponseProps {
  upsertOperationLimits: {
    status: string;
    errors: string[];
  };
}

const UPSERT_OPERATION_LIMITS = gql`
  mutation upsertOperationLimits($resourceId: ID!, $operationLimits: [OperationLimitsInput!]!) {
    upsertOperationLimits(input: { resourceId: $resourceId, operationLimits: $operationLimits }) {
      status
      errors
    }
  }
`;

export default UPSERT_OPERATION_LIMITS;
