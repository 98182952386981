import { useMutation } from '@apollo/client';
import { useToast } from '@tradener/lumen';

import QUOTES_REQUEST_MOVE, { ResponseProps, PayloadProps } from '@graphql/mutation/quotesRequestMove';

function useRequestMove(quoteId: string, onClose: () => void) {
  const toast = useToast();
  const [requestMove] = useMutation<ResponseProps, PayloadProps>(QUOTES_REQUEST_MOVE);

  return (description: string) =>
    requestMove({
      variables: { input: { quoteId } },
      onCompleted: () => {
        onClose();

        toast({ description, status: 'success' });
      },
      onError: ({ message }) => toast({ description: message, status: 'error' }),
    });
}

export default useRequestMove;
