import { gql } from '@apollo/client';

export interface MutationResponseProps {
  status: string;
}

export interface UpdateSimulationUsageFactorsProps {
  errors?: string[];
  updateSimulationUsageFactors: MutationResponseProps[];
}

export interface InputProps {
  simulationId: string | undefined;
  conventionalUsageFactor?: number;
  incentivizedUsageFactor?: number;
  file?: File;
}

export interface PayloadProps {
  input: InputProps;
}

export interface ResponseProps {
  updateSimulationUsageFactors: UpdateSimulationUsageFactorsProps;
}

const UPDATE_SIMULATION_USAGE_FACTORS = gql`
  mutation updateSimulationUsageFactors($input: UpdateSimulationUsageFactorsInput!) {
    updateSimulationUsageFactors(input: $input) {
      updateSimulationUsageFactors {
        status
      }
      errors
    }
  }
`;

export default UPDATE_SIMULATION_USAGE_FACTORS;
