import { Td as ChakraTd, BoxProps, useTheme } from '@tradener/lumen';

export const Td: React.FC<BoxProps> = ({ children, ...props }) => {
  const color = useTheme().colors.gray[900];

  return (
    <ChakraTd
      textStyle="body2-regular"
      px={3}
      py={2.5}
      fontWeight={400}
      color="gray.500"
      textTransform="none"
      border="0px"
      borderTop="solid 1px"
      borderColor={`${color}14`}
      {...props}
    >
      {children}
    </ChakraTd>
  );
};
