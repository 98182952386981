import type { Dispatch, SetStateAction } from 'react';

export type Transform = (value: any) => any;

export interface HandleChangeProps<Element = any> {
  <Value, Action extends SetStateAction<Value>>(
    dispatch: Dispatch<Action>,
    transform?: Transform,
  ): React.ChangeEventHandler<Element>;
}

const changeEvent: HandleChangeProps<HTMLInputElement> = (dispatch, transform) => (event) => {
  let value = event.target.value as unknown as Parameters<typeof dispatch>[0];

  if (transform) value = transform(value);

  dispatch(value);
};

export default changeEvent;
