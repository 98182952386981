import { pickByPaths, removeKeys } from '@tradener/lumen';
import { DateTime } from 'luxon';
import type { NonFunctionKeys } from 'utility-types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import type { PayloadProps } from '@graphql/query/portfolio';

export type PortfolioProps = Replace<PayloadProps, 'excludedContracts', { [key: string]: unknown }>;
export interface PortfolioHookProps extends PortfolioProps {
  setFilter: <Key extends NonFunctionKeys<PortfolioHookProps>>(key: Key, value: PortfolioHookProps[Key]) => void;
  resetField: (key: NonFunctionKeys<PortfolioHookProps>) => void;
  removeField: (key: NonFunctionKeys<PortfolioHookProps>) => void;
}

const dateTime = DateTime.now();

const initialState: PortfolioProps = {
  startYear: dateTime.year.toString(),
  endYear: dateTime.plus({ year: 1 }).year.toString(),
  tenantId: [],
  internalAccounts: [],
  energyType: [],
  priceType: [],
  expandedPeriods: [dateTime.year.toString()],
  contractFlags: [],
  excludedContracts: {},
  usageFactorByContract: false,
  usageFactorByEnergyType: false,
  usageFactor: false,
  accountTypes: [],
};

const getVariables = (state: PortfolioHookProps) => {
  const result = pickByPaths(
    state,
    'startYear',
    'endYear',
    'energyType',
    'periodType',
    'priceType',
    'operation',
    'expandedPeriods',
    'usageFactorByContract',
    'usageFactorByEnergyType',
    'usageFactor',
    'excludedContracts',
    'tenantId',
    'internalAccounts',
    'giro',
    'hasGiro',
    'contractFlags',
    'accountingPortfolio',
    'closeDateLte',
    'closeDateGte',
    'accountTypes',
  );

  return result;
};

const usePortfolio = create(
  persist<PortfolioHookProps>(
    (set) => ({
      ...initialState,
      tenantId: initialState.tenantId?.filter(Boolean),
      internalAccounts: initialState.internalAccounts?.filter(Boolean),
      setFilter: (key, value) => set({ [key]: value }),
      resetField: (key) => set({ [key]: initialState[key] }),
      removeField: (key) => set((prevState) => removeKeys(prevState, key)),
    }),
    { name: 'PORTFOLIO' },
  ),
);

export { getVariables };
export default usePortfolio;
