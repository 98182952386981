import styled, { css } from 'styled-components';

const Elipse = styled.span`
  display: inline-block;

  width: 16px;
  height: 16px;
  border-radius: 50%;

  margin-right: 8px;

  ${({ color }) => color
    && css`
      background-color: ${color};
    `}

  ${({ size }) => size
    && css`
      width: ${size}px;
      height: ${size}px;
    `}
`;

export default Elipse;
