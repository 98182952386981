import { SimulationItemProps, SimulationOperationsProps } from '@graphql/query/simulation';

const hasQuantityAndPrice = ({ quantityMwm, price }: SimulationItemProps) => quantityMwm && price;

const mergeOperations = (
  periods: string[],
  oldOperations: SimulationOperationsProps[],
  newOperations: SimulationOperationsProps[],
) => {
  const temporary = [] as SimulationOperationsProps[];

  newOperations.forEach(({ simulationItems: newSimulationItems, ...current }) => {
    if ('key' in current) {
      delete (current as any).key;
    }

    let temporarySimulationsItens = newSimulationItems?.filter(hasQuantityAndPrice).map(({ competence, quantityMwm, price }) => ({
      competence,
      quantityMwm,
      price,
    }));

    if (/temp_/.test(current.id)) {
      delete (current as Optional<typeof current, 'id'>).id;
    } else {
      const oldOperation = oldOperations?.find(({ id }) => id === current.id) as SimulationOperationsProps;
      const oldSimulationItems = oldOperation?.simulationItems || [];

      if (
        newSimulationItems.length === 0 &&
        current.operation === oldOperation?.operation &&
        current.submarket === oldOperation?.submarket &&
        current.energyType === oldOperation?.energyType &&
        current.priceType === oldOperation?.priceType
      ) {
        return;
      }

      newSimulationItems = newSimulationItems?.filter(({ competence }) => /-/.test(competence));

      const hiddenItems = oldSimulationItems?.filter(({ competence }) => !periods.includes(competence));

      temporarySimulationsItens = newSimulationItems
        .filter(hasQuantityAndPrice)
        .map(({ competence, quantityMwm, price }) => ({
          competence,
          quantityMwm,
          price,
        }))
        .concat(hiddenItems.map(({ competence, quantityMwm, price }) => ({ competence, quantityMwm, price })));
    }

    temporary.push({
      ...current,
      simulationItems: temporarySimulationsItens,
    });
  });

  return temporary;
};

export default mergeOperations;
