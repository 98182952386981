import { useContext, useEffect, useMemo, useState } from 'react';

import ContactList from '@DEPRECATED/components/ContactList';
import { InputLayout } from '@DEPRECATED/components/Form';
import Typeahead from '@DEPRECATED/components/Typeahead';
import VerticalScroll from '@DEPRECATED/components/VerticalScroll';
import ProposalsContext from '@DEPRECATED/context/Proposals/context';
import dot from 'dot-object';
import * as Yup from 'yup';
import Select from '@DEPRECATED/components/Form/Select';
import Contracts from './Contracts';

import OpportunityFooter from '../OpportunityFooter';
import { Container } from './styles';

import { CATEGORY } from '../keywords';
import DEFAULT_ACCOUNTS from '~/utils/getDefaultAccounts';

const schema = Yup.object().shape({
  type: Yup.string().required(),
  buyer: Yup.object().shape({
    id: Yup.string().required(),
    name: Yup.string().required(),
  }),
  seller: Yup.object().shape({
    id: Yup.string().required(),
    name: Yup.string().required(),
  }),
});

function Account(parent) {
  const { index, handleNext } = parent;
  const {
    mergeData,
    data: { opportunity, id, succeededId },
  } = useContext(ProposalsContext);
  const { type, channel, category, buyer, seller } = opportunity;
  const externalAccount = type === 'SALE' ? buyer : seller;

  function updateExternalAccount(value) {
    if (type === 'SALE') {
      mergeData('opportunity.buyer', value);
    } else {
      mergeData('opportunity.seller', value);
    }
  }

  function onChangePurchase({ currentTarget }) {
    const { dataset, textContent } = currentTarget;
    const { value } = dataset;

    if (type === 'SALE') {
      mergeData('opportunity.seller', { id: value, name: textContent });
    } else {
      mergeData('opportunity.buyer', { id: value, name: textContent });
    }
  }

  function handleChangeMerge(name) {
    return function event({ target }) {
      const { value } = target;
      const temp = {};

      dot.set(name, value, temp);

      mergeData(null, temp);
    };
  }

  function updateCategory({ target }) {
    const { value } = target;

    mergeData('opportunity.category', value);
    mergeData('lockCharges', false);
  }

  const disabledFields = useMemo(() => !!id || !!succeededId, [id, succeededId]);

  const disableNextTab = useMemo(() => !schema.isValidSync(opportunity), [opportunity]);

  const categoryOptions = useMemo(() => {
    return Object.entries(CATEGORY).map(([key, value]) => ({ value: key, label: value }));
  }, []);

  return (
    <>
      <Container>
        <VerticalScroll>
          <InputLayout label="Canal">
            <Select
              value={channel}
              onChange={handleChangeMerge('opportunity.channel')}
              options={[
                { value: 'MESA', label: 'Mesa' },
                { value: 'SALESFORCE', label: 'Salesforce', disabled: true },
                { value: 'BBCE', label: 'BBCE', disabled: true },
                { value: 'PORTAL', label: 'Portal', disabled: true },
              ]}
              disabled={disabledFields}
            />
          </InputLayout>
          <InputLayout label="Categoria">
            <Select value={category} options={categoryOptions} onChange={updateCategory} />
          </InputLayout>
          <InputLayout label={type === 'SALE' ? 'Vendedor' : 'Comprador'}>
            <Select
              value={type === 'SALE' ? seller.id : buyer.id}
              onChange={onChangePurchase}
              options={DEFAULT_ACCOUNTS.map((value) => ({
                  value: value.accountId,
                  label: value.name,
                }))}
              disabled={disabledFields || channel === 'PORTAL'}
            />
          </InputLayout>
          <InputLayout label={type === 'SALE' ? 'Comprador' : 'Vendedor'}>
            <Typeahead
              onSelect={updateExternalAccount}
              account={externalAccount}
              disabled={disabledFields || channel === 'PORTAL'}
            />
          </InputLayout>
          {externalAccount?.id && (
            <>
              <Contracts taxId={externalAccount?.taxId} operation={type} />
              <ContactList id={externalAccount.id} />
            </>
          )}
        </VerticalScroll>
      </Container>
      <OpportunityFooter index={index} handleNext={handleNext} labelNext="Produto" disableNext={disableNextTab} />
    </>
  );
}

export default Account;
