import { Card, Box, Flex, Text, BulletIcon, Avatar, Tooltip } from '@tradener/lumen';
import { DateTime } from 'luxon';

import { useDetails } from '../..';

const STATUS = {
  create: 'Criado',
  close: 'Fechado',
  update: 'Atualizado',
  propose: 'Proposto',
  reject: 'Rejeitado',
  expire: 'Expirado',
  cancel: 'Cancelado',
  succeed: 'Sucedido',
};

function LeftContent() {
  const { quote } = useDetails();

  return (
    <Card width="14.875rem" height="fit-content" bgColor="gray.50" gap="5" padding="6">
      <Flex width="full" direction="column" align="flex-start" rowGap="5">
        <Text textStyle="subheading2-semibold">Histórico da oferta</Text>
        <Flex gap="10" paddingInlineStart="2" width="full" height="full">
          <Flex flexDirection="column" justifyContent="flex-start" width="full" height="full">
            {quote?.auditLogs?.map((item, index) => (
              <Box key={item.createdAt + index}>
                <Flex justifyContent="space-between" gap="2" width="full">
                  <Flex flexDirection="column" alignItems="center" justifyContent="flex-start" height="full">
                    <Flex color="gray.500">
                      <BulletIcon width="1.75rem" height="1.75rem" />
                    </Flex>
                    {!(quote?.auditLogs?.length === 1 || quote?.auditLogs!.length - 1 === index) && (
                      <Flex alignItems="center" height="5.375rem" width="0.125rem" bgColor="gray.200" />
                    )}
                  </Flex>
                  <Flex flexDirection="column" justifyContent="flex-start" width="full" height="full">
                    <Text textStyle="body1-semibold">{STATUS[item.action]}</Text>
                    <Flex gap="2" alignItems="flex-start" width="full" overflow="hidden">
                      <Avatar size="xs" boxSize="5" colorScheme="gray" bgColor="gray.200" name={item.requester.email} />
                      <Flex width="full" overflow="hidden" flexDirection="column" gap="1">
                        <Flex textStyle="caption1-regular" maxWidth="80%">
                          <Tooltip
                            bgColor="gray.900"
                            color="gray.0"
                            borderRadius="base"
                            label={item.requester?.name ?? item.requester?.email}
                            hasArrow
                          >
                            <Text width="full" overflowX="clip" textOverflow="ellipsis" whiteSpace="nowrap">
                              {item.requester?.name ?? item.requester?.email}
                            </Text>
                          </Tooltip>
                        </Flex>
                        <Text
                          textStyle="caption1-regular"
                          textOverflow="ellipsis"
                          wordBreak="break-all"
                          overflow="hidden"
                          whiteSpace="nowrap"
                          color="gray.400"
                        >
                          {DateTime.fromISO(item.createdAt).setLocale('pt-BR').toFormat('dd LLL HH:mm')}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Box>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}

export default LeftContent;
