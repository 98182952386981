import { useMemo } from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

function OfferStatus({ type, status }) {
  const label = useMemo(() => (type === 'SALE' ? 'V' : 'C'), [type]);

  return <Container status={status}>{label}</Container>;
}

OfferStatus.propTypes = {
  type: PropTypes.oneOf(['PURCHASE', 'SALE']).isRequired,
  status: PropTypes.oneOf(['denied', 'rejected', 'proposed', 'closed', 'opened']).isRequired,
};

export default OfferStatus;
