import { Flex, useToken, Text } from '@tradener/lumen';
import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Legend, Tooltip, Bar, BarChart, LabelList } from 'recharts';

import CustomLegend from '../components/CustomLegend';
import CustomTooltip from '../components/CustomTooltip';
import TickLabelXAxis from '../components/TickLabelXAxis';
import { useTradingChart } from '../useTradingChart';

function FinancialBalance() {
  const [gray100, gray500, gray600, gray900, blue500, green200, purple500] = useToken('colors', [
    'gray.100',
    'gray.500',
    'gray.600',
    'gray.900',
    'blue.500',
    'green.200',
    'purple.500',
  ]);
  const { series } = useTradingChart();

  const config = (dataKey: string) => {
    if (dataKey === 'finalResult')
      return { iconColor: purple500, iconType: 'square', minimumFractionDigits: 0, maximumFractionDigits: 0 };
    if (dataKey === 'marketResult')
      return { iconColor: blue500, iconType: 'square', minimumFractionDigits: 0, maximumFractionDigits: 0 };
    if (dataKey === 'pldResult')
      return { iconColor: '#4EC5E8', iconType: 'square', minimumFractionDigits: 0, maximumFractionDigits: 0 };
  };

  if (!series?.length) return null;

  return (
    <Flex
      data-testid="financial-balance"
      flexDirection="column"
      bgColor="gray.0"
      height="28.125rem"
      borderRadius="xl"
      padding="5"
      flex="1"
      width="full"
      color={gray900}
    >
      <Flex flexDirection="column" justifyContent="center" gap="0.5">
        <Text textStyle="subheading1-semibold">Resultado mensal</Text>
        <Text textStyle="body2-regular" color="gray.500">
          R$ (mil)
        </Text>
      </Flex>
      <Flex
        direction="column"
        width="100%"
        height="100%"
        paddingBlock="1"
        sx={{
          '.recharts-text': { fill: gray500 },
          '.recharts-cartesian-axis-line, .recharts-brush > rect': { stroke: 'none' },
          '.recharts-cartesian-axis-tick-line, line': { stroke: gray100 },
        }}
        overflow="visible"
      >
        <ResponsiveContainer>
          <BarChart data={series} syncId="balanceId" margin={{ top: 25 }} barCategoryGap="8" barGap="1">
            <defs>
              <pattern id="dashed" width="5" height="5" patternUnits="userSpaceOnUse" patternTransform="rotate(-45)">
                <path d="M 0,4 l 8,0" stroke={green200} strokeWidth={2} />
              </pattern>
            </defs>
            <CartesianGrid vertical={false} strokeWidth={1} />
            <XAxis dataKey="competence" interval={0} tickLine={false} tick={<TickLabelXAxis mode="MONTH" />} />
            <YAxis
              orientation="left"
              stroke={blue500}
              tick={{ fill: gray600, fontSize: '0.75rem' }}
              tickMargin={16}
              interval={0}
              tickLine={false}
              scale="linear"
              domain={([dataMin, dataMax]) => {
                const absMax = Math.max(Math.abs(dataMin), Math.abs(dataMax));

                return [-(Number(dataMin) < 0 ? absMax * 1.5 : 0), absMax * 1.5];
              }}
              tickFormatter={(value) =>
                (value / 1000).formatCurrency('pt-BR', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
              }
            />
            <Tooltip
              cursor={{ stroke: `${gray900}14`, fill: `${gray900}14`, radius: 2 }}
              content={<CustomTooltip mode="MONTH" config={config} />}
            />
            <Bar name="Marcação a mercado" dataKey="marketResult" fill={blue500} stroke={blue500} radius={6}>
              <LabelList
                dataKey="marketResult"
                position="top"
                fontSize=".625rem"
                formatter={(value) =>
                  (value / 1000).formatCurrency('pt-BR', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                }
                style={{ fill: blue500 }}
              />
            </Bar>
            <Bar name="Projeção interna" dataKey="pldResult" fill="#4EC5E8" stroke="#4EC5E8" radius={6}>
              <LabelList
                dataKey="pldResult"
                position="top"
                fontSize=".625rem"
                formatter={(value) =>
                  (value / 1000).formatCurrency('pt-BR', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                }
                style={{ fill: '#4EC5E8' }}
              />
            </Bar>
            <Bar name="Posições fechadas" dataKey="finalResult" fill={purple500} stroke={purple500} radius={6}>
              <LabelList
                dataKey="finalResult"
                position="top"
                fontSize=".625rem"
                formatter={(value) =>
                  (value / 1000).formatCurrency('pt-BR', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                }
                style={{ fill: purple500 }}
              />
            </Bar>
            <Legend content={<CustomLegend config={config} />} />
          </BarChart>
        </ResponsiveContainer>
      </Flex>
    </Flex>
  );
}

export default FinancialBalance;
