import MuiInputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const InputAdornment = withStyles((theme) => ({
  root: {
    padding: '20px 5px',
    right: 0,
  },
  positionEnd: {
    marginLeft: 'unset',
  },
}))(MuiInputAdornment);

InputAdornment.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InputAdornment;
