import { pickByPaths } from '@tradener/lumen';

import { SimulationOperationsProps } from '@graphql/query/simulation';

const mergePeriods = (simulationOperations: SimulationOperationsProps[], periods: string[]) => {
  if (!simulationOperations) return [];

  return simulationOperations.map(({ simulationItems, ...simulationOperation }) => ({
    ...pickByPaths(simulationOperation, 'id', 'operation', 'submarket', 'energyType', 'priceType'),
    simulationItems: periods.map((period) => {
      const currentSimulationItem = simulationItems.find(({ competence }) => competence === period);
      const simulationItem = {
        competence: period,
        quantityMwm: '' as unknown as number,
        price: '' as unknown as number,
      };

      if (currentSimulationItem) {
        simulationItem.quantityMwm = currentSimulationItem.quantityMwm;
        simulationItem.price = currentSimulationItem.price;
      }

      return simulationItem;
    }),
  }));
};

export default mergePeriods;
