import { gql } from '@apollo/client';

export type DATE = string;

export type PRICE = number;

export interface BbceProductProps {
  id: string;
  name: string;
  locked: boolean;
  firstPrice?: number;
  minPrice?: number;
  maxPrice?: number;
  lastPrice?: number;
  lastPriceVariation: number;
  purchaseVolume: number;
  saleVolume: number;
  balanceVolume: number;
  resultValue: number;
  resultValueVariation?: number;
  internalResultValue?: number;
  avgPurchasePrice?: number;
  avgSalePrice?: number;
  totalOffers: number;
  priceHistory: [DATE, PRICE][];
}

export interface BbceProductFilterProps {
  endDate?: string;
  pinnedIds?: string[];
  productName?: string;
  restrictIntervalDate?: boolean;
  startDate?: string;
  tenantId?: string;
  internalAccountId?: string;
  userId?: string;
}

export interface BbceProductListProps {
  pinneds: BbceProductProps[];
  products: BbceProductProps[];
}

export interface PayloadProps {
  filter: BbceProductFilterProps;
  limit: number;
  offset: number;
}

export interface ResponseProps {
  bbceProducts: BbceProductListProps;
}

const PRODUCT_FRAGMENT = gql`
  fragment product on BbceProduct {
    id
    name
    locked
    firstPrice
    minPrice
    maxPrice
    lastPrice
    lastPriceVariation
    purchaseVolume
    saleVolume
    balanceVolume
    resultValue
    internalResultValue
    avgPurchasePrice
    avgSalePrice
    totalOffers
    priceHistory
  }
`;

const BBCE_PRODUCTS = gql`
  ${PRODUCT_FRAGMENT}

  query bbceProducts($filter: BbceProductFilter!, $limit: Int, $offset: Int) {
    bbceProducts(filter: $filter, limit: $limit, offset: $offset) {
      pinneds {
        ...product
      }
      products {
        ...product
      }
    }
  }
`;

export { PRODUCT_FRAGMENT };
export default BBCE_PRODUCTS;
