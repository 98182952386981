import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.ul`
  margin: 0;
  padding: 0;
  height: calc(100% - 56px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
`;
