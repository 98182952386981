import { useMemo, useContext } from 'react';

import ProposalsContext from '@DEPRECATED/context/Proposals/context';

import { useTheme } from '@material-ui/core';

import { FiTrash } from 'react-icons/fi';
import { MdFormatLineSpacing, MdMonetizationOn, MdFlashOn } from 'react-icons/md';

import { Input, RangeInput } from '@DEPRECATED/components/Form';
import { IconButton } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import { format, isSameMonth } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import PropTypes from 'prop-types';

import { Container, ButtonDelete, PeriodView } from './styles';

const LABEL = {
  12: 'Mensal',
  4: 'Trimestral',
  2: 'Semestral',
  1: 'Anual',
};

const LOCALE = { locale: ptBR };

function QuoteItem(props) {
  const {
    startDate,
    endDate,
    amount,
    index,
    requestedAmount,
    spreadAmount,
    maxDate,
    volume,
    lastItem,
    handleChangeDatePicker,
    handleVolume,
    handleRemove,
    handleEnergyOffer,
    handleAnnualSplitRange,
    volumeType,
    periodType,
    isFragmentedSplit,
    period,
  } = props;

  const { data } = useContext(ProposalsContext);

  const { palette: { background } } = useTheme();

  const showDeleteButton = useMemo(() => index !== 0 && lastItem && !isFragmentedSplit, [index, lastItem, isFragmentedSplit]);

  const showStaggerButton = useMemo(
    () => index === 0 && lastItem && periodType === 'LONG' && !isFragmentedSplit,
    [index, lastItem, periodType, isFragmentedSplit],
  );

  function handleInputBlur(e) {
    e.currentTarget.value = parseFloat(e.currentTarget.value).toFixed(2);
    handleEnergyOffer(e, e.currentTarget.name, index);
  }

  const parsedPeriod = useMemo(() => {
    if (isSameMonth(startDate, endDate)) {
      return `${format(startDate, 'MMM/yy', LOCALE)}`;
    }

    return `${format(startDate, 'MMM/yy', LOCALE)} à ${format(endDate, 'MMM/yy', LOCALE)}`;
  }, [startDate, endDate]);

  return (
    <Container>
      <div>
        {!isFragmentedSplit ? (
          <RangeInput startDate={startDate} endDate={endDate} index={index} maxDate={maxDate} onChange={handleChangeDatePicker} />
        ) : (
          <PeriodView style={{ backgroundColor: background.light }}>
            <span style={{ backgroundColor: background.medium }}>{LABEL[period]}</span>
            <strong>{parsedPeriod}</strong>
          </PeriodView>
        )}
      </div>

      <div>
        <Input
          label="Oferta"
          name="amount"
          placeholder="-"
          type="number"
          onChange={(e) => handleEnergyOffer(e, 'amount', index)}
          onBlur={handleInputBlur}
          value={amount}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ marginTop: 2 }}>
                <MdMonetizationOn size={16} />
              </InputAdornment>
            ),
          }}
        />
        <Divider />
        {data?.opportunity.category === 'TRD_TRIGGER' ? (
          <Input
            label="Spread"
            name="spreadAmount"
            placeholder="-"
            type="number"
            onChange={(e) => handleEnergyOffer(e, 'spreadAmount', index)}
            onBlur={handleInputBlur}
            value={spreadAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ marginTop: 2 }}>
                  <MdMonetizationOn size={16} />
                </InputAdornment>
              ),
            }}
          />) : (
            <Input
              label="Solicitado"
              name="requestedAmount"
              placeholder="-"
              type="number"
              onChange={(e) => handleEnergyOffer(e, 'requestedAmount', index)}
              onBlur={handleInputBlur}
              value={requestedAmount}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ marginTop: 2 }}>
                    <MdMonetizationOn size={16} />
                  </InputAdornment>
                ),
              }}
            />)
        }
      </div>
      <div>
        <Input
          label="MWm"
          name="MWm"
          placeholder="-"
          type="number"
          value={volume?.MWm ?? 0}
          disabled={volumeType === 'MWh' || isFragmentedSplit}
          onChange={(e) => handleVolume(e, 'MWm', index)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ marginTop: 2 }}>
                <MdFlashOn size={16} />
              </InputAdornment>
            ),
          }}
        />
        <Divider />
        <Input
          label="MWh"
          name="MWh"
          placeholder="-"
          type="number"
          value={volume?.MWh ?? 0}
          disabled={volumeType === 'MWm' || isFragmentedSplit}
          onChange={(e) => handleVolume(e, 'MWh', index)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ marginTop: 2 }}>
                <MdFlashOn size={16} />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div>
        {showStaggerButton && (
          <Tooltip title="Escalonar">
            <IconButton onClick={handleAnnualSplitRange}>
              <MdFormatLineSpacing size={16} />
            </IconButton>
          </Tooltip>
        )}
        {showDeleteButton && (
          <ButtonDelete onClick={handleRemove}>
            <FiTrash size={16} />
          </ButtonDelete>
        )}
      </div>
    </Container>
  );
}

QuoteItem.defaultProps = {
  period: undefined,
};

QuoteItem.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  maxDate: PropTypes.instanceOf(Date).isRequired,
  amount: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  requestedAmount: PropTypes.string,
  spreadAmount: PropTypes.string,
  lastItem: PropTypes.bool.isRequired,
  volume: PropTypes.shape({
    MWh: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    MWm: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  handleChangeDatePicker: PropTypes.func.isRequired,
  handleVolume: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleEnergyOffer: PropTypes.func.isRequired,
  handleAnnualSplitRange: PropTypes.func.isRequired,
  volumeType: PropTypes.oneOf(['MWm', 'MWh']).isRequired,
  periodType: PropTypes.oneOf(['SHORT', 'LONG']).isRequired,
  isFragmentedSplit: PropTypes.bool.isRequired,
  period: PropTypes.number,
};

export default QuoteItem;
