import ListItemIconRaw from '@material-ui/core/ListItemIcon';
import styled, { css } from 'styled-components';
import { IconButton } from '@material-ui/core';

export const ListItemIcon = styled(ListItemIconRaw)`
  && {
    min-width: 24px;
  }
`;

export const PersonalizedIconButton = styled(IconButton).attrs({})`
  ${({ theme }) => css`
    && {
      box-shadow: none;
      animation: none;
      transition: none;
      width: 32px;
      height: 32px;
      border-radius: 50%;

      &:hover {
        cursor: pointer;
        box-shadow: none;
        animation: none;
        transition: none;
        background-color: transparent;
      }
      svg {
      }
    }
  `}
`;
