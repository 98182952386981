import { createElement } from 'react';

import PropTypes from 'prop-types';

import TooltipContent from './TooltipContent';
import { Tooltip } from './styles';

function TooltipDetail(props) {
  const { children, data, component, ...rest } = props;

  const Content = createElement(
    component,
    {
      style: {
        width: '100%',
        cursor: data ? 'pointer' : 'default',
      },
    },
    children,
  );

  if (!data) return Content;

  return (
    <Tooltip arrow title={<TooltipContent data={data} />} {...rest}>
      {Content}
    </Tooltip>
  );
}

TooltipDetail.defaultProps = {
  data: undefined,
  component: 'div',
};

TooltipDetail.propTypes = {
  children: PropTypes.element.isRequired,
  component: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.number),
};

export default TooltipDetail;
