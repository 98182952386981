import { Flex, FlexProps, Text } from '@tradener/lumen';

export interface StatusProps extends FlexProps {
  title: string;
  value?: number;
}

export const textStatusColor = (value?: number) => (Number(value) >= 0 ? 'green.500' : 'red.500');

const Status: React.ComponentType<StatusProps> = ({ title, value, ...props }) => (
  <Flex
    rowGap="0.5"
    direction="column"
    borderRadius="xl"
    borderWidth="1px"
    borderColor="gray.0"
    py="4"
    px="5"
    bg="gray.0"
    {...props}
  >
    <Flex align="center" justify="space-between" textStyle="body2-regular" minWidth="14rem">
      <Text color="gray.900">{title}</Text>
    </Flex>
    <Text textStyle="h6-semibold" color={textStatusColor(value)}>
      {Number(value).formatCurrency('pt-br', { style: 'currency', currency: 'BRL', maximumFractionDigits: 0 })}
    </Text>
  </Flex>
);

export default Status;
