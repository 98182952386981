import { useQuery } from '@apollo/client';

import CONTRACT_FLAGS, { ResponseProps } from '@graphql/query/contractFlags';

function useContractFlags() {
  const { data, loading } = useQuery<ResponseProps>(CONTRACT_FLAGS);

  return {
    ...data,
    loading,
  };
}

export default useContractFlags;
