import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  ${({ theme }) => css`
    min-width: 112px;
    height: 136px;
    ${(theme?.palette?.type === 'light') && `background-color: #EEE` || `background-color: #373737`};

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    strong {
      font-size: 25px;
      font-weight: 700;
      margin-bottom: 10px;
      ${(theme?.palette?.type === 'light') && `color: #111` || `color: #EEE`};
    }
  `}
`;
