import { useMemo } from 'react';

import { MdTimer, MdTimerOff } from 'react-icons/md';

import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import isPast from 'date-fns/isPast';
import ptBR from 'date-fns/locale/pt-BR';
import parseISO from 'date-fns/parseISO';
import PropTypes from 'prop-types';

import { Container } from './styles';

function Timer({ date, status }) {
  const expired = useMemo(() => status !== 'closed' && isPast(parseISO(date)), [date]);

  const distance = useMemo(
    () =>
    formatDistanceStrict(parseISO(date), Date.now(), {
      locale: ptBR,
      addSuffix: true,
    }),
    [date],
  );

  const isHidden = useMemo(() => {
    const statusHidden = ['denied', 'opened'];

    return statusHidden.includes(status);
  }, [status]);

  const parsedDate = useMemo(() => parseISO(date).toLocaleString(), [date]);

  if (isHidden) {
    return (
      <Container expired>
        <span>-</span>
      </Container>
    );
  }

  return (
    <Container expired={expired}>
      {expired ? (
        <>
          <MdTimerOff size={20} />
          <span title={parsedDate}>Expirado</span>
        </>
      ) : (
        <>
          <MdTimer size={20} />
          <span title={parsedDate}>{distance}</span>
        </>
      )}
    </Container>
  );
}

Timer.propTypes = {
  date: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['denied', 'rejected', 'quoted', 'proposed', 'closed', 'opened']).isRequired,
};

export default Timer;
