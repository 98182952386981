import { memo } from 'react';

import PropTypes from 'prop-types';

import { Container, Content, Arrow } from './styles';

function Tab({ value, onClick, current, label }) {
  return (
    <Container>
      <Content value={value} onClick={() => onClick(value)} current={current}>
        {label}
      </Content>
      <Arrow />
    </Container>
  );
}

Tab.propTypes = {
  onClick: PropTypes.func.isRequired,
  current: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default memo(Tab);
