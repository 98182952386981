import DividerRaw from '@material-ui/core/Divider';
import IconButtonRaw from '@material-ui/core/IconButton';
import InputBaseRaw from '@material-ui/core/InputBase';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Paper)`
  margin-top: 16px;
  padding: 8px 16px 9px;

  &:first-child {
    margin-top: 0;
  }
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: auto 50px;
  align-items: center;
  padding-bottom: 8px;
`;

export const Label = styled.div``;

export const Input = styled(InputBaseRaw).attrs({
  inputProps: { 'aria-label': 'naked' },
})`
  && {
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: inherit;
    line-height: 1.36;
    letter-spacing: 0.7px;
    text-align: left;
    width: 100%;

    &.Mui-disabled {
    }
  }
`;

export const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Body = styled.div`
  padding-top: 2px;
`;

export const Textarea = styled(InputBaseRaw).attrs({
  inputProps: { 'aria-label': 'naked' },
})`
  && {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: 0.6px;
    text-align: left;
    width: 100%;

    &.Mui-disabled {
    }
  }
`;

export const IconButton = styled(IconButtonRaw)`
  && {
    font-size: 16px;
    padding: 8px 4px;

    &:hover {
      background-color: transparent;
    }
  }
`;

export const Divider = styled(DividerRaw)`
  && {
    &.MuiDivider-vertical {
      align-self: stretch;
      height: auto;
      margin: 8px auto;
    }
  }
`;
