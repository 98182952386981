import { useCallback, useEffect, useRef, useState } from 'react';

import { nanoid } from 'nanoid';

import createSocketSub, { Channels } from '~/messages/notification';

export interface OptionsProps {
  autoStart?: boolean;
  showId?: boolean;
}

export interface SendToChannelProps {
  [key: string]: unknown;
}

export interface SubscriptionProps {
  unsubscribe: () => void;
  send: (options: SendToChannelProps) => void;
}

const OPTIONS: OptionsProps = {
  autoStart: false,
  showId: false,
};

function useChannel<T>(channel: Channels, options = OPTIONS) {
  const config = { ...OPTIONS, ...options };
  const subscription = useRef<SubscriptionProps>();
  const sid = useRef(nanoid());
  const [data, setData] = useState<T>();

  const subscribeChannel = useCallback(
    (filter?: { [key: string]: unknown }) => {
      if (!subscription.current) {
        subscription.current = createSocketSub<T>({
          sid: sid.current,
          channel,
          filter,
          onMessage: setData,
        });
      }
    },
    [channel],
  );

  const sendMessage = (search: string) => {
    if (subscription.current) {
      subscription.current.send({ message: search, showId: !!config?.showId });
    }
  };

  const unsubscribeChannel = () => {
    if (subscription.current) {
      subscription.current.unsubscribe();
      subscription.current = undefined;
    }
  };

  const clearChannel = () => sendMessage('');

  useEffect(() => {
    if (config.autoStart) subscribeChannel();

    return () => {
      if (config.autoStart || subscription.current) unsubscribeChannel();
    };
  }, [config.autoStart, subscribeChannel]);

  return { data, sendMessage, subscribeChannel, unsubscribeChannel, clearChannel };
}

export default useChannel;
