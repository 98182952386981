import InfiniteScroll from 'react-infinite-scroll-component';

import { Box, BoxProps, CircularProgress, Typography } from '@material-ui/core';

import { ResponseProps } from '@graphql/query/simulationContracts';

import useColorMode from '~/hooks/useColorMode';
import usePortfolio from '~/pages/Portfolio/usePortfolio';

import usePeriods from '../../hooks/usePeriods';
import ContractLabel from '../ContractLabel';
import ContractPeriods from '../ContractPeriods';
import PeriodColumns from '../PeriodColumns';

import { Contracts } from '..';

import useStyles from '../styles';

const isStaging = process.env.REACT_APP_ROLLBAR_ENV === 'staging';

export interface ContractListProps extends BoxProps {
  data: ResponseProps | undefined;
  loading: boolean;
  onLoadMore: () => void;
  handleExpandedPeriods: (period: string) => void;
  operation: string;
  handleExcludedsContracts: (id: string, contractNumber: string) => void;
  excludedContractsTemp: { [id: string]: unknown };
}

const ContractList: React.ComponentType<ContractListProps> = ({
  data,
  loading,
  onLoadMore,
  handleExpandedPeriods,
  operation,
  handleExcludedsContracts,
  excludedContractsTemp,
  ...props
}) => {
  const { bodyInfinitescroll, columnContract, columnFactors } = useStyles();
  const { colorTernary } = useColorMode();
  const { periods } = usePeriods();
  const { operation: _, ...filterValues } = usePortfolio();

  if (loading)
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={isStaging ? 'calc(100% - 146px)' : 'calc(100% - 82px)'}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={isStaging ? 'calc(100vh - 290px)' : 'calc(100vh - 270px)'}
        >
          <CircularProgress size={30} />
        </Box>
      </Box>
    );

  return (
    <Box height={isStaging ? 'calc(100% - 146px)' : 'calc(100% - 82px)'} {...props}>
      <InfiniteScroll
        dataLength={data?.simulationContracts?.length ?? 0}
        hasMore={(data?.simulationContracts?.length ?? 0) % Contracts.LIMIT === 0}
        next={onLoadMore}
        height={isStaging ? 'calc(100vh - 290px)' : 'calc(100vh - 270px)'}
        loader={
          <Box display="flex" alignItems="center" position="sticky" left="0" justifyContent="center" pt="5px" pb="5px">
            <CircularProgress size={30} />
          </Box>
        }
      >
        <Box
          className={bodyInfinitescroll}
          gridTemplateColumns={
            `${
              (filterValues.usageFactorByContract || filterValues.usageFactorByEnergyType || filterValues.usageFactor) &&
              operation === 'sale'
                ? '540px'
                : '366px'
            }` + ` repeat(${periods.length}, 120px) 1fr 20px`
          }
          zIndex={3}
        >
          <Box className={columnContract} zIndex={2}>
            <Typography variant="body2" color="inherit" style={{ fontWeight: 500, paddingLeft: '40px' }}>
              Contrato
            </Typography>
            {operation === 'sale' && (
              <Box className={columnFactors}>
                {filterValues.usageFactorByContract && (
                  <Typography variant="body2" color="inherit" style={{ fontWeight: 500 }}>
                    C. Contrato
                  </Typography>
                )}
                {filterValues.usageFactorByEnergyType && (
                  <Typography variant="body2" color="inherit" style={{ fontWeight: 500 }}>
                    C. Fonte
                  </Typography>
                )}
                {(filterValues.usageFactorByEnergyType || filterValues.usageFactorByContract || filterValues.usageFactor) && (
                  <Typography variant="body2" color="inherit" style={{ fontWeight: 500 }}>
                    C. Aplicado
                  </Typography>
                )}
              </Box>
            )}
          </Box>
          <PeriodColumns periods={periods} handleExpandedPeriods={handleExpandedPeriods} />
        </Box>
        {data?.simulationContracts?.filter(Boolean).map((contract) => (
          <Box key={`${contract.id}-${contract.energyType}`}>
            <Box
              display="grid"
              gridTemplateColumns={
                `${
                  (filterValues.usageFactorByContract || filterValues.usageFactorByEnergyType || filterValues.usageFactor) &&
                  operation === 'sale'
                    ? '540px'
                    : '366px'
                }` + ` repeat(${periods.length}, 124px) 1fr 20px`
              }
              color={contract.id in excludedContractsTemp ? colorTernary('#5c5c5c', '#cbcbcb') : colorTernary('#CCC', '#262626')}
              style={{ width: 'fit-content', alignItems: 'center', justifyContent: 'flex-end', height: '40px' }}
            >
              <ContractLabel
                contract={contract}
                handleExcludedsContracts={handleExcludedsContracts}
                excludedContract={contract.id in excludedContractsTemp}
              />
              <ContractPeriods contract={contract} excludedContract={contract.id in excludedContractsTemp} periods={periods} />
            </Box>
          </Box>
        ))}
      </InfiniteScroll>
    </Box>
  );
};

export default ContractList;
