import { useState, useCallback, useEffect } from 'react';

import usePortfolio from '~/pages/Portfolio/usePortfolio';

const useExcludedsTemp = () => {
  const { excludedContracts } = usePortfolio();
  const [excludedContractsTemp, setExcludedContractsTemp] = useState<typeof excludedContracts>({});

  useEffect(() => {
    setExcludedContractsTemp({ ...excludedContracts });
  }, [excludedContracts]);

  const handleExcludedsContracts = useCallback(
    (contractId: string, contractNumber: string) => {
      setExcludedContractsTemp((prev) => {
        if (contractId in prev) {
          const { [contractId]: _, ...newExcludeds } = prev;

          return newExcludeds;
        }

        return { ...prev, [contractId]: contractNumber };
      });
    },
    [setExcludedContractsTemp],
  );

  const cancelHandleExcludedsContracts = useCallback(() => {
    setExcludedContractsTemp({ ...excludedContracts });
  }, [excludedContracts]);

  const removeAllExcludedContracts = useCallback(() => {
    setExcludedContractsTemp({});
  }, [setExcludedContractsTemp]);

  return {
    excludedContractsTemp,
    handleExcludedsContracts,
    cancelHandleExcludedsContracts,
    removeAllExcludedContracts,
  };
};

export default useExcludedsTemp;
