import { convertMWhToMWm, convertMWmToMWh } from '@DEPRECATED/utils';

export function updateFieldsVolume({ data, type, value = undefined }) {
  let convertFunction;
  const { volume } = { volume: data.volume };
  let newValue = value || volume[type];

  if (value === '') {
    volume.MWm = '';
    volume.MWh = '';

    return volume;
  }

  if (type === 'MWm') {
    convertFunction = convertMWmToMWh;
    newValue = Math.round(newValue * 1000000) / 1000000;
  } else {
    convertFunction = convertMWhToMWm;
    newValue = Math.round(newValue * 1000) / 1000;
  }

  const converted = convertFunction({
    endDate: data.endDate,
    startDate: data.startDate,
    value: newValue,
  });

  const inverseType = type === 'MWm' ? 'MWh' : 'MWm';

  volume[inverseType] = parseFloat(converted);
  volume[type] = parseFloat(newValue);

  return volume;
}

function updateLastMonth({ data, endDate }) {
  data.endDate = endDate;

  data.volume.MWh = convertMWmToMWh({
    value: data.volume.MWm,
    startDate: data.startDate,
    endDate: data.endDate,
  });
}

export function removeQuoteItem({ data }) {
  const { endDate } = data[data.length - 1];

  data.splice(-1, 1);

  updateLastMonth({
    data: data[data.length - 1],
    endDate,
  });
}
