import { gql } from '@apollo/client';

export interface PeriodProps {
  period: string;
  price: number;
  quantityMwm: number;
  totalAmount: number;
}

export type EnergyTypes = 'C' | 'I0' | 'I5' | 'I8' | 'I1' | 'INE5';

export type AccountingPortfolioTypes = 'TRADING' | 'WHOLESALE';

export interface ContractSubmarketProps {
  id: string;
  crmId: string;
  accountName: string;
  contractNumber: string;
  periodType: string;
  energyType: EnergyTypes;
  priceType: string;
  usageFactorByContract: number;
  usageFactorByEnergyType: number;
  periods: Array<PeriodProps>;
  giro: string;
  contractFlags?: Array<string>;
  accountingPortfolio?: AccountingPortfolioTypes;
  closeDateLte?: string;
  closeDateGte?: string;
}

export interface ContractsProps {
  n?: Array<ContractSubmarketProps>;
  ne?: Array<ContractSubmarketProps>;
  s?: Array<ContractSubmarketProps>;
  se?: Array<ContractSubmarketProps>;
}

export interface PortfolioContractsProps {
  purchaseContracts: ContractsProps;
  saleContracts: ContractsProps;
}

export interface DataProps {
  portfolio: PortfolioContractsProps;
}

export type { ResponseProps, PayloadProps } from './portfolio';

const CONTRACT_FRAGMENT = gql`
  fragment contractSubmarketPeriod on Period {
    period
    price
    quantityMwm
    totalAmount
  }

  fragment contractSubmarket on ContractSubmarket {
    id
    crmId
    contractNumber
    accountName
    periodType
    energyType
    priceType
    usageFactorByContract
    usageFactorByEnergyType
    usageFactor
    periods {
      ...contractSubmarketPeriod
    }
  }

  fragment contract on PortfolioContractOperation {
    se {
      ...contractSubmarket
    }
    s {
      ...contractSubmarket
    }
    ne {
      ...contractSubmarket
    }
    n {
      ...contractSubmarket
    }
  }
`;

export const saleContracts = gql`
  ${CONTRACT_FRAGMENT}

  query portfolio(
    $startYear: String!
    $endYear: String
    $energyType: [String!]
    $periodType: QuotePeriod
    $priceType: [QuotePrice!]
    $operation: String
    $expandedPeriods: [String!]
    $excludedContracts: [String!]
    $usageFactorByContract: Boolean!
    $usageFactorByEnergyType: Boolean!
    $usageFactor: Boolean!
    $tenantId: [ID!]!
    $giro: String
    $hasGiro: Boolean
    $contractFlags: [String!]
    $accountingPortfolio: ContractPortfolio
    $closeDateLte: ISO8601Date
    $closeDateGte: ISO8601Date
  ) {
    portfolio(
      startYear: $startYear
      endYear: $endYear
      energyType: $energyType
      periodType: $periodType
      priceType: $priceType
      operation: $operation
      expandedPeriods: $expandedPeriods
      excludedContracts: $excludedContracts
      usageFactorByContract: $usageFactorByContract
      usageFactorByEnergyType: $usageFactorByEnergyType
      usageFactor: $usageFactor
      tenantId: $tenantId
      giro: $giro
      hasGiro: $hasGiro
      contractFlags: $contractFlags
      accountingPortfolio: $accountingPortfolio
      closeDateLte: $closeDateLte
      closeDateGte: $closeDateGte
    ) {
      saleContracts {
        ...contract
      }
    }
  }
`;

export const purchaseContracts = gql`
  ${CONTRACT_FRAGMENT}

  query portfolio(
    $startYear: String!
    $endYear: String
    $energyType: [String!]
    $periodType: QuotePeriod
    $priceType: [QuotePrice!]
    $operation: String
    $expandedPeriods: [String!]
    $excludedContracts: [String!]
    $usageFactorByContract: Boolean!
    $usageFactorByEnergyType: Boolean!
    $usageFactor: Boolean!
    $tenantId: [ID!]!
    $giro: String
    $hasGiro: Boolean
    $contractFlags: [String!]
    $accountingPortfolio: ContractPortfolio
    $closeDateLte: ISO8601Date
    $closeDateGte: ISO8601Date
  ) {
    portfolio(
      startYear: $startYear
      endYear: $endYear
      energyType: $energyType
      periodType: $periodType
      priceType: $priceType
      operation: $operation
      expandedPeriods: $expandedPeriods
      excludedContracts: $excludedContracts
      usageFactorByContract: $usageFactorByContract
      usageFactorByEnergyType: $usageFactorByEnergyType
      usageFactor: $usageFactor
      tenantId: $tenantId
      giro: $giro
      hasGiro: $hasGiro
      contractFlags: $contractFlags
      accountingPortfolio: $accountingPortfolio
      closeDateLte: $closeDateLte
      closeDateGte: $closeDateGte
    ) {
      purchaseContracts {
        ...contract
      }
    }
  }
`;
