import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { fastProvider } from '@tradener/lumen';

import QUOTE_DETAILS, {
  ResponseProps as QuoteResponseProps,
  PayloadProps as QuotePayloadProps,
} from '@graphql/query/quoteDetails';

const [withDetails, useDetails] = fastProvider(() => {
  const { id } = useParams();
  const { data, loading } = useQuery<QuoteResponseProps, QuotePayloadProps>(QUOTE_DETAILS, {
    fetchPolicy: 'cache-and-network',
    skip: !id,
    variables: { id: id as string },
  });

  return { quote: data?.quote, loading };
});

export { withDetails, useDetails };
