import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.ul`
  ${({ theme }) => css`
    position: relative;
    ${(theme?.palette?.type === 'light') && `background-color: #FFF` || `background-color: #232323`};
    ${(theme?.palette?.type === 'light') && `color: rgba(38, 38, 38, 0.24)` || `color: #5c5c5c`};

    display: flex;
    min-height: 48px;
    height: 64px;
    justify-content: space-between;
    align-items: center;
    aling-self: center;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      flex: 1;
    }

    li:last-child svg {
      display: none;
    }
  `}
`;
