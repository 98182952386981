import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  row-gap: 16px;
`;

export const Left = styled.section`
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;

`;

export const Right = styled.section`
  display: flex;
  flex-direction: column;
  flex: 2;
`;

export const RightHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  label input + span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: unset;
  }


  input {
    align-self: center;
    margin: 0;
    accent-color: ${({ theme }) => theme.palette.primary.main};
  }

  span {
    align-self: center;
    margin: 0;
  }
`;

export const RightContent = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100%;

  .MuiOutlinedInput-root {
    max-width: 158px;
  }

  hr {
    display: block;
    width: 100%;
    margin: 10px 0;
  }
`;
