/* eslint-disable max-len */
import PropTypes from 'prop-types';

function PldIcon(props) {
  const { circleFill } = props;

  return (
    <g transform="translate(158 81)">
      <g transform="translate(-424 -24)">
        <circle cx="8" ou cy="8" r="8" transform="translate(424 24)" fill={circleFill} />
        <path
          d="M7.15137 10.623V14H4.75195V4.04688H8.72363C9.4847 4.04688 10.1569 4.18815 10.7402 4.4707C11.3281 4.7487 11.7816 5.14746 12.1006 5.66699C12.4242 6.18197 12.5859 6.76758 12.5859 7.42383C12.5859 8.39453 12.2373 9.17155 11.54 9.75488C10.8473 10.3337 9.89486 10.623 8.68262 10.623H7.15137ZM7.15137 8.77051H8.72363C9.18848 8.77051 9.54167 8.6543 9.7832 8.42188C10.0293 8.18945 10.1523 7.86133 10.1523 7.4375C10.1523 6.97266 10.027 6.60124 9.77637 6.32324C9.52572 6.04525 9.18392 5.90397 8.75098 5.89941H7.15137V8.77051Z"
          transform="translate(424 24)"
          fill="#000"
        />
      </g>
    </g>
  );
}

PldIcon.defaultProps = {
  circleFill: 'white',
};
PldIcon.propTypes = {
  circleFill: PropTypes.string,
};

export default PldIcon;
