import { gql } from '@apollo/client';

export interface MutationResponseProps {
  status: string;
}

export interface DeleteSimulationOperationProps {
  deleteSimulationOperation: MutationResponseProps;
}

export interface InputProps {
  simulationId: string;
  simulationOperationId: string;
}

export interface PayloadProps {
  input: InputProps;
}

export interface ResponseProps {
  deleteSimulationOperation: DeleteSimulationOperationProps;
  errors?: string[];
}

const DELETE_SIMULATION_OPERATION = gql`
  mutation deleteSimulationOperation($input: DeleteSimulationOperationInput!) {
    deleteSimulationOperation(input: $input) {
      deleteSimulationOperation {
        status
      }
      errors
    }
  }
`;

export default DELETE_SIMULATION_OPERATION;
