import { darken } from '@material-ui/core';
import styled from 'styled-components';

export const FunnelPartStyled = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 148px;

  justify-self: center;

  img {
    width: 100%;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    letter-spacing: 1.35px;
    color: white;
    text-shadow: 0px 3px 6px #0000004d;
    font-size: 38px;
    font-weight: 500;

    @media screen and (min-width: 600px) {
      font-size: 45px;
    }
  }

  @media screen and (min-width: 600px) {
    width: 240px;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 8px;
  overflow: hidden;
  & * {
    color: ${({ color }) => color};
  };
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ color }) => color};
`;

type LabelProps = {
  color: string;
};

export const Label = styled.span<LabelProps>`
  align-self: center;
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  ::before {
    content: '';
    width: 15px;
    height: 2px;
    background-color: ${({ color }) => color};
    margin-right: 8px;
  }

  @media screen and (min-width: 600px) {
    font-size: 18px;
  }
`;

export const Value = styled.span`
  display: inline-block;
  align-self: center;
  font-size: 14px;
  font-weight: 500;
  min-width: 40px;
  margin: 0 auto;
`;

export const Title = styled.span`
  display: block;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 18px;

  margin-top: 8px;
  margin-bottom: 24px;

  @media screen and (min-width: 600px) {
    font-size: 18px;
  }
`;
