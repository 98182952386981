import { useNavigate, useParams } from 'react-router-dom';

import { Flex, ArrowBackIcon, Text, IconButton, Skeleton, theme } from '@tradener/lumen';

import { Permissions } from '~/context/ducks/auth';
import useAuth from '~/hooks/useAuth';
import useColorMode from '~/hooks/useColorMode';
import useTrading from '~/pages/Trading/useTrading';
import Header from '~/theme/layout/Header';

import ListSearch from '../../../ListSearch';
import Users from '../../../Users';
import EnergyBalance from './EnergyBalance';
import ProductPrices from './ProductPrices';
import TradingVolumes from './TradingVolumes';
import { useProductAnalytics, withProductAnalytics } from './useProductAnalytics';

import ListItem from '..';

const subHeaderPosition = process.env.REACT_APP_ROLLBAR_ENV !== 'staging' ? '18' : '6.8125rem';

function ProductAnalytics() {
  const { id } = useParams() as { [key: string]: string };
  const { mode } = useColorMode();
  const navigate = useNavigate();
  const { permissions } = useAuth();
  const { setFilter } = useTrading();
  const { loading, series, product } = useProductAnalytics();
  const isAllowed = permissions.includes(Permissions.TRADING_ANALYTICS);
  const isEmpty = !loading && !series?.length;

  const onPrevious = () => {
    setFilter('productName', '');
    navigate('/trading/list');
  };

  if (!id || !isAllowed) onPrevious();

  return (
    <Flex flexDirection="column" maxWidth="calc(100dvw - 4.5rem)" width="full" height="full">
      <Header paddingInline="4" columnGap="3" align="center" />
      <Flex
        data-testid="subheader"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        width="full"
        gap="5"
        paddingInline="5"
        paddingTop="5"
        paddingBottom="2.5"
        height="auto"
        position="sticky"
        zIndex="banner"
        top={subHeaderPosition}
        sx={{ '&': { bgColor: mode === 'light' ? 'var(--lumen-colors-chakra-body-bg)' : '#171717' } }}
      >
        <Flex width="full" justifyContent="flex-start" gap="5" flex="1">
          <Flex justifyContent="flex-start" alignItems="center" gap="2" height="8">
            <IconButton
              aria-label="voltar"
              colorScheme="transparent"
              variant="ghost"
              width="fit-content"
              height="fit-content"
              minWidth="initial"
              boxSize="8"
              disabled={false}
              _hover={{ bgColor: 'gray.100' }}
              onClick={onPrevious}
            >
              <ArrowBackIcon boxSize="6" color="gray.900" />
            </IconButton>
            <Text textStyle="subheading1-medium">Análise do produto</Text>
          </Flex>
          <Skeleton isLoaded={!loading} borderRadius="md" width="74" height="8">
            {product && <ListSearch />}
          </Skeleton>
          <Users isLoaded={!loading && !!product} />
          <Skeleton isLoaded={!loading && !!product} borderRadius="md" width="50" height="8" />
        </Flex>
        <Flex width="full" flex="1">
          <Skeleton isLoaded={!loading} borderRadius="xl" height="20" width="full">
            {product && <ListItem key={product.id} {...product} />}
          </Skeleton>
        </Flex>
      </Flex>
      <Flex
        direction="column"
        width="full"
        gap="5"
        paddingBottom="5"
        paddingTop="2.5"
        paddingInline="5"
        position="relative"
        sx={{ '.recharts-tooltip-wrapper': { position: 'relative', zIndex: theme.zIndices.tooltip } }}
      >
        <Skeleton isLoaded={!loading} borderRadius="xl" height="25rem">
          {!isEmpty && <ProductPrices />}
        </Skeleton>
        <Skeleton isLoaded={!loading} borderRadius="xl" height="25rem">
          {!isEmpty && <TradingVolumes />}
        </Skeleton>
        <Skeleton isLoaded={!loading} borderRadius="xl" height="25rem">
          {!isEmpty && <EnergyBalance />}
        </Skeleton>
      </Flex>
    </Flex>
  );
}

export default withProductAnalytics(ProductAnalytics);
