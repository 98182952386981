import { useState } from 'react';

import { useLocation } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { pickByPaths } from '@tradener/lumen';

import BBCE_PRODUCTS, { ResponseProps, PayloadProps } from '@graphql/query/bbceProducts';

import fastProvider from '~/utils/fastProvider';

import useTrading, { usePagination } from '../../useTrading';

const [withProductsContext, useProducts] = fastProvider(() => {
  const location = useLocation();
  const [isExpanded, setExpanded] = useState<string | undefined>();
  const { filter, ...state } = useTrading();
  const pagination = usePagination();
  const isAnalytics = location.pathname?.includes('trading/list/analytics/');

  const { data, loading } = useQuery<ResponseProps, PayloadProps>(BBCE_PRODUCTS, {
    skip: !filter?.internalAccountId || !filter?.startDate || !filter?.endDate,
    initialFetchPolicy: 'cache-first',
    variables: pickByPaths({ ...state, ...pagination, filter }, 'filter', 'limit', 'offset'),
  });

  const offset = (data?.bbceProducts.products ?? []).length;

  const onUnfold = (id: string) => setExpanded(id);

  const onFold = () => setExpanded(undefined);

  const getProduct = (id: string) => {
    const isPinned = filter?.pinnedIds?.includes(id);

    return data?.bbceProducts[isPinned ? 'pinneds' : 'products'].find((product) => product.id === id);
  };

  const getProductByName = (name: string) => {
    const pinned = data?.bbceProducts.pinneds.find((product) => name && product.name.toUpperCase() === name.toUpperCase());

    return pinned ?? data?.bbceProducts?.products.find((product) => name && product.name.toUpperCase() === name.toUpperCase());
  };

  return { isExpanded, offset, data, isAnalytics, loading, onUnfold, onFold, getProduct, getProductByName };
});

export { withProductsContext };
export default useProducts;
