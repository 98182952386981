import { Flex, Text, useToken } from '@tradener/lumen';
import { DateTime } from 'luxon';

import DashIcon from '~/theme/icons/Dash';
import DashedSquareIcon from '~/theme/icons/DashedSquare';
import LineIcon from '~/theme/icons/Line';
import RoundedSquareIcon from '~/theme/icons/RoundedSquare';

const formatTooltip = (value: any, mode: string) => {
  const dateTime = DateTime.fromISO(value).setLocale('pt-BR');

  if (mode === 'HOUR') {
    return dateTime.hour + 1 + 'h';
  } else if (mode === 'DOW') {
    return dateTime.toFormat('ccc') + ' ' + dateTime.toFormat('dd MMM');
  } else if (mode === 'DAY') {
    return dateTime.toFormat('dd MMM');
  } else if (mode === 'MONTH') {
    return dateTime.toFormat('MMM yyyy');
  } else if (mode === 'YEAR') {
    return dateTime.toFormat('yyyy');
  }

  return '';
};

const CustomTooltip = ({ active, payload, label, customlabel, mode, config }: any) => {
  const [gray0, red500] = useToken('colors', ['gray.0', 'red.500']);

  if (active && payload && payload.length) {
    const sameValue = payload.every((item: any) => item?.value === payload?.[0]?.value);

    return (
      <Flex
        bgColor={gray0}
        py="2"
        px="3"
        flexDirection="column"
        justifyContent="space-between"
        width="full"
        borderRadius="lg"
        boxShadow="lg"
        gap="2"
      >
        <Text color="gray.900" textStyle="body2-medium">
          {customlabel ?? formatTooltip(label, mode)}
        </Text>
        <Flex flexDirection="column" justifyContent="space-between" gap="2">
          {payload?.map(({ value, dataKey, name }: any) => {
            const { iconColor, iconType, text, minimumFractionDigits, maximumFractionDigits, hidden, hiddenTooltip } =
              config(dataKey);
            const newIconColor = dataKey === 'balanceMwm' && Number(value) < 0 ? red500 : iconColor;
            const somePoint = (dataKey === 'pldResultAccumulated' || dataKey === 'marketResultAccumulated') && !!sameValue;

            if (hidden || hiddenTooltip || somePoint) return null;

            return (
              <Flex key={dataKey + value} alignItems="center" justifyContent="space-between" gap="5">
                <Flex alignItems="center" gap="2">
                  {iconType === 'square' && (
                    <Flex alignItems="center" width="3.5" height="3.5" color={newIconColor ?? 'gray.500'} marginRight="2">
                      <RoundedSquareIcon />
                    </Flex>
                  )}
                  {iconType === 'line' && (
                    <Flex alignItems="center" width="3.5" height=".125rem" color={iconColor ?? 'gray.500'} marginRight="2">
                      <LineIcon />
                    </Flex>
                  )}
                  {iconType === 'dashed' && (
                    <Flex alignItems="center" width="3.5" height=".125rem" color={iconColor ?? 'gray.500'} marginRight="2">
                      <DashIcon />
                    </Flex>
                  )}
                  {iconType === 'dashed-square' && (
                    <Flex alignItems="center" width="3.5" height="3.5" color={iconColor ?? 'gray.500'} marginRight="2">
                      <DashedSquareIcon />
                    </Flex>
                  )}
                  <Text color="gray.500" flex="1" textStyle="caption1-regular">
                    {text ?? name}
                  </Text>
                </Flex>
                <Text color="gray.900" textStyle="caption1-medium">
                  {Intl.NumberFormat('pt-BR', {
                    minimumFractionDigits: minimumFractionDigits ?? 2,
                    maximumFractionDigits: maximumFractionDigits ?? 2,
                  }).format(value)}
                </Text>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    );
  }

  return null;
};

export default CustomTooltip;
