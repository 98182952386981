/* eslint-disable max-lines */
import { useState, useEffect, useCallback } from 'react';

import { FiCalendar, FiSearch } from 'react-icons/fi';
import { toast } from 'react-toastify';

import { Choice } from '@DEPRECATED/components/Form';
import { removeKeysEmpty } from '@DEPRECATED/utils';
import { useTheme, InputAdornment, Select, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import { DatePicker } from '@material-ui/pickers';
import { Input } from '@rocketseat/unform';
import { parseISO, format } from 'date-fns';
import gql from 'graphql-tag';
import isEmpty from 'lodash/isEmpty';
import { usePopupState, bindTrigger, bindPopover } from 'material-ui-popup-state/hooks';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import client from '~/services/apollo';
import cable from '~/services/link/cable';

import FilterBox from './FilterBox';
import FilterButton from './FilterButton';
import TextField from './TextField';
import { Container, Form, Content, Footer, ExportButton } from './styles';
import Download from '~/theme/icons/Download';

function transform(value, originalvalue) {
  if (value) {
    let newValue = originalvalue.split('/');

    newValue = newValue.reverse().join('-');
    value = format(parseISO(newValue), 'yyyy-MM-dd');

    return this.isType(value) && value;
  }

  return value;
}

const schema = Yup.object().shape({
  accountName: Yup.string(),
  type: Yup.array().of(Yup.string()),
  stage: Yup.array()
    .transform(function stage(value, originalvalue) {
      if (originalvalue.includes('REJECTED')) value = [...value, 'DENIED'];

      return this.isType(value) && value;
    })
    .of(Yup.string()),
  product: Yup.array().of(Yup.string()),
  submarket: Yup.array().of(Yup.string()),
  priceType: Yup.array().of(Yup.string()),
  periodType: Yup.array().of(Yup.string()),
  hasGiro: Yup.string(),
  startDate: Yup.string().transform(transform),
  endDate: Yup.string().transform(transform),
  createdAtGte: Yup.string().transform(transform),
  createdAtLte: Yup.string().transform(transform),
  closedAtGte: Yup.string().transform(transform),
  closedAtLte: Yup.string().transform(transform),
  restrictIntervalDate: Yup.string(),
  categories: Yup.array().of(Yup.string()),
});

const GET_FILE = gql`
  query getFile($filter: QuotesFilter!) {
    quoteReport(filter: $filter) {
      reportUrl
    }
  }
`;

export default function Filter({ onFilter }) {
  const { palette } = useTheme();
  const [startDate, handleStartDate] = useState();
  const [endDate, handleEndDate] = useState();
  const [dateAtGte, handleDateAtGte] = useState();
  const [dateAtLte, handleDateAtLte] = useState();
  const [data, setData] = useState();
  const [filtered, setFiltered] = useState(false);
  const [filterDateType, setFilterDateType] = useState(data?.closedAtGte || data?.closedAtLte ? 'closedAt' : 'createdAt');

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'macro-filter',
  });

  function handleFilterDateType({ target: { value } }) {
    setFilterDateType(value);
  }

  function handleFilter(filter = {}) {
    let payload = removeKeysEmpty(filter);

    if (!isEmpty(payload)) {
      setData(payload);
      setFiltered(true);
    } else {
      setFiltered(false);
    }

    if (payload?.hasGiro) {
      payload = { ...payload, hasGiro: payload.hasGiro == 'true' };
    }

    if (payload?.restrictIntervalDate) {
      payload = { ...payload, restrictIntervalDate: payload.restrictIntervalDate == 'true' };
    }

    onFilter({ filter: payload });
    popupState.close();
  }

  const handleReset = useCallback(
    ({ refresh = false } = {}) => {
      handleEndDate(null);
      handleStartDate(null);
      handleDateAtGte(null);
      handleDateAtLte(null);
      setData(null);
      if (refresh) onFilter({ filter: {} });
    },
    [onFilter],
  );

  useEffect(() => {
    if (data && Object.values(data).reduce((acc, curr) => acc + curr)) {
      cable.connection.close({ allowReconnect: false });
    } else if (!cable.connection.isOpen()) {
      cable.connection.open();
    }
  }, [data]);

  async function getUrlFile() {
    try {
      let payload = data;

      if (payload?.hasGiro) {
        payload = { ...payload, hasGiro: payload.hasGiro == 'true' };
      }

      if (payload?.restrictIntervalDate) {
        payload = { ...payload, restrictIntervalDate: payload.restrictIntervalDate == 'true' };
      }

      const response = await client.query({
        query: GET_FILE,
        variables: {
          filter: payload,
        },
      });

      const { reportUrl } = response.data.quoteReport;

      window.open(reportUrl, '_blank');
    } catch (error) {
      toast.error('Não foi possivel exportar os dados.');
    }
  }

  useEffect(() => {
    handleReset();
  }, [handleReset]);

  return (
    <Container>
      {!!filtered && (
        <ExportButton onClick={getUrlFile} disableElevation>
          <Download />
        </ExportButton>
      )}

      <FilterButton isFiltered={filtered} {...bindTrigger(popupState)} />

      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 58,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Form schema={schema} onSubmit={handleFilter} initialData={data}>
          <Content>
            <FilterBox title="Conta">
              <TextField
                name="accountName"
                variant="outlined"
                placeholder="Procurar Conta..."
                autoComplete="off"
                InputProps={{
                  // eslint-disable-next-line
                  inputComponent: ({ inputRef, ...props }) => <Input {...props} />,
                  startAdornment: (
                    <InputAdornment position="start">
                      <FiSearch />
                    </InputAdornment>
                  ),
                }}
              />
            </FilterBox>
            <FilterBox title="Oportunidade">
              <Choice
                multiple
                name="type"
                options={[
                  { value: 'SALE', label: 'Venda' },
                  { value: 'PURCHASE', label: 'Compra' },
                ]}
              />
            </FilterBox>
            <FilterBox title="Estágio da Oferta" type="column">
              <Choice
                multiple
                name="stage"
                options={[
                  { value: 'OPENED', label: 'Aberto' },
                  { value: 'CLOSED', label: 'Fechado' },
                  { value: 'PROPOSED', label: 'Negociação' },
                  { value: 'REJECTED', label: 'Rejeitado' },
                ]}
              />
            </FilterBox>
            <FilterBox title="Tipo de Energia">
              <Choice
                multiple
                name="product"
                variant="outlined"
                options={[
                  { value: 'C', label: 'C' },
                  { value: 'I5', label: 'I5' },
                  { value: 'I1', label: 'I1' },
                  { value: 'I0', label: 'I0' },
                  { value: 'I8', label: 'I8' },
                  { value: 'INE5', label: 'INE5' },
                ]}
              />
            </FilterBox>
            <FilterBox title="Data de Criação/Fechamento">
              <Select
                variant="outlined"
                fullWidth
                onChange={handleFilterDateType}
                value={filterDateType}
                SelectDisplayProps={{ style: { textAlign: 'left' }}}
                style={{ marginBottom: 8 }}
              >
                <MenuItem value="createdAt" >Criado em</MenuItem>
                <MenuItem value="closedAt">Fechado em</MenuItem>
              </Select>
              <DatePicker
                value={dateAtGte}
                onChange={handleDateAtGte}
                inputVariant="outlined"
                variant="dialog"
                label="Inicio"
                format="dd/MM/yyyy"
                color="secondary"
                cancelLabel="cancelar"
                name={`${filterDateType}Gte`}
                id={`${filterDateType}Gte`}
                style={{
                  marginBottom: 8,
                }}
                TextFieldComponent={TextField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment style={{ background: palette.background.main }} position="end">
                      <FiCalendar />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
              <DatePicker
                value={dateAtLte}
                onChange={handleDateAtLte}
                inputVariant="outlined"
                variant="dialog"
                label="Fim"
                format="dd/MM/yyyy"
                color="secondary"
                cancelLabel="cancelar"
                name={`${filterDateType}Lte`}
                id={`${filterDateType}Lte`}
                TextFieldComponent={TextField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment style={{ background: palette.background.main }} position="end">
                      <FiCalendar />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </FilterBox>
            <FilterBox title="Período">
              <DatePicker
                value={startDate}
                onChange={handleStartDate}
                inputVariant="outlined"
                variant="dialog"
                label="Inicio"
                format="MM/yyyy"
                views={['year', 'month']}
                color="secondary"
                cancelLabel="cancelar"
                name="startDate"
                id="startDate"
                style={{
                  marginBottom: 8,
                }}
                TextFieldComponent={TextField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment style={{ background: palette.background.main }} position="end">
                      <FiCalendar />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
              <DatePicker
                value={endDate}
                onChange={handleEndDate}
                inputVariant="outlined"
                variant="dialog"
                label="Fim"
                format="MM/yyyy"
                views={['year', 'month']}
                color="secondary"
                cancelLabel="cancelar"
                name="endDate"
                style={{
                  marginBottom: 8,
                }}
                TextFieldComponent={TextField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment style={{ background: palette.background.main }} position="end">
                      <FiCalendar />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
              <Choice type="checkbox" name="restrictIntervalDate" options={[{ value: 'true', label: 'Restringir período' }]} />
            </FilterBox>
            <FilterBox title="Submercado">
              <Choice
                multiple
                name="submarket"
                variant="outlined"
                options={[
                  { value: 'SE', label: 'SE' },
                  { value: 'S', label: 'S' },
                  { value: 'NE', label: 'NE' },
                  { value: 'N', label: 'N' },
                ]}
              />
            </FilterBox>
            <FilterBox title="Modalidade de Preço" type="column">
              <Choice
                multiple
                name="priceType"
                options={[
                  { value: 'FIXO', label: 'Fixo' },
                  { value: 'PERCENT_PLD', label: '% PLD' },
                  { value: 'SPREAD', label: 'SPREAD' },
                ]}
              />
            </FilterBox>
            <FilterBox title="Tipo do Contrato" type="column">
              <Choice
                multiple
                name="periodType"
                options={[
                  { value: 'SHORT', label: 'Curto prazo' },
                  { value: 'LONG', label: 'Longo prazo' },
                ]}
              />
            </FilterBox>
            <FilterBox title="Operação de Giro" type="column">
              <Choice
                name="hasGiro"
                options={[
                  { value: 'false', label: 'Sem Giro' },
                  { value: 'true', label: 'Com Giro' },
                ]}
              />
            </FilterBox>
            <FilterBox title="Categoria" type="column">
              <Choice
                multiple
                name="categories"
                options={[
                  { value: 'TRD_DEFAULT', label: 'TRADENER (Padrão)' },
                  { value: 'TRD_FLAT', label: 'TRADENER (Flat)' },
                  { value: 'TRD_PLUS', label: 'TRD PLUS' },
                  { value: 'TRD_RETAILER_SET_PRICE', label: 'TRD VAREJISTA (Preço Definido)' },
                  { value: 'TRD_RETAILER_SET_PRICE_FLEX_FREE', label: 'TRD VAREJISTA (Preço Definido + Flex LIVRE)' },
                  { value: 'TRD_RETAILER_GUARANTEED_GAIN', label: 'TRD VAREJISTA (Ganho Garantido)' },
                  { value: 'TRD_RETAILER_GUARANTEED_GAIN_FLEX_FREE', label: 'TRD VAREJISTA (Ganho Garantido + Flex LIVRE)' },
                  { value: 'TRD_TRIGGER', label: 'TRD GATILHO' },
                ]}
              />
            </FilterBox>
          </Content>

          <Footer>
            <Button type="reset" variant="contained" color="secondary" onClick={handleReset} fullWidth>
              Limpar Filtro
            </Button>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Aplicar Filtro
            </Button>
          </Footer>
        </Form>
      </Popover>
    </Container>
  );
}

Filter.propTypes = {
  onFilter: PropTypes.func.isRequired,
};
