import { useLazyQuery } from '@apollo/client';
import {
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useTheme,
  Portal,
  PopoverArrow,
  Link,
} from '@tradener/lumen';

import ENERGY_CONTRACT, { PayloadProps, ResponseProps } from '@graphql/query/energyContractDetails';

import ContractsDetails from '~/pages/Portfolio/ContractsDetails';

import { useSimulationContract } from '../../context';

export interface ContractNumberProps {
  contractId: string;
  contractNumber: string;
}

const ContractNumber: React.ComponentType<ContractNumberProps> = ({ contractId, contractNumber }) => {
  const { colors, zIndices } = useTheme();
  const { contentRef } = useSimulationContract();
  const [findContract, { loading, data }] = useLazyQuery<ResponseProps, PayloadProps>(ENERGY_CONTRACT, {
    fetchPolicy: 'cache-and-network',
    variables: { id: contractId as string },
  });

  return (
    <Popover
      isLazy
      onOpen={findContract}
      trigger="click"
      placement="right"
      boundary="scrollParent"
      direction="rtl"
      colorScheme="gray"
    >
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Flex
              as={Link}
              fontSize="14px"
              fontWeight={600}
              lineHeight="21px"
              whiteSpace="nowrap"
              justifyContent="flex-start"
              alignItems="center"
              overflow="clip"
              textOverflow="ellipsis"
              width="full"
              height="fit-content"
              padding="1"
              borderRadius="0.375rem"
              data-testid="contract-details-trigger"
            >
              {contractNumber}
            </Flex>
          </PopoverTrigger>
          <Portal containerRef={contentRef}>
            <Flex position="absolute" zIndex={zIndices.popover + 1}>
              <PopoverContent
                position="relative"
                borderRadius="0.75rem"
                border="1px solid"
                borderColor={`${colors.gray[900]}1F`}
                _focus={{ boxShadow: 'md' }}
                _visited={{ boxShadow: 'md' }}
                data-testid="contract-details-popover"
              >
                <PopoverArrow bg="gray.0" borderColor={`${colors.gray[900]}1F`} />
                <PopoverBody borderRadius="0.75rem" padding="0" boxShadow="none" width="fit-content">
                  <ContractsDetails
                    isLoaded={!loading && !!data?.energyContract}
                    contract={data?.energyContract}
                    onClose={onClose}
                  />
                </PopoverBody>
              </PopoverContent>
            </Flex>
          </Portal>
        </>
      )}
    </Popover>
  );
};

export default ContractNumber;
