import Widget from '@DEPRECATED/components/Widget';
import WidgetHeader from '@DEPRECATED/components/WidgetHeader';
import styled, { AnyStyledComponent } from 'styled-components';

export const Container = styled(Widget as AnyStyledComponent)`
  max-height: 748px;
  padding: 0;
`;

export const Header = styled.section`
  height: 88px;
  padding: 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  section {
    display: flex;

    & > button:last-child {
      margin-left: 16px;
    }
  }
`;

export const Content = styled.section`
  padding: 16px;
`;

export const Divider = styled(WidgetHeader)`
  margin-bottom: 8px;

  span {
    font-size: 18px;
  }
`;

export const IconButton = styled.button`
  align-self: flex-end;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 0;
`;
