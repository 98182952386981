import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ zIndex, palette }) => {
  const isStaging = process.env.REACT_APP_ROLLBAR_ENV === 'staging';

  return {
    root: {
      display: 'flex',
      width: '72px',
      height: !isStaging ? '100dvh' : 'calc(100dvh - 37px)',
      padding: '16px',
      zIndex: zIndex.drawer + 3,
      backgroundColor: palette.background.default,
      justifyContent: 'space-between',
      left: 0,
    },
  };
});

export default useStyles;
