import { Skeleton, SkeletonCircle, MoreVertIcon } from '@tradener/lumen';

import OfferContainer from '../OfferContainer';
import OfferListItem from '../OperationList/OfferListItem';

function OfferSkeleton() {
  return (
    <OfferContainer>
      <OfferListItem>
        <Skeleton boxSize="9" />
      </OfferListItem>
      <OfferListItem>
        <Skeleton height="4" width="15.5" />
      </OfferListItem>
      <OfferListItem>
        <Skeleton height="4" width="36" />
      </OfferListItem>
      <OfferListItem>
        <Skeleton height="4" width="12.5" />
      </OfferListItem>
      <OfferListItem>
        <SkeletonCircle boxSize="8" />
      </OfferListItem>
      <OfferListItem>
        <Skeleton height="4" width="9.5" />
      </OfferListItem>
      <OfferListItem>
        <Skeleton height="4" width="12.5" />
      </OfferListItem>
      <OfferListItem>
        <Skeleton height="4" width="23.5" />
      </OfferListItem>
      <OfferListItem>
        <Skeleton height="4" width="20" />
      </OfferListItem>
      <OfferListItem>
        <MoreVertIcon boxSize="4" />
      </OfferListItem>
    </OfferContainer>
  );
}

export default OfferSkeleton;
