import { useMemo } from 'react';

import { DateTime } from 'luxon';

import usePortfolio from '~/pages/Portfolio/usePortfolio';

import { useSimulationTable } from '../SimulationTable/withSimulationTable';

const usePeriods = () => {
  const { startYear, endYear, expandedPeriods } = usePortfolio();
  const { loading, simulationPeriods } = useSimulationTable();

  const periods = useMemo(() => {
    const periodsSet: Set<string> = new Set([]);
    const fullPeriods: Array<string> = [];

    const startDate = DateTime.fromObject({ year: Number(startYear) });
    const endDate = DateTime.fromObject({ year: Number(endYear), month: 12 });

    let currentMonth = startDate;

    while (currentMonth <= endDate) {
      const yearIsExpanded = expandedPeriods?.includes(String(currentMonth.year));

      const format = yearIsExpanded ? 'yyyy-MM' : 'yyyy';

      periodsSet.add(currentMonth.toFormat(format));

      fullPeriods.push(currentMonth.toFormat('yyyy-MM'));

      if (currentMonth.month === 12) {
        periodsSet.add(currentMonth.toFormat('yyyy'));
      }

      currentMonth = currentMonth.startOf('month').plus({ months: 1 });
    }

    const periods = loading ? simulationPeriods!.map(({ period }) => period) : Array.from(periodsSet);

    return { startYear, endYear, periods, fullPeriods };
  }, [endYear, expandedPeriods, loading, simulationPeriods, startYear]);

  return periods;
};

export default usePeriods;
