/* eslint-disable no-underscore-dangle */
import { useMemo } from 'react';
import JwtDecode from 'jwt-decode';

import { QuoteStatus, JWTToken } from '@DEPRECATED/types';
import { Theme } from '@material-ui/core/styles';
import { differenceInHours } from 'date-fns';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import keys from 'lodash/keys';
import omitBy from 'lodash/omitBy';

type ColorStatus = {
  status: QuoteStatus;
  theme: Theme;
};

export function getColorByStatus({ status }: ColorStatus) {
  switch (status) {
    case QuoteStatus.DENIED:
    case QuoteStatus.REJECTED:
      return '#DB4C5D';
    case QuoteStatus.QUOTED:
    case QuoteStatus.PROPOSED:
      return '#F8BE15';
    case QuoteStatus.CLOSED:
      return '#15BE58';
    case QuoteStatus.OPENED:
    default:
      return '#868686';
  }
}

export function getStatus(status: QuoteStatus) {
  switch (status) {
    case QuoteStatus.DENIED:
    case QuoteStatus.REJECTED:
      return 'Rejeitado';
    case QuoteStatus.QUOTED:
    case QuoteStatus.PROPOSED:
      return 'Negociação';
    case QuoteStatus.CLOSED:
      return 'Fechado';
    case QuoteStatus.OPENED:
      return 'Aberto';
    default:
      return 'Inválido';
  }
}

export function getPermissions(token: string) {
  if (!token) return [];

  const content = JwtDecode<JWTToken>(token);
  const { permissions } = content.data;

  return permissions;
}

export function capitalize(string: string) {
  return string
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLocaleLowerCase())
    .join(' ');
}

type UseVariant = {
  component: {
    [key: string]: React.ReactNode | React.ReactSVG;
  };
  variant: string;
};

export function useVariant({ component, variant }: UseVariant) {
  const Component = useMemo(() => {
    const type = capitalize(variant);

    return component[type];
  }, [component, variant]);

  return Component;
}

type EnergyChange = {
  value: string;
  endDate: Date;
  startDate: Date;
};

export function convertMWhToMWm({ value, endDate, startDate }: EnergyChange): string | undefined {
  if (value === '') return undefined;
  const result = parseFloat(value) / (1 + differenceInHours(endDate, startDate));

  return result.toFixed(6);
}
export function convertMWmToMWh({ value, endDate, startDate }: EnergyChange): string | undefined {
  if (value === '') return undefined;

  const result = parseFloat(value) * (1 + differenceInHours(endDate, startDate));

  return result.toFixed(3);
}

function checkIsEmpty(value: unknown) {
  return isNil(value) || value === '' || (Array.isArray(value) && value.length < 1);
}

export function removeKeysEmpty(data: Record<string, unknown>) {
  return omitBy(data, checkIsEmpty);
}

type RemoveTypenameConfig = string | number | null | Record<string, unknown>;

export function removeTypename(item: RemoveTypenameConfig) {
  if (!item || typeof item === 'string' || typeof item === 'number') return;

  if (!isArray(item) && isObject(item)) {
    delete item.__typename;
  }

  keys(item).forEach((key) => removeTypename(item[key] as Record<string, unknown>));
}

export function letterExists(sentence: string) {
  return !!sentence.match(/([A-z]{1,})/i);
}

export const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
