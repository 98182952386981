import { forwardRef } from 'react';

import { useQuery } from '@apollo/client';
import { MenuItem, Select, Checkbox, Typography } from '@material-ui/core';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';

import TENANTS, { ResponseProps } from '@graphql/query/tenants';

import useColorMode from '~/hooks/useColorMode';
import usePortfolio from '~/pages/Portfolio/usePortfolio';

const Tenants = forwardRef<HTMLDivElement, FormControlProps>((props, ref) => {
  const { tenantId, setFilter } = usePortfolio();
  const { theme, colorTernary } = useColorMode();
  const { data: response } = useQuery<ResponseProps>(TENANTS, { fetchPolicy: 'cache-first' });

  const data = response?.tenants.filter(({ name }) => /^(pierp|tradener|tradener varejista)$/i.test(name)) ?? [];

  const handleChange: SelectInputProps['onChange'] = ({ target: { value } }) => {
    let tenants = [...(tenantId ?? []), value] as string[];

    if (value === 'all') {
      tenants = data.map(({ id }) => id);
    }

    if (tenantId?.includes(value as string)) {
      tenants = tenantId.filter((id) => id !== value);
    }

    setFilter('tenantId', tenants);
  };

  return (
    <FormControl ref={ref} variant="outlined" {...props}>
      <Select
        onChange={handleChange as any}
        color="primary"
        value={tenantId}
        renderValue={(values) => {
          const selecteds = (values as Array<string>).filter((id) => id !== 'all');
          const placeholder =
            selecteds.length === data.length
              ? 'Todos'
              : selecteds.map((current) => data.find(({ id }) => id === current)?.name).join(', ');

          return (
            <Typography variant="body2" style={{ color: theme.palette.grey[colorTernary(500, 900)], height: '16px' }}>
              {selecteds.length === 0 ? 'Selecione' : placeholder}
            </Typography>
          );
        }}
      >
        <MenuItem value="all" defaultChecked={tenantId?.length === data.length}>
          Todos
        </MenuItem>
        {data.map(({ id, name }) => (
          <MenuItem key={id} value={id} disabled={tenantId?.length === 1 && tenantId?.[0] === id}>
            <Checkbox
              size="small"
              defaultChecked={tenantId?.includes(id)}
              style={{
                pointerEvents: 'none',
                height: '20px',
                width: '20px',
                marginRight: '7px',
              }}
            />
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

export default Tenants;
