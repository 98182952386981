import { Flex, Text } from '@tradener/lumen';

import LineIcon from '~/theme/icons/Line';

const CustomLegend = () => (
  <Flex alignItems="center" justifyContent="center" width="100%" gap="4" paddingTop="2" marginInline="1.5" flexWrap="wrap">
    <Flex align="center" justify="flex-start" textStyle="body2-regular" gap="2">
      <Flex alignItems="center" width="3.5" height=".125rem" color="red.500">
        <LineIcon />
      </Flex>
      <Text color="gray.500" flex="1">
        PLD alto
      </Text>
    </Flex>
    <Flex align="center" justify="flex-start" textStyle="body2-regular" gap="2">
      <Flex alignItems="center" width="3.5" height=".125rem" color="blue.500">
        <LineIcon />
      </Flex>
      <Text color="gray.500" flex="1">
        PLD previsto
      </Text>
    </Flex>
  </Flex>
);

export default CustomLegend;
