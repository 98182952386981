import styled, { keyframes } from 'styled-components';

const pulse = keyframes`{
  0% {
    box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
  },
  50% {
      box-shadow: 0 0 0 30px rgba(255,255,255, 0);
  },

  100% {
      box-shadow: 0 0 0 0px rgba(255,255,255, 0);
  },
}
`;

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${({ theme }) => (theme?.palette?.type === 'light') &&
    `background-color: rgba(238, 238, 238, 0.8)` || `background-color: rgba(23, 23, 23, 0.8)`};
  z-index: 1104;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  .item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .circle {
    position: absolute;

    border-radius: 50%;
    border: 4px solid  ${({ theme }) => theme?.palette?.type === 'light' && `#AAA` || `#CCC` };

    width: 56px;
    height: 56px;

    animation: ${pulse} 3s infinite;
  }
`;
