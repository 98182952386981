import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  position: relative;
  height: calc(100% - 58px);

  .MuiInputBase-root {
    min-width: 380px;
  }

  hr {
    margin-bottom: 18px;

    :not(:first-child) {
      margin-top: 8px;
    }
  }
`;
