import { Box, BoxProps } from '@material-ui/core';

import Container from './Container';
import useStyles from './styles';

const Layout: React.FC<BoxProps> = ({ children, ...props }) => {
  const { wrapper } = useStyles();

  return (
    <Box className={wrapper}>
      <Container gridArea="content" {...props}>
        {children}
      </Container>
    </Box>
  );
};

export default Layout;
