import { Text } from '@tradener/lumen';

import { compareTwoEntries } from '~/utils/compare';

import OfferListItem from '../OfferListItem';
import useProductQuote from '../useProductQuote';

function OfferAmount() {
  const { stage, opportunity, amountTotal } = useProductQuote();
  const isRejectOrDenied = compareTwoEntries('REJECTED', stage) || compareTwoEntries('DENIED', stage);

  const getPrice = (amountTotal = '-') => {
    if (/SALE|PURCHASE/i.test(opportunity?.type)) return `${opportunity.type === 'SALE' ? '+' : '-'} ${amountTotal}`;

    return amountTotal;
  };

  const color = (() => {
    if (isRejectOrDenied) return 'gray.400';

    if (/SALE|PURCHASE/i.test(opportunity?.type)) return opportunity.type === 'SALE' ? 'green.500' : 'gray.900';

    return 'gray.900';
  })();

  return (
    <OfferListItem justifyContent="flex-end">
      <Text textStyle="body2-regular" color={color} textDecorationLine={isRejectOrDenied ? 'line-through' : undefined}>
        {getPrice(
          Math.abs(Number(amountTotal))?.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        )}
      </Text>
    </OfferListItem>
  );
}

export default OfferAmount;
