import useRestriction, { Restriction } from './useRestriction';

export type Rule<Args extends React.ComponentType[]> = {
  [Key in keyof Args]: React.ComponentType<Restriction<ComponentProps<Args[Key]>>>;
};

function useRule<T extends any[]>(...Components: T) {
  const { withBlocking } = useRestriction();

  return Components.map(withBlocking) as Rule<T>;
}

export default useRule;
