import { CheckIcon, Icon, Text, TimerIcon, TimerOffIcon } from '@tradener/lumen';
import { DateTime } from 'luxon';

import OfferListItem from '../OfferListItem';
import useProductQuote from '../useProductQuote';

export interface QuoteExpireProps {
  expireDate?: string;
  stage?: string;
}

function QuoteExpire() {
  const { stage, expireDate } = useProductQuote();

  const relative = /REJECTED/i.test(stage)
    ? 'Expirado'
    : expireDate
    ? DateTime.fromISO(expireDate).toRelative({ locale: 'pt-br' })
    : '-';

  return (
    <OfferListItem align="center" justify="flex-start" gap="1">
      <Icon as={/REJECTED/i.test(stage) ? TimerOffIcon : !relative?.includes('há') ? CheckIcon : TimerIcon} boxSize="4" />
      <Text textStyle="body2-regular">{relative?.replace(/há|em/g, '')}</Text>
    </OfferListItem>
  );
}

export default QuoteExpire;
