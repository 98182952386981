import { createTheme as createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import { ThemeProvider as MuiProvider } from '@material-ui/styles';
import { ThemeProvider as StyledProvider } from 'styled-components';

import useColorMode from '~/hooks/useColorMode';

import GlobalStyle from '../styles/global';

const Styled = StyledProvider as any;

export const grey = {
  50: '#f6f6f6',
  100: '#f2f2f2',
  200: '#e6e6e6',
  300: '#cccccc',
  400: '#A8A8A8',
  500: '#8c8c8c',
  600: '#5c5c5c',
  700: '#373737',
  800: '#262626',
  900: '#171717',
};

export const typography = {
  fontFamily: 'Archivo, Roboto, sans-serif',
  fontWeightBold: 700,
  fontWeightLight: 400,
  fontWeightMedium: 500,
  fontSize: 16,
  htmlFontSize: 16,
  letterSpacing: 'normal',
  body1: {
    fontSize: '16px',
    lineHeight: '21px',
  },
  body2: {
    fontSize: '14px',
    lineHeight: '19.6px',
  },
  caption: {
    fontSize: '12px',
    lineHeight: '15px',
  },
  h1: {
    fontSize: 54,
    fontWeight: 700,
    lineHeight: 1.5,
  },
  h2: {
    fontSize: 48,
    fontWeight: 700,
    lineHeight: 1.5,
  },
  h3: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: 1.5,
  },
  h4: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 1.5,
  },
  h5: {
    fontSize: 20,
    fontWeight: 700,
  },
  h6: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.5,
  },
};

const createTheme = (type: 'dark' | 'light') =>
  createMuiTheme({
    overrides: {
      MuiChip: {
        colorSecondary: {
          '&.unstyled:not(:hover)': {
            backgroundColor: 'initial !important',
          },
        },
      },
      MuiSelect: {
        root: {
          padding: '7px 16px',
          minHeight: 'initial',
        },
        outlined: {
          color: type === 'dark' ? '#FFF' : grey[900],
          borderColor: grey[200],
          textAlign: 'center',
          '&:focus': {
            backgroundColor: type === 'dark' ? grey[800] : '#fff',
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: '2px',
        },
        input: {
          padding: '10.5px 16px',
          '&::-webkit-input-placeholder, &::placeholder': {
            ...typography.body2,
            color: grey[type === 'dark' ? 300 : 700],
          },
        },
        notchedOutline: {
          border: `1px solid ${type === 'dark' ? grey[600] : '#DCDCDC'} !important`,
        },
      },
      MuiButton: {
        root: {
          height: '32px',
          borderRadius: '4px',
          textTransform: 'none',
          minWidth: '95px',
          padding: '10px 16px',
        },
        label: {
          ...typography.body2,
          letterSpacing: '0',
        },
        outlinedSecondary: {
          color: `${grey[type === 'dark' ? 300 : 800]}`,
          borderColor: `${grey[type === 'dark' ? 600 : 400]}`,
        },
      },
      MuiIconButton: {
        sizeSmall: {
          width: '32px',
          height: '32px',
          padding: '4px 6px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderRadius: '4px',
          borderColor: `${grey[type === 'dark' ? 600 : 400]}`,
        },
        colorSecondary: {
          color: `${grey[type === 'dark' ? 300 : 800]}`,
        },
      },
      MuiInputBase: {
        root: {
          borderRadius: '4px !important',
        },
      },
      MuiTable: {
        root: {
          tableLayout: 'fixed',
        },
      },
      MuiTableCell: {
        root: {
          padding: '0 8px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        },
      },
    },
    props: {
      MuiButton: {
        disableElevation: true,
      },
      MuiMenu: {
        disableAutoFocusItem: false,
        autoFocus: false,
      },
      MuiContainer: {
        disableGutters: true,
        maxWidth: false,
      },
      MuiTypography: {
        color: 'textPrimary',
      },
      MuiList: {
        disablePadding: true,
      },
      MuiModal: {
        disableAutoFocus: true,
        disableEnforceFocus: true,
        disableRestoreFocus: true,
      },
      MuiIconButton: {
        disableRipple: true,
      },
    },
    typography,
    palette: {
      grey,
      primary: {
        main: '#FA6800',
        dark: '#D45B00',
        light: '#EA7117',
        contrastText: '#FFFFFF',
      },
      secondary: {
        light: type === 'dark' ? '#EEE' : grey[900],
        main: '#8c8c8c',
        dark: '#7c7c7c',
        contrastText: '#DDD',
      },
      background: {
        default: type === 'dark' ? grey[900] : '#EEE',
        paper: type === 'dark' ? '#232323' : '#F7F9FA',
        light: type === 'dark' ? '#373737' : '#DDD',
        medium: '#7c7c7c',
        main: '#8c8c8c',
        dark: '#171717',
        even: type === 'dark' ? '#373737' : '#EEE',
        odd: type === 'dark' ? '#232323' : '#FFF',
      },
      action: {
        hoverOpacity: 0.08,
        focusOpacity: 0.12,
        selectedOpacity: 0.16,
        disabledOpacity: 0.38,
        activatedOpacity: 0.24,
      },
      type,
    },
    shadows: [
      'none',
      '0px 5px 15px rgba(0, 0, 0, 0.15), 0px 1px 6px rgba(24, 24, 43, 0.02), 0px 2px 16px rgba(0, 0, 0, 0.02)',
      '0px 5px 15px rgba(0, 0, 0, 0.14), 0px 1.17px 6px rgba(24, 24, 43, 0.02), 0px 2.01px 16.34px rgba(0, 0, 0, 0.02)',
      '0px 5px 16px rgba(0, 0, 0, 0.14), 0px 1.34px 6px rgba(24, 24, 43, 0.03), 0px 2.02px 16.69px rgba(0, 0, 0, 0.03)',
      '0px 5px 16px rgba(0, 0, 0, 0.13), 0px 1.52px 5px rgba(24, 24, 43, 0.03), 0px 2.03px 17.04px rgba(0, 0, 0, 0.03)',
      '0px 5px 16px rgba(0, 0, 0, 0.13), 0px 1.69px 5px rgba(24, 24, 43, 0.04), 0px 2.04px 17.39px rgba(0, 0, 0, 0.04)',
      '0px 5px 17px rgba(0, 0, 0, 0.12), 0px 1.86px 5px rgba(24, 24, 43, 0.05), 0px 2.05px 17.73px rgba(0, 0, 0, 0.05)',
      '0px 6px 17px rgba(0, 0, 0, 0.12), 0px 2.04px 5px rgba(24, 24, 43, 0.05), 0px 2.06px 18.08px rgba(0, 0, 0, 0.05)',
      '0px 6px 17px rgba(0, 0, 0, 0.11), 0px 2.21px 4px rgba(24, 24, 43, 0.06), 0px 2.07px 18.43px rgba(0, 0, 0, 0.06)',
      '0px 6px 18px rgba(0, 0, 0, 0.11), 0px 2.39px 4px rgba(24, 24, 43, 0.07), 0px 2.08px 18.78px rgba(0, 0, 0, 0.07)',
      '0px 6px 18px rgba(0, 0, 0, 0.11), 0px 2.56px 4px rgba(24, 24, 43, 0.07), 0px 2.09px 19.13px rgba(0, 0, 0, 0.07)',
      '0px 6px 18px rgba(0, 0, 0, 0.10), 0px 2.73px 4px rgba(24, 24, 43, 0.08), 0px 2.10px 19.47px rgba(0, 0, 0, 0.08)',
      '0px 6px 19px rgba(0, 0, 0, 0.10), 0px 2.91px 4px rgba(24, 24, 43, 0.09), 0px 2.11px 19.82px rgba(0, 0, 0, 0.09)',
      '0px 6px 19px rgba(0, 0, 0, 0.09), 0px 3.08px 3px rgba(24, 24, 43, 0.09), 0px 2.13px 20.17px rgba(0, 0, 0, 0.09)',
      '0px 6px 20px rgba(0, 0, 0, 0.09), 0px 3.26px 3px rgba(24, 24, 43, 0.10), 0px 2.14px 20.52px rgba(0, 0, 0, 0.10)',
      '0px 6px 20px rgba(0, 0, 0, 0.08), 0px 3.43px 3px rgba(24, 24, 43, 0.11), 0px 2.15px 20.86px rgba(0, 0, 0, 0.11)',
      '0px 6px 20px rgba(0, 0, 0, 0.08), 0px 3.60px 3px rgba(24, 24, 43, 0.11), 0px 2.16px 21.21px rgba(0, 0, 0, 0.11)',
      '0px 6px 21px rgba(0, 0, 0, 0.08), 0px 3.78px 3px rgba(24, 24, 43, 0.12), 0px 2.17px 21.56px rgba(0, 0, 0, 0.12)',
      '0px 6px 21px rgba(0, 0, 0, 0.07), 0px 3.95px 2px rgba(24, 24, 43, 0.13), 0px 2.18px 21.91px rgba(0, 0, 0, 0.13)',
      '0px 7px 21px rgba(0, 0, 0, 0.07), 0px 4.13px 2px rgba(24, 24, 43, 0.13), 0px 2.19px 22.26px rgba(0, 0, 0, 0.13)',
      '0px 7px 22px rgba(0, 0, 0, 0.06), 0px 4.30px 2px rgba(24, 24, 43, 0.14), 0px 2.20px 22.60px rgba(0, 0, 0, 0.14)',
      '0px 7px 22px rgba(0, 0, 0, 0.06), 0px 4.47px 2px rgba(24, 24, 43, 0.15), 0px 2.21px 22.95px rgba(0, 0, 0, 0.15)',
      '0px 7px 22px rgba(0, 0, 0, 0.05), 0px 4.65px 1px rgba(24, 24, 43, 0.15), 0px 2.22px 23.30px rgba(0, 0, 0, 0.157)',
      '0px 7px 23px rgba(0, 0, 0, 0.05), 0px 4.82px 1px rgba(24, 24, 43, 0.16), 0px 2.23px 23.65px rgba(0, 0, 0, 0.16)',
      '0px 7px 23px rgba(0, 0, 0, 0.05), 0px 5px 1px rgba(24, 24, 43, 0.17), 0px 2.25px 24px rgba(0, 0, 0, 0.17)',
    ],
  } as ThemeOptions);

const ThemeProvider: React.FC<React.PropsWithChildren<Omit<typeof MuiProvider, 'theme'>>> = ({ children, ...props }) => {
  const { mode } = useColorMode();
  const theme = createTheme(mode);

  return (
    <MuiProvider theme={theme} {...props}>
      <Styled theme={theme}>{children}</Styled>
      <GlobalStyle />
    </MuiProvider>
  );
};

export default ThemeProvider;
