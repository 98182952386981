import { useMemo } from 'react';

import DatePicker from '@DEPRECATED/components/Form/DatePicker';
import { timezone } from '@DEPRECATED/utils';
import { zonedTimeToUtc } from 'date-fns-tz';
import PropTypes from 'prop-types';

import { TextField } from './styles';

function Period({ period, isMonth }) {
  const format = useMemo(() => (isMonth ? 'MM/yyyy' : 'yyyy'), [isMonth]);

  const value = useMemo(() => zonedTimeToUtc(period, timezone), [period]);

  return (
    <DatePicker name="default" format={format} inputVariant="outlined" value={value} TextFieldComponent={TextField} readOnly />
  );
}

Period.propTypes = {
  period: PropTypes.string.isRequired,
  isMonth: PropTypes.bool.isRequired,
};

export default Period;
