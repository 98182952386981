import { darken, lighten, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette, zIndex }) => {
  const relay = palette.type === 'dark' ? lighten : darken;

  return {
    tableCell: {
      width: 110,
      minWidth: 110,
      padding: '6px 8px',
      border: 'none',
      color: palette.grey[palette.type === 'dark' ? 400 : 600],
      backgroundColor: palette.type === 'dark' ? palette.grey[900] : palette.common.white,
      fontWeight: 'normal',
      '&:last-child': {
        width: 'inherit',
        minWidth: 0,
        padding: 0,
      },
    },
    tableCellHead: {
      color: palette.common.white,
      backgroundColor: palette.primary.main,
      fontSize: 14,
      lineHeight: '21px',
      fontWeight: 'bold',
      cursor: 'pointer',
      '&:hover svg': {
        display: 'block',
      },
      '& div': {
        flexDirection: 'row-reverse',
      },
    },
    tableCellStrong: {
      fontWeight: 'bold',
      color: palette.common[palette.type === 'dark' ? 'white' : 'black'],
      backgroundColor: palette.grey[palette.type === 'dark' ? 800 : 100],
    },
    tableCellNegative: {
      color: palette.error.main,
    },
    tableCellPrevious: {
      backgroundColor: relay(palette.type === 'dark' ? palette.grey[900] : palette.common.white, 0.03),
    },
    tableCellHeadPrevious: {
      backgroundColor: relay(palette.primary.main, 0.03),
    },
    tableCellStrongPrevious: {
      backgroundColor: relay(palette.grey[palette.type === 'dark' ? 800 : 100], 0.03),
    },
    tableCellCurrent: {
      backgroundColor: relay(palette.type === 'dark' ? palette.grey[900] : palette.common.white, 0.075),
    },
    tableCellHeadCurrent: {
      backgroundColor: relay(palette.primary.main, 0.075),
    },
    tableCellStrongCurrent: {
      backgroundColor: relay(palette.grey[palette.type === 'dark' ? 800 : 100], 0.075),
    },
    tableCellStick: {
      width: 207,
      minWidth: 207,
      left: 0,
      position: 'sticky',
      zIndex: zIndex.appBar + 1,
      cursor: 'inherit',
      '&:hover svg': {
        display: 'inherit',
      },
      '& div': {
        flexDirection: 'inherit',
      },
    },
    iconUnfold: {
      transform: 'rotate(90deg)',
      display: 'none',
    },
  };
});

export default useStyles;
