import { gql } from '@apollo/client';

export interface ProductAnalyticsProps {
  timestamp: string;
  exposure?: number;
  firstPrice?: number;
  lastPrice?: number;
  maxPrice?: number;
  minPrice?: number;
  pldTrend?: number;
  pldTrendHigh?: number;
  quantityMwm?: number;
  operationMwm?: number;
  price?: number;
  type?: string;
}

export interface PayloadProps {
  bbceProductId: string;
  userId?: string;
}

export interface ResponseProps {
  productAnalytics: ProductAnalyticsProps[];
}

const PRODUCTS_ANALYTICS = gql`
  query productAnalytics($bbceProductId: ID!, $userId: ID) {
    productAnalytics(bbceProductId: $bbceProductId, userId: $userId) {
      ... on ProductBalance {
        timestamp
        firstPrice
        lastPrice
        maxPrice
        minPrice
        pldTrend
        pldTrendHigh
        quantityMwm
        exposure
      }
      ... on ProductOperation {
        timestamp
        operationMwm
        price
        type
      }
    }
  }
`;

export default PRODUCTS_ANALYTICS;
