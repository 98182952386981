import { useFormContext } from 'react-hook-form';

import { ButtonGroup, Button, Popover, PopoverTrigger, PopoverContent, PopoverBody, IconButton } from '@tradener/lumen';

import { InputProps } from '@graphql/mutation/upsertTradingQuote';

import ArrowDown from '~/theme/icons/ArrowDown';

import { useOffer } from '../context';

function SaveButton() {
  const { loading, onSubmit } = useOffer();
  const { handleSubmit, formState } = useFormContext<InputProps>();

  return (
    <ButtonGroup isAttached>
      <Popover gutter={4} placement="bottom-end" closeOnBlur closeOnEsc isLazy>
        <Button isLoading={loading} isDisabled={!formState.isValid || loading} onClick={handleSubmit(onSubmit)}>
          Salvar
        </Button>
        <PopoverTrigger>
          <IconButton aria-label="mais" isDisabled={!formState.isValid || loading} icon={<ArrowDown />} />
        </PopoverTrigger>
        {!loading && (
          <PopoverContent width="full" boxShadow="base">
            <PopoverBody
              key="menu-list"
              display="flex"
              flexDir="column"
              width="full"
              padding="2"
              bgColor="gray.0"
              borderColor="transparent"
              borderRadius="lg"
            >
              <Button
                width="full"
                colorScheme="gray"
                variant="ghost"
                textStyle="body2-regular"
                justifyContent={loading ? 'center' : 'flex-start'}
                isLoading={loading}
                isDisabled={!formState.isValid || loading}
                onClick={handleSubmit((prev) => onSubmit({ ...prev, saveAndReject: true }))}
              >
                Salvar e rejeitar
              </Button>
              <Button
                width="full"
                colorScheme="gray"
                variant="ghost"
                textStyle="body2-regular"
                justifyContent={loading ? 'center' : 'flex-start'}
                isLoading={loading}
                isDisabled={!formState.isValid || loading}
                onClick={handleSubmit((prev) => onSubmit({ ...prev, saveAndPropose: true }))}
              >
                Salvar e emitir
              </Button>
            </PopoverBody>
          </PopoverContent>
        )}
      </Popover>
    </ButtonGroup>
  );
}

export default SaveButton;
