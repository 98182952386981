import { useEffect, useRef, useMemo, useState } from 'react';

import { MdSettings, MdUndo } from 'react-icons/md';

import ConfirmationDialog from '@DEPRECATED/components/ConfirmationDialog';
import { DialogContent } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import ExpandQuoteItems from './ExpandQuoteItems';
import QuoteItem from './QuoteItem';
import { Container } from './styles';
import useQuoteItemState, { useQuoteItemStateTypes, useQuoteItemStateTypesDefaultsProps } from './useQuoteItemState';

const schema = Yup.array(
  Yup.object().shape({
    subItems: Yup.array().min(1).required(),
  }),
);

function QuoteItemList(props) {
  const [expandOpen, setExpandOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const ref = useRef();

  const { initialState, volumeType, periodType, startDate, endDate, onUnmount } = props;

  const {
    quoteItems,
    isExpanded,
    handleChangeDatePicker,
    handleVolume,
    handleRemove,
    handleEnergyOffer,
    handleAnnualSplitRange,
    overwrite,
    reset,
  } = useQuoteItemState({
    initialState,
    startDate,
    endDate,
  });

  const isFragmentedSplit = useMemo(() => schema.isValidSync(quoteItems), [quoteItems]);

  function toggleExpand() {
    setExpandOpen((prevState) => !prevState);
  }

  function handleOpenConfirmation() {
    setOpenDialog(true);
  }

  function handleCloseConfirmation(confirmation) {
    setOpenDialog(false);

    if (confirmation) reset();
  }

  useEffect(() => {
    ref.current = quoteItems;
    return () => setTimeout(100);
  }, [onUnmount, quoteItems]);

  useEffect(() => {
    return () => {
      onUnmount(ref.current);
    };
  }, []);

  return (
    <>
      <Container data-testid="quote-item-list">
        {quoteItems.map((quoteItem, index, array) => (
          <QuoteItem
            key={quoteItem.id}
            startDate={quoteItem.startDate}
            endDate={quoteItem.endDate}
            amount={quoteItem.amount}
            requestedAmount={quoteItem.requestedAmount}
            spreadAmount={quoteItem.spreadAmount}
            volume={quoteItem.volume}
            maxDate={array[index + 1]?.endDate ?? quoteItem.endDate}
            index={index}
            lastItem={array.length - 1 === index}
            handleChangeDatePicker={handleChangeDatePicker}
            handleVolume={handleVolume}
            handleRemove={handleRemove}
            handleEnergyOffer={handleEnergyOffer}
            handleAnnualSplitRange={handleAnnualSplitRange}
            volumeType={volumeType}
            periodType={periodType}
            isFragmentedSplit={isFragmentedSplit}
            period={quoteItem?.group}
          />
        ))}
      </Container>

      <ExpandQuoteItems
        quoteItems={quoteItems}
        open={expandOpen}
        onClose={toggleExpand}
        onSave={overwrite}
        volumeType={volumeType}
      />

      {(isExpanded || isFragmentedSplit) && (
        <Tooltip title="Customizar" aria-label="customize">
          <Fab
            size="small"
            color="primary"
            aria-label="customize"
            style={{
              position: 'absolute',
              bottom: 12,
              right: 22,
              height: 32,
              width: 34,
            }}
            onClick={toggleExpand}
          >
            <MdSettings size={18} />
          </Fab>
        </Tooltip>
      )}

      {isFragmentedSplit && (
        <Tooltip title="Restaurar" aria-label="restore">
          <Fab
            size="small"
            color="secondary"
            aria-label="restore"
            onClick={handleOpenConfirmation}
            style={{
              position: 'absolute',
              bottom: 18,
              right: 64,
              height: 32,
              width: 34,
            }}
          >
            <MdUndo size={18} />
          </Fab>
        </Tooltip>
      )}

      <ConfirmationDialog
        id="actions-dialog-confirmation"
        open={openDialog}
        onClose={handleCloseConfirmation}
        title="Atenção"
        fullWidth
      >
        <DialogContent
          dividers
          style={{
            minHeight: 80,
          }}
        >
          Você deseja redefinir o escalonamento?
        </DialogContent>
      </ConfirmationDialog>
    </>
  );
}

QuoteItemList.defaultProps = {
  initialState: useQuoteItemStateTypesDefaultsProps.initialState,
  onUnmount: () => ({}),
};

QuoteItemList.propTypes = {
  initialState: useQuoteItemStateTypes.initialState,
  volumeType: PropTypes.oneOf(['MWm', 'MWh']).isRequired,
  periodType: PropTypes.oneOf(['SHORT', 'LONG']).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  onUnmount: PropTypes.func,
};

export default QuoteItemList;
