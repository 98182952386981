import { getColorByStatus } from '@DEPRECATED/utils';
import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  min-height: 32px;
  border-radius: 24px;
  background-color: ${getColorByStatus};
  font-weight: 700;
  font-size: 14px;
  color: white;

  margin: auto;

  text-transform: uppercase;
  cursor: default;
  ${({ theme }) => (theme?.palette?.type === 'light') &&
    `color: #FFF` || `color: #222`};
`;
