import { Box, BoxProps, Button, useTheme } from '@material-ui/core';

export interface ContractApplyButtonProps extends BoxProps {
  onCancel: () => void;
}

const ApplyButton: React.ComponentType<ContractApplyButtonProps> = ({ onClick, onCancel, ...props }) => {
  const { palette, zIndex } = useTheme();

  return (
    <Box
      zIndex={zIndex.appBar + 3}
      position="sticky"
      height="64px"
      bottom="0"
      marginTop="-64px"
      marginRight="18px"
      boxShadow="0px -4px 12px rgba(0, 0, 0, 0.12)"
      bgcolor={`${palette.type === 'dark' ? '#111' : '#FFF'}`}
      {...props}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        padding="12px 20px"
        gridColumnGap="30px"
      >
        <Button
          variant="outlined"
          onClick={onCancel}
          disableElevation
          style={{ height: '40px', backgroundColor: '#FFF', color: '#111' }}
        >
          Cancelar
        </Button>
        <Button
          variant="outlined"
          disableElevation
          style={{ height: '40px', color: '#FFF', backgroundColor: '#4383F7' }}
          onClick={onClick}
        >
          Aplicar
        </Button>
      </Box>
    </Box>
  );
};

export default ApplyButton;
