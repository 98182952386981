import { gql, DocumentNode } from '@apollo/client';

export interface UpdateEnergyQuoteExtraInputProps {
  id: string;
  bbceProductTransferId?: string;
  giro?: string;
  notes?: string;
  externalId?: string;
  swap?: boolean;
}

export interface UpdateEnergyQuoteExtraProps<Quote> {
  quote?: Quote;
}

export interface PayloadProps {
  input: UpdateEnergyQuoteExtraInputProps;
}

export interface ResponseProps<Quote> {
  updateEnergyQuoteExtra: UpdateEnergyQuoteExtraProps<Quote>;
}

const UPDATE_ENERGY_QUOTE_EXTRA = (fragment: DocumentNode) => gql`
  ${fragment}

  mutation updateEnergyQuoteExtra($input: UpdateEnergyQuoteExtraInput!) {
    updateEnergyQuoteExtra(input: $input) {
      quote {
        ...quote
      }
    }
  }
`;

export default UPDATE_ENERGY_QUOTE_EXTRA;
