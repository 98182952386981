import { ApolloQueryResult, useQuery } from '@apollo/client';
import { useToast } from '@tradener/lumen';

import LIST_OPERATION_LIMITS, { ResponseProps, PayloadProps } from '@graphql/query/operationLimits/list';

import { Filter } from '../../types/Filter';
import { OPERATION_LIMITS_TYPE } from '../../types/OperationTypes';

export const initialFilterState: Filter = { type: 'COUNTERPART' as OPERATION_LIMITS_TYPE, search: '' };

interface ListOperationLimitsProps {
  loading: boolean;
  data: ResponseProps | undefined;
  refetch: (variables?: Partial<PayloadProps>) => Promise<ApolloQueryResult<ResponseProps>>;
  fetchMore: (options: { variables: Partial<PayloadProps> }) => Promise<ApolloQueryResult<ResponseProps>>;
}

const useListOperationLimits = () => {
  const toast = useToast();
  const { data, loading, refetch, fetchMore } = useQuery<ResponseProps, PayloadProps>(LIST_OPERATION_LIMITS, {
    fetchPolicy: 'cache-first',
    variables: { offset: 0, filter: initialFilterState, limit: 50 },
    onError: ({ message }) => {
      if (message !== 'not_found') {
        toast({ title: 'Erro', status: 'error', description: message || 'Erro ao carregar lista de limites!' });
      }
    },
  });

  return {
    data,
    loading,
    refetch,
    fetchMore,
  } as ListOperationLimitsProps;
};

export default useListOperationLimits;
