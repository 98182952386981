import { useMemo } from 'react';

import Chart from 'react-apexcharts';

import Elipse from '@DEPRECATED/components/Elipse';
import { listEnergyTypeGroupByOperation } from '@DEPRECATED/pages/Portfolio/PortfolioHelper';
import TooltipDetail from '@DEPRECATED/pages/Portfolio/TooltipDetail';
import { timezone } from '@DEPRECATED/utils';
import { numberFormatOrReturnDash } from '@DEPRECATED/utils/format';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { ptBR } from 'date-fns/locale';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';

import { getOptions, getSeries } from './OperationHelper';
import {
  Container,
  ContentTotal,
  Total,
  GraphContent,
  ContentDetails,
  Details,
  Title,
  Value,
  ChartLabels,
  Label,
  Graph,
  GraphDescription,
  Difference,
  DifferencePercent,
} from './styles';

function formatPeriod(period) {
  if (!period) return '';

  if (period.length === 4) {
    const date = zonedTimeToUtc(`${period}-01-01`, timezone);

    return format(date, 'yyyy');
  }

  const date = zonedTimeToUtc(`${period}-01`, timezone);

  return format(date, 'MMMyy', {
    locale: ptBR,
  }).toUpperCase();
}

function Operation({ isAveragePrice, data, operation, color }) {
  const type = useMemo(() => (isAveragePrice ? 'averagePrice' : 'quantityMwm'), [isAveragePrice]);

  const operationData = useMemo(() => data && data[operation], [data, operation]);

  const currentPeriod = useMemo(() => formatPeriod(data?.period), [data]);

  const previousPeriod = useMemo(() => formatPeriod(operationData?.previousYear.period), [operationData]);

  const currentValue = operationData?.quantityMwm;
  const previousValue = operationData?.previousYear.quantityMwm;

  const operationDecimalPlaces = useMemo(() => (isAveragePrice ? 2 : 1), [isAveragePrice]);

  const totalDecimalPlaces = useMemo(() => (isAveragePrice ? 1 : 0), [isAveragePrice]);

  const parsedTotal = useMemo(
    () => numberFormatOrReturnDash(operationData?.[type], totalDecimalPlaces, totalDecimalPlaces),
    [operationData, type, totalDecimalPlaces],
  );

  const parsedOperationConventional = useMemo(
    () => numberFormatOrReturnDash(operationData?.con?.[type], operationDecimalPlaces, operationDecimalPlaces),
    [type, operationData, operationDecimalPlaces],
  );

  const parsedOperationEncouraged = useMemo(
    () => numberFormatOrReturnDash(operationData?.inc?.[type], operationDecimalPlaces, operationDecimalPlaces),
    [type, operationData, operationDecimalPlaces],
  );

  const tooltipData = useMemo(
    () => (!isAveragePrice ? data && listEnergyTypeGroupByOperation(data, operation) : undefined),
    [data, operation, isAveragePrice],
  );

  const options = useMemo(() => getOptions(color), [color]);

  const series = useMemo(
    () =>
      getSeries({
        currentValue,
        previousValue,
        currentPeriod,
        previousPeriod,
      }),
    [currentValue, previousValue, currentPeriod, previousPeriod],
  );

  const differencePercent = useMemo(
    () => `${numberFormatOrReturnDash(((currentValue - previousValue) / previousValue) * 100, 1, 1)} %`,
    [currentValue, previousValue],
  );

  const difference = useMemo(
    () =>
      `${numberFormatOrReturnDash(currentValue - previousValue, operationDecimalPlaces, operationDecimalPlaces)} ${
        isAveragePrice ? 'R$/MWh' : 'MWm'
      }`,
    [currentValue, isAveragePrice, operationDecimalPlaces, previousValue],
  );

  return (
    <Container>
      <ContentTotal>
        <Total>{parsedTotal}</Total>
        <Graph>
          <GraphContent>
            <Chart options={options} series={series} type="area" height={42} width={96} />
            <ChartLabels>
              <Label>{previousPeriod}</Label>
              <Label>{currentPeriod}</Label>
            </ChartLabels>
          </GraphContent>
          <GraphDescription>
            <DifferencePercent>{differencePercent}</DifferencePercent>
            <Difference>{difference}</Difference>
          </GraphDescription>
        </Graph>
      </ContentTotal>
      <ContentDetails>
        <TooltipDetail data={tooltipData?.con} placement="left">
          <Details>
            <Elipse color="#F9B850" />
            <Title>Convencional</Title>
            <Value>{parsedOperationConventional}</Value>
          </Details>
        </TooltipDetail>
        <TooltipDetail data={tooltipData?.inc} placement="left">
          <Details>
            <Elipse color="#6A51F6" />
            <Title>Incentivada</Title>
            <Value>{parsedOperationEncouraged}</Value>
          </Details>
        </TooltipDetail>
      </ContentDetails>
    </Container>
  );
}

Operation.fragments = {
  operation: gql`
    fragment OperationFields on Operation {
      quantityMwm
      averagePrice
      inc {
        quantityMwm
        averagePrice
        energyType {
          i0 {
            quantityMwm
            averagePrice
          }
          i1 {
            quantityMwm
            averagePrice
          }
          i5 {
            quantityMwm
            averagePrice
          }
          i8 {
            quantityMwm
            averagePrice
          }
        }
      }
      con {
        quantityMwm
        averagePrice
        energyType {
          c {
            quantityMwm
            averagePrice
          }
          ine5 {
            quantityMwm
            averagePrice
          }
          ine8 {
            quantityMwm
            averagePrice
          }
        }
      }
      previousYear {
        averagePrice
        period
        quantityMwm
      }
    }
  `,
};

Operation.defaultProps = {
  isAveragePrice: false,
  data: {},
  color: null,
};

Operation.propTypes = {
  isAveragePrice: PropTypes.bool,
  operation: PropTypes.string.isRequired,
  color: PropTypes.string,

  data: PropTypes.shape({
    quantityMwm: PropTypes.number,
    averagePrice: PropTypes.number,
    period: PropTypes.string,
    inc: PropTypes.shape({
      quantityMwm: PropTypes.number,
      averagePrice: PropTypes.number,
    }),
    con: PropTypes.shape({
      quantityMwm: PropTypes.number,
      averagePrice: PropTypes.number,
    }),
  }),
};

export default Operation;
