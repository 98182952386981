import { matchRoutes, NavLink, useLocation } from 'react-router-dom';

import {
  Header as AppBar,
  Flex,
  FlexProps,
  Heading,
  Highlight,
  ComponentWithAs,
  IconProps,
  Icon,
  Tab,
  TabList,
  Tabs,
  CalculateIcon,
  PieChartIcon,
  ViewStreamIcon,
} from '@tradener/lumen';

import useColorMode from '~/hooks/useColorMode';
import useRestriction from '~/hooks/useRestriction';

const createNavigable = (to: string, title: string, icon: ComponentWithAs<'svg', IconProps>) => ({ to, title, icon });

const Header: React.ComponentType<FlexProps> = (props) => {
  const { restrict } = useRestriction();
  const location = useLocation();
  const { mode } = useColorMode();

  const config = {
    portfolio: {
      title: 'Portfólio',
      items: [
        createNavigable('/portfolio/table', 'Tabela', ViewStreamIcon),
        restrict('PORTFOLIO', createNavigable('/portfolio/chart', 'Dashboard', PieChartIcon)),
        restrict('SIMULATION', createNavigable('/portfolio/simulation', 'Simulação', CalculateIcon)),
      ].filter(Boolean),
    },
    book: {
      title: 'Book',
      items: [createNavigable('/book/list', 'Lista', ViewStreamIcon), createNavigable('/book/chart', 'Gráfico', PieChartIcon)],
    },
    trading: {
      title: 'Trading',
      items: [
        createNavigable('/trading/list', 'Lista', ViewStreamIcon),
        restrict('TRADING_DASHBOARD', createNavigable('/trading/dashboard', 'Dashboard', PieChartIcon)),
      ].filter(Boolean),
    },
    limits: {
      title: 'Limites',
      items: [].filter(Boolean),
    },
  }[location.pathname.slice(1).split('/')[0] as 'trading'];

  const index =
    config &&
    (Object.values(config)[1] as ReturnType<typeof createNavigable>[]).findIndex(({ to }) =>
      matchRoutes([{ path: `${to}/*` }], location),
    );

  return (
    <AppBar
      data-testid="header"
      position="sticky"
      height="18"
      top={process.env.REACT_APP_ROLLBAR_ENV !== 'staging' ? '0' : '37px'}
      px="5"
      zIndex="banner"
      sx={{ '&': { bgColor: mode === 'light' ? 'var(--lumen-colors-chakra-body-bg)' : '#171717' } }}
    >
      <Flex columnGap="8" align="center">
        <Heading textStyle="h6-semibold" color="gray.900" fontSize="2xl" fontWeight="regular" whiteSpace="nowrap">
          <Highlight query="storm" styles={{ fontWeight: 'semibold', color: 'gray.900' }}>
            {`Storm ${config?.title}`}
          </Highlight>
        </Heading>
        <Tabs variant="solid-rounded" index={index}>
          <TabList color="gray.500">
            {config?.items.map(({ title, icon, to }: any) => (
              <Tab as={NavLink} key={to} to={to} layerStyle="tab-left-icon">
                <Icon as={icon} boxSize="5" />
                {title}
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </Flex>
      <Flex {...props} />
    </AppBar>
  );
};

export default Header;
