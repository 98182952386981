import { gql } from '@apollo/client';

export type DATE = string;

export type PRICE = number;

export interface BbceProductProps {
  id: string;
  name: string;
  locked: boolean;
  firstPrice?: number;
  minPrice?: number;
  maxPrice?: number;
  lastPrice?: number;
  lastPriceVariation: number;
  purchaseVolume: number;
  saleVolume: number;
  balanceVolume: number;
  resultValue: number;
  resultValueVariation?: number;
  internalResultValue?: number;
  avgPurchasePrice?: number;
  avgSalePrice?: number;
  totalOffers: number;
  priceHistory: [DATE, PRICE][];
}

export interface PayloadProps {
  id: string;
  userId?: string;
}

export interface ResponseProps {
  findBbceProduct: BbceProductProps;
}

const FIND_BBCE_PRODUCTS = gql`
  query findBbceProduct($id: ID!, $userId: ID) {
    findBbceProduct(id: $id, userId: $userId) {
      id
      name
      locked
      firstPrice
      minPrice
      maxPrice
      lastPrice
      lastPriceVariation
      purchaseVolume
      saleVolume
      balanceVolume
      resultValue
      internalResultValue
      avgPurchasePrice
      avgSalePrice
      totalOffers
      priceHistory
    }
  }
`;

export default FIND_BBCE_PRODUCTS;
