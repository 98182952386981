import { isValidElement, useMemo } from 'react';

import { Skeleton } from '@material-ui/lab';
import { Flex, FlexProps } from '@tradener/lumen';

import useColorMode from '~/hooks/useColorMode';
import { typography, grey } from '~/theme';

import { useSimulationTable } from '../../withSimulationTable';

export interface TableColumnProps extends Omit<FlexProps, 'children'> {
  emphasis?: boolean;
  animated?: boolean;
  showSkeleton?: boolean;
  children: React.ReactNode;
}

const TableColumn = ({ emphasis, children, animated, showSkeleton = true, ...props }: TableColumnProps) => {
  const { colorTernary } = useColorMode();
  const { loading } = useSimulationTable();

  const color = useMemo(() => {
    if (!isValidElement(children) && typeof children === 'string' ? /-/.test(children) : children! < 0) {
      return '#DB4C5D';
    }

    return grey[colorTernary(50, 800)];
  }, [children, colorTernary]);

  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-end"
      px="4"
      color={color}
      fontWeight={emphasis ? 600 : 400}
      borderBottom={`1px solid ${grey[colorTernary(700, 200)]}`}
      {...typography.body2}
      {...props}
    >
      {loading && animated && showSkeleton ? <Skeleton width={102} height={20} /> : children}
    </Flex>
  );
};

TableColumn.defaultProps = {
  animated: true,
};

export default TableColumn;
