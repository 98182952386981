import { useState } from 'react';

import { FolderOpen, GetApp } from '@material-ui/icons';
import { Flex, FlexProps, Button, IconButton, AddIcon, CircularProgress } from '@tradener/lumen';

import useColorMode from '~/hooks/useColorMode';
import useRestriction from '~/hooks/useRestriction';
import fastProvider from '~/utils/fastProvider';

import useDownloadPortfolioData from '../../useDownloadPortfolioData';
import useSimulationRules from '../../useSimulationRules';
import SimulationCoefficientPopOver from '../SimulationCoefficients';
import useDownloadListContract from '../SimulationContracts/useDownloadListContract';
import SimulationCreation from '../SimulationCreation';
import SimulationDuplication from '../SimulationDuplication';
import NewOperationModal from '../SimulationOperation';
import SimulationsList from '../SimulationsList';
import NewSimulation from './NewSimulation';
import SimulationInterestRate from './SimulationInterestRate';
import SimulationName from './SimulationName';

export type SubheaderDirectives = 'LIST' | 'OPERATION' | 'CREATE' | 'DUPLICATE';

const [withModalContext, useModalContext] = fastProvider(() => {
  const [isOpen, setOpen] = useState<SubheaderDirectives>();

  const onOpen = (directive: SubheaderDirectives) => () => setOpen(directive);

  const onClose = () => setOpen(undefined);

  return { onOpen, onClose, isOpen };
});

export const withIsOpenController =
  <T,>(Component: React.ComponentType<T>) =>
  (props: React.PropsWithChildren<T>) => {
    const { isOpen } = useModalContext();
    const { id } = props as any;

    if (id !== isOpen) return null;

    return <Component {...props} />;
  };

const SimulationSubheader: React.ComponentType<FlexProps> = ({ children, ...props }) => {
  const { onOpen } = useModalContext();
  const { restrict } = useRestriction();
  const { colorTernary } = useColorMode();
  const { isReadOnly, isPortfolio, isContractPage } = useSimulationRules();
  const { downloadPortfolio, loadingDownloadPortfolio } = useDownloadPortfolioData();
  const { downloadContracts, loadingDownloadContracts } = useDownloadListContract();
  const SimulationsListController = withIsOpenController(SimulationsList);
  const NewOperationModalController = withIsOpenController(NewOperationModal);
  const SimulationCreationController = withIsOpenController(SimulationCreation);
  const SimulationDuplicationController = withIsOpenController(SimulationDuplication);

  return (
    <Flex {...props}>
      <Flex
        align="center"
        justify="space-between"
        height="14"
        width="full"
        padding="2"
        bgColor={colorTernary('#1c1c1c', 'white')}
        borderRadius="lg"
      >
        <Flex align="center" gridColumnGap="1">
          {children}
        </Flex>
        <Flex align="center" gridColumnGap="2" padding="1">
          {!isPortfolio && !isContractPage && <SimulationInterestRate />}
          <SimulationCoefficientPopOver />
          {!isPortfolio && (
            <>
              <IconButton
                aria-label="open simulations folder"
                size="sm"
                onClick={onOpen('LIST')}
                colorScheme="gray"
                variant="outline"
              >
                <FolderOpen fontSize="small" />
              </IconButton>
              <NewSimulation />
            </>
          )}
          {!isReadOnly && (
            <Button leftIcon={<AddIcon boxSize="5" />} onClick={onOpen('OPERATION')} size="sm" colorScheme="orange">
              Nova operação
            </Button>
          )}
          {restrict(
            'SIMULATION_EXPORT_RESULT',
            !isContractPage && (
              <IconButton
                aria-label="download"
                variant="outline"
                colorScheme="gray"
                size="sm"
                onClick={() => downloadPortfolio()}
                disabled={loadingDownloadPortfolio}
              >
                {!loadingDownloadPortfolio ? (
                  <GetApp style={{ color: `${colorTernary('#EEE', '#262626')}`, width: '20px', height: '20px' }} />
                ) : (
                  <CircularProgress size={5} isIndeterminate color="gray.500" />
                )}
              </IconButton>
            ),
          )}
          {restrict(
            'SIMULATION_EXPORT_LIST_CONTRACT',
            isContractPage && (
              <IconButton
                aria-label="download"
                variant="outline"
                colorScheme="gray"
                size="sm"
                onClick={() => downloadContracts()}
                disabled={loadingDownloadContracts}
              >
                {!loadingDownloadContracts ? (
                  <GetApp style={{ color: `${colorTernary('#EEE', '#262626')}`, width: '20px', height: '20px' }} />
                ) : (
                  <CircularProgress size={5} isIndeterminate color="gray.500" />
                )}
              </IconButton>
            ),
          )}
        </Flex>
      </Flex>
      <SimulationsListController id="LIST" />
      <NewOperationModalController id="OPERATION" />
      <SimulationCreationController id="CREATE" />
      <SimulationDuplicationController id="DUPLICATE" />
    </Flex>
  );
};

export { withModalContext, useModalContext, SimulationName };
export default withModalContext(SimulationSubheader);
