import { Fragment } from 'react';

import { Flex, Grid, GridItem, Text, Theme, useTheme } from '@tradener/lumen';
import { DateTime } from 'luxon';

import { useDetails } from '../..';

function LeftContent() {
  const { sizes, colors } = useTheme<Theme>();
  const { quote } = useDetails();

  return (
    <Flex direction="column" align="flex-start" gap="3" width="28.625rem">
      <Text textStyle="subheading2-semibold">Ofertas</Text>
      <Grid
        width="full"
        overflow="hidden"
        gridTemplateColumns={`${sizes['22']} ${sizes['22']} auto auto`}
        sx={{
          '& .item': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            height: '10',
            whiteSpace: 'nowrap',
            padding: '4',
            borderColor: `${colors.gray[900]}29`,
          },
        }}
      >
        <GridItem className="item" textStyle="body2-medium" color="gray.500">
          De
        </GridItem>
        <GridItem className="item" textStyle="body2-medium" color="gray.500">
          Até
        </GridItem>
        <GridItem className="item" textStyle="body2-medium" color="gray.500">
          Preço (R$/MWh)
        </GridItem>
        <GridItem className="item" textStyle="body2-medium" color="gray.500">
          Volume
        </GridItem>
        {quote?.quoteItems?.map((item) => (
          <Fragment key={item?.amount + item?.startDate}>
            <GridItem className="item" borderTop="1px solid" textStyle="body2-regular">
              {DateTime.fromISO(item.startDate ?? '').toFormat('dd/MM/yy')}
            </GridItem>
            <GridItem className="item" borderTop="1px solid" textStyle="body2-regular">
              {DateTime.fromISO(item.endDate ?? '').toFormat('dd/MM/yy')}
            </GridItem>
            <GridItem className="item" borderTop="1px solid" textStyle="body2-regular">
              {item.amount?.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
              })}
            </GridItem>
            <GridItem className="item" borderTop="1px solid" textStyle="body2-regular">
              {item.quantity?.toLocaleString('pt-br', {
                minimumFractionDigits: 6,
              })}{' '}
              MWm
            </GridItem>
          </Fragment>
        ))}
      </Grid>
    </Flex>
  );
}

export default LeftContent;
