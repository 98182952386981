import styled, { css } from 'styled-components';

/* eslint-disable */
export const Container = styled.button`
  position: relative;
  border: none;

  cursor: pointer;

  height: 24px;
  width: 112px;

  color: #ffffff;
  background-color: #8c8c8c;

  ${({ checked }) =>
    checked &&
    css`
      background-color: #E96300;
      color: #eee;
    `};

  vertical-align: middle;
  border-radius: 16px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 14px;
      overflow: hidden;
      white-space: nowrap;
      padding-right: 12px;
      text-overflow: ellipsis;
      vertical-align: middle;
    }

    svg {
      width: 22px;
      cursor: pointer;
      height: 22px;
      margin: 0 5px 0 6px;
    }
  }
`;
