import { useState, useRef } from 'react';

import fastProvider from '~/utils/fastProvider';

export interface SimulationItemPushProps {
  resourceId: string;
  competence: string;
  price: number;
  quantityMwm: number;
}

const [withSimulationContract, useSimulationContract] = fastProvider(() => {
  const [itemPushList, setItemPushList] = useState<SimulationItemPushProps[]>([]);
  const contentRef = useRef<HTMLDivElement>(null);

  return {
    itemPushList,
    setItemPushList,
    contentRef,
  };
});

export { withSimulationContract, useSimulationContract };
