import { useMemo } from 'react';

import ReactChart from 'react-apexcharts';

import gql from 'graphql-tag';

import { options } from './ChartHelper';
import { Title } from './styles';

type MonthStage = {
  closed: number;
  negotiated: number;
  opened: number;
};

export type FunnelMonth = {
  period: string;
  accumulated: MonthStage;
  stacked: MonthStage;
};

type ChartSeries = {
  name: string;
  data: number[];
};

type ChartProps = {
  data: FunnelMonth[];
};

const Chart: React.FC<ChartProps> = (props) => {
  const { data } = props;

  const series = useMemo(() => {
    const rawSeries: ChartSeries[] = [
      { name: 'Fechadas', data: [] as number[] },
      { name: 'Negociadas', data: [] as number[] },
      { name: 'Abertas', data: [] as number[] },
    ];

    data.forEach((month) => {
      rawSeries[0].data.push(month.stacked.closed);
      rawSeries[1].data.push(month.stacked.negotiated);
      rawSeries[2].data.push(month.stacked.opened);
    });

    return rawSeries;
  }, [data]);

  return (
    <div>
      <Title>Distribuição de Ofertas por mês</Title>
      <ReactChart options={options} type="bar" series={series} height="100%" />
    </div>
  );
};

export const ChartFragment = gql`
  fragment ChartFragment on QuoteFunnel {
    months {
      period
      accumulated {
        opened
        negotiated
        closed
      }
      stacked {
        opened
        negotiated
        closed
      }
    }
  }
`;

export default Chart;
