import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';

import CREATE_SIMULATION, { ResponseProps, PayloadProps, InputProps } from '@graphql/mutation/createSimulation';

import useColorMode from '~/hooks/useColorMode';
import FilledInput from '~/theme/components/FilledInput';
import Modal, { ModalBody, ModalHeader, ModalFooter, ModalProps } from '~/theme/components/Modal';

import useSimulation from '../../useSimulation';
import { SubheaderDirectives, useModalContext } from '../SimulationSubheader';

export interface SimulationDuplicationProps extends Omit<ModalProps, 'isOpen' | 'onClose'> {
  id: SubheaderDirectives;
}

const SimulationDuplication: React.ComponentType<SimulationDuplicationProps> = (props) => {
  const { colorTernary } = useColorMode();
  const { handleSubmit, register } = useForm<InputProps>();
  const { setFilter, name: simulationName, simulationId: simulationBaseId } = useSimulation();
  const { onClose } = useModalContext();
  const [createSimulation, { loading }] = useMutation<ResponseProps, PayloadProps>(CREATE_SIMULATION, {
    onCompleted: ({ createSimulation }) => {
      setFilter('simulationId', createSimulation.simulation.id);
      toast.success('Simulação copiada com sucesso!');
      onClose();
    },
    onError: ({ message }) => toast.error(message),
  });

  const onSubmit: SubmitHandler<InputProps> = async ({ name }) => {
    if (!name) name = `Cópia de ${simulationName}`;

    createSimulation({ variables: { input: { name: name as string, simulationBaseId: simulationBaseId as string } } });
  };

  return (
    <Modal onClose={onClose} isOpen {...props}>
      <ModalHeader style={{ height: '64px' }}>
        <Typography variant="h5">Duplicar Simulação</Typography>
      </ModalHeader>
      <ModalBody style={{ height: '78px' }}>
        <Typography
          variant="body2"
          style={{
            fontSize: '14px',
            fontWeight: 500,
            color: `${colorTernary('#d9d9d9', '#262626')}`,
          }}
        >
          Nome
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="start"
          flexWrap="nowrap"
          width="100%"
          height="40px"
          style={{
            marginBlock: '8px',
          }}
        >
          <FilledInput
            type="text"
            placeholder={`Cópia de ${simulationName}`}
            style={{ minWidth: '120px', margin: '0px' }}
            {...register('name', { required: false })}
          />
        </Box>
      </ModalBody>
      <ModalFooter style={{ height: '88px' }}>
        <Button
          variant="outlined"
          color="secondary"
          style={{ color: `${colorTernary('#d9d9d9', '#262626')}`, marginRight: '10px' }}
          onClick={onClose}
          disableElevation
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ width: '160px' }}
          onClick={handleSubmit(onSubmit)}
          disabled={loading}
          disableElevation
        >
          {!loading ? 'Duplicar simulação' : <CircularProgress size={22} color="secondary" />}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SimulationDuplication;
