import { useEffect } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { BoxProps } from '@material-ui/core';
import { parseSafeFloat } from '@tradener/lumen';
import dot from 'dot-object';

import usePeriodComparison from '~/hooks/usePeriodComparison';

import { TableVerticalAxis } from '../../Table';
import { useOperationInput } from '../Operation';
import { FormProps, useSimulationOperations } from '../withGuarantee';
import OperationEdition from './OperationEdition';

const getNumberFormat = (
  value: number,
  options: Intl.NumberFormatOptions = {
    currency: 'BRL',
    style: 'currency',
    minimumFractionDigits: 2,
  },
) => {
  if (Number.isNaN(value) || !value) return '';

  return new Intl.NumberFormat('pt-BR', options).format(value);
};

const OperationInput: React.ComponentType<BoxProps> = (props) => {
  const operationIndex = useSimulationOperations(({ index }) => index);
  const { simulationItem, index: periodIndex, period } = useOperationInput();
  const getColor = usePeriodComparison();

  const {
    control,
    formState: { defaultValues },
    setValue,
    getValues,
    watch,
  } = useFormContext<FormProps>();

  const path = `operations.${operationIndex}.simulationItems.${periodIndex}` as 'operations.0.simulationItems.0';
  const quantityMwmPath = `${path}.quantityMwm` as 'operations.0.simulationItems.0.quantityMwm';
  const pricePath = `${path}.price` as 'operations.0.simulationItems.0.price';

  const parseQuantityMwm = (value: string) => {
    const quantityMwm = parseSafeFloat(value);

    return getNumberFormat(quantityMwm!, { minimumFractionDigits: 3 });
  };

  const parsePrice = (value: string) => {
    const price = parseSafeFloat(value);

    return getNumberFormat(price!);
  };

  const resetField = (path: string) => {
    setValue(path as any, dot.pick(path, defaultValues), {
      shouldDirty: false,
      shouldTouch: false,
      shouldValidate: false,
    });
  };

  useEffect(() => {
    if (!getValues(`${path}.competence`)) setValue(`${path}.competence`, period);
  }, [getValues, path, period, setValue]);

  return (
    <TableVerticalAxis width="fit-content" bgColor={getColor(period)} {...props}>
      <Controller
        name={quantityMwmPath}
        control={control}
        defaultValue={simulationItem?.quantityMwm}
        render={({ field: { onChange, value: _, ...props } }) => {
          const quantityMwm = watch(quantityMwmPath)?.toString();
          const value = parseQuantityMwm(quantityMwm);

          return (
            <OperationEdition
              onSubmit={(value) => onChange(parseSafeFloat(value))}
              transform={parseQuantityMwm}
              value={value}
              onDelete={(event) => {
                event.stopPropagation();

                resetField(quantityMwmPath);
              }}
              {...props}
            />
          );
        }}
      />
      <Controller
        name={pricePath}
        control={control}
        defaultValue={simulationItem?.price}
        render={({ field: { onChange, value: _, ...props } }) => {
          const price = watch(pricePath)?.toString();
          const value = parsePrice(price);

          return (
            <OperationEdition
              onSubmit={(value) => onChange(parseSafeFloat(value))}
              transform={parsePrice}
              value={value}
              onDelete={(event) => {
                event.stopPropagation();

                resetField(pricePath);
              }}
              {...props}
            />
          );
        }}
      />
    </TableVerticalAxis>
  );
};

export default OperationInput;
