import { useQuery } from '@apollo/client';

import TENANTS, { ResponseProps, TenantProps } from '@graphql/query/tenants';

import usePortfolio from '~/pages/Portfolio/usePortfolio';
import client from '~/services/apollo';

const useCachedTenants = () => {
  const { data } = useQuery<ResponseProps>(TENANTS, { fetchPolicy: 'cache-only' });

  return data?.tenants ?? [];
};

const getCachedTenants = () => {
  const cache = client.readQuery({ query: TENANTS });

  return (cache?.tenants as TenantProps[]) ?? [];
};

const getTenants = async () => {
  const { data } = await client.query<ResponseProps>({ query: TENANTS });
  const { tenantId } = usePortfolio.getState();

  if (!tenantId?.length) {
    usePortfolio.setState({ tenantId: [data.tenants.find(({ name }) => name === 'TRADENER')?.id as string].filter(Boolean) });
  }

  return data?.tenants;
};

export { getCachedTenants, getTenants, useCachedTenants };
