import { memo } from 'react';

import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import { Container } from './styles';

const STATUS = {
  create: 'Criado',
  close: 'Fechado',
  update: 'Atualizado',
  propose: 'Proposto',
  reject: 'Rejeitado',
  expire: 'Expirado',
  cancel: 'Cancelado',
  succeed: 'Sucedido',
};

function parseDate(date) {
  return format(parseISO(date), 'dd/MM/yy HH:mm');
}

function History({ logs }) {
  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th>Data</th>
            <th>Ação</th>
            <th>Responsável</th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log) => (
            <tr key={log.id}>
              <td>{parseDate(log.createdAt)}</td>
              <td>{STATUS[log.action]}</td>
              <td>{log?.requester?.name || log?.requester?.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
}

History.defaultProps = {
  logs: [],
};

History.propTypes = {
  logs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      createdAt: PropTypes.string,
      action: PropTypes.oneOf(['create', 'close', 'update', 'propose', 'reject', 'expire', 'cancel', 'succeed']),
      requester: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.email,
      }),
    }),
  ),
};

export default memo(History);
