import { useMemo } from 'react';

import { FiZap } from 'react-icons/fi';

import { numberFormat } from '@DEPRECATED/utils/format';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';

import useColorMode from '~/hooks/useColorMode';

import { Container, AvatarContent, Content } from './styles';

function BoxStatus({ color, title, value }) {
  const { colorTernary } = useColorMode();

  const isNegative = useMemo(() => value < 0, [value]);
  const parsedValue = useMemo(() => (typeof value === 'number' ? `${numberFormat(value, 1, 1)} MWm` : '-'), [value]);

  return (
    <Container color={color} style={{ backgroundColor: colorTernary('#111', 'white') }}>
      <AvatarContent color={color}>
        <Avatar alt="Energy">
          <FiZap />
        </Avatar>
      </AvatarContent>
      <Content>
        <span className="title" style={{ color: colorTernary('white', '#111') }}>
          {title}
        </span>
        <span className={isNegative ? 'red' : undefined}>{parsedValue}</span>
      </Content>
    </Container>
  );
}

BoxStatus.defaultProps = {
  value: null,
};

BoxStatus.propTypes = {
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
};

export default BoxStatus;
