import { createIcon } from "@tradener/lumen";

const PurchaseIcon = createIcon({
  displayName: 'PurchaseIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path fillRule="evenodd" clipRule="evenodd" d="M10 7C8.89543 7 8 7.89543 8 9V15C8 16.1046 8.89543 17 10 17H14C15.1046 17 16 16.1046 16 15C16 14.4477 16.4477 14 17 14C17.5523 14 18 14.4477 18 15C18 17.2091 16.2091 19 14 19H10C7.79086 19 6 17.2091 6 15V9C6 6.79086 7.79086 5 10 5H14C16.2091 5 18 6.79086 18 9C18 9.55228 17.5523 10 17 10C16.4477 10 16 9.55228 16 9C16 7.89543 15.1046 7 14 7H10Z" fill="currentColor"/>
    </>
  )
});

export default PurchaseIcon;
