import MuiFab from '@material-ui/core/Fab';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiPaper from '@material-ui/core/Paper';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    height: calc(100% - 48px);
    display: flex;
    position: relative;
    ${(theme?.palette?.type === 'light') && `background-color: #FFF` || `background-color: #000`};
  `}
`;

export const ConditionList = styled.div`
  padding: 14px 16px;
`;

export const Fab = styled(MuiFab)`
  && {
    position: absolute;
    bottom: 14px;
    right: 16px;
  }
`;

export const Paper = styled(MuiPaper)`
  && {
  }
`;

export const MenuItem = styled(MuiMenuItem)`
  && {
    &.MuiMenuItem-dense {
      font-family: 'Roboto';
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.7px;
      text-align: left;
    }
  }
`;
