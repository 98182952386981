import { GridItem, Menu, MenuButton, MenuList, IconButton, MoreVertIcon, theme } from '@tradener/lumen';

import { QuoteProps } from '@graphql/query/productOperations';

import QuoteOptions from '~/pages/Trading/TradingList/QuoteOptions';
import ComplementDialog from '~/pages/Trading/TradingList/QuoteOptions/ComplementDialog';
import ConfirmDialog from '~/pages/Trading/TradingList/QuoteOptions/ConfirmDialog';
import ActionApprove from '~/pages/Trading/TradingList/QuoteOptions/OptionActions/ActionApprove';
import ActionCancel from '~/pages/Trading/TradingList/QuoteOptions/OptionActions/ActionCancel';
import ActionComplement from '~/pages/Trading/TradingList/QuoteOptions/OptionActions/ActionComplement';
import ActionDownloadProposal from '~/pages/Trading/TradingList/QuoteOptions/OptionActions/ActionDownloadProposal';
import ActionDuplicate from '~/pages/Trading/TradingList/QuoteOptions/OptionActions/ActionDuplicate';
import ActionEdit from '~/pages/Trading/TradingList/QuoteOptions/OptionActions/ActionEdit';
import ActionEmitProposal from '~/pages/Trading/TradingList/QuoteOptions/OptionActions/ActionEmitProposal';
import ActionMoveBook from '~/pages/Trading/TradingList/QuoteOptions/OptionActions/ActionMoveBook';
import ActionReject from '~/pages/Trading/TradingList/QuoteOptions/OptionActions/ActionReject';
import ActionRenegotiate from '~/pages/Trading/TradingList/QuoteOptions/OptionActions/ActionRenegotiate';
import ActionSendProposal from '~/pages/Trading/TradingList/QuoteOptions/OptionActions/ActionSendProposal';
import StageDivider from '~/pages/Trading/TradingList/QuoteOptions/OptionActions/StageDivider';

export interface OfferOptionsProps {
  quote: QuoteProps;
}

const OfferOptions: React.ComponentType<OfferOptionsProps> = ({ quote }) => (
  <QuoteOptions key={quote.updatedAt} quote={quote}>
    <GridItem
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      whiteSpace="nowrap"
      color="gray.900"
      textStyle="body2-regular"
      cursor="pointer"
      height="14"
      padding="0"
    >
      <Menu colorScheme="gray" autoSelect={false} lazyBehavior="unmount" isLazy>
        <MenuButton
          as={IconButton}
          data-testid="options"
          aria-label="mais"
          variant="unstyled"
          colorScheme="gray"
          icon={<MoreVertIcon boxSize="6" />}
        />
        <MenuList
          bgColor="gray.0"
          color="gray.900"
          width="43.5"
          padding="2"
          borderRadius="lg"
          boxShadow="md"
          zIndex={theme.zIndices.popover + 1}
        >
          <ActionApprove />
          <ActionReject />
          <StageDivider stages={['OPENED', 'PROPOSED']} />
          <ActionEmitProposal />
          <ActionRenegotiate />
          <ActionSendProposal />
          <StageDivider stages={['OPENED', 'PROPOSED']} />
          <ActionEdit />
          <ActionDuplicate />
          <ActionComplement />
          <ActionDownloadProposal />
          <ActionMoveBook />
          <StageDivider stages={['CLOSED', 'PROPOSED', 'OPENED', 'REJECTED', 'DENIED']} />
          <ActionCancel />
        </MenuList>
      </Menu>
      <ConfirmDialog />
      <ComplementDialog />
    </GridItem>
  </QuoteOptions>
);

export default OfferOptions;
