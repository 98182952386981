import { forwardRef } from 'react';

import { Card } from '@material-ui/core';
import { SnackbarContent, SnackbarContentProps } from 'notistack';

import useStyles from './styles';

export interface SnackbarProps extends SnackbarContentProps {
  message: string | React.ReactNode;
}

const Snackbar = forwardRef<HTMLDivElement, SnackbarProps>(({ message, ...props }, ref) => {
  const styles = useStyles();

  return (
    <SnackbarContent ref={ref} className={styles.root} {...props}>
      <Card className={styles.card} elevation={1}>
        {message}
      </Card>
    </SnackbarContent>
  );
});

export default Snackbar;
