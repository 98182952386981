import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette, typography, zIndex }) => {
  const backgroundColor = palette.type === 'dark' ? palette.grey[800] : palette.common.white;

  return {
    sticky: {
      left: 0,
      position: 'sticky',
      backgroundColor,
    },
    ellipsis: {
      ...typography.caption,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '228px',
    },
    chip: {
      padding: '0 5px',
      minWidth: '20px',
      height: '20px',
      textAlign: 'center',
      borderRadius: '4px',
      '& span': {
        ...typography.caption,
        paddingLeft: 0,
        paddingRight: 0,
        fontWeight: 'normal',
      },
    },
    table: {
      '& th:first-child, & th:last-child, & td:first-child, & td:last-child': {
        padding: '0 16px',
      },
    },
    stickyHead: {
      top: 0,
      position: 'sticky',
      zIndex: zIndex.tooltip,
      backgroundColor,
    },
  };
});

export default useStyles;
