import { gql } from '@apollo/client';

export interface PayloadProps {
  file: File;
}

export interface ResponseProps {
  status: string;
  errors: string[];
}

const UPLOAD_OPERATION_LIMITS = gql`
  mutation importOperationLimits($file: Upload!) {
    importOperationLimits(input: { file: $file }) {
      status
      errors
    }
  }
`;

export default UPLOAD_OPERATION_LIMITS;
