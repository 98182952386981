import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';

import PRODUCT_OPERATIONS, { ResponseProps, PayloadProps } from '@graphql/query/productOperations';

import { pickByPaths } from '@tradener/lumen';

import { useBbceProduct } from '..';

const useProductOperations = (fetchPolicy: WatchQueryFetchPolicy) => {
  const { id, filter } = useBbceProduct();

  const response = useQuery<ResponseProps, PayloadProps>(PRODUCT_OPERATIONS, {
    variables: { productId: id, filter: pickByPaths(filter, 'internalAccountId', 'userId', 'startDate', 'endDate') },
    fetchPolicy,
  });

  return response;
};

export default useProductOperations;
