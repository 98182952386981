import { useState, useMemo, useEffect } from 'react';

import { FaCheckSquare } from 'react-icons/fa';
import { FiPlusSquare } from 'react-icons/fi';
import { MdSearch } from 'react-icons/md';

import { OutlinedInput } from '@DEPRECATED/components/Form';
import { letterExists } from '@DEPRECATED/utils';
import { useMutation } from '@apollo/client';
import { InputAdornment, IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import Check from '~/theme/icons/Check';

import cable from '~/services/link/cable';

import Option from './Option';
import { Container } from './styles';

const { subscriptions } = cable;
let subscription;

const sid = uuid();

const useStyles = makeStyles(({ palette }) => ({
  root: {
    color: palette.secondary.light,
    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
  },
  option: {
    '&:hover': {
      color: palette.secondary.main,
      backgroundColor: palette.background.main,
    },
    '&[data-focus="true"]': {
      backgroundColor: palette.background.main,
    },
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    height: '18px',
  },
}));

export const ADD_ACCOUNT = gql`
  mutation AddAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      account {
        id
        name
      }
    }
  }
`;

function Typeahead({ onSelect, account, disabled }) {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState('');
  const classes = useStyles();

  useEffect(() => {
    if (account.name) setValue(account.name);
  }, [account.name]);

  function finish(data) {
    if (data && typeof data === 'object') {
      onSelect(data);
      setValue(data?.name ?? '');
    }
  }

  const [addAccount, { data, loading }] = useMutation(ADD_ACCOUNT, {
    onCompleted({ createAccount }) {
      finish(createAccount.account);
    },
  });

  function handlerSubscribe() {
    subscription = subscriptions.create(
      { sid, channel: 'AccountTypeaheadChannel' },
      {
        received: setOptions,
      },
    );
  }

  function handlerUnsubscribe() {
    subscriptions.remove(subscription);
  }

  function handlerChange({ target }) {
    const { value: name } = target;

    setValue(name);
    subscription.send({ message: name, sid });
  }

  function handlerValue({ target }) {
    const { textContent: name } = target;

    setValue(name);
  }

  function registerAccount() {
    addAccount({
      variables: { input: { name: value } },
    });
  }

  function getOptionLabel(option) {
    if (typeof option === 'object') return option.name;
    if (option) return option;

    return '';
  }

  const showRegistration = useMemo(() => {
    if (!value) return false;
    if (value === account.name) return false;
    if (!letterExists(value)) return false;

    return !options.some((option) => value.toLocaleLowerCase() === option.name.toLocaleLowerCase());
  }, [account.name, options, value]);

  const showSalesforceIcon = useMemo(
    () => !showRegistration && value === account.name && account.crmId,
    [account.crmId, account.name, showRegistration, value],
  );

  return (
    <Container>
      <Autocomplete
        debug
        disabled={disabled}
        freeSolo
        autoHighlight
        clearOnEscape
        options={options}
        onChange={(_, item) => finish(item)}
        value={value}
        getOptionLabel={getOptionLabel}
        ListboxProps={{
          onClick: handlerValue,
        }}
        renderOption={Option}
        renderInput={(params) => (
          <OutlinedInput
            fullWidth
            onChange={handlerChange}
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            onFocus={handlerSubscribe}
            onBlur={handlerUnsubscribe}
            placeholder="Localize ou cadastre uma conta"
            autoComplete="off"
            startAdornment={
              <InputAdornment position="start">
                <MdSearch size={24} />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end" classes={{ root: classes.inputAdornment }}>
                {showRegistration && (
                  <IconButton aria-label="registrar conta" onClick={registerAccount} disabled={loading} edge="end">
                    <FiPlusSquare size={24} color="#a8adba" />
                  </IconButton>
                )}

                {showSalesforceIcon && (
                  <Tooltip title="Abrir no Salesforce" arrow>
                    <IconButton
                      classes={{ root: classes.iconButton }}
                      aria-label="abrir no salesforce"
                      target="_blank"
                      href={`${process.env.REACT_APP_SALESFORCE_URL}/lightning/r/Account/${account.crmId}/view`}
                    >
                      <Check fontSize="small" classes={{ root: classes.icon }} />
                    </IconButton>
                  </Tooltip>
                )}

                {data && data.createAccount.account.name === value && (
                  <IconButton aria-label="Conta criada" edge="end">
                    <FaCheckSquare size={24} color="#0ebc29" />
                  </IconButton>
                )}
              </InputAdornment>
            }
          />
        )}
      />
    </Container>
  );
}

Typeahead.defaultProps = {
  account: {},
  disabled: false,
};

Typeahead.propTypes = {
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  account: PropTypes.shape({
    name: PropTypes.string,
    crm_id: PropTypes.string,
    crmId: PropTypes.string,
  }),
};

export default Typeahead;
