/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo, useState, useCallback, useReducer, useEffect } from 'react';

import ReactChart from 'react-apexcharts';
import { MdZoomOut } from 'react-icons/md';

import { Title } from '@DEPRECATED/components/WidgetHeader/styles';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { padStart, defaultsDeep, last, sortBy } from 'lodash';
import { Action } from 'redux';
import { ValuesType } from 'utility-types';

import { useProvider } from '~/DEPRECATED/pages/provider';
import useColorMode from '~/hooks/useColorMode';
import fastProvider from '~/utils/fastProvider';

import OfferNegotiationFilter from './OfferNegotiationFilter';
import { getOptions, barStyle, lineStyle } from './OfferNegotiationHelper';
import { Container, Header, Content, Divider, IconButton } from './styles';

type SummarySeries = {
  period: string[];
  purchase: {
    volumes: Array<number | null>;
    prices: Array<number | null>;
  };
  sale: {
    volumes: Array<number | null>;
    prices: Array<number | null>;
  };
  years?: {
    [key: string]: SummarySeries;
  };
};

type QuoteSummaryFields = {
  averagePrice: number;
  quantityMwm: number;
};

type QuoteSummaryItemBase = {
  period: string;
  purchase?: QuoteSummaryFields;
  sale?: QuoteSummaryFields;
};

type QuoteSummaryItem = QuoteSummaryItemBase & {
  months: QuoteSummaryItemBase[];
};

type QuoteSummaryData = {
  quoteSummary: QuoteSummaryItem[];
};

export interface QuoteSummaryProps {
  stage: string[];
  internalAccounts?: string[] | undefined;
  submarket?: string | undefined;
  priceType?: string | undefined;
  periodType?: string | undefined;
  product?: string[] | undefined;
  closedAfter?: string | undefined;
  closedBefore?: string | undefined;
  swap?: boolean | undefined;
  hasGiro?: boolean | undefined;
  additionalPrice?: number | undefined;
  additionalEnergyType?: string | undefined;
}

export type QuoteSummaryAction = Action<keyof QuoteSummaryProps> & { value?: ValuesType<QuoteSummaryProps> };

export const GET_QUOTE_SUMMARY = gql`
  query quoteSummary(
    $stage: [QuoteStage!]!
    $submarket: QuoteSubitemSubmarket
    $priceType: QuotePrice
    $periodType: QuotePeriod
    $product: [String!]
    $closedAfter: String
    $closedBefore: String
    $internalAccounts: [String!]
    $swap: Boolean
    $hasGiro: Boolean
    $additionalPrice: Float
    $additionalEnergyType: String
  ) {
    quoteSummary(
      filter: {
        stage: $stage
        submarket: $submarket
        priceType: $priceType
        periodType: $periodType
        product: $product
        closedAfter: $closedAfter
        closedBefore: $closedBefore
        internalAccounts: $internalAccounts
        swap: $swap
        hasGiro: $hasGiro
        additionalPrice: $additionalPrice
        additionalEnergyType: $additionalEnergyType
      }
    ) {
      period
      purchase {
        averagePrice
        quantityMwm
      }
      sale {
        averagePrice
        quantityMwm
      }
      months {
        period
        purchase {
          averagePrice
          quantityMwm
        }
        sale {
          averagePrice
          quantityMwm
        }
      }
    }
  }
`;

export const initialFilterState = { stage: ['PROPOSED'] };

export const reducer: React.Reducer<QuoteSummaryProps, QuoteSummaryAction> = (state, { type, value }) => {
  switch (type) {
    case 'all' as typeof type:
      return { ...state, ...(value as unknown as QuoteSummaryProps) };
    case 'reset' as typeof type:
      return initialFilterState;
    default:
      return { ...state, [type]: value };
  }
};

const [withOfferFilter, useOfferFilter] = fastProvider(() => {
  const [filter, dispatch] = useReducer(reducer, initialFilterState);

  const replaceFilter = useCallback((value: QuoteSummaryProps) => {
    dispatch({ type: 'all', value } as unknown as QuoteSummaryAction);
  }, []);

  const resetFilter = useCallback(() => {
    dispatch({ type: 'reset' } as unknown as QuoteSummaryAction);
  }, []);

  return { filter, replaceFilter, resetFilter };
});

const OfferNegotiation = () => {
  const { internalAccounts } = useProvider();
  const { colorTernary } = useColorMode();
  const [year, setYear] = useState(String(new Date().getFullYear()));
  const [isZoomOut, setIsZoomOut] = useState(false);
  const { filter } = useOfferFilter();

  const { data } = useQuery<QuoteSummaryData, QuoteSummaryProps>(GET_QUOTE_SUMMARY, {
    fetchPolicy: 'network-only',
    variables: {
      ...filter,
      internalAccounts,
    },
  });

  const handleZoomIn = (year: string) => {
    setYear(year);
    setIsZoomOut(false);
  };

  const handleZoomOut = () => {
    setIsZoomOut(true);
    setYear('');
  };

  const getSummaries = useCallback(() => {
    const payload: SummarySeries = {
      years: {},
      period: [],
      purchase: {
        volumes: [],
        prices: [],
      },
      sale: {
        volumes: [],
        prices: [],
      },
    };

    if (!data?.quoteSummary) return payload;

    const getPeriods = (items: QuoteSummaryItemBase[]) => {
      const summary = sortBy(items, ['period']);

      return {
        period: summary.map((item) => item.period),
        purchase: {
          volumes: summary.map((item) => item.purchase?.quantityMwm || null),
          prices: summary.map((item) => item.purchase?.averagePrice || null),
        },
        sale: {
          volumes: summary.map((item) => item.sale?.quantityMwm || null),
          prices: summary.map((item) => item.sale?.averagePrice || null),
        },
      };
    };

    (data.quoteSummary || []).forEach((summary: QuoteSummaryItem) => {
      const months = Array.from({ length: 12 }).map((_, i) => {
        const period = `${summary.period}-${padStart(String(i + 1), 2, '0')}`;

        return defaultsDeep(last(summary.months.filter((item) => item.period === period)), {
          period,
          purchase: {
            quantityMwm: null,
            averagePrice: null,
          },
          sale: {
            quantityMwm: null,
            averagePrice: null,
          },
        });
      });

      (payload['years'] as unknown as SummarySeries)[summary.period] = getPeriods(months);
    });

    return {
      ...payload,
      ...getPeriods(data.quoteSummary),
    };
  }, [data]);

  const series = useMemo(() => {
    const summaries = getSummaries();
    const summary = summaries.years?.[year] ?? summaries;

    return {
      categories: summary.period,
      volumes: [
        { name: 'Compra', data: summary.purchase.volumes },
        { name: 'Venda', data: summary.sale.volumes },
      ],
      prices: [
        { name: 'Compra', data: summary.purchase.prices },
        { name: 'Venda', data: summary.sale.prices },
      ],
    };
  }, [getSummaries, year]);

  const plotOptions = useMemo(() => {
    let percent = 80;

    if (series.categories.length < 12) {
      percent = (percent / 11) * series.categories.length;
    }

    return {
      plotOptions: {
        bar: {
          columnWidth: `${percent}%`,
          dataLabels: {
            position: 'top',
          },
        },
      },
    };
  }, [series]);

  useEffect(() => {
    if (data?.quoteSummary.length) {
      const [{ period }] = sortBy(data.quoteSummary, ['period']);

      handleZoomIn(period);
    }
  }, [data?.quoteSummary]);

  return (
    <Container>
      <Header style={{ backgroundColor: colorTernary('#232323', '#DDD') }}>
        <Title>{filter.stage.includes('PROPOSED') ? 'Ofertas em negociação' : 'Ofertas fechadas'}</Title>
        <section>
          <OfferNegotiationFilter />
          {!isZoomOut && (
            <IconButton onClick={handleZoomOut}>
              <MdZoomOut size={24} color={colorTernary('#DDD', '#262626')} />
            </IconButton>
          )}
        </section>
      </Header>
      <Content>
        <Divider title={filter.stage.includes('PROPOSED') ? 'Volume em negociação' : 'Volume fechado'} subtitle="(MWm)" />
        <ReactChart
          options={getOptions({
            ...barStyle,
            ...plotOptions,
            chart: {
              events: {
                click: (event: any) => {
                  const { j: index } = event.target.attributes;

                  if (index?.value && series.categories[index.value].length === 4) {
                    handleZoomIn(series.categories[index.value]);
                  }
                },
              },
            },
            xaxis: { categories: series.categories },
          })}
          type="bar"
          series={series.volumes}
          height="230"
        />
        <Divider title={filter.stage.includes('PROPOSED') ? 'Preço em negociação' : 'Preço fechado'} subtitle="(R$/MWh)" />
        <ReactChart
          options={getOptions({
            ...lineStyle,
            xaxis: { categories: series.categories },
          })}
          type="line"
          series={series.prices}
          height="230"
        />
      </Content>
    </Container>
  );
};

export { useOfferFilter };
export default withOfferFilter(OfferNegotiation);
